import React from 'react';
import PropTypes from 'prop-types';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import styles from './index.module.less';

const SwipeableDrawer = ({ open, onToggle, swipeDrawerContent }) => {
  return (
    <div>
      <SwipeableBottomSheet
        open={open}
        onChange={() => onToggle(swipeDrawerContent)}
        style={{ zIndex: 999 }}
        bodyStyle={{ backgroundColor: 'transparent' }}
        data-test="swipeableWrapper"
        overflowHeight={26}
      >
        <div className={styles.drawerContent} data-test="drawerContent">
          <div className={styles.drawerHandle} data-test="drawerHandle" />
          {swipeDrawerContent}
        </div>
      </SwipeableBottomSheet>
    </div>
  );
};

SwipeableDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  swipeDrawerContent: PropTypes.node.isRequired,
};

export default SwipeableDrawer;
