/* eslint-disable react/prop-types */
import { hasAircraftPermission } from './AuthenticationWrapper';

const DoesUserHaveACPermission = ({ acId, loggedInUser, requiredResource, requiredPermissionLevel }) => {
  const personInOrgWithPermissions =
    loggedInUser && loggedInUser.find((person) => person.permission_groups[1].aircraft.includes(acId));

  if (
    hasAircraftPermission(
      { id: acId },
      requiredResource,
      requiredPermissionLevel,
      personInOrgWithPermissions && personInOrgWithPermissions.permission_groups[1],
    )
  ) {
    return true;
  }
  return null;
};

export default DoesUserHaveACPermission;
