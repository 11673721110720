import { Form, Input, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { add } from '../../models/people/actions';
import { redirectFromError } from '../../utils/requestError';
import Loading from '../TFLoading';
import styles from './index.module.less';

const { Option } = Select;

let roles = ['Admin', 'Pilot', 'OpsController', 'Camo', 'Crew'];

class AddUserModal extends PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    flightRolesOnly: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    operators: PropTypes.array.isRequired,
  };

  state = {
    operators: [],
  };

  componentDidMount() {
    this.getOperatorList();
  }

  getOperatorList = () => {
    const { operators } = this.props;
    this.setState({
      operators,
    });
  };

  handleSubmit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
      const opList = this.state.operators;
      await this.props
        .dispatch(
          add({
            payload: {
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              role: values.role,
              position: values.role,
              organisation_id: opList.length > 1 ? values.operator : opList[0].id,
            },
          }),
        )
        .catch(() => {
          const { history } = this.props;
          redirectFromError(500, history.push);
        });
      this.clearForm();
    });
  };

  clearForm = () => {
    const { form } = this.props;
    form.resetFields();
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const {
      form: { getFieldDecorator },
      loading,
      flightRolesOnly,
      intl: { formatMessage },
      visible,
    } = this.props;

    const { operators } = this.props;

    const labelCol = { span: 7 };
    const wrapperCol = { span: 13 };

    if (flightRolesOnly) {
      roles = ['Pilot', 'Crew'];
    }

    return (
      <Modal
        title={formatMessage({ id: 'title.addNewUser' })}
        onOk={this.handleSubmit}
        onCancel={this.clearForm}
        visible={visible}
      >
        <Form onSubmit={this.handleSubmit}>
          <Loading loading={loading} contain />
          <Form.Item label={formatMessage({ id: 'form.labels.firstName' })} labelCol={labelCol} wrapperCol={wrapperCol}>
            {getFieldDecorator('firstName', {
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'message.addFirstName' }),
                },
              ],
            })(
              <Input
                className={styles.addUserModal}
                placeholder={formatMessage({ id: 'form.placeholder.firstName' })}
              />,
            )}
          </Form.Item>
          <Form.Item label={formatMessage({ id: 'form.labels.lastName' })} labelCol={labelCol} wrapperCol={wrapperCol}>
            {getFieldDecorator('lastName', {
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'message.addLastName' }),
                },
              ],
            })(
              <Input
                className={styles.addUserModal}
                placeholder={formatMessage({ id: 'form.placeholder.lastName' })}
              />,
            )}
          </Form.Item>
          <Form.Item label={formatMessage({ id: 'form.labels.email' })} labelCol={labelCol} wrapperCol={wrapperCol}>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: formatMessage({ id: 'message.emailInvalid' }),
                },
                {
                  required: true,
                  message: formatMessage({ id: 'message.addValidEmail' }),
                },
              ],
            })(<Input className={styles.addUserModal} placeholder={formatMessage({ id: 'form.placeholder.email' })} />)}
          </Form.Item>
          <Form.Item label={formatMessage({ id: 'form.labels.role' })} labelCol={labelCol} wrapperCol={wrapperCol}>
            {getFieldDecorator('role', {
              initialValue: roles[0],
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'message.selectUserRole' }),
                },
              ],
            })(
              <Select className={styles.dropdown}>
                {roles.map((element) => {
                  return (
                    <Option key={element} value={element}>
                      {element}
                    </Option>
                  );
                })}
              </Select>,
            )}
          </Form.Item>
          {operators.length > 1 ? (
            <Form.Item
              label={formatMessage({ id: 'form.labels.operator' })}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
            >
              {getFieldDecorator('operator', {
                rules: [
                  {
                    required: true,
                    message: formatMessage({ id: 'message.selectOperator' }),
                  },
                ],
              })(
                <Select
                  className={styles.dropdown}
                  placeholder={formatMessage({ id: 'form.placeholder.pleaseSelect' })}
                >
                  {operators.map((op) => {
                    return (
                      <Option key={op.id} value={op.id}>
                        {op.name}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(({ people, userSettings }) => ({
    operators: userSettings.details.people.map((person) => ({
      name: person.organisation.name,
      id: person.organisation.id,
    })),
    people,
  })),
  Form.create(),
)(AddUserModal);
