import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import ContentLayout from '../layouts/ContentLayout';
import ExternalLayout from '../layouts/ExternalLayout';
import { DashboardResource, UserPermission } from '../models/userSettings';
import { AircraftPermission, AircraftResource } from '../models/aircraft';
import Exception403 from '../pages/403';
import Exception404 from '../pages/404';
import Exception500 from '../pages/500';
import AddWorkpack from '../pages/AddWorkpack/AddWorkpack';
import EditWorkpack from '../pages/AddWorkpack/EditWorkpack';
import AddAircraftTrip from '../pages/Aircraft/AddAircraftTrip';
import AircraftAddDefectDetails from '../pages/Aircraft/AircraftAddDefectDetails';
import AircraftAddDefectFlight from '../pages/Aircraft/AircraftAddDefectFlight';
import AircraftDefectProfile from '../pages/Aircraft/AircraftDefectProfile';
import AircraftEditTripFlight from '../pages/Aircraft/AircraftEditTripFlight';
import AircraftSettings from '../pages/Aircraft/AircraftSettings';
import EditAircraftFlight from '../pages/Aircraft/EditAircraftFlight';
import Overview from '../pages/Aircraft/Overview';
import DamageMaps from '../pages/DamageMaps/DamageMaps';
import ListView from '../pages/DamageMaps/ListView';
import SchematicView from '../pages/DamageMaps/SchematicView';
import MXSignoff from '../pages/External/MXSignoff';
import AircraftDefectsList from '../pages/Logs/AircraftDefectsList';
import AircraftDocuments from '../pages/Manage/AircraftDocuments';
import Metrics from '../pages/Metrics/Metrics';
import Trips from '../pages/ManageTrips/Trips';
import OauthCallback from '../pages/OauthCallback';
import WorkpackProfile from '../pages/Workpacks/WorkpackProfile';
import Workpacks from '../pages/Workpacks/Workpacks';
import MaintenanceManagement from '../pages/AircraftMaintenance/MaintenanceManagement';
import Monthly from '../pages/Logbook/Monthly';
import { AuthProvider, AircraftAuthProvider } from './AuthProvider';

const AddAircraftDetails = lazy(() =>
  import(/* webpackChunkName: "AddAircraftDetails" */ '../pages/AddAircraft/Details'),
);
const AppSettings = lazy(() => import(/* webpackChunkName: "AppSettubgs" */ '../pages/AddAircraft/AppSettings'));
const Totals = lazy(() => import(/* webpackChunkName: "Totals" */ '../pages/AddAircraft/Totals'));
const Flight = lazy(() => import(/* webpackChunkName: "Flight" */ '../pages/AddDefect/Flight'));
const Details = lazy(() => import(/* webpackChunkName: "Details" */ '../pages/AddDefect/Details'));
const AddTrip = lazy(() => import(/* webpackChunkName: "AddTrip" */ '../pages/AddTrip'));
const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '../pages/Dashboard'));
const DefectsList = lazy(() => import(/* webpackChunkName: "DefectsList" */ '../pages/Logs/DefectsList'));
const DefectProfile = lazy(() => import(/* webpackChunkName: "DefectProfile" */ '../pages/Logs/DefectProfile'));
const Documents = lazy(() => import(/* webpackChunkName: "Documents" */ '../pages/Manage/Documents'));
const FlightsList = lazy(() => import(/* webpackChunkName: "FlightsList" */ '../pages/Logs/FlightsList'));
const FlightProfile = lazy(() => import(/* webpackChunkName: "FlightProfile " */ '../pages/Logs/FlightProfile'));
const MaintenanceList = lazy(() =>
  import(/* webpackChunkName: "FlightProfile " */ '../pages/Maintenance/MaintenanceList'),
);
const EditFlight = lazy(() => import(/* webpackChunkName: "EditFlight" */ '../pages/EditFlight/index'));
const EditTripFlight = lazy(() =>
  import(/* webpackChunkName: "EditTripFlight " */ '../pages/EditFlight/EditTripFlight'),
);
const MELs = lazy(() => import(/* webpackChunkName: "MELs" */ '../pages/MELs/MELs'));
const EditMEL = lazy(() => import(/* webpackChunkName: "EditMEL" */ '../pages/MELs/EditMEL'));
const People = lazy(() => import(/* webpackChunkName: "People" */ '../pages/UserManagement/UserManagement'));
const Operations = lazy(() => import(/* webpackChunkName: "Operations" */ '../pages/Dashboard/Operations'));
const Routedashboard = lazy(() => import(/* webpackChunkName: "Routedashboard" */ '../pages/Dashboard/Routedashboard'));
const Fuel = lazy(() => import(/* webpackChunkName: "Fuel" */ '../pages/Receipts/Fuel'));
const Aircraft = lazy(() => import(/* webpackChunkName: "Aircraft" */ '../pages/Manage/Aircraft'));
const AccountSettings = lazy(() => import(/* webpackChunkName: "AccountSettings" */ '../pages/Manage/AccountSettings'));
const Integrations = lazy(() => import(/* webpackChunkName: "Integrations" */ '../pages/Manage/Integrations'));

const Loading: React.FC = () => null;

/**
 * React Context Provider for React Router DOM.
 *
 * Currently handles determines which pages require authentication,
 * which paths are available and the permissions required for each path.
 *
 * @returns {ReactFC} React Functional Component
 */
const RoutesWrapper: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Route path="/auth/callback">
        <OauthCallback />
      </Route>
      <Route path="/defects/:id/public/:public_key" key={`/defects/:id/public/:public_key_${pathname}`} exact>
        <ExternalLayout>
          <MXSignoff />
        </ExternalLayout>
      </Route>
      <Route path="/public/exception/404" key={`/public/exception/404_${pathname}`} exact>
        <ExternalLayout>
          <Exception404 />
        </ExternalLayout>
      </Route>
      <Route path="/public/exception/500" key={`/public/exception/500_${pathname}`} exact>
        <ExternalLayout>
          <Exception500 />
        </ExternalLayout>
      </Route>
      <Route path="/workpacks/:id/public/:public_key" key={`/workpacks/:id/public/:public_key_${pathname}`} exact>
        <ExternalLayout>
          <MXSignoff />
        </ExternalLayout>
      </Route>
      <Route path="/" key={`/_${pathname}`} exact>
        {/* This path should never be used so we redirect to Dashboard instead :) */}
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/add/aircraft" key={`/add/aircraft_${pathname}`} exact>
        <Redirect to="/add/aircraft/details" />
      </Route>
      <Route path="/add/aircraft/details" key={`/add/aircraft/details_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <AddAircraftDetails />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/add/aircraft/log" key={`/add/aircraft/log_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <AppSettings />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/add/aircraft/totals" key={`/add/aircraft/totals_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Totals />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/add/defect" key={`/add/defect_${pathname}`} exact>
        <Redirect to="/add/defect/flight" />
      </Route>
      <Route path="/add/defect/flight" key={`/add/defect/flight_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DEFECT} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Flight />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/add/defect/details" key={`/add/defect/details_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DEFECT} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Details />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/add/trip" key={`/add/trip_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.TRIP} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <AddTrip />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/aircraft/:id/overview" key={`/aircraft/:id/overview_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.READ}>
          <Overview />
        </AuthProvider>
      </Route>
      <Route path="/aircraft/:id/logbook" key={`/aircraft/:id/logbook_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.LOGBOOK} permissionRequired={AircraftPermission.READ}>
          <Monthly />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/trips" key={`/aircraft/:id/trips_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.TRIP} permissionRequired={AircraftPermission.READ}>
          <Trips />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/trips/add" key={`/aircraft/:id/trips/add_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.TRIP} permissionRequired={AircraftPermission.CREATE}>
          <AddAircraftTrip />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/trips/:flight/edit" key={`/aircraft/:id/trips/:flight/edit_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.FLIGHT} permissionRequired={AircraftPermission.UPDATE}>
          <EditAircraftFlight />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/trips/:trip/trip" key={`/aircraft/:id/trips/:trip/trip_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.TRIP} permissionRequired={AircraftPermission.UPDATE}>
          <AircraftEditTripFlight />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/defects"
        key={`/aircraft/:id/defects_${pathname}`}
        exact
        render={({ location: { pathname: localPathname } }): JSX.Element => {
          return <Redirect to={`${localPathname}/log`} />;
        }}
      />
      <Route path="/aircraft/:id/defects/log" key={`/aircraft/:id/defects/log_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DEFECT} permissionRequired={AircraftPermission.READ}>
          <AircraftDefectsList />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/defects/add"
        key={`/aircraft/:id/defects/add_${pathname}`}
        exact
        render={({ location: { pathname: localPathname } }): JSX.Element => {
          return <Redirect to={`${localPathname}/flight`} />;
        }}
      />
      <Route path="/aircraft/:id/defects/add/flight" key={`/aircraft/:id/defects/add/flight_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DEFECT} permissionRequired={AircraftPermission.CREATE}>
          <AircraftAddDefectFlight />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/defects/add/details" key={`/aircraft/:id/defects/add/details_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DEFECT} permissionRequired={AircraftPermission.CREATE}>
          <AircraftAddDefectDetails />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/defects/:defect/view" key={`/aircraft/:id/defects/:defect/_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DEFECT} permissionRequired={AircraftPermission.READ}>
          <AircraftDefectProfile />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/maintenance" key={`/aircraft/:id/maintenance_${pathname}`} exact>
        <AircraftAuthProvider
          resourceRequired={AircraftResource.SCHEDULED_MX_ITEM}
          permissionRequired={AircraftPermission.READ}
        >
          <MaintenanceManagement />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/workpacks"
        key={`/aircraft/:id/workpacks_${pathname}`}
        exact
        render={({ location: { pathname: localPathname } }): JSX.Element => {
          return <Redirect to={`${localPathname}/log`} />;
        }}
      />
      <Route path="/aircraft/:id/workpacks/log" key={`/aircraft/:id/workpacks/log_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.WORKPACK} permissionRequired={AircraftPermission.READ}>
          <Workpacks />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/workpacks/add" key={`/aircraft/:id/workpacks/add_${pathname}`} exact>
        <AircraftAuthProvider
          resourceRequired={AircraftResource.WORKPACK}
          permissionRequired={AircraftPermission.CREATE}
        >
          <AddWorkpack />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/workpacks/:workpack_id/view"
        key={`/aircraft/:id/workpacks/:workpack_id/view_${pathname}`}
        exact
      >
        <AircraftAuthProvider resourceRequired={AircraftResource.WORKPACK} permissionRequired={AircraftPermission.READ}>
          <WorkpackProfile />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/workpacks/:workpack_id/edit"
        key={`/aircraft/:id/workpacks/:workpack_id/edit_${pathname}`}
        exact
      >
        <AircraftAuthProvider
          resourceRequired={AircraftResource.WORKPACK}
          permissionRequired={AircraftPermission.UPDATE}
        >
          <EditWorkpack />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/documents" key={`/aircraft/:id/documents_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DOCUMENT} permissionRequired={AircraftPermission.READ}>
          <AircraftDocuments />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/metrics" key={`/aircraft/:id/metrics_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.TRIP} permissionRequired={AircraftPermission.READ}>
          <Metrics />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/damage_maps"
        key={`/aircraft/:id/damage_maps_${pathname}`}
        exact
        render={({ location: { pathname: localPathname } }): JSX.Element => {
          return <Redirect to={`${localPathname}/list_view`} />;
        }}
      />
      <Route path="/aircraft/:id/damage_maps/list_view" key={`/aircraft/:id/damage_maps/list_view_${pathname}`} exact>
        <AircraftAuthProvider
          resourceRequired={AircraftResource.DAMAGE_MAP}
          permissionRequired={AircraftPermission.READ}
        >
          <DamageMaps>
            <ListView />
          </DamageMaps>
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/damage_maps/schematic_view"
        key={`/aircraft/:id/damage_maps/schematic_view_${pathname}`}
        exact
      >
        <AircraftAuthProvider
          resourceRequired={AircraftResource.DAMAGE_MAP}
          permissionRequired={AircraftPermission.READ}
        >
          <DamageMaps>
            <SchematicView />
          </DamageMaps>
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/settings" key={`/aircraft/:id/settings_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.READ}>
          <AircraftSettings />
        </AuthProvider>
      </Route>
      <Route path="/dashboard" key={`/dashboard_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DASHBOARD} permissionRequired={UserPermission.READ}>
          {/* <ContentLayout> */}
          <Suspense fallback={<Loading />}>
            <Dashboard />
          </Suspense>
          {/* </ContentLayout> */}
        </AuthProvider>
      </Route>
      <Route path="/exception/403" key={`/exception/403_${pathname}`} exact>
        <ContentLayout>
          <Exception403 />
        </ContentLayout>
      </Route>
      <Route path="/exception/404" key={`/exception/404_${pathname}`} exact>
        <ContentLayout>
          <Exception404 />
        </ContentLayout>
      </Route>
      <Route path="/exception/500" key={`/exception/500_${pathname}`} exact>
        <ContentLayout>
          <Exception500 />
        </ContentLayout>
      </Route>
      <Route path="/logbook/:id" key={`/logbook/:id_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.LOGBOOK} permissionRequired={AircraftPermission.READ}>
          <ContentLayout>
            <Monthly />
          </ContentLayout>
        </AircraftAuthProvider>
      </Route>
      <Route path="/defects" key={`/defects_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DEFECT} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <DefectsList />
          </Suspense>
        </AuthProvider>
      </Route>
      <Route path="/defects/:defect/view" key={`/defects/:defect_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DEFECT} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <DefectProfile />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/operations/documents" key={`/operations/documents_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DOCUMENT} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <Documents />
          </Suspense>
        </AuthProvider>
      </Route>
      <Route path="/operations/flights" key={`/operations/flights_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.TRIP} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <FlightsList />
          </Suspense>
        </AuthProvider>
      </Route>
      <Route path="/operations/flights/:flight" key={`/operations/flights/:flight_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.TRIP} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <FlightProfile />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/operations/flights/:flight/edit" key={`/operations/flights/:flight/edit_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.TRIP} permissionRequired={UserPermission.UPDATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <EditFlight />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/operations/flights/:trip/trip" key={`/operations/flights/:trip/trip_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.TRIP} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <EditTripFlight />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/operations/mels" key={`/operations/mels_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.MEL} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <MELs />
          </Suspense>
        </AuthProvider>
      </Route>
      <Route path="/operations/mels/:mel/edit" key={`/operations/mels/:mel/edit_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.MEL} permissionRequired={UserPermission.UPDATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <EditMEL />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/maintenance" key={`/maintenance_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.MX_ITEM} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <MaintenanceList />
          </Suspense>
        </AuthProvider>
      </Route>

      <Route path="/people" key={`/people_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.PEOPLE} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <People />
          </Suspense>
        </AuthProvider>
      </Route>
      <Route path="/reports/metrics" key={`/reports/metrics_${pathname}`} exact>
        <Suspense fallback={<Loading />}>
          <Operations />
        </Suspense>
      </Route>
      <Route path="/reports/map" key={`/reports/map_${pathname}`} exact>
        <Suspense fallback={<Loading />}>
          <Routedashboard />
        </Suspense>
      </Route>
      <Route path="/reports/receipts" key={`/reports/receipts_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.RECEIPT} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <Fuel />
          </Suspense>
        </AuthProvider>
      </Route>
      <Route path="/settings/aircraft" key={`/settings/aircraft_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <Aircraft />
          </Suspense>
        </AuthProvider>
      </Route>
      <Route path="/settings/account" key={`/settings/account_${pathname}`} exact>
        <Suspense fallback={<Loading />}>
          <AccountSettings />
        </Suspense>
      </Route>
      <Route path="/settings/integrations" key={`/settings/integrations_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.INTEGRATION} permissionRequired={UserPermission.READ}>
          <Suspense fallback={<Loading />}>
            <Integrations />
          </Suspense>
        </AuthProvider>
      </Route>
      <Route path="*">
        <Redirect to="/exception/404" />
      </Route>
    </Switch>
  );
};

const RouterProvider: React.SFC = () => (
  <Router>
    <RoutesWrapper />
  </Router>
);

export default RouterProvider;
