import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import ProgressBarMX from './ProgressBarMX';
import styles from './ProgressBars.module.less';

const ProgressBars = ({ title, mxItem, hasAPU, unitsOnly, layout, intl: { formatMessage } }) => {
  const hrsTimeDetails =
    mxItem.flight_seconds_visible_tolerance !== null &&
    mxItem.flight_seconds_remaining < 0 &&
    mxItem.flight_seconds_remaining + mxItem.flight_seconds_visible_tolerance > 0
      ? 'critical'
      : '';
  const cyclesTimeDetails =
    mxItem.cycles_visible_tolerance !== null &&
    mxItem.cycles_remaining < 0 &&
    mxItem.cycles_remaining + mxItem.cycles_visible_tolerance > 0
      ? 'critical'
      : '';
  const isDaysCritical = () => {
    let status = '';
    if (mxItem.months_visible_tolerance !== null && mxItem.days_remaining < 0) {
      const dateOfRemaining = moment().add(mxItem.days_remaining, 'days');
      const remainingWithTolerance = moment(dateOfRemaining).add(mxItem.months_visible_tolerance, 'months');
      if (moment(remainingWithTolerance).diff(moment(), 'days') >= 0) {
        status = 'critical';
      } else {
        status = '';
      }
    }
    return status;
  };
  const apuHrsTimeDetails =
    mxItem.apu_seconds_visible_tolerance !== null &&
    mxItem.apu_seconds_remaining < 0 &&
    mxItem.apu_seconds_remaining + mxItem.apu_seconds_visible_tolerance > 0
      ? 'critical'
      : '';

  const hrsItem = {
    threshold: hrsTimeDetails,
    remaining:
      mxItem.flight_seconds_remaining +
      (mxItem.flight_seconds_visible_tolerance ? mxItem.flight_seconds_visible_tolerance : 0),
    tolerance: mxItem.flight_seconds_visible_tolerance,
    format: 'seconds',
  };

  const cyclesItem = {
    threshold: cyclesTimeDetails,
    remaining: mxItem.cycles_remaining + (mxItem.cycles_visible_tolerance ? mxItem.cycles_visible_tolerance : 0),
    tolerance: mxItem.cycles_visible_tolerance,
    format: 'number',
  };

  const daysItem = {
    threshold: isDaysCritical(),
    remaining: mxItem.days_remaining + (mxItem.days_visible_tolerance ? mxItem.days_visible_tolerance : 0),
    tolerance: mxItem.days_visible_tolerance,
    format: 'days',
  };

  const apuHrsItem = {
    threshold: apuHrsTimeDetails,
    remaining:
      mxItem.apu_seconds_remaining + (mxItem.apu_seconds_visible_tolerance ? mxItem.apu_seconds_visible_tolerance : 0),
    tolerance: mxItem.apu_seconds_visible_tolerance,
    format: 'seconds',
  };

  return (
    <>
      {title && (
        <div className={styles.itemTitle} data-test="progressBarsTitle">
          {title}
        </div>
      )}
      <ProgressBarMX
        enabled={!(mxItem.flight_seconds_remaining == null)}
        unitSingular={unitsOnly ? formatMessage({ id: 'units.hour' }) : formatMessage({ id: 'units.hourLeft' })}
        unitPlural={unitsOnly ? formatMessage({ id: 'units.hours' }) : formatMessage({ id: 'units.hoursLeft' })}
        item={hrsItem}
        layout={layout}
        data-test="progressBarFlightHrs"
      />
      <ProgressBarMX
        enabled={!(mxItem.cycles_remaining == null)}
        unitSingular={unitsOnly ? formatMessage({ id: 'units.cycle' }) : formatMessage({ id: 'units.cycleLeft' })}
        unitPlural={unitsOnly ? formatMessage({ id: 'units.cycles' }) : formatMessage({ id: 'units.cyclesLeft' })}
        item={cyclesItem}
        layout={layout}
        data-test="progressBarCycles"
      />
      <ProgressBarMX
        enabled={!(mxItem.days_remaining == null)}
        unitSingular={unitsOnly ? formatMessage({ id: 'units.day' }) : formatMessage({ id: 'units.dayLeft' })}
        unitPlural={unitsOnly ? formatMessage({ id: 'units.days' }) : formatMessage({ id: 'units.daysLeft' })}
        item={daysItem}
        layout={layout}
        data-test="progressBarDays"
      />
      {hasAPU && (
        <ProgressBarMX
          enabled={!(mxItem.apu_seconds_remaining == null)}
          unitSingular={unitsOnly ? formatMessage({ id: 'units.apuHour' }) : formatMessage({ id: 'units.apuHourLeft' })}
          unitPlural={unitsOnly ? formatMessage({ id: 'units.apuHours' }) : formatMessage({ id: 'units.apuHoursLeft' })}
          item={apuHrsItem}
          layout={layout}
          data-test="progressBarAPU"
        />
      )}
    </>
  );
};

ProgressBars.defaultProps = {
  title: '',
  hasAPU: false,
  unitsOnly: false,
  layout: null,
};

ProgressBars.propTypes = {
  title: PropTypes.string,
  mxItem: PropTypes.object.isRequired,
  hasAPU: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  unitsOnly: PropTypes.bool,
  layout: PropTypes.string,
};
export default injectIntl(ProgressBars);
