import { Icon } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import collapseIcon from '../../assets/collapse.svg';
import expandIcon from '../../assets/expand.svg';
import packageIcon from './assets/icon-package.svg';
import workpackItem from './assets/icon-workpack-scheduled.svg';
import styles from './ExpandableCard.module.less';

const ExpandableCardTitle = ({ item, expanded, isOverdue, isCritical, onToggle }) => {
  const { formatMessage } = useIntl();
  const titleButton = useRef(null);
  const textBox = useRef(null);
  const [boxWidth, setBoxWidth] = useState(400);

  const titleButtonWidth = titleButton.current && titleButton.current.getBoundingClientRect().width;
  const textBoxWidth = textBox.current && textBox.current.getBoundingClientRect().width;

  const getTagArea = () => {
    const { mx_type: mxType } = item;
    let type = mxType ? mxType.replace(/^.+_/, '') : 'sch';
    if (type === 'scheduled') {
      type = 'sch';
    }
    let area = (mxType || '').substring(0, (mxType || '').lastIndexOf('_')).replace('_', ' ');
    if (area === 'apu') area = 'APU';

    let pkgText = null;
    if (item.package && item.package_items.length > 0) {
      area = 'Package';
      type = 'pkg';
      pkgText = (
        <>
          <img src={packageIcon} alt="packageIcon" className={styles.packageIcon} /> {item.package_items.length}
        </>
      );
    }
    return {
      type,
      area,
      pkgText,
    };
  };
  const { type, area, pkgText } = getTagArea();

  const renderSource = () => {
    const { source, app_source } = item;
    let sourceOutput = (
      <>
        <Icon type="plus" className={styles.iconBlue} /> <span>{formatMessage({ id: 'text.trustflight' })}</span>
      </>
    );
    if (source === 'camp') {
      sourceOutput = (
        <>
          <Icon type="arrow-down" className={styles.iconAmber} /> <span>{formatMessage({ id: 'text.camp' })}</span>
        </>
      );
    }
    if (source === 'core' && app_source === 'tl') {
      sourceOutput = (
        <>
          <Icon type="plus" className={styles.iconBlue} /> <span>{formatMessage({ id: 'text.techlog' })}</span>
        </>
      );
    }
    return sourceOutput;
  };

  const renderCreated = () => {
    let renderOutput = (
      <div>
        {formatMessage({ id: 'text.created' })}:{' '}
        <span data-test="cardTitleDateValue">{moment(item.created_at).format('YYYY-MM-DD')}</span>
      </div>
    );

    if (item.source === 'camp' && item.imported_at) {
      renderOutput = (
        <div>
          {formatMessage({ id: 'text.imported' })}:{' '}
          <span data-test="cardTitleDateValue">{moment(item.imported_at).format('YYYY-MM-DD')}</span>
        </div>
      );
    }

    if (item.edit_history && item.edit_history.length > 0) {
      renderOutput = (
        <div className={styles.textBlue} data-test="cardTitleDateWrapper">
          <Icon type="edit" /> {formatMessage({ id: 'text.editedIn' })}:{' '}
          <span>
            {item.edit_history[0].app_source === 'tl'
              ? formatMessage({ id: 'text.techlog' })
              : formatMessage({ id: 'text.trustflight' })}
          </span>
        </div>
      );
    }

    return renderOutput;
  };

  const renderIcon = () => {
    let icon = <img src={expandIcon} alt="expandIcon" />;
    if (expanded) {
      icon = <img src={collapseIcon} alt="collapseIcon" />;
    }
    return icon;
  };

  useEffect(() => {
    if (!expanded) {
      setBoxWidth(titleButtonWidth - 400);
      if (titleButtonWidth < 710) {
        setBoxWidth(230);
      }
    }
  }, [boxWidth, expanded, titleButtonWidth]);
  const itemName = item.name;
  return (
    <button
      className={`${styles.cardTitleWrapper} ${expanded ? styles.cardTitleWrapperExpanded : undefined} ${
        isOverdue ? styles.cardTitleWrapperOverdue : undefined
      } ${isCritical ? styles.cardTitleWrapperCritical : undefined}`}
      onClick={() => onToggle()}
      type="button"
      data-test="cardTitleWrapper"
      ref={titleButton}
    >
      <div className={styles.titleWrapperLeft}>
        {item.workpacks && item.workpacks.length > 0 ? (
          <img src={workpackItem} alt="workpack icon" className={styles.workpackItem} />
        ) : null}
        <div className={`${styles.cardTag} ${styles[`cardTag${type}`]}`} data-test="cardTitleTag">
          {type === 'pkg' ? pkgText : type}
        </div>
        <div className={item.workpacks && item.workpacks.length > 0 ? styles.cardTitleWithWP : styles.cardTitle}>
          <div className={styles.cardTitleArea} data-test="cardTitleArea">
            {area && `${area},`}
          </div>
          <div
            className={expanded ? styles.cardTitleName : styles.cardTitleNameEllipsis}
            style={{ width: `${boxWidth}px` }}
            data-test="cardTitleName"
            ref={textBox}
          >
            {itemName}
          </div>
        </div>
      </div>
      <div className={styles.titleWrapperRight}>
        <div className={styles.cardSource} data-test="cardTitleSource">
          {renderSource()}
        </div>
        <div className={styles.cardDates} data-test="cardTitleDates">
          {renderCreated()}
        </div>
        <span className={styles.cardExpand} data-test="cardTitleIcon">
          {renderIcon()}
        </span>
      </div>
    </button>
  );
};

ExpandableCardTitle.propTypes = {
  item: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  isOverdue: PropTypes.bool.isRequired,
  isCritical: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ExpandableCardTitle;
