/* eslint-disable react/jsx-props-no-spreading */
import { Form, InputNumber, Radio, TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import InputNumberUnits from '../InputNumberUnits';
import FlightTime from './FlightTime';
import { warnings } from './validation';

class DeiceEvent extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    formItemLayout: PropTypes.object.isRequired,
    flight: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    intl: PropTypes.object.isRequired,
  };

  render() {
    const {
      form,
      formItemLayout,
      flight,
      value,
      intl: { formatMessage },
    } = this.props;
    const { getFieldDecorator } = form;

    const timeOptions = {
      getValueProps(hhmm) {
        return { value: hhmm && moment.utc(hhmm, 'HH:mm') };
      },
      getValueFromEvent(time) {
        return time && time.format('HH:mm');
      },
    };
    const { wrapperCol } = formItemLayout;
    const { labelCol } = formItemLayout;
    return (
      <>
        <Form.Item wrapperCol={wrapperCol} labelCol={labelCol} label={formatMessage({ id: 'form.labels.startTime' })}>
          {getFieldDecorator('id')}
          {getFieldDecorator('start_time', timeOptions)(<FlightTime />)}
        </Form.Item>
        <Form.Item
          wrapperCol={wrapperCol}
          labelCol={labelCol}
          label={formatMessage({ id: 'form.labels.endTime' })}
          {...warnings(formatMessage).deicingEndTime(value, flight)}
        >
          {getFieldDecorator('end_time', timeOptions)(<FlightTime prevValue={moment.utc(value.start_time, 'HH:mm')} />)}
        </Form.Item>
        <Form.Item wrapperCol={wrapperCol} labelCol={labelCol} label={formatMessage({ id: 'form.labels.holdover' })}>
          {getFieldDecorator('holdover_seconds', {
            getValueProps(secs) {
              return { value: moment('00:00', 'HH:mm').add(secs, 'seconds') };
            },
            getValueFromEvent(time, timeString) {
              return moment.duration(timeString).asSeconds();
            },
          })(<TimePicker format="HH:mm" />)}
        </Form.Item>
        <Form.Item wrapperCol={wrapperCol} labelCol={labelCol} label={formatMessage({ id: 'form.labels.fluidRatio' })}>
          {getFieldDecorator('fluid_ratio_left')(<InputNumber min={0} max={100} step={1} />)} /{' '}
          {form.getFieldValue('fluid_ratio_left') && 100 - form.getFieldValue('fluid_ratio_left')}
        </Form.Item>
        <Form.Item wrapperCol={wrapperCol} labelCol={labelCol} label={formatMessage({ id: 'form.labels.type' })}>
          {getFieldDecorator('fluid_type')(
            <Radio.Group>
              <Radio.Button value="I">I</Radio.Button>
              <Radio.Button value="II">II</Radio.Button>
              <Radio.Button value="III">III</Radio.Button>
              <Radio.Button value="IV">IV</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item wrapperCol={wrapperCol} labelCol={labelCol} label={formatMessage({ id: 'form.labels.quantity' })}>
          {getFieldDecorator('quantity')(<InputNumberUnits min={0} units="l" />)}
        </Form.Item>
      </>
    );
  }
}

export default compose(
  injectIntl,
  Form.create({
    onValuesChange(props, changedValues, allValues) {
      if (props.onChange) {
        props.onChange({
          ...allValues,
          fluid_ratio_right: 100 - allValues.fluid_ratio_left,
        });
      }
    },
    mapPropsToFields({ value }) {
      return ['start_time', 'end_time', 'fluid_ratio_left', 'quantity', 'fluid_type', 'holdover_seconds', 'id'].reduce(
        (acc, x) => {
          acc[x] = Form.createFormField({ value: value[x] });
          return acc;
        },
        {},
      );
    },
  }),
)(DeiceEvent);
