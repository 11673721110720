/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { Layout, Icon } from 'antd';
import { pathToRegexp } from 'path-to-regexp';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { urlToList } from '../_utils/pathTools';
import styles from './index.module.less';
import BaseMenu, { getMenuMatches } from './BaseMenu';

const { Sider } = Layout;

/**
 * @memberof SiderMenu
 */
const getDefaultCollapsedSubMenus = (props) => {
  const {
    location: { pathname },
    flatMenuKeys,
  } = props;
  return urlToList(pathname)
    .map((item) => getMenuMatches(flatMenuKeys, item)[0])
    .filter((item) => item);
};

/**
 * Recursively flatten the data
 * [{path:string},{path:string}] => {path,path2}
 * @param  menu
 */
export const getFlatMenuKeys = (menu) =>
  menu.reduce((keys, item) => {
    keys.push(item.path);
    if (item.children) {
      return keys.concat(getFlatMenuKeys(item.children));
    }
    return keys;
  }, []);

/**
 * Find all matched menu keys based on paths
 * @param  flatMenuKeys: [/abc, /abc/:id, /abc/:id/info]
 * @param  paths: [/abc, /abc/11, /abc/11/info]
 */
export const getMenuMatchKeys = (flatMenuKeys, paths) =>
  paths.reduce((matchKeys, path) => matchKeys.concat(flatMenuKeys.filter((item) => pathToRegexp(item).test(path))), []);

class SiderMenu extends PureComponent {
  static propTypes = {
    menuData: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    logo: PropTypes.string,
    logoText: PropTypes.string,
    theme: PropTypes.string,
  };

  static defaultProps = {
    collapsed: undefined,
    onCollapse: undefined,
    logo: '',
    logoText: '',
    theme: '',
  };

  state = {
    openKeys: getDefaultCollapsedSubMenus(this.props),
  };

  constructor(props) {
    super(props);
    this.flatMenuKeys = getFlatMenuKeys(props.menuData);
  }

  static getDerivedStateFromProps(props, state) {
    const { pathname } = state;
    if (props.location.pathname !== pathname) {
      return {
        pathname: props.location.pathname,
        openKeys: getDefaultCollapsedSubMenus(props),
      };
    }
    return null;
  }

  isMainMenu = (key) => {
    const { menuData } = this.props;
    return menuData.some((item) => {
      if (key) {
        return item.key === key || item.path === key;
      }
      return false;
    });
  };

  handleOpenChange = (openKeys) => {
    const moreThanOne = openKeys.filter((openKey) => this.isMainMenu(openKey)).length > 1;
    this.setState({
      openKeys: moreThanOne ? openKeys.shift() : [...openKeys],
    });
  };

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 6; i++) {
      setTimeout(() => {
        this.triggerResizeEvent();
      }, i * 50);
    }
  };

  triggerResizeEvent = () => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  };

  render() {
    const { logo, logoText, collapsed, onCollapse, theme } = this.props;
    const { openKeys } = this.state;

    let logoJsx = null;
    if (logo && logoText) {
      logoJsx = (
        <div className={styles.logo} id="logo" data-testid="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
            <img id={styles.logoText} src={logoText} alt="logo text" />
          </Link>
        </div>
      );
    }
    const fixedSpacerWidth = collapsed ? '60px' : '200px';
    return (
      <>
        <div
          aria-hidden
          className={styles.fixedMenuSpacer}
          style={{ width: fixedSpacerWidth, maxWidth: fixedSpacerWidth, minWidth: fixedSpacerWidth }}
        />
        <div className={styles.fixedMenu} style={{ width: fixedSpacerWidth }}>
          <Sider
            trigger={null}
            collapsed={collapsed}
            breakpoint="lg"
            onCollapse={onCollapse}
            width={200}
            collapsedWidth={60}
            theme={theme}
            className={styles.siderMenuGeneral}
            data-testid="leftMenu"
          >
            {logoJsx}
            <BaseMenu
              {...this.props}
              mode="inline"
              handleOpenChange={this.handleOpenChange}
              onOpenChange={this.handleOpenChange}
              style={{ padding: '18px 0', width: '100%', overflowX: 'hidden' }}
              defaultProps={collapsed ? {} : { openKeys }}
            />
            <div className={styles.collapseIconHolder}>
              <Icon
                className={collapsed ? `${styles.trigger} ${styles.collapsed}` : styles.trigger}
                type={collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={this.toggle}
              />
            </div>
          </Sider>
        </div>
      </>
    );
  }
}

export default compose(withRouter)(SiderMenu);
