import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import TFTooltip from '../../TFTooltip/TFTooltip';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';
import StyledRadioButton from './StyledRadioButton';

interface AircraftLimitationProps {
  defect: Defect | null;
  editDefect: boolean;
}

const BoldDiv = styled.div`
  font-weight: 600;
`;

const RegularText = styled.span`
  font-weight: 400;
`;

const AircraftLimitations: React.FC<AircraftLimitationProps> = ({ defect, editDefect }) => {
  const [isLimitations, setIsLimitations] = useState(false);
  const [isEROPS, setIsEROPS] = useState(false);
  const [limitation, setLimitation] = useState('');
  const [showLimitationsInfo, setShowLimitationsInfo] = useState(false);

  const { formatMessage } = useIntl();

  const onMouseEnterLimitations = (): void => {
    setShowLimitationsInfo(true);
  };

  const onMouseLeaveLimitations = (): void => {
    setShowLimitationsInfo(false);
  };

  useEffect(() => {
    if (defect) {
      setIsLimitations(!!defect.limitations);
      setLimitation(defect.limitations);
      if (defect?.mel_item?.rectification_id) {
        const chosenRect = defect.mel_item.mel_rectifications.find(
          (rect) => rect.id === defect.mel_item.rectification_id,
        );
        setIsEROPS(chosenRect.erops_prevented);
      }
    }
  }, [defect]);
  return (
    <>
      <FlexWrapper column={editDefect} marginTop={editDefect ? 0 : 30} marginBottom={20}>
        <FlexWrapper>
          <Label marginBottom={editDefect ? 20 : 0} fontWeight={600} marginRight={5}>
            {formatMessage({ id: 'title.aircraftLimitationsApplicable' })}
          </Label>
          <TFTooltip
            handleMouseOver={onMouseEnterLimitations}
            handleMouseLeave={onMouseLeaveLimitations}
            showToolTip={showLimitationsInfo}
          >
            <BoldDiv>
              {formatMessage({ id: 'text.mel' })}{' '}
              <RegularText>{formatMessage({ id: 'text.minimumEquipmentList' })}</RegularText>
            </BoldDiv>
            <BoldDiv>
              {formatMessage({ id: 'text.cdl' })}
              <RegularText>{formatMessage({ id: 'text.configurationDeviationList' })}</RegularText>
            </BoldDiv>
            <BoldDiv>
              {formatMessage({ id: 'text.nef' })}{' '}
              <RegularText>{formatMessage({ id: 'text.nonEssentialEquipmentFurnishing' })}</RegularText>
            </BoldDiv>
            <BoldDiv>
              {formatMessage({ id: 'text.cas' })}{' '}
              <RegularText>{formatMessage({ id: 'text.crewAlertingSystem' })}</RegularText>
            </BoldDiv>
          </TFTooltip>
        </FlexWrapper>
        <Label marginBottom={10} fontWeight={500}>
          {formatMessage({ id: 'text.areThereAnyLimitations' })}
        </Label>
        <FlexWrapper>
          <StyledRadioButton
            id="yesLimit"
            group="limitationsPresent"
            value="Yes"
            marginRight={5}
            checked={isLimitations}
            onClick={(): void => setIsLimitations(true)}
          />
          <StyledRadioButton
            id="noLimit"
            group="limitationsPresent"
            value="No"
            checked={!isLimitations}
            onClick={(): void => setIsLimitations(false)}
          />
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper column={editDefect}>
        <FlexWrapper column marginBottom={20} marginRight={!editDefect && 100}>
          <Label marginBottom={5} fontWeight={500}>
            {formatMessage({ id: 'title.limitations' })}
          </Label>
          <StyledInput
            id="limitations"
            type="text"
            width={400}
            defaultValue={limitation}
            onChange={(e): void => setLimitation(e.target.value)}
          />
        </FlexWrapper>
        <FlexWrapper column>
          <Label marginBottom={5} fontWeight={500}>
            {formatMessage({ id: 'title.exceptForEROPS' })}
          </Label>
          <FlexWrapper>
            <StyledRadioButton
              id="yesEROPS"
              group="EROPS"
              value="Yes"
              marginRight={5}
              checked={isEROPS}
              onClick={(): void => setIsEROPS(true)}
            />
            <StyledRadioButton
              id="noEROPS"
              group="EROPS"
              value="No"
              checked={!isEROPS}
              onClick={(): void => setIsEROPS(false)}
            />
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </>
  );
};

export default AircraftLimitations;
