import React, { useEffect, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import PropTypes from 'prop-types';
import { intl } from '../../../providers/IntlProvider';
import style from '../workpackDrawer.module.less';

const Signature = ({ setAcceptanceSig, acceptanceSig }) => {
  const [canvasTouched, setCanvasTouched] = useState(false);
  let sigPad = {};

  const clear = () => {
    sigPad.clear();
    setCanvasTouched(false);
    setAcceptanceSig('');
  };
  // const trim = () => {
  //   this.setState({ trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL('image/png') });
  // };

  const onSignatureBegin = () => {
    setCanvasTouched(true);
    if (sigPad) {
      const signatureData = sigPad
        .getCanvas()
        .toDataURL()
        .substring(22);
    }
  };

  const onSignatureEnd = () => {
    if (sigPad) {
      const signatureData = sigPad
        .getCanvas()
        .toDataURL()
        .substring(22);
      setAcceptanceSig(signatureData);
    }
  };

  useEffect(() => {
    if (acceptanceSig === '') {
      sigPad.clear();
    }
  }, [acceptanceSig, sigPad]);

  return (
    <div>
      <div className={style.signaturePad}>
        <SignaturePad
          ref={(ref) => {
            sigPad = ref;
          }}
          canvasProps={{ width: 500, height: 100 }}
          onBegin={() => onSignatureBegin()}
          onEnd={() => onSignatureEnd()}
        />
        <div className={style.signatureHelper} data-test="signatureHelper">
          <span>{intl.formatMessage({ id: 'text.pleaseSignHere' })}</span>
          {canvasTouched && (
            <button type="button" onClick={() => clear()} className={style.clearButton}>
              Clear
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Signature.propTypes = {
  setAcceptanceSig: PropTypes.func.isRequired,
  acceptanceSig: PropTypes.string.isRequired,
};

export default Signature;
