import React from 'react';
import styles from '../workpackDrawer.module.less';
import SignaturePad from './SignaturePad';

const CRSAcceptance = ({ setAcceptanceSig, acceptanceSig }): JSX.Element => {
  return (
    <div className={styles.column}>
      <span className={styles.signaturePadHeading}>CRS Acceptance</span>
      <span className={styles.signatureSubHeading}>
        Sign here to accept the issued CRS and mark the included maintenance items as complied with.
      </span>
      <SignaturePad setAcceptanceSig={setAcceptanceSig} acceptanceSig={acceptanceSig} />
    </div>
  );
};

export default CRSAcceptance;
