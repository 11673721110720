import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';
import styles from './stylesheet.module.less';

function Footer(props) {
  const {
    uploading,
    handleModalVisible,
    handleFormUpload,
    submitButtonText,
    submitNotClickable,
    completeButtonClickable,
    intl,
  } = props;

  return (
    <>
      <div className={styles.modalFlexibleFooter}>
        <Button
          className={styles.modalFooterCancelButton}
          onClick={() => {
            handleModalVisible(false);
          }}
          disabled={uploading || completeButtonClickable}
          data-test="cancelButton"
        >
          {intl.formatMessage({ id: 'text.cancel' })}
        </Button>
        <Button
          className={submitNotClickable ? styles.modalFooterSubmitButtonNotClickable : styles.modalFooterSubmitButton}
          onClick={handleFormUpload}
          type="primary"
          loading={uploading || completeButtonClickable}
          data-test="submitButton"
        >
          {submitButtonText}
        </Button>
      </div>
    </>
  );
}

Footer.propTypes = {
  uploading: PropTypes.bool,
  handleModalVisible: PropTypes.func,
  handleFormUpload: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  submitNotClickable: PropTypes.bool,
  completeButtonClickable: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

Footer.defaultProps = {
  submitNotClickable: false,
  completeButtonClickable: false,
  uploading: false,
  handleModalVisible: undefined,
};

export default injectIntl(Footer);
