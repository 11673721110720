import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Button, Icon, message, Modal } from 'antd';
import moment from 'moment';
import Scroll from 'react-scroll';
import defaults from '../../utils/defaults';
import globalStyles from '../../utils/globalStyles.module.less';
import PublicPageWrapper from '../../components/PublicPageWrapper';
import AircraftOverview from '../../components/AircraftOverview';
import ExternalSelectableMx from '../../components/ExternalSelectableMX/ExternalSelectableMx';
import AdditionalTasks from '../../components/ExternalForm/AdditionalTasks';
import infoIcon from '../../assets/icon-info-white.svg';
// eslint-disable-next-line import/no-named-as-default
import CompanyPersonalDetails from '../../components/ExternalForm/CompanyPersonalDetails';
import CRSSignature from '../../components/ExternalForm/CRSSignature';
import { postPublicWorkpack } from '../../services/external';
import { getPublicWorkpackDetails, setPublicWorkpackDetailsWithoutAPI } from '../../models/workpacks/actions';
import { getPublicDefectDetails } from '../../models/defects/actions';
import Loading from '../../components/TFLoading';
import servers from '../../utils/servers';
import styles from './MXSignoff.module.less';

const { animateScroll } = Scroll;

class MXSignoff extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getPublicDefectDetails: PropTypes.func.isRequired,
    getPublicWorkpackDetails: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    workpack: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      disabled: false,
      showResolvedDefects: true,
      showResolvedMX: true,
      signoffAsEngineer: true,
      allItemsResolved: false,
      selectAll: false,
      selectAllText: false,
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.getPublicWorkpackDetails();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setStepDirection);
    if (this.setStepDirection) {
      this.setStepDirection.cancel();
    }
  }

  setNewWorkpackData = () => {
    this.setState({ submitting: false });
    this.getPublicWorkpackDetails();
  };

  setSelectableIndeterminate = (value) => {
    const valueID = value.replace('true_', '').replace('false_', '');
    const valueBool = value.replace(`_${valueID}`, '') === 'true';
    this.setState({
      [`indeterminate_${valueID}`]: valueBool,
    });
  };

  getPublicWorkpackDetails = async () => {
    const {
      location,
      match: {
        params: { id, public_key },
      },
    } = this.props;
    await new Promise((resolve) => this.setState({ loading: true }, resolve));
    if (location.pathname.includes('defects')) {
      await this.props.getPublicDefectDetails({
        id,
        key: public_key,
      });
    } else {
      await this.props.getPublicWorkpackDetails({
        id,
        key: public_key,
      });
    }
    await new Promise((resolve) => this.setState({ loading: false }, resolve));
  };

  getDefectsArray = () => {
    const { workpack } = this.props;
    return workpack && workpack.workpack_items
      ? workpack.workpack_items.filter((item) => item.mx_item.type === 'defect')
      : null;
  };

  getMXItemsArray = () => {
    const { workpack } = this.props;
    return workpack && workpack.workpack_items
      ? workpack.workpack_items.filter((item) => item.mx_item.id && item.mx_item.type !== 'defect')
      : null;
  };

  getAircraftMXData = () => {
    const {
      workpack,
      intl: { formatMessage },
    } = this.props;
    const { aircraft } = workpack;
    const aircraftAPUEnabled = aircraft ? aircraft.apu_installed : false;
    if (aircraft) {
      const cyclesObj = {
        remaining: aircraft.widgets.cycles.remaining || null,
        threshold: aircraft.mx_thresholds.cycles || 30,
        tolerance: aircraft.widgets.cycles.tolerance,
        total: aircraft.widgets.cycles.total,
        nextDue: aircraft.widgets.cycles.next_due + aircraft.widgets.cycles.tolerance || null,
      };
      const isYearDiff = moment(aircraft.widgets.days.next_due).diff(moment(), 'years') > 0;
      const daysObj = {
        remaining: aircraft.widgets.days.remaining || null,
        threshold: aircraft.mx_thresholds.days || 30,
        tolerance: aircraft.widgets.days.tolerance * 30,
        total: moment().format('DD MMM'),
        nextDue:
          moment(aircraft.widgets.days.next_due)
            .add(aircraft.widgets.days.tolerance, 'months')
            .format(isYearDiff ? 'DD MMM YY' : 'DD MMM') || null,
      };
      const hoursObj = {
        remaining: aircraft.widgets.flight_hours.remaining || null,
        threshold: aircraft.mx_thresholds.flights_seconds || 30,
        tolerance: aircraft.widgets.flight_hours.tolerance,
        total: aircraft.widgets.flight_hours.total || null,
        nextDue: aircraft.widgets.flight_hours.next_due + aircraft.widgets.flight_hours.tolerance || null,
      };
      const apuObj = {
        remaining: aircraft.widgets.apu_hours.remaining || null,
        threshold: aircraft.mx_thresholds.apu_seconds || 30,
        tolerance: aircraft.widgets.flight_hours.tolerance,
        total: aircraft.widgets.apu_hours.total || null,
        nextDue: aircraft.widgets.apu_hours.next_due + aircraft.widgets.apu_hours.tolerance || null,
      };

      return [
        {
          mxObj: cyclesObj,
          title: formatMessage({ id: 'title.cyclesRem' }),
          circleUnit: formatMessage({ id: 'units.cycles' }),
          format: 'number',
          showTotal: true,
          showNextDue: true,
          active: !!aircraft.widgets.cycles.next_due,
        },
        {
          mxObj: daysObj,
          title: formatMessage({ id: 'title.daysRem' }),
          circleUnit: formatMessage({ id: 'units.days' }),
          format: 'date',
          showTotal: true,
          showNextDue: true,
          active: !!aircraft.widgets.days.next_due,
        },
        {
          mxObj: hoursObj,
          title: formatMessage({ id: 'title.hoursRem' }),
          circleUnit: formatMessage({ id: 'units.hours' }),
          format: 'seconds',
          showTotal: true,
          showNextDue: true,
          active: !!aircraft.widgets.flight_hours.next_due,
        },
        {
          mxObj: apuObj,
          title: formatMessage({ id: 'title.apuRem' }),
          circleUnit: formatMessage({ id: 'units.apuHours' }),
          format: 'seconds',
          showTotal: true,
          showNextDue: true,
          active: !!(aircraft.widgets.apu_hours.next_due && aircraftAPUEnabled),
        },
      ];
    }
    return [];
  };

  getReleaseId = () => {
    const { workpack } = this.props;
    return (
      (workpack &&
        workpack.workpack_items &&
        workpack.workpack_items[0] &&
        workpack.workpack_items[0].mx_item &&
        workpack.workpack_items[0].mx_item.mx_events &&
        workpack.workpack_items[0].mx_item.mx_events[0] &&
        workpack.workpack_items[0].mx_item.mx_events[0].release &&
        workpack.workpack_items[0].mx_item.mx_events[0].release.id) ||
      null
    );
  };

  workpackIsResolved = () => {
    const { workpack } = this.props;

    const workPackStatus =
      workpack &&
      workpack.workpack_items &&
      Array.isArray(workpack.workpack_items) &&
      workpack.workpack_items.every(
        (item) => item.mx_item && item.mx_item.status && item.mx_item.status === 'resolved',
      );
    return workPackStatus;
  };

  handleItemResolved = async () => {
    const { form, workpack } = this.props;
    const itemsArray = workpack ? workpack.workpack_items : [];

    // Check if all workpack items have been marked as resolved
    let allItemsResolved = true;
    itemsArray.forEach((item) => {
      if (!form.getFieldValue(`item_${item.mx_item_id}`)) {
        allItemsResolved = false;
      }
    });
    await new Promise((resolve) => this.setState({ allItemsResolved }, resolve));
  };

  handleSubmit = async (skip) => {
    const {
      form,
      workpack,
      intl: { formatMessage },
    } = this.props;
    const defectsArray = workpack ? workpack.workpack_items.filter((item) => item.mx_item.type === 'defect') : [];
    const itemsArray = workpack ? workpack.workpack_items : [];
    if (!skip) await this.handleItemResolved();
    const { allItemsResolved } = this.state;
    // Check all defect resolutions have been saved
    let checkIndeterminate = false;
    defectsArray.forEach((item) => {
      const defectID = item.mx_item_id;
      if (!checkIndeterminate) {
        checkIndeterminate = this.state[`indeterminate_${defectID}`];
      }
    });
    if (checkIndeterminate) {
      const defectsRow = document.querySelector('.defectsRow');
      if (defectsRow) {
        window.scrollTo(0, defectsRow.offsetTop + 200);
      }
      message.error(formatMessage({ id: 'message.pleaseSaveResolution' }));
      return;
    }

    // Validate fields
    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }
      const resolvedItemsArray = [];
      itemsArray.forEach((item) => {
        // Build Resolved MX Items array from the unresolved items (note, already resolved items are skipped)
        if (form.getFieldValue(`item_${item.mx_item_id}`) && item.mx_item.status !== 'resolved') {
          resolvedItemsArray.push(item);
        }
      });
      // Error if no items were marked as resolved
      if (resolvedItemsArray.length === 0) {
        message.error(formatMessage({ id: 'message.oneItemMustBeResolved' }));
        return;
      }
      if (resolvedItemsArray.length > 0 && !allItemsResolved) {
        this.setState({ modalVisible: true });
        return;
      }

      if (allItemsResolved && resolvedItemsArray.length !== itemsArray.length)
        message.warning(formatMessage({ id: 'message.unresolvedItemsMoved' }));

      this.setState({
        submitting: true,
      });

      const mxEventsArr = [];
      let additionalTasksArr = [];

      resolvedItemsArray.forEach((item) => {
        if (item.mx_item.status !== 'resolved') {
          const mxItemId = item.mx_item_id;
          const pushArray = {
            mx_item_id: mxItemId,
            mx_item_resolved: true,
            date: values.resolution_date.format('YYYY-MM-DD'),
          };

          const workType = form.getFieldValue(`resolution_type_${mxItemId}`);
          const description = form.getFieldValue(`details_${mxItemId}`);
          const limitations = form.getFieldValue(`limitations_details_${mxItemId}`);
          const partChangesArr = [];
          let partNoOn;
          let partNoOff;
          let serialOff;
          let serialOn;
          let batchLotNo;
          for (let i = 0; i < 30; i += 1) {
            partNoOn = values[`replaced_part_part_number_on_${i}_${mxItemId}`];
            partNoOff = values[`replaced_part_part_number_off_${i}_${mxItemId}`];
            serialOff = values[`replaced_part_serial_off_${i}_${mxItemId}`];
            serialOn = values[`replaced_part_serial_on_${i}_${mxItemId}`];
            batchLotNo = values[`replaced_part_batch_lot_number_${i}_${mxItemId}`];
            if (partNoOn && partNoOff && serialOff && serialOn && batchLotNo) {
              partChangesArr.push({
                part_number: partNoOff,
                part_number_on: partNoOn,
                serial_off: serialOff,
                serial_on: serialOn,
                batch: batchLotNo,
              });
            }
          }
          if (workType) {
            pushArray.work_type = workType;
          }
          if (description) {
            pushArray.description = description;
          }
          if (limitations) {
            pushArray.limitations = limitations;
          }
          if (partChangesArr && partChangesArr.length > 0) {
            pushArray.part_changes = partChangesArr;
          }
          mxEventsArr.push(pushArray);
        }
      });

      // Build Additional Tasks array from form values
      Object.keys(values).forEach((key) => {
        if (
          (key.includes('additional_tasks_task_ref') || key.includes('additional_tasks_details')) &&
          values[key] !== undefined &&
          values[key]
        ) {
          const addTaskIndex = key.replace('additional_tasks_task_ref_', '').replace('additional_tasks_details_', '');
          additionalTasksArr.push({
            title: values[`additional_tasks_details_${addTaskIndex}`],
            reference: values[`additional_tasks_task_ref_${addTaskIndex}`],
          });
        }
      });
      additionalTasksArr = additionalTasksArr.filter(
        (key, index, self) => index === self.findIndex((t) => t.title === key.title && t.reference === key.reference),
      );

      const releaseObj = {
        release: {
          date: values.resolution_date.format('YYYY-MM-DD'),
          company_name: values.company_name,
          approval_number: values.company_approval,
          licence_number: values.licence_number,
          first_name: values.first_name,
          ...(values.reporter_first_name && { reporter_first_name: values.reporter_first_name }),
          last_name: values.last_name,
          ...(values.reporter_last_name && { reporter_last_name: values.reporter_last_name }),
          signature: values.signature_data,
          attachments: values.attachment ? values.attachment.attachment : null,
          additional_items: additionalTasksArr,
        },
        reference: values.workpack_reference,
        mx_events: mxEventsArr,
      };

      try {
        let response;
        const {
          location: { pathname },
        } = this.props;
        if (pathname.includes('defects')) {
          response = await postPublicWorkpack({
            id: workpack.id,
            key: workpack.public_url_key,
            body: releaseObj,
          });
        } else {
          response = await postPublicWorkpack({
            id: this.props.match.params.id,
            key: this.props.match.params.public_key,
            body: releaseObj,
          });
        }
        if (response) {
          message.success(formatMessage({ id: 'message.resolutionSaveSuccess' }));
          setPublicWorkpackDetailsWithoutAPI(response);
          this.setNewWorkpackData();
          animateScroll.scrollToTop();
          form.resetFields();
          this.setState({
            showResolvedDefects: true,
            showResolvedMX: true,
          });
        } else {
          message.error(formatMessage({ id: 'message.resolutionSaveError' }));
          this.setNewWorkpackData();
        }
      } catch (error) {
        message.error(formatMessage({ id: 'message.resolutionSaveError' }));
        this.setNewWorkpackData();
      }
    });
  };

  onModalClose = () => {
    this.setState({ modalVisible: false, allItemsResolved: true }, () => {
      this.handleSubmit(true);
    });
  };

  toggleResolvedVisibility = (stateField) => {
    this.setState((prevState) => {
      return {
        [stateField]: !prevState[stateField],
      };
    });
  };

  toggleSignoffMethod = (value) => {
    const { form } = this.props;
    if (value === true) {
      this.setState(() => ({ signoffAsEngineer: true }));
    } else {
      this.setState(() => ({ signoffAsEngineer: false }));
    }
    form.resetFields(['attachment', 'first_name', 'last_name']);
  };

  handleSelectAll = async () => {
    const { selectAll, selectAllText } = this.state;
    await new Promise((resolve) => {
      this.setState({ loading: true }, resolve);
    });
    if (!selectAll && !selectAllText)
      this.setState({ selectAll: true, selectAllText: true }, () =>
        setTimeout(() => {
          document.getElementById('companyDetails').scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.setState({ loading: false });
        }, 1000),
      );
    if (selectAll && selectAllText)
      this.setState({ selectAll: false, selectAllText: false }, () => this.setState({ loading: false }));
    if (selectAll && !selectAllText)
      this.setState({ selectAll: true, selectAllText: true }, () =>
        setTimeout(() => {
          document.getElementById('companyDetails').scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.setState({ loading: false });
        }, 1000),
      );
  };

  handleCheckboxUncheck = () => {
    this.setState({ selectAllText: false });
  };

  scrollToUnresolvedItem = () => {
    const { workpack, form } = this.props;
    const defectsArray = this.getDefectsArray();
    const unresolvedId = [];
    if (workpack) {
      workpack.workpack_items.forEach((item) => {
        if (!form.getFieldValue(`item_${item.mx_item_id}`) && item.mx_item.status !== 'resolved') {
          unresolvedId.push(`item_${item.mx_item_id}`);
        }
        return null;
      });
    }
    if (unresolvedId.length > 0) {
      document.getElementById(unresolvedId[0]).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    // Check all defect resolutions have been saved
    let checkIndeterminate = false;
    let defectID = '';
    defectsArray.forEach((item) => {
      defectID = item.mx_item_id;
      if (!checkIndeterminate) {
        checkIndeterminate = this.state[`indeterminate_${defectID}`];
      }
    });
    if (checkIndeterminate) {
      document.getElementById(`item_${defectID}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  renderSectionTitle = (title, overTitle, showToggle, stateField, openItemsLeft, itemType) => {
    const {
      intl: { formatMessage },
      workpack,
    } = this.props;

    const newStateField = this.state[`${stateField}`];
    const srp_link = workpack?.resolution_trip_url;
    return (
      <div className={styles.sectionTitle}>
        <div className={styles.sectionTitleInnerWrapper}>
          {overTitle && (
            <div className={styles.overTitle} data-test="overTitle" data-testid="overTitle">
              {overTitle}
            </div>
          )}
          {title && (
            <div className={styles.titleWrapper}>
              <div className={styles.title} data-test="title" data-testid="title">
                {title}
              </div>
            </div>
          )}
        </div>
        {showToggle && (
          <div className={`${styles.titleToggle} ${!this.state[stateField] ? styles.titleToggleActive : undefined}`}>
            {openItemsLeft ? (
              <Button
                size="small"
                onClick={() => this.toggleResolvedVisibility(stateField)}
                className={styles.toggleButton}
                data-testid="toggleButton"
              >
                {newStateField ? (
                  <span className={styles.hide}>
                    <Icon type="minus-circle" />{' '}
                    {formatMessage({ id: 'title.hideResolved' }, { type: itemType.toLowerCase() })}
                  </span>
                ) : (
                  <span className={styles.show}>
                    <Icon type="plus-circle" />{' '}
                    {formatMessage({ id: 'title.showResolved' }, { type: itemType.toLowerCase() })}
                  </span>
                )}
              </Button>
            ) : (
              <div className={styles.srpWrapper}>
                <Button size="small" className={styles.toggleButton} disabled>
                  <span>
                    <Icon type="check-circle" />{' '}
                    {formatMessage({ id: 'title.allResolved' }, { type: itemType.toLowerCase() })}
                  </span>
                </Button>
                {srp_link && (
                  <span className={styles.SRPlink}>
                    <a
                      href={`${servers.api}/${srp_link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={formatMessage({ id: 'text.srp' })}
                      className={styles.resolvedLink}
                      data-testid="itemResolvedLink"
                    >
                      {formatMessage({ id: 'text.viewSRP' })}
                      <Icon type="file-pdf" />
                    </a>
                  </span>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      workpack,
      form,
      userSettings,
      intl: { formatMessage },
      match: {
        params: { id },
      },
    } = this.props;
    const { submitting, disabled, loading, signoffAsEngineer, selectAll, selectAllText, modalVisible } = this.state;
    const { aircraft, public_url_key, signature_statement } = workpack;
    let releaseId;
    const defectsArray = this.getDefectsArray();
    const resolvedDefectsCount =
      defectsArray && defectsArray.length > 0
        ? defectsArray.filter((defect) => defect.mx_item.status === 'resolved').length
        : 0;
    const mxItemsArray = this.getMXItemsArray();
    const resolvedMXItemsCount =
      mxItemsArray && mxItemsArray.length > 0
        ? mxItemsArray.filter((mxItem) => mxItem.mx_item.status === 'resolved').length
        : 0;
    let totalSteps = 5;
    if (defectsArray && defectsArray.length === 0) {
      totalSteps -= 1;
    }
    if (mxItemsArray && mxItemsArray.length === 0) {
      totalSteps -= 1;
    }

    const workPackResolved = this.workpackIsResolved();

    if (workPackResolved) {
      releaseId = this.getReleaseId();
    }

    const aircraftAPUEnabled = aircraft ? aircraft.apu_installed : false;
    const mxArray = this.getAircraftMXData();

    const additionalItems = workpack.additional_items || [];
    const aircraftThreshold = aircraft ? aircraft.mx_thresholds : {};

    const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;

    const defectCount = (defectsArray && defectsArray.length) || 0;
    let scheduledCount = 0;
    let oopCount = 0;
    let llpCount = 0;
    if (mxItemsArray) {
      mxItemsArray.forEach((item) => {
        const type =
          item.mx_item.mx_type &&
          item.mx_item.mx_type
            .replace(/^.+_/, '')
            .slice(0, 3)
            .toUpperCase();
        if (type === 'SCH') scheduledCount += 1;
        if (type === 'OOP') oopCount += 1;
        if (type === 'LLP') llpCount += 1;
      });
    }
    const totalCount = defectCount + scheduledCount + oopCount + llpCount;
    const intercomLink = 'https://intercom.help/trustflight/en/articles/5207051-how-to-complete-the-public-crs-page';

    return (
      <PublicPageWrapper intercomLink={intercomLink}>
        <div className={styles.pageDetails}>
          <Row gutter={16} className={styles.pagePadding}>
            <Col md={12} className={styles.colLeft}>
              <div className={styles.pageDetailsTitle} data-test="workpackTitle" data-testid="workpackTitle">
                {formatMessage({ id: 'text.workpack' })}:{' '}
                <span data-test="workpackTitleDetails">
                  {aircraft && aircraft.registration} {workpack && workpack.po_number && `#${workpack.po_number}`}
                </span>
              </div>
            </Col>
            <Col md={12} className={styles.colRight}>
              <div className={styles.pageDetailsSecondary} data-test="workpackDate" data-testid="workpackDate">
                {formatMessage({ id: 'text.dateOpened' })}:{' '}
                <span data-test="workpackDateDetails">{workpack ? workpack.date : '-'}</span>
              </div>
            </Col>
          </Row>
        </div>
        <Loading loading={submitting || loading} contain />
        <Form onSubmit={this.handleSubmit} hideRequiredMark>
          <Row gutter={16} className={`${styles.pagePadding} ${styles.singleRowSection}`}>
            <Col md={24} data-test="aircraftOverviewTitle">
              {this.renderSectionTitle(formatMessage({ id: 'title.aircraftOverview' }))}
            </Col>
            <Col md={24}>
              {aircraft && <AircraftOverview aircraft={aircraft} widgets={mxArray} data-test="aircraftOverview" />}
            </Col>
          </Row>
          {totalCount > 1 && !workPackResolved ? (
            <div className={styles.selectAllBanner} data-testid="infoBanner">
              <div className={styles.infoWrapper}>
                <div className={styles.infoIconWrapper}>
                  <img src={infoIcon} alt="info" />
                </div>
                <span data-testid="infoBannerText">
                  {selectAllText
                    ? formatMessage({ id: 'text.youHaveSelected' })
                    : formatMessage({ id: 'text.melThereAre' })}{' '}
                  {totalCount} items including{' '}
                  {defectCount > 0 &&
                    `${defectCount} ${defectCount === 1 ? 'defect' : 'defects'}${
                      scheduledCount > 0 || llpCount > 0 || oopCount > 0 ? ',' : ''
                    } `}
                  {scheduledCount > 0 &&
                    `${scheduledCount} scheduled ${scheduledCount === 1 ? `item` : `items`}${
                      oopCount > 0 || llpCount > 0 ? ',' : ''
                    } `}
                  {oopCount > 0 && `${oopCount} OOP ${oopCount === 1 ? `` : `'s`}${llpCount > 0 ? ',' : ''} `}
                  {llpCount > 0 && `${llpCount} LLP${llpCount === 1 ? `` : `'s`}`}
                </span>
              </div>
              <div className={styles.selectAllWrapper}>
                <button
                  type="button"
                  onClick={async () => {
                    await new Promise((resolve) => {
                      this.setState({ loading: true }, resolve);
                    });
                    setTimeout(() => {
                      this.handleSelectAll();
                    }, 1000);
                  }}
                  className={styles.selectAllButton}
                  data-testid="selectAllButton"
                >
                  <span>
                    {selectAllText
                      ? formatMessage({ id: 'options.deselectAll' })
                      : formatMessage({ id: 'options.selectAll' })}
                  </span>
                </button>
              </div>
            </div>
          ) : null}
          {defectsArray && defectsArray.length > 0 && (
            <Row gutter={16} className={`${styles.pagePadding} ${styles.singleRowSection} defectsRow`}>
              <Col md={24} data-test="defectsTitle" data-testid="defectsTitle">
                {this.renderSectionTitle(
                  formatMessage({ id: 'title.selectRepairedDefects' }),
                  formatMessage(
                    { id: 'subtitle.step' },
                    { current: mxItemsArray.length > 0 ? totalSteps - 4 : totalSteps - 3, total: totalSteps },
                  ),
                  resolvedDefectsCount > 0,
                  'showResolvedDefects',
                  !!(resolvedDefectsCount > 0 && resolvedDefectsCount !== defectsArray.length),
                  formatMessage({ id: 'title.defects' }),
                  true,
                )}
              </Col>
              <Col md={24}>
                <ExternalSelectableMx
                  type="DEF"
                  items={defectsArray}
                  form={form}
                  setIndeterminate={(value) => this.setSelectableIndeterminate(value)}
                  aircraftThreshold={aircraftThreshold}
                  data-test="ExternalSelectableMXDEF"
                  handleItemResolved={this.handleItemResolved}
                  selectAll={selectAll}
                  selectAllText={selectAllText}
                  handleCheckboxUncheck={this.handleCheckboxUncheck}
                />
              </Col>
            </Row>
          )}
          {mxItemsArray && mxItemsArray.length > 0 && (
            <Row gutter={16} className={`${styles.pagePadding} ${styles.singleRowSection} itemsRow`}>
              <Col md={24} data-test="mxTitle" data-testid="mxTitle">
                {this.renderSectionTitle(
                  formatMessage({ id: 'title.selectCompletedMXItems' }),
                  formatMessage({ id: 'subtitle.step' }, { current: totalSteps - 3, total: totalSteps }),
                  resolvedMXItemsCount > 0,
                  'showResolvedMX',
                  !!(resolvedMXItemsCount > 0 && resolvedMXItemsCount !== mxItemsArray.length),
                  formatMessage({ id: 'title.items' }),
                  defectsArray.length === 0,
                )}
              </Col>
              <Col md={24}>
                <ExternalSelectableMx
                  items={mxItemsArray}
                  hasAPU={aircraftAPUEnabled}
                  form={form}
                  aircraftThreshold={aircraftThreshold}
                  setIndeterminate={(value) => this.setSelectableIndeterminate(value)}
                  data-test="ExternalSelectableMXMX"
                  handleItemResolved={this.handleItemResolved}
                  selectAll={selectAll}
                  selectAllText={selectAllText}
                  handleCheckboxUncheck={this.handleCheckboxUncheck}
                />
              </Col>
            </Row>
          )}
          <Row gutter={16} className={`${styles.pagePadding} ${styles.singleRowSection}`}>
            <Col md={24} data-test="additionalTasks" style={workPackResolved ? { display: 'none' } : null}>
              {this.renderSectionTitle(
                formatMessage({ id: 'title.selectAdditionalTasks' }),
                formatMessage({ id: 'subtitle.step' }, { current: totalSteps - 2, total: totalSteps }),
              )}
            </Col>
            {additionalItems && additionalItems.length > 0 && (
              <Col md={24} style={workPackResolved ? { display: 'none' } : null}>
                <CRSSignature
                  form={form}
                  parentClearSignature={submitting}
                  data-test="crsSignature"
                  signoffAsEngineer={signoffAsEngineer}
                  signatureStatement={signature_statement}
                />
              </Col>
            )}
            <Col md={24} style={workPackResolved ? { display: 'none' } : null}>
              <AdditionalTasks form={form} data-test="additionalTasks" />
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.pagePadding} ${styles.singleRowSection}`}>
            <Col md={24} data-test="personalDetails" style={workPackResolved ? { display: 'none' } : null}>
              {this.renderSectionTitle(
                formatMessage({ id: 'title.enterCompanyPersonalDetails' }),
                formatMessage({ id: 'subtitle.step' }, { current: totalSteps - 1, total: totalSteps }),
              )}
            </Col>
            <Col md={24} id="companyDetails">
              <CompanyPersonalDetails
                toggleSignoffMethod={this.toggleSignoffMethod}
                signoffAsEngineer={signoffAsEngineer}
                uploadParams={{ releaseId, public_url_key, updatePage: this.setNewWorkpackData, workpackId: id }}
                workpackResolved={workPackResolved}
                form={form}
                data-test="personalDetails"
                dateFormat={dateFormat}
              />
            </Col>
          </Row>
          <Row gutter={16} className={`${styles.pagePadding} ${styles.singleRowSection}`}>
            <Col md={24} data-test="signatureCRS" style={workPackResolved ? { display: 'none' } : null}>
              {this.renderSectionTitle(
                formatMessage({ id: signoffAsEngineer ? 'title.signForCRS' : 'title.signToConfirmCRS' }),
                formatMessage({ id: 'subtitle.step' }, { current: totalSteps, total: totalSteps }),
              )}
            </Col>
            <Col md={24} style={workPackResolved ? { display: 'none' } : null}>
              <CRSSignature
                signoffAsEngineer={signoffAsEngineer}
                form={form}
                parentClearSignature={submitting}
                data-test="crsSignature"
                signatureStatement={signature_statement}
              />
            </Col>
          </Row>
          <Row
            gutter={16}
            className={`${styles.pagePadding} ${styles.singleRowSection}`}
            style={workPackResolved ? { display: 'none' } : null}
          >
            <Col md={24}>
              <Button
                type="primary"
                loading={submitting}
                disabled={disabled}
                className={`${globalStyles.standardButton} ${globalStyles.standardButtonWide}`}
                data-test="submitButton"
                data-testid="submitButton"
                onClick={() => this.handleSubmit()}
              >
                {formatMessage({ id: 'form.button.submitCRS' })}
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal
          title={selectAllText ? 'Unresolved Defects' : 'Unresolved Items'}
          visible={modalVisible}
          okText={selectAllText ? 'Add to a new workpack' : formatMessage({ id: 'form.button.continue' })}
          cancelText={selectAllText ? 'Go to defects' : formatMessage({ id: 'form.button.cancel' })}
          onCancel={() => {
            this.setState({ modalVisible: false }, () =>
              setTimeout(() => {
                if (selectAllText) this.scrollToUnresolvedItem();
              }, 500),
            );
          }}
          onOk={this.onModalClose}
        >
          <span>
            {selectAllText
              ? formatMessage({ id: 'text.incompleteReleaseDefects' })
              : formatMessage({ id: 'text.incompleteReleaseWorkpack' })}
          </span>
        </Modal>
        <Row />
      </PublicPageWrapper>
    );
  }
}

const mxPublicWithForm = Form.create()(MXSignoff);

const mxSignoffWithRedux = connect(
  ({ userSettings, workpacks }) => ({
    workpack: workpacks.publicWorkpack || {},
    userSettings,
  }),
  (dispatch) => ({
    getPublicWorkpackDetails: (payload) =>
      dispatch(
        getPublicWorkpackDetails({
          payload,
        }),
      ),
    getPublicDefectDetails: (payload) => dispatch(getPublicDefectDetails({ payload })),
    setPublicWorkpackDetailsWithoutAPI: ({ payload }) =>
      dispatch(
        setPublicWorkpackDetailsWithoutAPI({
          payload,
        }),
      ),
  }),
)(mxPublicWithForm);
export default withRouter(injectIntl(mxSignoffWithRedux));
