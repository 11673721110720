import React from 'react';
import moment from 'moment';
import { timeDifference } from './utils';

// eslint-disable-next-line import/prefer-default-export
export const warnings = (formatMessage) => ({
  offBlocks(flight, prevFlightOnBlocksTime) {
    if (prevFlightOnBlocksTime && flight.time_offblocks < prevFlightOnBlocksTime) {
      return {
        help: formatMessage({ id: 'message.warningOffBlocks' }),
        validateStatus: 'warning',
      };
    }
    return null;
  },
  takeOff(flight) {
    const dif = timeDifference(flight.time_offblocks, flight.time_takeoff);
    if (!Number.isNaN(Number.parseInt(dif, 10)) && dif > 30 * 60) {
      return {
        help: formatMessage({ id: 'message.warningTakeOff' }),
        validateStatus: 'warning',
      };
    }
    return null;
  },
  landing(flight) {
    const dif = timeDifference(flight.time_takeoff, flight.time_landing);
    if (!Number.isNaN(Number.parseInt(dif, 10)) && dif > 18 * 3600) {
      return {
        help: formatMessage({ id: 'message.warningLanding' }),
        validateStatus: 'error',
      };
    }
    return null;
  },
  onBlocks(flight) {
    const dif = timeDifference(flight.time_landing, flight.time_onblocks);
    if (!Number.isNaN(Number.parseInt(dif, 10)) && dif > 30 * 60) {
      return {
        help: formatMessage({ id: 'message.warningOnBlocks' }),
        validateStatus: 'warning',
      };
    }
    return null;
  },
  deicingEndTime(deicing, flight) {
    const help = [];
    const startTime = moment.utc(deicing.start_time, 'HH:mm');
    const endTime = moment.utc(deicing.end_time, 'HH:mm');

    const takeOffDif = timeDifference(endTime, flight.time_takeoff);
    if (!Number.isNaN(takeOffDif) && takeOffDif > 60 * 60) {
      if (takeOffDif > 12 * 60 * 60) {
        help.push(<div key="1">{formatMessage({ id: 'message.warningDeicingTimeAfter' })}</div>);
      } else {
        help.push(
          <div key="2">
            {formatMessage({ id: 'message.warningDeicingTimeBefore' })}{' '}
            {moment.duration(takeOffDif, 'seconds').humanize()}.
          </div>,
        );
      }
    }

    const deicingDif = timeDifference(startTime, endTime);
    if (!Number.isNaN(deicingDif) && deicingDif > 30 * 60) {
      help.push(
        <div key="3">
          {formatMessage({ id: 'message.warningDeicingTime' })} {moment.duration(deicingDif, 'seconds').humanize()}.
        </div>,
      );
    }

    if (help.length > 0) {
      return {
        help,
        validateStatus: 'warning',
      };
    }
    return null;
  },
});
