import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import ItemWrapper from './DraftsList/ItemWrapper';

const IndividualWrapper = ({ item, onSkip, onSuccess, hideSkip, handleSubmittingChange }) => {
  return (
    <>
      {item && (
        <ItemWrapper
          onSuccess={() => onSuccess()}
          key={item.id}
          item={item}
          hideTitle
          showSkip={!hideSkip}
          onSkip={() => onSkip()}
          data-test="itemWrapper"
          handleSubmittingChange={handleSubmittingChange}
        />
      )}
    </>
  );
};

IndividualWrapper.propTypes = {
  item: PropTypes.object.isRequired,
  onSkip: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  hideSkip: PropTypes.bool.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  handleSubmittingChange: PropTypes.func.isRequired,
};

export default injectIntl(IndividualWrapper);
