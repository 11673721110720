import React, { Component } from 'react';
import { Col, Button, Row, Form, Input, Icon } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import globalStyles from '../../utils/globalStyles.module.less';
import styles from './ReplacedParts.module.less';

class ReplacedParts extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    defect: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [
        { serial_off: null, serial_on: null, part_number_on: null, part_number_off: null, batch_lot_number: null },
      ],
    };
  }

  removeElement = (index) => {
    const { defect } = this.props;
    const { data: originalData } = this.state;
    const formValues = this.props.form.getFieldsValue();

    // Remove values from state array
    const data = [...originalData];
    data.splice(index, 1);
    this.setState({ data });

    // Remove values from form instance
    this.props.form.setFieldsValue({ [`replaced_part_part_number_off_${index}`]: null });
    this.props.form.setFieldsValue({ [`replaced_part_part_number_on_${index}`]: null });
    this.props.form.setFieldsValue({ [`replaced_part_task_serial_off_${index}`]: null });
    this.props.form.setFieldsValue({ [`replaced_part_task_serial_on_${index}`]: null });
    this.props.form.setFieldsValue({ [`replaced_part_batch_lot_number_${index}`]: null });

    // Set new values instead of previous
    originalData.forEach((item, i) => {
      if (i >= index) {
        this.props.form.setFieldsValue({
          [`replaced_part_part_number_off_${i}_${defect.id}`]: formValues[
            `replaced_part_part_number_off_${i + 1}_${defect.id}`
          ],
        });
        this.props.form.setFieldsValue({
          [`replaced_part_part_number_on_${i}_${defect.id}`]: formValues[
            `replaced_part_part_number_on_${i + 1}_${defect.id}`
          ],
        });
        this.props.form.setFieldsValue({
          [`replaced_part_serial_off_${i}_${defect.id}`]: formValues[`replaced_part_serial_off_${i + 1}_${defect.id}`],
        });
        this.props.form.setFieldsValue({
          [`replaced_part_serial_on_${i}_${defect.id}`]: formValues[`replaced_part_serial_on_${i + 1}_${defect.id}`],
        });
        this.props.form.setFieldsValue({
          [`replaced_part_batch_lot_number_${i}_${defect.id}`]: formValues[
            `replaced_part_batch_lot_number_${i + 1}_${defect.id}`
          ],
        });
      }
    });
  };

  addElement = () => {
    const { data: stateData, data } = this.state;
    const newData = Array.isArray(stateData) ? [...data] : [];

    newData.push({
      part_number_off: null,
      part_number_on: null,
      serial_off: null,
      serial_on: null,
      batch_lot_number: null,
    });
    this.setState({ data: newData });
  };

  render() {
    const {
      form,
      defect,
      intl: { formatMessage },
    } = this.props;
    const { getFieldDecorator } = form;
    const { data } = this.state;

    const replacedPartsData = data || null;

    let replacedPartsFormItems = null;
    if (data && replacedPartsData) {
      replacedPartsFormItems = replacedPartsData.map((k, index) => {
        return (
          <>
            <Row gutter={16} className={`${styles.formRowFlex} ${styles.partsRowParent}`}>
              <Col md={6}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.partNumberOff' })}
                  className={globalStyles.formItem}
                  data-test="formItemPartNumberOff"
                >
                  {getFieldDecorator(`replaced_part_part_number_off_${index}_${defect.id}`, {
                    rules: [{ required: true, message: formatMessage({ id: 'message.fillInNumber' }) }],
                  })(
                    <Input
                      className={`${globalStyles.formInput}`}
                      placeholder={formatMessage({ id: 'form.placeholder.xxx' })}
                      data-test="formElementPartNumberOff"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.serialOff' })}
                  className={globalStyles.formItem}
                  data-test="formItemSerialOff"
                >
                  {getFieldDecorator(`replaced_part_serial_off_${index}_${defect.id}`, {
                    rules: [{ required: true, message: formatMessage({ id: 'message.fillInNumber' }) }],
                  })(
                    <Input
                      className={`${globalStyles.formInput}`}
                      placeholder={formatMessage({ id: 'form.placeholder.xxx' })}
                      data-test="formElementSerialOff"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className={`${styles.formRowFlex} ${styles.partsRowParentBottom}`}>
              <Col md={6}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.partNumberOn' })}
                  className={globalStyles.formItem}
                  data-test="formItemPartNumberOn"
                >
                  {getFieldDecorator(`replaced_part_part_number_on_${index}_${defect.id}`, {
                    rules: [{ required: true, message: formatMessage({ id: 'message.fillInNumber' }) }],
                  })(
                    <Input
                      className={`${globalStyles.formInput}`}
                      placeholder={formatMessage({ id: 'form.placeholder.xxx' })}
                      data-test="formElementPartNumberOn"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.serialOn' })}
                  className={globalStyles.formItem}
                  data-test="formItemSerialOn"
                >
                  {getFieldDecorator(`replaced_part_serial_on_${index}_${defect.id}`, {
                    rules: [{ required: true, message: formatMessage({ id: 'message.fillInNumber' }) }],
                  })(
                    <Input
                      className={`${globalStyles.formInput}`}
                      placeholder={formatMessage({ id: 'form.placeholder.xxx' })}
                      data-test="formElementSerialOn"
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.batchLotNumber' })}
                  className={globalStyles.formItem}
                  data-test="formItemBatchLotNumber"
                >
                  {getFieldDecorator(`replaced_part_batch_lot_number_${index}_${defect.id}`, {
                    rules: [{ required: true, message: formatMessage({ id: 'message.fillInNumber' }) }],
                  })(
                    <Input
                      className={`${globalStyles.formInput}`}
                      placeholder={formatMessage({ id: 'form.placeholder.xxx' })}
                      data-test="formElementBatchLotNumber"
                    />,
                  )}
                </Form.Item>
              </Col>
              {replacedPartsData.length > 1 && (
                <Col md={5} className={styles.buttonCol}>
                  <Form.Item
                    label={<div className={styles.emptyDiv} />}
                    className={`${globalStyles.formItem} ${styles.whiteLabel}`}
                  >
                    <Button
                      type="danger"
                      onClick={() => this.removeElement(index)}
                      className={globalStyles.standardButton}
                      data-test="removeButton"
                    >
                      <Icon className={styles.deleteIcon} type="minus-circle-o" />{' '}
                      <span>{formatMessage({ id: 'form.button.removePart' })}</span>
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </>
        );
      });
    }

    return (
      <>
        {replacedPartsFormItems}
        <Row gutter={16} className={`${styles.formRowFlex} ${styles.partsRowParent}`}>
          <Col md={6} className={styles.buttonCol}>
            <Form.Item label={replacedPartsData.length > 1 ? '' : ` `} className={globalStyles.formItem}>
              <Button
                type="primary"
                className={`${globalStyles.standardButton} ${globalStyles.formSingleRowButton}`}
                onClick={() => this.addElement()}
                data-test="addButton"
              >
                <Icon type="plus" /> <span>{formatMessage({ id: 'form.button.addPart' })}</span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}

export default injectIntl(ReplacedParts);
