import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import AircraftStatusCollapseContent from '../AircraftStatusCollapseContent/AircraftStatusCollapseContent';
import GreyCrossIcon from '../../assets/cross-grey.svg';
import RedCrossIcon from '../../assets/cross-red.svg';
import GreenTickIcon from '../../assets/tick-green.svg';
import styles from './AircraftStatusTooltip.module.less';

const AircraftStatusTooltip = ({ content, userSettings }) => {
  const collapseContentArray = [];
  let rowContent = {};

  const formatStatus = (status) => {
    if (status === 'not_complete')
      return { title: 'Not Completed', style: styles.checkStatusNotCompleted, icon: GreyCrossIcon };
    if (status === 'overdue') return { title: 'Expired', style: styles.checkStatusExpired, icon: RedCrossIcon };
    if (status === 'open') return { title: 'Valid', style: styles.checkStatusValid, icon: GreenTickIcon };
    if (status === 'critical') return { title: 'Critical', style: styles.checkStatusCritcal, icon: RedCrossIcon };
    return null;
  };

  const formatValidityTimeHours = (time) => {
    if (time) {
      return Math.floor(time);
    }
    return null;
  };

  const formatValidityTime = (time) => {
    if (time) {
      return moment.duration(time, 'hours').humanize();
    }
    return null;
  };

  const tooltipLabel = (validityDates) => {
    if (!validityDates.last_performed && !validityDates.valid) return null;
    if (validityDates.last_performed && !validityDates.valid) {
      return (
        <div>
          <div className={styles.tooltipLabel}>LAST PERFORMED</div>
          <div className={styles.tooltipDate}>{validityDates.last_performed}</div>
        </div>
      );
    }
    if (validityDates.last_performed && validityDates.valid) {
      const formatValid = moment()
        .add(validityDates.valid, 'h')
        .format(userSettings.dateFormat);
      return (
        <div className={styles.tooltipWrapper} data-testid="AircraftStatusTile--Tooltip">
          <div className={styles.lastPerformedWrapper}>
            <div className={styles.tooltipLabel}>LAST PERFORMED</div>
            <div className={styles.tooltipDate}>
              {moment(validityDates.last_performed).format(userSettings.dateFormat)}
            </div>
          </div>
          <div>
            <div className={styles.tooltipLabel}>VALID UNTIL</div>
            <div className={styles.tooltipDate}>{formatValid}</div>
          </div>
        </div>
      );
    }
    return null;
  };

  const rowContentBuilder = (tooltipContent) => {
    return (
      <Tooltip
        title={tooltipLabel(tooltipContent.validFromTo)}
        className={tooltipContent.title === 'EROPS Check' ? styles.contentRowNoPad : styles.contentRow}
        placement="bottom"
        key={tooltipContent.title}
      >
        <AircraftStatusCollapseContent
          collapseContent={tooltipContent}
          popoverContent={tooltipLabel(tooltipContent.validFromTo)}
        />
      </Tooltip>
    );
  };

  if (content.daily) {
    rowContent = {
      title: 'Daily Check',
      status: formatStatus(content.daily.status.status || content.daily.status),
      valid: formatValidityTimeHours(content.daily.valid),
      validFromTo: content.daily,
    };
    const row = rowContentBuilder(rowContent);
    collapseContentArray.push(row);
  }
  if (content.preflight) {
    rowContent = {
      title: 'Preflight Check',
      status: formatStatus(content.preflight.status.status || content.preflight.status),
      valid: formatValidityTime(content.preflight.valid),
      validFromTo: content.preflight,
    };
    const row = rowContentBuilder(rowContent);
    collapseContentArray.push(row);
  }
  if (content.weekly) {
    rowContent = {
      title: 'Weekly Check',
      status: formatStatus(content.weekly.status.status || content.weekly.status),
      valid: formatValidityTime(content.weekly.valid),
      validFromTo: content.weekly,
    };
    const row = rowContentBuilder(rowContent);
    collapseContentArray.push(row);
  }
  if (content.erops) {
    rowContent = {
      title: 'EROPS Check',
      status: formatStatus(content.erops.status.status || content.erops.status),
      valid: formatValidityTime(content.erops.valid),
      validFromTo: content.erops,
    };
    const row = rowContentBuilder(rowContent);
    collapseContentArray.push(row);
  }

  return collapseContentArray;
};

AircraftStatusTooltip.propTypes = {
  content: PropTypes.object.isRequired,
  userSettings: PropTypes.object.isRequired,
};

export default connect(({ userSettings }) => ({ userSettings }))(AircraftStatusTooltip);
