/* eslint-disable react/jsx-props-no-spreading */
import { Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import InputNumberUnits from '../InputNumberUnits';

class OilUplift extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    aircraft: PropTypes.object.isRequired,
    formItemLayout: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    forwardedRef: PropTypes.func.isRequired,
  };

  engine(i) {
    const {
      form,
      aircraft,
      formItemLayout,
      intl: { formatMessage },
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form.Item {...formItemLayout} label={`${formatMessage({ id: 'form.labels.oilUplift' })} E${i}`} key={i}>
        {getFieldDecorator(`engine_${i}`)(<InputNumberUnits min={0} step={0.1} units={aircraft.oil_unit} />)}
      </Form.Item>
    );
  }

  render() {
    const {
      form,
      aircraft,
      formItemLayout,
      forwardedRef,
      intl: { formatMessage },
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        {aircraft.apu_installed && (
          <Form.Item {...formItemLayout} label={formatMessage({ id: 'form.labels.apu' })}>
            {getFieldDecorator('apu')(<InputNumber min={0} step={0.1} ref={forwardedRef} />)}
          </Form.Item>
        )}
        {Array.from({ length: aircraft.aircraft_type ? aircraft.aircraft_type.engine_count : 0 }).map((e, i) =>
          this.engine(i + 1),
        )}
      </>
    );
  }
}

const ComposedOilUplift = compose(
  injectIntl,
  Form.create({
    onValuesChange(props, _, allValues) {
      if (props.onChange) {
        props.onChange(allValues);
      }
    },
    mapPropsToFields({ value = {}, aircraft }) {
      return {
        unit: Form.createFormField({ value: aircraft.oil_unit }),
        apu: Form.createFormField({ value: value.apu }),
        engine_1: Form.createFormField({ value: value.engine_1 }),
        engine_2: Form.createFormField({ value: value.engine_2 }),
        engine_3: Form.createFormField({ value: value.engine_3 }),
        engine_4: Form.createFormField({ value: value.engine_4 }),
      };
    },
  }),
)(OilUplift);

export default forwardRef((props, ref) => <ComposedOilUplift {...props} forwardedRef={ref} />);
