import { BackTop, Dropdown, Icon, Menu, message, Tabs, Tooltip, Button } from 'antd';
import PCancelable from 'p-cancelable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import uuid from 'uuid';
import logo from '../../assets/back-arrow.svg';
import MagnifierSplitView from '../../components/MagnifierSplitView';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import TripDescriptionForm from '../../components/TripDescriptionForm';
import FlightForm from '../../components/FlightForm';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { getFlightDetails, deleteFlightEntry } from '../../models/flights/actions';
import { getTripDetails, storeEphemeralTrip } from '../../models/trips/actions';
import {
  addFlight,
  addFlightAttachment,
  addTrip,
  addTripAttachment,
  deleteAttachment,
  getAttachmentFile,
  updateFlight,
  updateTripDetails,
} from '../../services/api';
import transformAttachment from '../../utils/transformAttachment';
import { isCompleteFlightRecord } from '../../utils/utils';
import Loading from '../../components/TFLoading';
import awaitForPropChange from './awaitForPropChange';
import styles from './style.module.less';
import transformFlightForSubmit from './transformFlightForSubmit';
import uploadNewAttachments from './uploadNewAttachments';
import { flightTimesValidation } from './flightTimesValidation';

const { TabPane } = Tabs;

class EditFlight extends Component {
  static propTypes = {
    // IDs
    aircraftId: PropTypes.string.isRequired,
    flightId: PropTypes.string.isRequired,
    tripId: PropTypes.string.isRequired,
    // Maps
    flightsMap: PropTypes.instanceOf(Map).isRequired,
    tripsMap: PropTypes.instanceOf(Map).isRequired,
    // Objs
    match: PropTypes.shape({
      path: PropTypes.string,
      params: PropTypes.object,
    }).isRequired,
    matchedAircraft: PropTypes.object.isRequired,
    matchedFlight: PropTypes.object.isRequired,
    matchedTrip: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    // Funcs
    createNewFlight: PropTypes.func.isRequired,
    createNewTrip: PropTypes.func.isRequired,
    refetchAircraft: PropTypes.func.isRequired,
    refetchFlight: PropTypes.func.isRequired,
    refetchTrip: PropTypes.func.isRequired,
    deleteFlight: PropTypes.func.isRequired,
    source: PropTypes.string,
    storeEphemeralTrip: PropTypes.func.isRequired,
  };

  static defaultProps = {
    source: undefined,
  };

  constructor(props) {
    super(props);
    const { matchedFlight, matchedTrip } = this.props;
    this.state = {
      attachment: null,
      downloadingFile: false,
      fetchingFlight: false,
      fetchingTrip: false,
      flight: {
        ...matchedFlight,
        attachments: Array.isArray(matchedFlight.attachments) ? matchedFlight.attachments.map(transformAttachment) : [],
      },
      trip: {
        ...matchedTrip,
        captain_id: matchedTrip.captain && matchedTrip.captain.id,
        first_officer_id: matchedTrip.first_officer && matchedTrip.first_officer.id,
        hasUpdated: false,
      },
      preventSubmit: false,
      submitting: false,
    };

    // Initialising non-react component properties
    this.fetchFlightRunningForId = '';
    this.fetchTripRunningForId = '';
  }

  componentDidMount() {
    const {
      tripId,
      flightId,
      matchedTrip,
      aircraftId,
      matchedAircraft,
      refetchAircraft,
      matchedFlight,
      flightsMap,
      history,
    } = this.props;
    const { flight } = this.state;
    if (tripId && matchedTrip.id !== tripId) {
      // We're editing a flight from a trip but we don't have the data at hand yet.
      this.fetchTrip(true);
    }

    if (
      !matchedTrip.id ||
      (Array.isArray(matchedTrip.flights) &&
        !matchedTrip.flights.some((currFlight) => currFlight.id === flightId && flight.id === matchedFlight.id))
    ) {
      // We either don't have a trip or the trip doesn't have the flight details we should be editing here
      if (flightId.startsWith('new-')) {
        // We shouldn't be editing ephemeral flights that are not part of a trip
        // so let's redirect back to a flights/trips table.
        history.push(this.getBaseRoute());
        return;
      }
      this.fetchFlight(true);
    } else if (!flightsMap.has(flightId) || !isCompleteFlightRecord(matchedFlight, false)) {
      // This flight is not part of a trip at all and we need to fetch its details
      this.fetchFlight(true);
    }

    if (
      (!flight || !flight.attachments) &&
      matchedTrip &&
      Array.isArray(matchedTrip.attachments) &&
      matchedTrip.attachments[0]
    ) {
      // Show trip attachment on load
      this.updateAttachment(matchedTrip.attachments[0]);
    }

    if (aircraftId && matchedAircraft.id !== aircraftId) {
      refetchAircraft(aircraftId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { flightId, matchedFlight, matchedTrip, tripId, matchedAircraft, aircraftId, refetchAircraft } = this.props;
    const { fetchingFlight, fetchingTrip, flight, trip } = this.state;

    // Fetch flight if necessary
    if (
      ((flightId && !matchedFlight) || flightId !== prevProps.flightId) &&
      !fetchingFlight &&
      !flightId.startsWith('new-')
    ) {
      this.fetchFlight(true);
    }

    // Fetch trip if necessary
    if (((tripId && !matchedTrip) || tripId !== prevProps.tripId) && !fetchingTrip) {
      this.fetchTrip();
    }

    if (prevProps.matchedFlight && prevProps.matchedFlight.id === flight.id && prevProps.flightId !== flightId) {
      if (flightId.startsWith('new-')) {
        this.resetStateData();
      } else {
        // This shouldn't really happen
        // eslint-disable-next-line no-console
        console.info('Fetched a new flight from a condition that should not trigger');
        this.fetchFlight(true);
      }
    }

    if (
      Array.isArray(matchedTrip.flights) &&
      ((prevState.fetchingFlight && !fetchingFlight) ||
        (prevState.fetchingTrip && !fetchingTrip) ||
        prevState.flight !== flight)
    ) {
      // Something happened with our component state flight object and it needs to be reflected in the Dva store.
      storeEphemeralTrip({
        ...matchedTrip,
        ...trip,
        flights: matchedTrip.flights.map((aFlight) =>
          [matchedFlight.id, flight.id].every((id) => id === aFlight.id)
            ? {
                ...aFlight,
                ...matchedFlight,
                ...flight,
              }
            : aFlight,
        ),
      });

      if (!matchedAircraft.id || matchedAircraft.id !== aircraftId) {
        refetchAircraft(aircraftId);
      }
    }

    // Hide deleted attachments if previewed

    const flightAttachments = (flight && flight.attachments) || [];
    const tripAttachments = (matchedTrip && matchedTrip.attachments) || [];

    if (!flightAttachments.includes(this.currentAttachment) && !tripAttachments.includes(this.currentAttachment)) {
      if (tripAttachments.length > 0 && flightAttachments.length === 0) {
        this.updateAttachment(tripAttachments[0]);
      } else if (flightAttachments.length > 0) {
        this.updateAttachment(flightAttachments[0]);
      }
    }
    if (flightAttachments.length === 0 && tripAttachments.length === 0) {
      this.updateAttachment(null);
    }
  }

  componentWillUnmount() {
    if (this.getAttachmentFilePromise) this.getAttachmentFilePromise.cancel();
  }

  getBaseRoute = () =>
    this.props.aircraftId && this.props.match.path.startsWith('/aircraft/')
      ? // We are viewing this form from an aircraft perspective
        `/aircraft/${this.props.aircraftId}/trips`
      : // We are viewing this form from an operations->flights perpective
        '/operations/flights';

  getFlightDesc = (flight = {}) => `${flight.departure_airport || ''} - ${flight.arrival_airport || ''}`;

  getFlightNeedsSaving = (flight = {}) => !flight.id || flight.id.startsWith('new-') || flight.hasUpdated;

  getOtherFlightsNeedSaving = (flightId, flights) =>
    Object.keys(flights)
      .map((k) => flights[k])
      .some((flight) => flight.id !== flightId && this.getFlightNeedsSaving(flight));

  fetchFlight = async (updateState = false) => {
    const { flightId } = this.props;
    if (flightId.startsWith('new-') || this.fetchFlightRunningForId === flightId) {
      return;
    }
    this.fetchFlightRunningForId = flightId;

    await new Promise((resolve) => {
      this.setState({ fetchingFlight: true }, resolve);
    });
    await this.props.refetchFlight(flightId);
    if (this.fetchFlightRunningForId === flightId) {
      await awaitForPropChange(() => this.props.matchedFlight.id === flightId);
      await new Promise((resolve) => {
        if (updateState) {
          this.setState(
            {
              fetchingFlight: false,
              flight: {
                ...this.props.matchedFlight,
                attachments: Array.isArray(this.props.matchedFlight.attachments)
                  ? this.props.matchedFlight.attachments.map(transformAttachment)
                  : [],
              },
            },
            resolve,
          );
        } else {
          this.setState({ fetchingFlight: false }, resolve);
        }
      });
      this.fetchFlightRunningForId = '';
    }
  };

  fetchTrip = async (updateEphemeralTrip = false) => {
    const { tripId } = this.props;
    if (!tripId || this.fetchTripRunningForId === tripId) {
      return;
    }
    this.fetchTripRunningForId = tripId;

    await new Promise((resolve) => {
      this.setState({ fetchingTrip: true }, resolve);
    });
    await this.props.refetchTrip(tripId);

    await awaitForPropChange(() => tripId === this.props.matchedTrip.id);

    if (updateEphemeralTrip) {
      await this.props.storeEphemeralTrip(this.props.matchedTrip);
    }
    await new Promise((resolve) => {
      this.setState({ fetchingTrip: false }, resolve);
    });
    this.fetchTripRunningForId = '';
  };

  resetStateData = () => {
    this.setState({
      trip: this.props.matchedTrip,
      flight: this.props.matchedFlight,
      fetchingTrip: false,
      fetchingFlight: false,
    });
  };

  getLatestTripData = () => {
    return {
      ...this.props.matchedTrip,
      ...this.state.trip,
      flights: this.props.matchedTrip.flights.map((flight) =>
        flight.id === this.state.flight.id
          ? {
              ...flight,
              ...this.state.flight,
            }
          : flight,
      ),
    };
  };

  updateAttachment = async (attachment) => {
    if (attachment === this.currentAttachment) return;
    this.currentAttachment = attachment;
    if (!attachment) {
      this.setState({ attachment: null });
    } else if (attachment.originFileObj) {
      this.setState({ attachment: attachment.originFileObj });
    } else if (attachment.url) {
      await new Promise((resolve) => {
        this.setState({ downloadingFile: true }, resolve);
      });

      try {
        this.getAttachmentFilePromise = PCancelable.fn(getAttachmentFile)(attachment.url);
        const file = await this.getAttachmentFilePromise;
        // Solve race condition
        if (this.currentAttachment === attachment) {
          await new Promise((resolve) => {
            this.setState({ attachment: file, downloadingFile: false }, resolve);
          });
        }
      } finally {
        if (this.state.downloadingFile) {
          await new Promise((resolve) => {
            this.setState({ downloadingFile: false }, resolve);
          });
        }
      }
    }
  };

  createNewTripAndFlight = async () => {
    const { flight, trip } = this.state;
    const latestTrip = {
      ...this.props.matchedTrip,
      ...trip,
    };
    const newTrip = await this.props.createNewTrip(latestTrip, this.props.aircraftId);
    const newFlight = await this.props.createNewFlight(newTrip, flight);
    const oldId = flight.id;
    await new Promise((resolve) => {
      this.setState(
        {
          trip: {
            ...trip,
            ...newTrip,
            hasUpdated: false,
          },
          flight: {
            ...flight,
            ...newFlight,
            // Attachments should be preserved from the old state otherwise they won't be uploaded below.
            attachments: flight.attachments,
            hasUpdated: false,
          },
        },
        resolve,
      );
    });

    await this.props.storeEphemeralTrip({
      ...this.props.matchedTrip,
      ...trip,
      id: newTrip.id,
      flights: this.props.matchedTrip.flights.map((aFlight) =>
        aFlight.id !== oldId
          ? aFlight
          : {
              ...this.state.flight,
              // This oldId is used to match the flight object in the connect method below once props get updated
              oldId,
            },
      ),
    });
    await awaitForPropChange(
      () =>
        this.props.matchedTrip.flights.some((aFlight) => aFlight.id === this.state.flight.id) &&
        this.props.matchedTrip.id === newTrip.id,
    );

    // Upload trip attachments
    if (this.props.matchedTrip.attachments) {
      await uploadNewAttachments(addTripAttachment, this.props.matchedTrip.id, this.props.matchedTrip.attachments);
    }

    // Upload flight attachments
    if (this.state.flight.attachments) {
      await uploadNewAttachments(addFlightAttachment, this.state.flight.id, this.state.flight.attachments);
    }
  };

  updateExistingTrip = async () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const newTrip = this.getLatestTripData();
    const params = {
      id: newTrip.id,
      trip: {
        date: newTrip.date.format('YYYY-MM-DD'),
        callsign: newTrip.callsign,
        captain_id: newTrip.captain_id,
        first_officer_id: newTrip.first_officer_id,
      },
    };

    // Send new trip object to backend.
    const updatedTrip = await updateTripDetails(params);
    if (updatedTrip.statusCode > 199 && updatedTrip.statusCode < 400) {
      await new Promise((resolve) =>
        this.setState(
          {
            trip: {
              ...updatedTrip.body,
              flights: newTrip.flights,
              date: moment.utc(updatedTrip.body.date),
              hasUpdated: false,
            },
          },
          resolve,
        ),
      );
      // Store the current record in the global store so we can reset the local state.
      await this.props.storeEphemeralTrip({
        ...updatedTrip.body,
      });
    } else {
      message.error(formatMessage({ id: 'text.failedToUpdateTrip' }));
    }
  };

  createNewFlight = async () => {
    const { flight } = this.state;
    const newFlight = await this.props.createNewFlight(this.props.matchedTrip, flight);
    const oldId = this.state.flight.id;
    await new Promise((resolve) => {
      this.setState(
        {
          flight: {
            ...flight,
            ...newFlight,
            // Attachments should be preserved from the old state otherwise they won't be uploaded below.
            attachments: flight.attachments,
            hasUpdated: false,
          },
        },
        resolve,
      );
    });
    await this.props.storeEphemeralTrip({
      ...this.props.matchedTrip,
      ...this.state.trip,
      flights: this.props.matchedTrip.flights.map((aFlight) =>
        aFlight.id !== oldId
          ? aFlight
          : {
              ...this.state.flight,
              // This oldId is used to match the flight object in the connect method below once props get updated
              oldId,
            },
      ),
    });
    await awaitForPropChange(() =>
      this.props.matchedTrip.flights.some((aFlight) => aFlight.id === this.state.flight.id),
    );

    // Upload flight attachments
    if (this.state.flight.attachments) {
      await uploadNewAttachments(addFlightAttachment, this.state.flight.id, this.state.flight.attachments);
    }
  };

  updateExistingFlight = async () => {
    // Delete removed attachments
    const originalFlightObject = this.props.flightsMap.get(this.props.flightId);
    if (originalFlightObject) {
      const newAttachmentUIds = this.state.flight.attachments.map((att) => att.id);
      await Promise.all(
        originalFlightObject.attachments.map((att) =>
          newAttachmentUIds.includes(att.id)
            ? // If this attachment is still used we just need to resolve a blank promise
              Promise.resolve()
            : // Catch and silence any network errors so the Promise.all doesn't throw
              // errors if any of the promises fail.
              deleteAttachment(att.id).catch((reason) => {
                // eslint-disable-next-line no-console
                console.error(reason);
                return Promise.resolve();
              }),
        ),
      );
    }

    // Upload new attachments
    if (Array.isArray(this.state.flight.attachments)) {
      const existingAttachmentUIds =
        originalFlightObject && Array.isArray(originalFlightObject.attachments)
          ? originalFlightObject.attachments.map((att) => att.id)
          : [];
      const newAttachmentIds = this.state.flight.attachments.filter((att) => !existingAttachmentUIds.includes(att.id));
      await uploadNewAttachments(addFlightAttachment, this.state.flight.id, newAttachmentIds);
    }

    // Prepare the flight state for submitting
    const editedFlight = transformFlightForSubmit(this.state.flight);

    // Update the fuel uplifts
    if (originalFlightObject) {
      const deletedUplifts = originalFlightObject.fuel_uplifts.filter(
        (originalFlight) => !this.state.flight.fuel_uplifts.some((stateFlight) => originalFlight.id === stateFlight.id),
      );
      for (const deletedUplift of deletedUplifts) {
        editedFlight.fuel_uplifts.push({
          id: deletedUplift.id,
          _destroy: true,
        });
      }
    }
    const params = {
      id: this.state.flight.id,
      flight: editedFlight,
    };

    // Send new flight object to backend.
    const updatedFlight = await updateFlight(params);
    await new Promise((resolve) => {
      this.setState(
        {
          flight: {
            ...updatedFlight,
            // Attachments should be preserved from the old state otherwise they won't be uploaded below.
            attachments: updatedFlight.attachments.map(transformAttachment),
            hasUpdated: false,
          },
          fetchingFlight: false,
        },
        resolve,
      );
    });

    // Send the updated flight response data to our trip object.
    await this.props.storeEphemeralTrip({
      ...this.props.matchedTrip,
      ...this.state.trip,
      flights: this.props.matchedTrip.flights.map((flight) =>
        flight.id !== updatedFlight.id ? flight : { ...this.state.flight },
      ),
    });
  };

  handleSubmit = async (finish) => {
    const {
      intl: { formatMessage },
      history,
      matchedTrip,
      flightId,
    } = this.props;
    const { flight, trip, preventSubmit } = this.state;

    // Return if submit is blocked by incomplete fields, or there are other flights to save
    if (preventSubmit) {
      message.error(formatMessage({ id: 'text.correctErrors' }));
      // Smooth scroll up with fallback
      if ('scrollBehavior' in document.documentElement.style) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        window.scrollTo(0, 0);
      }
      return;
    }
    const otherFlightsToSave = this.getOtherFlightsNeedSaving(flightId, matchedTrip.flights);
    if (finish && otherFlightsToSave) {
      message.error(formatMessage({ id: 'text.otherUnsavedFlights' }));
    }

    // Prevent users from navigating away if the form is submitting.
    const unloadPrevent = (e) => {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', unloadPrevent);

    await new Promise((resolve) => {
      this.setState({ submitting: true }, resolve);
    });

    if (matchedTrip.id === 'new') {
      // If this is a new trip, create a new trip and flight
      await this.createNewTripAndFlight();
    } else {
      if (flight.id.startsWith('new-')) {
        await this.createNewFlight();
      } else if (flight.hasUpdated) {
        await this.updateExistingFlight();
      }
      if (trip.hasUpdated) {
        // If the trip details have changed, update the trip
        await this.updateExistingTrip();
      }
    }

    // Navigate back to the previous screen
    if (finish && !otherFlightsToSave) {
      history.push(this.getBaseRoute());
    } else if (this.state.flight && this.state.flight.hasUpdated !== true) {
      history.push(`${this.getBaseRoute()}/${this.state.flight.id}/edit`);
    }
    // Smooth scroll up with fallback
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }

    // Finish up the submission process.
    await new Promise((resolve) => {
      this.setState(
        {
          submitting: false,
          fetchingFlight: !isCompleteFlightRecord(this.props.matchedFlight, false),
          fetchingTrip: false,
        },
        resolve,
      );
    });
    window.removeEventListener('beforeunload', unloadPrevent);
  };

  handleDelete = async (flightId) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { flights } = this.props.matchedTrip;

    const unsavedFlight = flights.some((flight) => flight.id.startsWith('new-'));
    if (unsavedFlight && !flightId.startsWith('new-')) {
      message.error(formatMessage({ id: 'message.pleaseSaveNewFlight' }));
      return false;
    }
    // Do nothing if current trip has only one flight (should be impossible to get here)
    if (flights && flights.length < 2) return false;

    // If the flight is not new, dispatch delete request
    if (!this.state.flight.id.startsWith('new-')) {
      await this.props.deleteFlight({ id: flightId });
    }
    // Move to previous flight tab & refresh
    let index = flights.findIndex((flight) => flight.id === flightId);
    index = index === 0 ? 1 : index - 1;
    const { id } = flights[index];
    this.props
      .storeEphemeralTrip({
        ...this.props.matchedTrip,
        ...this.state.trip,
        flights: flights.filter((flight) => flight.id !== flightId),
      })
      .then(() => {
        this.resetStateData();
        this.props.history.push(`${this.getBaseRoute()}/${id}/edit`);
      });
    return false;
  };

  flightChange = (newFlightData = {}) => {
    const date = this.state?.trip?.date ? moment.utc(this.state.trip.date) : moment.utc(this.props.matchedTrip.date);
    const { flight } = this.state;

    // If attachment added, change to newest
    const flightAttachments = (flight && flight.attachments) || [];
    if (newFlightData.attachments && newFlightData.attachments.length > flightAttachments.length) {
      this.updateAttachment(newFlightData.attachments[newFlightData.attachments.length - 1]);
    }

    // Get time of previous blocks on
    const previousBlocksOnTime = this.getPrevBlockOnTime(flight.id);

    // Validate the flight times
    const flightData = {
      ...flight,
      ...newFlightData,
    };

    const validatedFlightData = flightTimesValidation(flightData, date, previousBlocksOnTime);

    this.setState({
      flight: {
        ...validatedFlightData,
        hasUpdated: true,
      },
    });
  };

  tripChange = (newTripData) => {
    const { matchedTrip, matchedFlight } = this.props;
    const { trip, flight } = this.state;

    // Prevent an undefined date from being stored.
    const oldDate = trip.date || matchedTrip.date;

    const validatedTripData = {
      ...matchedTrip,
      ...trip,
      ...newTripData,
      date: newTripData.date || oldDate,
      captain: {
        id: newTripData.captain_id,
      },
      first_officer: {
        id: newTripData.first_officer_id,
      },
    };

    // Prevent submit if the trip date, callsign or PIC is invalid
    if (!newTripData.date || !newTripData.date.isValid() || !newTripData.callsign || !newTripData.captain_id) {
      this.setState({ preventSubmit: true });
    } else {
      this.setState({ preventSubmit: false });
    }

    // Reset flight date to start of day (otherwise will apply current time!)
    validatedTripData.date = moment.utc(validatedTripData.date).startOf('day');

    const tripDate = moment.utc(trip.date) || moment.utc(matchedTrip.date).startOf('day');

    // If the date has changed, validate and save all the flights
    if (newTripData.date && !newTripData.date.isSame(tripDate, 'date')) {
      validatedTripData.flights.forEach((flt, index) => {
        const previousBlocksOnTime =
          index > 0 && validatedTripData.flights[index - 1].time_onblocks
            ? validatedTripData.flights[index - 1].time_onblocks
            : validatedTripData.date;
        const validatedFlightTimes =
          flight.id && flt.id === flight.id
            ? flightTimesValidation(flight, newTripData.date, previousBlocksOnTime)
            : flightTimesValidation(flt, newTripData.date, previousBlocksOnTime);

        // Update the current flight to refresh the dates on the times
        if (flt.id === this.props.flightId) {
          this.setState({
            flight: {
              ...matchedFlight,
              ...flight,
              ...validatedFlightTimes,
              hasUpdated: true,
            },
          });
        }

        // Update all flights in the trip and mark as unsaved
        validatedTripData.flights[index] = {
          ...matchedTrip.flights[index],
          ...validatedFlightTimes,
          hasUpdated: true,
        };
      });
      // Update the ephemeral trip store
      this.props.storeEphemeralTrip({
        ...validatedTripData,
      });
    }

    this.setState({
      trip: {
        ...validatedTripData,
        hasUpdated: true,
      },
    });
  };

  // File uploaded via split view
  fileUpload = (files) => {
    const { flightId } = this.props;
    const { flight } = this.state;
    if (!flightId || !flight || flight.id !== flightId) return;
    const attachments = [...flight.attachments, ...files.map((file) => ({ ...file, status: 'newfile' }))];
    this.flightChange({
      ...flight,
      attachments,
    });
  };

  handlePreview = (file) => {
    if (file.url) {
      window.open(file.url, '_blank');
    } else if (file.originFileObj) {
      this.updateAttachment(file);
    }
  };

  checkPrevAirport = (id) => {
    const { flights } = this.props.matchedTrip;
    if (flights && flights.length > 0) {
      const flight = flights.find((item) => item.id === id);
      const flightIndex = flights.indexOf(flight);

      if (flightIndex > 0 && flight.departure_airport) {
        // It's not the first flight, so we should check if DEP match previous arrival
        const prevArrival = flights[flightIndex - 1].arrival_airport;
        const currDeparture = flight.departure_airport;
        if (prevArrival !== currDeparture) {
          return true;
        }
      }
    }
    return false;
  };

  getPrevBlockOnTime = (id) => {
    const date = this.state?.trip?.date
      ? moment.utc(this.state.trip.date)
      : moment.utc(this.props.matchedTrip.date).startOf('day');
    const trip = {
      ...this.props.matchedTrip,
      ...this.state.trip,
      date,
    };
    if (trip.flights && trip.flights.length > 1) {
      const flight = trip.flights.find((item) => item.id === id);
      const flightIndex = trip.flights.indexOf(flight);
      if (flightIndex > 0 && trip.flights[flightIndex - 1].time_onblocks) {
        // Return the previous flight's block on time (which includes the date)
        return moment.utc(trip.flights[flightIndex - 1].time_onblocks);
      }
    }
    // Fallback to the trip date
    return trip.date;
  };

  openTab = async (id) => {
    // Store the current record in the global store so we can reset the local state.
    const newTrip = this.getLatestTripData();
    await this.props.storeEphemeralTrip(newTrip);

    // Navigate to the next page and refresh the current component state
    this.props.history.push(`${this.getBaseRoute()}/${id}/edit`);
    await awaitForPropChange(() => this.props.flightId === id);
    await this.fetchFlight(true);
  };

  editTab = (flightId, action) => {
    if (action === 'add') {
      const { matchedTrip } = this.props;
      // Set departure airport to previous arrival airport
      const lastFlight = matchedTrip.flights[matchedTrip.flights.length - 1];
      const hasArrivalAirport = lastFlight && lastFlight.arrival_airport && lastFlight.arrival_airport_id;
      const id = `new-${uuid.v4()}`;

      const newFlight = {
        id,
        departure_airport: hasArrivalAirport ? lastFlight.arrival_airport : undefined,
        departure_airport_id: hasArrivalAirport ? lastFlight.arrival_airport_id : undefined,
        attachments: [],
      };

      const newTrip = this.getLatestTripData();

      this.props
        .storeEphemeralTrip({
          ...newTrip,
          flights: [...newTrip.flights, newFlight],
        })
        .then(() => {
          this.resetStateData();
          this.props.history.push(`${this.getBaseRoute()}/${id}/edit`);
        });
    } else if (action === 'remove') {
      this.handleDelete(flightId);
    }
  };

  attachmentDropdown = () => {
    const {
      matchedTrip,
      intl: { formatMessage },
    } = this.props;
    const { flight } = this.state;
    const menuItem = (file) => (
      <Menu.Item key={file.uid} onClick={() => this.updateAttachment(file)}>
        {file.name}
      </Menu.Item>
    );
    const tripAtts =
      matchedTrip && matchedTrip.attachments ? matchedTrip.attachments.map(transformAttachment).map(menuItem) : [];
    const flightAtts = flight && flight.attachments ? flight.attachments.map(menuItem) : [];
    if (flightAtts.length === 0 && tripAtts.length === 0) {
      return null;
    }

    return (
      <Dropdown
        placement="bottomRight"
        overlay={
          <Menu className={styles.dropdown}>
            {tripAtts.length > 0 && (
              <Menu.ItemGroup title={formatMessage({ id: 'title.tripAttachments' })}>{tripAtts}</Menu.ItemGroup>
            )}
            {flightAtts.length > 0 && (
              <Menu.ItemGroup title={formatMessage({ id: 'title.flightAttachments' })}>{flightAtts}</Menu.ItemGroup>
            )}
          </Menu>
        }
      >
        <Button type="link">
          {formatMessage({ id: 'text.view' })} <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  flightPanel = (flight, index, tripFlights, prevAirportMatch) => {
    const {
      intl: { formatMessage },
      flightId,
    } = this.props;

    const previousAirport = tripFlights.length > 0 && index > 0 ? tripFlights[index - 1].arrival_airport : null;
    const deletable = flight.id && tripFlights.length > 1;
    const hasFlightUpdated =
      this.state.flight && this.state.flight.id === flight.id ? this.state.flight.hasUpdated : flight.hasUpdated;

    return (
      <TabPane
        tab={
          <>
            {hasFlightUpdated && (
              <Tooltip title={formatMessage({ id: 'title.changesNotSaved' })}>
                <Icon type="warning" />
              </Tooltip>
            )}
            {this.getFlightDesc(flight)}
          </>
        }
        key={flight.id}
        closable={deletable && flight.id.startsWith('new-')}
      >
        {!this.state.fetchingFlight && this.state.flight && flightId === flight.id ? (
          <FlightForm
            aircraft={this.props.matchedAircraft}
            flight={this.state.flight}
            onAttachmentPreview={this.handlePreview}
            onChange={this.flightChange}
            onSubmit={this.handleSubmit}
            onDelete={deletable ? this.editTab : undefined}
            submitting={this.state.submitting}
            previousAirport={previousAirport}
            showFinish
            depError={!prevAirportMatch}
            preventSubmit={this.state.preventSubmit}
          />
        ) : (
          <Loading loading />
        )}
      </TabPane>
    );
  };

  render() {
    const {
      matchedTrip,
      aircraftId,
      flightId,
      intl: { formatMessage },
      source,
      matchedAircraft,
      tripsMap,
      tripId,
    } = this.props;
    const { fetchingTrip, submitting, attachment, downloadingFile, trip } = this.state;
    const loading = !matchedTrip.id || fetchingTrip;
    const prevAirportMatch = loading || !this.checkPrevAirport(flightId);
    let linkTo;
    if (aircraftId && source) {
      linkTo = `/aircraft/${aircraftId}/${source}`;
    } else if (aircraftId && this.getBaseRoute().startsWith('/aircraft')) {
      linkTo = `/aircraft/${aircraftId}/trips`;
    } else {
      linkTo = '/operations/flights';
    }

    if (!matchedTrip.number && tripsMap.get(tripId)) matchedTrip.number = tripsMap.get(tripId).number;

    // Latest trip data is the matchedTrip overridden by anything held in the trip state.
    const latestTripData = {
      ...matchedTrip,
      ...trip,
      date: trip.date ? moment.utc(trip.date) : moment.utc(matchedTrip.date),
    };

    if (!latestTripData.number && matchedTrip.number) latestTripData.number = matchedTrip.number;

    let tripNumber = `${formatMessage({ id: 'title.edittingFlightOnTrip' })} ${formatMessage({
      id: 'text.newCaps',
    })}`;
    if (latestTripData.number)
      tripNumber = `${formatMessage({ id: 'title.edittingFlightOnTrip' })} ${latestTripData.number}`;
    if (tripsMap.get(tripId) && tripsMap.get(tripId).number)
      tripNumber = `${formatMessage({ id: 'title.edittingFlightOnTrip' })} ${tripsMap.get(tripId).number}`;
    return (
      <>
        <BackTop />
        {!loading && !submitting ? (
          <PageHeaderWrapper
            logo={
              <Link to={linkTo}>
                <img className={styles.backIcon} alt="back arrow" src={logo} />
              </Link>
            }
            title={tripNumber}
            content={
              <TripDescriptionForm aircraft={matchedAircraft} trip={latestTripData} onChange={this.tripChange} />
            }
            className={styles.headerWrapper}
          >
            <MagnifierSplitView
              onUpload={this.fileUpload}
              file={attachment}
              leftCol={this.attachmentDropdown()}
              loading={downloadingFile}
              offsetTop={aircraftId ? 88 : undefined}
              draggerProps={{ disabled: !flightId }}
            >
              <Tabs type="editable-card" activeKey={flightId} onChange={this.openTab} onEdit={this.editTab}>
                {latestTripData.flights.map((flight, index, arr, date) =>
                  this.flightPanel(flight, index, arr, date, prevAirportMatch),
                )}
              </Tabs>
            </MagnifierSplitView>
          </PageHeaderWrapper>
        ) : (
          <Loading loading />
        )}
      </>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    ({ aircraft, flights, trips }, { match: { params } }) => {
      const flightId =
        // If we are processing a new flight submission, we need that to take priority over params.flight
        (Array.isArray(trips.ephemeralTrip.flights) &&
          trips.ephemeralTrip.flights.reduce(
            (acc, flight) => (flight.oldId === params.flight ? flight.id : acc),
            null,
          )) ||
        // In all other cases a flight ID should only be derived from the URL params.
        params.flight;
      if (!flightId) {
        throw new Error('No valid Flight ID could be found');
      }

      // If we have an ephemeral trip record, try and fetch a flight from there.
      const ephemeralFlight =
        (trips.ephemeralTrip &&
          trips.ephemeralTrip.flights &&
          trips.ephemeralTrip.flights.find((flight) => flight.id === flightId)) ||
        {};

      // Try to look for the flight in flights map
      const mapFlight = flights.flightsMap.get(flightId) || {};

      // Merge the two results with the ephemeral flight record taking precedence,
      // so that if there were any edits those are accounted for.
      const matchedFlight = {
        ...mapFlight,
        ...ephemeralFlight,
      };

      const tripId =
        // Look for a trip ID in the matched flight record
        (params.flight && matchedFlight.trip && matchedFlight.trip.id) ||
        // If we have a matched flight but no trip yet, we can have a few scenarios
        // 1. This is a new trip that we're adding in
        (trips.ephemeralTrip && trips.ephemeralTrip.id === 'new' && trips.ephemeralTrip.id) ||
        // 2. This is a new flight and the trip has just been saved so we need the new tripId to continue
        // the submission process
        (trips.ephemeralTrip &&
          Array.isArray(trips.ephemeralTrip.flights) &&
          trips.ephemeralTrip.flights.some((flight) => flight.id === flightId) &&
          trips.ephemeralTrip.id) ||
        // 3. We're yet to fetch a trip from the server so fallback to empty string
        '';

      const matchedTrip =
        // First try to use the ephemeral trip object as it should have priority here
        (tripId === trips.ephemeralTrip.id && trips.ephemeralTrip) ||
        // Otherwise grab a new one from the store
        trips.tripsMap.get(tripId) ||
        // fallback to an empty object as we don't yet have a valid trip we can show.
        {};

      // Calculate a suitable aircraft id
      const aircraftId =
        // If we have one from the URL params, use that
        params.id ||
        // If not then try to use one attached to the flight being edited
        (matchedFlight && matchedFlight.aircraft && matchedFlight.aircraft.id) ||
        // Lastly try to grab one from the associated trip object
        (matchedTrip && matchedTrip.aircraft_id) ||
        // Fallback to empty string
        '';

      const matchedAircraft =
        // Aircraft objects can either come only come from the store
        aircraft.aircraftMap.get(aircraftId) ||
        // If that fails we fallback to empty object and refetch after the component mounts.
        {};

      const { aircraftMap } = aircraft;
      const { flightsMap } = flights;
      const { tripsMap, source } = trips;

      return {
        // Ids
        aircraftId,
        flightId,
        tripId,
        // Objects
        matchedAircraft,
        matchedFlight,
        matchedTrip,
        // Maps of objects
        aircraftMap,
        flightsMap,
        tripsMap,
        source,
      };
    },
    (dispatch) => ({
      createNewFlight: async (trip = {}, flight = {}) => {
        // Create new flight
        const newFlight = transformFlightForSubmit(flight);
        const params = {
          id: trip.id,
          flight: newFlight,
        };
        return addFlight(params);
      },
      createNewTrip: async (trip, aircraftId) => {
        const tripBody = {
          id: aircraftId,
          trip: {
            date: trip.date,
            callsign: trip.callsign,
            captain_id: trip.captain_id,
            first_officer_id: trip.first_officer_id,
          },
        };
        return addTrip(tripBody);
      },
      refetchAircraft: async (payload) => dispatch(getSingleAircraft({ payload })),
      refetchTrip: async (payload) => dispatch(getTripDetails({ payload })),
      refetchFlight: async (payload) =>
        typeof payload === 'string' && !payload.startsWith('new-') && dispatch(getFlightDetails({ payload })),
      storeEphemeralTrip: async (payload) => dispatch(storeEphemeralTrip({ payload })),
      deleteFlight: async (payload) => dispatch(deleteFlightEntry({ payload })),
    }),
  ),
)(EditFlight);
