import React, { Component } from 'react';
import { Col, Button, Row, Form, Input, message } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ResolutionCard from '../DefectCard/ResolutionCard';
import globalStyles from '../../utils/globalStyles.module.less';
import ReplacedParts from './ReplacedParts';
import styles from './DefectResolution.module.less';

const { TextArea } = Input;

class DefectResolution extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    defect: PropTypes.object.isRequired,
    isEdit: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    setIndeterminate: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      resolutionType: 'inspected',
      limitations: false,
      isSaved: false,
      resolutionDetails: null,
    };
  }

  changeResolutionType = (type) => {
    const { form, defect } = this.props;
    this.setState({
      resolutionType: type,
    });
    form.setFieldsValue({
      [`resolution_type_${defect.id}`]: type,
    });
  };

  changeLimitations = (value) => {
    const { form, defect } = this.props;
    this.setState({
      limitations: value,
    });
    form.setFieldsValue({
      [`limitations_${defect.id}`]: value,
    });
  };

  saveResolution = () => {
    const {
      form,
      defect,
      intl: { formatMessage },
    } = this.props;

    // Set form values with defaults if user hasn't clicked on buttons
    form.validateFields([`resolution_type_${defect.id}`, `limitations_${defect.id}`], (errors) => {
      if (errors) {
        form.setFieldsValue({
          [`limitations_${defect.id}`]: this.state.limitations,
          [`resolution_type_${defect.id}`]: this.state.resolutionType,
        });
      }
    });

    // Set array of fields to validate - Single Defect Resolution only
    const fieldsArray = [
      `resolution_type_${defect.id}`,
      `limitations_${defect.id}`,
      `details_${defect.id}`,
      `limitations_details_${defect.id}`,
    ];
    const replacedPartsMaxNo = 100;
    for (let i = 0; i <= replacedPartsMaxNo; i += 1) {
      fieldsArray.push(`replaced_part_part_number_on_${i}_${defect.id}`);
      fieldsArray.push(`replaced_part_part_number_off_${i}_${defect.id}`);
      fieldsArray.push(`replaced_part_serial_off_${i}_${defect.id}`);
      fieldsArray.push(`replaced_part_serial_on_${i}_${defect.id}`);
      fieldsArray.push(`replaced_part_batch_lot_number_${i}_${defect.id}`);
    }
    form.validateFields(fieldsArray, (errors, values) => {
      if (!errors) {
        message.success(formatMessage({ id: 'message.resolutionDetailsSaved' }));
        this.setState({
          isSaved: true,
          resolutionDetails: values,
        });

        if (this.props.onSave) this.props.onSave();
      }
    });
  };

  editResolution = () => {
    this.setState({
      isSaved: false,
    });
    if (this.props.setIndeterminate) this.props.setIndeterminate();
  };

  render() {
    const { resolutionType, limitations, resolutionDetails, isSaved } = this.state;
    const {
      form,
      defect,
      isEdit,
      intl: { formatMessage },
    } = this.props;
    const defectID = defect.id;
    const { getFieldDecorator } = form;
    return (
      <>
        <Row
          gutter={16}
          className={`${styles.parentRow} ${((isEdit && isSaved) || (!isEdit && !isSaved)) && styles.hiddenRow}`}
          data-testid="defectResolution"
        >
          <Col md={8}>
            <Row gutter={16} className={styles.formRow}>
              <Col md={24}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.resolutionDetails' })}
                  className={globalStyles.formItem}
                  data-test="formItemResolutionDetails"
                  data-testid="formItemResolutionDetails"
                />
              </Col>
            </Row>
          </Col>
          <Col md={14}>
            <Row gutter={16} className={styles.formRow}>
              <Col md={24}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.resolutionType' })}
                  className={globalStyles.formItem}
                  data-test="formItemResolutionType"
                >
                  {getFieldDecorator(`resolution_type_${defectID}`, {
                    rules: [{ required: true, message: formatMessage({ id: 'message.selectResolutionType' }) }],
                  })(
                    <>
                      <Button
                        className={`${globalStyles.buttonRadioGroup} ${resolutionType === 'inspected' &&
                          globalStyles.buttonRadioGroupActive}`}
                        onClick={() => this.changeResolutionType('inspected')}
                        data-test="formElementButtonInspected"
                      >
                        {formatMessage({ id: 'form.button.inspected' })}
                      </Button>
                      <Button
                        className={`${globalStyles.buttonRadioGroup} ${resolutionType === 'replaced' &&
                          globalStyles.buttonRadioGroupActive}`}
                        onClick={() => this.changeResolutionType('replaced')}
                        data-test="formElementButtonReplaced"
                      >
                        {formatMessage({ id: 'form.button.partReplaced' })}
                      </Button>
                      <Button
                        className={`${globalStyles.buttonRadioGroup} ${resolutionType === 'other' &&
                          globalStyles.buttonRadioGroupActive}`}
                        onClick={() => this.changeResolutionType('other')}
                        data-test="formElementButtonOther"
                      >
                        {formatMessage({ id: 'form.button.otherTask' })}
                      </Button>
                    </>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {resolutionType === 'replaced' && <ReplacedParts form={form} defect={defect} data-test="replacedParts" />}
            <Row gutter={16} className={styles.formRow}>
              <Col md={24}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.details' })}
                  className={globalStyles.formItem}
                  data-test="formItemDetails"
                >
                  {getFieldDecorator(`details_${defectID}`, {
                    rules: [{ required: true, message: formatMessage({ id: 'message.detailsResolution' }) }],
                  })(
                    <TextArea
                      className={globalStyles.formTextArea}
                      spellCheck="false"
                      placeholder={formatMessage({ id: 'form.placeholder.enterDetails' })}
                      rows={4}
                      data-test="formElementDetails"
                      data-testid="formElementDetails"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className={styles.formRow}>
              <Col md={24}>
                <Form.Item
                  label={formatMessage({ id: 'form.labels.limitations' })}
                  className={globalStyles.formItem}
                  data-test="formItemLimitations"
                >
                  {getFieldDecorator(`limitations_${defectID}`, {
                    rules: [{ required: true, message: formatMessage({ id: 'message.setLimitations' }) }],
                  })(
                    <>
                      <Button
                        className={`${globalStyles.buttonRadioGroup} ${limitations &&
                          globalStyles.buttonRadioGroupActive}`}
                        onClick={() => this.changeLimitations(true)}
                        data-test="formElementButtonYes"
                      >
                        {formatMessage({ id: 'form.button.yes' })}
                      </Button>
                      <Button
                        className={`${globalStyles.buttonRadioGroup} ${!limitations &&
                          globalStyles.buttonRadioGroupActive}`}
                        onClick={() => this.changeLimitations(false)}
                        data-test="formElementButtonNo"
                      >
                        {formatMessage({ id: 'form.button.no' })}
                      </Button>
                    </>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {limitations && (
              <Row gutter={16} className={styles.formRow}>
                <Col md={24}>
                  <Form.Item
                    label={formatMessage({ id: 'form.labels.limitationsSpecify' })}
                    className={globalStyles.formItem}
                    data-test="formItemLimitationsDetails"
                  >
                    {getFieldDecorator(`limitations_details_${defectID}`, {
                      rules: [{ required: true, message: formatMessage({ id: 'message.limitationsDetails' }) }],
                    })(
                      <TextArea
                        className={globalStyles.formTextArea}
                        spellCheck="false"
                        placeholder={formatMessage({ id: 'form.placeholder.enterLimitations' })}
                        rows={4}
                        data-test="formElementLimitationsDetails"
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={16} className={styles.formRow}>
              <Col md={24}>
                <Button
                  type="primary"
                  className={`${globalStyles.standardButton} ${globalStyles.standardButtonWide}`}
                  onClick={() => this.saveResolution()}
                  data-test="saveButton"
                  data-testid="saveButton"
                >
                  {formatMessage({ id: 'form.button.saveDetails' })}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {!!isSaved && (
          <ResolutionCard item={resolutionDetails} editResolution={() => this.editResolution()} type="DEF" />
        )}
      </>
    );
  }
}

export default injectIntl(DefectResolution);
