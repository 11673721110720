import { Button, Dropdown, Icon, Menu } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import uuid from 'uuid';
import moment from 'moment';
import backArrowIcon from '../../assets/back-arrow.svg';
import MagnifierSplitView from '../../components/MagnifierSplitView';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import TripForm from '../../components/TripForm';
import { storeEphemeralTrip as storeEphemeralTripAction } from '../../models/trips/actions';
import ExpandedContent from '../../components/TopNavWrapper/ExpandedContent';
import styles from './index.module.less';

class AddTrip extends Component {
  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    peopleMap: PropTypes.instanceOf(Map).isRequired,
    selectedAircraft: PropTypes.object,
    match: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    storeEphemeralTrip: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedAircraft: undefined,
  };

  constructor(props) {
    super(props);
    const { selectedAircraft, match } = this.props;
    let selectedAircraftId;
    if (selectedAircraft) {
      if (selectedAircraft.id) {
        if (match.params && match.params.id) {
          selectedAircraftId = match.params.id;
        }
      }
    }
    this.state = {
      attachment: null,
      submitting: false,
      trip: {
        id: 'new',
        aircraft_id: selectedAircraftId,
        attachments: [],
        flights: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedAircraft } = this.props;
    if (prevProps.selectedAircraft !== selectedAircraft) {
      this.setTrip();
    }
  }

  setTrip = () => {
    const { selectedAircraft } = this.props;
    this.setState(({ trip }) => ({
      trip: {
        ...trip,
        aircraft_id: selectedAircraft ? selectedAircraft.id : undefined,
      },
    }));
  };

  // File uploaded via split view
  fileUpload = (files) => {
    const { trip } = this.state;
    if (!trip) return;
    this.setState(
      {
        trip: {
          ...trip,
          attachments: [...trip.attachments, ...files],
        },
      },
      () => {
        this.updateAttachment(files[0]);
      },
    );
  };

  updateAttachment = (attachment) => {
    if (attachment === this.currentAttachment) return;
    this.currentAttachment = attachment;

    if (!attachment) {
      this.setState({ attachment: null });
    } else if (attachment.originFileObj) {
      this.setState({ attachment: attachment.originFileObj });
    }
  };

  tripChange = (newTrip) => {
    const { trip } = this.state;
    if (!newTrip.attachments.includes(this.currentAttachment)) {
      this.updateAttachment(newTrip.attachments[0]);
    } else if (newTrip.attachments.length > this.state.trip.attachments.length) {
      this.updateAttachment(newTrip.attachments[newTrip.attachments.length - 1]);
    }

    this.setState({
      trip: {
        ...trip,
        ...newTrip,
      },
    });
  };

  handlePreview = (file) => {
    if (file.url) {
      window.open(file.url, '_blank');
    } else if (file.originFileObj) {
      this.updateAttachment(file);
    }
  };

  handleTripSubmit = async () => {
    const { storeEphemeralTrip } = this.props;
    await new Promise((resolve) => {
      this.setState(
        {
          submitting: true,
        },
        resolve,
      );
    });
    const { trip } = this.state;
    const aircraft = this.props.aircraftMap.get(trip.aircraft_id);
    const captain = this.props.peopleMap.get(trip.captain_id);
    const firstOfficer = this.props.peopleMap.get(trip.first_officer_id);
    const id = `new-${uuid.v4()}`;
    const newTrip = {
      ...trip,
      date: moment.utc(trip.date).startOf('day'),
      aircraft,
      captain,
      first_officer: firstOfficer,
      flights: [...trip.flights, { id }],
    };

    await storeEphemeralTrip(newTrip);
    const { history } = this.props;
    if (aircraft && aircraft.id && this.props.match.params.id) {
      history.push(`/aircraft/${aircraft.id}/trips/${id}/edit`);
    } else {
      history.push(`/operations/flights/${id}/edit`);
    }
  };

  attachmentDropdown = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { trip } = this.state;
    if (!trip || !trip.attachments || trip.attachments.length < 1) return null;
    return (
      <Dropdown
        placement="bottomRight"
        overlay={
          <Menu>
            {trip.attachments.map((file) => (
              <Menu.Item
                key={file.uid}
                onClick={() =>
                  this.setState({
                    attachment: file.originFileObj,
                  })
                }
              >
                {file.name}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button type="link">
          {formatMessage({ id: 'text.view' })} <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  render() {
    const {
      selectedAircraft,
      intl: { formatMessage },
      match,
    } = this.props;
    const { trip, attachment, submitting } = this.state;

    return (
      <>
        <ExpandedContent
          displayTitle={formatMessage({ id: 'title.addNewTrip' })}
          action={
            selectedAircraft && (
              <Link
                to={match.params.id ? `/aircraft/${match.params.id}/trips` : '/operations/flights'}
                data-test="cancelLink"
              >
                <Button data-test="cancelButton">{formatMessage({ id: 'form.button.cancel' })}</Button>
              </Link>
            )
          }
          logo={
            <Link
              to={selectedAircraft.id ? `/aircraft/${selectedAircraft.id}/trips` : '/operations/flights'}
              data-test="backLink"
            >
              <img className={styles.backIcon} src={backArrowIcon} alt="back icon" />
            </Link>
          }
        />
        <MagnifierSplitView
          file={attachment}
          onUpload={this.fileUpload}
          leftCol={this.attachmentDropdown()}
          offsetTop={selectedAircraft ? 88 : undefined}
          data-test="magnifierSplitView"
        >
          <TripForm
            aircraft={selectedAircraft}
            trip={trip}
            onAttachmentPreview={this.handlePreview}
            onChange={this.tripChange}
            onSubmit={this.handleTripSubmit}
            submitting={submitting}
            data-test="tripForm"
          />
        </MagnifierSplitView>
      </>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    ({ aircraft: { aircraftMap }, people: { peopleMap } }, { match }) => {
      const selectedAircraft = aircraftMap.get(match.params.id) || {};

      return {
        selectedAircraft,
        aircraftMap,
        peopleMap,
      };
    },
    (dispatch) => ({
      storeEphemeralTrip: async (payload) => dispatch(storeEphemeralTripAction({ payload })),
    }),
  ),
)(AddTrip);
