import {
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tag,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../TFLoading';
import NonStyledButton from '../NonStyledButton/NonStyledButton';
import {
  getAircraftTripEntries,
  updateEntry,
  updateTrip,
  deleteEntry,
  deleteUpdate,
  addUpdate,
  setTripsTableSource,
} from '../../models/trips/actions';
import { deleteFlightEntry, setFlightsDirty } from '../../models/flights/actions';
import defaults from '../../utils/defaults';
import servers from '../../utils/servers';
import EmptyStateTrips from '../../assets/emptyState/empty-state-trips.svg';
import { AircraftResource } from '../../models/aircraft';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import styles from './index.module.less';

const EditableCell = ({ editable, value, onChange }) => (
  <span className={styles.tripNumber}>
    #
    {editable ? (
      <Input
        data-test="numberInput"
        className={styles.editableCellInput}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    ) : (
      value
    )}
  </span>
);

EditableCell.defaultProps = {
  editable: false,
  value: undefined,
};

EditableCell.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

const EditableDate = ({ editable, value, onChange, dateFormat, id }) => (
  <span>
    {editable ? (
      <DatePicker
        value={value ? moment(value) : null}
        format={dateFormat}
        onChange={(e) => onChange(e, id)}
        data-test="datePickerInput"
      />
    ) : (
      moment(value).format(dateFormat)
    )}
  </span>
);

EditableDate.defaultProps = {
  editable: false,
  id: undefined,
  value: undefined,
};

EditableDate.propTypes = {
  editable: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

const EditableSRP = ({ editable, value, id, onChange, formatMessage, jl, showSRPNumber }) => (
  <span className={styles.srpNumber}>
    {!jl && editable ? (
      <span>
        <a
          href={jl ? `${servers.api}/srp/journey_log/${id}.pdf` : `${servers.api}/srp/${id}.pdf`}
          target="_blank"
          rel="noopener noreferrer"
          download={formatMessage({ id: 'text.srp' })}
        >
          {formatMessage({ id: jl ? 'text.jl' : 'text.srp' })} <Icon type="file-pdf" />
        </a>
        <Input
          data-test="srpInput"
          className={styles.editableCellInput}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </span>
    ) : (
      <a
        href={jl ? `${servers.api}/srp/journey_log/${id}.pdf` : `${servers.api}/srp/${id}.pdf`}
        target="_blank"
        rel="noopener noreferrer"
        download={formatMessage({ id: jl ? 'text.jl' : 'text.srp' })}
      >
        {formatMessage({ id: jl ? 'text.jl' : 'text.srp' })} <Icon type="file-pdf" />{' '}
        {jl ? null : showSRPNumber && `(${value})`}
      </a>
    )}
  </span>
);

EditableSRP.defaultProps = {
  editable: false,
  id: undefined,
  value: undefined,
  jl: false,
  showSRPNumber: false,
};

EditableSRP.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  id: PropTypes.string,
  jl: PropTypes.bool,
  showSRPNumber: PropTypes.bool,
};

class TripsTable extends PureComponent {
  static propTypes = {
    aircraft: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    lastFetched: PropTypes.number.isRequired,
    limitTrips: PropTypes.number,
    showPagination: PropTypes.bool,
    showSearchAdd: PropTypes.bool,
    tripsArr: PropTypes.array.isRequired,
    userSettings: PropTypes.object.isRequired,
    tripUpdate: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    source: PropTypes.string,
    handleEditClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    aircraft: undefined,
    limitTrips: undefined,
    showPagination: false,
    showSearchAdd: false,
    source: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentEditKey: null,
      currentEditTrip: null,
      data: [],
      expRows: [],
      globalFilter: '',
      isInlineEdit: false,
      loading: true,
      sortedInfo: { columnKey: null, order: -1 },
      columns: [],
      editFirstRow: false,
      tripUpdate: null,
      preUpdateTripArrLength: 0,
      recentlySavedID: '',
    };
  }

  componentDidMount() {
    const { aircraft } = this.props;
    this.getColumns();
    if (aircraft) {
      this.getAircraftTrips();
    }
  }

  componentDidUpdate(prevProps) {
    const { aircraft, lastFetched, tripsArr, tripUpdate } = this.props;
    const { editFirstRow, preUpdateTripArrLength, tripUpdate: tripUpdateInState, loading } = this.state;
    if (
      // We didn't have an aircraft before but we do now
      (aircraft && !prevProps.aircraft) ||
      // We did have an aircraft before but it was a different one
      (aircraft && prevProps.aircraft && aircraft.id !== prevProps.aircraft.id)
    ) {
      this.getFreshAircraftTrips();
    }
    if (tripsArr !== prevProps.tripsArr && !editFirstRow) {
      this.updateTripsMap();
    }

    // We have an aircraft and there was an action in table or data was updated somewhere
    if (aircraft && prevProps.aircraft && aircraft.id === prevProps.aircraft.id) {
      // We have an aircraft and it is in edit mode
      if (editFirstRow && tripsArr.length > prevProps.tripsArr.length && tripUpdate.id) {
        this.parseTripsMapAndEdit();
      }
      // We have an aircraft and it is not in edit mode
      if (!editFirstRow && (!tripUpdate || !tripUpdate.id)) {
        if (lastFetched > prevProps.lastFetched) {
          if (tripUpdateInState && !tripUpdate.id) {
            this.updateTripsMap();
          } else {
            this.parseTripsMap();
          }
        }
      }
      // We have an aircraft and it was updated and saved
      if (!editFirstRow && tripsArr.length <= prevProps.tripsArr.length) {
        if (lastFetched > prevProps.lastFetched) {
          if (preUpdateTripArrLength && preUpdateTripArrLength < tripsArr.length) {
            this.updateTripsMap();
            this.parseTripsMap();
          } else {
            this.parseTripsMap();
          }
        }
      }
    }

    const highlightedRow = document.querySelector(`.${styles.highlightedRow}`);
    if (highlightedRow) {
      window.scrollTo(0, highlightedRow.offsetTop);
    }
    if (loading && tripsArr.length === 0 && prevProps.lastFetched !== lastFetched) {
      this.setEmptyTable();
    }
  }

  componentWillUnmount = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  getAircraftTrips = () => {
    const { dispatch, aircraft } = this.props;
    this.setState({ loading: true }, () => {
      dispatch(
        getAircraftTripEntries({
          payload: aircraft.id,
        }),
      );
    });
  };

  getFreshAircraftTrips = () => {
    const { dispatch, aircraft } = this.props;
    this.setState(
      {
        loading: true,
        editFirstRow: false,
        data: [],
        currentEditKey: null,
        tripUpdate: null,
        preUpdateTripArrLength: 0,
      },
      () => {
        dispatch(
          getAircraftTripEntries({
            payload: aircraft.id,
          }),
        );
      },
    );
  };

  setEmptyTable = () => {
    this.setState({
      loading: false,
      editFirstRow: false,
    });
  };

  getColumns = () => {
    const {
      intl: { formatMessage },
      aircraft,
      tripUpdate,
    } = this.props;
    const { sortedInfo } = this.state;
    const columns = [
      {
        title: formatMessage({ id: 'title.tripNum' }),
        dataIndex: 'number',
        className: 'tripNumCol',
        sortOrder: sortedInfo.columnKey === 'number' && sortedInfo.order,
        render: (text, record) => {
          return (this.state.isInlineEdit && this.state.currentEditTrip === record.id) || text ? (
            <div>
              <EditableCell
                editable={record.editable}
                value={text}
                onChange={(value) => this.handleChange(value, record.id, 'number')}
              />
              <EditableSRP
                editable={record.editable}
                formatMessage={formatMessage}
                value={record.srp_number}
                id={record.id}
                onChange={(value) => this.handleChange(value, record.id, 'srp_number')}
                showSRPNumber={text !== record.srp_number}
              />
              {record.type === 'Trip' ? (
                <EditableSRP
                  editable={record.editable}
                  formatMessage={formatMessage}
                  value={record.srp_number}
                  id={record.id}
                  onChange={(value) => this.handleChange(value, record.id, 'jl_number')}
                  jl
                />
              ) : null}
            </div>
          ) : (
            '-'
          );
        },
        sorter: (a, b) => a.number - b.number,
      },
      {
        title: formatMessage({ id: 'title.date' }),
        dataIndex: 'date',
        className: 'dateCol',
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        render: (text, record) => {
          if (record.type === 'TripUpdate' || !record.type) {
            if ((this.state.isInlineEdit && this.state.currentEditTrip === record.id) || text) {
              return (
                <EditableDate
                  id={record.id}
                  onChange={(value) => this.handleChange(value, record.id, 'date')}
                  dateFormat={this.props.userSettings.dateFormat || defaults.defaultDateFormat}
                  editable={record.editable}
                  value={text}
                />
              );
            }
            return '-';
          }
          return moment(text).format(this.props.userSettings.dateFormat || defaults.defaultDateFormat) || '-';
        },
        sorter: (a, b) => moment(a.date).diff(moment(b.date)),
      },
      {
        title: formatMessage({ id: 'title.callsign' }),
        dataIndex: 'callsign',
        className: 'callsignCol',
        sortOrder: sortedInfo.columnKey === 'callsign' && sortedInfo.order,
        render: (text) => {
          return text || '-';
        },
        sorter: (a, b) => {
          const aCallsign = a.callsign || '0';
          const bCallsign = b.callsign || '0';
          return aCallsign && bCallsign ? aCallsign.toString().localeCompare(bCallsign.toString()) : 0;
        },
      },
      {
        title: formatMessage({ id: 'title.hours' }),
        dataIndex: 'flight_seconds',
        className: 'hoursCol',
        sortOrder: sortedInfo.columnKey === 'flight_seconds' && sortedInfo.order,
        render: (text) => {
          let hours = Math.floor(text / 3600);
          const remainingSeconds = text % 3600;
          let minutes = Math.floor(remainingSeconds / 60);
          minutes = String(minutes).padStart(2, '0');
          hours = String(hours).padStart(2, '0');
          return text ? `${hours}:${minutes}` : '-';
        },
        sorter: (a, b) => a.flight_seconds - b.flight_seconds,
      },
      {
        title: formatMessage({ id: 'title.landings' }),
        dataIndex: 'landings',
        className: 'landingsCol',
        sortOrder: sortedInfo.columnKey === 'landings' && sortedInfo.order,
        render: (text) => {
          return text || '-';
        },
        sorter: (a, b) => a.landings - b.landings,
      },
      {
        title: formatMessage({ id: 'title.recordType' }),
        dataIndex: 'type',
        className: 'recordTypeCol',
        render: (text) => {
          if (text === formatMessage({ id: 'text.trip' })) {
            return <Tag color="cyan">{formatMessage({ id: 'text.trip' })}</Tag>;
          }
          return <Tag color="orange">{formatMessage({ id: 'text.tripUpdate' })}</Tag>;
        },
        filters: [
          {
            text: formatMessage({ id: 'text.trip' }),
            value: 'Trip',
          },
          {
            text: formatMessage({ id: 'text.tripUpdate' }),
            value: 'TripUpdate',
          },
        ],
        onFilter: (value, record) => record.type === value,
      },
      {
        title: '',
        dataIndex: 'operation',
        className: 'operationCol',
        render: (text, record) => {
          const { editable } = record;
          return (
            <div className={styles.editableRowOperations}>
              {editable ? (
                <span>
                  <Button type="link" onClick={() => this.save(record.id)}>
                    Save
                  </Button>
                  <Divider type="vertical" />
                  {tripUpdate && !tripUpdate.type ? (
                    <Button type="link" onClick={() => this.cancel(record.id)}>
                      {formatMessage({ id: 'form.button.cancel' })}
                    </Button>
                  ) : (
                    <Popconfirm
                      title={formatMessage({ id: 'title.sureToCancel' })}
                      onConfirm={() => this.cancel(record.id)}
                    >
                      <Button type="link">{formatMessage({ id: 'form.button.cancel' })}</Button>
                    </Popconfirm>
                  )}
                </span>
              ) : (
                <span />
              )}
            </div>
          );
        },
      },
      {
        title: '',
        dataIndex: 'id',
        className: 'optionsCol',
        width: 50,
        render: (text, trip) => {
          const { editable } = trip || false;
          const { source, dispatch, handleEditClick } = this.props;

          const flightsInProgress = trip.flights
            ? trip.flights.some((flight) => flight.status === 'in_progress')
            : false;
          const displayEditTrip = !editable && trip.type === 'Trip';
          const displayEditTripUpdate = !editable && (!trip.type || trip.type !== 'Trip');

          const editFlights = (
            <Link
              to={aircraft ? `/aircraft/${aircraft.id}/trips/${trip.id}/trip` : ''}
              onClick={() => {
                dispatch(
                  setTripsTableSource({
                    payload: {
                      source,
                    },
                  }),
                );
              }}
            >
              <span>{formatMessage({ id: 'message.editFlights' })}</span>
            </Link>
          );

          if (displayEditTrip) {
            return (
              <div className={styles.tripsAuthmenuWrapper}>
                <AuthDropdownMenu
                  menuStyle={{ position: 'fixed', zIndex: '100' }}
                  editText={<span>{formatMessage({ id: 'message.editTrip' })}</span>}
                  editCallback={() => this.edit(trip.id)}
                  customText={!flightsInProgress && editFlights}
                  resource={AircraftResource.TRIP}
                  handleDelete={() => this.deleteTrip(trip.id)}
                  aircraftId={aircraft.id}
                  options={{ create: false, read: false, update: displayEditTrip, delete: displayEditTrip }}
                />
              </div>
            );
          }

          if (displayEditTripUpdate) {
            return (
              <div className={styles.tripsAuthmenuWrapper}>
                <AuthDropdownMenu
                  menuStyle={{ position: 'fixed', zIndex: '100' }}
                  editText={<span>{formatMessage({ id: 'message.editTrip' })}</span>}
                  editCallback={() => handleEditClick(trip)}
                  resource={AircraftResource.TRIP}
                  handleDelete={() => this.deleteTrip(trip.id)}
                  aircraftId={aircraft.id}
                  options={{
                    create: false,
                    read: false,
                    update: displayEditTripUpdate,
                    delete: displayEditTripUpdate,
                  }}
                />
              </div>
            );
          }

          return null;
        },
      },
    ];
    this.setState({ columns });
  };

  parseTripsMap = () => {
    const data = this.props.tripsArr;
    this.setState({
      data,
      expRows: data.map((item) => item.id),
      loading: false,
      tripUpdate: null,
    });
  };

  parseTripsMapAndEdit = () => {
    const newTripUpdate = this.props.tripUpdate;
    const data = this.props.tripsArr.filter((f) => {
      return f.id !== newTripUpdate.id;
    });
    data.unshift(newTripUpdate);
    this.setState(
      {
        data,
        expRows: data.map((item) => item.id),
        loading: false,
        tripUpdate: newTripUpdate,
        preUpdateTripArrLength: data.length - 1,
      },
      () => {
        if (this.state.editFirstRow) {
          this.edit(data[0].id);
        }
      },
    );
  };

  updateTripsMap = () => {
    const newTripUpdate = this.state.tripUpdate;
    if (newTripUpdate) {
      const data = this.props.tripsArr.filter((f) => {
        if (f) {
          return f.id !== newTripUpdate.id;
        }
        return f != null;
      });
      data.unshift(newTripUpdate);
      const expRowNum = [];
      data.forEach((d) => {
        expRowNum.push(d.id);
      });
      this.setState({
        data,
        expRows: expRowNum,
        loading: false,
      });
    }
  };

  menuAddNew = () => {
    const {
      aircraft,
      intl: { formatMessage },
    } = this.props;
    return (
      <Menu data-test="dropdownMenu">
        <Menu.Item>
          <NonStyledButton>
            <Link data-test="linkToFlightForm" to={aircraft ? `/aircraft/${aircraft.id}/trips/add` : ''}>
              {formatMessage({ id: 'text.trip' })}
            </Link>
          </NonStyledButton>
        </Menu.Item>
        <Menu.Item>
          <NonStyledButton
            className={styles.menuButton}
            data-test="linkToCreateNewTripUpdate"
            onClick={this.handleAddTripUpdate}
          >
            {formatMessage({ id: 'text.tripUpdate' })}
          </NonStyledButton>
        </Menu.Item>
      </Menu>
    );
  };

  menuFlight = (flightId, tripId, tripNumber, detailsLink) => {
    const {
      aircraft,
      intl: { formatMessage },
      dispatch,
      source,
    } = this.props;
    const { state } = detailsLink;
    return (
      <Menu>
        <Menu.Item key="edit">
          {/* <AuthenticationWrapper requiredResource="trips" requiredPermissionLevel="update"> */}
          <Button type="link">
            <Link
              to={aircraft ? `/aircraft/${aircraft.id}/trips/${flightId}/edit` : ''}
              onClick={() => {
                dispatch(
                  setTripsTableSource({
                    payload: {
                      source,
                    },
                  }),
                );
              }}
            >
              {formatMessage({ id: 'text.edit' })}
            </Link>
          </Button>
          {/* </AuthenticationWrapper> */}
        </Menu.Item>
        <Menu.Item key="details">
          <Button type="link">
            <Link
              to={{
                pathname: `/operations/flights/${flightId}`,
                state,
              }}
            >
              {formatMessage({ id: 'text.details' })}
            </Link>
          </Button>
        </Menu.Item>
        <Menu.Item>
          {/* <AuthenticationWrapper requiredResource="trips" requiredPermissionLevel="delete"> */}
          <Button
            type="link"
            className={styles.deleteButton}
            onClick={() => this.deleteSector(flightId, tripId, tripNumber)}
          >
            {formatMessage({ id: 'form.button.delete' })}
          </Button>
          {/* </AuthenticationWrapper> */}
        </Menu.Item>
      </Menu>
    );
  };

  dataDetails = (record) => {
    const {
      intl: { formatMessage },
      aircraft,
      dispatch,
      source,
    } = this.props;
    const tripFlights = record.flights;
    const items = [];
    const flightsInProgress = tripFlights ? tripFlights.some((flight) => flight.status === 'in_progress') : false;
    if (tripFlights) {
      let sectorNumber = 0;
      for (const flight of tripFlights) {
        sectorNumber += 1;

        const detailsLink = {
          pathname: `/operations/flights/${flight.id}`,
          state: { fromLocation: 'trips', fromAircraft: aircraft.id },
        };

        const edit = (
          <Link to={aircraft ? `/aircraft/${aircraft.id}/trips/${flight.id}/edit` : ''}>
            <span>{formatMessage({ id: 'text.edit' })}</span>
          </Link>
        );

        const view = (
          <Link
            to={{
              pathname: `/operations/flights/${flight.id}`,
              state: detailsLink.state,
            }}
          >
            <span>{formatMessage({ id: 'text.details' })}</span>
          </Link>
        );

        const linkToFlight = () => {
          window.location = detailsLink.pathname;
        };

        items.push(
          <Row key={flight.id} className={styles.sectorRow} gutter={16} data-test="singleFlightRow">
            <Col className={styles.sectorCol} span={4} onClick={linkToFlight}>
              <Tag color="green" className={flight.status === 'complete' ? styles.tagSector : styles.tagSectorHidden} />
              <span className={styles.sectorTitle}>
                {formatMessage({ id: 'title.sector' })} {sectorNumber}
              </span>
              {flight.status === 'partial' && (
                <Tag className={styles.tagSectorDetails} color="orange">
                  {formatMessage({ id: 'status.partial' })}
                </Tag>
              )}
              {flight.status === 'in_progress' && (
                <Tag className={styles.tagSectorDetails} color="blue">
                  {formatMessage({ id: 'status.inProgress' })}
                </Tag>
              )}
              {flight.status === 'error' && (
                <Tag className={styles.tagSectorDetails} color="red">
                  {formatMessage({ id: 'status.error' })}
                </Tag>
              )}
              {flight.status === 'planned' && (
                <Tag color="#c2bebb" className={`${styles.tagSectorDetails} ${styles.tagDraft}`}>
                  {formatMessage({ id: 'status.draft' })}
                </Tag>
              )}
            </Col>
            <Col className={styles.sectorCol} span={8} onClick={linkToFlight}>
              <div className={styles.sectorDetails} data-test="singleFlightPIC">
                <span className={styles.sectorLabel}>{formatMessage({ id: 'title.pic' })}</span>{' '}
                {`${record.captain.first_name} ${record.captain.last_name}` || '-'}
              </div>
              <div className={styles.sectorDetails} data-test="singleFlightSIC">
                <span className={styles.sectorLabel}>{formatMessage({ id: 'title.sic' })}</span>{' '}
                {(record.first_officer && `${record.first_officer.first_name} ${record.first_officer.last_name}`) ||
                  '-'}
              </div>
            </Col>
            <Col className={styles.sectorCol} span={6} onClick={linkToFlight}>
              <div className={styles.sectorDetails}>
                <span className={styles.sectorLabel}>{formatMessage({ id: 'title.from' })}</span>{' '}
                {flight.departure_airport || '-'}
              </div>
              <div className={styles.sectorDetails}>
                <span className={styles.sectorLabel}>{formatMessage({ id: 'title.to' })}</span>{' '}
                {flight.arrival_airport || '-'}
              </div>
            </Col>
            <Col className={styles.sectorCol} span={5} onClick={linkToFlight}>
              <div className={styles.sectorDetails}>
                <span className={styles.sectorLabel}>{formatMessage({ id: 'title.takeoff' })}</span>
                {flight.time_takeoff ? moment.utc(flight.time_takeoff).format('HH:mm') : '-'}
                {flight.time_takeoff ? <span className={styles.timeUnit}>Z</span> : ''}
              </div>
              <div className={styles.sectorDetails}>
                <span className={styles.sectorLabel}>{formatMessage({ id: 'title.lanDing' })}</span>
                {flight.time_landing ? moment.utc(flight.time_landing).format('HH:mm') : '-'}
                {flight.time_landing ? <span className={styles.timeUnit}>Z</span> : ''}
              </div>
            </Col>
            <Col className={styles.sectorCol} span={1}>
              <AuthDropdownMenu
                menuStyle={{ position: 'absolute', zIndex: '100!important', left: '20px' }}
                editText={edit}
                editCallback={() =>
                  dispatch(
                    setTripsTableSource({
                      payload: {
                        source,
                      },
                    }),
                  )
                }
                viewText={view}
                resource={AircraftResource.TRIP}
                handleDelete={() => this.deleteSector(flight.id, record.id, record.number)}
                aircraftId={aircraft.id}
                options={{
                  create: false,
                  read: true,
                  update: !flightsInProgress,
                  delete: true,
                }}
              />
            </Col>
          </Row>,
        );
      }
    }
    return items;
  };

  filterTable = () => {
    const filteredBy = this.state.globalFilter.toLowerCase();
    const filterByKeys = [
      'aircraft_registration',
      'callsign',
      'captain',
      'first_officer',
      'date',
      'end_airport',
      'landings',
      'start_airport',
      'type',
      'flights',
    ];
    const filterByNestedKeys = [
      'first_name',
      'last_name',
      'arrival_airport',
      'departure_airport',
      'captain',
      'first_officer',
      'time_landing',
      'time_takeoff',
      'status',
    ];
    const data =
      filteredBy === null || ''
        ? this.props.tripsArr
        : this.props.tripsArr.filter((entry) =>
            Object.keys(entry).some((key) => {
              if (filterByKeys.includes(key)) {
                const value = entry[key];
                if (typeof value === 'string') {
                  return value.toLowerCase().includes(filteredBy);
                }
                if (Array.isArray(value)) {
                  return value.some((nestedValue) => {
                    return Object.keys(nestedValue).some((nestedValueKey) => {
                      if (filterByNestedKeys.includes(nestedValueKey)) {
                        return (
                          typeof nestedValue[nestedValueKey] === 'string' &&
                          nestedValue[nestedValueKey].toLowerCase().includes(filteredBy)
                        );
                      }
                      return false;
                    });
                  });
                }
                if (typeof value === 'object' && value !== null) {
                  return Object.keys(value).some((nestedValueKey) => {
                    if (filterByNestedKeys.includes(nestedValueKey)) {
                      return (
                        typeof value[nestedValueKey] === 'string' &&
                        value[nestedValueKey].toLowerCase().includes(filteredBy)
                      );
                    }
                    return false;
                  });
                }
                return false;
              }
              return false;
            }),
          );
    this.setState({ data });
  };

  handleUpdate = async (target) => {
    const {
      intl: { formatMessage },
    } = this.props;
    await new Promise((resolve) => {
      this.setState(
        {
          isInlineEdit: false,
          currentEditKey: null,
          currentEditTrip: null,
          editFirstRow: false,
          recentlySavedID: target.id,
        },
        resolve,
      );
    });
    if (!target.type || target.type === 'TripUpdate') {
      this.props.dispatch(
        updateEntry({
          payload: {
            id: target.id,
            trip: {
              number: target.number,
              srp_number: target.srp_number,
              date: moment(target.date).format('YYYY-MM-DD'),
            },
          },
        }),
      );
    } else {
      this.props.dispatch(
        updateTrip({
          payload: {
            id: target.id,
            trip: {
              number: target.number,
              srp_number: target.srp_number,
            },
            srp_number: true,
          },
        }),
      );
    }
    message.success(formatMessage({ id: 'message.tripUpdatedSuccess' }));

    this.timeout = setTimeout(() => {
      this.setState({
        recentlySavedID: '',
      });
    }, 4000);
  };

  handleDelete = (target, newData) => {
    const callBack = () => {
      this.setState({ data: newData });
    };
    if (target.type && target.type === 'Trip') {
      this.setState({ isInlineEdit: false, currentEditKey: null, currentEditTrip: null }, () => {
        this.props.dispatch(
          deleteEntry(
            {
              payload: {
                id: target.id,
              },
            },
            callBack,
          ),
        );
        this.props.dispatch(
          setFlightsDirty({
            payload: true,
          }),
        );
      });
    } else {
      this.setState({ isInlineEdit: false, currentEditKey: null, currentEditTrip: null }, () => {
        this.props.dispatch(
          deleteUpdate(
            {
              payload: {
                id: target.id,
              },
            },
            callBack,
          ),
        );
      });
    }
  };

  handleFlightDelete = (target, newData) => {
    const callBack = () => {
      this.setState({ data: newData });
    };
    if (target) {
      this.setState({ isInlineEdit: false, currentEditKey: null, currentEditTrip: null }, () => {
        this.props.dispatch(
          deleteFlightEntry(
            {
              payload: {
                id: target,
              },
            },
            callBack,
          ),
        );
      });
    }
  };

  handleChange = (value, id, column) => {
    const { data } = this.state;
    if ((column === 'number' || column === 'srp_number' || column === 'jl_number') && value.length > 0) {
      const isNumber = /^-{0,1}\d+$/.test(value);
      if (!isNumber) {
        return;
      }
    }
    // First we check whether to update the records or not
    if (data.some((item) => id === item.id)) {
      // Now that we know there was an update on an existing object we loop through them
      const newData = data.map((item) => {
        // If the object is the edited one
        if (item.id === id) {
          let newColumn;
          if (column === 'date' && value) {
            newColumn = moment(value).format('YYYY-MM-DD');
          } else {
            newColumn = value;
          }
          return {
            ...item,
            [column]: newColumn,
          };
        }
        return item;
      });
      this.setState({ data: newData });
    }
  };

  handleAddTripUpdate = () => {
    const {
      dispatch,
      aircraft,
      intl: { formatMessage },
    } = this.props;
    const { editFirstRow, sortedInfo } = this.state;
    if (editFirstRow) {
      message.error(formatMessage({ id: 'message.pleaseSaveExistingWork' }));
    } else {
      dispatch(
        addUpdate({
          payload: {
            aircraftId: aircraft.id,
            trip: {
              date: moment().format('YYYY-MM-DD'),
            },
          },
        }),
      );
      this.setState({ sortedInfo: { columnKey: sortedInfo.columnKey, order: false }, editFirstRow: true }, () =>
        this.getAircraftTrips(),
      );
    }
  };

  handleTableChange = (p, f, s) => {
    if (s.columnKey) {
      this.setState({ sortedInfo: s }, () => {
        this.getColumns();
      });
    } else {
      this.setState({ sortedInfo: { columnKey: null, order: -1 } }, () => {
        this.getColumns();
      });
    }
  };

  edit = (id) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { data, currentEditKey } = this.state;
    if (!currentEditKey || !data.some((entry) => entry.editable)) {
      // The two if statements cannot be merged as the error message in the else below doesn't apply to the second if
      if (data.some((item) => id === item.id)) {
        const newData = data.map((item) => (item.id === id ? { ...item, editable: true } : item));
        this.setState({ data: newData, isInlineEdit: true, currentEditKey: id, currentEditTrip: id });
      }
    } else {
      message.error(formatMessage({ id: 'message.pleaseSaveExistingWork' }));
    }
  };

  save = (id) => {
    const {
      intl: { formatMessage },
    } = this.props;
    this.setState({ loading: true });
    const target = this.state.data.find((item) => id === item.id);
    if (target) {
      const numberLength = target.number.toString().length;
      const srpLength = target.srp_number.toString().length;
      const invalidDate = target.date === 'Invalid date' || target.date === null;
      if ((numberLength && numberLength > 9) || (srpLength && srpLength > 9)) {
        message.error(formatMessage({ id: 'message.tooLongNumber' }));
        this.setState({ loading: false });
      } else if (numberLength === 0) {
        message.error(formatMessage({ id: 'message.tripNumberBlank' }));
        this.setState({ loading: false });
      } else if (srpLength === 0) {
        message.error(formatMessage({ id: 'message.tripSRPNumberBlank' }));
        this.setState({ loading: false });
      } else if (parseInt(target.number, 10) < 1 || parseInt(target.srp_number, 10) < 1) {
        message.error(formatMessage({ id: 'message.tripNumberZero' }));
        this.setState({ loading: false });
      } else if (invalidDate) {
        message.error(formatMessage({ id: 'message.invalidDate' }));
        this.setState({ loading: false });
      } else {
        delete target.editable;
        this.handleUpdate(target);
      }
    }
  };

  cancel = (id) => {
    const { data } = this.state;
    const { tripsArr, tripUpdate } = this.props;
    const target = tripsArr.find((item) => id === item.id);
    const newData = data.map((item) => (item.id === id ? { ...target, editable: false } : item));

    if (tripUpdate && !tripUpdate.type) {
      const removedData = [...data];
      const newTarget = data.find((item) => id === item.id);
      const targetIndex = removedData.findIndex((item) => id === item.id);
      removedData.splice(targetIndex, 1);
      this.setState({
        isInlineEdit: false,
        currentEditKey: null,
        currentEditTrip: null,
        editFirstRow: false,
        tripUpdate: null,
      });
      this.handleDelete(newTarget, removedData);
    } else {
      this.setState({
        data: newData,
        isInlineEdit: false,
        currentEditKey: null,
        currentEditTrip: null,
        editFirstRow: false,
      });
    }
  };

  deleteTrip = (id) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { data } = this.state;
    Modal.confirm({
      title: formatMessage({ id: 'title.deleteItem' }),
      content: `${formatMessage({ id: 'form.question.areYouSureDeleteTrip' })} ${formatMessage({
        id: 'form.labels.cannotBeUndone',
      })}`,
      okText: formatMessage({ id: 'form.button.delete' }),
      cancelText: formatMessage({ id: 'form.button.cancel' }),
      onOk: () => {
        const newData = [...data];
        const target = newData.find((item) => id === item.id);
        const targetIndex = newData.findIndex((item) => id === item.id);
        newData.splice(targetIndex, 1);
        this.handleDelete(target, newData);
      },
    });
  };

  deleteSector = (id, tripId, tripNumber) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { data } = this.state;
    const warningMessage = `${
      tripNumber ? `${formatMessage({ id: 'text.tripNum' }) + tripNumber}:` : ''
    } ${formatMessage({
      id: 'form.question.areYouSureDeleteSector',
    })} ${formatMessage({
      id: 'form.labels.cannotBeUndone',
    })}`;
    Modal.confirm({
      title: formatMessage({ id: 'title.deleteItem' }),
      content: warningMessage,
      okText: formatMessage({ id: 'form.button.delete' }),
      cancelText: formatMessage({ id: 'form.button.cancel' }),
      onOk: () => {
        const newData = [...data];
        const targetTrip = newData.find((item) => tripId === item.id);
        const targetTripIndex = newData.map((trip) => trip.id).indexOf(targetTrip.id);
        if (targetTrip.flights.length === 1) {
          newData.splice(targetTripIndex, 1);
          this.handleDelete(targetTrip, newData);
        } else {
          const targetSectorIndex = targetTrip.flights.findIndex((item) => id === item.id);
          targetTrip.flights.splice(targetSectorIndex, 1);
          newData[targetTripIndex] = targetTrip;
          this.handleFlightDelete(id, newData);
        }
      },
    });
  };

  render() {
    const {
      showSearchAdd,
      limitTrips,
      showPagination,
      intl: { formatMessage },
    } = this.props;
    const { expRows, globalFilter, data, addNewTrigger, recentlySavedID, columns, loading } = this.state;
    return (
      <>
        {showSearchAdd && (
          <>
            <Input
              value={globalFilter}
              className={styles.filterBox}
              data-test="inputFilterBox"
              onChange={(e) => {
                this.setState({ globalFilter: e.target.value }, () => {
                  this.filterTable();
                });
              }}
              placeholder={formatMessage({ id: 'form.placeholder.filterData' })}
            />
            {/* <AuthenticationWrapper requiredResource="trips" requiredPermissionLevel="create"> */}
            <Dropdown overlay={this.menuAddNew} data-test="addNewDropdown" trigger={addNewTrigger || ['hover']}>
              <Button data-test="addNewButton" type="primary" className={styles.addNewButton} icon="plus">
                <span className={styles.buttonText}>{formatMessage({ id: 'form.button.addNew' })}</span>
              </Button>
            </Dropdown>
            {/* </AuthenticationWrapper> */}
          </>
        )}
        <Loading loading={loading} contain />
        <Table
          onChange={this.handleTableChange}
          dataSource={limitTrips ? data.slice(0, limitTrips) : data}
          rowKey={(record) => record.id}
          rowClassName={(record) => (record.id === recentlySavedID ? styles.highlightedRow : '')}
          columns={columns}
          pagination={showPagination}
          className={styles.tripsTable}
          expandedRowRender={this.dataDetails}
          expandedRowKeys={expRows}
          onExpandedRowsChange={this.expandedRowsChange}
          scroll={{ x: true, y: window.innerHeight * 0.6 }}
          data-test="tripsTable"
          locale={{
            emptyText: (
              <div className={styles.emptyText} data-test="emptyState">
                <img src={EmptyStateTrips} alt="empty state" />
                <span>No Data</span>
              </div>
            ),
          }}
        />
      </>
    );
  }
}

export default compose(
  injectIntl,
  connect(({ trips, userSettings }, { aircraft }) => {
    const tripsArr = Array.from(trips.tripsMap.values())
      .filter((tripEntry) => aircraft && tripEntry.aircraft_id === aircraft.id)
      .sort((a, b) => moment(b.date).diff(moment(a.date)));
    let tripUpdate = {};
    if (trips.newTrip) {
      tripUpdate = trips.newTrip;
    }
    return {
      tripUpdate,
      tripsArr,
      lastFetched: trips.lastFetched,
      userSettings,
      aircraftPermissions: userSettings.details.people[0].permission_groups[1].permissions,
    };
  }),
  Form.create(),
)(TripsTable);
