import styled from 'styled-components';

const Styles = styled.div`
  padding: 2px;
  padding-right: 10px;
  .date {
    color: rgba(36, 45, 65, 0.4);
  }
  table {
    border-spacing: 0 10px;
    border-collapse: separate;
    min-width: 850px;
    thead th {
      background-color: transparent;
      color: #c8c8c8;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.33px;
      border: none;
      text-align: left;
      text-transform: uppercase;
      border-spacing: 0;
      padding: 0 10px;
      flex-shrink: 0;
      button {
        border: none;
        background-color: transparent;
      }
    }
    tbody tr {
      box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
      margin: 0 5px;
      height: 40px;
      border-radius: 4px;
      background-color: #fcfcfc;
      font-size: 13px;
      color: rgba(36, 45, 65, 0.9);
      &:last-child {
        margin-bottom: 40px;
      }
      &:hover {
        box-shadow: 0 0 5px 2px rgba(219, 227, 237, 0.8);
        background-color: rgba(24, 144, 255, 0.03);
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 1px #1890ff;
        background-color: rgba(24, 144, 255, 0.05);
      }
    }
    tr td {
      padding: 0 10px;
      border-top: 2px solid #fff;
      border-bottom: 2px solid #fff;

      &:first-child {
        width: 1%;
        border-left: 2px solid #fff;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        border-right: 2px solid #fff;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
`;

export default Styles;
