/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Icon, DatePicker, Modal, Table, Select, message, List, Popconfirm, Card } from 'antd';
import { injectIntl } from 'react-intl';
import { fetchImage } from '../../services/api';
import DocumentUploadModal from '../../components/Modals/Documents/documentUploadModal';
import defaults from '../../utils/defaults';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import servers from '../../utils/servers';
import Loading from '../../components/TFLoading';
import { unSnakeify } from '../../utils/utils';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import NonStyledButton from '../../components/NonStyledButton/NonStyledButton';
import { getAllAircraft } from '../../models/aircraft/actions';
import {
  getDocuments,
  update as updateDocuments,
  remove as removeDocument,
} from '../../models/aircraftDocuments/actions';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { selectView } from '../../models/userSettings/actions';
import EmptyStateDocuments from '../../assets/emptyState/empty-state-documents-v1.svg';
import ExpandedContent from '../../components/TopNavWrapper/ExpandedContent';
import styles from './AircraftDocuments.module.less';

const { Option } = Select;
class AircraftDocuments extends PureComponent {
  static propTypes = {
    documentMap: PropTypes.instanceOf(Map),
    userSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    selectedView: PropTypes.oneOf(['table', 'grid']).isRequired,
    listAnimationClass: PropTypes.oneOf(['defaultList', 'animatedList']).isRequired,
    tableAnimationClass: PropTypes.oneOf(['defaultTable', 'animatedTable']).isRequired,
    intl: PropTypes.object.isRequired,
    aircraftMap: PropTypes.object.isRequired,
  };

  static defaultProps = {
    documentMap: new Map(),
  };

  constructor(props) {
    super(props);
    const {
      match,
      intl: { formatMessage },
      userSettings,
    } = this.props;
    this.state = {
      showDocumentUploadModal: false,
      documentId: null,
      loading: false,
      documentList: [
        'deice',
        'uplift_chit',
        'payment_receipt',
        'fuel_release',
        'quote',
        'delivery',
        'ARC',
        'CAT.IDE',
        'CofA',
        'CofR',
        'CRS',
        'MEL',
        'DDL',
        'insurance_certificate',
        'maintenance_statement',
        'noise_certificate',
        'radio_licence',
        'third_party_liability',
      ],
      documentType: '',
      documentOperator: '',
      documentPreviewRoute: '',
      previewHeight: '300px',
      validFrom: null,
      validTo: null,
      newItemAdded: false,
      sortOrder: null,
      sortColumn: null,
      newItemID: '',
      activePage: 1,
      addedItemIndex: -1,
      pageSize: 10,
      selectedRowKeys: [],
      operatorList: [],
      columns: [
        {
          title: formatMessage({ id: 'title.filePreview' }),
          dataIndex: '',
          width: 120,
          render: (record) => {
            return (
              <div className={styles.thumbnailBox}>
                {record.thumbnail_url !== null && record.thumbnail_url !== undefined ? (
                  <NonStyledButton
                    onClick={() => {
                      this.showDocumentPreview(record);
                    }}
                  >
                    <img
                      src={`${servers.api}/${record.thumbnail_url}`}
                      alt={record.file_name}
                      className={styles.thumbnailImage}
                    />
                  </NonStyledButton>
                ) : (
                  <Button
                    type="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      this.showDocumentPreview(record);
                    }}
                  >
                    <Icon type="file" />
                    {record.file_name}
                  </Button>
                )}
              </div>
            );
          },
        },
        {
          title: formatMessage({ id: 'title.fileName' }),
          dataIndex: 'file_name',
          width: 250,
          sorter: (a, b) => {
            return a.file_name.localeCompare(b.file_name);
          },
        },
        {
          title: formatMessage({ id: 'title.category' }),
          dataIndex: 'category',
          width: 270,
          align: 'center',
          sorter: (a, b) => {
            const a1 = a.category || '';
            const b1 = b.category || '';
            return a1.localeCompare(b1);
          },
          render: (value) => {
            if (value === null) {
              return '-';
            }
            return unSnakeify(value);
          },
        },
        {
          title: formatMessage({ id: 'title.validFrom' }),
          dataIndex: 'valid_from',
          width: 250,

          align: 'center',
          sorter: (a, b) => {
            const a1 = a.valid_from || 0;
            const b1 = b.valid_from || 0;
            return moment(a1) - moment(b1);
          },
          render: (v) => {
            if (v === null) {
              return '-';
            }
            return (
              v &&
              (userSettings && userSettings.dateFormat
                ? moment(v).format(userSettings.dateFormat)
                : moment(v).format(defaults.defaultDateFormat))
            );
          },
        },
        {
          title: formatMessage({ id: 'title.validTo' }),
          dataIndex: 'valid_to',
          width: 250,
          align: 'center',
          sorter: (a, b) => {
            const a1 = a.valid_to || 0;
            const b1 = b.valid_to || 0;
            return moment(a1) - moment(b1);
          },
          render: (v) => {
            if (v === null) {
              return '-';
            }
            return (
              v &&
              (userSettings && userSettings.dateFormat
                ? moment(v).format(userSettings.dateFormat)
                : moment(v).format(defaults.defaultDateFormat))
            );
          },
        },
        {
          dataIndex: 'id',
          width: 50,
          render: (id, record) => {
            return (
              <AuthDropdownMenu
                options={{
                  create: false,
                  read: false,
                  update: true,
                  delete: true,
                }}
                menuStyle={{ right: 0, position: 'absolute', zIndex: 10 }}
                resource={AircraftResource.DOCUMENT}
                editText={<span>{formatMessage({ id: 'form.option.edit' })}</span>}
                editCallback={async () => {
                  this.showDocumentPreview(record);
                  const response = await fetchImage(record.url);
                  if (response?.size) {
                    try {
                      this.setState({ documentPreviewRoute: response });
                    } catch (e) {
                      console.error(e);
                    }
                  } else {
                    this.setState({ isDocumentViewVisible: false });
                    message.error(formatMessage({ id: 'message.onFileDoesNotExist' }));
                  }
                }}
                aircraftId={match.params.id}
                handleDelete={() => this.confirmDelete(id)}
              />
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.getDocumentList();
      const aircraft = this.getAircraft();
      if (!aircraft || Date.now() - aircraft.lastFetched > 30000) {
        this.getAircraft(true);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { match, documentMap } = this.props;
    const { newItemAdded } = this.state;
    const selectedAircraft = match.params.id;
    if (selectedAircraft && (!prevProps.match.params.id || prevProps.match.params.id !== selectedAircraft)) {
      this.getDocumentList();
    }
    if (documentMap !== prevProps.documentMap) {
      this.hideFileView();
      this.handleModalVisible(false);
      this.onLoaded();
      if (newItemAdded) {
        this.highlightRow();
      }
    }
  }

  onLoaded = () => this.setState({ loading: false });

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  getAircraft = (forceRefetch = false) => {
    const { aircraftMap, dispatch, match } = this.props;
    if (forceRefetch) {
      dispatch(getAllAircraft());
    }
    return aircraftMap.get(match.params.id);
  };

  getDocumentList = () => {
    this.setState({ loading: true });
    const { dispatch, match } = this.props;
    const selectedAircraftId = match.params.id;
    if (selectedAircraftId) {
      dispatch(
        getDocuments({
          payload: {
            id: selectedAircraftId,
          },
        }),
      );
    }
  };

  validFromChange = (date) => {
    if (date) {
      this.setState({ validFrom: moment(date) });
    } else {
      this.setState({ validFrom: null });
    }
  };

  validToChange = (date) => {
    if (date) {
      this.setState({ validTo: moment(date) });
    } else {
      this.setState({ validTo: null });
    }
  };

  handleModalVisible = (e) => {
    this.setState({ showDocumentUploadModal: e });
  };

  handleShowUploadModal = () => {
    this.setState({ showDocumentUploadModal: true });
  };

  handleChangeSort = (pagination, filters, sorter) => {
    this.setState({
      sortOrder: sorter.order,
      sortColumn: sorter.columnKey,
    });
  };

  showDocumentPreview = (record) => {
    if (record.valid_to !== null) {
      this.setState({
        validTo: moment(record.valid_to),
      });
    }
    if (record.valid_from !== null) {
      this.setState({
        validFrom: moment(record.valid_from),
      });
    }
    if (record.category !== null) {
      this.setState(
        {
          documentCategory: record.category,
        },
        () => {
          this.checkDocumentType(record);
        },
      );
    } else {
      this.setState(
        {
          documentCategory: null,
        },
        () => {
          this.checkDocumentType(record);
        },
      );
    }
    if (record.organisation_id !== null) {
      this.setState({
        documentOperator: record.organisation_id,
      });
    } else {
      this.setState({
        documentOperator: null,
      });
    }
  };

  checkDocumentType = (record) => {
    this.setState({
      documentType: record.content_type,
    });
    if (record.content_type.includes('pdf')) {
      this.setState({
        documentId: record.id,
        isDocumentViewVisible: true,
        previewHeight: window.innerHeight > 800 ? 600 : window.innerHeight * 0.7,
      });
    } else if (record.content_type.includes('image')) {
      this.setState({
        documentId: record.id,
        isDocumentViewVisible: true,
        previewHeight: '100%',
      });
    } else {
      this.setState({
        documentId: record.id,
        isDocumentViewVisible: true,
        previewHeight: '100%',
      });
    }
  };

  hideFileView = () => {
    this.setState({
      isDocumentViewVisible: false,
      validFrom: null,
      validTo: null,
      documentPreviewRoute: null,
      documentType: null,
      documentId: null,
      documentOperator: null,
    });
  };

  handleFileUpdate = () => {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss Z';
    const { dispatch } = this.props;
    const { validFrom, validTo, documentCategory } = this.state;
    const id = this.state.documentId;
    const formData = new FormData();
    if (validFrom) {
      formData.append('valid_from', moment(validFrom).format(dateFormat));
    } else {
      formData.append('valid_from', null);
    }
    if (validTo) {
      formData.append('valid_to', moment(validTo).format(dateFormat));
    } else {
      formData.append('valid_to', null);
    }
    if (documentCategory === '-') {
      formData.append('category', '');
    } else if (documentCategory !== null) {
      formData.append('category', documentCategory);
    }
    if (id !== null) {
      dispatch(
        updateDocuments({
          payload: {
            id,
            form: formData,
          },
        }),
      );
    }
  };

  sortArrByCol = (a, b) => {
    const columnKey = this.state.sortColumn;
    if (this.state.sortOrder === 'ascend') {
      if (a[columnKey].toLowerCase() < b[columnKey].toLowerCase()) {
        return -1;
      }
      if (a[columnKey].toLowerCase() > b[columnKey].toLowerCase()) {
        return 1;
      }
      return 0;
    }
    if (this.state.sortOrder === 'descend') {
      if (a[columnKey].toLowerCase() < b[columnKey].toLowerCase()) {
        return 1;
      }
      if (a[columnKey].toLowerCase() > b[columnKey].toLowerCase()) {
        return -1;
      }
      return 0;
    }
    return 0;
  };

  highlightRow = () => {
    const currentArr = Array.from(this.props.documentMap.values());

    currentArr.sort(this.sortArrByCol);

    const newestItemIndex = currentArr.findIndex((x) => x.id === this.state.newItemID);
    const stateObj = this.state;
    const pageSizeObj = stateObj.pageSize ? stateObj.pageSize : 10;
    let pageNum = 0;
    if (this.props.selectedView === 'grid') {
      pageNum = Math.floor(newestItemIndex / 24 + 1);
    } else {
      pageNum = Math.floor(newestItemIndex / pageSizeObj + 1);
    }

    this.setState(
      {
        activePage: pageNum,
        addedItemIndex: newestItemIndex,
      },
      () => {
        if (this.props.selectedView === 'grid' && this.state.newItemAdded) {
          if (document.getElementsByClassName(styles.highlightListImageContainer)[0]) {
            document.getElementsByClassName(styles.highlightListImageContainer)[0].scrollIntoView({
              block: 'start',
              inline: 'nearest',
            });
          }
        } else if (this.state.newItemAdded) {
          if (document.getElementsByClassName(styles.highlightedRow)[0]) {
            window.scrollTo(0, document.getElementsByClassName(styles.highlightedRow)[0].offsetTop);
          }
        }
        this.timeout = setTimeout(() => {
          this.setState({
            addedItemIndex: -1,
            newItemID: '',
            newItemAdded: false,
          });
        }, 4000);
      },
    );
  };

  confirmDelete = (id) => {
    const {
      intl: { formatMessage },
    } = this.props;
    Modal.confirm({
      title: formatMessage({ id: 'title.deleteItem' }),
      content: formatMessage({ id: 'form.question.areYouSureDelete' }),
      okText: formatMessage({ id: 'form.button.delete' }),
      cancelText: formatMessage({ id: 'form.button.cancel' }),
      onOk: () => this.removeDocument(id, true),
    });
  };

  removeDocument = (id, single) => {
    const {
      dispatch,
      intl: { formatMessage },
    } = this.props;
    const { selectedRowKeys } = this.state;
    const documentId = id;
    if (single) message.success(`1 ${formatMessage({ id: 'message.oneDocumentDeleted' })}`);
    if (single) {
      const newSelected = selectedRowKeys.filter((item) => item !== id).map((item) => item);
      this.setState({ selectedRowKeys: newSelected });
    }
    if (id !== null) {
      dispatch(
        removeDocument({
          payload: {
            id: documentId,
          },
        }),
      );
    }
  };

  handleTabChange = (key) => {
    this.props.dispatch(
      selectView({
        payload: {
          selectedView: key,
        },
      }),
    );
  };

  sortData = (a, b) => {
    if (a.file_name.toLowerCase() > b.file_name.toLowerCase()) return 1;
    if (a.file_name.toLowerCase() < b.file_name.toLowerCase()) return -1;
    return 0;
  };

  render() {
    const {
      match,
      intl: { formatMessage },
      userSettings,
      selectedView,
      documentMap,
      listAnimationClass,
      tableAnimationClass,
    } = this.props;
    const {
      selectedRowKeys,
      showDocumentUploadModal,
      loading,
      newItemID,
      columns,
      activePage,
      pageSize,
      addedItemIndex,
      documentId,
      isDocumentViewVisible,
      previewHeight,
      documentType,
      documentPreviewRoute,
      operatorList,
      documentOperator,
      validFrom,
      validTo,
      documentList,
      documentCategory,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };
    if (selectedRowKeys.length > 0) {
      rowSelection.selections = [
        {
          key: 'delete',
          text: 'Delete',
          onSelect: () => {
            Modal.confirm({
              title: formatMessage({ id: 'title.deleteItems' }),
              content: formatMessage({ id: 'form.question.areYouSureMultiDelete' }),
              okText: formatMessage({ id: 'form.button.delete' }),
              cancelText: formatMessage({ id: 'form.button.cancel' }),
              onOk: () => {
                if (selectedRowKeys && selectedRowKeys.length > 0) {
                  if (selectedRowKeys.length === 1) {
                    message.success(`1 ${formatMessage({ id: 'message.oneDocumentDeleted' })}`);
                  } else {
                    message.success(`${selectedRowKeys.length} ${formatMessage({ id: 'message.documentsDeleted' })}`);
                  }
                  selectedRowKeys.forEach((id) => {
                    this.removeDocument(id);
                  });
                  this.setState({ selectedRowKeys: [] });
                }
              },
            });
          },
        },
      ];
    }

    const tabList = [
      {
        key: 'table',
        tab: formatMessage({ id: 'form.button.table' }),
      },
      {
        key: 'grid',
        tab: formatMessage({ id: 'form.button.grid' }),
      },
    ];

    const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;

    return (
      <InnerMenuLayout loading={false}>
        <ExpandedContent
          displayTitle={formatMessage({ id: 'text.documents' })}
          tabList={tabList}
          tabActiveKey={selectedView}
          onTabChange={this.handleTabChange}
        />
        <Card bordered={false}>
          <div>
            <AircraftAuthenticationWrapper
              aircraftId={match.params.id}
              requiredResource={AircraftResource.DOCUMENT}
              requiredPermissionLevel={AircraftPermission.CREATE}
            >
              <Button
                type="primary"
                icon="plus"
                className={styles.addNewButton}
                onClick={() => {
                  this.handleShowUploadModal();
                }}
              >
                <span className={styles.buttonText}>{formatMessage({ id: 'form.button.addNewDocument' })}</span>
              </Button>
            </AircraftAuthenticationWrapper>
          </div>
          {selectedView === 'grid' ? (
            <div className={styles.gridListWrapper}>
              <List
                grid={{
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 3,
                  xl: 4,
                  xxl: 6,
                }}
                pagination={{
                  pageSize: 24,
                }}
                loading={loading}
                className={styles[listAnimationClass]}
                dataSource={Array.from(documentMap.values()).sort(this.sortData)}
                locale={{
                  emptyText: (
                    <div className={styles.emptyText}>
                      <img src={EmptyStateDocuments} alt="empty state" />
                      <span>No Data</span>
                    </div>
                  ),
                }}
                renderItem={(item) => {
                  return (
                    <List.Item
                      className={styles.singleListItem}
                      key={item.id}
                      extra={
                        <div
                          className={item.id === newItemID ? styles.highlightWrappedImageContainer : styles.itemWrapper}
                        >
                          <NonStyledButton
                            className={styles.imageContainer}
                            onClick={() => {
                              this.showDocumentPreview(item);
                            }}
                          >
                            <img
                              className={styles.imageItself}
                              src={`${servers.api}/${item.thumbnail_url}`}
                              alt={item.file_name}
                            />
                          </NonStyledButton>
                          <Button
                            type="link"
                            className={styles.textUnderImage}
                            onClick={() => {
                              this.showDocumentPreview(item);
                            }}
                          >
                            {item.file_name}
                          </Button>
                        </div>
                      }
                    />
                  );
                }}
              />
            </div>
          ) : (
            <>
              <Loading loading={loading} contain />
              <Table
                columns={columns}
                rowSelection={rowSelection}
                rowKey={(record) => record.id}
                className={styles[tableAnimationClass]}
                dataSource={Array.from(documentMap.values()).sort(this.sortData)}
                onChange={this.handleChangeSort}
                scroll={{ x: 1000, y: window.innerHeight * 0.6 }}
                pagination={{
                  showSizeChanger: true,
                  showQuickJumper: true,
                  current: activePage,
                  onChange: (page, size) => {
                    this.setState({
                      pageSize: size,
                      activePage: page,
                    });
                  },
                  onShowSizeChange: (page, size) => {
                    this.setState({
                      pageSize: size,
                      activePage: page,
                    });
                  },
                }}
                locale={{
                  emptyText: (
                    <div className={styles.emptyText}>
                      <img src={EmptyStateDocuments} alt="empty state" />
                      <span>No Data</span>
                    </div>
                  ),
                }}
                rowClassName={(record, index) =>
                  index === addedItemIndex - (activePage - 1) * (pageSize || 10) ? styles.highlightedRow : ''
                }
              />
            </>
          )}
        </Card>

        <Modal
          title={formatMessage({ id: 'title.filePreview' })}
          visible={isDocumentViewVisible}
          onCancel={() => {
            this.hideFileView();
          }}
          className={styles.fixedModal}
          destroyOnClose="true"
          footer={[
            <Popconfirm
              placement="bottom"
              onConfirm={() => {
                this.removeDocument(documentId);
              }}
              title={formatMessage({ id: 'title.confirmDelete' })}
              okText={formatMessage({ id: 'form.button.yes' })}
              cancelText={formatMessage({ id: 'form.button.cancel' })}
              key="confirmDelete"
            >
              <Button key="removeItem" className={styles.footerButton}>
                {formatMessage({ id: 'form.button.delete' })}
              </Button>
            </Popconfirm>,
            <Button
              key="openInNewTab"
              className={styles.footerButton}
              type="primary"
              onClick={() => {
                window.open(`${servers.api}/${documentPreviewRoute}`);
              }}
            >
              {formatMessage({ id: 'form.button.openInNewTab' })}
            </Button>,
          ]}
        >
          <div className={styles.modalFlexContainer}>
            <div className={styles.modalFlexPreviewContainer}>
              <iframe
                src={documentPreviewRoute?.size > 0 ? window.URL.createObjectURL(documentPreviewRoute) : null}
                title="Trustflight"
                width="100%"
                height={previewHeight}
                type={documentType}
              />
            </div>
            <div className={styles.modalFlexControlContainer}>
              {operatorList.length > 1 ? (
                <p>
                  <span className={styles.thickerText}>{formatMessage({ id: 'text.documentOperator' })}: </span>
                  {this.checkOperatorName(documentOperator)}
                </p>
              ) : null}
              <p>
                <span className={styles.thickerText}>{formatMessage({ id: 'text.documentValidRange' })}</span> (
                {formatMessage({ id: 'text.leaveBlank' })})
              </p>
              <div>
                <span className={styles.thickerText}>{formatMessage({ id: 'title.validFrom' })}</span>
                <span>{formatMessage({ id: 'form.optional' })}</span>
              </div>
              <DatePicker
                id="doc_valid_from"
                value={validFrom ? moment(validFrom) : null}
                allowEmpty
                format={dateFormat}
                disabledDate={this.disabledDateFrom}
                className={styles.datePicker}
                onChange={(date) => this.validFromChange(date)}
              />
              <div>
                <span className={styles.thickerText}>{formatMessage({ id: 'title.validTo' })}</span>
                <span>{formatMessage({ id: 'form.optional' })}</span>
              </div>
              <DatePicker
                format={dateFormat}
                value={validTo ? moment(validTo) : null}
                allowEmpty
                disabledDate={this.disabledDateTo}
                className={styles.datePicker}
                onChange={(date) => this.validToChange(date)}
              />
              <p>
                <span className={styles.thickerText}>{formatMessage({ id: 'text.documentCategory' })}</span> (
                {formatMessage({ id: 'text.leaveBlank' })})
              </p>
              <Select
                value={documentCategory || formatMessage({ id: 'text.noCategory' })}
                className={styles.viewerInputFirst}
                onChange={(d) => {
                  this.setState({ documentCategory: d });
                }}
              >
                <Option key="empty" value="">
                  {formatMessage({ id: 'text.noCategory' })}
                </Option>
                {documentList.map((element) =>
                  element === '' ? null : (
                    <Option key={element} value={element}>
                      {unSnakeify(element)}
                    </Option>
                  ),
                )}
              </Select>
              <Button
                key="submitUpdate"
                type="primary"
                onClick={() => {
                  this.handleFileUpdate();
                }}
                className={styles.updateModalButton}
              >
                {formatMessage({ id: 'form.button.update' })}
              </Button>
            </div>
          </div>
        </Modal>
        {showDocumentUploadModal ? (
          <DocumentUploadModal
            handleModalVisible={this.handleModalVisible}
            visible={showDocumentUploadModal}
            type="aircraft"
            aircraft={this.getAircraft()}
          />
        ) : null}
      </InnerMenuLayout>
    );
  }
}

const documentsWithRedux = connect(({ aircraft, aircraftDocuments, userSettings }) => ({
  documentMap: aircraftDocuments.aircraftDocumentsMap,
  userSettings,
  selectedView: userSettings.selectedView,
  tableAnimationClass: userSettings.tableAnimationClass,
  listAnimationClass: userSettings.listAnimationClass,
  aircraftPermissions: userSettings.details.people[0].permission_groups[1].permissions,
  aircraftMap: aircraft.aircraftMap,
}))(AircraftDocuments);
export default withRouter(injectIntl(documentsWithRedux));
