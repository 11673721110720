import { IntlFormatters } from 'react-intl';
import { intersection } from 'lodash';
import { UserRoles, PeoplePermissions, DashboardResource, UserPermission } from '../models/userSettings';
import reports from '../assets/mainNav/reports-02.svg';
import operations from '../assets/mainNav/operations-02.svg';
import fleet from '../assets/mainNav/fleet.svg';
import defects from '../assets/mainNav/defects.svg';
import crew from '../assets/mainNav/crew.svg';
import settings from '../assets/mainNav/operations.svg';
import maintenance from '../assets/mainNav/maintenance.svg';
import support from '../assets/mainNav/support.svg';
import { hasDashboardPermission } from '../components/_utils/AuthenticationWrapper';

export interface MenuItem {
  exact?: boolean;
  imgSrc?: string;
  label?: string;
  value: string;
  redirect?: string;
  authority?: UserRoles[];
  items?: MenuItem[];
  requiredResource?: DashboardResource;
  requiredPermission?: UserPermission;
}

// Menu structure. For routes, still refer to ./config/router.config.js
export default (
  formatMessage: IntlFormatters['formatMessage'],
  credentials: UserRoles[],
  peoplePermissions: PeoplePermissions[],
): MenuItem[] =>
  [
    // {
    //   exact: true,
    //   value: '/',
    //   redirect: '/dashboard',
    //   authority: [UserRoles.ADMIN],
    //   requiredResource: DashboardResource.DASHBOARD,
    //   requiredPermission: UserPermission.READ,
    // },
    {
      imgSrc: fleet,
      label: formatMessage({ id: 'menu.fleet' }),
      value: '/dashboard',
      authority: [UserRoles.ADMIN],
      requiredResource: DashboardResource.DASHBOARD,
      requiredPermission: UserPermission.READ,
    },
    {
      imgSrc: reports,
      label: formatMessage({ id: 'menu.reports' }),
      value: '/reports',
      authority: [UserRoles.ADMIN],
      items: [
        {
          exact: true,
          label: formatMessage({ id: 'menu.metrics' }),
          value: '/reports/metrics',
        },
        {
          exact: true,
          label: formatMessage({ id: 'menu.map' }),
          value: '/reports/map',
        },
        {
          exact: true,
          label: formatMessage({ id: 'menu.receipts' }),
          value: '/reports/receipts',
          requiredResource: DashboardResource.RECEIPT,
          requiredPermission: UserPermission.READ,
        },
      ],
    },
    {
      imgSrc: operations,
      label: formatMessage({ id: 'menu.operations' }),
      value: '/operations',
      authority: [UserRoles.ADMIN],
      items: [
        {
          exact: true,
          label: formatMessage({ id: 'menu.flights' }),
          value: '/operations/flights',
          requiredResource: DashboardResource.TRIP,
          requiredPermission: UserPermission.READ,
        },
        {
          exact: true,
          label: formatMessage({ id: 'menu.documents' }),
          value: '/operations/documents',
          requiredResource: DashboardResource.DOCUMENT,
          requiredPermission: UserPermission.READ,
        },
        {
          exact: true,
          label: formatMessage({ id: 'title.mel' }),
          value: '/operations/mels',
          requiredResource: DashboardResource.MEL,
          requiredPermission: UserPermission.READ,
        },
      ],
    },
    {
      imgSrc: defects,
      label: formatMessage({ id: 'menu.defects' }),
      value: '/defects',
      authority: [UserRoles.ADMIN],
      requiredResource: DashboardResource.DEFECT,
      requiredPermission: UserPermission.READ,
    },
    {
      imgSrc: maintenance,
      label: formatMessage({ id: 'menu.maintenance' }),
      value: '/maintenance',
      authority: [UserRoles.ADMIN],
      requiredResource: DashboardResource.MX_ITEM,
      requiredPermission: UserPermission.READ,
    },
    {
      imgSrc: crew,
      label: formatMessage({ id: 'menu.userSettings' }),
      value: '/people',
      authority: [UserRoles.ADMIN],
      exact: true,
      requiredResource: DashboardResource.PEOPLE,
      requiredPermission: UserPermission.READ,
    },
    {
      imgSrc: settings,
      label: formatMessage({ id: 'menu.settings' }),
      value: '/settings',
      authority: [UserRoles.ADMIN],
      items: [
        {
          label: formatMessage({ id: 'menu.aircraft' }),
          value: '/settings/aircraft',
          exact: true,
        },
        {
          label: formatMessage({ id: 'menu.integrations' }),
          value: '/settings/integrations',
          exact: true,
          requiredResource: DashboardResource.INTEGRATION,
          requiredPermission: UserPermission.READ,
        },
      ],
    },
    {
      imgSrc: support,
      label: 'Support',
      value: 'https://intercom.help/trustflight/en/collections/2751520-tech-log-dashboard',
      authority: [UserRoles.ADMIN],
    },
  ].filter((entry) => {
    if (entry.requiredPermission && entry.requiredResource) {
      return hasDashboardPermission(peoplePermissions, entry.requiredResource, entry.requiredPermission);
    }
    // TODO: turn this into a reduce function
    if (Array.isArray(entry.items)) {
      const newChildren = entry.items.filter((child) => {
        return (
          (!child.requiredPermission && !child.requiredResource) ||
          hasDashboardPermission(peoplePermissions, child.requiredResource, child.requiredPermission)
        );
      });
      // eslint-disable-next-line no-param-reassign
      entry.items = newChildren;
      return newChildren.length;
    }
    // Simple persmission checking to determine which pages of the dashboard the user is able to view.
    return intersection(entry.authority, credentials).length > 0;
  });
