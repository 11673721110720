import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { MELItem } from '../../../models/mels';
import remarksIcon from '../../../assets/icon-remarks.svg';
import placardsIcon from '../../../assets/icon-placards.svg';
import opIcon from '../../../assets/icon-operational-procedure.svg';
import Card from './Card';
import ConfirmationCard from './ConfirmationCard';
import Label from './Label';
import FlexWrapper from './FlexWrapper';
import StyledInput from './StyledInput';

interface ConfirmSectionProps {
  defect: Defect | null;
  melItem: MELItem;
  setErrorMessage?: (input: string) => void;
}

const ConfirmSection: React.FC<ConfirmSectionProps> = ({ defect, melItem, setErrorMessage }) => {
  const [remarks, setRemarks] = useState([]);
  const [placards, setPlacards] = useState([]);
  const [operationalProcedures, setOperationalProcedures] = useState([]);

  const { formatMessage } = useIntl();
  const placardsExtraContent = (
    <FlexWrapper marginTop={30} column>
      <Label fontWeight={600} marginBottom={5}>
        {formatMessage({ id: 'text.placardsNumberOptional' })}
      </Label>
      <StyledInput id="pacard number" type="text" placeholder="Please enter placards number..." />
    </FlexWrapper>
  );

  useEffect(() => {
    if (defect && defect.mel_item) {
      const { mel_item } = defect;

      setRemarks(mel_item?.mel_rectifications?.map((rectification) => rectification.remarks));

      setPlacards(mel_item?.mel_rectifications?.map((rectification) => rectification.placard_procedure));

      setOperationalProcedures(
        mel_item?.mel_rectifications?.map((rectification) => rectification.operational_procedure),
      );
    }
  }, [defect]);

  useEffect(() => {
    if (melItem) {
      setRemarks(melItem?.mel_rectifications.map((rectification) => rectification.remarks));

      setPlacards(melItem?.mel_rectifications.map((rectification) => rectification.placard_procedure));

      setOperationalProcedures(melItem?.mel_rectifications.map((rectification) => rectification.operational_procedure));
    }
  }, [melItem]);

  return (
    <>
      {((remarks && remarks.length > 0) ||
        (placards && placards.length > 0) ||
        (operationalProcedures && operationalProcedures.length > 0)) && (
        <Card marginTop={30}>
          <Label fontWeight={600} identifier="ConfirmSectionTitle">
            {formatMessage({ id: 'text.confirmReviewedAndActioned' })}
          </Label>
          {!remarks.includes(null) && (
            <ConfirmationCard title="Remarks" text={remarks} icon={remarksIcon} setErrorMessage={setErrorMessage} />
          )}
          {!placards.includes(null) && (
            <ConfirmationCard
              title="Placards"
              text={placards}
              icon={placardsIcon}
              extraContent={placardsExtraContent}
              setErrorMessage={setErrorMessage}
            />
          )}
          {!operationalProcedures.includes(null) && (
            <ConfirmationCard
              title="OperationalProcedures"
              text={operationalProcedures}
              icon={opIcon}
              setErrorMessage={setErrorMessage}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default ConfirmSection;
