import { BaseModel } from '..';

const initialState: TicketsState = {
  ticketsMap: new Map(),
  lastFetched: 0,
  groups: {
    list: [],
    lastFetched: 0,
  },
  params: {},
  filters: {},
  pagination: {},
};

export enum TicketsActionTypes {
  SAVE_ALL = 'tickets/saveAll',
  SAVE_GROUPS = 'tickets/saveGroups',
  SAVE = 'tickets/save',
  SAVE_FILTERS = 'defects/saveFilters',
  SAVE_PARAMS = 'defects/saveParams',
}

interface SaveAllTicketsActionTypes {
  type: TicketsActionTypes.SAVE_ALL;
  payload: { list: Ticket[]; pagination: {} };
}

export interface SaveFiltersEphemeralTicketsActionType {
  type: TicketsActionTypes.SAVE_FILTERS;
  payload: Ticket;
}

export interface SaveParamsTicketsActionType {
  type: TicketsActionTypes.SAVE_PARAMS;
  payload: Ticket;
}

interface SaveGroupsTicketsActionTypes {
  type: TicketsActionTypes.SAVE_GROUPS;
  payload: [];
}

interface SaveTicketsActionTypes {
  type: TicketsActionTypes.SAVE;
  payload: Ticket;
}

type TicketsAction =
  | SaveAllTicketsActionTypes
  | SaveGroupsTicketsActionTypes
  | SaveTicketsActionTypes
  | SaveFiltersEphemeralTicketsActionType
  | SaveParamsTicketsActionType;

export default function(state = initialState, action: TicketsAction): TicketsState {
  switch (action.type) {
    case TicketsActionTypes.SAVE_ALL: {
      const ticketsMap = new Map();
      if (action.payload && 'list' in action.payload && Array.isArray(action.payload.list)) {
        action.payload.list.forEach((ticket) => {
          ticketsMap.set(ticket.id, {
            ...ticket,
          });
        });
      }
      return {
        ...state,
        ticketsMap,
        lastFetched: Date.now(),
        pagination: action.payload.pagination || {},
      };
    }
    case TicketsActionTypes.SAVE_GROUPS: {
      return {
        ...state,
        groups: {
          list: action.payload,
          lastFetched: Date.now(),
        },
      };
    }
    case TicketsActionTypes.SAVE: {
      const ticketsMap = new Map(state.ticketsMap.entries());
      if (action.payload && action.payload.id) {
        ticketsMap.set(action.payload.id, {
          ...ticketsMap.get(action.payload.id),
          ...action.payload,
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        ticketsMap,
        lastFetched: Date.now(),
      };
    }
    case TicketsActionTypes.SAVE_PARAMS: {
      return {
        ...state,
        params: action.payload,
      };
    }
    case TicketsActionTypes.SAVE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface TicketsState {
  ticketsMap: Map<string, Ticket>;
  lastFetched: number;
  groups: {
    list: [];
    lastFetched: number;
  };
  pagination: {};
  params: {};
  filters: {};
}

export interface Ticket extends BaseModel {
  id: string;
}
