import { Button, Card, Col, message, Row } from 'antd';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Sticky from 'react-stickynode';
import Loading from '../../components/TFLoading';
import FiltersDrawer from '../../components/FiltersDrawer';
import FiltersSidebar from '../../components/FiltersSidebar';
import MXItemsImporter from '../../components/MXItemsImporter';
import ListFilter from '../../components/MXManagementList/listFilter';
import ListWrapper from '../../components/MXManagementList/listWrapper';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import PageOverlayAction from '../../components/PageOverlayAction';
import CampSyncButton from '../../components/CampSyncButton';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { Aircraft, AircraftState, AircraftPermission, AircraftResource } from '../../models/aircraft';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { getAllWorkpacks } from '../../models/workpacks/actions';
import { getAllMxItems, getAllDraftMxItems } from '../../models/maintenance/actions';
import globalStyles from '../../utils/globalStyles.module.less';
import { MaintenanceState } from '../../models/maintenance';
import { WorkpacksState } from '../../models/workpacks';
import MXModal from '../../components/Modals/Maintenance/MXModal';
import { syncWithCamp } from '../../services/apiNew';
import MXModalTolerance from '../../components/Modals/Maintenance/MXModalTolerance';
import MXDraftQueue from '../../components/Modals/Maintenance/MXDraftQueue/MXDraftQueue';
import ExpandedContent from '../../components/TopNavWrapper/ExpandedContent';
import styles from './MaintenanceManagement.module.less';

// MX Periods to be shown in dropdown
export enum MX_LIST_PERIODS {
  CRITICAL = 'crit',
  OVERDUE = 0,
  NEXT_DAY = 1,
  NEXT_3_DAYS = 3,
  NEXT_10_DAYS = 10,
  NEXT_30_DAYS = 30,
  NEXT_60_DAYS = 60,
  NEXT_120_DAYS = 120,
  NEXT_365_DAYS = 365,
  ALL_RECORDS = 1000000,
}

const MX_PERIODS = [
  {
    days: 3,
    listPeriods: [MX_LIST_PERIODS.OVERDUE, MX_LIST_PERIODS.NEXT_DAY, MX_LIST_PERIODS.NEXT_3_DAYS],
  },
  {
    days: 10,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
    ],
  },
  {
    days: 30,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
    ],
  },
  {
    days: 60,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
    ],
  },
  {
    days: 120,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
      MX_LIST_PERIODS.NEXT_120_DAYS,
    ],
  },
  {
    days: 1000000,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
      MX_LIST_PERIODS.NEXT_120_DAYS,
      MX_LIST_PERIODS.NEXT_365_DAYS,
      MX_LIST_PERIODS.ALL_RECORDS,
    ],
  },
];

class MaintenanceManagement extends Component<MaintenanceManagementProps, MaintenanceManagementState> {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        show: [],
        itemType: [],
        area: [],
        source: [],
        limitations: [],
        planning: [],
        tolerance: [],
      },
      searchFilter: null,
      // default maintenance period for MX filter dropdown in maintenance timeline
      mxPeriodDays: 1000000,
      selectedMXItems: [],
      selectedDraftMXItems: [],
      loading: false,
      mxLoading: false,
      modalVisible: false,
      modalToleranceVisible: false,
      modalDraftsVisible: false,
      editedItem: null,
      synchronising: false,
      passedMxItemId: '',
    };
  }

  componentDidMount(): void {
    const {
      match: {
        params: { id },
      },
      location: { state },
      ttl,
    } = this.props;

    if (state) {
      this.setFiltersBasedOnParams(state);
      if (state.id !== null) {
        this.setPassedMxItemId(state.id);
      }
    }

    const aircraft = this.getAircraft();
    if (!aircraft || Date.now() - aircraft.lastFetched > ttl) {
      this.getAircraft(true);
    } else {
      this.setState({
        loading: false,
      });
    }
    if (id) {
      this.getMaintenanceContents(id, true);
    }
  }

  componentDidUpdate(prevProps, prevState): void {
    const {
      aircraftMap,
      match: {
        params: { id },
      },
    } = this.props;

    const { editedItem, modalToleranceVisible } = this.state;

    aircraftMap.get(id);
    if (aircraftMap.get(id) !== prevProps.aircraftMap.get(id)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: false,
      });
    }
    if (
      id !== prevProps.match.params.id ||
      (prevState.editedItem !== null && editedItem !== prevState.editedItem && !modalToleranceVisible)
    ) {
      this.getMaintenanceContents(id, true);
    }
  }

  setFiltersBasedOnParams = (state): void => {
    const { key, value } = state;
    const { filters } = this.state;
    const newFilters = { ...filters };
    newFilters[key] = [value];
    this.setState({
      filters: newFilters,
    });
  };

  setPassedMxItemId = (id): any => {
    this.setState({ passedMxItemId: id });
  };

  mxItemsLoaded = (): void => {
    this.setState({
      mxLoading: false,
    });
  };

  onFilterChange = (filterKey, groupKey): void => {
    const { filters } = this.state;
    if (filters[groupKey].includes(filterKey)) {
      // Item is in array -> it's active -> remove it from array to switch off
      filters[groupKey] = filters[groupKey].filter((item) => item !== filterKey);
    } else {
      // Item is not in array -> it's closed -> add it to array to switch on
      if (groupKey === 'planning' || groupKey === 'tolerance') {
        // Allows you to toggle filter items, rather than applying a number of them at once.
        filters[groupKey] = [];
      }
      filters[groupKey].push(filterKey);
    }
    this.setState({ filters });
  };

  onFiltersClear = (groupKey): void => {
    const { filters } = this.state;
    const clearedFilters = { ...filters };
    clearedFilters[groupKey] = [];
    this.setState({ filters: clearedFilters });
  };

  onSearchChange = (value): void => {
    this.setState({
      searchFilter: value,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDateChange = (value, days): void => {
    this.setState(
      {
        mxPeriodDays: days,
      },
      () => {
        this.getMaintenanceContents(this.props.match.params.id, false);
      },
    );
  };

  onItemSelect = (value, itemID): void => {
    const { selectedMXItems: activeMXItems } = this.state;

    if (value) {
      activeMXItems.push(itemID);
    } else {
      const itemIndex = activeMXItems.indexOf(itemID);
      if (itemIndex > -1) {
        activeMXItems.splice(itemIndex, 1);
      }
    }
    this.setState({
      selectedMXItems: [...new Set(activeMXItems)],
    });
  };

  onDraftItemSelect = (value, itemID): void => {
    const { selectedDraftMXItems: activeDraftMXItems } = this.state;

    if (value) {
      activeDraftMXItems.push(itemID);
    } else {
      const itemIndex = activeDraftMXItems.indexOf(itemID);
      if (itemIndex > -1) {
        activeDraftMXItems.splice(itemIndex, 1);
      }
    }
    this.setState({
      selectedDraftMXItems: [...new Set(activeDraftMXItems)],
    });
  };

  getAircraft = (
    forceRefetch = false,
  ): {
    id: string;
    registration: string;
    lastFetched: number;
    camp_integration_enabled: boolean;
    camp_last_synced: string;
  } => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (forceRefetch) {
      this.setState(
        {
          loading: true,
        },
        () => this.props.fetchAircraft(id),
      );
    }
    return this.props.aircraftMap.get(id);
  };

  getMaintenanceContents = async (id, fetchWorkpacks, skipDeleteAll = false, forceReplace = false): Promise<void> => {
    const { mxPeriodDays } = this.state;
    await new Promise((resolve) => this.setState({ mxLoading: true }, resolve));
    if (fetchWorkpacks) {
      await this.props.getAllWorkpacks({
        aircraft_id: id,
      });
    }
    // from_date is 100 years behind as we want to get all overdue items from past
    if (mxPeriodDays === MX_LIST_PERIODS.ALL_RECORDS) {
      await this.props.getAllMxItems(
        {
          aircraft_id: id,
        },
        skipDeleteAll,
        forceReplace,
      );
    } else {
      await this.props.getAllMxItems(
        {
          aircraft_id: id,
          from_date: moment()
            .subtract(100, 'years')
            .format('YYYY-MM-DD'),
          to_date: moment()
            .add(mxPeriodDays, 'days')
            .format('YYYY-MM-DD'),
        },
        skipDeleteAll,
        forceReplace,
      );
    }
    await this.props.getAllDraftMxItems(
      {
        aircraft_id: id,
        from_date: moment()
          .subtract(100, 'years')
          .format('YYYY-MM-DD'),
        to_date: moment()
          .add(1000000, 'days')
          .format('YYYY-MM-DD'),
      },
      skipDeleteAll,
      forceReplace,
    );
    await new Promise((resolve) => this.setState({ mxLoading: false }, resolve));
  };

  getFilteredMXItems = (): any[] => {
    const { approvedMxItems } = this.props;
    const { filters, searchFilter, selectedMXItems } = this.state;
    const { itemType, area, source, limitations, planning, tolerance } = filters;
    const filteredItems = approvedMxItems
      .map((item) => {
        if (
          itemType.length === 0 &&
          area.length === 0 &&
          source.length === 0 &&
          limitations.length === 0 &&
          planning.length === 0 &&
          tolerance.length === 0 &&
          !searchFilter
        ) {
          // No filters
          return item;
        }
        let filteredItem = null;
        if (searchFilter) {
          // SEARCH filter (input) active
          Object.keys(item).forEach((key) => {
            if (
              typeof item[key] === 'string' &&
              key !== 'id' &&
              item[key].toLowerCase().includes(searchFilter.toLowerCase())
            ) {
              filteredItem = { ...item };
            } else if (key === 'package' && item[key] === true && 'package'.includes(searchFilter.toLowerCase())) {
              filteredItem = { ...item };
            }
          });
        } else {
          filteredItem = { ...item };
        }

        if (
          itemType.length === 0 &&
          area.length === 0 &&
          source.length === 0 &&
          limitations.length === 0 &&
          planning.length === 0 &&
          tolerance.length === 0 &&
          searchFilter
        ) {
          // Only search filter is active, return item and don't do further checks
          return filteredItem;
        }

        if (filteredItem && itemType.length > 0) {
          // ITEM TYPE active
          if (!this.checkItemInFilter(itemType, filteredItem, 'mx_type')) {
            filteredItem = null;
          }
        }
        if (filteredItem && area.length > 0) {
          // AREA filter active
          if (!this.checkItemInFilter(area, filteredItem, 'mx_type')) {
            filteredItem = null;
          }
        }
        if (filteredItem && source.length > 0) {
          // SOURCE filter active
          if (
            !(
              this.checkItemInFilter(source, filteredItem, 'app_source') ||
              this.checkItemInFilter(source, filteredItem, 'source')
            )
          ) {
            filteredItem = null;
          }
        }
        if (filteredItem && limitations.length > 0) {
          // LIMITATIONS filter active
          if (!this.checkItemLimitations(limitations, filteredItem)) {
            filteredItem = null;
          }
        }

        if (filteredItem && planning.length > 0) {
          // PLANNING filter active
          if (
            !(
              this.checkItemInFilter(planning, filteredItem, 'planned') ||
              this.checkItemInFilter(planning, filteredItem, 'unplanned')
            )
          ) {
            filteredItem = null;
          }
        }

        if (filteredItem && tolerance.length > 0) {
          // TOLERANCE filter active
          if (
            !(
              this.checkItemInFilter(tolerance, filteredItem, 'tolApplied') ||
              this.checkItemInFilter(tolerance, filteredItem, 'tolNotApplied')
            )
          ) {
            filteredItem = null;
          }
        }
        return filteredItem;
      })
      .filter((el) => el !== null);

    // Make sure that results uncheck ay checked and hidden items
    const filteredIDs = filteredItems.map((item) => item.id);
    const selectedIDs = selectedMXItems.filter((item) => filteredIDs.includes(item));
    if (selectedMXItems.length !== selectedIDs.length) {
      this.setState({
        selectedMXItems: selectedIDs,
      });
    }

    return filteredItems;
  };

  getDraftWithCamp = (): any[] => {
    const { draftMxItems, approvedMxItems } = this.props;
    const campChanges = approvedMxItems.filter((item: any) => item.camp_diffs.length > 0);
    const newItems = draftMxItems.concat(campChanges);
    return newItems;
  };

  checkItemInFilter = (filterValues, item, itemKey): boolean => {
    let itemCheck = false;
    filterValues.forEach((val) => {
      if (val === 'pkg' && item.package) {
        itemCheck = true;
      } else if (itemKey === 'app_source') {
        if ((val === 'dash' || val === 'tl') && item.source === 'core') {
          itemCheck = true;
        }
      } else if (item[itemKey] && item[itemKey].includes(val) && !item.package) {
        itemCheck = true;
      } else if (val === 'planned') {
        if (item.workpacks.length > 0) {
          itemCheck = true;
        }
      } else if (val === 'unplanned') {
        if (item.workpacks.length === 0) {
          itemCheck = true;
        }
      } else if (val === 'tolApplied') {
        if (
          item.months_visible_tolerance > 0 ||
          item.flight_seconds_visible_tolerance > 0 ||
          item.days_visible_tolerance > 0 ||
          item.cycles_visible_tolerance > 0 ||
          item.apu_seconds_visible_tolerance > 0
        ) {
          itemCheck = true;
        }
      } else if (val === 'tolNotApplied') {
        if (
          item.months_visible_tolerance === 0 &&
          item.flight_seconds_visible_tolerance === 0 &&
          item.days_visible_tolerance === 0 &&
          item.cycles_visible_tolerance === 0 &&
          item.apu_seconds_visible_tolerance === 0
        ) {
          itemCheck = true;
        }
      }
    });
    return itemCheck;
  };

  checkItemLimitations = (appliedLimitations, filteredItem): boolean => {
    const limitationDictionary = {
      cycles: filteredItem.cycles_due,
      days: filteredItem.date_due,
      flightHours: filteredItem.flight_seconds_due,
      APUHours: filteredItem.apu_seconds_due,
    };

    const allLimitations = ['cycles', 'days', 'flightHours', 'APUHours'];

    // These filters work slightly differently, as they can all be applied at once.
    // this is ugly and convoluted because this component is so badly written.
    const limitationsToFilter = allLimitations.reduce((acc: (boolean | string)[], currentLimitation: string) => {
      if (appliedLimitations.includes(currentLimitation)) {
        // add item to filter if its been clicked.
        if (limitationDictionary[currentLimitation] !== null) {
          acc.push(true);
        } else {
          acc.push(false);
        }
      }
      return acc;
    }, []);
    return limitationsToFilter.every((item: boolean) => item === true);
  };

  getSortedItems = (items): Array<{ status: string; estimated_date: Moment }> => {
    return items.sort((a, b) => {
      const aEstimatedDate = a.estimated_date || moment().format('YYYY-MM-DD');
      const bEstimatedDate = b.estimated_date || moment().format('YYYY-MM-DD');
      const dateA = Number(moment(aEstimatedDate).format('X'));
      const dateB = Number(moment(bEstimatedDate).format('X'));
      return dateA - dateB;
    });
  };

  getItemsSeparatedByStatus = (items): { overdueItems; criticalItems; standardItems } => {
    const mxPeriods = MX_PERIODS;
    const { mxPeriodDays } = this.state;
    const { listPeriods } = mxPeriods.find(({ days }) => days === mxPeriodDays);
    const overdueItems = [];
    const criticalItems = [];
    const standardItems = [];
    this.getSortedItems(items).forEach((item) => {
      const estimatedDate = item.estimated_date || moment().format('YYYY-MM-DD');
      const itemRemainingDays = moment
        .duration(moment(estimatedDate).diff(moment().format('YYYY-MM-DD'), 'days'), 'days')
        .asDays();
      if (item.status === 'overdue') {
        overdueItems.push(item);
      } else if (item.status === 'critical') {
        criticalItems.push(item);
      } else {
        listPeriods.forEach((period, key) => {
          if (itemRemainingDays < period && itemRemainingDays >= listPeriods[key - 1]) {
            // periods with lower and upper bounds
            standardItems.push(item);
            return true;
          }
          return false;
        });
      }
    });
    return { overdueItems, criticalItems, standardItems };
  };

  cancelDraftsApproval = (): void => {
    this.setState({
      selectedDraftMXItems: [],
    });
  };

  onDraftsSuccess = (): void => {
    const aircraft = this.getAircraft();
    this.getMaintenanceContents(aircraft.id, false, true, true);
  };

  cancelWorkpack = (): void => {
    this.setState({
      selectedMXItems: [],
    });
  };

  onWorkpackSuccess = (): void => {
    this.setState({
      selectedMXItems: [],
    });
    const aircraft = this.getAircraft();
    this.getMaintenanceContents(aircraft.id, true, true);
  };

  onDeleteSuccess = (): void => {
    const aircraft = this.getAircraft(true);
    this.getMaintenanceContents(aircraft.id, false, true, true);
  };

  modalSuccess = async (estimatedDate): Promise<void> => {
    const { mxPeriodDays } = this.state;
    const aircraft = this.getAircraft();
    const today = moment().startOf('day');
    const daysUntilNewMXItem = moment(estimatedDate).diff(today, 'days');
    let days = mxPeriodDays;
    if (daysUntilNewMXItem > mxPeriodDays) {
      if (daysUntilNewMXItem >= 0 && daysUntilNewMXItem <= 3) {
        days = 3;
      } else if (daysUntilNewMXItem > 3 && daysUntilNewMXItem <= 10) {
        days = 10;
      } else if (daysUntilNewMXItem > 10 && daysUntilNewMXItem <= 30) {
        days = 30;
      } else if (daysUntilNewMXItem > 30 && daysUntilNewMXItem <= 60) {
        days = 60;
      } else if (daysUntilNewMXItem > 60 && daysUntilNewMXItem <= 120) {
        days = 120;
      } else {
        days = 100000;
      }
      await this.setState({ mxPeriodDays: days });
    }
    this.getMaintenanceContents(aircraft.id, false, true, true);
  };

  hideModal = (): void => {
    this.setState({
      modalVisible: false,
      modalToleranceVisible: false,
      modalDraftsVisible: false,
      editedItem: null,
    });
  };

  onEdit = (item): void => {
    this.setState({
      modalVisible: true,
      editedItem: item,
    });
  };

  onApplyTolerance = (item): void => {
    this.setState({
      modalToleranceVisible: true,
      editedItem: item,
    });
  };

  showMXDraftQueue = (): void => {
    this.setState({ modalDraftsVisible: true });
  };

  handleSyncButtonClick = (): void => {
    this.setState({ synchronising: true });
    const {
      match: {
        params: { id },
      },
    } = this.props;
    syncWithCamp(id)
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          this.setState({ synchronising: false }, () => {
            this.getAircraft(true);
          });
        } else {
          message.error('Unable to synchronise. Please try again');
        }
      })
      .catch(() => {
        message.error('Unable to synchronise. Please try again');
        this.setState({ synchronising: false });
      });
  };

  render(): object {
    const {
      intl: { formatMessage },
      approvedMxItems,
      draftMxItems,
      workpacksArray,
      mxFetched,
    } = this.props;
    const {
      filters,
      searchFilter,
      mxPeriodDays,
      loading,
      mxLoading,
      selectedDraftMXItems,
      selectedMXItems,
      modalVisible,
      editedItem,
      modalToleranceVisible,
      modalDraftsVisible,
      synchronising,
      passedMxItemId,
    } = this.state;
    const aircraft = this.getAircraft();
    const mxItems = this.getFilteredMXItems();
    const filteredItems = this.getItemsSeparatedByStatus(mxItems);

    const draftWithCampChangeItems = this.getDraftWithCamp();

    return (
      <InnerMenuLayout>
        <ExpandedContent
          displayTitle={formatMessage({ id: 'title.maintenance' })}
          action={
            <AircraftAuthenticationWrapper
              aircraftId={aircraft && aircraft.id}
              requiredResource={AircraftResource.SCHEDULED_MX_ITEM}
              requiredPermissionLevel={AircraftPermission.CREATE}
            >
              <Button
                type="primary"
                className={globalStyles.addNewButton}
                icon="plus"
                onClick={(): void => {
                  this.setState({
                    modalVisible: true,
                  });
                }}
                data-test="addNewButton"
              >
                <span className={globalStyles.buttonText}>{formatMessage({ id: 'form.button.addNewItem' })}</span>
              </Button>
            </AircraftAuthenticationWrapper>
          }
          layout="modern"
          data-test="pageHeader"
        />
        <Loading loading={loading || mxLoading || !mxFetched} contain topPosition={60} />
        <PageOverlayAction
          visible={selectedMXItems ? selectedMXItems.length > 0 : false}
          type="workpack"
          workpacks={workpacksArray}
          onSuccess={(): void => this.onWorkpackSuccess()}
          items={selectedMXItems}
          aircraft={aircraft}
          onCancel={(): void => this.cancelWorkpack()}
          data-test="pageOverlayWorkpack"
        />
        <MXModal
          aircraft={aircraft}
          visible={modalVisible}
          hideModal={(): void => this.hideModal()}
          onSuccess={(estimatedDate): Promise<void> => this.modalSuccess(estimatedDate)}
          item={editedItem}
          data-test="mxModal"
        />
        <MXModalTolerance
          aircraft={aircraft}
          visible={modalToleranceVisible}
          hideModal={(): void => this.hideModal()}
          item={editedItem}
          data-test="mxModalTolerance"
        />
        <MXDraftQueue
          aircraft={aircraft}
          visible={modalDraftsVisible}
          items={draftWithCampChangeItems}
          onSuccess={(): void => this.onDraftsSuccess()}
          hideModal={(): void => this.hideModal()}
        />
        <div className={styles.titleWrapper}>
          <div className={`${globalStyles.overCardTitle} ${styles.overCardTitle}`}>
            {formatMessage({ id: 'title.timeline' })}
          </div>
          {aircraft && !mxLoading && aircraft.camp_last_synced && (
            <CampSyncButton
              camp_last_synced={aircraft.camp_last_synced}
              synchronising={synchronising}
              formatMessage={formatMessage}
              handleSyncButtonClick={this.handleSyncButtonClick}
            />
          )}
        </div>
        <Card className={globalStyles.pageCard}>
          <div className={styles.layoutDiv}>
            <div className={styles.mainCol}>
              <Row>
                <Col md={24} className={styles.mxItemsImporter}>
                  <MXItemsImporter items={draftWithCampChangeItems} onActionClick={this.showMXDraftQueue} />
                </Col>
                <Col md={24} className={styles.mxListCol}>
                  <ListFilter
                    mxPeriods={MX_PERIODS}
                    mxPeriodsDays={mxPeriodDays}
                    onSearchChange={(value): void => this.onSearchChange(value)}
                    onDateChange={(value, days): void => this.onDateChange(value, days)}
                  />
                  <div className={styles.filtersDrawerWrapper}>
                    <FiltersDrawer
                      aircraft={aircraft as Aircraft}
                      mxItems={approvedMxItems}
                      filters={filters}
                      mxPeriods={MX_PERIODS}
                      mxPeriodsDays={mxPeriodDays}
                      onFilterChange={(filterKey, groupKey): void => this.onFilterChange(filterKey, groupKey)}
                      onFiltersClear={(groupKey): void => this.onFiltersClear(groupKey)}
                      onDateChange={(value, days): void => this.onDateChange(value, days)}
                    />
                  </div>
                  <ListWrapper
                    items={filteredItems}
                    filtersSearchActive={
                      (searchFilter && searchFilter.length > 0) ||
                      filters.show.length > 0 ||
                      filters.itemType.length > 0 ||
                      filters.area.length > 0 ||
                      filters.source.length > 0
                    }
                    aircraft={aircraft as Aircraft}
                    selectedItems={selectedMXItems}
                    onItemSelect={(value, itemID): void => this.onItemSelect(value, itemID)}
                    mxPeriods={MX_PERIODS}
                    mxFetched={mxFetched}
                    mxPeriodDays={mxPeriodDays}
                    disabledCheckbox={selectedDraftMXItems.length > 0}
                    onEdit={(item): void => this.onEdit(item)}
                    onDeleteSuccess={(): void => this.onDeleteSuccess()}
                    onApplyTolerance={(item): void => this.onApplyTolerance(item)}
                    expandAll={filters.show.includes('expandAll')}
                    expandItem={passedMxItemId}
                    setPassedMxItemId={this.setPassedMxItemId}
                  />
                </Col>
              </Row>
            </div>
            <div className={styles.filtersCol}>
              <Sticky top={80} className={styles.stickyFilters} bottomBoundary="#cardBottomBoundary">
                <FiltersSidebar
                  aircraft={aircraft}
                  allItems={approvedMxItems}
                  activeFilters={filters}
                  onFilterChange={(filterKey, groupKey): void => this.onFilterChange(filterKey, groupKey)}
                  onFiltersClear={(groupKey): void => this.onFiltersClear(groupKey)}
                  data-test="filtersSidebar"
                />
              </Sticky>
            </div>
          </div>
          <div id="cardBottomBoundary" className={styles.bottomBoundary} />
        </Card>
      </InnerMenuLayout>
    );
  }
}

const mapStateToProps = (
  {
    aircraft,
    maintenance,
    workpacks,
  }: { aircraft: AircraftState; maintenance: MaintenanceState; workpacks: WorkpacksState },
  { match },
): {
  ttl: number;
  mxFetched: boolean;
  aircraftMap: Map<
    string,
    {
      id: string;
      registration: string;
      lastFetched: number;
      camp_integration_enabled: boolean;
      camp_last_synced: string;
    }
  >;
  approvedMxItems: any[];
  draftMxItems: any[];
  workpacksArray: {}[];
} => {
  const approvedMxItems = Array.from(maintenance.activeMaintenanceMap.values()).sort((a, b) => {
    const statusOrder = b.status.localeCompare(a.status);
    if (statusOrder === 0) {
      return a.id.localeCompare(b.id);
    }
    return statusOrder;
  });
  const draftMxItems = Array.from(maintenance.draftMaintenanceMap.values()).sort((a, b) => {
    const statusOrder = b.status.localeCompare(a.status);
    if (statusOrder === 0) {
      return a.id.localeCompare(b.id);
    }
    return statusOrder;
  });
  return {
    aircraftMap: aircraft.aircraftMap,
    ttl: aircraft.ttl,
    approvedMxItems,
    draftMxItems,
    mxFetched: maintenance.fetched,
    workpacksArray: Array.from(workpacks.workpacksMap.values()).filter(
      (pack) => pack.aircraft_id === match.params.id && pack.status !== 'complete',
    ),
  };
};

const mapDispatchToProps = (
  dispatch,
): {
  fetchAircraft: (id: string) => Promise<void>;
  getAllMxItems: (
    payload: { aircraft_id: string; from_date?: string; to_date?: string },
    skipDeleteAll: boolean,
    forceReplace: boolean,
  ) => Promise<void>;
  getAllDraftMxItems: (
    payload: { aircraft_id: string; from_date: string; to_date: string },
    skipDeleteAll: boolean,
    forceReplace: boolean,
  ) => Promise<void>;
  getAllWorkpacks: (payload: { aircraft_id: string }) => Promise<void>;
} => ({
  fetchAircraft: (id): Promise<void> => {
    return dispatch(
      getSingleAircraft({
        payload: id,
      }),
    );
  },
  getAllWorkpacks: (payload): Promise<void> => {
    return dispatch(
      getAllWorkpacks({
        payload,
      }),
    );
  },
  getAllMxItems: (payload, skipDeleteAll, forceReplace): Promise<void> => {
    return dispatch(
      getAllMxItems({
        payload,
        skipDeleteAll,
        forceReplace,
      }),
    );
  },
  getAllDraftMxItems: (payload): Promise<void> => {
    return dispatch(
      getAllDraftMxItems({
        payload,
      }),
    );
  },
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(MaintenanceManagement)));

export type MaintenanceManagementProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps<'intl'> &
  RouteComponentProps<{ id: string }, any, LocationState | any>;

export type MaintenanceManagementState = {
  filters: {
    show: {}[];
    itemType: string[];
    area: Array<[]>;
    source: Array<[]>;
    limitations: Array<[]>;
    planning: Array<[]>;
    tolerance: Array<[]>;
  };
  searchFilter: string;
  mxPeriodDays: number;
  selectedMXItems: {}[];
  selectedDraftMXItems: {}[];
  loading: boolean;
  mxLoading: boolean;
  modalVisible: boolean;
  modalToleranceVisible: boolean;
  modalDraftsVisible: boolean;
  editedItem: {};
  synchronising: boolean;
  passedMxItemId: string;
};

type LocationState = {
  id: string;
};
