import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import chevron from '../../assets/dropdown-icon.svg';
import FlexWrapper from '../DefectDrawer/components/FlexWrapper';
import TFPortal from '../TFPortal/TFPortal';

interface TFSelectProps {
  options: { title: string; colour?: string; value?: string }[];
  handleSelectChange: (option: { title: string; colour?: string; value?: string }) => void;
  initial: { title: string; colour?: string; value?: string };
  width?: number;
  height?: number;
  disabled?: boolean;
  borderRadius?: string;
}

const StyledWrapper = styled.div`
  height: ${({ height }): string => (height ? `${height}px` : '34px')};
  width: ${({ width }): string => (width ? `${width}px` : '160px')};
  color: rgba(36, 45, 65, 0.9);
`;

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #fff;
  border-radius: ${({ borderRadius }): string => (borderRadius ? `${borderRadius}` : '2px')};
  ${({ optionsVisible }): string =>
    optionsVisible
      ? css`
          border: solid 1px #1890ff;
        `
      : css`
          border: solid 1px rgba(36, 45, 65, 0.2);
        `};
  cursor: pointer;
`;

const ColourSquare = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 10px;
`;

const ChevronIcon = styled.img`
  transform: ${({ optionsVisible }): string => (optionsVisible ? 'rotate(180deg)' : '')};
`;

const InvisibleBackground = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  padding: none;
  border: none;
  background-color: transparent;
  z-index: 201;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  border: 1px solid rgba(36, 45, 65, 0.1);
  max-height: 320px;
  overflow-y: scroll;
  border-radius: 2px;
  background-color: #fff;
  margin-top: 9px;
  margin-left: -1px;
  z-index: 202;
`;

const SelectOption = styled.button`
  height: 32px;
  width: 100%;
  padding: 4px 10px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-align: left;
  border: none;
  background-color: transparent;
  &:hover {
    background-color: rgba(24, 144, 255, 0.05);
    color: #1890ff;
  }
  &:focus {
    border: solid 1px #1890ff;
  }
  color: ${({ current }): string => current && '#1890ff'};
`;

const TFSelect: React.FC<TFSelectProps> = ({
  options,
  handleSelectChange,
  initial,
  width,
  height,
  disabled,
  borderRadius,
}) => {
  const [buttonTitle, setButtonTitle] = useState(null);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [menuStyle, setMenuStyle] = useState({});
  const [backgroundStyle, setBackgroundStyle] = useState({});

  const buttonRef = useRef(null);

  const handleOptionSelection = (option: { title: string; colour?: string; value?: string }): void => {
    setButtonTitle(option);
    setOptionsVisible(false);
    handleSelectChange(option);
  };

  const selectOptions = options.map((option) => {
    return (
      <SelectOption
        type="button"
        key={option.title}
        current={initial && option.title === initial.title}
        onClick={(): void => handleOptionSelection(option)}
      >
        {option.colour ? <ColourSquare style={{ backgroundColor: option.colour }} /> : null}
        <span>{option.title}</span>
      </SelectOption>
    );
  });

  const handleButtonClick = (): void => {
    setOptionsVisible(!optionsVisible);
    const rect = buttonRef.current.getBoundingClientRect();
    const { top, left } = rect;
    setMenuStyle({ position: 'absolute', top: top + 24 + window.scrollY, left: left + window.scrollX });
    setBackgroundStyle({ top: 0 + window.scrollY, left: 0 + window.scrollX });
  };

  useEffect(() => {
    if (initial) {
      setButtonTitle(initial);
    }
  }, [initial, options]);

  return (
    <StyledWrapper width={width} height={height}>
      {buttonTitle && (
        <StyledButton
          type="button"
          ref={buttonRef}
          disabled={disabled}
          optionsVisible={optionsVisible}
          onClick={handleButtonClick}
          borderRadius={borderRadius}
        >
          <FlexWrapper alignItems="center">
            {buttonTitle.colour ? <ColourSquare style={{ backgroundColor: buttonTitle.colour }} /> : null}
            <span>{buttonTitle.title}</span>
          </FlexWrapper>
          <ChevronIcon src={chevron} alt="chevron" optionsVisible={optionsVisible} />
        </StyledButton>
      )}
      {optionsVisible ? (
        <TFPortal>
          <InvisibleBackground style={backgroundStyle} onClick={(): void => setOptionsVisible(false)} />
          <OptionsWrapper style={menuStyle}>
            <FlexWrapper column alignItems="center">
              {selectOptions}
            </FlexWrapper>
          </OptionsWrapper>
        </TFPortal>
      ) : null}
    </StyledWrapper>
  );
};

export default TFSelect;
