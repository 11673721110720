import { Card, Col, Descriptions, Row, Tag } from 'antd';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import globalStyles from '../../utils/globalStyles.module.less';
import ProgressBars from './ProgressBars';
import MXTimeDetails from './MXTimeDetails';
import styles from './index.module.less';

const MXCard = memo(({ item, aircraftThreshold, type, hasAPU, expanded, intl: { formatMessage } }) => {
  const hrsTimeDetails = {
    repeat: item.flight_seconds_repeat,
    due: item.flight_seconds_due,
    threshold: item.flight_seconds_threshold,
    tolerance: item.flight_seconds_tolerance,
    format: 'seconds',
  };

  const cyclesTimeDetails = {
    repeat: item.cycles_repeat,
    due: item.cycles_due,
    threshold: item.cycles_threshold,
    tolerance: item.cycles_tolerance,
    format: 'number',
  };

  const monthsTimeDetails = {
    repeat: item.months_repeat,
    due: item.date_due,
    remaining: item.days_remaining,
    threshold: item.months_threshold,
    tolerance: item.months_tolerance,
    visible_tolerance: item.months_visible_tolerance,
    format: 'months',
  };

  const daysTimeDetails = {
    repeat: item.days_repeat,
    due: item.date_due,
    remaining: item.days_remaining,
    threshold: item.days_threshold,
    tolerance: item.days_tolerance,
    visible_tolerance: item.days_visible_tolerance,
    format: 'days',
  };

  const apuHrsTimeDetails = {
    repeat: item.apu_seconds_repeat,
    due: item.apu_seconds_due,
    threshold: item.apu_seconds_threshold,
    tolerance: item.apu_seconds_tolerance,
    format: 'seconds',
  };

  return (
    <Card className={`${globalStyles.itemCard} ${styles.mxCard}`} data-testid="mxCard">
      <Row gutter={16}>
        <Col md={24}>
          <Descriptions
            column={{ xxl: 6, xl: 6, lg: 6, md: 3, sm: 2, xs: 2 }}
            layout="vertical"
            data-test="mxDescriptions"
          >
            <Descriptions.Item label={formatMessage({ id: 'title.area' })}>
              <span className={styles.textCaptialize} data-test="itemArea">
                {item.mx_type
                  ? item.mx_type
                      .replace('llp', '')
                      .replace('oop', '')
                      .replace('scheduled', '')
                      .replace(/_/g, ' ')
                  : '-'}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label={formatMessage({ id: 'title.ataChapter' })}>
              <span data-test="itemAtaChapter">
                {type && (type === 'LLP' || type === 'OOP') ? item.ata_chapter || '-' : '-'}
              </span>
            </Descriptions.Item>

            <Descriptions.Item span={2} label={formatMessage({ id: 'title.item' })}>
              <span data-test="itemItem">{item.name || '-'}</span>
              {type && type === 'LLP' && (
                <div className={styles.itemMoreParent}>
                  {item.assembly && (
                    <div className={styles.itemMore}>
                      <span className={styles.inlineLabel} data-test="inlineLabelAssemby">
                        {formatMessage({ id: 'title.assembly' })}:
                      </span>
                      <span className={styles.inlineText} data-test="itemAssembly">
                        {item.assembly}
                      </span>
                    </div>
                  )}
                  {item.part_number && (
                    <div className={styles.itemMore}>
                      <span className={styles.inlineLabel} data-test="inlineLabelPartNum">
                        {formatMessage({ id: 'title.partNum' })}:
                      </span>
                      <span className={styles.inlineText} data-test="itemPartNum">
                        {item.part_number}
                      </span>
                    </div>
                  )}
                  {item.part_serial && (
                    <div className={styles.itemMore}>
                      <span className={styles.inlineLabel} data-test="inlineLabelSerialNum">
                        {formatMessage({ id: 'title.serialNum' })}:
                      </span>
                      <span className={styles.inlineText} data-test="itemSerialNum">
                        {item.part_serial}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={formatMessage({ id: 'title.notes' })}>
              <span data-test="itemNotes" className={styles.itemNotes}>
                {item.repeat_remarks}
              </span>
              {item.reference && (
                <Tag color="blue" className={styles.referenceTag} data-test="itemReference">
                  {item.reference}
                </Tag>
              )}
              {!item.repeat_remarks && !item.reference && '-'}
            </Descriptions.Item>
            <Descriptions.Item label={formatMessage({ id: 'title.estimatedDue' })}>
              <span data-test="itemEstDue">{item.estimated_date || '-'}</span>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      {expanded && (
        <Row gutter={16} className={styles.mxDetails} data-test="expandedDetails">
          <Col md={7}>
            <ProgressBars
              title={formatMessage({ id: 'title.status' })}
              mxItem={item}
              hasAPU={hasAPU}
              aircraftThreshold={aircraftThreshold}
              data-test="progressBars"
            />
          </Col>
          <Col md={16} offset={1} className={styles.mxTimings}>
            <Row gutter={16}>
              <Col md={hasAPU ? 6 : 8} data-test="hrsCol">
                <MXTimeDetails
                  title={formatMessage({ id: 'title.flightHrs' })}
                  unitSingular={formatMessage({ id: 'units.hourShort' })}
                  unitPlural={formatMessage({ id: 'units.hoursShort' })}
                  item={hrsTimeDetails}
                  data-test="mxTimeHrs"
                />
              </Col>
              <Col md={hasAPU ? 6 : 8} data-test="cyclesCol">
                <MXTimeDetails
                  title={formatMessage({ id: 'title.cycles' })}
                  unitSingular={formatMessage({ id: 'units.cycle' })}
                  unitPlural={formatMessage({ id: 'units.cycles' })}
                  item={cyclesTimeDetails}
                  data-test="mxTimeCycles"
                />
              </Col>
              <Col md={hasAPU ? 6 : 8} data-test="monthsCol">
                <MXTimeDetails
                  title={
                    item?.unit_of_time === 'months'
                      ? formatMessage({ id: 'title.months' })
                      : formatMessage({ id: 'title.days' })
                  }
                  unitSingular={
                    item?.unit_of_time === 'months'
                      ? formatMessage({ id: 'units.monthShort' })
                      : formatMessage({ id: 'units.day' })
                  }
                  unitPlural={
                    item?.unit_of_time === 'months'
                      ? formatMessage({ id: 'units.monthShort' })
                      : formatMessage({ id: 'units.days' })
                  }
                  item={item?.unit_of_time === 'months' ? monthsTimeDetails : daysTimeDetails}
                  data-test="mxTimeMonths"
                />
              </Col>
              {hasAPU && (
                <Col md={6} data-test="apuCol">
                  <MXTimeDetails
                    title={formatMessage({ id: 'title.apuHours' })}
                    unitSingular={formatMessage({ id: 'units.hourShort' })}
                    unitPlural={formatMessage({ id: 'units.hoursShort' })}
                    item={apuHrsTimeDetails}
                    data-test="mxTimeAPU"
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  );
});

MXCard.defaultProps = {
  type: 'SCH',
  hasAPU: false,
  expanded: false,
};

MXCard.propTypes = {
  item: PropTypes.object.isRequired,
  aircraftThreshold: PropTypes.object.isRequired,
  type: PropTypes.string,
  hasAPU: PropTypes.bool,
  expanded: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};
export default injectIntl(MXCard);
