import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledRadioInput from './StyledRadioInput';

interface ConfirmationCardProps {
  title: string;
  text: Array<string>;
  icon: string;
  extraContent?: ReactElement;
  setErrorMessage?: (input: string) => void;
}

const StyledIcon = styled.img`
  width: 24px;
  margin-right: 10px;
`;

const ConfirmationCard: React.FC<ConfirmationCardProps> = ({ title, text, icon, extraContent, setErrorMessage }) => {
  const [boxChecked, setBoxChecked] = useState(false);

  const handleCheckBoxClick = (): void => {
    setBoxChecked(!boxChecked);
    setErrorMessage('');
  };
  return (
    <Card marginTop={30} identifier={`ConfirmationCard${title}`}>
      <FlexWrapper>
        <StyledIcon src={icon} alt="speech icon" data-testid="ConfirmationCard--Icon" />
        <Label fontWeight={600} identifier="ConfirmationCardTitle">
          {title}
        </Label>
      </FlexWrapper>
      <FlexWrapper column marginTop={20} marginLeft={34}>
        {text &&
          text.map((item) => (
            <div key={item} data-testid="ConfirmationCard--Text">
              {item}
            </div>
          ))}
        {extraContent && extraContent}
        <FlexWrapper marginTop={30} alignItems="center">
          <StyledRadioInput
            id={`ConfirmationCard${title}`}
            identifier={`ConfirmationCard${title}`}
            checked={boxChecked}
            handleClick={handleCheckBoxClick}
            value="checked"
            name={`${title}Checked`}
          />
          <span data-testid="ConfirmationCard--Statement">{`I have read and reviewed the ${title.toLowerCase()}`}</span>
        </FlexWrapper>
      </FlexWrapper>
    </Card>
  );
};

export default ConfirmationCard;
