import React from 'react';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';

interface AdditionalInformationProps {
  defect: Defect | null;
}

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({ defect }) => {
  const { formatMessage } = useIntl();

  return (
    <FlexWrapper column>
      <Label marginBottom={20} fontWeight={600}>
        {formatMessage({ id: 'text.pleaseEnterAdditional' })}
      </Label>
      <Label marginBottom={10} fontWeight={500}>
        {formatMessage({ id: 'text.reasonToDefer' })} {formatMessage({ id: 'form.labels.optional' })}
      </Label>
      <StyledInput
        width={400}
        defaultValue={defect?.reason_to_defer}
        placeholder="Please enter reason to defer..."
        type="text"
        id="reasonToDefer"
      />
      <Label fontWeight={500} marginBottom={5} marginTop={20}>
        {formatMessage({ id: 'text.deferralApprovalReference' })} {formatMessage({ id: 'form.labels.optional' })}
      </Label>
      <StyledInput
        width={400}
        defaultValue={defect?.defer_approval_reference}
        placeholder="Please enter deferral approval reference..."
        id="deferApprovalRef"
        type="text"
      />
    </FlexWrapper>
  );
};

export default AdditionalInformation;
