import React from 'react';
import TFFilerViewer from '../../TFFileViewer/fileViewer';
import styles from '../workpackDrawer.module.less';

const ResolutionDetails = ({ release, poNum }): JSX.Element => {
  const {
    first_name = '',
    last_name = '',
    licence_number = '',
    approval_number = '',
    company_name = '',
    reporter_first_name = '',
    reporter_last_name = '',
    signature = '',
    signature_statement = '',
    attachments = '',
  } = release.release;

  const addAttachment = (): void => null;

  return (
    <div className={styles.workpackWhiteBoxPad}>
      <div className={styles.columnNoMarg}>
        <div className={styles.minorHeadingPad}> Resolution Details</div>
        <div className={`${styles.rowGridFour} ${styles.rowPad25}`}>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>ENGINEER</span>
            <span>
              {first_name} {last_name}
            </span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>LICENCE/AUTHORISATION NO.</span>
            <span>{licence_number || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>PART 145 ORGANISATION</span>
            <span>{company_name || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>PART 145 APPROVAL</span>
            <span>{approval_number || '-'}</span>
          </div>
        </div>
        <div className={styles.rowGridTwo}>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>WORKPACK REFERENCE</span>
            <span>{poNum}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>ATTACHMENTS</span>
            <TFFilerViewer attachments={attachments} addAttachment={addAttachment} />
          </div>
        </div>
        <div className={styles.rowUpperMarg}>
          <span>
            Resolved by:{' '}
            <span className={styles.name}>
              {reporter_first_name || first_name || '-'} {reporter_last_name || last_name || '-'}
            </span>
          </span>
          <img alt="signature" src={`data:image/png;base64,${signature}`} className={styles.signatureImage} />
        </div>
        <div className={styles.row}>
          <div className={styles.rowUpperMarg}>
            <span>{signature_statement}.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResolutionDetails;
