import { Dropdown, Icon, Menu } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import styles from './listFilter.module.less';

const PeriodsDropdown = ({ daysValue, mxPeriods, onDateChange }) => {
  const { formatMessage } = useIntl();
  const getDatePeriod = (days) => {
    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment()
      .add(days, 'days')
      .format('YYYY-MM-DD');
    return [startDate, endDate];
  };

  const onMenuClick = (keyObj) => {
    const value = Number(keyObj.key);
    const period = getDatePeriod(value);
    onDateChange(period, value);
  };

  const getDateMenu = () => {
    return (
      <Menu className={styles.dateMenu} onClick={(keyObj) => onMenuClick(keyObj)}>
        {mxPeriods.map((mxPeriod) => (
          <Menu.Item key={mxPeriod.days}>
            {mxPeriod.days === 1000000
              ? formatMessage({ id: 'text.nextYearMore' })
              : formatMessage({ id: 'text.nextXDays' }, { days: mxPeriod.days })}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={getDateMenu()}
      trigger={['click']}
      placement={window.innerWidth <= 991 ? 'bottomLeft' : 'bottomCenter'}
      data-test="daysDropdown"
    >
      <span href="#" className={styles.dropdownLink}>
        {formatMessage({ id: 'text.showing' })}:
        <span>
          {daysValue === 1000000
            ? formatMessage({ id: 'text.nextYearMore' })
            : formatMessage({ id: 'text.nextXDays' }, { days: daysValue })}
        </span>{' '}
        <Icon type="caret-down" />
      </span>
    </Dropdown>
  );
};

PeriodsDropdown.propTypes = {
  daysValue: PropTypes.number.isRequired,
  onDateChange: PropTypes.func.isRequired,
  mxPeriods: PropTypes.array.isRequired,
};

export default PeriodsDropdown;
