// Uploads files with file objects to a specified api
export default function uploadNewAttachments(api, id, attachments) {
  const files = attachments.filter((attachment) => attachment.originFileObj);
  return Promise.all(
    files.map((attachment) => {
      const formData = new FormData();
      formData.append('file', attachment.originFileObj);
      return api({ id, formData });
    }),
  );
}
