import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import PageMessage from '../PageMessage';
import infoIcon from './assets/info.svg';
import styles from './index.module.less';

class MXItemsImporter extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string,
    onActionClick: PropTypes.func,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    onActionClick: null,
    type: '',
  };

  getItemsCount = () => {
    const {
      items,
      intl: { formatMessage },
    } = this.props;
    const newItemsNo = items.filter((item) => item.draft && item.camp_diffs && item.camp_diffs.length === 0).length;
    const updatedItemsNo = items.filter((item) => item.camp_diffs && item.camp_diffs.length > 0).length;
    return (
      <div className={styles.itemsCountParent}>
        {newItemsNo > 0 && (
          <div className={styles.singleItemCount}>
            {formatMessage({ id: 'message.newItems' })}: <span>{newItemsNo}</span>
          </div>
        )}

        {updatedItemsNo > 0 && (
          <div className={styles.singleItemCount}>
            {formatMessage({ id: 'message.updatedItems' })}: <span>{updatedItemsNo}</span>
          </div>
        )}
      </div>
    );
  };

  showReviewModal = () => {
    this.props.onActionClick();
  };

  render() {
    const {
      items,
      intl: { formatMessage },
      type,
    } = this.props;
    const messageText = `${items.length} ${
      items.length > 1
        ? formatMessage({ id: 'message.draftItemsImported' })
        : formatMessage({ id: 'message.draftItemImported' })
    }`;

    return items && items.length > 0 ? (
      <PageMessage
        type="warning"
        typeIcon={infoIcon}
        text={messageText}
        actionDescription={type === 'draftsOnly' ? null : this.getItemsCount()}
        actionText={type === 'draftsOnly' ? null : formatMessage({ id: 'message.startReviewing' })}
        actionFunction={() => this.showReviewModal()}
        data-test="pageMessage"
      />
    ) : null;
  }
}

export default injectIntl(MXItemsImporter);
