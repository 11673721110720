import React, { useEffect, useState } from 'react';
import { Row, Col, Progress } from 'antd';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import convertMomentToHoursMins from '../../utils/convertMomentToHoursMins';
import styles from './MXWidget.module.less';

const MXWidget = ({ format, mxObj, title, showTotal, showNextDue, active, circleUnit, intl: { formatMessage } }) => {
  const [timeNow, setTimeNow] = useState(moment());

  useEffect(() => {
    setTimeNow(moment());
  }, []);

  const emptyMxObj = {
    threshold: 0,
    remaining: null,
    total: null,
    nextDue: null,
  };
  const { threshold, tolerance } = mxObj || emptyMxObj;
  let { remaining, total, nextDue } = mxObj || emptyMxObj;
  let unit = tolerance === 1 ? 'cycle' : 'cycles';

  // Red/Default Stroke Color
  const defaultStrokeColor = {
    '0%': '#ff7474',
    '100%': '#ff4040',
  };

  // Warning Stroke Color
  const warningStrokeColor = {
    '0%': '#fad288',
    '100%': '#f2a650',
  };

  // Green stroke Color
  const greenStrokeColor = {
    '0%': '#7bc88d',
    '100%': '#4aa91c',
  };

  let strokeColor = defaultStrokeColor;
  let percentValue = 100;
  let displayTextOverride = null;

  if (active) {
    if (format === 'date') {
      percentValue = ((remaining + tolerance * 30) / (threshold * 4)) * 100;
    } else {
      percentValue = ((remaining + tolerance) / (threshold * 4)) * 100;
    }
    if (format === 'seconds') percentValue = (Number(remaining / 3600) / 100) * 100;
    if (format === 'number') {
      if (remaining < 0) remaining = 'Overdue';
    }
    if (format === 'date') {
      unit = tolerance === 1 ? 'day' : 'days';
      if (tolerance !== null && remaining > 0) {
        const dateOfRemaining = moment().add(remaining, 'days');
        if (moment(dateOfRemaining).diff(moment(), 'days') >= 0) {
          remaining = moment(dateOfRemaining).diff(moment(), 'days');
        } else {
          remaining = 'Overdue';
        }
      } else if (remaining === 0) {
        remaining = moment(mxObj.nextDue).diff(moment(), 'days');
      } else if (remaining < 0) {
        remaining = 'Overdue';
      }
    }

    if (percentValue <= 0) {
      percentValue = 100;
      strokeColor = defaultStrokeColor;
    } else if (percentValue <= 25) {
      strokeColor = warningStrokeColor;
    } else if (percentValue > 25) {
      strokeColor = greenStrokeColor;
    }

    if (format === 'seconds') {
      unit = tolerance === 1 ? 'hour' : 'hours';
      if (remaining < 0) {
        remaining = 'Overdue';
      } else {
        const durationRemaining = moment.duration(remaining, 'seconds');
        const hoursRemaining = durationRemaining.days() * 24 + durationRemaining.hours();
        let minutesRemaining = durationRemaining.minutes();
        if (minutesRemaining < 10) {
          minutesRemaining = `0${minutesRemaining}`;
        }
        remaining = `${hoursRemaining}:${minutesRemaining}`;
      }

      const durationTotal = moment.duration(total, 'seconds');
      const hoursTotal = durationTotal.days() * 24 + durationTotal.hours();
      let minutesTotal = durationTotal.minutes();
      if (minutesTotal < 10) {
        minutesTotal = `0${minutesTotal}`;
      }
      total = `${hoursTotal}:${minutesTotal}`;

      if (!Number.isNaN(nextDue)) {
        nextDue = convertMomentToHoursMins(nextDue, timeNow);
      }
    }
  } else {
    displayTextOverride = 'Unknown';
    remaining = -1;
    nextDue = '-';
    percentValue = 0;
    if (format === 'seconds') {
      const durationTotal = moment.duration(total, 'seconds');
      const hoursTotal = durationTotal.days() * 24 + durationTotal.hours();
      let minutesTotal = durationTotal.minutes();
      if (minutesTotal < 10) {
        minutesTotal = `0${minutesTotal}`;
      }
      total = `${hoursTotal}:${minutesTotal}`;
    }
  }

  const showWarningDot = () => {
    let showDot = false;
    if (format === 'date' && tolerance) {
      if (remaining - tolerance * 30 <= 0) showDot = true;
    } else if (format === 'seconds' && tolerance) {
      if (mxObj.remaining - tolerance <= 0) showDot = true;
    } else if (tolerance && remaining - tolerance <= 0) {
      showDot = true;
    }
    return showDot;
  };

  const remainingLengthIsGtThanFive = () => remaining && remaining.length && remaining.length > 5;

  let progressValueStyle = `${styles.progressValue} ${
    remainingLengthIsGtThanFive() ? styles.progressValueSmall : undefined
  }`;
  if (remaining === 'Overdue') {
    progressValueStyle = `${styles.progressValue} ${styles.overdue} ${
      remainingLengthIsGtThanFive() ? styles.progressValueSmall : undefined
    }`;
  } else if (strokeColor === warningStrokeColor) {
    progressValueStyle = `${styles.progressValue} ${styles.critical} ${
      remainingLengthIsGtThanFive() ? styles.progressValueSmall : undefined
    }`;
  }
  return mxObj ? (
    <Row gutter={16} className={!active ? styles.rowDisabled : undefined}>
      <Col md={24} className={styles.colCenter}>
        <Progress
          type="circle"
          strokeColor={strokeColor}
          strokeLinecap="square"
          strokeWidth={5}
          trailColor="#e6e8ed"
          percent={percentValue}
          format={() => {
            return (
              <>
                {title === formatMessage({ id: 'title.lastFlown' }) ? (
                  <div className={styles.progressTitle} data-test="progressUnits">
                    {title}
                  </div>
                ) : null}
                <div className={progressValueStyle} data-test="progressValue">
                  {displayTextOverride || (remaining < 0 ? '0' : remaining)}
                </div>
                {circleUnit && (
                  <div className={styles.progressUnits} data-test="progressUnits">
                    {circleUnit}
                  </div>
                )}
              </>
            );
          }}
          className={styles.progressCircle}
          data-test="progressCircle"
        />
        {showTotal && (
          <>
            <div className={styles.widgetTotals} data-test="widgetTotals">
              {circleUnit === 'days' ? formatMessage({ id: 'text.today' }) : formatMessage({ id: 'text.totals' })}:{' '}
              <span>{total || '-'}</span>
            </div>
          </>
        )}
        {showNextDue && (
          <>
            <div className={styles.widgetTotals} data-test="widgetNextDue">
              <div>{`${formatMessage({ id: 'text.due' })}: `}</div>
              <div className={styles.nextDueWrapper}>
                {showWarningDot() && active ? <div className={styles.warningCircle} /> : null}
                <span>{nextDue}</span>
              </div>
            </div>
            {tolerance && active ? (
              <div className={styles.includingTolerance}>
                {`Inc. ${format === 'seconds' ? tolerance / 3600 : tolerance} ${unit} tolerance`}
              </div>
            ) : null}
          </>
        )}
      </Col>
    </Row>
  ) : (
    formatMessage({ id: 'text.loading' })
  );
};

MXWidget.defaultProps = {
  title: '',
  showTotal: false,
  showNextDue: false,
  circleUnit: '',
  format: undefined,
  mxObj: undefined,
  active: false,
};

MXWidget.propTypes = {
  format: PropTypes.string,
  mxObj: PropTypes.object,
  title: PropTypes.string,
  showTotal: PropTypes.bool,
  showNextDue: PropTypes.bool,
  active: PropTypes.bool,
  circleUnit: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(MXWidget);
