import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Tag } from 'antd';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import defaults from '../../utils/defaults';
import ProgressBars from '../../components/MXCard/ProgressBars';
import EmptyStateMaintenance from '../../assets/emptyState/empty-state-maintenance.svg';
import styles from './WorkpackProfile.module.less';

class LlpTable extends PureComponent {
  static propTypes = {
    dataSource: PropTypes.array.isRequired,
    userSettings: PropTypes.object,
    extraColumns: PropTypes.array,
    globalFilter: PropTypes.string,
    selectedAircraft: PropTypes.object,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    extraColumns: [],
    userSettings: defaults.defaultDateFormat,
    globalFilter: '',
    selectedAircraft: {},
  };

  renderLlpColumns() {
    const {
      userSettings: { dateFormat },
      intl: { formatMessage },
      selectedAircraft,
      globalFilter: globalFilterProp,
    } = this.props;
    const globalFilterLower = globalFilterProp.toLowerCase();
    const aircraftThreshold = selectedAircraft ? selectedAircraft.mx_thresholds : {};
    const aircraftAPUEnabled = selectedAircraft ? selectedAircraft.apu_installed : false;
    const globalFilter = globalFilterProp.toLowerCase();
    const itemRefColumn = {
      title: formatMessage({ id: 'title.itemRef' }),
      dataIndex: 'ata_number',
      width: 200,
      sorter: (a, b) => a.ata_number - b.ata_number,
      render: (text, row) => {
        const area =
          row.mx_type && row.mx_type === 'apu_llp'
            ? row.mx_type
                .replace(/_/g, ' ')
                .replace(' llp', '')
                .toUpperCase()
            : (row.mx_type.charAt(0).toUpperCase() + row.mx_type.slice(1)).replace(/_/g, ' ').replace(' llp', '');
        const chapter =
          row.ata_number !== null && row.ata_number >= 0 ? (
            <div className={styles.maintenanceDetailsTitle}>
              {formatMessage({ id: 'title.chapter' })}: <span className={styles.divFontWeight}>{row.ata_number}</span>
            </div>
          ) : null;
        const item =
          row.name && row.name !== '' ? (
            <div className={styles.maintenanceDetailsTitle}>
              {formatMessage({ id: 'title.item' })}: <span className={styles.divFontWeight}>{row.name}</span>
            </div>
          ) : null;
        const areaDiv = area ? (
          <div className={styles.maintenanceDetailsTitle}>
            {formatMessage({ id: 'title.area' })}: <span className={styles.divFontWeight}>{area}</span>
          </div>
        ) : null;
        const assembly =
          row.assembly && row.assembly !== '' ? (
            <div className={styles.maintenanceDetailsTitle}>
              {formatMessage({ id: 'title.assembly' })}: <span className={styles.divFontWeight}>{row.assembly}</span>
            </div>
          ) : null;
        const taskRef = row.reference ? (
          <Tag color="blue" className={styles.taskRefTag}>
            {row.reference}
          </Tag>
        ) : null;
        return (
          <div>
            {areaDiv}
            {chapter}
            {item}
            {assembly}
            {taskRef}
          </div>
        );
      },
      filteredValue: [globalFilterLower],
      onFilter: (value, record) => {
        let outReturn = false;
        if (record.ata_number && !outReturn) {
          outReturn = record.ata_number
            .toString()
            .toLowerCase()
            .includes(globalFilter.toLowerCase());
        }
        if (record.assembly && !outReturn) {
          outReturn = record.assembly.toLowerCase().includes(globalFilter.toLowerCase());
        }
        if (record.reference && !outReturn) {
          outReturn = record.reference.toLowerCase().includes(globalFilter.toLowerCase());
        }
        if (record.part_number && !outReturn) {
          outReturn = record.part_number.toLowerCase().includes(globalFilter.toLowerCase());
        }
        if (record.part_serial && !outReturn) {
          outReturn = record.part_serial.toLowerCase().includes(globalFilter.toLowerCase());
        }
        if (record.mx_type && !outReturn) {
          outReturn = record.mx_type.toLowerCase().includes(globalFilter.toLowerCase());
        }
        if (record.name && !outReturn) {
          outReturn = record.name.toLowerCase().includes(globalFilter.toLowerCase());
        }
        if (!outReturn) {
          outReturn = globalFilter === '';
        }
        return outReturn;
      },
    };
    const partColumn = {
      title: formatMessage({ id: 'title.part' }),
      dataIndex: 'part_number',
      rowKey: 'part_number',
      width: 150,
      sorter: (a, b) => {
        if (!a.part_number) {
          return -1;
        }
        if (!b.part_number) {
          return 1;
        }
        return a.part_number.localeCompare(b.part_number);
      },
      render: (text, row) => {
        if (row.part_number || row.part_serial) {
          return (
            <div className={styles.maintenanceDetails}>
              {row.part_number ? (
                <p>
                  {formatMessage({ id: 'title.partNum' })}
                  <span className={styles.columnSpan}>{row.part_number}</span>
                </p>
              ) : null}
              {row.part_serial ? (
                <p>
                  {formatMessage({ id: 'title.serialNum' })}
                  <span className={styles.columnSpan}>{row.part_serial}</span>
                </p>
              ) : null}
            </div>
          );
        }
        return null;
      },
    };
    const flightHrsColumn = {
      title: formatMessage({ id: 'title.flightHrs' }),
      dataIndex: 'flight_seconds_repeat',
      rowKey: 'flight_seconds_repeat',
      width: 150,
      render: (text, row) => {
        if (row.flight_seconds_repeat || row.flight_seconds_due) {
          return (
            <div className={styles.maintenanceDetails}>
              {row.flight_seconds_repeat ? (
                <p>
                  {formatMessage({ id: 'title.lifespan' })}:
                  <span className={styles.columnSpan}>
                    {row.flight_seconds_repeat / 3600} {formatMessage({ id: 'period.hrs' })}
                    {row.flight_seconds_threshold && row.flight_seconds_threshold >= row.flight_seconds_due ? (
                      <span className={styles.secondarySpan}>
                        {' '}
                        ({formatMessage({ id: 'text.thresholdShort' })} {row.flight_seconds_threshold / 3600}{' '}
                        {formatMessage({ id: 'period.hrs' })})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
              ) : (
                ''
              )}
              {row.flight_seconds_due ? (
                <p>
                  {formatMessage({ id: 'text.nextDue' })}:
                  <span className={styles.columnSpan}>
                    {row.flight_seconds_due / 3600} {formatMessage({ id: 'period.hrs' })}
                    {row.flight_seconds_tolerance ? (
                      <span className={styles.secondarySpan}>
                        {' '}
                        (+/- {Math.abs(row.flight_seconds_tolerance / 3600)} {formatMessage({ id: 'period.hrs' })})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
              ) : (
                ''
              )}
            </div>
          );
        }
        return '-';
      },
      sorter: (a, b) => a.flight_seconds_due - b.flight_seconds_due,
    };
    const cyclesColumn = {
      title: formatMessage({ id: 'title.cycles' }),
      dataIndex: 'cycles_repeat',
      rowKey: 'cycles_repeat',
      width: 150,
      render: (text, row) => {
        if (row.cycles_repeat || row.cycles_due) {
          return (
            <div className={styles.maintenanceDetails}>
              {row.cycles_repeat ? (
                <p>
                  {formatMessage({ id: 'title.lifespan' })}:
                  <span className={styles.columnSpan}>
                    {row.cycles_repeat} {formatMessage({ id: 'units.cycles' })}
                    {row.cycles_threshold && row.cycles_threshold >= row.cycles_due ? (
                      <span className={styles.secondarySpan}>
                        {' '}
                        ({formatMessage({ id: 'text.thresholdShort' })} {Math.abs(row.cycles_threshold)}{' '}
                        {formatMessage({ id: 'units.cycles' })})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
              ) : (
                ''
              )}
              {row.cycles_due ? (
                <p>
                  {formatMessage({ id: 'text.nextDue' })}:
                  <span className={styles.columnSpan}>
                    {row.cycles_due} {formatMessage({ id: 'period.cycles' })}
                    {row.cycles_tolerance ? (
                      <span className={styles.secondarySpan}>
                        {' '}
                        (+/- {Math.abs(row.cycles_tolerance)} {formatMessage({ id: 'period.cycles' })})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
              ) : (
                ''
              )}
            </div>
          );
        }
        return '-';
      },
      sorter: (a, b) => a.cycles_due - b.cycles_due,
    };
    const monthsColumn = {
      title: formatMessage({ id: 'title.months' }),
      dataIndex: 'months_repeat',
      rowKey: 'months_repeat',
      width: 150,
      render: (text, row) => {
        if (row.months_repeat || row.date_due) {
          return (
            <div className={styles.maintenanceDetails}>
              {row.months_repeat ? (
                <p>
                  {formatMessage({ id: 'title.lifespan' })}:
                  <span className={styles.columnSpan}>
                    {row.months_repeat} {formatMessage({ id: 'units.months' })}
                    {row.months_threshold &&
                    row.months_threshold >= Math.abs(moment(row.date_due).diff(moment(), 'months', true)) ? (
                      <span className={styles.secondarySpan}>
                        {' '}
                        ({formatMessage({ id: 'text.thresholdShort' })} {Math.abs(row.months_threshold)}{' '}
                        {formatMessage({ id: 'units.months' })})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
              ) : (
                ''
              )}
              {row.date_due ? (
                <p>
                  {formatMessage({ id: 'text.nextDue' })}:
                  <span className={styles.columnSpan}>
                    {moment(row.date_due).format(dateFormat)}
                    {row.months_tolerance ? (
                      <span className={styles.secondarySpan}>
                        {' '}
                        (+/- {Math.abs(row.months_tolerance)} {formatMessage({ id: 'units.months' })})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
              ) : (
                ''
              )}
            </div>
          );
        }
        return '-';
      },
      sorter: (a, b) => {
        if (!a.date_due) {
          return -1;
        }
        if (!b.date_due) {
          return 1;
        }
        return moment(a.date_due) - moment(b.date_due);
      },
    };
    const apuHrsColumn = {
      title: formatMessage({ id: 'title.apuHours' }),
      dataIndex: 'apu_seconds_repeat',
      rowKey: 'apu_seconds_repeat',
      width: 150,
      render: (text, row) => {
        if (row.apu_seconds_repeat || row.apu_seconds_due) {
          return (
            <div className={styles.maintenanceDetails}>
              {row.apu_seconds_repeat ? (
                <p>
                  {formatMessage({ id: 'title.lifespan' })}:
                  <span className={styles.columnSpan}>
                    {Math.abs(row.apu_seconds_repeat / 3600)} {formatMessage({ id: 'period.hrs' })}
                    {row.apu_seconds_threshold && row.apu_seconds_threshold >= row.apu_seconds_due ? (
                      <span className={styles.secondarySpan}>
                        {' '}
                        ({formatMessage({ id: 'text.thresholdShort' })}
                        {Math.abs(row.apu_seconds_threshold / 3600)} {formatMessage({ id: 'period.hrs' })})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
              ) : (
                ''
              )}
              {row.apu_seconds_due ? (
                <p>
                  {formatMessage({ id: 'text.nextDue' })}:
                  <span className={styles.columnSpan}>
                    {Math.abs(row.apu_seconds_due / 3600)} {formatMessage({ id: 'period.hrs' })}
                    {row.apu_seconds_tolerance ? (
                      <span className={styles.secondarySpan}>
                        {' '}
                        (+/- {Math.abs(row.apu_seconds_tolerance / 3600)} {formatMessage({ id: 'period.hrs' })})
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </p>
              ) : (
                ''
              )}
            </div>
          );
        }
        return '-';
      },
      sorter: (a, b) => a.apu_seconds_due - b.apu_seconds_due,
    };
    const estDueColumn = {
      title: formatMessage({ id: 'title.estimatedDue' }),
      dataIndex: 'estimated_date',
      width: 200,
      sorter: (a, b) => {
        if (!a.estimated_date) {
          return -1;
        }
        if (!b.estimated_date) {
          return 1;
        }
        return moment(a.estimated_date) - moment(b.estimated_date);
      },
      render: (text, record) => {
        if (text) {
          return (
            <div className={styles.maintenanceDetails}>
              <p>
                {formatMessage({ id: 'text.estimatedShort' })}:
                <span className={styles.columnSpan}>{moment(text).format(dateFormat)}</span>
              </p>
            </div>
          );
        }
        if (record.date_due) {
          return (
            <div className={styles.maintenanceDetails}>
              {formatMessage({ id: 'text.due' })}:
              <p>
                <span className={styles.columnSpan}>{moment(record.date_due).format(dateFormat)}</span>
              </p>
            </div>
          );
        }
        return (
          <div>
            <p>-</p>
          </div>
        );
      },
    };
    const statusColumn = {
      title: formatMessage({ id: 'title.status' }),
      dataIndex: 'flight_seconds_remaining',
      rowKey: 'flight_seconds_remaining',
      width: 300,
      align: 'start',
      render: (text, row) => {
        return (
          <ProgressBars
            mxItem={row}
            hasAPU={aircraftAPUEnabled}
            aircraftThreshold={aircraftThreshold}
            data-test="progressBars"
          />
        );
      },
    };
    const columnsArray = [
      itemRefColumn,
      partColumn,
      flightHrsColumn,
      cyclesColumn,
      monthsColumn,
      estDueColumn,
      statusColumn,
    ];
    if (selectedAircraft && selectedAircraft.apu_installed) {
      columnsArray.splice(5, 0, apuHrsColumn);
    }
    return columnsArray;
  }

  render() {
    const { dataSource, extraColumns, intl } = this.props;
    const newExtraColumns = extraColumns || [];
    return (
      <Table
        title={() => <div className={styles.tableTitle}>{intl.formatMessage({ id: 'title.lifeLimitedParts' })}</div>}
        columns={this.renderLlpColumns().concat(newExtraColumns)}
        scroll={{ x: 1360 }}
        className={styles.defaultTable}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        rowKey={(item) => item.id}
        dataSource={dataSource}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        locale={{
          emptyText: (
            <div className={styles.emptyText}>
              <img src={EmptyStateMaintenance} alt="empty state" />
              <span>No Data</span>
            </div>
          ),
        }}
      />
    );
  }
}

const llpTableWithRedux = connect(({ userSettings }) => ({
  userSettings,
}))(LlpTable);
export default injectIntl(llpTableWithRedux);
