import { BaseModel } from '..';

const initialState = {
  aircraftDocumentsMap: new Map(),
};

export enum AircraftDocumentsActionTypes {
  SAVE = 'aircraftDocuments/save',
  SET_DOCUMENTS = 'aircraftDocuments/setDocuments',
  REMOVE_DOCUMENT = 'aircraftDocuments/removeDocument',
}

export interface SaveAircraftDocumentsActionTypes {
  type: AircraftDocumentsActionTypes.SAVE;
  payload: AircraftDocument;
}
export interface SetDocumentsAircraftDocumentsActionTypes {
  type: AircraftDocumentsActionTypes.SET_DOCUMENTS;
  payload: AircraftDocument[];
}
export interface RemoveDocumentAircraftDocumentsActionTypes {
  type: AircraftDocumentsActionTypes.REMOVE_DOCUMENT;
  payload: { removeId: string };
}

type AircraftDocumentsAction =
  | SaveAircraftDocumentsActionTypes
  | SetDocumentsAircraftDocumentsActionTypes
  | RemoveDocumentAircraftDocumentsActionTypes;

export default function(state = initialState, action: AircraftDocumentsAction): AircraftDocumentsState {
  switch (action.type) {
    case AircraftDocumentsActionTypes.SAVE: {
      const aircraftDocumentsMap = new Map(state.aircraftDocumentsMap.entries());
      aircraftDocumentsMap.set(action.payload.id, {
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        aircraftDocumentsMap,
      };
    }
    case AircraftDocumentsActionTypes.SET_DOCUMENTS: {
      const aircraftDocumentsMap = new Map();
      action.payload.reverse().forEach((document) => {
        aircraftDocumentsMap.set(document.id, {
          ...document,
        });
      });
      return {
        ...state,
        aircraftDocumentsMap,
      };
    }
    case AircraftDocumentsActionTypes.REMOVE_DOCUMENT: {
      const aircraftDocumentsMap = new Map(state.aircraftDocumentsMap.entries());
      if (action.payload.removeId) {
        aircraftDocumentsMap.delete(action.payload.removeId);
      }
      return {
        ...state,
        aircraftDocumentsMap,
      };
    }
    default:
      return state;
  }
}

export interface AircraftDocumentsState {
  aircraftDocumentsMap: Map<string, AircraftDocument>;
}

export interface AircraftDocument extends BaseModel {
  id: string;
}
