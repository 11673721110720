import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import servers from '../../../utils/servers';
import FileViewer from '../../TFFileViewer/fileViewer';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import GridWrapper from './GripWrapper';

interface ResolutionDetailsProps {
  defect: Defect;
}

const StyledImg = styled.img`
  height: 70px;
  max-width: 400px;
`;

const ResolutionDetails: React.FC<ResolutionDetailsProps> = ({ defect }) => {
  const { formatMessage } = useIntl();

  const convertToImgFile = (file): Promise<any> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentUpload = async (files): Promise<void> => {
    // if (files) {
    //   const attachPromises = [];
    //   files.forEach(async (file) => {
    //     attachPromises.push(convertToImgFile(file));
    //   });
    //   const base64Attachments = await Promise.all(attachPromises);
    //   setAttachments(attachments?.concat(...base64Attachments));
    // }
  };

  const handleDelete = ({ fileId, fileName }): void => {
    // const newAttachmentsList = attachments.filter(
    //   (item) => item?.id !== fileId || item?.attachment_file_name !== fileName,
    // );
    // if (fileId) {
    //   newAttachmentsList.push({ id: fileId, _destroy: true });
    // }
    // setAttachments(newAttachmentsList);
  };

  const lastMXEvent = defect?.mx_events[defect?.mx_events.length - 1];

  return (
    <Card marginTop={30}>
      <FlexWrapper column>
        <Label marginBottom={20} fontWeight={600}>
          {formatMessage({ id: 'title.resolutionDetails' })}
        </Label>
        <GridWrapper rowGap={20} columns="1fr 1fr 1fr 1fr" rows="1fr 1fr">
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'text.engineer' })}
            </Label>
            <span>
              {lastMXEvent?.first_name || '-'} {lastMXEvent?.last_name || null}
            </span>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'title.licenceAuthorisationNo' })}.
            </Label>
            <span>{lastMXEvent?.licence_number || '-'}</span>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.part145Organisation' })}
            </Label>
            <span>{lastMXEvent?.company_name || '-'}</span>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'title.licenceAuthorisationNo' })}.
            </Label>
            <span>{lastMXEvent?.approval_number || '-'}</span>
          </FlexWrapper>
          <FlexWrapper column colSpan={2}>
            <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'text.purchaseOrder' })}
            </Label>
            <span>{lastMXEvent?.workpack_reference || '-'}</span>
          </FlexWrapper>
          <FlexWrapper column colSpan={2}>
            <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.attachments' })}.
            </Label>
            <FileViewer
              attachments={lastMXEvent?.attachments}
              handleDeleteClick={handleDelete}
              addAttachment={handleAttachmentUpload}
            />
          </FlexWrapper>
        </GridWrapper>
        <FlexWrapper justifyContent="space-between" alignItems="center" marginTop={20} marginBottom={20} height={40}>
          <FlexWrapper alignItems="center">
            <Label fontWeight={500} marginRight={4}>
              {formatMessage({ id: 'title.resolvedBy' })}
            </Label>
            <Label fontWeight={600} marginRight={15}>
              {lastMXEvent?.first_name || '-'} {lastMXEvent?.last_name || null}
            </Label>
            {lastMXEvent?.signature_image_url && (
              <StyledImg alt="Signature" src={`${servers.api}${lastMXEvent?.signature_image_url}`} />
            )}
          </FlexWrapper>
          <FlexWrapper flexShrink="0" marginRight={30}>
            <Label fontWeight={500} marginRight={4}>
              {formatMessage({ id: 'title.resolvedOn' })}:
            </Label>
            <Label fontWeight={600}>{lastMXEvent?.date ? moment(lastMXEvent?.date).format('YYYY-MM-DD') : '-'}</Label>
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </Card>
  );
};

export default ResolutionDetails;
