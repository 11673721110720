import React, { Component } from 'react';
import { Checkbox, Row, Col, Tag, Form, Button, Icon } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import Scroll from 'react-scroll';
import DefectCard from '../DefectCard';
import ResolutionCard from '../DefectCard/ResolutionCard';
import DefectResolution from '../ExternalForm/DefectResolution';
import expandIcon from '../../assets/expand.svg';
import collapseIcon from '../../assets/collapse.svg';
import MXCard from '../MXCard';
import servers from '../../utils/servers';
import styles from './index.module.less';

const { animateScroll } = Scroll;

class ExternalSelectableMX extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    aircraftThreshold: PropTypes.object.isRequired,
    type: PropTypes.string,
    hasAPU: PropTypes.bool,
    handleItemResolved: PropTypes.func,
    intl: PropTypes.object.isRequired,
    selectAll: PropTypes.bool,
    setIndeterminate: PropTypes.func,
    selectAllText: PropTypes.bool,
    handleCheckboxUncheck: PropTypes.func,
  };

  static defaultProps = {
    type: '',
    hasAPU: false,
    handleItemResolved: undefined,
    selectAll: false,
    setIndeterminate: () => {
      // default func
    },
    selectAllText: false,
    handleCheckboxUncheck: () => {
      // default func
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
    this.titleRef = React.createRef();
    this.resolvedRef = React.createRef();
  }

  componentDidMount() {
    const { items } = this.props;
    if (items && items.length > 0) this.setUpItems();
  }

  componentDidUpdate(prevProps) {
    const { selectAll, selectAllText, items } = this.props;
    if (prevProps.selectAll !== selectAll || prevProps.selectAllText !== selectAllText) {
      this.selectAllItems();
    }
    if (!_.isEqual(items, prevProps.items)) {
      this.setUpItems(_.difference(items, prevProps.items));
    }
  }

  setUpItems = (changeItems) => {
    const { items, type, form } = this.props;
    const newItems = items.map((item) => {
      if (item.mx_item.status === 'resolved') {
        form.setFieldsValue({ [`item_${item.mx_item.id}`]: true });
        return item;
      }
      const newItem = item;
      newItem.mx_item.expanded = false;
      if (changeItems && type === 'DEF') {
        changeItems.forEach((entry) => {
          if (entry.id === newItem.id) {
            newItem.mx_item.indeterminate = false;
          }
        });
      } else if (type === 'DEF') {
        newItem.mx_item.indeterminate = false;
      }
      return newItem;
    });
    this.setState({ items: newItems });
  };

  selectAllItems = () => {
    const { items, selectAllText, selectAll, type, form, setIndeterminate } = this.props;
    const newItems = items.map((item) => {
      const newItem = item;
      if (selectAllText && selectAll) {
        newItem.mx_item.expanded = true;
        if (type === 'DEF') {
          if (!form.getFieldValue(`item_${item.mx_item.id}`)) {
            form.setFieldsValue({ [`item_${item.mx_item.id}`]: true });
            newItem.mx_item.indeterminate = true;
            if (setIndeterminate) setIndeterminate(`${newItem.mx_item.indeterminate}_${item.mx_item.id}`);
          }
        } else {
          form.setFieldsValue({ [`item_${item.mx_item.id}`]: true });
        }
      } else if (!selectAll && !selectAllText) {
        newItem.mx_item.expanded = false;
        if (type === 'DEF') {
          form.setFieldsValue({ [`item_${item.mx_item.id}`]: false });
          newItem.mx_item.indeterminate = false;
          if (setIndeterminate) setIndeterminate(`false_${item.mx_item.id}`);
        } else {
          form.setFieldsValue({ [`item_${item.mx_item.id}`]: false });
        }
      }
      return newItem;
    });
    this.setState({ items: newItems });
  };

  checkboxChange = async (item) => {
    const { form, type, items, handleCheckboxUncheck, setIndeterminate } = this.props;
    const checked = form.getFieldValue(`item_${item.id}`);
    const itemIndex = items.findIndex((i) => i.mx_item.id === item.id);
    const newItem = item;
    if (item.status !== 'resolved') {
      if (type === 'DEF') {
        if (checked) {
          await form.setFieldsValue({ [`item_${item.id}`]: false });
          newItem.indeterminate = false;
          if (setIndeterminate) await setIndeterminate(`false_${item.id}`);
        } else {
          await form.setFieldsValue({ [`item_${item.id}`]: false });
          newItem.indeterminate = !newItem.indeterminate;
          if (setIndeterminate) await setIndeterminate(`${newItem.indeterminate}_${item.id}`);
        }
      } else {
        await form.setFieldsValue({ [`item_${item.id}`]: !checked });
        newItem.expanded = !newItem.expanded;
      }
    }
    items[itemIndex] = { ...items[itemIndex], mx_item: newItem };
    this.setState({ items });
    handleCheckboxUncheck();
  };

  toggleCard = (item) => {
    const { items } = this.state;
    const itemIndex = items.findIndex((i) => i.mx_item.id === item.id);
    const foundItem = items[itemIndex];
    foundItem.mx_item.expanded = !foundItem.mx_item.expanded;
    items[itemIndex] = { ...items[itemIndex], foundItem };
    this.setState({ items });
  };

  resolutionSaved = async (item) => {
    const { form, handleItemResolved, items, setIndeterminate } = this.props;
    const itemIndex = items.findIndex((i) => i.mx_item.id === item.id);
    items[itemIndex] = { ...items[itemIndex], mx_item: { ...items[itemIndex].mx_item, indeterminate: false } };
    this.setState({ items });
    if (setIndeterminate) await setIndeterminate(`false_${item.id}`);
    form.setFieldsValue({ [`item_${item.id}`]: true });
    handleItemResolved();
  };

  resolutionEditing = async (item) => {
    const { form, items, setIndeterminate } = this.props;
    const itemIndex = items.findIndex((i) => i.mx_item.id === item.id);
    items[itemIndex] = { ...items[itemIndex], mx_item: { ...items[itemIndex].mx_item, indeterminate: true } };
    this.setState({ ...items });
    if (setIndeterminate) await setIndeterminate(`true_${item.id}`);
    form.setFieldsValue({ [`item_${item.id}`]: false });
  };

  renderItem = (item, prefix) => {
    const {
      hasAPU,
      form,
      aircraftThreshold,
      intl: { formatMessage },
    } = this.props;
    let { type } = this.props;
    const { status } = item;
    const resolutionDetails = [];
    const title = item.details || item.name;

    if (type !== 'DEF')
      type =
        item.mx_type &&
        item.mx_type
          .replace(/^.+_/, '')
          .slice(0, 3)
          .toUpperCase();

    const checked = status === 'resolved' || form.getFieldValue(`item_${item.id}`) || false;
    const defectType = type === 'DEF' ? `${formatMessage({ id: 'title.shortDefectNum' })}${item.number}` : type;

    if (status === 'resolved' && item.mx_events && item.mx_events.length > 0) {
      item.mx_events.forEach((event, eventKey) => {
        const eventObj = {
          status: 'resolved',
          key: eventKey,
          resolution_type: event.work_type,
          details: event.description,
          release: event.release,
          ...(event.limitations && { limitations: true, limitations_details: event.limitations }),
        };
        if (event.part_changes.length > 0) {
          event.part_changes.forEach((pc, key) => {
            eventObj[`replaced_part_part_number_on_${key}`] = pc.part_number_on;
            eventObj[`replaced_part_part_number_off_${key}`] = pc.part_number;
            eventObj[`replaced_part_serial_off_${key}`] = pc.serial_off;
            eventObj[`replaced_part_serial_on_${key}`] = pc.serial_on;
            eventObj[`replaced_part_batch_lot_number_${key}`] = pc.batch;
          });
        }
        resolutionDetails.push(eventObj);
      });
    }

    const { getFieldDecorator } = form;
    const srp_link = item.resolution_srp_url ? item.resolution_srp_url : item.srp_url;
    return (
      <>
        <Row gutter={16} className={`${styles.checkboxRow} ${styles.checkboxRowTitle}`} id={`item_${item.id}`}>
          <div ref={this.titleRef}>
            <Col md={1}>
              <Form.Item className={styles.selectableFormItem}>
                {getFieldDecorator(`item_${item.id}`, {
                  rules: [{ required: false }],
                  initialValue: status === 'resolved',
                  valuePropName: 'checked',
                })(
                  <Checkbox
                    indeterminate={item.indeterminate}
                    onChange={() => this.checkboxChange(item)}
                    disabled={status === 'resolved'}
                    data-testid="itemCheckbox"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col md={23}>
              <div
                className={`${styles.itemTitleHolder} ${
                  type && (type === 'SCH' || type === 'LLP' || type === 'OOP')
                    ? styles.itemTitleHolderExpandable
                    : undefined
                }`}
              >
                <div className={styles.selectableItemTitle}>
                  {prefix && <span className={styles.itemTitlePrefix} data-testid="itemPrefix">{`${prefix} -`}</span>}
                  {type && (
                    <Tag className={`${styles.itemTitleTag} ${styles.itemTitleTag}${type}`} data-testid="itemTag">
                      {defectType}
                    </Tag>
                  )}
                  {title && (
                    <span
                      className={item.expanded ? styles.itemTitleExpanded : styles.itemTitle}
                      data-testid="itemTitle"
                    >
                      {title}
                    </span>
                  )}
                </div>
                <div className={styles.resolvedWrapper}>
                  {status !== 'resolved' && item.srp_url && (
                    <a
                      href={`${servers.api}/${item.srp_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={formatMessage({ id: 'text.srp' })}
                      data-testid="itemSRPLink"
                    >
                      {formatMessage({ id: 'text.viewSRP' })}
                      <Icon type="file-pdf" />
                    </a>
                  )}
                  {status === 'resolved' && (
                    <>
                      <Tag
                        color="green"
                        className={`${styles.resolvedTag} ${styles[`resolvedTag${type}`]}`}
                        data-testid="itemTag"
                      >
                        <span ref={this.resolvedRef}>
                          {formatMessage({ id: 'status.resolved' })}
                          {item.date_closed &&
                            ` ${formatMessage({ id: 'status.on' })} ${moment(item.date_closed).format('YYYY-MM-DD')} `}
                        </span>
                      </Tag>
                      {srp_link && (
                        <a
                          href={`${servers.api}/${srp_link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download={formatMessage({ id: 'text.srp' })}
                          className={styles.resolvedLink}
                          data-testid="itemResolvedLink"
                        >
                          {formatMessage({ id: 'text.viewSRP' })}
                          <Icon type="file-pdf" />
                        </a>
                      )}
                    </>
                  )}

                  {type && (type === 'SCH' || type === 'LLP' || type === 'OOP') && (
                    <Button
                      type="link"
                      className={styles.toggleCard}
                      onClick={() => this.toggleCard(item)}
                      data-testid="itemIcon"
                    >
                      {item && item.expanded ? (
                        <img src={collapseIcon} alt="collapse-icon" />
                      ) : (
                        <img src={expandIcon} alt="expand-icon" />
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </div>
        </Row>
        <Row gutter={16} className={`${styles.checkboxRow} ${styles.checkboxRowCard}`}>
          <Col md={23} offset={1} className={styles.cardHolder}>
            {type && type === 'DEF' && (item.indeterminate || checked) && status !== 'resolved' && (
              <DefectResolution
                form={form}
                defect={item}
                isEdit={item.indeterminate || checked}
                onSave={() => this.resolutionSaved(item)}
                setIndeterminate={() => this.resolutionEditing(item)}
                data-testid="resolutionCard"
              />
            )}
            {status === 'resolved' &&
              resolutionDetails.map((singleResolution) => (
                <ResolutionCard
                  item={singleResolution}
                  key={`resolution_${singleResolution.key}`}
                  hideEdit
                  type={type}
                />
              ))}
            {type && type === 'DEF' && (
              <div className={status === 'resolved' ? styles.itemDisabled : undefined} data-testid="defectCard">
                <DefectCard defect={item} />
              </div>
            )}
            {type && (type === 'SCH' || type === 'LLP' || type === 'OOP') && (
              <div className={status === 'resolved' ? styles.itemDisabled : undefined}>
                <section
                  ref={(section) => {
                    this.mxCardDetails = section;
                  }}
                />
                <MXCard
                  type={type}
                  item={item}
                  hasAPU={hasAPU}
                  expanded={item.expanded}
                  aircraftThreshold={aircraftThreshold}
                  data-testid="mxCard"
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const { items } = this.state;
    return <>{items && items.length > 0 && items.map((item, index) => this.renderItem(item.mx_item, index + 1))}</>;
  }
}

export default injectIntl(ExternalSelectableMX);
