import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import { injectIntl } from 'react-intl';
import { Icon, Upload, Input, Select, Button } from 'antd';
import PropTypes from 'prop-types';
import disableAutoCorrect from '../../utils/disableAutoCorrect';
import uploadIcon from '../../assets/upload-doc-icon.svg';
import dropdownIcon from '../../assets/dropdown-icon.svg';
import schematics from '../../assets/schematic-upload.svg';
import Loading from '../../components/TFLoading';
import styles from './DamageMaps.module.less';

const { Option } = Select;
const { Dragger } = Upload;
const mobileViewWidth = 800;

class SchematicViewModalBody extends Component {
  static propTypes = {
    fileList: PropTypes.array.isRequired,
    handleFileListChange: PropTypes.func.isRequired,
    newThumbnails: PropTypes.array.isRequired,
    thumbnailIndex: PropTypes.object,
    mainImageInModalSrc: PropTypes.string,
    crop: PropTypes.object.isRequired,
    showThumbnail: PropTypes.bool.isRequired,
    categorySelectorDropDown: PropTypes.array.isRequired,
    titleInput: PropTypes.string.isRequired,
    categoryInput: PropTypes.string.isRequired,
    categoryButtonSelected: PropTypes.string.isRequired,
    imageIsUploading: PropTypes.bool.isRequired,
    handleClickAddNewImage: PropTypes.func.isRequired,
    handleClickLeftArrow: PropTypes.func.isRequired,
    handleRightArrowClick: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    displayThumbnails: PropTypes.func.isRequired,
    onImageLoaded: PropTypes.func.isRequired,
    onCropChange: PropTypes.func.isRequired,
    onCropComplete: PropTypes.func.isRequired,
    handleFileUpload: PropTypes.func.isRequired,
    handleSaveButtonPress: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    mainImageInModalSrc: null,
    thumbnailIndex: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
    };
  }

  componentDidMount() {
    const { fileList } = this.props;
    if (fileList) {
      this.setFileList();
    }
    disableAutoCorrect();
  }

  componentDidUpdate() {
    const { fileList } = this.state;
    const { handleFileListChange } = this.props;
    handleFileListChange(fileList);
    disableAutoCorrect();
  }

  setFileList = () => {
    this.setState({ fileList: this.props.fileList });
  };

  render() {
    const {
      newThumbnails,
      thumbnailIndex,
      mainImageInModalSrc,
      crop,
      showThumbnail,
      categorySelectorDropDown,
      titleInput,
      categoryInput,
      categoryButtonSelected,
      imageIsUploading,
      handleClickAddNewImage,
      handleClickLeftArrow,
      handleRightArrowClick,
      handleInput,
      handleSelectChange,
      displayThumbnails,
      onImageLoaded,
      onCropChange,
      onCropComplete,
      handleFileUpload,
      handleSaveButtonPress,
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <Loading loading={imageIsUploading} contain />
        {window.innerWidth > mobileViewWidth ? (
          <div className={styles.modalBody}>
            <div className={styles.thumbnailColumn}>
              <div className={styles.addAndDisplayDiv}>
                <Button
                  type="link"
                  className={styles.addNewBox}
                  onClick={() => handleClickAddNewImage(false, null, '', formatMessage({ id: 'text.categoryNotSet' }))}
                >
                  <Icon type="plus" className={styles.plusIcon} />
                </Button>
                <span className={styles.addNewText}>{formatMessage({ id: 'form.button.addNew' })}</span>
              </div>
              <div className={styles.thumbnailDiv}>{displayThumbnails()}</div>
            </div>
            <div className={styles.imageColumn}>
              <div className={styles.imageWrapperDiv}>
                <div className={styles.fileUploadDiv}>
                  <Icon
                    type="left"
                    className={
                      thumbnailIndex !== null && thumbnailIndex !== 0 ? styles.arrowButtonClickable : styles.arrowButton
                    }
                    onClick={() => handleClickLeftArrow()}
                  />
                  {showThumbnail ? (
                    <div className={styles.imageDiv}>
                      <ReactCrop
                        src={mainImageInModalSrc}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        imageStyle={{ width: 'auto', maxHeight: '365px' }}
                      />
                    </div>
                  ) : (
                    <div className={styles.imageDiv}>
                      <Dragger
                        onChange={handleFileUpload}
                        beforeUpload={(file) => {
                          Promise.resolve(file).then(
                            this.setState((state) => ({
                              fileList: [...state.fileList, file],
                            })),
                          );
                          return false;
                        }}
                        accept=".jpeg,.jpg,.png"
                      >
                        <button type="submit" className={styles.uploadButton}>
                          <div className={styles.schematicsImage}>
                            <img src={schematics} alt={formatMessage({ id: 'text.schematics' })} />
                          </div>
                          <div className={styles.internalUploadButton}>
                            <img src={uploadIcon} alt={formatMessage({ id: 'text.uploadIcon' })} />
                          </div>
                          <div>{formatMessage({ id: 'text.files' })}</div>
                          <div className={styles.defaultUploadText}>
                            <div>{formatMessage({ id: 'form.button.youCan' })}</div>
                            <div className={styles.clickHere}>{formatMessage({ id: 'form.button.clickHere' })}</div>
                          </div>
                        </button>
                      </Dragger>
                    </div>
                  )}
                  <Icon
                    type="right"
                    className={
                      (newThumbnails
                      ? newThumbnails.length !== 0 && newThumbnails.length - 1 > thumbnailIndex
                      : '')
                        ? styles.arrowButtonClickable
                        : styles.arrowButton
                    }
                    onClick={() => handleRightArrowClick()}
                  />
                </div>
              </div>
              <div className={styles.titlesGroupInputDiv}>
                <div className={styles.titleDiv}>
                  <span className={styles.title}>{formatMessage({ id: 'form.labels.enterTitle' })}</span>
                  <Input
                    className={styles.titleInput}
                    onChange={(e) => handleInput(e.target.value)}
                    value={titleInput}
                    placeholder={formatMessage({ id: 'form.placeholder.enterTitle' })}
                  />
                </div>
                <div className={styles.titleDiv}>
                  <span className={styles.categoryTitle}>{formatMessage({ id: 'form.labels.selectCategory' })}</span>
                  <Select
                    onChange={(value) => handleSelectChange(value)}
                    value={categoryInput}
                    suffixIcon={<img src={dropdownIcon} alt={formatMessage({ id: 'text.dropdownIcon' })} />}
                    className={styles.dropdown}
                    placeholder={formatMessage({ id: 'text.categoryNotSet' })}
                  >
                    <Option
                      key="default"
                      className={styles.option}
                      value={formatMessage({ id: 'text.categoryNotSet' })}
                    >
                      {formatMessage({ id: 'form.labels.selectCategory' })}
                    </Option>
                    {categorySelectorDropDown}
                  </Select>
                </div>
                <div className={styles.saveButtonDiv}>
                  <Button className={styles.saveButton} type="primary" onClick={handleSaveButtonPress}>
                    {formatMessage({ id: 'form.button.save' })}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.modalBodyMobile}>
            <div className={styles.imageWrapperDiv}>
              <div className={styles.fileUploadDiv}>
                <Icon
                  type="left"
                  className={
                    thumbnailIndex !== null && thumbnailIndex !== 0 ? styles.arrowButtonClickable : styles.arrowButton
                  }
                  onClick={() => handleClickLeftArrow()}
                />
                {showThumbnail ? (
                  <div className={styles.imageDiv}>
                    <ReactCrop
                      src={mainImageInModalSrc}
                      crop={crop}
                      onImageLoaded={onImageLoaded}
                      onComplete={onCropComplete}
                      onChange={onCropChange}
                      imageStyle={{ width: 'auto', maxHeight: '165px' }}
                    />
                  </div>
                ) : (
                  <div className={styles.imageDiv}>
                    <Dragger
                      onChange={handleFileUpload}
                      className={styles.dragger}
                      beforeUpload={(file) => {
                        Promise.resolve(file).then(
                          this.setState((state) => ({
                            fileList: [...state.fileList, file],
                          })),
                        );
                        return false;
                      }}
                      accept=".jpeg,.jpg,.png"
                    >
                      <button type="submit" className={styles.uploadButton}>
                        <div className={styles.internalUploadButton}>
                          <img src={uploadIcon} alt={formatMessage({ id: 'text.uploadIcon' })} />
                        </div>
                        <div className={styles.textFiles}>{formatMessage({ id: 'text.files' })}</div>
                        <div className={styles.defaultUploadText}>
                          <div className={styles.clickHere}>{formatMessage({ id: 'form.button.choseFile' })}</div>
                        </div>
                      </button>
                    </Dragger>
                  </div>
                )}
                <Icon
                  type="right"
                  className={
                    (newThumbnails
                    ? newThumbnails.length !== 0 && newThumbnails.length - 1 > thumbnailIndex
                    : '')
                      ? styles.arrowButtonClickable
                      : styles.arrowButton
                  }
                  onClick={() => handleRightArrowClick()}
                />
              </div>
            </div>
            <div className={styles.titlesGroupInputDiv}>
              <div className={styles.titleDiv}>
                <span className={styles.title}>{formatMessage({ id: 'form.labels.enterTitle' })}</span>
                <Input
                  className={styles.titleInput}
                  onChange={(e) => handleInput(e.target.value)}
                  value={titleInput}
                  placeholder={formatMessage({ id: 'form.placeholder.enterTitle' })}
                />
              </div>
              <div className={styles.titleDiv}>
                <span className={styles.categoryTitle}>{formatMessage({ id: 'form.labels.selectCategory' })}</span>
                <Select
                  onChange={(value) => handleSelectChange(value)}
                  value={categoryInput}
                  suffixIcon={<img src={dropdownIcon} alt={formatMessage({ id: 'text.dropdownIcon' })} />}
                  className={styles.dropdown}
                  placeholder={categoryButtonSelected}
                >
                  <Option key="default" className={styles.option} value={formatMessage({ id: 'text.categoryNotSet' })}>
                    {formatMessage({ id: 'form.labels.selectCategory' })}
                  </Option>
                  {categorySelectorDropDown}
                </Select>
              </div>
              <div className={styles.saveButtonDiv}>
                <Button className={styles.saveButtonMobile} type="primary" onClick={handleSaveButtonPress}>
                  {formatMessage({ id: 'form.button.save' })}
                </Button>
              </div>
            </div>
            <div className={styles.thumbnailColumn}>
              <div className={styles.addAndDisplayDiv}>
                <Button
                  type="link"
                  className={styles.addNewBox}
                  onClick={() => handleClickAddNewImage(false, null, '', formatMessage({ id: 'text.categoryNotSet' }))}
                >
                  <Icon type="plus" className={styles.plusIcon} />
                </Button>
                <span className={styles.addNewText}>{formatMessage({ id: 'form.button.addNew' })}</span>
              </div>
              {displayThumbnails()}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default injectIntl(SchematicViewModalBody);
