/* eslint-disable @typescript-eslint/no-explicit-any */
import { getDocumentTypes } from '../../services/api';
import { DocumentTypesActionTypes } from '.';

// eslint-disable-next-line import/prefer-default-export
export const fetchDocumentTypes = () => async (dispatch): Promise<any> => {
  const documentTypes = await getDocumentTypes();
  dispatch({
    type: DocumentTypesActionTypes.SAVE,
    payload: documentTypes,
  });
};
