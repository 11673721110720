import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import servers from '../../utils/servers';
import styles from './AircraftDetails.module.less';

const AircraftDetails = ({ aircraft, intl: { formatMessage } }) => {
  const checkLength = aircraft
    ? Number(aircraft.serial_number.length) + Number(aircraft.aircraft_type.model.length)
    : 0;
  const lastFlownInSeconds = moment().diff(moment(aircraft.last_flown));
  const lastFlown = aircraft.last_flown !== null ? moment.duration(lastFlownInSeconds).humanize() : '-';
  return aircraft ? (
    <div className={styles.parentAircraftDetails}>
      <div
        className={styles.aircraftImg}
        style={{
          backgroundImage: `url(${servers.api}${aircraft.aircraft_type.aircraft_image_url})`,
        }}
        alt={aircraft.registration}
        data-test="aircraftSilhouette"
      />
      <div className={styles.aircraftText}>
        <div className={styles.aircraftReg} data-test="aircraftRegistration">
          {aircraft.registration || '-'}
        </div>
        <div className={styles.aircraftType}>
          <span data-test="aircraftModel">{aircraft.aircraft_type.model || '-'}</span>
          <span data-test="aircraftSerialNo">
            {aircraft.serial_number ? `${checkLength > 20 ? `\n` : ` | `}${aircraft.serial_number}` : '-'}
          </span>
        </div>
      </div>
      <div className={styles.infoWrapper}>
        <div>
          <div className={styles.infoLabel}>{formatMessage({ id: 'text.fuelOnBoard' })}</div>
          <div>
            {aircraft.fuel_on_board || '-'} {aircraft.fuel_on_board ? aircraft.fuel_unit : null}
          </div>
        </div>
        <div>
          <div className={styles.infoLabel}>{`${formatMessage({ id: 'title.lastFlownCaps' })}`}</div>
          <div>
            <div className={styles.lastFlown}>
              {lastFlown}
              {aircraft.last_flown !== null ? <span className={styles.lowercase}> ago</span> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.loading}>{formatMessage({ id: 'text.loading' })}</div>
  );
};

AircraftDetails.propTypes = {
  aircraft: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AircraftDetails);
