import React from 'react';
import PropTypes from 'prop-types';
import EmptyStateNoData from '../../assets/emptyState/empty-state-no-data.svg';
import styles from './NoData.module.less';

const NoData = ({ isNoOilData, isNoOilDataForRange, image }) => {
  let displayText = 'No Data';
  if (!isNoOilData && isNoOilDataForRange) {
    displayText = 'No Data Available For Given Range';
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.emptyText}>
        <img src={image || EmptyStateNoData} alt="empty state" />
        <span>{displayText}</span>
      </div>
    </div>
  );
};

NoData.propTypes = {
  isNoOilDataForRange: PropTypes.bool,
  isNoOilData: PropTypes.bool,
  image: PropTypes.string,
};

NoData.defaultProps = {
  isNoOilDataForRange: false,
  isNoOilData: false,
  image: undefined,
};

export default NoData;
