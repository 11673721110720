import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSingleWorkpack } from '../../models/workpacks/actions';
import WorkpackForm from './WorkpackForm';

class EditWorkpack extends PureComponent {
  static propTypes = {
    workpacksMap: PropTypes.instanceOf(Map).isRequired,
    match: PropTypes.object.isRequired,
    getWorkpack: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const workpack = this.getWorkpack();
    if (!workpack || Date.now() - workpack.lastFetched > 30000) {
      this.getWorkpack(true);
    }
  }

  getWorkpack(forceRefecth = false) {
    const { getWorkpack, match, workpacksMap } = this.props;
    if (forceRefecth) {
      getWorkpack(match.params.workpack_id);
    }
    return workpacksMap.get(match.params.workpack_id);
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const workpack = this.getWorkpack();
    return (
      <WorkpackForm
        title={`${formatMessage({ id: 'title.editWorkpack' })}${
          workpack && workpack.po_number ? `: ${workpack.po_number}` : ''
        }`}
        content={formatMessage({ id: 'text.editWorkpack' })}
        onSave={this.handleSave}
        data={workpack || null}
      />
    );
  }
}

const editWorkpackRedux = connect(
  ({ workpacks }) => ({
    workpacksMap: workpacks.workpacksMap,
  }),
  (dispatch) => ({
    getWorkpack: (id) => {
      dispatch(
        getSingleWorkpack({
          payload: id,
        }),
      );
    },
  }),
)(EditWorkpack);

export default withRouter(injectIntl(editWorkpackRedux));
