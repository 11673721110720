import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Card, Form, Input, DatePicker, Select, message } from 'antd';
import moment from 'moment';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import DefectsTable, { allowedStatuses } from '../Workpacks/DefectsTable';
import ScheduledTable from '../Workpacks/ScheduledTable';
import OopTable from '../Workpacks/OopTable';
import LlpTable from '../Workpacks/LlpTable';
import defaults from '../../utils/defaults';
import Loading from '../../components/TFLoading';
import { postWorkpack, putWorkpack } from '../../services/api';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { getSingleWorkpack } from '../../models/workpacks/actions';
import { getAircraftsDefects } from '../../models/defects/actions';
import { getAllMxItems as getAllMxItemsAction } from '../../models/maintenance/actions';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import logo from '../../assets/back-arrow.svg';
import styles from './style.module.less';

const { Option } = Select;

class WorkpackForm extends PureComponent {
  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    content: PropTypes.string.isRequired,
    data: PropTypes.object,
    defectsLastFetched: PropTypes.number.isRequired,
    defectsMap: PropTypes.instanceOf(Map).isRequired,
    fetchAircraft: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    getWorkpack: PropTypes.func.isRequired,
    getAllDefects: PropTypes.func.isRequired,
    getAllMxItems: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    mxItems: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    userSettings: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDefects: [],
      selectedScheduled: [],
      selectedOop: [],
      selectedLlp: [],
      scheduled: [],
      closedScheduled: [],
      closedOop: [],
      closedLlp: [],
      oop: [],
      llp: [],
      existingItemMap: {},
      submitting: false,
      loading: true,
    };
    this.handleSaveOnClick = this.handleSaveOnClick.bind(this);
    this.handleCancelOnClick = this.handleCancelOnClick.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    const aircraft = this.getAircraft();
    if (!aircraft || Date.now() - aircraft.lastFetched > 30000) {
      this.getAircraft(true);
    } else {
      this.getAircraftDefects();
      this.getAircraftMaintenanceItems();
    }
    if (data) {
      this.populateFormWithExistingWorkpackData();
    }
  }

  componentDidUpdate(prevProps) {
    const { data, mxItems } = this.props;
    const aircraft = this.getAircraft();
    const prevAircraft = prevProps.aircraftMap.get(prevProps.match.params.id);
    if (
      // current aircraft but no previous aircraft
      (aircraft && !prevAircraft) ||
      // the aircraft has changed
      (aircraft && prevAircraft && aircraft.id !== prevAircraft.id) ||
      // same aircraft has been updated
      (aircraft && prevAircraft && aircraft.lastFetched !== prevAircraft.lastFetched)
    ) {
      this.getAircraftDefects();
      this.getAircraftMaintenanceItems();
    }
    if (data !== prevProps.data) {
      this.populateFormWithExistingWorkpackData();
    }

    if (mxItems !== prevProps.mxItems) {
      this.filterMxItems();
    }
  }

  getAircraft = (forceRefetch = false) => {
    const { match, fetchAircraft, aircraftMap } = this.props;
    if (forceRefetch) {
      fetchAircraft(match.params.id);
    }
    return aircraftMap.get(match.params.id);
  };

  getAircraftDefects() {
    const selectedAircraft = this.getAircraft();
    const { getAllDefects } = this.props;

    getAllDefects({
      aircraftId: selectedAircraft.id,
    });
  }

  async getAircraftMaintenanceItems() {
    const { getAllMxItems } = this.props;
    const selectedAircraft = this.getAircraft();
    await getAllMxItems({
      aircraft_id: selectedAircraft.id,
      status: ['open', 'overdue'],
    });
    await new Promise((resolve) => this.setState({ loading: false }, resolve));
  }

  filterMxItems = () => {
    const scheduled = [];
    const oop = [];
    const llp = [];
    this.props.mxItems.forEach((item) => {
      if (item.mx_type && item.mx_type.includes('scheduled')) {
        scheduled.push(item);
      } else if (item.mx_type && item.mx_type.includes('oop')) {
        oop.push(item);
      } else if (item.mx_type && item.mx_type.includes('llp')) {
        llp.push(item);
      } else if (item.mx_type === null) {
        scheduled.push(item);
      }
    });
    this.setState({
      scheduled,
      oop,
      llp,
    });
  };

  handleDefectsTableChange = async (pagination, filters, sorter) => {
    const selectedAircraft = this.getAircraft();
    const params = {
      page: pagination.current,
      current: pagination.current,
      limit: pagination.pageSize,
      ...filters,
    };
    if (selectedAircraft) {
      params.aircraftId = selectedAircraft.id;
    }

    if (sorter.field) {
      params.sort_column = sorter.column ? sorter.column.sortName : 'date';
      params.sort_order = sorter.order || 'descend';
    } else {
      params.sort_order = 'descend';
    }
    await new Promise((resolve) => this.setState({ loading: true }, resolve));
    await this.props.getAllDefects(params);
    await new Promise((resolve) => this.setState({ loading: false }, resolve));
  };

  async addNewWorkpack(payload) {
    const {
      history,
      intl: { formatMessage },
      getWorkpack,
    } = this.props;
    const selectedAircraft = this.getAircraft();
    try {
      const response = await postWorkpack(payload);
      if (response && response.statusCode === 422) {
        throw new Error(response.statusCode);
      } else if (response && response.statusCode > 199 && response.statusCode < 400) {
        getWorkpack(response.body.id);
        history.push(`/aircraft/${selectedAircraft.id}/workpacks`);
        message.success(formatMessage({ id: 'message.workpackAdded' }));
      }
    } catch (err) {
      message.error(formatMessage({ id: 'message.workpackAlreadyExists' }));
    }
  }

  async updateWorkpack(payload) {
    const {
      history,
      intl: { formatMessage },
      data,
      getWorkpack,
    } = this.props;
    const selectedAircraft = this.getAircraft();
    try {
      const response = await putWorkpack({
        id: data.id,
        body: payload,
      });
      if (response && response.statusCode === 422) {
        throw new Error(response.statusCode);
      } else if (response && response.statusCode > 199 && response.statusCode < 400) {
        getWorkpack(response.body.id);
        history.push(`/aircraft/${selectedAircraft.id}/workpacks`);
        message.success(formatMessage({ id: 'message.workpackUpdated' }));
      }
    } catch (err) {
      message.error(formatMessage({ id: 'message.workpackAlreadyExists' }));
    }
  }

  handleSaveOnClick() {
    const selectedAircraft = this.getAircraft();
    const { data, form, intl } = this.props;
    const { selectedDefects, selectedScheduled, selectedOop, selectedLlp, existingItemMap } = this.state;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        const workpackItems = [];
        selectedDefects
          .concat(selectedScheduled)
          .concat(selectedOop)
          .concat(selectedLlp)
          .forEach((item) => {
            workpackItems.push({
              id: existingItemMap[item],
              mx_item_id: item,
            });
            // @TODO inspect the consequences of this delete clause against the component's state.
            delete existingItemMap[item];
          });
        Object.keys(existingItemMap).forEach((key) => {
          workpackItems.push({
            id: existingItemMap[key],
            _destroy: true,
          });
        });
        const payload = {
          aircraft_id: selectedAircraft.id,
          po_number: values.po_number,
          date: moment(values.date).format('YYYY-MM-DD'),
          status: values.status,
          workpack_items: workpackItems,
        };
        // eslint-disable-next-line no-underscore-dangle
        const checkWorkpack = workpackItems.filter((it) => !it._destroy);
        if (checkWorkpack.length <= 0 && values.status === 'active') {
          message.error(intl.formatMessage({ id: 'message.emptyActiveWorkPack' }));
        } else {
          this.setState({ submitting: true });
          if (data) {
            this.updateWorkpack(payload);
          } else {
            this.addNewWorkpack(payload);
          }
        }
      }
    });
  }

  handleCancelOnClick() {
    const { history } = this.props;
    const selectedAircraft = this.getAircraft();
    history.push(`/aircraft/${selectedAircraft.id}/workpacks`);
  }

  populateFormWithExistingWorkpackData() {
    const {
      form,
      data,
      userSettings: { dateFormat },
    } = this.props;
    const date = moment(data.date).format(dateFormat);
    form.setFieldsValue({
      po_number: data.po_number,
      date: moment(date, dateFormat),
      status: data.status,
    });
    const selectedDefects = [];
    const selectedScheduled = [];
    const selectedOop = [];
    const selectedLlp = [];
    const closedScheduled = [];
    const closedOop = [];
    const closedLlp = [];
    const existingItemMap = {};
    data.workpack_items.forEach((item) => {
      if (item.mx_item.type && item.mx_item.type === 'defect') {
        selectedDefects.push(item.mx_item.id);
      } else if (item.mx_item.mx_type && item.mx_item.mx_type.includes('oop')) {
        selectedOop.push(item.mx_item.id);
        if (item.mx_item.status === 'resolved') {
          closedOop.push(item.mx_item);
        }
      } else if (item.mx_item.mx_type && item.mx_item.mx_type.includes('llp')) {
        selectedLlp.push(item.mx_item.id);
        if (item.mx_item.status === 'resolved') {
          closedLlp.push(item.mx_item);
        }
      } else if (item.mx_item.mx_type && item.mx_item.mx_type.includes('scheduled')) {
        selectedScheduled.push(item.mx_item.id);
        if (item.mx_item.status === 'resolved') {
          closedScheduled.push(item.mx_item);
        }
      }
      existingItemMap[item.mx_item.id] = item.id;
    });
    this.setState({
      selectedDefects,
      selectedScheduled,
      selectedOop,
      selectedLlp,
      closedScheduled,
      closedOop,
      closedLlp,
      existingItemMap,
    });
  }

  render() {
    const selectedAircraft = this.getAircraft();
    const {
      form,
      title,
      content,
      intl: { formatMessage },
      userSettings,
      defectsMap,
      defectsLastFetched,
      pagination,
    } = this.props;
    const {
      selectedScheduled,
      selectedDefects,
      selectedLlp,
      selectedOop,
      closedScheduled,
      closedOop,
      closedLlp,
      scheduled,
      oop,
      llp,
      submitting,
      loading,
    } = this.state;
    const { getFieldDecorator } = form;
    const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;
    return (
      <InnerMenuLayout>
        <Loading loading={defectsLastFetched === 0 || loading} />
        <PageHeaderWrapper
          logo={
            <Link to={selectedAircraft ? `/aircraft/${selectedAircraft.id}/workpacks` : ''}>
              <img src={logo} alt="back arrow" className={styles.backIcon} />
            </Link>
          }
          title={title}
          content={content}
        >
          <Card bordered={false}>
            <Form layout="inline" className={styles.formItems}>
              <Form.Item label={formatMessage({ id: 'title.poNumber' })}>
                {getFieldDecorator('po_number', {
                  rules: [
                    {
                      required: true,
                      message: formatMessage({ id: 'message.poRequired' }),
                    },
                  ],
                })(<Input className={styles.workpackInput} name="po_number" />)}
              </Form.Item>
              <Form.Item label={formatMessage({ id: 'title.date' })}>
                {getFieldDecorator('date', {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: formatMessage({ id: 'message.dateRequired' }),
                    },
                  ],
                })(<DatePicker className={styles.workpackInput} format={dateFormat} />)}
              </Form.Item>
              <Form.Item label={formatMessage({ id: 'title.status' })} className={styles.formStatus}>
                {getFieldDecorator('status', {
                  initialValue: 'active',
                })(
                  <Select>
                    <Option value="draft">{formatMessage({ id: 'form.option.draft' })}</Option>
                    <Option value="active">{formatMessage({ id: 'form.option.active' })}</Option>
                  </Select>,
                )}
              </Form.Item>
              <Form.Item className={styles.workpackButtons}>
                <Button onClick={this.handleCancelOnClick}>{formatMessage({ id: 'form.button.cancel' })}</Button>
                <Button
                  type="primary"
                  loading={submitting}
                  className={styles.saveButton}
                  onClick={this.handleSaveOnClick}
                  name="save"
                >
                  {formatMessage({ id: 'form.button.save' })}
                </Button>
              </Form.Item>
            </Form>
            <DefectsTable
              title={() => (
                <>
                  <span className={styles.tableTitle}>{formatMessage({ id: 'title.defects' })}</span>
                  <span className={styles.selectionCount}>
                    {selectedDefects.length} {formatMessage({ id: 'text.selected' })}
                  </span>
                </>
              )}
              rowSelection={{
                selectedRowKeys: selectedDefects,
                onChange: (selectedRowKeys) => {
                  this.setState({
                    selectedDefects: selectedRowKeys,
                  });
                },
              }}
              rowKey={(item) => {
                return item.id;
              }}
              dataSource={Array.from(defectsMap.values()).filter((defect) => allowedStatuses.includes(defect.status))}
              pagination={pagination}
              onChange={this.handleDefectsTableChange}
            />
            <ScheduledTable
              title={() => (
                <>
                  <span className={styles.tableTitle}>{formatMessage({ id: 'title.scheduledItems' })}</span>
                  <span className={styles.selectionCount}>
                    {selectedScheduled.length} {formatMessage({ id: 'text.selected' })}
                  </span>
                </>
              )}
              rowSelection={{
                selectedRowKeys: selectedScheduled,
                onChange: (selectedRowKeys) => {
                  this.setState({
                    selectedScheduled: selectedRowKeys,
                  });
                },
              }}
              rowKey={(item) => {
                return item.id;
              }}
              dataSource={closedScheduled.concat(scheduled)}
              selectedAircraft={selectedAircraft}
            />
            <OopTable
              title={() => (
                <>
                  <span className={styles.tableTitle}>{formatMessage({ id: 'title.outOfPhaseItems' })}</span>
                  <span className={styles.selectionCount}>
                    {selectedOop.length} {formatMessage({ id: 'text.selected' })}
                  </span>
                </>
              )}
              rowSelection={{
                selectedRowKeys: selectedOop,
                onChange: (selectedRowKeys) => {
                  this.setState({
                    selectedOop: selectedRowKeys,
                  });
                },
              }}
              rowKey={(item) => {
                return item.id;
              }}
              dataSource={closedOop.concat(oop)}
              selectedAircraft={selectedAircraft}
            />
            <LlpTable
              title={() => (
                <>
                  <span className={styles.tableTitle}>{formatMessage({ id: 'title.lifeLimitedParts' })}</span>
                  <span className={styles.selectionCount}>
                    {selectedLlp.length} {formatMessage({ id: 'text.selected' })}
                  </span>
                </>
              )}
              rowSelection={{
                selectedRowKeys: selectedLlp,
                onChange: (selectedRowKeys) => {
                  this.setState({
                    selectedLlp: selectedRowKeys,
                  });
                },
              }}
              rowKey={(item) => {
                return item.id;
              }}
              dataSource={closedLlp.concat(llp)}
              selectedAircraft={selectedAircraft}
            />
          </Card>
        </PageHeaderWrapper>
      </InnerMenuLayout>
    );
  }
}

const WorkpackFormWithForm = Form.create()(WorkpackForm);

export default withRouter(
  injectIntl(
    connect(
      ({ aircraft, workpack, defects, maintenance, userSettings }, { match }) => {
        const mxItems = Array.from(maintenance.maintenanceMap.values())
          .filter((item) => match.params && match.params.id === item.aircraft.id && item.draft === false)
          .sort((a, b) => {
            const statusOrder = b.status.localeCompare(a.status);
            if (statusOrder === 0) {
              return a.id.localeCompare(b.id);
            }
            return statusOrder;
          });
        return {
          aircraftMap: aircraft.aircraftMap,
          workpack,
          defectsMap: defects.defectsMap,
          pagination: defects.pagination,
          defectsLastFetched: defects.lastFetched,
          maintenance,
          mxItems,
          userSettings,
          lastFetched: mxItems.length > 0 ? maintenance.lastFetched : 0,
        };
      },
      (dispatch) => ({
        fetchAircraft: (id) =>
          dispatch(
            getSingleAircraft({
              type: 'aircraft/getSingleAircraft',
              payload: id,
            }),
          ),
        getWorkpack: (id) =>
          dispatch(
            getSingleWorkpack({
              payload: id,
            }),
          ),
        getAllDefects: (payload) => dispatch(getAircraftsDefects(payload)),
        getAllMxItems: (payload) =>
          dispatch(
            getAllMxItemsAction({
              payload,
            }),
          ),
      }),
    )(WorkpackFormWithForm),
  ),
);
