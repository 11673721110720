import { Defect } from '../defects';
import { BaseModel } from '..';

export const initialState: AircraftState = {
  aircraftMap: new Map(),
  newAircraftId: null,
  lastFetched: 0,
  ttl: 30000,
  data: {
    list: [],
  },
};

export enum AircraftActionTypes {
  SAVE = 'aircraft/save',
  SAVE_ALL = 'aircraft/saveAll',
  SAVE_NEW = 'aircraft/saveNew',
  REMOVE = 'aircraft/removePerson',
}

export enum AircraftResource {
  ADDITIONAL_CREW = 'AdditionalCrew',
  AIRCRAFT = 'Aircraft',
  DAMAGE_MAP = 'DamageMap',
  DEFECT = 'Defect',
  DOCUMENT = 'Document',
  FLIGHT = 'Flight',
  LOGBOOK = 'Logbook',
  RELEASE = 'Release',
  SCHEDULED_MX_ITEM = 'ScheduledMxItem',
  TRIP = 'Trip',
  WORKPACK = 'Workpack',
}

export enum AircraftPermission {
  CREATE = 'to_create',
  READ = 'to_read',
  UPDATE = 'to_update',
  DELETE = 'to_delete',
}
export interface SaveAircraftAction {
  type: AircraftActionTypes.SAVE;
  payload: Aircraft;
}

export interface SaveAllAircraftAction {
  type: AircraftActionTypes.SAVE_ALL;
  payload: Aircraft[];
}

export interface SaveNewAircraftAction {
  type: AircraftActionTypes.SAVE_NEW;
  payload: Aircraft;
}

export interface RemoveAircraftAction {
  type: AircraftActionTypes.REMOVE;
  payload: Aircraft;
}

type AircraftAction = SaveAircraftAction | SaveAllAircraftAction | SaveNewAircraftAction | RemoveAircraftAction;

export const isCompleteAircraftRecord = (record: BaseAircraft | Aircraft): record is Aircraft =>
  record &&
  'maintenance' in record &&
  'departure_airport' in record &&
  'destination_airport' in record &&
  'last_airport' in record &&
  'totals' in record &&
  'tiles' in record;

export default function(state = initialState, action: AircraftAction): AircraftState {
  switch (action.type) {
    case AircraftActionTypes.SAVE: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      aircraftMap.set(action.payload.id, {
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        aircraftMap,
      };
    }
    case AircraftActionTypes.SAVE_NEW: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      const newAircraftId = action.payload.id;
      aircraftMap.set(action.payload.id, {
        ...action.payload,
        lastFetched: Date.now(),
      });
      return {
        ...state,
        newAircraftId,
        aircraftMap,
      };
    }
    case AircraftActionTypes.SAVE_ALL: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      action.payload.forEach((aircraft: Aircraft | BaseAircraft) => {
        const stateAircraft = aircraftMap.get(aircraft.id);
        const lastFetched = isCompleteAircraftRecord(aircraft) ? Date.now() : stateAircraft?.lastFetched || 0;
        aircraftMap.set(aircraft.id, {
          ...stateAircraft,
          ...aircraft,
          lastFetched,
        });
      });
      return {
        ...state,
        aircraftMap,
        lastFetched: Date.now(),
      };
    }
    case AircraftActionTypes.REMOVE: {
      const aircraftMap = new Map(state.aircraftMap.entries());
      aircraftMap.delete(action.payload.id);
      return {
        ...state,
        aircraftMap,
      };
    }
    default:
      return state;
  }
}

export interface AircraftState {
  aircraftMap: Map<string, Aircraft | BaseAircraft>;
  newAircraftId?: string;
  lastFetched: number;
  ttl: number;
  data: {
    list: Aircraft[];
  };
}

export interface AircraftType {
  id: string;
  manufacturer: string;
  model: string;
  designator: string;
  category: string;
  engine_count: number;
  engine_type: string;
  weight_category: string;
  aircraft_image_url: string;
}

export interface BaseAircraft extends BaseModel {
  id: string;
  serial_number: string;
  registration: string;
  year: number;
  simulator: false;
  apu_installed: false;
  operator_id: string;
  max_fuel_value: number;
  fuel_unit: string;
  oil_unit: string;
  maintenance_status: string;
  flight_status: string;
  mx_display_days: null;
  mx_thresholds: {};
  aircraft_type: AircraftType;
  camp_integration_enabled: boolean;
  camp_last_synced: string;
}

export interface Aircraft extends BaseAircraft {
  last_airport: string;
  departure_airport: {
    ident: string;
    latitude: string;
    longitude: string;
  };
  destination_airport: {
    ident: string;
    latitude: string;
    longitude: string;
  };
  camp_integration_enabled: boolean;
  days_remaining: number;
  flight_seconds_remaining: number;
  apu_seconds_remaining: number;
  cycles_remaining: number;
  open_defects: Defect[];
  limitations: string[];
  estimated_next_due_date: string;
}

export const defaultWritePermissions = [
  {
    resource_type: AircraftResource.ADDITIONAL_CREW,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.DAMAGE_MAP,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.DEFECT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.DOCUMENT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.FLIGHT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.LOGBOOK,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.RELEASE,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.SCHEDULED_MX_ITEM,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.TRIP,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.WORKPACK,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
];

export const defaultPilotPermissions = [
  {
    resource_type: AircraftResource.ADDITIONAL_CREW,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DAMAGE_MAP,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DEFECT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.DOCUMENT,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.FLIGHT,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.LOGBOOK,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.RELEASE,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.SCHEDULED_MX_ITEM,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.TRIP,
    to_read: true,
    to_create: true,
    to_update: true,
    to_delete: true,
  },
  {
    resource_type: AircraftResource.WORKPACK,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
];

export const defaultReadPermissions = [
  {
    resource_type: AircraftResource.ADDITIONAL_CREW,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DAMAGE_MAP,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DEFECT,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.DOCUMENT,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.FLIGHT,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.LOGBOOK,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.RELEASE,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.SCHEDULED_MX_ITEM,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.TRIP,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
  {
    resource_type: AircraftResource.WORKPACK,
    to_read: true,
    to_create: false,
    to_update: false,
    to_delete: false,
  },
];
