import { BaseModel } from '..';

const initialState: TripsState = {
  ephemeralTrip: {},
  tripsMap: new Map(),
  lastFetched: 0,
  data: [],
  newTrip: null,
  source: null,
};

export enum TripsActionTypes {
  DELETE_TRIP = 'trips/deleteTrip',
  SAVE_ALL = 'trips/saveAll',
  SAVE_EPHEMERAL_ENTRY = 'trips/saveEphemeralEntry',
  SAVE = 'trips/save',
  SAVE_SOURCE = 'trips/saveSource',
}

interface DeleteTripTripsActionType {
  type: TripsActionTypes.DELETE_TRIP;
  payload: Trip;
}

interface SaveAllTripsActionType {
  type: TripsActionTypes.SAVE_ALL;
  payload: Trip[];
}

interface SaveEphemeralEntryTripsActionType {
  type: TripsActionTypes.SAVE_EPHEMERAL_ENTRY;
  payload: Trip;
}

interface SaveTripsActionType {
  type: TripsActionTypes.SAVE;
  payload: Trip;
}

interface SaveSourceTripsActionType {
  type: TripsActionTypes.SAVE_SOURCE;
  payload: TripsState;
}

type TripsAction =
  | DeleteTripTripsActionType
  | SaveAllTripsActionType
  | SaveEphemeralEntryTripsActionType
  | SaveTripsActionType
  | SaveSourceTripsActionType;

export default function(state = initialState, action: TripsAction): TripsState {
  switch (action.type) {
    case TripsActionTypes.SAVE_ALL: {
      const tripsMap = new Map(state.tripsMap.entries());
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((trip) => {
          tripsMap.set(trip.id, {
            ...tripsMap.get(trip.id),
            ...trip,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        tripsMap,
        lastFetched: Date.now(),
        data: action.payload,
      };
    }
    case TripsActionTypes.SAVE: {
      const tripsMap = new Map(state.tripsMap.entries());
      if (action.payload && action.payload.id) {
        tripsMap.set(action.payload.id, {
          ...tripsMap.get(action.payload.id),
          ...action.payload,
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        tripsMap,
        lastFetched: Date.now(),
        newTrip: action.payload,
      };
    }
    case TripsActionTypes.SAVE_EPHEMERAL_ENTRY: {
      return {
        ...state,
        ephemeralTrip: {
          ...state.ephemeralTrip,
          ...action.payload,
        },
      };
    }
    case TripsActionTypes.SAVE_SOURCE: {
      return {
        ...state,
        source: action.payload.source,
      };
    }
    case TripsActionTypes.DELETE_TRIP: {
      const tripsMap = new Map(state.tripsMap.entries());
      if (action.payload && action.payload.id) {
        tripsMap.delete(action.payload.id);
      }
      return {
        ...state,
        tripsMap,
        newTrip: null,
      };
    }
    default:
      return state;
  }
}

export interface TripsState {
  tripsMap: Map<string, Trip>;
  ephemeralTrip: Trip | {};
  lastFetched: number;
  data: Trip[];
  newTrip: Trip;
  source: string;
}

export interface Trip extends BaseModel {
  id: string;
}
