import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import closeIcon from '../../assets/close-icon-mobile.svg';

const slideOut = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const fadeBg = keyframes`
  from {
    background-color: rgba(50, 50, 50, 0.5);
  }
  to {
    background-color: rgba(50, 50, 50, 0);
  }
`;

const DrawerSlideOut = css`
  height: calc(100vh - 24px);
  width: 872px;
  background-color: #f7f7f7;
  border-radius: 5px 0 0 5px;
  animation: ${slideOut} 0.3s;
  @media (max-width: 770px) {
    width: 768px;
  }
`;

const DrawerSlideIn = css`
  height: calc(100vh - 24px);
  width: 872px;
  background-color: #f7f7f7;
  border-radius: 5px 0 0 5px;
  animation: ${slideIn} 0.3s;
  @media (max-width: 770px) {
    width: 768px;
  }
`;

const BGFadeOut = css`
  animation: ${fadeBg} 0.3s;
`;

const DrawerBG = styled.div`
  background-color: rgba(3, 44, 82, 0.33);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  z-index: 200;
  ${({ visible }): string => (!visible ? null : BGFadeOut)}
`;

const DrawerWrapper = styled.div`
  width: 0;
  height: calc(100vh - 24px);
  right: 0;
  top: 12px;
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  ${({ visible }): string => (!visible ? DrawerSlideIn : DrawerSlideOut)}
`;

const DrawerInnerWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const DrawerCloseButton = styled.button`
  position: absolute;
  top: 21px;
  right: 35px;
  z-index: 201;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const CloseIcon = styled.img`
  height: 12px;
  width: 12px;
`;

const ChildWrapper = styled.div`
  width: 100%;
  text-align: unset;
  height: 100%;
`;

interface TFDrawerProps {
  toggleDrawer: () => void;
  visible: boolean;
}

const TFDrawer: React.FC<TFDrawerProps> = ({ toggleDrawer, children, visible }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      setDrawerVisible(visible);
      document.querySelector('body').setAttribute('style', 'overflow: hidden');
    }
  }, [visible]);

  const closeDrawer = (): void => {
    setDrawerVisible(false);
    document.querySelector('body').setAttribute('style', 'overflow: scroll');
    setTimeout(() => {
      toggleDrawer();
    }, 290);
  };

  return (
    <DrawerBG
      data-testid="TFDrawer--Background"
      onClick={closeDrawer}
      tabIndex={0}
      onKeyDown={(): void => null}
      role="button"
    >
      <DrawerWrapper visible={drawerVisible} id="tfDrawerWrapper" data-testid="TFDrawer--DrawerWrapper">
        <DrawerInnerWrapper id="drawerWrapper">
          <DrawerCloseButton
            type="button"
            onClick={(e): void => {
              e.stopPropagation();
              closeDrawer();
            }}
            data-testid="TFDrawer--CloseButton"
          >
            <CloseIcon src={closeIcon} alt="close icon" id="drawerCloseButton" />
          </DrawerCloseButton>
          <ChildWrapper
            role="button"
            onClick={(e): void => e.stopPropagation()}
            onKeyDown={(): void => null}
            tabIndex={0}
          >
            <div data-testid="TFDrawer--ChildrenWrapper">{children}</div>
          </ChildWrapper>
        </DrawerInnerWrapper>
      </DrawerWrapper>
    </DrawerBG>
  );
};

export default TFDrawer;
