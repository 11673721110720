/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Button } from 'antd';
import searchIcon from './assets/search.svg';
import styles from './index.module.less';

const SearchInput = ({ placeholder, value, onChange, inputRef }) => {
  const searchRef = useRef();

  return (
    <div ref={searchRef} className={styles.searchHolder}>
      <Button type="link" className={styles.searchIconHolder}>
        <img
          src={searchIcon}
          alt="search"
          className={`${styles.searchIcon} ${styles.searchIconActive}`}
          data-test="searchIcon"
        />
      </Button>
      <div className={styles.searchInputHolder}>
        <Input
          className={`${styles.searchInput} ${inputRef} ${styles.searchInputActive}`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          data-testid="searchInput"
        />
      </div>
      {value ? (
        <Button
          type="link"
          className={`${styles.closeIconHolder} ${styles.closeIconHolderActive}`}
          onClick={() => onChange('')}
          data-test="closeIcon"
        >
          <Icon type="close-circle" className={styles.closeIcon} />
        </Button>
      ) : null}
    </div>
  );
};

SearchInput.defaultProps = {
  value: null,
  inputRef: '',
};

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.string,
};

export default SearchInput;
