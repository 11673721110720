import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import SearchInput from '../SearchInput';
import styles from './listFilter.module.less';
import PeriodsDropdown from './PeriodsDropdown';

class ListFilter extends Component {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
    mxPeriods: PropTypes.array.isRequired,
    mxPeriodsDays: PropTypes.number.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchVisible: false,
      searchValue: '',
    };
  }

  setSearchValue = (value) => {
    this.setState({ searchValue: value });
    this.props.onSearchChange(value);
  };

  showSearchInput = () => {
    const { searchVisible, searchValue } = this.state;
    if (searchVisible && searchValue) {
      // input is open and has some filtered value - first remove value, than close
      this.setSearchValue(null);
    }
    this.setState({
      searchVisible: !searchVisible,
    });
  };

  render() {
    const {
      intl: { formatMessage },
      mxPeriods,
      mxPeriodsDays,
      onDateChange,
    } = this.props;
    const { searchVisible, searchValue } = this.state;
    return (
      <div className={styles.listFilterRow}>
        <SearchInput
          placeholder={formatMessage({ id: 'form.placeholder.searchMX' })}
          value={searchValue}
          visible={searchVisible}
          onToggle={() => this.showSearchInput()}
          onChange={(value) => this.setSearchValue(value)}
          data-test="searchInput"
        />
        <div className={styles.dateHolder}>
          <PeriodsDropdown
            onDateChange={(period, value) => onDateChange(period, value)}
            daysValue={mxPeriodsDays}
            mxPeriods={mxPeriods}
            data-test="periodsDropdown"
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(ListFilter);
