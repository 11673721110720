import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Loading from '../../TFLoading/index';
import TFButton from '../../TFButton/TFButton';
import FlexWrapper from './FlexWrapper';

interface ButtonSectionProps {
  handleCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const StyledSubmitButton = styled.input`
  background-color: ${({ theme }): string => theme.colours.brandAccentBlue};
  color: ${({ theme }): string => theme.colours.white};
  height: 40px;
  width: 140px;
  border-radius: 20px;
  padding: 0 40px;
  border: none;
  outline: none;
  cursor: ${({ disabled }): string => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }): string => (disabled ? '0.3' : '1')};
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 142px;
  border-radius: 20px;
  overflow: hidden;
`;

const ButtonSection: React.FC<ButtonSectionProps> = ({ handleCancel, loading, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <FlexWrapper justifyContent="center" marginTop={40} marginBottom={20} padding="0 0 20px 0">
      <TFButton clear marginRight="10" handleClick={handleCancel}>
        {formatMessage({ id: 'text.cancel' })}
      </TFButton>
      <InputWrapper>
        <Loading loading={loading} contain width={40} height={40} />
        <StyledSubmitButton type="submit" value="Complete" disabled={disabled} />
      </InputWrapper>
    </FlexWrapper>
  );
};

export default ButtonSection;
