/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import {
  addAircraftTotals,
  deleteTripEntry,
  deleteTripUpdate,
  getTrip,
  queryAllTrips,
  queryTrips,
  updateTripEntry,
  updateTripDetails,
  updateTripNumber,
  updateTripUpdate,
} from '../../services/api';
import { intl } from '../../providers/IntlProvider';
import { TripsActionTypes } from '.';

export const getAircraftTripEntries = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await queryTrips(payload); // trip_entries
  dispatch({
    type: TripsActionTypes.SAVE_ALL,
    payload: response,
  });
};

export const getTripEntries = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await queryAllTrips(payload); // trip_entries
  dispatch({
    type: TripsActionTypes.SAVE_ALL,
    payload: response,
  });
};

export const getTripDetails = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await getTrip(payload); // trip_entries
  dispatch({
    type: TripsActionTypes.SAVE,
    payload: response,
  });
};

export const storeEphemeralTrip = ({ payload }) => async (dispatch): Promise<any> => {
  dispatch({ type: TripsActionTypes.SAVE_EPHEMERAL_ENTRY, payload });
};

export const setTripsTableSource = ({ payload }) => async (dispatch): Promise<any> => {
  dispatch({ type: TripsActionTypes.SAVE_SOURCE, payload });
};

export const addUpdate = ({ payload }) => async (dispatch): Promise<any> => {
  // @TODO determine how these influence the tripsMap and implement reducers for them.
  const response = await addAircraftTotals(payload);
  dispatch({
    type: TripsActionTypes.SAVE,
    payload: response.body,
  });
};

export const updateEntry = ({ payload }) => async (dispatch): Promise<any> => {
  // @TODO determine how these influence the tripsMap and implement reducers for them.
  const response = await updateTripEntry(payload);
  dispatch({
    type: TripsActionTypes.SAVE,
    payload: response.body,
  });
};

export const amendTripUpdate = (payload) => async (dispatch): Promise<any> => {
  const response = await updateTripUpdate(payload);
  dispatch({
    type: TripsActionTypes.SAVE,
    payload: response.body,
  });
};

export const updateTrip = ({ payload }) => async (dispatch): Promise<any> => {
  // @TODO determine how these influence the tripsMap and implement reducers for them.
  let response;
  if (payload.srp_number) {
    response = await updateTripNumber(payload);
  } else {
    response = await updateTripDetails(payload);
  }
  dispatch({
    type: TripsActionTypes.SAVE,
    payload: response.body,
  });
};

export const deleteEntry = ({ payload }, callBack) => async (dispatch): Promise<any> => {
  // @TODO determine how these influence the tripsMap and implement reducers for them.
  const response = await deleteTripEntry(payload);

  if (response && response.statusCode > 199 && response.statusCode < 400) {
    if (callBack) {
      callBack();
    }
    dispatch({
      type: TripsActionTypes.DELETE_TRIP,
      payload,
    });
    message.success(intl.formatMessage({ id: 'message.tripRemoved' }));
  } else {
    message.error(intl.formatMessage({ id: 'message.tripRemovalFailed' }));
  }
};

export const deleteUpdate = ({ payload }, callBack) => async (dispatch): Promise<any> => {
  // @TODO determine how these influence the tripsMap and implement reducers for them.
  const response = await deleteTripUpdate(payload);
  if (response && response.statusCode > 199 && response.statusCode < 400) {
    if (callBack) {
      callBack();
    }
    dispatch({
      type: TripsActionTypes.DELETE_TRIP,
      payload,
    });

    message.success(intl.formatMessage({ id: 'message.tripUpdateRemoved' }));
  } else {
    message.error(intl.formatMessage({ id: 'message.tripUpdateRemovalFailed' }));
  }
};
