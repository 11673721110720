import React from 'react';
import styled, { css } from 'styled-components';

interface RadioInputButtonProps {
  text: string;
  active: boolean;
  marginRight?: string;
  onClick: () => void;
}

const activeButton = css`
  border: solid 1px rgba(24, 144, 255, 0.3);
  background-color: rgba(24, 144, 255, 0.1);
  color: #3f98ff;
`;

const defaultButton = css`
  border: solid 1px rgba(36, 45, 65, 0.1);
  background-color: rgba(36, 45, 65, 0.02);
  color: rgba(36, 46, 65, 0.7);
`;

const StyledButton = styled.button`
  width: 100px;
  height: 32px;
  border-radius: 2px;
  line-height: normal;
  cursor: pointer;
  ${({ active }): any => (active ? activeButton : defaultButton)}
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}` : '0')};
`;

const RadioInputButton: React.FC<RadioInputButtonProps> = ({ text, active, marginRight, onClick }) => {
  return (
    <StyledButton type="button" active={active} marginRight={marginRight} onClick={onClick}>
      {text}
    </StyledButton>
  );
};

export default RadioInputButton;
