import { Card, Steps } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import ExpandedContent from '../../components/TopNavWrapper/ExpandedContent';
import styles from './style.module.less';

const { Step } = Steps;

const AddDefectPageWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const currentStep = useMemo(() => {
    const pathList = pathname.split('/');
    switch (pathList[pathList.length - 1]) {
      case 'flight':
        return 0;
      case 'details':
        return 1;
      default:
        return 0;
    }
  }, [pathname]);

  return (
    <>
      <ExpandedContent
        displayTitle={formatMessage({ id: 'title.addNewDefect' })}
        content={formatMessage({ id: 'tooltip.addNewDefect' })}
      />
      <Card bordered={false}>
        <>
          <Steps size="small" current={currentStep} className={styles.steps}>
            <Step title={formatMessage({ id: 'title.aircraftAndFlight' })} />
            <Step title={formatMessage({ id: 'title.details' })} />
          </Steps>
          {children}
        </>
      </Card>
    </>
  );
};

AddDefectPageWrapper.propTypes = {
  children: PropTypes.array.isRequired,
};

export default AddDefectPageWrapper;
