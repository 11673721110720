import React from 'react';
import { Card, Descriptions, Row, Col, Button } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import servers from '../../utils/servers';
import globalStyles from '../../utils/globalStyles.module.less';
import styles from './ResolutionCard.module.less';

const ResolutionCard = ({ hideEdit, item, editResolution, type, intl: { formatMessage } }) => {
  const resolutionItem = { ...item };
  let replacedPartsCount = 0;

  Object.keys(resolutionItem).forEach((key) => {
    if (key.indexOf('_') > -1) {
      const newKey = key.slice(0, key.lastIndexOf('_'));
      resolutionItem[newKey] = item[key];
    }
    if (key.indexOf('replaced_part_part_number_off') > -1) {
      replacedPartsCount += 1;
    }
  });

  const partsArray = [];
  for (let i = 0; i < replacedPartsCount; i += 1) {
    if (resolutionItem[`replaced_part_part_number_off_${i}`]) {
      partsArray.push({
        part_number_off: resolutionItem[`replaced_part_part_number_off_${i}`],
        part_number_on: resolutionItem[`replaced_part_part_number_on_${i}`],
        serial_off: resolutionItem[`replaced_part_serial_off_${i}`],
        serial_on: resolutionItem[`replaced_part_serial_on_${i}`],
        batch_lot_number: resolutionItem[`replaced_part_batch_lot_number_${i}`],
      });
    }
  }

  return (
    <Card className={globalStyles.itemCard} data-testid="resolutionCardDefect">
      {type && type !== 'SCH' && type !== 'LLP' && type !== 'OOP' && (
        <>
          <div className={`${globalStyles.cardTitle} ${styles.resolutionTitle}`}>
            <div data-test="cardTitle">{formatMessage({ id: 'title.resolutionDetails' })}</div>
            {!hideEdit && (
              <Button type="link" data-test="cardEdit" data-testid="cardEdit" onClick={() => editResolution()}>
                {formatMessage({ id: 'title.editResolution' })}
              </Button>
            )}
          </div>
          <Descriptions
            column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 2 }}
            layout="vertical"
            data-test="cardDescriptions"
          >
            <Descriptions.Item span={4} label={formatMessage({ id: 'title.resolutionType' })}>
              <span className={styles.textCapitalize}>{resolutionItem.resolution_type || '-'}</span>
            </Descriptions.Item>
            <Descriptions.Item span={8} label={formatMessage({ id: 'title.details' })}>
              {resolutionItem.details || '-'}
            </Descriptions.Item>
            <Descriptions.Item span={8} label={formatMessage({ id: 'title.limitations' })}>
              {resolutionItem.limitations_details || '-'}
            </Descriptions.Item>
          </Descriptions>
          {partsArray.length > 0 && resolutionItem.resolution_type === 'replaced' && (
            <Row gutter={16} className={styles.resolutionParts}>
              {partsArray.map((part) => {
                return (
                  <Col md={24} key={`part_${part.part_number_on}`}>
                    <Descriptions
                      column={{ xxl: 6, xl: 6, lg: 4, md: 3, sm: 2, xs: 2 }}
                      layout="vertical"
                      className={styles.partsDescriptions}
                      data-test="cardDescriptionsParts"
                    >
                      <Descriptions.Item label={formatMessage({ id: 'form.labels.partNumberOff' })}>
                        {part.part_number_off || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label={formatMessage({ id: 'form.labels.serialOff' })}>
                        {part.serial_off || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label={formatMessage({ id: 'form.labels.partNumberOn' })}>
                        {part.part_number_on || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label={formatMessage({ id: 'form.labels.serialOn' })}>
                        {part.serial_on || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label={formatMessage({ id: 'form.labels.batchLotNumber' })}>
                        {part.batch_lot_number || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item className={styles.emptyDescItem} />
                    </Descriptions>
                  </Col>
                );
              })}
            </Row>
          )}
        </>
      )}
      {resolutionItem.status && resolutionItem.status === 'resolved' && resolutionItem.release && (
        <>
          <div className={`${globalStyles.cardTitle} ${styles.resolutionTitle} ${styles.resolutionTitleResolved}`}>
            <div data-test="cardTitleSigned">{formatMessage({ id: 'title.resolvedBy' })}</div>
          </div>
          <Descriptions
            column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }}
            layout="vertical"
            data-test="cardDescriptionsResolved"
            className={styles.resolvedDescriptions}
          >
            <Descriptions.Item span={6} label={formatMessage({ id: 'text.engineer' })}>
              {resolutionItem.release.first_name && resolutionItem.release.last_name
                ? `${resolutionItem.release.first_name} ${resolutionItem.release.last_name}`
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item span={6} label={formatMessage({ id: 'form.labels.reporter' })}>
              {resolutionItem.release.reporter_first_name &&
                resolutionItem.release.reporter_last_name &&
                `${resolutionItem.release.reporter_first_name} ${resolutionItem.release.reporter_last_name}`}
            </Descriptions.Item>
            <Descriptions.Item span={6} label={formatMessage({ id: 'title.licenceNumber' })}>
              {resolutionItem.release.licence_number || '-'}
            </Descriptions.Item>
            <Descriptions.Item span={6} label={formatMessage({ id: 'form.labels.part145Organisation' })}>
              {resolutionItem.release.company_name || '-'}
            </Descriptions.Item>
            <Descriptions.Item span={6} label={formatMessage({ id: 'form.labels.part145Approval' })}>
              {resolutionItem.release.approval_number || '-'}
            </Descriptions.Item>
            <Descriptions.Item span={24} label={formatMessage({ id: 'form.labels.signature' })}>
              {resolutionItem.release.signature ? (
                <img
                  src={`data:image/png;base64,${resolutionItem.release.signature}`}
                  alt="signature"
                  className={styles.resolutionSignatureImg}
                  data-test="signatureImg"
                />
              ) : (
                '-'
              )}
            </Descriptions.Item>
            <Descriptions.Item span={24} label={formatMessage({ id: 'title.attachments' })}>
              <Row gutter={8} span={24}>
                {resolutionItem.release.attachments && resolutionItem.release.attachments.length > 0 ? (
                  resolutionItem.release.attachments.map((attachment) => {
                    return (
                      <Col lg={4} md={4} sm={12} className={styles.attachmentCol} key={`attachment_${attachment.url}`}>
                        <a
                          href={`${servers.api}/${attachment.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          data-test="attachmentLink"
                        >
                          <div
                            className={styles.attachmentView}
                            style={{ backgroundImage: `url(${servers.api}/${attachment.thumbnail_url})` }}
                            data-test="attachmentImage"
                          />
                        </a>
                      </Col>
                    );
                  })
                ) : (
                  <Col md={24}>-</Col>
                )}
              </Row>
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Card>
  );
};

ResolutionCard.defaultProps = {
  hideEdit: false,
  item: null,
  editResolution: null,
  type: '',
};

ResolutionCard.propTypes = {
  item: PropTypes.object,
  editResolution: PropTypes.func,
  hideEdit: PropTypes.bool,
  type: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ResolutionCard);
