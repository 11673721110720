import React, { useState } from 'react';
import moment from 'moment';
import greenTick from '../../../assets/complete-icon.svg';
import shareIcon from '../../../assets/link-share.svg';
import StatusBadge from '../../Status/StatusBadge';
import styles from '../workpackDrawer.module.less';
import TFPopover from '../../TFPopover/TFPopover';

const WorkpackInfo = ({ dateCreated, status, dateCompleted, publicLink }): JSX.Element => {
  const [popover, setPopover] = useState(false);

  const handlePopupClick = (): void => {
    setPopover(true);
    const url = publicLink;
    const el = document.createElement('textarea');
    el.value = url;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handlePopoverClose = (): void => {
    setPopover(false);
  };

  return (
    <>
      <div className={styles.workpackInfo}>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>CRS ACCEPTANCE</span>
          <span className={styles.statusInfo}>
            <StatusBadge status={status} /> {status}
          </span>
        </div>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>ENGINEERING WORK</span>
          <span className={styles.statusInfo}>
            <StatusBadge status={status === 'pending' ? 'completed' : status} />{' '}
            {status === 'pending' ? 'completed' : status}
          </span>
        </div>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>DATE CREATED</span>
          <span>{moment(dateCreated, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>
        </div>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>DATE COMPLETED</span>
          <span>{(dateCompleted && dateCompleted.replace(/T.*/gm, '')) || '-'}</span>
        </div>
        <div className={`${styles.rowItem} ${styles.publicLink}`}>
          <button type="button" onClick={handlePopupClick} id="publicLink" className={styles.publicLinkWrapper}>
            <img src={shareIcon} alt="" /> Public Share Link
          </button>
        </div>
      </div>
      {popover ? (
        <TFPopover right="36px" top="143px" onClick={handlePopoverClose}>
          <div className={styles.popoverContainer}>
            <div className={styles.imageContainer}>
              <img src={greenTick} alt="Green Tick" />
            </div>
            <div className={styles.clipboardText}>Copied to clipboard</div>
            <div className={styles.linkText}>{publicLink}</div>
            <div className={styles.popoverText}>
              This link (valid for 3 days) will allow third party to view and input a resolution to the defect, without
              requiring to log in.
            </div>
          </div>
        </TFPopover>
      ) : null}
    </>
  );
};

export default WorkpackInfo;
