import { BackTop, Icon, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import logo from '../../assets/back-arrow.svg';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import TripDescriptionForm from '../../components/TripDescriptionForm';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { getTripDetails, storeEphemeralTrip } from '../../models/trips/actions';
import awaitForPropChange from './awaitForPropChange';
import styles from './style.module.less';

const LoadingSpinner = () => (
  <Spin style={{ width: '100%', margin: '40px 0' }} indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />} />
);

class EditFlight extends Component {
  static propTypes = {
    // IDs
    aircraftId: PropTypes.string.isRequired,
    tripId: PropTypes.string.isRequired,
    // Objs
    match: PropTypes.shape({
      path: PropTypes.string,
      params: PropTypes.object,
    }).isRequired,
    matchedAircraft: PropTypes.object.isRequired,
    matchedTrip: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    // Funcs
    refetchAircraft: PropTypes.func.isRequired,
    refetchTrip: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      fetchingTrip: false,
    };
  }

  componentDidMount() {
    const { matchedAircraft, aircraftId, refetchAircraft } = this.props;
    this.fetchTripOrRedirect();
    if (matchedAircraft.id !== aircraftId) {
      refetchAircraft(aircraftId);
    }
  }

  componentDidUpdate() {
    this.fetchTripOrRedirect();
  }

  getBaseRoute = () => (this.props.aircraftId ? `/aircraft/${this.props.aircraftId}/trips` : '/operations/flights');

  fetchTripOrRedirect = () => {
    const { matchedTrip, match, history } = this.props;
    if (
      /\/trip$/.test(match.path) &&
      Array.isArray(matchedTrip.flights) &&
      matchedTrip.flights[0] &&
      matchedTrip.flights[0].id &&
      matchedTrip.flights[0].id.length > 0
    ) {
      history.push(`${this.getBaseRoute()}/${matchedTrip.flights[0].id}/edit`);
    } else {
      this.fetchTrip();
    }
  };

  fetchTrip = async () => {
    if (this.state.fetchingTrip) {
      return;
    }

    await new Promise((resolve) => {
      this.setState({ fetchingTrip: true }, resolve);
    });
    await this.props.refetchTrip(this.props.tripId);

    await awaitForPropChange(() => this.props.tripId === this.props.matchedTrip.id);

    await new Promise((resolve) => {
      this.setState({ fetchingTrip: false }, resolve);
    });
  };

  render() {
    const {
      matchedTrip,
      matchedAircraft,
      aircraftId,
      intl: { formatMessage },
    } = this.props;
    const { fetchingTrip } = this.state;
    const loading = !matchedTrip.id || fetchingTrip;

    // Latest trip data is the matchedTrip overridden by anything held in the trip state.
    const latestTripData = {
      ...matchedTrip,
      date: moment.utc(matchedTrip.date),
    };

    return (
      <>
        <BackTop />
        {!loading ? (
          <PageHeaderWrapper
            logo={
              <Link to={aircraftId ? `/aircraft/${aircraftId}/trips` : '/operations/flights'}>
                <img className={styles.backIcon} alt="back arrow" src={logo} />
              </Link>
            }
            title={`${formatMessage({ id: 'title.edittingFlightOnTrip' })} ${latestTripData.number ||
              formatMessage({ id: 'text.newCaps' })}`}
            content={<TripDescriptionForm aircraft={matchedAircraft} trip={latestTripData} />}
          >
            <LoadingSpinner />
          </PageHeaderWrapper>
        ) : (
          <LoadingSpinner />
        )}
      </>
    );
  }
}

const editTripFlightWithRedux = connect(
  ({ aircraft, trips }, { match: { params } }) => {
    const tripId = params.trip || '';
    const matchedTrip = tripId === trips.ephemeralTrip.id ? trips.ephemeralTrip : trips.tripsMap.get(tripId) || {};

    // Calculate a suitable aircraft id
    const aircraftId =
      // If we have one from the URL params, use that
      params.id ||
      // Lastly try to grab one from the associated trip object
      (matchedTrip && matchedTrip.aircraft_id) ||
      // Fallback to empty string
      '';
    const matchedAircraft = aircraft.aircraftMap.get(aircraftId) || {};

    return {
      // Ids
      aircraftId,
      tripId,
      // Objects
      matchedAircraft,
      matchedTrip,
    };
  },
  (dispatch) => ({
    refetchAircraft: async (payload) => dispatch(getSingleAircraft({ payload })),
    refetchTrip: async (payload) => dispatch(getTripDetails({ payload })),
    storeEphemeralTrip: async (payload) => dispatch(storeEphemeralTrip({ payload })),
  }),
)(EditFlight);

export default withRouter(injectIntl(editTripFlightWithRedux));
