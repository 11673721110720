import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import iconPackage from './assets/icon-package.svg';
import styles from './SmallTag.module.less';

const SmallTag = ({ type, isPackage, packageLength }) => {
  return (
    <Tag
      className={`${styles.itemTitleTag} ${styles.itemTitleTag}${type} ${
        isPackage ? styles.itemTitleTagPackage : undefined
      }`}
      data-test="itemTag"
    >
      {isPackage ? (
        <>
          <img src={iconPackage} alt="iconPackage" className={styles.iconPkg} data-test="iconPackage" />{' '}
          {packageLength || ''}
        </>
      ) : (
        type
      )}
    </Tag>
  );
};

SmallTag.propTypes = {
  type: PropTypes.string.isRequired,
  isPackage: PropTypes.bool,
  packageLength: PropTypes.number,
};

SmallTag.defaultProps = {
  isPackage: false,
  packageLength: null,
};

export default SmallTag;
