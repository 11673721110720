const initialState: OperationsState = {
  aircraftFlights: [],
  aircraftHours: [],
  airports: [],
  monthly: [],
  routes: [],
  oilConsumption: [],
  now: [],
};

export enum OperationsActionTypes {
  CLEAR_MONTHLY = 'operations/clearMonthly',
  CLEAR_ROUTES = 'operations/clearRoutes',
  UPDATE_MONTHLY_SUMMARY = 'operations/saveMonthlySummary',
  UPDATE_OIL_CONSUMPTION = 'operations/saveOilConsumption',
  UPDATE_ROUTES = 'operations/saveRoutes',
  UPDATE_TOP_AIRCRAFT = 'operations/saveTopAircraft',
  UPDATE_TOP_AIRPORTS = 'operations/saveTopAircraft',
}

export interface ClearMonthlyOperationsActionType {
  type: OperationsActionTypes.CLEAR_MONTHLY;
}

export interface ClearRoutesOperationsActionType {
  type: OperationsActionTypes.CLEAR_ROUTES;
}

export interface UpdateMonthlySummaryOperationsActionType {
  type: OperationsActionTypes.UPDATE_MONTHLY_SUMMARY;
  payload: OperationsReportCoordinate[];
}

export interface UpdateOilConsumptionOperationsActionType {
  type: OperationsActionTypes.UPDATE_OIL_CONSUMPTION;
  payload: OilConsumption[];
}

export interface UpdateRoutesOperationsActionType {
  type: OperationsActionTypes.UPDATE_ROUTES;
  payload: FlightRoute[];
}

export interface UpdateTopAircraftOperationsActionType {
  type: OperationsActionTypes.UPDATE_TOP_AIRCRAFT;
  payload: FlightHours[];
}

export interface UpdateTopAirportsOperationsActionType {
  type: OperationsActionTypes.UPDATE_TOP_AIRPORTS;
  payload: OperationsReportCoordinate[];
}

type OperationsAction =
  | ClearMonthlyOperationsActionType
  | ClearRoutesOperationsActionType
  | UpdateMonthlySummaryOperationsActionType
  | UpdateRoutesOperationsActionType
  | UpdateOilConsumptionOperationsActionType
  | UpdateTopAircraftOperationsActionType
  | UpdateTopAirportsOperationsActionType;

export default function(state = initialState, action: OperationsAction): OperationsState {
  switch (action.type) {
    case OperationsActionTypes.CLEAR_MONTHLY: {
      return {
        ...state,
        monthly: [],
      };
    }
    case OperationsActionTypes.CLEAR_ROUTES: {
      return {
        ...state,
        routes: [],
      };
    }
    case OperationsActionTypes.UPDATE_MONTHLY_SUMMARY:
    case OperationsActionTypes.UPDATE_OIL_CONSUMPTION:
    case OperationsActionTypes.UPDATE_ROUTES:
    case OperationsActionTypes.UPDATE_TOP_AIRCRAFT:
    case OperationsActionTypes.UPDATE_TOP_AIRPORTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export interface OperationsReportCoordinate {
  x: string;
  y: number;
}

export interface FlightHours {
  month: OperationsReportCoordinate[];
  year: OperationsReportCoordinate[];
  date: string;
}

export interface OilConsumption {
  date: string;
  engine_oil_consumption_threshold: number;
  apu_oil_consumption_threshold: number;
  data: Array<any>;
}

export interface Location {
  latitude: string;
  longitude: string;
  ident: string;
  name: string;
}

export interface FlightRoute {
  departure: Location;
  arrival: Location;
  startLat: number;
  startLng: number;
  endLat: number;
  endLong: number;
}

export interface OperationsState {
  aircraftFlights: OperationsReportCoordinate[];
  aircraftHours: OperationsReportCoordinate[];
  airports: OperationsReportCoordinate[];
  monthly: OperationsReportCoordinate[];
  oilConsumption: OilConsumption[];
  routes: FlightRoute[];
  now: object[];
}
