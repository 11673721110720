/* eslint-disable react/jsx-props-no-spreading */
import { Layout } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { pathToRegexp } from 'path-to-regexp';
import React from 'react';
import { ContainerQuery } from 'react-container-query';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { SideNav } from '@arcflight/tf-component-library';
import disableAutoCorrect from '../utils/disableAutoCorrect';
import logo from '../assets/logo.svg';
import logoText from '../assets/logoText.svg';
import SiderMenu from '../components/SiderMenu';
import menuDataGenerator from '../utils/menuData';
import { fetch } from '../models/userSettings/actions';
import { changeLayoutCollapsed } from '../models/menu';
import Footer from './Footer';
import MyHeader from './Header';
import styles from './BasicLayout.module.less';

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
};

const mediaQueryList = window.matchMedia('(max-width: 767px)');

class BasicLayout extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    collapsed: PropTypes.bool.isRequired,
    menu: PropTypes.object,
    fixSiderbar: PropTypes.bool.isRequired,
    fixedHeader: PropTypes.bool.isRequired,
    layout: PropTypes.string.isRequired,
    navTheme: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    sideNavExpanded: PropTypes.bool,
  };

  static defaultProps = {
    menu: undefined,
    sideNavExpanded: undefined,
  };

  state = {
    isMobile: false,
    menuData: menuDataGenerator(
      this.props.intl.formatMessage,
      this.props.userSettings?.details?.dashboard_permissions,
      this.props.userSettings?.details?.people,
    ),
    currentMenuItem: false,
  };

  constructor(props) {
    super(props);
    this.getPageTitle = memoizeOne(this.getPageTitle);
    this.matchParamsPath = memoizeOne(this.matchParamsPath, isEqual);
  }

  componentDidMount() {
    const { dispatch, userSettings, history } = this.props;
    const { isMobile, menuData, currentMenuItem } = this.state;

    if (!userSettings || !userSettings.details) {
      dispatch(fetch());
      this.setMenuData();
    }

    this.getCurrentMenuItem(history.location.pathname, menuData, currentMenuItem);
    // Dispatching resize event to prevent DASH-1096 bug
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    window.addEventListener(
      'orientationchange',
      () => {
        if (isMobile) this.handleMenuCollapse(true);
      },
      false,
    );
    disableAutoCorrect();
  }

  componentDidUpdate(prevProps, prevState) {
    // After changing to phone mode,
    // if collapsed is true, you need to click twice to display
    const { isMobile, menuData, currentMenuItem } = this.state;
    const { collapsed, menu, userSettings, history } = this.props;
    if (isMobile && !prevState.isMobile && !collapsed) {
      this.handleMenuCollapse(false);
    }
    if (
      menu !== prevProps.menu ||
      !_.isEqual(prevProps.userSettings && prevProps.userSettings.details, userSettings.details)
    ) {
      this.setMenuData();
    }

    if (!currentMenuItem) {
      this.getCurrentMenuItem(history.location.pathname, menuData, currentMenuItem);
    }
    disableAutoCorrect();
  }

  getCurrentMenuItem(location, menuData, currentMenuItem) {
    let menuSubItem;

    const topLevelMenuItem = menuData.filter((obj) => {
      return obj.value === location;
    });

    if (topLevelMenuItem.length < 1) {
      // subitem
      menuData.forEach((menuItem) => {
        if (menuItem.items) {
          const match = menuItem.items.filter((subItem) => {
            return subItem.value === location;
          });
          if (match.length > 0) {
            menuSubItem = match;
          }
        }
      });
      if (!currentMenuItem) {
        this.setState({
          currentMenuItem: menuSubItem ? menuSubItem[0] : false,
        });
      }
    } else {
      this.setState({
        currentMenuItem: topLevelMenuItem[0],
      });
    }
  }

  setMenuData() {
    const {
      userSettings,
      intl: { formatMessage },
    } = this.props;
    this.setState({
      menuData: menuDataGenerator(
        formatMessage,
        userSettings?.details?.dashboard_permissions,
        userSettings?.details?.people,
      ),
    });
  }

  updateMediaScreenSize = () => {
    this.setState({ isMobile: mediaQueryList.matches });
  };

  getPageTitle = (pathname) => {
    const {
      intl: { messages },
      match: {
        params: { id },
      },
    } = this.props;
    const intlPath = pathname
      .split('/')
      .filter((path) => path !== id && path.length > 0)
      .map((path) => path.charAt(0).toUpperCase() + path.slice(1))
      .join('.');
    const message = messages[`menu.${intlPath}`];
    return message ? `${message} - TrustFlight` : 'TrustFlight';
  };

  getLayoutStyle = () => {
    const { isMobile } = this.state;
    const { fixSiderbar, collapsed, layout } = this.props;
    if (fixSiderbar && layout !== 'topmenu' && !isMobile) {
      return {
        paddingLeft: collapsed ? '80px' : '200px',
      };
    }
    return null;
  };

  getContentStyle = () => {
    const { fixedHeader } = this.props;
    return {
      margin: '24px 24px 0',
      paddingTop: fixedHeader ? 64 : 0,
    };
  };

  matchParamsPath = (pathname) => {
    const pathKey = Object.keys(this.breadcrumbNameMap).find((key) => pathToRegexp(key).test(pathname));
    return this.breadcrumbNameMap[pathKey];
  };

  handleMenuCollapse = (collapsed, fromButton) => {
    if (fromButton !== 'responsive' || window.innerWidth < 1100) {
      const { dispatch } = this.props;
      dispatch(
        changeLayoutCollapsed({
          payload: collapsed,
        }),
      );
    }
  };

  render() {
    const {
      navTheme,
      children,
      location: { pathname },
      history,
      sideNavExpanded,
    } = this.props;
    const { menuData, currentMenuItem } = this.state;

    const isMobile = mediaQueryList.matches;

    return (
      <>
        <DocumentTitle title={this.getPageTitle(pathname)}>
          <ContainerQuery query={query}>
            {(params) => (
              <div className={classNames(params)}>
                <Layout>
                  <Layout
                    style={{
                      ...this.getLayoutStyle(),
                    }}
                    className={styles.contentLayout}
                  >
                    <div id="sideMenu">
                      <SideNav
                        expanded={sideNavExpanded}
                        id="sideMenu"
                        items={menuData}
                        onSelect={(item) => {
                          if (item.value.includes('https://')) {
                            window.location = item.value;
                          } else {
                            history.push(item.value);
                          }
                        }}
                        selected={currentMenuItem}
                        data-testid="SideNav"
                      >
                        <div id="sideMenuChildren">{children}</div>
                        <Footer />
                      </SideNav>
                    </div>
                  </Layout>
                </Layout>
              </div>
            )}
          </ContainerQuery>
        </DocumentTitle>
      </>
    );
  }
}

export default injectIntl(
  withRouter(
    connect(({ menu, userSettings }) => ({
      collapsed: menu.collapsed,
      userSettings,
      ...userSettings.ui,
    }))(BasicLayout),
  ),
);
