/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, InputNumber, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import defaults from '../../../utils/defaults';
import { formatTime } from '../../../utils/utils';
import globalStyles from '../../../utils/globalStyles.module.less';
import styles from './MXModal.module.less';

const MXModalSCHContents = ({ form: { getFieldDecorator }, intl: { formatMessage }, userSettings, aircraft, item }) => {
  const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;
  const hasAPU = aircraft ? aircraft.apu_installed : false;
  const [unitOfTime, setUnitOfTime] = useState('days');

  const handleUnitOfTimeClick = (input) => {
    setUnitOfTime(input);
  };
  useEffect(() => {
    if (item && item.unit_of_time) {
      setUnitOfTime(item.unit_of_time);
    }
  }, [item]);

  const activeLimitValue = ({ monthsValue, daysValue }) => {
    if (monthsValue && Number(monthsValue) && Number(monthsValue) > 0) {
      return monthsValue;
    }

    if (daysValue && Number(daysValue) && Number(daysValue) > 0) {
      return daysValue;
    }

    return null;
  };

  return (
    <>
      <Form.Item
        label={formatMessage({ id: 'form.labels.taskDescription' })}
        className={globalStyles.formItem}
        data-test="formItemName"
      >
        {getFieldDecorator('name', {
          rules: [{ required: true, message: formatMessage({ id: 'form.message.addItemName' }) }],
          initialValue: item ? item.name : null,
        })(
          <Input
            className={globalStyles.formInput}
            placeholder={formatMessage({ id: 'form.placeholder.itemName' })}
            data-test="formInputName"
          />,
        )}
      </Form.Item>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleFH">
            <span>{formatMessage({ id: 'form.title.addFlightHours' })}</span>
          </div>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.threshold' })}
            className={globalStyles.formItem}
            data-test="formItemFHThreshold"
          >
            {getFieldDecorator('flight_seconds_threshold', {
              rules: [{ required: false }],
              initialValue:
                item && item.flight_seconds_threshold ? Math.round(item.flight_seconds_threshold / 3600) : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputFHThreshold"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.interval' })}
            className={globalStyles.formItem}
            data-test="formItemFHInterval"
          >
            {getFieldDecorator('flight_seconds_repeat', {
              rules: [{ required: false }],
              initialValue: item && item.flight_seconds_repeat ? Math.round(item.flight_seconds_repeat / 3600) : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputFHInterval"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemFHTolerance"
          >
            {getFieldDecorator('flight_seconds_tolerance', {
              rules: [{ required: false }],
              initialValue:
                item && item.flight_seconds_tolerance ? Math.round(item.flight_seconds_tolerance / 3600) : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputFHTolerance"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.nextDue' })}
            className={globalStyles.formItem}
            data-test="formItemFHNextDue"
          >
            {getFieldDecorator('flight_seconds_due', {
              rules: [{ required: false }],
              initialValue: item && item.flight_seconds_due ? Math.round(item.flight_seconds_due / 3600) : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputFHNextDue"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleCycles">
            <span>{formatMessage({ id: 'form.title.addCycles' })}</span>
          </div>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.threshold' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesThreshold"
          >
            {getFieldDecorator('cycles_threshold', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_threshold : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputCyclesThreshold"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.interval' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesInterval"
          >
            {getFieldDecorator('cycles_repeat', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_repeat : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputCyclesInterval"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesTolerance"
          >
            {getFieldDecorator('cycles_tolerance', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_tolerance : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputCyclesTolerance"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.nextDue' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesNextDue"
          >
            {getFieldDecorator('cycles_due', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_due : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputCyclesNextDue"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleMonths">
            <span>{formatMessage({ id: 'form.title.addMonths' })}</span>
          </div>
        </Col>
        <Col md={24}>
          <Form.Item>
            {getFieldDecorator('unit_of_time', {
              initialValue: unitOfTime,
              rules: [{ required: false }],
            })(
              <>
                <div className={styles.dateLabel}>Limit Type</div>
                <RadioInputButton
                  text="Days"
                  active={unitOfTime === 'days'}
                  onClick={() => handleUnitOfTimeClick('days')}
                  marginRight="8px"
                />
                <RadioInputButton
                  text="Months"
                  active={unitOfTime === 'months'}
                  onClick={() => handleUnitOfTimeClick('months')}
                />
              </>,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.threshold' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsThreshold"
          >
            {getFieldDecorator('months_threshold', {
              rules: [{ required: false }],
              initialValue: activeLimitValue({ monthsValue: item?.months_threshold, daysValue: item?.days_threshold }),
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputMonthsThreshold"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.interval' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsInterval"
          >
            {getFieldDecorator('months_repeat', {
              rules: [{ required: false }],
              initialValue: activeLimitValue({ monthsValue: item?.months_repeat, daysValue: item?.days_repeat }),
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputMonthsInterval"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsTolerance"
          >
            {getFieldDecorator('months_tolerance', {
              rules: [{ required: false }],
              initialValue: activeLimitValue({ monthsValue: item?.months_tolerance, daysValue: item?.days_tolerance }),
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputMonthsTolerance"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.nextDue' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsNextDue"
          >
            {getFieldDecorator('date_due', {
              rules: [{ required: false }],
              initialValue: item && item.date_due ? moment(item.date_due) : undefined,
            })(
              <DatePicker
                className={globalStyles.formDatePicker}
                format={dateFormat}
                data-test="formInputMonthsNextDue"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      {hasAPU && (
        <Row gutter={16}>
          <Col md={24}>
            <div className={styles.timesTitle} data-test="sectionTitleAPU">
              <span>{formatMessage({ id: 'form.title.addAPUHours' })}</span>
            </div>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.threshold' })}
              className={globalStyles.formItem}
              data-test="formItemAPUThreshold"
            >
              {getFieldDecorator('apu_seconds_threshold', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_threshold ? formatTime(item.apu_seconds_threshold) : null,
              })(
                <InputNumber
                  min={1}
                  className={globalStyles.formInputNumber}
                  placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                  data-test="formInputAPUThreshold"
                />,
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.interval' })}
              className={globalStyles.formItem}
              data-test="formItemAPUInterval"
            >
              {getFieldDecorator('apu_seconds_repeat', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_repeat ? formatTime(item.apu_seconds_repeat) : null,
              })(
                <InputNumber
                  min={1}
                  className={globalStyles.formInputNumber}
                  placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                  data-test="formInputAPUInterval"
                />,
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.tolerance' })}
              className={globalStyles.formItem}
              data-test="formItemAPUTolerance"
            >
              {getFieldDecorator('apu_seconds_tolerance', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_tolerance ? Math.round(item.apu_seconds_tolerance / 3600) : null,
              })(
                <InputNumber
                  min={1}
                  className={globalStyles.formInputNumber}
                  placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                  data-test="formInputAPUTolerance"
                />,
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.nextDue' })}
              className={globalStyles.formItem}
              data-test="formItemAPUNextDue"
            >
              {getFieldDecorator('apu_seconds_due', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_due ? formatTime(item.apu_seconds_due) : null,
              })(
                <InputNumber
                  min={1}
                  className={globalStyles.formInputNumber}
                  placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                  data-test="formInputAPUNextDue"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

MXModalSCHContents.propTypes = {
  form: PropTypes.object.isRequired,
  aircraft: PropTypes.object.isRequired,
  item: PropTypes.object,
  userSettings: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

MXModalSCHContents.defaultProps = {
  item: null,
};

export default injectIntl(MXModalSCHContents);
