import React, { PureComponent } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import TopNavWrapper from '../components/TopNavWrapper';
import { DashboardState } from '../models';
import BasicLayout from './BasicLayout';
import styles from './ContentLayout.module.less';

class ContentLayout extends PureComponent<{
  fixedHeader: boolean;
  title?: string;
  icon?: string;
  history?: any;
  children: any;
  userSettings: any;
}> {
  getContentStyle = (): ContentStyle => {
    const { fixedHeader } = this.props;
    return {
      paddingTop: fixedHeader ? 64 : 0,
    };
  };

  render(): JSX.Element {
    const { children, title, icon, history } = this.props;
    return (
      <BasicLayout>
        <>
          {title && <TopNavWrapper titleIcon={icon} history={history} title={title} />}
          <Layout style={this.getContentStyle()} className={styles.contentLayout}>
            {children}
          </Layout>
        </>
      </BasicLayout>
    );
  }
}

export default connect(({ userSettings }: DashboardState) => ({
  fixedHeader: userSettings.ui.fixedHeader,
  userSettings,
}))(ContentLayout);

export interface ContentStyle {
  paddingTop: number;
}
