import servers from './servers';

/**
 * Transform a file for use within antd's.
 * @param {{}} file - An array of files in the Core API's format.
 * @returns {{}} A correctly Antd formatted file object.
 */
const transformAttachment = (file = {}) =>
  file.uid
    ? file
    : {
        ...file,
        uid: file.id,
        name: file.file_name,
        status: 'done',
        url: `${servers.api}/${file.url}`,
        thumbUrl: `${servers.api}/${file.thumbnail_url}`,
      };

export default transformAttachment;
