import React from 'react';
import { Row, Col, Carousel } from 'antd';
import PropTypes from 'prop-types';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import styles from './AircraftWidgets.module.less';

const AircraftWidgets = ({ aircraft }) => {
  const { apu_hours, flight_hours, cycles, days } = aircraft.widgets;
  const {
    apu_seconds: apu_threshold,
    flight_seconds: hours_threshold,
    days: days_threshold,
    cycles: cycles_threshold,
  } = aircraft.mx_thresholds;
  const widgetArray = [
    {
      title: 'Cycles',
      total: cycles.total,
      nextDue: cycles.next_due !== null ? cycles.next_due : null,
      remaining: cycles.remaining,
      tolerance: cycles.tolerance,
      threshold: cycles_threshold || 30,
      itemId: cycles.item_id,
    },
    {
      title: 'Days',
      nextDue: days.next_due !== null ? days.next_due : null,
      remaining: days.remaining,
      tolerance: days.tolerance,
      threshold: days_threshold || 30,
      itemId: days.item_id,
      isDefect: !!days.is_defect,
    },
    {
      title: 'Hours',
      total: flight_hours.total,
      nextDue: flight_hours.next_due !== null ? flight_hours.next_due : null,
      remaining: flight_hours.remaining,
      tolerance: flight_hours.tolerance,
      threshold: hours_threshold / 3600 || 30,
      itemId: flight_hours.item_id,
    },
  ];
  if (apu_hours.installed) {
    widgetArray.push({
      title: 'APU Hours',
      total: apu_hours.total,
      nextDue: apu_hours.next_due !== null ? apu_hours.next_due : null,
      remaining: apu_hours.remaining,
      tolerance: apu_hours.tolerance,
      threshold: apu_threshold / 3600 || 30,
      itemId: apu_hours.item_id,
    });
  }
  return (
    <>
      <Row className={styles.widgetCardWrapper} data-testid="AircraftWidgets--Widgets">
        {widgetArray.map((widget) => (
          <Col xs={12} sm={12} md={12} lg={12} xl={6} className={styles.progressCircleWrapper} key={widget.title}>
            <ProgressCircle widget={widget} widgetNumber={widgetArray.length} aircraftId={aircraft.id} />
          </Col>
        ))}
      </Row>
      <div className={styles.widgetCarousel}>
        <Carousel>
          <div className={styles.widgetCarouselItem}>
            {widgetArray.slice(0, 2).map((widget) => (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} className={styles.progressCircleWrapper} key={widget.title}>
                <ProgressCircle widget={widget} widgetNumber={widgetArray.length} aircraftId={aircraft.id} />
              </Col>
            ))}
          </div>
          <div className={styles.widgetCarouselItem}>
            {widgetArray.slice(2, 4).map((widget) => (
              <Col xs={12} sm={12} md={12} lg={12} xl={6} className={styles.progressCircleWrapper} key={widget.title}>
                <ProgressCircle widget={widget} widgetNumber={widgetArray.length} aircraftId={aircraft.id} />
              </Col>
            ))}
          </div>
        </Carousel>
      </div>
    </>
  );
};

AircraftWidgets.propTypes = {
  aircraft: PropTypes.object.isRequired,
};

export default AircraftWidgets;
