/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import UploadIcon from '../../assets/icon-file-upload.svg';
import styles from './fileViewer.module.less';

const FileUploader = ({ disableAddition, fileRef, fileUpload }): JSX.Element =>
  !disableAddition && (
    <label htmlFor="file-upload" className={styles.fileInput}>
      <img src={UploadIcon} alt="upload" />
      <input
        id="file-upload"
        ref={fileRef}
        type="file"
        accept=".jpg, .jpeg, .png, .pdf"
        onChange={fileUpload}
        multiple
      />
    </label>
  );

export default FileUploader;
