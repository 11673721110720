/* eslint-disable react/jsx-props-no-spreading */
import { Select, Empty } from 'antd';
import React, { PureComponent, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Loading from '../TFLoading';
import styles from './index.module.less';

const { Option } = Select;

class SelectMELItem extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    items: PropTypes.array,
    forwardedRef: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    items: [],
  };

  render() {
    const {
      intl: { formatMessage },
      items,
      forwardedRef,
      loading,
    } = this.props;
    const options = Array.isArray(items)
      ? items.map((item) => {
          const innerOptions = [];
          const number = `${item.chapter_number < 10 ? `0${item.chapter_number}` : item.chapter_number}-${
            item.section_number
          }-${item.subsection_number}${item.subsubsection ? `-${item.subsubsection}` : ''}`;
          if (item.mel_rectifications) {
            item.mel_rectifications.map((rect) => {
              let category = 'CAT A - AS PER REMARKS';
              if (rect.interval === 'B') {
                category = 'CAT B - 3 DAYS';
              } else if (rect.interval === 'C') {
                category = 'CAT C - 10 DAYS';
              } else if (rect.interval === 'D') {
                category = 'CAT D - 120 DAYS';
              }
              innerOptions.push(
                <Option
                  key={`MelId=${item.id}@RectTitle=${rect.title}@RectId=${rect.id}@`}
                  item={item}
                  label={`${number} ${item.title}`}
                >
                  <div className={styles.melItem}>
                    <div className={styles.labelCol}>
                      <div className={styles.melLabel}>MEL</div>
                      <div className={styles.melCode}>{number}</div>
                    </div>
                    <div>
                      <div className={styles.melCat}>{category}</div>
                      <div className={styles.melTitle}>
                        {item.title}
                        {rect.title ? ` - ${rect.title}` : null}
                      </div>
                    </div>
                  </div>
                </Option>,
              );
              return null;
            });
          }
          return innerOptions;
        })
      : [];

    return (
      <Select
        className={styles.melSelector}
        showSearch
        loading={loading}
        notFoundContent={
          loading ? (
            <div className={styles.spinHolder}>
              <Loading loading contain width={50} height={50} />
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        placeholder={formatMessage({ id: 'form.placeholder.selectMELItem' })}
        optionLabelProp="label"
        filterOption={(input, { props: { item } }) =>
          item.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          `${item.chapter_number}-${item.section_number}-${item.subsection_number}`
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        ref={forwardedRef}
        {...this.props}
      >
        {options}
      </Select>
    );
  }
}

const ComposedComponent = injectIntl(SelectMELItem);

export default forwardRef((props, ref) => <ComposedComponent {...props} forwardedRef={ref} />);
