import React, { useState } from 'react';
import { Button, InputNumber } from 'antd';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './MXModal.module.less';

const ToleranceCounter = ({
  itemTolerance,
  itemVisibleTolerance,
  nextDue,
  remaining,
  unitSingular,
  unitPlural,
  type,
  onChange,
  intl: { formatMessage },
  dateFormat,
}) => {
  let parsedItemVisibleTolerance = itemVisibleTolerance;
  let parsedItemTolerance = itemTolerance;
  let parsedNextDue = nextDue;
  let parsedRemaining = remaining;

  if (type === 'seconds') {
    parsedItemVisibleTolerance = Math.round(parsedItemVisibleTolerance / 3600);
    parsedItemTolerance = Math.round(parsedItemTolerance / 3600);
    parsedNextDue = Math.round(parsedNextDue / 3600);
    parsedRemaining = Math.round(parsedRemaining / 3600);
  }

  const [visibleTolerance, setVisibleTolerance] = useState(parsedItemVisibleTolerance || 0);

  const minusTolerance = () => {
    const newTolerance = visibleTolerance > 0 ? visibleTolerance - 1 : 0;
    setVisibleTolerance(newTolerance);
    onChange(type === 'seconds' ? newTolerance * 3600 : newTolerance);
  };

  const addTolerance = () => {
    const newTolerance = visibleTolerance < parsedItemTolerance ? visibleTolerance + 1 : parsedItemTolerance;
    setVisibleTolerance(newTolerance);
    onChange(type === 'seconds' ? newTolerance * 3600 : newTolerance);
  };

  const onInputChange = (value) => {
    if (value !== null && value >= 0 && value <= parsedItemTolerance) {
      setVisibleTolerance(value);
      onChange(type === 'seconds' ? value * 3600 : value);
    }
  };

  return (
    <>
      {parsedItemTolerance > 0 ? (
        <>
          <div className={styles.counterWrapper}>
            <div className={styles.toleranceValue} data-test="toleranceWrapper">
              <span data-test="toleranceValue">
                <InputNumber
                  value={visibleTolerance || 0}
                  min={0}
                  precision={0}
                  max={parsedItemTolerance}
                  size="small"
                  className={styles.toleranceInput}
                  onChange={(value) => onInputChange(value)}
                />
                /{` ${parsedItemTolerance}`}
              </span>
              {parsedItemTolerance > 1 ? unitPlural : unitSingular}
            </div>
            <Button
              type="default"
              className={styles.counterButton}
              disabled={Number(visibleTolerance) === 0}
              onClick={() => minusTolerance()}
              data-test="minusButton"
            >
              -
            </Button>
            <Button
              type="default"
              className={styles.counterButton}
              disabled={Number(visibleTolerance) === Number(parsedItemTolerance)}
              onClick={() => addTolerance()}
              data-test="plusButton"
            >
              +
            </Button>
          </div>
          <div className={styles.toleranceDescription} data-test="toleranceDescription">
            {parsedNextDue !== null && parsedNextDue !== undefined && (
              <>
                {formatMessage({ id: 'form.labels.nextDue' })}:{' '}
                <span data-test="toleranceNextDue">
                  {type === 'months' &&
                    !!parsedNextDue &&
                    moment(parsedNextDue, dateFormat || 'YYYY-MM-DD')
                      .add('month', visibleTolerance)
                      .format(dateFormat || 'YYYY-MM-DD')}
                  {type === 'days' &&
                    !!parsedNextDue &&
                    moment(parsedNextDue, dateFormat || 'YYYY-MM-DD')
                      .add('day', visibleTolerance)
                      .format(dateFormat || 'YYYY-MM-DD')}
                  {type !== 'months' && type !== 'days' && !!parsedNextDue && (
                    <>
                      {parsedNextDue + visibleTolerance} {parsedNextDue > 1 ? unitPlural : unitSingular}
                    </>
                  )}
                </span>
              </>
            )}
            {parsedRemaining !== null && parsedRemaining !== undefined && (
              <>
                (
                <span data-test="toleranceRemaining">
                  {parsedRemaining >= 0 ? parsedRemaining + visibleTolerance : 0}{' '}
                  {parsedRemaining > 1 ? unitPlural : unitSingular}
                </span>
                {formatMessage({ id: 'text.remaining' })})
              </>
            )}
          </div>
        </>
      ) : (
        <div className={styles.toleranceDescription} data-test="toleranceNotAvailable">
          {formatMessage({ id: 'text.toleranceNotAvailable' })}
        </div>
      )}
    </>
  );
};

ToleranceCounter.defaultProps = {
  itemTolerance: null,
  itemVisibleTolerance: null,
  nextDue: null,
  remaining: null,
  type: null,
};

ToleranceCounter.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  itemTolerance: PropTypes.number,
  itemVisibleTolerance: PropTypes.number,
  nextDue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remaining: PropTypes.number,
  unitSingular: PropTypes.string.isRequired,
  unitPlural: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default injectIntl(ToleranceCounter);
