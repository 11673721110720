import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Portal from '../TFPortal/TFPortal';

interface MessageProps {
  text?: string;
  clearText?: () => void;
}

const MessageWrapper = styled.div`
  top: 50px;
  left: 45%;
  position: absolute;
  min-width: 200px;
  max-width: 350px;
  min-height: 50px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  display: ${({ visible }): string => (visible ? 'flex' : 'none')};
  padding: 20px;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Message: React.FC<MessageProps> = ({ text, clearText }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    if (text) {
      setDisplayText(text);
      setTimeout(() => {
        setDisplayText('');
        clearText();
      }, 5000);
    } else {
      setDisplayText('');
    }
  }, [clearText, text]);

  return (
    <Portal>
      <MessageWrapper visible={displayText}>
        <span>{text}</span>
      </MessageWrapper>
    </Portal>
  );
};

export default Message;
