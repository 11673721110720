import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import DefectForm from '../../components/DefectForm';
import { getMELItems } from '../../services/api';
import { submitDefect } from '../../models/defects/actions';
import styles from './style.module.less';
import AddDefectPageWrapper from './AddDefectPageWrapper';

class Details extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    ephemeralDefect: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    defectData: {},
    submitting: false,
    melLoading: false,
    melItems: [],
  };

  componentDidMount() {
    this.getMelItems();
  }

  componentDidUpdate(prevProps) {
    const { match, history, ephemeralDefect } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      history.push(this.resetRoute());
    }
    if (!ephemeralDefect.aircraft_id) {
      this.onSubmitComplete();
    }
  }

  onMelLoaded() {
    this.setState({ melLoading: false });
  }

  onSubmitComplete() {
    const { history, match } = this.props;
    history.push(match.params.id ? `/aircraft/${match.params.id}/defects/log` : '/defects');
    this.setState({
      submitting: false,
    });
  }

  async getMelItems() {
    const { ephemeralDefect } = this.props;
    this.setState({ melLoading: true }, () => {
      getMELItems({ id: ephemeralDefect.aircraft_id }).then((response) => {
        this.setState({
          melItems: response.data,
          melLoading: false,
        });
      });
    });
  }

  handleSubmit = async () => {
    const { dispatch, ephemeralDefect } = this.props;
    const { defectData } = this.state;

    const melItemRegex = new RegExp(/MelId=(.*?)@/);
    const rectItemRegex = new RegExp(/RectId=(.*?)@/);

    const defectBody = {
      id: ephemeralDefect.aircraft_id,
      defect: {
        flight_id: ephemeralDefect.flight_id,
        details: defectData.details,
        date: moment(ephemeralDefect.date).format('YYYY-MM-DD'),
        deferred: defectData.deferred || false,
        operator_reference: defectData.operator_reference,
        signature: ephemeralDefect.signature,
        mel_item_id: defectData?.mel_item_id?.match(melItemRegex)[1] || null,
        mel_rectification_id: defectData?.mel_item_id?.match(rectItemRegex)[1] || null,
        attachments: defectData.attachments,
        reported_by_id: defectData.reporter,
        grace_period_days: defectData.deferred ? undefined : 0,
      },
    };
    await new Promise((resolve) => {
      this.setState({ submitting: true }, resolve);
    });
    dispatch(
      submitDefect({
        payload: defectBody,
      }),
    );
  };

  resetRoute = () => {
    const aircraftId = this.props.match.params.id;
    return aircraftId ? `/aircraft/${aircraftId}/defects/add` : '/add/defect';
  };

  render() {
    const {
      ephemeralDefect: { aircraft_id: aircraftId },
    } = this.props;
    const { melItems, melLoading, submitting, defectData } = this.state;
    // Redirect if dependent data is missing
    if (aircraftId === undefined) {
      return <Redirect to={this.resetRoute()} />;
    }

    return (
      <AddDefectPageWrapper>
        <DefectForm
          aircraft_id={aircraftId}
          className={styles.stepForm}
          defect={defectData}
          onChange={(newDefectData) => this.setState({ defectData: newDefectData })}
          melItems={melItems}
          melLoading={melLoading}
          submittable
          submitting={submitting}
          onSubmit={this.handleSubmit}
        />
      </AddDefectPageWrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(({ defects }) => ({
    ephemeralDefect: defects.ephemeralDefect,
  })),
)(Details);
