import { BaseModel } from '..';

const initialState: DamageMapsState = {
  damageMapsMap: new Map(),
  lastFetched: 0,
  image: '',
};

export enum DamageMapsActionTypes {
  SAVE = 'damageMap/save',
  SAVE_ALL = 'damageMap/saveAll',
  SAVE_IMAGE = 'damageMap/saveImage',
}

export interface SaveDamageMapsActionTypes {
  type: DamageMapsActionTypes.SAVE;
  payload: DamageMap;
  aircraftId: string;
}

export interface SaveAllDamageMapsActionTypes {
  type: DamageMapsActionTypes.SAVE_ALL;
  payload: DamageMap[];
  aircraftId: string;
}

export interface SaveImageDamageMapsActionTypes {
  type: DamageMapsActionTypes.SAVE_IMAGE;
  payload: '';
  aircraftId: string;
}

type DamageMapsActions = SaveDamageMapsActionTypes | SaveAllDamageMapsActionTypes | SaveImageDamageMapsActionTypes;

export default function(state = initialState, action: DamageMapsActions): DamageMapsState {
  switch (action.type) {
    case DamageMapsActionTypes.SAVE_ALL: {
      const masterMap = new Map(state.damageMapsMap.entries());
      const damageMapsMap = masterMap.has(action.aircraftId) ? masterMap.get(action.aircraftId) : new Map();
      action.payload.forEach((damageMap) => {
        damageMapsMap.set(damageMap.id, {
          ...damageMapsMap.get(damageMap.id),
          ...damageMap,
          lastFetched: Date.now(),
        });
      });
      masterMap.set(action.aircraftId, damageMapsMap);
      return {
        ...state,
        damageMapsMap: masterMap,
        lastFetched: Date.now(),
      };
    }
    case DamageMapsActionTypes.SAVE: {
      const masterMap = new Map(state.damageMapsMap.entries());
      if (action.payload && action.payload.id) {
        const damageMapsMap = masterMap.has(action.aircraftId) ? masterMap.get(action.aircraftId) : new Map();
        const damageMaps = action.payload;
        damageMapsMap.set(damageMaps.id, {
          ...damageMapsMap.get(damageMaps.id),
          ...damageMaps,
          lastFetched: Date.now(),
        });
        masterMap.set(action.aircraftId, damageMapsMap);
      }
      return {
        ...state,
        damageMapsMap: masterMap,
      };
    }
    case DamageMapsActionTypes.SAVE_IMAGE: {
      return {
        ...state,
        image: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface DamageMapsState {
  damageMapsMap: Map<string, Map<string, DamageMap>>;
  lastFetched: number;
  image: '';
}

export interface DamageMap extends BaseModel {
  id: string;
  aircraft_id: string;
  title: string;
  sort_order: number;
  last_updated: number;
  category: string;
  attachment: {
    content_type: string;
    filename: string;
    url: string;
    thumbnail_url: string;
  };
}
