import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Badge, Button, Card, Input, Spin, Tooltip, Icon, Row, Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import DescriptionList from '../../components/DescriptionList';
import SharingLinkModal from '../../components/Modals/SharingLink/SharingLink';
import { getSingleAircraft } from '../../models/aircraft/actions';
import servers from '../../utils/servers';
import { getWorkpackUrl } from '../../services/api';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import logo from '../../assets/back-arrow.svg';
import { getSingleWorkpack } from '../../models/workpacks/actions';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { AircraftResource, AircraftPermission } from '../../models/aircraft/index';
import DefectsTable, { allowedStatuses } from './DefectsTable';
import ScheduledTable from './ScheduledTable';
import OopTable from './OopTable';
import LlpTable from './LlpTable';
import styles from './WorkpackProfile.module.less';

const { Description } = DescriptionList;

class WorkpackProfile extends PureComponent {
  static propTypes = {
    aircraftMap: PropTypes.object.isRequired,
    fetchAircraft: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    getSingleWorkpack: PropTypes.func.isRequired,
    workpacksMap: PropTypes.instanceOf(Map).isRequired,
    userSettings: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      status: {
        draft: {
          text: 'Draft',
          badge: 'default',
        },
        active: {
          text: 'Active',
          badge: 'processing',
        },
        complete: {
          text: 'Complete',
          badge: 'success',
        },
      },
      sharingModalVisible: false,
      workpackUrl: '',
      responseUrl: '',
    };
  }

  async componentDidMount() {
    const workpack = this.getWorkpack();
    const { match } = this.props;
    if (!workpack || Date.now() - workpack.lastFetched > 30000) {
      this.getWorkpack(true);
    }
    try {
      const response = await getWorkpackUrl(match.params.workpack_id);
      this.setState({
        workpackUrl: `${servers.pub === 'localhost:8000' ? 'http://' : 'https://'}${servers.pub + response.url}`,
        responseUrl: response.url,
      });
    } catch (err) {
      this.setState({
        workpackUrl: '',
        responseUrl: '',
      });
    }
  }

  getAircraft = (forceRefetch = false) => {
    if (forceRefetch) {
      this.props.fetchAircraft(this.props.match.params.id);
    }
    return this.props.aircraftMap.get(this.props.match.params.id);
  };

  getWorkpack(forceRefetch = false) {
    const { getSingleWorkpack: dispatchGetSingleWorkpack, workpacksMap, match } = this.props;
    if (forceRefetch) {
      return dispatchGetSingleWorkpack(match.params.workpack_id);
    }
    return workpacksMap.get(match.params.workpack_id);
  }

  render() {
    const {
      match,
      intl: { formatMessage },
      userSettings,
    } = this.props;
    const aircraftId = match.params.id;
    const { status, globalFilter, sharingModalVisible, workpackUrl, responseUrl } = this.state;
    const workpack = this.getWorkpack();

    let displayDefectTable = (
      <>
        <div className={styles.tableTitle}>{formatMessage({ id: 'title.defects' })}</div>
        <div className={styles.hiddenTable}>
          <Spin size="small" />
        </div>
      </>
    );
    if (workpack && workpack.itemCounts.defects > 0) {
      displayDefectTable = (
        <DefectsTable
          dataSource={
            workpack
              ? workpack.workpack_items
                  .map((item) => item.mx_item)
                  .filter((item) => item.type === 'defect' && allowedStatuses.includes(item.status))
              : []
          }
          globalFilter={globalFilter}
        />
      );
    } else if (workpack && workpack.itemCounts.defects === 0) {
      displayDefectTable = (
        <>
          <div className={styles.tableTitle}>{formatMessage({ id: 'title.defects' })}</div>
          <div className={styles.hiddenTable}>{formatMessage({ id: 'text.noDefects' })}</div>
        </>
      );
    }

    let displayScheduleTable = (
      <>
        <div className={styles.tableTitle}>{formatMessage({ id: 'title.scheduledItems' })}</div>
        <div className={styles.hiddenTable}>
          <Spin size="small" />
        </div>
      </>
    );
    if (workpack && workpack.itemCounts.scheduled > 0) {
      displayScheduleTable = (
        <ScheduledTable
          dataSource={
            workpack
              ? workpack.workpack_items
                  .map((item) => item.mx_item)
                  .filter((item) => {
                    if (item.mx_type === null) {
                      return item;
                    }
                    return item.mx_type && item.type === 'scheduled_mx_item' && item.mx_type.includes('scheduled');
                  })
              : []
          }
          globalFilter={globalFilter}
        />
      );
    } else if (workpack && workpack.itemCounts.scheduled === 0) {
      displayScheduleTable = (
        <>
          <div className={styles.tableTitle}>{formatMessage({ id: 'title.scheduledItems' })}</div>
          <div className={styles.hiddenTable}>{formatMessage({ id: 'text.noScheduled' })}</div>
        </>
      );
    }

    let displayOopTable = (
      <>
        <div className={styles.tableTitle}>{formatMessage({ id: 'title.outOfPhaseItems' })}</div>
        <div className={styles.hiddenTable}>
          <Spin size="small" />
        </div>
      </>
    );
    if (workpack && workpack.itemCounts.oop > 0) {
      displayOopTable = (
        <OopTable
          dataSource={
            workpack
              ? workpack.workpack_items
                  .map((item) => item.mx_item)
                  .filter((item) => {
                    return item.mx_type && item.type === 'scheduled_mx_item' && item.mx_type.includes('oop');
                  })
              : []
          }
          globalFilter={globalFilter}
        />
      );
    } else if (workpack && workpack.itemCounts.oop === 0) {
      displayOopTable = (
        <>
          <div className={styles.tableTitle}>{formatMessage({ id: 'title.outOfPhaseItems' })}</div>
          <div className={styles.hiddenTable}>{formatMessage({ id: 'text.noOop' })}</div>
        </>
      );
    }

    let displayLlpTable = (
      <>
        <div className={styles.tableTitle}>{formatMessage({ id: 'title.lifeLimitedParts' })}</div>
        <div className={styles.hiddenTable}>
          <Spin size="small" />
        </div>
      </>
    );
    if (workpack && workpack.itemCounts.llp > 0) {
      displayLlpTable = (
        <LlpTable
          dataSource={
            workpack
              ? workpack.workpack_items
                  .map((item) => item.mx_item)
                  .filter((item) => {
                    return item.mx_type && item.type === 'scheduled_mx_item' && item.mx_type.includes('llp');
                  })
              : []
          }
          globalFilter={globalFilter}
        />
      );
    } else if (workpack && workpack.itemCounts.llp === 0) {
      displayLlpTable = (
        <>
          <div className={styles.tableTitle}>{formatMessage({ id: 'title.lifeLimitedParts' })}</div>
          <div className={styles.hiddenTable}>{formatMessage({ id: 'text.noLlp' })}</div>
        </>
      );
    }
    return (
      <InnerMenuLayout>
        <PageHeaderWrapper
          logo={
            <Link to={`/aircraft/${aircraftId}/workpacks`}>
              <img className={styles.backIcon} src={logo} alt="back arrow" />
            </Link>
          }
          title={`Workpack: ${workpack ? workpack.po_number : ''}`}
          content={
            workpack ? (
              <>
                <DescriptionList className={styles.headerDescriptions} size="small" col={1}>
                  <Description term={formatMessage({ id: 'text.date' })}>
                    {moment(workpack.date).format(userSettings.dateFormat)}
                  </Description>
                  <Description term={formatMessage({ id: 'text.status' })}>
                    <Badge
                      status={status[workpack.status] ? status[workpack.status].badge : null}
                      text={status[workpack.status] ? status[workpack.status].text : null}
                    />
                  </Description>
                </DescriptionList>
              </>
            ) : null
          }
          extraContent={
            workpack &&
            workpack.status !== 'draft' && (
              <AircraftAuthenticationWrapper
                aircraftId={aircraftId}
                requiredResource={AircraftResource.RELEASE}
                requiredPermissionLevel={AircraftPermission.UPDATE}
              >
                <Row gutter={14}>
                  <Col md={{ span: 16 }}>
                    <div className={styles.textSecondary}>{formatMessage({ id: 'title.sharingLinkCaps' })}</div>
                    <Tooltip
                      title={
                        workpackUrl
                          ? formatMessage({ id: 'tooltip.generateLink3rdParty' })
                          : formatMessage({ id: 'message.generateShareLinkFailed' })
                      }
                    >
                      <Icon type="info-circle-o" className={styles.tooltipIcon} />
                    </Tooltip>
                    <Button
                      disabled={!workpackUrl}
                      onClick={workpackUrl ? () => this.setState({ sharingModalVisible: true }) : false}
                    >
                      {formatMessage({ id: 'form.button.generate' })}
                    </Button>
                  </Col>
                  <Col md={{ span: 8 }}>
                    <div className={styles.textSecondary}>{formatMessage({ id: 'title.resolve' })}</div>
                    <Tooltip
                      title={
                        workpackUrl
                          ? formatMessage({ id: 'tooltip.resolveDefect' })
                          : formatMessage({ id: 'message.generateShareLinkFailed' })
                      }
                    >
                      <Icon type="info-circle-o" className={styles.tooltipIcon} />
                    </Tooltip>
                    <Button disabled={!responseUrl}>
                      <a href={responseUrl} target="_blank" rel="noopener noreferrer">
                        {workpack.status !== 'complete'
                          ? formatMessage({ id: 'form.button.addCRS' })
                          : formatMessage({ id: 'form.button.viewCRS' })}
                      </a>
                    </Button>
                  </Col>
                </Row>
              </AircraftAuthenticationWrapper>
            )
          }
        >
          <SharingLinkModal
            visible={sharingModalVisible}
            handleModalVisible={() => this.setState({ sharingModalVisible: false })}
            url={workpackUrl}
          />
          <Card>
            <Input
              value={globalFilter}
              className={styles.filterBox}
              onChange={(e) => {
                this.setState({ globalFilter: e.target.value });
              }}
              placeholder={formatMessage({ id: 'form.placeholder.filterData' })}
            />
            {workpack && workpack.status !== 'complete' ? (
              <AircraftAuthenticationWrapper
                aircraftId={aircraftId}
                requiredResource={AircraftResource.WORKPACK}
                requiredPermissionLevel={AircraftPermission.UPDATE}
              >
                <Button type="primary" className={styles.workpackButton} name="edit">
                  <Link to={`/aircraft/${aircraftId}/workpacks/${workpack.id}/edit`}>
                    {formatMessage({ id: 'form.button.edit' })}
                  </Link>
                </Button>
              </AircraftAuthenticationWrapper>
            ) : null}
            {displayDefectTable}
            {displayScheduleTable}
            {displayOopTable}
            {displayLlpTable}
          </Card>
        </PageHeaderWrapper>
      </InnerMenuLayout>
    );
  }
}

const workpackProfileWithRedux = connect(
  ({ aircraft, workpacks, userSettings }) => ({
    userSettings,
    aircraftMap: aircraft.aircraftMap,
    workpacksMap: workpacks.workpacksMap,
  }),
  (dispatch) => ({
    fetchAircraft: (id) =>
      dispatch(
        getSingleAircraft({
          type: 'aircraft/getSingleAircraft',
          payload: id,
        }),
      ),
    getSingleWorkpack: (id) => {
      return dispatch(
        getSingleWorkpack({
          payload: id,
        }),
      );
    },
  }),
)(WorkpackProfile);

export default withRouter(injectIntl(workpackProfileWithRedux));
