import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const flightTimesValidation = (flightTimes, tripDate, previousBlocksOnTime) => {
  const validatedTimes = {
    ...flightTimes,
  };

  const timeValues = ['time_offblocks', 'time_onblocks', 'time_landing', 'time_takeoff'];

  const flightDate = previousBlocksOnTime || tripDate;

  // Append the flight date to each of the time values

  timeValues.forEach((time) => {
    validatedTimes[time] =
      validatedTimes[time] && moment(validatedTimes[time]).isValid()
        ? moment
            .utc(validatedTimes[time])
            .year(flightDate.year())
            .month(flightDate.month())
            .date(flightDate.date())
        : null;
  });

  // Validate times to fall in the correct date sequence
  // If any time is before it's previous time, add a day to it and all thereafter
  if (
    validatedTimes.time_offblocks &&
    previousBlocksOnTime &&
    validatedTimes.time_offblocks.isBefore(previousBlocksOnTime)
  ) {
    // Only validate block off against previous block on if the flight_date hasn't been changed.
    validatedTimes.time_offblocks = validatedTimes.time_offblocks.clone().add(1, 'd');
    validatedTimes.time_takeoff = validatedTimes.time_takeoff && validatedTimes.time_takeoff.clone().add(1, 'd');
    validatedTimes.time_landing = validatedTimes.time_landing && validatedTimes.time_landing.clone().add(1, 'd');
    validatedTimes.time_onblocks = validatedTimes.time_onblocks && validatedTimes.time_onblocks.clone().add(1, 'd');
  }
  if (
    validatedTimes.time_offblocks &&
    validatedTimes.time_takeoff &&
    validatedTimes.time_takeoff.isBefore(validatedTimes.time_offblocks)
  ) {
    validatedTimes.time_takeoff = validatedTimes.time_takeoff.clone().add(1, 'd');
    validatedTimes.time_landing = validatedTimes.time_landing && validatedTimes.time_landing.clone().add(1, 'd');
    validatedTimes.time_onblocks = validatedTimes.time_onblocks && validatedTimes.time_onblocks.clone().add(1, 'd');
  }
  if (
    validatedTimes.time_landing &&
    validatedTimes.time_takeoff &&
    validatedTimes.time_landing.isBefore(validatedTimes.time_takeoff)
  ) {
    validatedTimes.time_landing = validatedTimes.time_landing.clone().add(1, 'd');
    validatedTimes.time_onblocks = validatedTimes.time_onblocks && validatedTimes.time_onblocks.clone().add(1, 'd');
  }
  if (
    validatedTimes.time_landing &&
    validatedTimes.time_onblocks &&
    validatedTimes.time_onblocks.isBefore(validatedTimes.time_landing)
  ) {
    validatedTimes.time_onblocks = validatedTimes.time_onblocks && validatedTimes.time_onblocks.clone().add(1, 'd');
  }

  return validatedTimes;
};
