import React, { ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { TopNav } from '@arcflight/tf-component-library';
import Cookies from 'universal-cookie';
import { fetch } from '../../models/userSettings/actions';
import logout from '../../utils/logout';
import logoutIcon from '../../assets/icon-link-log-out.svg';
import settingsIcon from '../../assets/icon-link-view-profile-settings.svg';
import servers from '../../utils/servers';
import styles from './index.module.less';

const cookies = new Cookies();

const TopNavWrapper = ({ userSettings, titleIcon = undefined, title = undefined, history }): ReactElement => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { details } = userSettings;
  let avatarUrl;
  let people;
  if (details) {
    avatarUrl = details.avatarUrl;
    people = details.people;
  }
  const avatarIcon = avatarUrl ? `${servers.api}/logins/${cookies.get('userId')}/avatar?${avatarUrl}` : undefined;
  const role = people ? people[0].type : '-';
  const menuItems = [
    {
      label: `${formatMessage({ id: 'menu.account.settings', defaultMessage: 'Profile Settings' })}`,
      icon: settingsIcon,
      onSelect: () => {
        history.push('/settings/account');
      },
    },
    {
      label: ` ${formatMessage({ id: 'menu.account.logout' })}`,
      icon: logoutIcon,
      onSelect: () => {
        logout();
      },
    },
  ];
  useEffect(() => {
    if (!userSettings.displayName) {
      dispatch(fetch());
    }
  });
  return (
    <div className={styles.topnavWrapper} data-testid="topnavwrapper">
      <TopNav
        title={title}
        username={userSettings.displayName}
        userIcon={avatarIcon}
        userRole={role}
        menuItems={menuItems}
        titleIcon={titleIcon}
      />
    </div>
  );
};

const mapStateToProps = ({ userSettings }): { userSettings: object } => {
  return {
    userSettings,
  };
};

export default connect(mapStateToProps)(TopNavWrapper);
