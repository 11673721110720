import React, { useState } from 'react';
import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import globalStyles from '../../utils/globalStyles.module.less';
import { approveMaintenance } from '../../services/api';

const ApproveContent = ({ items, onSuccess, intl: { formatMessage } }) => {
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await approveMaintenance({
        ids: items,
      });

      if (response && response.statusCode === 204) {
        message.success(
          items.length > 1
            ? formatMessage({ id: 'message.approveItemsSuccess' })
            : formatMessage({ id: 'message.approveItemSuccess' }),
        );
        onSuccess();
      }
    } catch (error) {
      message.error(formatMessage({ id: 'message.approveItemsError' }));
    }
  };

  return (
    <>
      <Button
        type="primary"
        className={`${globalStyles.addNewButton} ${globalStyles.buttonPreserveText}`}
        icon="check"
        onClick={() => handleSubmit()}
        loading={submitting}
        data-test="approveButton"
      >
        <span className={globalStyles.buttonText}>{formatMessage({ id: 'title.approveItems' })}</span>
      </Button>
    </>
  );
};

ApproveContent.propTypes = {
  items: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default injectIntl(ApproveContent);
