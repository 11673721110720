import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import overviewIcon from '../assets/context-menu-icons/overview.svg';
import tripsIcon from '../assets/context-menu-icons/trips.svg';
import logbookIcon from '../assets/context-menu-icons/logbook.svg';
import defectsIcon from '../assets/context-menu-icons/defects.svg';
import maintenanceIcon from '../assets/context-menu-icons/maintenance.svg';
import workpacksIcon from '../assets/context-menu-icons/workpacks.svg';
import metricsIcon from '../assets/context-menu-icons/metrics.svg';
import documentsIcon from '../assets/context-menu-icons/documents-2.svg';
import damageMapsIcon from '../assets/context-menu-icons/damage-maps.svg';
import moreIcon from '../assets/more-icon.svg';
import AuthenticationWrapper, { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { DashboardResource, UserPermission } from '../../models/userSettings';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import styles from './AircraftMenu.module.less';

const devices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

const deviceIsIOS = () => navigator && devices.includes(navigator.platform);

const fixIOSHover = () => {
  if (deviceIsIOS()) {
    return styles.noHover;
  }
  return null;
};

const hoverClass = fixIOSHover();

const AircraftMenu = ({ selectedKeys, onClick, isMobile, intl: { formatMessage }, match }) => {
  const { id } = match.params;
  return (
    <ul className={`${styles.aircraftMenuWrapper} ${isMobile ? styles.aircraftMobileHeader : undefined}`}>
      <AuthenticationWrapper
        requiredResource={DashboardResource.AIRCRAFT}
        requiredPermissionLevel={UserPermission.READ}
      >
        <li className={selectedKeys[0] === 'overview' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/overview`} className={styles.aircraftMenuLink}>
            <img src={overviewIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Overview' })}
          </Link>
        </li>
      </AuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.TRIP}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'trips' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/trips`}>
            <img src={tripsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Trips' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.LOGBOOK}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'logbook' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/logbook`}>
            <img src={logbookIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Logbook' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.DEFECT}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'defects' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/defects`}>
            <img src={defectsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Defects' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.SCHEDULED_MX_ITEM}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'maintenance' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/maintenance`}>
            <img src={maintenanceIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Maintenance' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.TRIP}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'metrics' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/metrics`}>
            <img src={metricsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Metrics' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.WORKPACK}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'workpacks' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/workpacks`}>
            <img src={workpacksIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Workpacks' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.DOCUMENT}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'documents' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/documents`}>
            <img src={documentsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.Documents' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      <AircraftAuthenticationWrapper
        aircraftId={id}
        requiredResource={AircraftResource.DAMAGE_MAP}
        requiredPermissionLevel={AircraftPermission.READ}
      >
        <li className={selectedKeys[0] === 'damage_maps' ? `${styles.activeLink} ${hoverClass}` : `${hoverClass}`}>
          <Link to={`/aircraft/${id}/damage_maps/list_view`}>
            <img src={damageMapsIcon} className={styles.menuIcon} alt="" />
            {formatMessage({ id: 'menu.Aircraft.DamageMap' })}
          </Link>
        </li>
      </AircraftAuthenticationWrapper>
      {isMobile && (
        <button type="button" onClick={onClick} onKeyDown={onClick}>
          <img src={moreIcon} alt="Open aircraft menu" className={styles.moreIcon} />
        </button>
      )}
    </ul>
  );
};

AircraftMenu.propTypes = {
  selectedKeys: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  isMobile: PropTypes.bool,
  match: PropTypes.object.isRequired,
};

AircraftMenu.defaultProps = {
  isMobile: false,
  onClick: () => {
    // default
  },
};

export default withRouter(injectIntl(AircraftMenu));
