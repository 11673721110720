// eslint-disable-next-line import/prefer-default-export
export const warnings = (formatMessage) => ({
  callsign(trip) {
    if (!trip.callsign || trip.callsign === '') {
      return {
        help: formatMessage({ id: 'message.noCallsignSelected' }),
        validateStatus: 'error',
      };
    }
    return null;
  },
  pic(captain_id) {
    if (!captain_id || captain_id === '') {
      return {
        help: formatMessage({ id: 'message.noPilotInCommandSelected' }),
        validateStatus: 'error',
      };
    }
    return null;
  },
  tripDate(trip) {
    if (!trip.date || !trip.date.isValid()) {
      return {
        help: formatMessage({ id: 'message.noDateSelected' }),
        validateStatus: 'error',
      };
    }
    return null;
  },
});
