import React from 'react';
import styles from '../workpackDrawer.module.less';

const Totals = ({ ta }): JSX.Element => {
  if (ta.length > 0) {
    const arrayOrder = {
      AIRFRAME: 0,
      'ENGINE 1': 1,
      'ENGINE 2': 2,
      'ENGINE 3': 3,
      'ENGINE 4': 4,
      APU: 5,
      'PROP 1': 6,
      'PROP 2': 7,
      'PROP 3': 8,
      'PROP 4': 9,
    };

    const compare = (a, b): number => {
      if (a === b) {
        return 0;
      }
      return a < b ? -1 : 1;
    };

    ta.sort((a, b) => {
      const indexResult = compare(arrayOrder[a.name], arrayOrder[b.name]);
      if (indexResult === 0) {
        return compare(a.name, b.name);
      }
      return indexResult;
    });
  }

  return ta.map((item) => (
    <div className={styles.rowItem} key={item.name}>
      <span className={styles.headingGray}>{item.name} </span>
      <span className={styles.units}>{item.seconds !== null && `${(item.seconds / 60 / 60).toFixed(0)} hrs`}</span>
      <span className={styles.units}>{item.cycles !== null && `${item.cycles} cycles`}</span>
    </div>
  ));
};

const AircraftTotals = ({ totals, showApu }): JSX.Element => {
  let totalsArray = [];
  if (totals) {
    totalsArray = Object.keys(totals).reduce((acc, curr) => {
      const currName = curr.split('_').join(' ');
      const nameToUppercase = currName.toUpperCase();
      if (nameToUppercase === 'APU') {
        if (showApu) acc.push({ name: nameToUppercase, ...totals[curr] });
      } else {
        acc.push({ name: nameToUppercase, ...totals[curr] });
      }
      return acc;
    }, []);
  }

  return (
    <div className={styles.workpackWhiteBox}>
      <div className={`${styles.minorHeading} ${styles.rowPad20}`}>Aircraft Totals</div>
      <div className={styles.rowGridFiveByTwo}>
        <Totals ta={totalsArray} />
      </div>
    </div>
  );
};

export default AircraftTotals;

interface TotalsType {
  airframe: {
    cycles: number;
    flights: number;
    landings: number;
    next_due: string;
    seconds: number;
  };
  apu?: {
    cycles: number;
    next_due: number;
    seconds: number;
  };
  engine_1?: {
    cycles: number;
    next_due: string;
    seconds: number;
  };
  engine_2?: {
    cycles: number;
    next_due: string;
    seconds: number;
  };
  engine_3?: {
    cycles: number;
    next_due: string;
    seconds: number;
  };
  engine_4?: {
    cycles: number;
    next_due: string;
    seconds: number;
  };
  prop_1?: {
    cycles: number;
    next_due: string;
    seconds: number;
  };
  prop_2?: {
    cycles: number;
    next_due: string;
    seconds: number;
  };
  prop_3?: {
    cycles: number;
    next_due: string;
    seconds: number;
  };
  prop_4?: {
    cycles: number;
    next_due: string;
    seconds: number;
  };
}
