/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.less';

const DescriptionList = ({
  className,
  title,
  col = 3,
  layout = 'horizontal',
  gutter = 32,
  children,
  size,
  ...restProps
}) => {
  const clsString = classNames(styles.descriptionList, styles[layout], className, {
    [styles.small]: size === 'small',
    [styles.large]: size === 'large',
  });
  const column = col > 4 ? 4 : col;
  return (
    <div className={clsString} {...restProps}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <Row gutter={gutter}>{React.Children.map(children, (child) => React.cloneElement(child, { column }))}</Row>
    </div>
  );
};

DescriptionList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  col: PropTypes.number,
  layout: PropTypes.string,
  gutter: PropTypes.number,
  children: PropTypes.node,
  size: PropTypes.string.isRequired,
};

DescriptionList.defaultProps = {
  col: 3,
  layout: 'horizontal',
  gutter: 32,
  title: undefined,
  children: null,
  className: '',
};

export default DescriptionList;
