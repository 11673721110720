/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import expandIcon from '../../../assets/expand.svg';
import completeIcon from '../../../assets/icon-complete.svg';
import TFFileViewer from '../../TFFileViewer/fileViewer';
import collapseIcon from '../../../assets/collapse.svg';
import bin from '../../../assets/delete-red.svg';
import styles from '../workpackDrawer.module.less';

const PartChanges = ({ parts }): JSX.Element => {
  const { formatMessage } = useIntl();

  return parts.map((part) => (
    <div className={`${styles.rowGridFour} ${styles.rowPad20}`} key={part && part.part_number}>
      <div className={styles.rowItem}>
        <span className={styles.headingGray}>{formatMessage({ id: 'form.labels.partNumber' })}</span>
        <span>{(part && part.part_number) || '-'}</span>
      </div>
      <div className={styles.rowItem}>
        <span className={styles.headingGray}>{formatMessage({ id: 'form.labels.serialOff' })}</span>
        <span>{(part && part.serial_off) || '-'}</span>
      </div>
      <div className={styles.rowItem}>
        <span className={styles.headingGray}>{formatMessage({ id: 'form.labels.serialOn' })}</span>
        <span>{(part && part.serial_on) || '-'}</span>
      </div>
      <div className={styles.rowItem}>
        <span className={styles.headingGray}>{formatMessage({ id: 'form.labels.batchNumberOn' })}</span>
        <span>{(part && part.batch) || '-'}</span>
      </div>
    </div>
  ));
};

const DefectResolution = ({ resolutionInfo, mode, status }): JSX.Element => {
  const { formatMessage } = useIntl();

  const hasPartChanges = resolutionInfo.part_changes.length > 0;
  let defectResolutionWrapper = styles.defectResolution;
  if (mode === 'edit') defectResolutionWrapper = styles.defectResolutionEdit;
  if (status === 'resolved') defectResolutionWrapper = `${styles.defectResolutionResolved} ${styles.clearTopBorder}`;
  return (
    <div className={defectResolutionWrapper}>
      <span>Resolution Details</span>
      {hasPartChanges && <PartChanges parts={resolutionInfo && resolutionInfo.part_changes} />}
      <div className={styles.rowGridTwo}>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>{formatMessage({ id: 'form.labels.details' })}</span>
          <span>{(resolutionInfo && resolutionInfo.description) || '-'}</span>
        </div>
        <div className={styles.rowItem}>
          <span className={styles.headingGray}>{formatMessage({ id: 'form.labels.limitations' })}</span>
          <span>{(resolutionInfo && resolutionInfo.limitations) || '-'}</span>
        </div>
      </div>
    </div>
  );
};

const DefectDetails = ({
  melInfo: { display_data, mel_item },
  details,
  attachments,
  remaining,
  reportedBy,
  signature,
  date,
  mode,
  status,
}): JSX.Element => {
  const { formatMessage } = useIntl();

  let defectDetailsWrapper = styles.MXDetails;
  if (mode === 'edit') defectDetailsWrapper = styles.MXDetailsEdit;
  if (status === 'resolved') defectDetailsWrapper = `${styles.MXDetailsResolution} ${styles.clearBottomBorder}`;
  const addAttachment = (): void => null;
  return (
    <div className={defectDetailsWrapper}>
      <div className={styles.defectDetails}>
        <span className={styles.header}>{formatMessage({ id: 'form.labels.defectDetails' })}</span>
        <div className={styles.rowGridTwo}>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'form.labels.details' })}</span>
            <span>{details || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'title.attachments' })}</span>
            <TFFileViewer attachments={attachments} addAttachment={addAttachment} uploaderVisible={mode === 'edit'} />
          </div>
        </div>
        <div className={`${styles.rowGridFourByTwo} ${styles.rowPad20}`}>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'title.type' })}</span>
            <span>{(display_data && display_data.type) || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.ata' })}</span>
            <span>{(display_data && display_data.ata) || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.itemNum' })}</span>
            <span>{(mel_item && mel_item.item_number) || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.item' })}</span>
            <span>{(mel_item && mel_item.item_title) || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'title.rectificationIntervalCaps' })}</span>
            <span>{display_data?.category || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.quantityRemaining' })}</span>
            <span>{remaining || '-'}</span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.defectReportedBy}>
            <span>{formatMessage({ id: 'text.reportedBy' })} </span>
            <span className={styles.bold}>
              {(reportedBy && reportedBy.first_name) || '-'} {(reportedBy && reportedBy.last_name) || null}
            </span>
            {signature && <img src={signature} alt="signature" />}
          </div>
          <div className={styles.defectReportedOn}>
            <span>{formatMessage({ id: 'text.reportedOn' })} </span>
            <span className={styles.bold}>{date || '-'}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const defectHasMel = (mx_item): boolean => Object.prototype.hasOwnProperty.call(mx_item, 'display_data');

const Defect = ({ item: { mx_item, id, mx_item_id }, removeWPItem, mode }): JSX.Element => {
  const [showDefectDetails, toggleShowDefectDetails] = useState(false);

  const { display_data, mel_item } = mx_item;
  const toggleDefectDetails = (): void => toggleShowDefectDetails((state) => !state);

  let melChapter = null;
  const defectResolved = mx_item.mx_events.length > 0;

  if (defectHasMel(mx_item)) {
    melChapter = mx_item.display_data.ata;
  }
  return (
    <div>
      <div className={styles.wpRowWrapper}>
        {mx_item.status === 'resolved' ? (
          <div className={styles.checkbox}>
            <img src={completeIcon} alt="complete icon" className={styles.tickIcon} />
          </div>
        ) : (
          <div className={styles.emptyCheckbox} />
        )}
        <div
          role="button"
          key={id}
          className={showDefectDetails ? styles.wpItemRowExpanded : styles.wpItemRow}
          onClick={toggleDefectDetails}
        >
          <div className={styles.itemDesWrapper}>
            <span className={styles.mxType} style={{ backgroundColor: '#1e589f' }}>
              DEF{' '}
            </span>
            <span className={styles.mxLabel}>
              <span className={styles.chapterName}>{mx_item.details}</span>
            </span>
          </div>
          <div className={styles.expandWrapper}>
            {mx_item.status === 'resolved' ? (
              <span className={styles.outcome}>
                {mx_item.mx_events[0].part_changes && mx_item.mx_events[0].part_changes.length > 0
                  ? 'Part Replaced'
                  : 'Complied With'}
              </span>
            ) : (
              <span className={styles.outcome}>Created {moment(mx_item.created_at).format('DD MMM YYYY')}</span>
            )}
            <img src={showDefectDetails ? collapseIcon : expandIcon} alt="expand icon" className={styles.expandIcon} />
          </div>
        </div>
        {mode === 'edit' && (
          <button type="button" onClick={(): void => removeWPItem(id, mx_item_id)}>
            <img src={bin} alt="bin" className={styles.bin} />
          </button>
        )}
      </div>
      {showDefectDetails && (
        <>
          <DefectDetails
            melInfo={{ display_data, mel_item }}
            details={mx_item.details}
            attachments={mx_item.attachments}
            remaining={mx_item.item_number_remaining}
            reportedBy={mx_item.reported_by || mx_item.reporter}
            signature={mx_item.public_signature_image_url}
            date={mx_item.date}
            mode={mode}
            status={mx_item.status}
          />
          {defectResolved && (
            <DefectResolution resolutionInfo={mx_item.mx_events[0]} mode={mode} status={mx_item.status} />
          )}
        </>
      )}
    </div>
  );
};

export default Defect;
