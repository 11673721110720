import { parse, stringify } from 'qs';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { LoadingScreen } from '../providers/AuthProvider';
import { getUserDetails } from '../services/api';
import config, { ConfigKey } from '../utils/config';

const cookies = new Cookies();

const renewAuthToken = async (
  code: string,
  redirect: string,
  history: ReturnType<typeof useHistory>,
): Promise<void> => {
  const paramsForToken = stringify({
    code,
    grant_type: 'authorization_code',
    client_id: config.get(ConfigKey.UID),
    client_secret: config.get(ConfigKey.SECRET),
    redirect_uri: `${window.location.origin}/auth/callback`,
  });

  let sameSiteProperties = {};

  if (process.env.NODE_ENV !== 'development') {
    sameSiteProperties = {
      sameSite: 'none',
      secure: true,
    };
  }

  const url = `${config.get(ConfigKey.API)}/oauth/token?${paramsForToken}`;
  const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
  const data = await fetch(url, { method: 'POST', headers })
    .then((response) => {
      if (!response.ok) throw response;
      return response.json();
    })
    .catch((err) => {
      console.error('OAuth Fetch Failed', err);
      return {};
    });

  const token = data.access_token;
  cookies.set('auth', token, {
    domain: config.get(ConfigKey.COOKIEDOMAIN),
    maxAge: 14000, // 3.8 hrs
    path: '/',
    ...sameSiteProperties,
  });
  const details = await getUserDetails();

  cookies.set('user', `${details.first_name} ${details.last_name}`, {
    maxAge: 31536000,
    path: '/',
    ...sameSiteProperties,
  });
  cookies.set('userId', details.id, {
    domain: config.get(ConfigKey.COOKIEDOMAIN),
    maxAge: 31536000,
    path: '/',
    ...sameSiteProperties,
  });
  cookies.set('user_authority', details.dashboard_permissions, {
    maxAge: 31536000,
    path: '/',
    ...sameSiteProperties,
  });
  cookies.set('org', details.people[0].organisation.id, {
    domain: config.get(ConfigKey.COOKIEDOMAIN),
    maxAge: 31536000,
    path: '/',
    ...sameSiteProperties,
  });
  // Remove this due to the cookie becoming too large
  // const operators = details.people.map((person) => ({
  //   name: person.organisation.name,
  //   id: person.organisation.id,
  // }));
  // cookies.set('operators', operators.slice(0, 30), {
  //   domain: config.get(ConfigKey.COOKIEDOMAIN),
  //   maxAge: 31536000,
  //   path: '/',
  //   ...sameSiteProperties,
  // });

  history.push(redirect || '/');
};

const OauthCallback: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    // Parse all necessary data from CoreAPI
    const { code, state: stateJson } = parse(window.location.search, { ignoreQueryPrefix: true });
    const { redirect, nonce } = JSON.parse(stateJson);
    const cookieNonce = cookies.get('oauthnonce');
    // Remove outdated cookie
    cookies.remove('oauthnonce');
    // If our nonce and the one from Core differ, redirect to Core's endpoint.
    if (nonce !== cookieNonce) {
      history.push(config.get(ConfigKey.API));
      return;
    }
    renewAuthToken(code, redirect, history);
  });
  return <LoadingScreen />;
};

export default OauthCallback;
