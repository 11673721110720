import React, { useRef } from 'react';
import searchIcon from '../../assets/search.svg';
import cancelIcon from '../../assets/new-icon-cancel.svg';
import styles from './TableSearch.module.less';

const TableSearch: React.FC<TableSearchProps> = ({ placeholder, value, visible, onToggle, onChange }) => {
  const inputRef = useRef(null);

  const handleSeachToggle = (): void => {
    onToggle();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 400);
  };

  const handleInputBlur = (): void => {
    if (!value) {
      onToggle();
    }
  };
  return (
    <div className={styles.searchHolder}>
      <button type="button" onClick={handleSeachToggle}>
        <img
          src={searchIcon}
          alt="search"
          className={`${styles.searchIcon} ${visible ? styles.searchIconActive : undefined}`}
          data-test="searchIcon"
        />
      </button>
      {visible ? (
        <div className={styles.searchInputHolder}>
          <input
            className={`${styles.searchInput} ${visible ? styles.searchInputActive : undefined}`}
            placeholder={placeholder}
            value={value}
            onChange={(e): void => onChange(e.target.value)}
            data-test="searchInput"
            onBlur={handleInputBlur}
            ref={inputRef}
          />
          <button
            type="button"
            className={`${styles.closeIconHolder} ${visible ? styles.closeIconHolderActive : undefined}`}
            onClick={(): void => onChange('')}
            data-test="closeIcon"
          >
            <img src={cancelIcon} alt="cancel icon" className={styles.closeIcon} />
          </button>
        </div>
      ) : null}
    </div>
  );
};

type TableSearchProps = {
  placeholder: string;
  value: string;
  visible: boolean;
  onToggle: () => void;
  onChange: (e: string) => void;
};

export default TableSearch;
