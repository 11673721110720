import { Button, message, Upload, Icon } from 'antd';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { addAttachmentsToMXRelease } from '../../services/external';
import { getWorkpackUrl } from '../../services/api';
import styles from './index.module.less';

class DocumentUploader extends PureComponent {
  static propTypes = {
    returnBase64: PropTypes.bool,
    returnDocument: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    workpackResolved: PropTypes.bool.isRequired,
    uploadParams: PropTypes.object.isRequired,
  };

  static defaultProps = {
    returnBase64: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      fileSource: [],
      fileList: [],
      uploading: false,
      fileListHasBeenReset: false,
    };
  }

  setNewFileOnState = async (file) => {
    const { fileSource } = this.state;
    const newFileSource = fileSource.concat(
      await Promise.all(
        file.map(async (item) => {
          let attachment;
          if (file.size === 0) {
            message.error(this.props.intl.formatMessage({ id: 'message.fileIsEmpty' }));
          } else {
            attachment = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(item);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
          }
          return { attachment, attachment_file_name: item.name, uid: item.uid };
        }),
      ),
    );

    this.setState({ fileSource: newFileSource }, () => {
      if (this.props.returnBase64 && this.props.returnDocument) {
        this.props.returnDocument({
          attachment: this.state.fileSource,
        });
      }
    });
  };

  handleUploadAfterSignoff = async () => {
    const {
      intl: { formatMessage },
      uploadParams: { releaseId, public_url_key, updatePage, workpackId },
    } = this.props;
    const { fileList } = this.state;

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('attachments[]', file.originFileObj);
    });

    const handleMXUploadRequest = async (public_key) => {
      const result = await addAttachmentsToMXRelease({ releaseId, public_url_key: public_key, formData });
      if (result === 200) {
        message.success(formatMessage({ id: 'message.onFileSuccess' }));
        this.setState({ fileList: [], uploading: false }, () => {
          updatePage();
        });
      }
      return result;
    };

    const uploadFailed = () => {
      message.error(formatMessage({ id: 'message.onFileFailed' }));
      this.setState({ uploading: false });
    };

    this.setState({
      uploading: true,
    });

    // ND 17/08/2020 we've added retry logic here to accommodate expired workpack public keys.
    // EP 19/10/2020 getWorkpackUrl moved here to prevent redirect to Core login page
    try {
      const result = await handleMXUploadRequest(public_url_key);
      if (result === 401) {
        const workpackUrl = await getWorkpackUrl(workpackId);
        const workpackKey = workpackUrl.url.match(/[^/]+$/);
        const retry = await handleMXUploadRequest(workpackKey);
        if (retry > 299) {
          uploadFailed();
        }
      } else if (result > 299) {
        uploadFailed();
      }
    } catch {
      uploadFailed();
    }
  };

  resetFileListAfterFormSubmission = () => {
    this.setState({ fileList: [], fileListHasBeenReset: true });
  };

  handleFileChange = ({ fileList } = {}) => {
    const { workpackResolved } = this.props;

    const removeAttachments = () => {
      this.setState({ fileSource: [] }, () => {
        const newFileList = fileList.map((item) => item.originFileObj);
        this.setNewFileOnState(newFileList);
      });
    };

    const itemsHaveBeenRemovedFromFileList = () => fileList.length < this.state.fileList.length;

    if (itemsHaveBeenRemovedFromFileList() && !workpackResolved) {
      removeAttachments();
    }

    this.setState({ fileList });
  };

  render() {
    const { fileList, uploading, fileListHasBeenReset } = this.state;
    const {
      intl: { formatMessage },
      workpackResolved,
    } = this.props;

    if (workpackResolved && !fileListHasBeenReset) {
      this.resetFileListAfterFormSubmission();
    }

    return (
      <div className={styles.documentsUploaderParent}>
        <Upload
          multiple
          accept={['.jpg', '.jpeg', '.pdf', '.png'].join(',')}
          onChange={this.handleFileChange}
          fileList={fileList}
          beforeUpload={(file, currentFileList) => {
            if (workpackResolved) {
              return false;
            }
            this.setNewFileOnState(currentFileList);
            return false;
          }}
          className={styles.fileUploadDragger}
          data-test="uploader"
        >
          <Button data-test="public-mx-upload-button">
            <Icon type="upload" data-test="upload-icon" /> {formatMessage({ id: 'form.button.upload' })}
          </Button>
        </Upload>
        {workpackResolved && (
          <Button
            type="primary"
            onClick={this.handleUploadAfterSignoff}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
            data-test="post-signoff-upload"
          >
            {formatMessage({ id: 'form.submit' })}
          </Button>
        )}
      </div>
    );
  }
}

export default injectIntl(DocumentUploader);
