import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Badge, Table, Icon } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import servers from '../../utils/servers';
import defaults from '../../utils/defaults';
import EmptyStateDefects from '../../assets/emptyState/empty-state-defects.svg';
import styles from './WorkpackProfile.module.less';

export const allowedStatuses = ['open', 'overdue', 'error'];

class DefectsTable extends PureComponent {
  static propTypes = {
    dataSource: PropTypes.array.isRequired,
    globalFilter: PropTypes.string,
    intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
    userSettings: PropTypes.object.isRequired,
    pagination: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    globalFilter: '',
    pagination: {},
    onChange: () => {
      // default func
    },
  };

  constructor(props) {
    super(props);
    const {
      intl: { formatMessage },
    } = props;
    this.state = {
      status: {
        open: {
          badge: 'processing',
          text: formatMessage({ id: 'status.open' }),
        },
        overdue: {
          badge: 'error',
          text: formatMessage({ id: 'status.overdue' }),
        },
        error: {
          badge: 'error',
          text: formatMessage({ id: 'status.error' }),
        },
      },
    };
    const { status } = this.state;
    if (!Object.keys(status).every((key) => allowedStatuses.includes(key))) {
      // Because we cannot define the object status outside of a React component (`formatMessage` calls needs
      // to be within the component) we need this check to ensure that we keep the state of the component and the
      // array of allowed statuses in sync.
      throw new Error('Mismatched allowed statuses and status keys.');
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { onChange } = this.props;
    onChange(pagination, filters, sorter);
  };

  renderDefectColumns() {
    const {
      intl: { formatMessage },
      globalFilter,
      userSettings,
    } = this.props;
    const { status } = this.state;
    const newGlobalFilter = globalFilter ? globalFilter.toLowerCase() : '';
    return [
      {
        title: formatMessage({ id: 'title.numberShort' }),
        dataIndex: 'number',
        sorter: (a, b) => a.number - b.number,
        filteredValue: [newGlobalFilter],
        width: 80,
      },
      {
        title: formatMessage({ id: 'title.date' }),
        dataIndex: 'date',
        render: (text) => {
          return userSettings && userSettings.dateFormat
            ? moment(text).format(userSettings.dateFormat)
            : moment(text).format(defaults.defaultDateFormat);
        },
        sorter: (a, b) => {
          if (!a.date) {
            return -1;
          }
          if (!b.date) {
            return 1;
          }
          return moment(a.date).diff(moment(b.date));
        },
        width: 130,
      },
      {
        dataIndex: 'attachment',
        width: 80,
        render: (val) => {
          if (val !== null && val !== undefined) {
            return (
              <span>
                <a href={`${servers.api + val}.pdf`} target="_blank" rel="noopener noreferrer" download="DDL">
                  <Icon type="file-pdf" /> {formatMessage({ id: 'text.ddl' })}
                </a>
              </span>
            );
          }
          return null;
        },
      },
      {
        title: formatMessage({ id: 'title.ata' }),
        dataIndex: 'display_data.ata',
        sorter: (a, b) => a.number - b.number,
        width: 110,
        filteredValue: [newGlobalFilter],
        render: (text, row) => {
          const number = text.split(' ')[0];
          return number || row.mel_item.ata_number || '-';
        },
        onFilter: (value, record) => {
          // Global filters for all items, as they filters with filteredValue should be placed in only one column
          let outReturn = false;
          if (record?.mel_item?.chapter_number && !outReturn) {
            outReturn = record?.mel_item?.chapter_number
              .toString()
              .toLowerCase()
              .includes(newGlobalFilter?.toLowerCase());
          }
          if (record?.mel_item?.ata_number && !outReturn) {
            outReturn = record?.mel_item?.ata_number
              .toString()
              .toLowerCase()
              .includes(newGlobalFilter.toLowerCase());
          }
          if (record?.mel_item?.title && !outReturn) {
            outReturn = record?.mel_item?.title.toLowerCase().includes(newGlobalFilter.toLowerCase());
          }
          if (record?.mel_item?.item_title && !outReturn) {
            outReturn = record?.mel_item?.item_title.toLowerCase().includes(newGlobalFilter.toLowerCase());
          }
          if (!outReturn) {
            outReturn = newGlobalFilter === '';
          }
          return outReturn;
        },
      },
      {
        title: formatMessage({ id: 'title.item' }),
        dataIndex: 'details',
        sorter: (a, b) => {
          if (!a.mel_item.title) {
            return -1;
          }
          if (!b.mel_item.title) {
            return 1;
          }
          return a.mel_item.title.localeCompare(b.mel_item.title);
        },
        render: (text, row) => {
          return text || row.mel_item.item_title || '-';
        },
      },
      {
        title: formatMessage({ id: 'title.daysRemainingShort' }),
        dataIndex: 'days_remaining',
        sorter: (a, b) => a.days_remaining - b.days_remaining,
        width: 130,
      },
      {
        title: formatMessage({ id: 'title.status' }),
        dataIndex: 'status',
        width: 130,
        filters: Object.keys(status).map((key) => ({
          text: status[key].text,
          value: key,
        })),
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        render: (val) => {
          return <Badge status={status[val].badge} text={status[val].text} />;
        },
      },
    ];
  }

  render() {
    const { dataSource, intl, pagination } = this.props;
    return (
      <Table
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        title={() => <div className={styles.tableTitle}>{intl.formatMessage({ id: 'title.defects' })}</div>}
        columns={this.renderDefectColumns()}
        scroll={{ x: 800 }}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          ...pagination,
        }}
        rowKey={(item) => item.id}
        dataSource={dataSource}
        onChange={this.handleTableChange}
        locale={{
          emptyText: (
            <div className={styles.emptyText}>
              <img src={EmptyStateDefects} alt="empty state" />
              <span>No Data</span>
            </div>
          ),
        }}
      />
    );
  }
}

const defectsTableWithRedux = connect(({ userSettings }) => ({
  userSettings,
}))(DefectsTable);
export default injectIntl(defectsTableWithRedux);
