import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import FileViewer from '../../TFFileViewer/fileViewer';
import TFButton from '../../TFButton/TFButton';
import servers from '../../../utils/servers';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';
import ViewDeferralOptions from './ViewDeferralOptions';
import ViewMelDetails from './ViewMelDetails';

interface DefectDetailsProps {
  defect: Defect;
  editDefect: boolean;
  signature: string;
  attachments?: any[];
  setAttachments?: (input: any) => void;
}

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
`;

const BorderLine = styled.div`
  width: 792px;
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-left: -22px;
`;

const StyledImg = styled.img`
  height: 70px;
  max-width: 400px;
`;

const DefectDetails: React.FC<DefectDetailsProps> = ({
  defect,
  editDefect,
  signature,
  attachments,
  setAttachments,
}) => {
  const [details, setDetails] = useState('');
  const [isMelTableVisible, setIsMelTableVisible] = useState(true);
  const [limitations, setLimitations] = useState('');
  const [EROPS, setEROPS] = useState(false);
  const [sigSource, setSigSource] = useState('');

  const { formatMessage } = useIntl();

  const convertToImgFile = (file): Promise<any> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentUpload = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      files.forEach(async (file) => {
        attachPromises.push(convertToImgFile(file));
      });
      const base64Attachments = await Promise.all(attachPromises);
      setAttachments(attachments?.concat(...base64Attachments));
    }
  };

  const handleDelete = ({ fileId, fileName }): void => {
    const newAttachmentsList = attachments.filter(
      (item) => item?.id !== fileId || item?.attachment_file_name !== fileName,
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    setAttachments(newAttachmentsList);
  };

  const handleMELTableClick = (): void => {
    setIsMelTableVisible(!isMelTableVisible);
  };

  useEffect(() => {
    if (defect) {
      setAttachments(defect?.attachments);
      setLimitations(defect?.limitations);
      setDetails(defect?.details);
      if (defect.mel_item && defect.mel_item.rectification_id) {
        const rectification = defect.mel_item.mel_rectifications.find(
          (rec) => rec.id === defect.mel_item.rectification_id,
        );
        const recEROPS = rectification?.erops_prevented || false;
        setEROPS(recEROPS);
      } else if (defect && defect.mel_item && defect.mel_item.mel_rectifications) {
        const rectification = defect?.mel_item?.mel_rectifications[0];
        const recEROPS = (rectification && rectification.erops_prevented) || false;
        setEROPS(recEROPS);
      }
    }
  }, [defect, setAttachments]);

  useEffect(() => {
    let imageSource = `${servers.api}${signature}`;
    if (!signature?.startsWith('/signature')) imageSource = `data:image/png;base64,${signature}`;
    setSigSource(imageSource);
  }, [signature]);

  const lastMXEvent = defect?.mx_events && defect?.mx_events[defect?.mx_events.length - 1];

  return (
    <Card marginTop={30} marginBottom={20} identifier="DefectDetailsWrapper">
      <Label fontWeight={600} identifier="DefectDetailsLabel">
        {formatMessage({ id: 'form.labels.defectDetails' })}
      </Label>
      <GridWrapper columns="1fr 1fr" marginBottom={20} marginTop={20} columnGap={20}>
        <FlexWrapper column colSpan={0}>
          <Label identifier="NotesLabel">Notes</Label>
          {editDefect ? (
            <StyledTextarea
              id="notes"
              name="notes"
              data-testid="DefectDetails--TextArea"
              cols={30}
              rows={5}
              defaultValue={details}
            />
          ) : (
            <span data-testid="DefectDetails--Details">{details || '-'}</span>
          )}
        </FlexWrapper>
        <FlexWrapper column>
          <Label identifier="AttachmentsLabel">{formatMessage({ id: 'title.attachments' })}</Label>
          <FileViewer
            attachments={attachments}
            handleDeleteClick={handleDelete}
            addAttachment={handleAttachmentUpload}
            uploaderVisible={editDefect}
            allowDelete={editDefect}
          />
        </FlexWrapper>
      </GridWrapper>
      {!editDefect && (
        <>
          <ViewDeferralOptions defect={defect} />
          {defect?.defect_type === 'MEL' ? (
            <>
              <TFButton padding="0" clear handleClick={handleMELTableClick}>
                {isMelTableVisible ? 'Hide MEL Table' : 'Show MEL Table'}
              </TFButton>
              <ViewMelDetails defect={defect} isMelTableVisible={isMelTableVisible} />
            </>
          ) : null}
          <FlexWrapper column marginBottom={30}>
            <Label marginBottom={20} marginTop={defect?.defect_type === 'MEL' ? 30 : 1} fontWeight={600}>
              {formatMessage({ id: 'form.labels.aircraftLimitations' })}
            </Label>
            <GridWrapper columns="1fr 1fr">
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'form.labels.aircraftLimitations' })}
                </Label>
                <span>{limitations || '-'}</span>
              </FlexWrapper>
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'title.exceptForEROPS' })}
                </Label>
                <span>{EROPS ? 'Yes' : 'No'}</span>
              </FlexWrapper>
            </GridWrapper>
          </FlexWrapper>
          <BorderLine />
          <FlexWrapper column marginBottom={30}>
            <Label marginBottom={20} marginTop={30} fontWeight={600}>
              {formatMessage({ id: 'title.additionalInformation' })}
            </Label>
            <GridWrapper columns="1fr 1fr">
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'text.reasonToDefer' })}
                </Label>
                <span>{defect?.reason_to_defer || '-'}</span>
              </FlexWrapper>
              <FlexWrapper column>
                <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                  {formatMessage({ id: 'text.deferralApprovalReference' })}
                </Label>
                <span>{defect?.defer_approval_reference || '-'}</span>
              </FlexWrapper>
            </GridWrapper>
          </FlexWrapper>
          <BorderLine />
          <FlexWrapper justifyContent="space-between" alignItems="center" marginTop={20} marginBottom={20} height={40}>
            <FlexWrapper alignItems="center">
              <Label fontWeight={500} marginRight={4}>
                {formatMessage({ id: 'text.reportedBy' })}
              </Label>
              <Label fontWeight={600} marginRight={15}>
                {defect?.reporter?.first_name || '-'} {defect?.reporter?.last_name || null}
              </Label>
              {signature && <StyledImg alt="Signature" src={sigSource} />}
            </FlexWrapper>
            <FlexWrapper flexShrink="0">
              <Label fontWeight={500} marginRight={4}>
                {formatMessage({ id: 'form.labels.date' })}:
              </Label>
              <Label fontWeight={600}>{defect?.date || '-'}</Label>
            </FlexWrapper>
          </FlexWrapper>
          {defect?.editor ? (
            <>
              <BorderLine />
              <FlexWrapper
                justifyContent="space-between"
                alignItems="center"
                marginTop={20}
                marginBottom={20}
                height={40}
              >
                <FlexWrapper alignItems="center">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'text.reportedBy' })}
                  </Label>
                  <Label fontWeight={600} marginRight={15}>
                    {defect?.reporter?.first_name || '-'} {defect?.reporter?.last_name || null}
                  </Label>
                  {signature && <StyledImg alt="Signature" src={sigSource} />}
                </FlexWrapper>
                <FlexWrapper flexShrink="0">
                  <Label fontWeight={500} marginRight={4}>
                    {formatMessage({ id: 'form.labels.date' })}:
                  </Label>
                  <Label fontWeight={600}>{defect?.date || '-'}</Label>
                </FlexWrapper>
              </FlexWrapper>
            </>
          ) : null}
          {defect?.mx_events && defect?.mx_events.length > 0 ? (
            <>
              <BorderLine />
              <FlexWrapper column>
                <Label marginBottom={20} marginTop={30} fontWeight={600}>
                  {formatMessage({ id: 'title.resolutionDetails' })}
                </Label>
                <GridWrapper rowGap={20} columns="1fr 1fr 1fr 1fr" rows="1fr 1fr">
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.engineer' })}
                    </Label>
                    <span>
                      {lastMXEvent?.first_name || '-'} {lastMXEvent?.last_name || null}
                    </span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'title.licenceAuthorisationNo' })}.
                    </Label>
                    <span>{lastMXEvent?.licence_number || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'form.labels.part145Organisation' })}
                    </Label>
                    <span>{lastMXEvent?.company_name || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper column>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'title.licenceAuthorisationNo' })}.
                    </Label>
                    <span>{lastMXEvent?.approval_number || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper column colSpan={2}>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'text.purchaseOrder' })}
                    </Label>
                    <span>{lastMXEvent?.workpack_reference || '-'}</span>
                  </FlexWrapper>
                  <FlexWrapper column colSpan={2}>
                    <Label fontSize="12" textTransform="uppercase" marginBottom={5} color="rgba(36, 45, 65, 0.4)">
                      {formatMessage({ id: 'form.labels.attachments' })}.
                    </Label>
                    <FileViewer
                      attachments={lastMXEvent?.attachments}
                      handleDeleteClick={handleDelete}
                      addAttachment={handleAttachmentUpload}
                      allowDelete={editDefect}
                    />
                  </FlexWrapper>
                </GridWrapper>
                <FlexWrapper
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop={20}
                  marginBottom={20}
                  height={40}
                >
                  <FlexWrapper alignItems="center">
                    <Label fontWeight={500} marginRight={4}>
                      {formatMessage({ id: 'title.resolvedBy' })}:
                    </Label>
                    <Label fontWeight={600} marginRight={15}>
                      {lastMXEvent?.first_name || '-'} {lastMXEvent?.last_name || null}
                    </Label>
                    {lastMXEvent?.signature_image_url && (
                      <StyledImg alt="Signature" src={`${servers.api}${lastMXEvent?.signature_image_url}`} />
                    )}
                  </FlexWrapper>
                  <FlexWrapper flexShrink="0" marginRight={30}>
                    <Label fontWeight={500} marginRight={4}>
                      {formatMessage({ id: 'title.resolvedOn' })}:
                    </Label>
                    <Label fontWeight={600}>
                      {lastMXEvent?.date ? moment(lastMXEvent?.date).format('YYYY-MM-DD') : '-'}
                    </Label>
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
            </>
          ) : null}
        </>
      )}
    </Card>
  );
};

export default DefectDetails;
