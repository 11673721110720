/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import SearchBox from '../../TFSearch/searchBox';
import styles from '../workpackDrawer.module.less';
import Defect from './Defect';
import ScheduledMX from './ScheduledMX';

const MapWorkpackItems = ({ itemsToDisplay, mode, removeWPItem }): JSX.Element => {
  const itemIsDefect = (wpItem): boolean => {
    return (
      wpItem &&
      wpItem.mx_item &&
      wpItem.mx_item &&
      wpItem.mx_item &&
      wpItem.mx_item.type &&
      wpItem.mx_item.type.toLowerCase() === 'defect'
    );
  };

  return (
    (itemsToDisplay &&
      itemsToDisplay
        .sort((a, b) => {
          if (a.mx_item.status === 'resolved' && b.mx_item.status !== 'resolved') return 1;
          if (b.mx_item.status === 'resolved' && a.mx_item.status !== 'resolved') return -1;
          return 0;
        })
        .map((wpItem) => {
          return itemIsDefect(wpItem) ? (
            <Defect item={wpItem} key={wpItem.mx_item_id} mode={mode} removeWPItem={removeWPItem} />
          ) : (
            <ScheduledMX item={wpItem} key={wpItem.mx_item_id} mode={mode} removeWPItem={removeWPItem} />
          );
        })) ||
    null
  );
};

const ViewWorkpackItems = ({ wpItems, mode, removeWPItem }): JSX.Element => {
  const [itemsToDisplay, updateItemsToDisplay] = useState(wpItems);

  useEffect(() => {
    updateItemsToDisplay(wpItems);
  }, [wpItems]);

  const searchCallback = useCallback((value): void => {
    if (value) {
      const isDefectWithDetails = (mx_item): boolean =>
        mx_item && mx_item.details && mx_item.details.toLowerCase().includes(value.toLowerCase());

      const isMXItemWithName = (mx_item): boolean =>
        mx_item && mx_item.name && mx_item.name.toLowerCase().includes(value.toLowerCase());

      const isMxItemWithMelData = (mx_item): boolean =>
        mx_item &&
        mx_item.display_data &&
        mx_item.display_data.ata &&
        mx_item.display_data.ata.toLowerCase().includes(value.toLowerCase());

      const results = itemsToDisplay.filter(
        (item) =>
          isDefectWithDetails(item.mx_item) || isMXItemWithName(item.mx_item) || isMxItemWithMelData(item.mx_item),
      );

      if (results.length > 0) {
        updateItemsToDisplay(results);
      } else {
        updateItemsToDisplay([]);
      }
    } else if (value.length === 0) {
      updateItemsToDisplay(wpItems);
    }
  }, []);

  return (
    <div className={styles.column}>
      <div className={styles.minorHeading}>
        <h4>Items in this workpack</h4>
        <SearchBox searchCallback={searchCallback} />
      </div>
      <MapWorkpackItems itemsToDisplay={itemsToDisplay} mode={mode} removeWPItem={removeWPItem} />
    </div>
  );
};

export default ViewWorkpackItems;
