/* eslint-disable react/jsx-props-no-spreading */
import { Affix, Button, Card, Col, Icon, Row, Spin, Upload, message } from 'antd';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Magnifier from '../Magnifier';
import styles from './index.module.less';

const { Dragger } = Upload;

class MagnifierSplitView extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    draggerProps: PropTypes.object,
    file: PropTypes.object,
    intl: PropTypes.object.isRequired,
    leftCol: PropTypes.object,
    loading: PropTypes.bool,
    offsetTop: PropTypes.number,
    onChangeSplit: PropTypes.func,
    onUpload: PropTypes.func.isRequired,
    split: PropTypes.bool,
  };

  static defaultProps = {
    draggerProps: {},
    file: undefined,
    leftCol: null,
    loading: false,
    offsetTop: 24,
    onChangeSplit: null,
    split: undefined,
  };

  constructor(props) {
    super(props);
    this.affixRef = React.createRef();
    this.magnifierRef = React.createRef();
    this.state = {
      splitView: true,
      file: null,
      leftColAffixed: false,
      magnifierHeight: this.calcMagnifierHeight(),
    };
  }

  static getDerivedStateFromProps(props) {
    const newState = {};
    if (typeof props.file !== 'undefined') {
      newState.file = props.file;
    }
    if (typeof props.split !== 'undefined') {
      newState.splitView = props.split;
      if (!props.split) {
        newState.leftColAffixed = false;
      }
    }
    return newState;
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  calcMagnifierHeight = () => {
    return window.innerHeight - 150 - this.props.offsetTop;
  };

  handleResize = () => {
    this.setState({ magnifierHeight: this.calcMagnifierHeight() });
    if (!this.affixRef.current) return;
    this.affixRef.current.updatePosition();
  };

  handleFileUpload = ({ file, fileList }) => {
    const { onUpload } = this.props;
    if (this.lastFileListItem && this.lastFileListItem.uid === fileList[fileList.length - 1].uid) {
      // To stop from executing per file
      return;
    }
    this.lastFileListItem = fileList[fileList.length - 1];

    if (!('file' in this.props)) {
      this.setState({ file: file.originFileObj });
    }

    if (onUpload) {
      onUpload(fileList);
    }
  };

  changeSplit = (split) => {
    const { onChangeSplit, split: splitProp } = this.props;
    if (typeof splitProp === 'undefined') {
      this.setState((state) => ({
        splitView: split,
        leftColAffixed: split ? state.leftColAffixed : false,
      }));
    }
    if (onChangeSplit) onChangeSplit(split);
  };

  toggleSplitView = () => {
    this.changeSplit(!this.state.splitView);
  };

  handleLoadError = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    message.error(formatMessage({ id: 'message.filePreviewFailed' }));
    this.setState({ file: null });
  };

  render() {
    const {
      offsetTop,
      draggerProps,
      leftCol,
      loading,
      children,
      intl: { formatMessage },
    } = this.props;
    const { splitView, leftColAffixed, file, magnifierHeight } = this.state;
    const loadingSpinner = (
      <Spin
        style={{ width: '100%', margin: '40px 0' }}
        indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />}
      />
    );

    let displayComponent;
    if (loading) {
      displayComponent = loadingSpinner;
    } else if (file == null) {
      displayComponent = (
        <Dragger
          onChange={(e) => this.handleFileUpload(e)}
          beforeUpload={() => false}
          multiple
          {...draggerProps}
          accept={['.jpg', '.jpeg', '.pdf', '.png'].join(',')}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="file" />
          </p>
          <p className="ant-upload-text">{formatMessage({ id: 'text.uploadAttachmentToView' })}</p>
          <p className="ant-upload-hint">{formatMessage({ id: 'text.clickOrDragFile' })}</p>
        </Dragger>
      );
    } else {
      displayComponent = (
        <Magnifier
          ref={this.magnifierRef}
          file={file}
          loading={loadingSpinner}
          onLoadError={this.handleLoadError}
          height={magnifierHeight}
        />
      );
    }
    return (
      <Row gutter={12}>
        {splitView && (
          <Affix
            ref={this.affixRef}
            onChange={(value) => this.setState({ leftColAffixed: value })}
            offsetTop={offsetTop}
          >
            <Col xs={0} sm={12}>
              <Card bordered={false}>
                <div className={styles.leftCol}>{leftCol}</div>
                {/*  */}
                {displayComponent}
              </Card>
            </Col>
          </Affix>
        )}
        <Col
          xs={24}
          sm={{
            span: splitView ? 12 : 24,
            offset: leftColAffixed ? 12 : 0,
          }}
        >
          <Card bordered={false}>
            <Button className={styles.viewButton} onClick={this.toggleSplitView}>
              {splitView ? formatMessage({ id: 'text.hideAttachment' }) : formatMessage({ id: 'text.showAttachment' })}
            </Button>
            {children}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default injectIntl(MagnifierSplitView);
