import PropTypes from 'prop-types';
import React from 'react';
import styles from './modernPageHeader.module.less';

const ModernPageHeader = ({ title, action, content }) => {
  return (
    <>
      <div className={styles.pageHeader}>
        <div className={styles.detail}>
          <div className={styles.main}>
            <div className={styles.row}>
              <div className={styles.colTitle}>
                {title && (
                  <h1 className={styles.title} data-test="pageTitle">
                    {title}
                  </h1>
                )}
                {content && <div className={styles.content}>{content}</div>}
              </div>
              <div className={styles.colAction}>{action && <div className={styles.action}>{action}</div>}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ModernPageHeader.defaultProps = {
  title: '',
  action: '',
  content: '',
};

ModernPageHeader.propTypes = {
  title: PropTypes.string,
  action: PropTypes.node,
  content: PropTypes.string,
};

export default ModernPageHeader;
