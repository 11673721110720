import React from 'react';
import { Row, Col, Carousel } from 'antd';
import PropTypes from 'prop-types';
import AircraftDetails from './AircraftDetails';
import MXWidget from './MXWidget';
import styles from './index.module.less';

const AircraftOverview = ({ aircraft, widgets }) => {
  return (
    <>
      <Row gutter={16} className={styles.aircraftDetailsRow} data-testid="aircraftOverview">
        <Col md={6} className={styles.aircraftDetailsCol}>
          <AircraftDetails aircraft={aircraft} data-test="aircraftDetails" />
        </Col>
        <Col md={18} className={styles.aircraftWidgetsCol}>
          <Row gutter={16} className={styles.aircraftWidgets}>
            {widgets &&
              widgets.map((item) => {
                return (
                  <Col className={styles.aircraftWidgetStandard} key={`mxWidget${item.title}`}>
                    <MXWidget
                      mxObj={item.mxObj}
                      title={item.title}
                      circleUnit={item.circleUnit}
                      format={item.format}
                      showTotal={item.showTotal}
                      showNextDue={item.showNextDue}
                      active={item.active}
                      data-test="mxWidget"
                    />
                  </Col>
                );
              })}
            <Col md={24} className={styles.aircraftWidgetsCarousel}>
              <Carousel>
                <div className={`${styles.aircraftWidgets} ${styles.aircraftWidgetCarousel}`}>
                  {widgets &&
                    widgets.length > 3 &&
                    widgets.slice(0, window.innerWidth === 320 ? 2 : 3).map((item) => {
                      return (
                        <Col key={`mxWidgetCarousel${item.title}`}>
                          <MXWidget
                            mxObj={item.mxObj}
                            title={item.title}
                            circleUnit={item.circleUnit}
                            format={item.format}
                            showTotal={item.showTotal}
                            showNextDue={item.showNextDue}
                            active={item.active}
                          />
                        </Col>
                      );
                    })}
                </div>
                <div className={`${styles.aircraftWidgets} ${styles.aircraftWidgetCarousel}`}>
                  {widgets &&
                    widgets.length > 3 &&
                    widgets.slice(window.innerWidth === 320 ? 2 : 3, window.innerWidth === 320 ? 4 : 6).map((item) => {
                      return (
                        <Col key={`mxWidgetCarouselSlice${item.title}`}>
                          <MXWidget
                            mxObj={item.mxObj}
                            title={item.title}
                            circleUnit={item.circleUnit}
                            format={item.format}
                            showTotal={item.showTotal}
                            showNextDue={item.showNextDue}
                          />
                        </Col>
                      );
                    })}
                </div>
                {window.innerWidth === 320 ? (
                  <div className={`${styles.aircraftWidgets} ${styles.aircraftWidgetCarousel}`}>
                    {widgets &&
                      widgets.length > 3 &&
                      widgets.slice(4, 6).map((item) => {
                        return (
                          <Col key={`mxWidgetCarouselSlice${item.title}`}>
                            <MXWidget
                              mxObj={item.mxObj}
                              title={item.title}
                              circleUnit={item.circleUnit}
                              format={item.format}
                              showTotal={item.showTotal}
                              showNextDue={item.showNextDue}
                            />
                          </Col>
                        );
                      })}
                  </div>
                ) : null}
              </Carousel>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

AircraftOverview.propTypes = {
  aircraft: PropTypes.object.isRequired,
  widgets: PropTypes.array.isRequired,
};

export default AircraftOverview;
