/* eslint-disable no-unused-expressions */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getDefectForDrawer, update, submitDefect } from '../../models/defects/actions';
import { DashboardState } from '../../models';
import Loading from '../TFLoading/index';
import Message from '../Message/Message';
import ResolutionDetails from './components/ResolutionDetails';
import AdditionalInformation from './components/AdditionalInformation';
import AircraftLimitations from './components/AircraftLimitations';
import ButtonSection from './components/ButtonSection';
import Card from './components/Card';
import ConfirmSection from './components/ConfirmSection';
import DefectDetails from './components/DefectDetails';
import DefectHeader from './components/DefectHeader';
import DeferralOptions from './components/DeferralOptions';
import SignatureSection from './components/SignatureSection';

interface DefectDrawerProps {
  defectId: string;
  editDefect: boolean;
  addDefect: boolean;
  userSettings: any;
  handleCancelClick: () => void;
  handleSubmitClick: (input?: any) => void;
  handleEditClick: (id: string) => void;
  controller: any;
  melItemController: any;
  defectTypeChange: () => void;
  acId: string;
}

const DrawerWrapper = styled.div`
  background-image: linear-gradient(to left, #f4f6f9, #fbfcfc, #fbfcfc);
  padding: 35px 35px 35px 45px;
  min-height: calc(100vh - 24px);
  height: 100%;
`;

const DefectDrawer: React.FC<DefectDrawerProps> = ({
  defectId,
  acId,
  editDefect,
  addDefect,
  handleCancelClick,
  handleSubmitClick,
  handleEditClick,
  controller,
  melItemController,
  defectTypeChange,
}) => {
  const [signature, setSignature] = useState('');
  const [rectificationCategory, setRectificationCategory] = useState({ title: 'A' });
  const [status, setStatus] = useState('draft');
  const [date, setDate] = useState(moment());
  const [flightId, setFlightId] = useState(null);
  const [aircraftId, setAircraftId] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [melItem, setMelItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [melItemsLoading, setMelItemsLoading] = useState(false);
  const [confirmationChecks, setConfirmationChecks] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [rectificationId, setRectificationId] = useState('');
  const [melItemId, setMelItemId] = useState('');
  const [defectType, setDefectType] = useState('MEL');
  const [formChanged, setFormChanged] = useState(false);

  const dispatch = useDispatch();
  let defect = null;
  const { drawerDefect } = useSelector((state: DashboardState) => state.defects);
  if (defectId) {
    defect = drawerDefect;
  }
  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDefectsMap = usePrevious(drawerDefect);

  const handleSignature = (input: string): void => {
    if (signature !== '') setFormChanged(true);
    setSignature(input);
  };

  useEffect(() => {
    if (melItem) {
      setConfirmationChecks([]);
      let foundRect = melItem?.mel_rectifications[0];
      if (rectificationId) {
        foundRect = melItem?.mel_rectifications.find((rec) => rec.id === rectificationId);
      }
      if (foundRect?.remarks) {
        confirmationChecks.push('remarks');
      }
      if (foundRect?.placards) {
        confirmationChecks.push('placards');
      }
      if (foundRect?.operational_procedures) {
        confirmationChecks.push('operations');
      }
      setConfirmationChecks(confirmationChecks);
    } else if (defect?.mel_item) {
      setConfirmationChecks([]);
      let foundRect = defect?.mel_item?.mel_rectifications && defect?.mel_item?.mel_rectifications[0];
      if (rectificationId) {
        foundRect = defect?.mel_item?.mel_rectifications.find((rec) => rec.id === rectificationId);
      }
      if (foundRect?.remarks) {
        confirmationChecks.push('remarks');
      }

      if (foundRect?.placard_procedure) {
        confirmationChecks.push('placards');
      }

      if (foundRect?.operational_procedure) {
        confirmationChecks.push('operations');
      }
      setConfirmationChecks(confirmationChecks);
    }
  }, [confirmationChecks, defect, melItem, rectificationId]);

  const checkAllConfirmed = (payloadKeys, payload): boolean => {
    let allChecked = true;
    if (payload.defectTypeGroup === 'MEL' && confirmationChecks.length > 0) {
      if (confirmationChecks.includes('remarks') && !payloadKeys.includes('RemarksChecked')) {
        setErrorMessage('Remarks needs checked');
        allChecked = false;
      }
      if (confirmationChecks.includes('placards') && !payloadKeys.includes('PlacardsChecked')) {
        setErrorMessage('Placards needs checked');
        allChecked = false;
      }
      if (confirmationChecks.includes('operations') && !payloadKeys.includes('OperationalProceduresChecked')) {
        setErrorMessage('Operational Procedures needs checked');
        allChecked = false;
      }
    }
    return allChecked;
  };

  const handleFormSubmit = (e): void => {
    e.preventDefault();
    document.getElementById('tfDrawerWrapper').scrollTop = 0;
    const formData = new FormData(e.target as HTMLFormElement);
    const payload = {} as any;
    const payloadKeys = [];
    formData.forEach((value, key) => {
      payload[key] = value;
      payloadKeys.push(key);
    });
    if (checkAllConfirmed(payloadKeys, payload)) {
      const deferred = payload.deferGroup === 'deferred';
      const defectPayload: any = {
        body: {
          flight_id: flightId,
          details: payload?.notes,
          date: date.format('YYYY-MM-DD'),
          attachments_attributes: attachments,
          deferred,
          defect_type: payload?.defectTypeGroup,
          mel_item_id: melItemId,
          mel_rectification_id: rectificationId,
          ata_chapter: payload?.cdlATAChapter,
          ata_section: payload?.cdlATASection,
          rectification_category: rectificationCategory.title,
          defer_approval_reference: payload?.deferApprovalRef,
          reason_to_defer: payload?.reasonToDefer,
          approval_signature_attributes: { data: signature },
          flight_seconds_limit: payload?.flightHoursLimit,
          seconds_limit: payload?.hoursLimit,
          flight_days_limit: payload?.flightDaysLimit,
          cycles_limit: payload?.cyclesDaysLimit,
          flights_limit: payload?.flightLimit,
          calendar_days_limit: payload?.calendarDaysLimit,
          apu_seconds_limit: payload?.APUHoursLimit,
        },
      };
      let newDefect = null;
      if (defectId) {
        defectPayload.id = defectId;
        defectPayload.body.aircraft_id = aircraftId || acId;
        setLoading(true);
        dispatch(update({ payload: defectPayload }));
        handleSubmitClick(defect);
        setFormChanged(false);
      } else {
        defectPayload.body.id = aircraftId || acId;
        setLoading(true);
        const res = dispatch(submitDefect({ payload: defectPayload }));
        Promise.all([res]).then((values) => {
          // eslint-disable-next-line prefer-destructuring
          newDefect = values[0];
          handleSubmitClick(newDefect[0]);
          setFormChanged(false);
        });
      }
    }
  };

  const handleFormChange = (): void => {
    setFormChanged(true);
  };

  const handleHeaderDetailsChange = ({ newDate, newFlightId, newAircraftId }): void => {
    if (newDate.format('YYYY-MM-DD') !== date.format('YYYY-MM-DD')) {
      setDate(newDate);
      if (date.format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
        setFormChanged(true);
      }
    }
    if (newFlightId && flightId && newFlightId !== flightId) {
      setFlightId(newFlightId);
      if (flightId !== '') {
        setFormChanged(true);
      }
    }
    if (newAircraftId && newAircraftId !== aircraftId) {
      setAircraftId(newAircraftId);
      if (aircraftId !== null) {
        setFormChanged(true);
      }
    }
  };

  const handleMelItemChange = (newMelId: string, rectId: string): void => {
    setMelItemId(newMelId);
    setRectificationId(rectId);
    setFormChanged(true);
  };

  const cancelClick = (): void => {
    handleCancelClick();
    setErrorMessage('');
    setSignature('');
    setFormChanged(false);
  };

  useEffect(() => {
    if (!_.isEqual(defect, prevDefectsMap)) {
      setLoading(false);
    }
  }, [defect, prevDefectsMap]);

  useEffect(() => {
    if (defectId && defectId !== defect?.id) {
      const payload = { id: defectId, signal: controller.signal };
      setLoading(true);
      dispatch(getDefectForDrawer(payload));
    }
  }, [controller, defect, defectId, dispatch]);

  useEffect(() => {
    if (defect) {
      setStatus(defect?.status);
      setFlightId(defect?.flight_id);
      setSignature(defect?.approval_signature_image_url);
    }
  }, [defect]);

  useEffect(() => {
    if (loading) {
      document?.getElementById('tfDrawerWrapper')?.setAttribute('style', 'overflow: hidden');
    } else {
      document?.getElementById('tfDrawerWrapper')?.setAttribute('style', 'overflow: scroll');
    }
  }, [loading]);

  return (
    <DrawerWrapper data-testid="DefectDrawer--DrawerWrapper">
      <Loading loading={loading} contain />
      <form onSubmit={handleFormSubmit} onChange={handleFormChange}>
        <DefectHeader
          defect={defect}
          editDefect={editDefect}
          addDefect={addDefect}
          handleEditClick={handleEditClick}
          status={status}
          date={date}
          flightId={flightId}
          handleHeaderDetailsChange={handleHeaderDetailsChange}
          controller={controller}
        />
        <DefectDetails
          defect={defect}
          editDefect={editDefect}
          signature={signature}
          attachments={attachments}
          setAttachments={setAttachments}
        />
        {editDefect && (
          <>
            <DeferralOptions
              defect={defect}
              editDefect={editDefect}
              rectificationCategory={rectificationCategory}
              setRectificationCategory={setRectificationCategory}
              controller={melItemController}
              aircraftId={aircraftId || acId}
              defectTypeChange={defectTypeChange}
              setMelItem={setMelItem}
              melItemsLoading={melItemsLoading && !loading}
              setMelItemsLoading={setMelItemsLoading}
              handleMelItemChange={handleMelItemChange}
              defectType={defectType}
              setDefectType={setDefectType}
            />
            <Card marginTop={30}>
              <AircraftLimitations defect={defect} editDefect={editDefect} />
            </Card>
            <Card marginTop={30}>
              <AdditionalInformation defect={defect} />
            </Card>
            {defectType === 'MEL' && (
              <ConfirmSection defect={defect} melItem={melItem} setErrorMessage={setErrorMessage} />
            )}
            {defect?.mx_events.length > 0 ? <ResolutionDetails defect={defect} /> : null}
            <SignatureSection
              handleSignature={handleSignature}
              signature={signature}
              formChanged={formChanged}
              addDefect={addDefect}
            />
            <ButtonSection handleCancel={cancelClick} loading={melItemsLoading || loading} disabled={!signature} />
          </>
        )}
      </form>
      <Message text={errorMessage} clearText={(): void => setErrorMessage('')} />
    </DrawerWrapper>
  );
};

export default DefectDrawer;
