import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getSingleAircraft } from '../../models/aircraft/actions';
import defaults from '../../utils/defaults';
import styles from './AircraftSettings.module.less';

class ThresholdSettingsDetails extends Component {
  static propTypes = {
    fetchAircraft: PropTypes.func.isRequired,
    aircraftMap: PropTypes.instanceOf(Map),
    id: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    aircraftMap: new Map(),
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const aircraft = this.getAircraft();
    if (!aircraft || Date.now() - aircraft.lastFetched > 30000) {
      this.getAircraft(true);
    }
  }

  componentDidUpdate(prevProps) {
    // if the aircraft has changed reset the from
    const { id } = this.props;
    const { loading } = this.state;
    if (id !== prevProps.id) {
      const aircraft = this.getAircraft();
      if (!aircraft || Date.now() - aircraft.lastFetched > 30000) {
        this.getAircraft(true);
      }
    }
    // if the aircaft has been updated reset the form
    const aircraft = this.getAircraft();
    const prevAircraft = prevProps.aircraftMap.get(prevProps.id);
    if (loading && prevAircraft && aircraft.lastFetched !== prevAircraft.lastFetched) {
      this.onLoaded();
    }
  }

  onLoaded = () => {
    this.setState({ loading: false });
  };

  getAircraft = (forceRefetch = false) => {
    if (forceRefetch) {
      this.setState({ loading: true }, () => this.props.fetchAircraft(this.props.id));
    }
    return this.props.aircraftMap.get(this.props.id);
  };

  thresholdDetailsForm = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const selectedAircraft = this.getAircraft();
    let mxThresholds = {};
    if (selectedAircraft && Object.keys(selectedAircraft.mx_thresholds).length === 0) {
      mxThresholds = defaults.defaultMXThresholds;
    } else {
      mxThresholds = selectedAircraft ? selectedAircraft.mx_thresholds : defaults.defaultMXThresholds;
    }

    let displayAPUColumn = null;
    if (selectedAircraft) {
      if (selectedAircraft.apu_installed === true) {
        displayAPUColumn = (
          <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
            <Form.Item
              className={styles.valueInputDiv}
              labelAlign="left"
              label={formatMessage({ id: 'period.APUhours' })}
              data-test="formThresholdAPUHours"
            >
              <span data-test="thresholdAPUHours" className={styles.editableCellCapacityInput}>
                {mxThresholds.apu_seconds / 3600}
              </span>
            </Form.Item>
          </Col>
        );
      }
    }

    return (
      <Form className={styles.detailsForm}>
        <Row gutter={24}>
          <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
            <Form.Item
              data-test="formThresholdDays"
              className={styles.valueInputDiv}
              labelAlign="left"
              label={formatMessage({ id: 'period.days' })}
            >
              <span data-test="thresholdDays" className={styles.editableCellCapacityInput}>
                {mxThresholds.days}
              </span>
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
            <Form.Item
              className={styles.valueInputDiv}
              labelAlign="left"
              label={formatMessage({ id: 'period.Cycles' })}
              data-test="formThresholdCycles"
            >
              <span data-test="thresholdCycles" className={styles.editableCellCapacityInput}>
                {mxThresholds.cycles}
              </span>
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
            <Form.Item
              className={styles.valueInputDiv}
              labelAlign="left"
              label={formatMessage({ id: 'period.flights' })}
              data-test="formThresholdFlights"
            >
              <span data-test="thresholdFlights" className={styles.editableCellCapacityInput}>
                {mxThresholds.flights}
              </span>
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
            <Form.Item
              className={styles.valueInputDiv}
              labelAlign="left"
              label={formatMessage({ id: 'period.landings' })}
              data-test="formThresholdLandings"
            >
              <span data-test="thresholdLandings" className={styles.editableCellCapacityInput}>
                {mxThresholds.landings}
              </span>
            </Form.Item>
          </Col>
          {displayAPUColumn}
          <Col xs={12} sm={8} md={8} lg={6} xl={12} xxl={8}>
            <Form.Item
              className={styles.valueInputDiv}
              labelAlign="left"
              label={formatMessage({ id: 'period.fltHours' })}
              data-test="formThresholdFlightHours"
            >
              <span data-test="thresholdFlightHours" className={styles.editableCellCapacityInput}>
                {mxThresholds.flight_seconds / 3600}
              </span>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    return this.thresholdDetailsForm();
  }
}

export default injectIntl(
  connect(
    ({ aircraft, menu }) => ({
      aircraftMap: aircraft.aircraftMap,
      collapsed: menu.collapsed,
    }),
    (dispatch) => ({
      fetchAircraft: (id) => {
        return dispatch(
          getSingleAircraft({
            payload: id,
          }),
        );
      },
    }),
  )(ThresholdSettingsDetails),
);
