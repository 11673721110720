/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAircraft } from '../../models/aircraft/actions';
import { AircraftPermission } from '../../models/aircraft';
import doesUserHaveAcPermission from '../_utils/DoesUserHaveAcPermission';
import styles from './index.module.less';

const { Option } = Select;

const SelectAircraft = (props, ref) => {
  // Partially de-structure props so we can use these more effectively in `useEffect` and `useMemo`.
  const { aircraftMap, aircraftLastFetched, aircraftTtl, loggedInUser } = useSelector(({ aircraft, userSettings }) => ({
    aircraftMap: aircraft.aircraftMap,
    aircraftLastFetched: aircraft.lastFetched,
    aircraftTtl: aircraft.ttl,
    loggedInUser: userSettings && userSettings.details && userSettings.details.people,
  }));

  const dispatch = useDispatch();
  const refetchAircraft = useCallback(() => dispatch(getAllAircraft()), [dispatch]);
  // Internal loading state.
  const [loading, setloading] = useState(true);
  // Intl
  const { formatMessage } = useIntl();

  const { requiredResource } = props;
  // Dropdown options, which is only re-calculated when the `aircraftMap` gets refetched.
  const options = useMemo(
    () =>
      Array.from(aircraftMap.values())
        .filter((item) =>
          doesUserHaveAcPermission({
            acId: item.id,
            requiredResource,
            loggedInUser,
            requiredPermissionLevel: AircraftPermission.CREATE,
          }),
        )
        .map((item) => <Option key={item.id}>{item.registration}</Option>),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aircraftMap],
  );
  // Controls the loading flag and determines when to refetch `aircraftMap`
  useEffect(() => {
    if (loading && Date.now() - aircraftLastFetched > aircraftTtl) {
      refetchAircraft();
    } else if (loading) {
      setloading(false);
    }
  }, [loading, refetchAircraft, aircraftMap, aircraftLastFetched, aircraftTtl]);

  return (
    <Select
      className={styles.selectAircraft}
      placeholder={formatMessage({ id: 'form.placeholder.selectAircraft' })}
      showSearch
      optionFilterProp="children"
      notFoundContent={formatMessage({ id: 'text.noAircraftFound' })}
      loading={loading}
      {...props}
      ref={ref}
    >
      {options}
    </Select>
  );
};

export default React.forwardRef(SelectAircraft);
