import React from 'react';
import styled from 'styled-components';
import { DashboardResource, UserPermission } from '../../models/userSettings';
import TFButton from '../TFButton/TFButton';
import AuthenticationWrapper from '../_utils/AuthenticationWrapper';

interface DefectTableHeaderProps {
  defectCount: number;
  addNewDefect: () => void;
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 10px;
`;

const DefectCount = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #242d41;
  span {
    font-weight: 400;
  }
`;

const DefectTableHeader: React.FC<DefectTableHeaderProps> = ({ defectCount, addNewDefect }) => {
  return (
    <HeaderWrapper>
      <DefectCount>Defects {defectCount && <span>{`(${defectCount})`}</span>}</DefectCount>
      <AuthenticationWrapper
        requiredResource={DashboardResource.DEFECT}
        requiredPermissionLevel={UserPermission.CREATE}
      >
        <TFButton skinny padding="0 15px" handleClick={addNewDefect}>
          {window.innerWidth < 400 ? '+' : '+ Add a defect'}
        </TFButton>
      </AuthenticationWrapper>
    </HeaderWrapper>
  );
};

export default DefectTableHeader;
