import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { injectIntl } from 'react-intl';
import ModernPagination from '../../../../ModernPagination';
import ItemWrapper from './ItemWrapper';

const ListWrapper = ({ items, onSuccess, intl: { formatMessage }, aircraft, handleSubmittingChange }) => {
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedItems, setPaginatedItems] = useState([]);

  useEffect(() => {
    const allItems = items;
    const maxPageSize = pageSize <= allItems.length ? pageSize : allItems.length;
    let firstItemIndex = (currentPage - 1) * maxPageSize;
    if (firstItemIndex >= allItems.length) {
      firstItemIndex = allItems.length - maxPageSize;
    }
    const compare = (a, b) => {
      if (a.date_due < b.date_due) {
        return -1;
      }
      if (a.date_due > b.date_due) {
        return 1;
      }
      return 0;
    };
    allItems.sort(compare);

    setPaginatedItems(allItems.slice(firstItemIndex, firstItemIndex + pageSize));
  }, [items, pageSize, currentPage]);

  return (
    <>
      {paginatedItems && paginatedItems.length > 0 ? (
        <>
          {paginatedItems.map((item) => (
            <ItemWrapper
              onSuccess={() => onSuccess()}
              item={item}
              key={item.id}
              aircraft={aircraft}
              data-test="itemWrapper"
              handleSubmittingChange={handleSubmittingChange}
            />
          ))}
          <ModernPagination
            key="modernPagination"
            pageSize={pageSize}
            current={currentPage}
            total={items.length}
            onPageNoChange={(page) => setCurrentPage(page)}
            onPageSizeChange={(size) => setPageSize(size)}
          />
        </>
      ) : (
        <Empty description={formatMessage({ id: 'text.noItemsFoundMX' })} data-test="empty" />
      )}
    </>
  );
};

ListWrapper.propTypes = {
  items: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  aircraft: PropTypes.object.isRequired,
  handleSubmittingChange: PropTypes.func.isRequired,
};

export default injectIntl(ListWrapper);
