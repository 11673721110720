import React from 'react';
import { Modal, Row, Col, Input, message, Button } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import globalStyles from '../../../utils/globalStyles.module.less';
import bigIcon from './assets/share-link.svg';
import smallIcon from './assets/link.svg';
import cancelIcon from './assets/cancel.svg';
import styles from './SharingLink.module.less';

const SharingLinkModal = ({
  title,
  description,
  url,
  visible,
  buttonText,
  handleModalVisible,
  intl: { formatMessage },
}) => {
  const hideModal = () => {
    if (handleModalVisible) handleModalVisible();
  };

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.success('Link copied to clipboard');
  };

  return (
    <>
      <Modal
        onCancel={hideModal}
        visible={visible}
        className={styles.modal}
        footer={null}
        width={770}
        closable
        closeIcon={<img src={cancelIcon} alt="cancelIcon" className={styles.cancelIcon} />}
        destroyOnClose
      >
        <Row gutter={16} className={styles.modalParentRow}>
          <Col md={10}>
            <img src={bigIcon} alt="sharingBigIcon" className={styles.bigIcon} data-test="bigIcon" />
          </Col>
          <Col md={14}>
            <div className={styles.modalTitle} data-test="title">
              <img src={smallIcon} alt="sharingSmallIcon" className={styles.smallIcon} data-test="smallIcon" />
              {title || formatMessage({ id: 'title.sharingLink' })}
            </div>
            <div className={styles.modalDescription} data-test="description">
              {description || formatMessage({ id: 'text.validFor30days' })}
            </div>
            <div className={styles.inputWithButton}>
              <Input
                value={url}
                readOnly
                className={`${globalStyles.formInput} ${styles.modalInput}`}
                data-test="copyInput"
                spellCheck="false"
              />
              <Button
                type="primary"
                onClick={() => copyToClipboard(url)}
                className={styles.copyButton}
                data-test="copyButton"
              >
                {buttonText || formatMessage({ id: 'form.button.copy' })}
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

SharingLinkModal.defaultProps = {
  title: '',
  description: '',
  url: '',
  buttonText: '',
};

SharingLinkModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  handleModalVisible: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(SharingLinkModal);
