import { Button, DatePicker, Form, Icon, Input, Upload } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import defaults from '../../utils/defaults';
import SelectAircraft from '../SelectAircraft';
import SelectPerson from '../SelectPerson';
import { AircraftResource } from '../../models/aircraft';
import styles from './index.module.less';

const labelCol = {
  lg: { span: 24 },
  xl: { span: 8 },
};
const wrapperCol = {
  lg: { span: 24 },
  xl: { span: 16 },
};

class TripForm extends React.PureComponent {
  static propTypes = {
    aircraft: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    onAttachmentPreview: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    userSettings: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err) => {
      if (!err) {
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
      }
    });
  };

  render() {
    const {
      form,
      submitting,
      aircraft,
      onAttachmentPreview,
      intl: { formatMessage },
      userSettings,
    } = this.props;
    const { getFieldDecorator } = form;
    const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;
    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit} hideRequiredMark>
        {!aircraft.id && (
          <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.aircraft' })}>
            {getFieldDecorator('aircraft_id', {
              rules: [{ required: true, message: formatMessage({ id: 'message.noAircraftSelected' }) }],
            })(<SelectAircraft requiredResource={AircraftResource.TRIP} />)}
          </Form.Item>
        )}
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.callsign' })}>
          {getFieldDecorator('callsign', {
            rules: [{ required: true, message: formatMessage({ id: 'message.noCallsignSelected' }) }],
          })(
            <Input
              placeholder={formatMessage({ id: 'form.placeholder.registration' })}
              style={{ maxWidth: 120, width: '100%' }}
            />,
          )}
        </Form.Item>
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.date' })}>
          {getFieldDecorator('date', {
            rules: [{ required: true, message: formatMessage({ id: 'message.noDateSelected' }) }],
            getValueProps(date) {
              // Convert to moment object if not already
              return { value: date && moment(date) };
            },
          })(<DatePicker format={dateFormat} disabledDate={(cur) => cur && cur > moment.utc().add(1, 'd')} />)}
        </Form.Item>
        <Form.Item
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          label={formatMessage({ id: 'form.labels.pic' })}
          data-test="formItemPIC"
        >
          {getFieldDecorator('captain_id', {
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'message.noPilotInCommandSelected' }),
              },
            ],
          })(
            <SelectPerson
              placeholder={formatMessage({ id: 'form.placeholder.selectPilotInCommand' })}
              blockedPeople={[form.getFieldValue('first_officer_id')]}
              currentCrew={aircraft.aircraft_people}
              aircraft_id={form.getFieldValue('aircraft_id')}
              addPerson
              organisation={aircraft.operator_id}
            />,
          )}
        </Form.Item>
        <Form.Item
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          label={formatMessage({ id: 'form.labels.sic' })}
          data-test="formItemSIC"
        >
          {getFieldDecorator('first_officer_id')(
            <SelectPerson
              placeholder={formatMessage({ id: 'form.placeholder.selectSecondInCommand' })}
              blockedPeople={[form.getFieldValue('captain_id')]}
              currentCrew={aircraft.aircraft_people}
              aircraft_id={form.getFieldValue('aircraft_id')}
              addPerson
              allowClear
              organisation_id={aircraft.operator_id}
            />,
          )}
        </Form.Item>
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'title.attachments' })}>
          {getFieldDecorator('attachments', {
            getValueFromEvent(event) {
              return event.fileList;
            },
            // eslint-disable-next-line consistent-return
            getValueProps(value) {
              if (value) {
                return {
                  fileList: value.map((item) => ({
                    ...item,
                    uid: `${item.uid}upload`,
                  })),
                };
              }
            },
          })(
            <Upload
              className={styles.upload}
              beforeUpload={() => false}
              multiple
              onPreview={onAttachmentPreview}
              accept={['.jpg', '.jpeg', '.pdf', '.png'].join(',')}
            >
              <Button>
                <Icon type="upload" /> {formatMessage({ id: 'form.button.upload' })}
              </Button>
            </Upload>,
          )}
        </Form.Item>
        <Form.Item
          wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: { span: wrapperCol.span, offset: labelCol.span },
          }}
        >
          <Button type="primary" loading={submitting} htmlType="submit">
            {formatMessage({ id: 'form.button.continue' })}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default compose(
  injectIntl,
  connect(({ userSettings }, { aircraft }) => ({
    aircraft: aircraft || {},
    userSettings,
  })),
  Form.create({
    onValuesChange(props, _, allValues) {
      if (!props.onChange) return;
      props.onChange(allValues);
    },
    mapPropsToFields({ trip }) {
      return ['aircraft_id', 'date', 'callsign', 'captain_id', 'first_officer_id', 'attachments'].reduce(
        (formObj, fieldName) => ({
          ...formObj,
          [fieldName]: Form.createFormField({ value: trip && trip[fieldName] ? trip[fieldName] : undefined }),
        }),
        {},
      );
    },
  }),
)(TripForm);
