import React from 'react';
import PropTypes from 'prop-types';
import AircraftStatusTile from '../../components/AircraftStatusTile/AircraftStatusTile';

const AircraftOverviewTiles = ({ aircraft }) => {
  const tileArray = Object.entries(aircraft.tiles);
  return (
    <div>
      {tileArray.map((tile) => (
        <AircraftStatusTile tile={tile} id={aircraft.id} key={tile[0]} />
      ))}
    </div>
  );
};

AircraftOverviewTiles.propTypes = {
  aircraft: PropTypes.object.isRequired,
};

export default AircraftOverviewTiles;
