import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import React from 'react';
import * as Sentry from '@sentry/browser';
import * as Bowser from 'bowser';
import ReactDOM from 'react-dom';
import NotValidBrowser from './layouts/notValidBrowser';
import './index.module.less';
import IntlProvider from './providers/IntlProvider';
import ReduxProvider from './providers/ReduxProvider';
import RouterProvider from './providers/RouterProvider';
import * as serviceWorker from './serviceWorker';
import config from './utils/config';
import CustomThemeProvider from './providers/CustomThemeProvider';

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.getBrowserName() !== 'Internet Explorer';

let environment;

if (window.location.hostname === 'dashboard.trustflight.io') {
  environment = 'production';
}

if (window.location.hostname === 'dashboard.staging.trustflight.io') {
  environment = 'staging';
}

if (window.location.hostname === 'dashboard.dev.trustflight.io') {
  environment = 'develop';
}

if (!isValidBrowser) {
  ReactDOM.render(<NotValidBrowser browserName={browser.getBrowserName()} />, document.getElementById('root'));
} else {
  if (environment) {
    Sentry.init({ dsn: 'https://8fd83e51dbe141adbc5d26c46430fb0f@sentry.io/1819948', environment });
  }

  axios
    .get(`${process.env.PUBLIC_URL}/config.json`)
    .then((res) => {
      config.setConfig(res.data);
    })
    .catch(async (err) => {
      console.info('Failed to load config from server, will fallback to Production configuration', err);
    })
    // This should be a `.finally` but we still require our app to at least render for IE11.
    .then(() => {
      const App: React.SFC = () => {
        return (
          <ReduxProvider>
            <IntlProvider>
              <CustomThemeProvider>
                <RouterProvider />
              </CustomThemeProvider>
            </IntlProvider>
          </ReduxProvider>
        );
      };

      ReactDOM.render(<App />, document.getElementById('root'));
    });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
