import React from 'react';
import { Col, Row, Form } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import globalStyles from '../../utils/globalStyles.module.less';
import InlineSignature from '../InlineSignature';
import styles from './CRSSignature.module.less';

const CRSSignature = ({
  form,
  parentClearSignature,
  intl: { formatMessage },
  signoffAsEngineer,
  signatureStatement,
}) => {
  const setSignatureData = (isSignature, signatureData) => {
    // Antd Form doesn't like setting array of fields as values, so each field must be set separately
    form.setFieldsValue({ signature: isSignature });
    form.setFieldsValue({ signature_data: signatureData });
  };

  const { getFieldDecorator } = form;

  return (
    <>
      <Row gutter={16} className={styles.formRow}>
        <Col md={12}>
          <Form.Item className={styles.hiddenItem}>
            {getFieldDecorator('signature_data', {
              rules: [{ required: false }],
            })(<></>)}
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'form.labels.signature' })}
            className={globalStyles.formItem}
            data-test="formItemSignature"
          >
            {getFieldDecorator('signature', {
              rules: [{ required: true, message: formatMessage({ id: 'message.addSignature' }) }],
            })(
              <>
                <div className={`${globalStyles.formHelperText}`} data-test="formHelperSignature">
                  {signoffAsEngineer
                    ? signatureStatement || formatMessage({ id: 'form.helper.signatureTextEngineer' })
                    : formatMessage({ id: 'form.helper.signatureText' })}
                </div>
                <InlineSignature
                  signatureChanged={(isSignature, signatureData) => setSignatureData(isSignature, signatureData)}
                  parentClearSignature={parentClearSignature}
                />
              </>,
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

CRSSignature.defaultProps = {
  parentClearSignature: false,
};

CRSSignature.propTypes = {
  form: PropTypes.object.isRequired,
  parentClearSignature: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  signoffAsEngineer: PropTypes.bool.isRequired,
  signatureStatement: PropTypes.string.isRequired,
};
export default injectIntl(CRSSignature);
