import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import SignatureCanvas from '../SignatureCanvas';
import styles from './index.module.less';

class InlineSignature extends PureComponent {
  static propTypes = {
    signatureChanged: PropTypes.func.isRequired,
    parentClearSignature: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      canWidth: 600,
      canHeight: 600,
      canvasTouched: false,
    };
    this.signatureParent = React.createRef();
  }

  componentDidMount() {
    this.getSignatureCanvasWidth();
  }

  componentDidUpdate() {
    const { parentClearSignature } = this.props;
    if (parentClearSignature) {
      this.sigCanvas.clear();
    }
  }

  onSignatureBegin = () => {
    this.setState({
      canvasTouched: true,
    });
  };

  onSignatureEnd = () => {
    const signatureData = this.sigCanvas
      .getCanvas()
      .toDataURL()
      .substring(22);
    if (this.props.signatureChanged) this.props.signatureChanged(true, signatureData);
  };

  getSignatureCanvasWidth = () => {
    let canWidth = 600;
    let canHeight = 300;
    canWidth = this.signatureParent.current.clientWidth;
    canHeight = 120;
    this.setState({
      canHeight,
      canWidth,
    });
  };

  clearSig = () => {
    this.sigCanvas.clear();
    if (this.sigCanvas.isEmpty()) {
      this.setState({
        canvasTouched: false,
      });
      if (this.props.signatureChanged) this.props.signatureChanged(null, null);
    }
  };

  render() {
    const { canvasTouched, canHeight, canWidth } = this.state;
    const { intl } = this.props;
    return (
      <>
        <div className={styles.signatureWrapper}>
          <div className={styles.signatureCanvas} ref={this.signatureParent}>
            <SignatureCanvas
              penColor="black"
              ref={(ref) => {
                this.sigCanvas = ref;
              }}
              canvasProps={{
                style: { display: 'inline' },
                height: canHeight,
                width: canWidth,
                className: 'sigCanvas',
              }}
              backgroundColor="#ffffff"
              onBegin={() => this.onSignatureBegin()}
              onEnd={() => this.onSignatureEnd()}
              data-test="signatureCanvas"
            />
          </div>
          <div className={styles.signatureHelper} data-test="signatureHelper">
            {intl.formatMessage({ id: 'text.pleaseSignHere' })}
            {canvasTouched && (
              <Button onClick={() => this.clearSig()} data-test="signatureClear">
                Clear
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(InlineSignature);
