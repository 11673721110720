import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import styles from './UpdateCalendar.module.less';

const UpdateCalendar = ({ item, newDate, intl: { formatMessage } }) => {
  const changedDate = newDate;
  let olderDate = null;
  let newerDate = null;
  if (moment(item.estimated_date).format('X') > moment(changedDate).format('X')) {
    olderDate = changedDate;
    newerDate = item.estimated_date;
  } else {
    olderDate = item.estimated_date;
    newerDate = changedDate;
  }

  let defaultStartOf = 'month';
  let primaryHeaderLabelFormat = 'MMMM YYYY';
  let primaryHeaderFormat = 'month';
  let secondaryHeaderFormat = 'day';
  let secondaryHeaderLabelFormat = 'D_d-dd';

  const monthsDiff = moment(newerDate).diff(moment(olderDate), 'months', true);
  if (
    moment(newerDate)
      .startOf('month')
      .format('MM') ===
      moment(olderDate)
        .startOf('month')
        .format('MM') &&
    moment(newerDate)
      .startOf('year')
      .format('YYYY') ===
      moment(olderDate)
        .startOf('year')
        .format('YYYY')
  ) {
    secondaryHeaderFormat = 'day';
    secondaryHeaderLabelFormat = 'D_d-dd';
  } else if (monthsDiff < 3) {
    secondaryHeaderFormat = 'week';
    secondaryHeaderLabelFormat = 'w_w-';
  } else if (monthsDiff < 6) {
    secondaryHeaderFormat = 'month';
    secondaryHeaderLabelFormat = 'MMMM_MM-';
  } else {
    primaryHeaderLabelFormat = 'YYYY';
    primaryHeaderFormat = 'year';
    defaultStartOf = 'year';
    secondaryHeaderFormat = 'month';
    secondaryHeaderLabelFormat = 'MM_YY-';
  }

  const groups = [{ id: 1, title: item.name, height: 50, stackItems: true }];

  const items = [
    {
      id: 1,
      group: 1,
      title: 'MX',
      start_time: moment(item.estimated_date).startOf('day'),
      end_time: moment(item.estimated_date)
        .startOf('day')
        .add(1, 'days'),
      canMove: false,
      itemProps: {
        className: `${styles.calendarItem} ${
          item.estimated_date === changedDate ? styles.calendarItemOverlapOld : undefined
        }`,
      },
    },
    {
      id: 2,
      group: 1,
      title: 'MX',
      start_time: moment(changedDate).startOf('day'),
      end_time: moment(changedDate)
        .startOf('day')
        .add(1, 'days'),
      canMove: false,
      itemProps: {
        className: `${styles.calendarItem} ${styles.calendarItemNew} ${
          item.estimated_date === changedDate ? styles.calendarItemOverlapNew : undefined
        }`,
      },
    },
  ];

  const verticalLineClassNamesForTime = (timeStart) => {
    const currentTimeStart = moment(timeStart).format('YYYY');
    let classes = [];
    if (currentTimeStart % 2 === 0 && primaryHeaderFormat === 'year') {
      classes = ['', styles.evenYearCol];
    } else {
      classes = [''];
    }

    return classes;
  };

  return (
    <Col md={24} className={styles.calendarWrapper}>
      <Timeline
        groups={groups}
        items={items}
        canMove={false}
        canResize={false}
        defaultTimeStart={moment(olderDate).startOf(defaultStartOf)}
        defaultTimeEnd={moment(newerDate).endOf(defaultStartOf)}
        verticalLineClassNamesForTime={verticalLineClassNamesForTime}
        data-test="timeline"
      >
        <TimelineHeaders>
          <SidebarHeader data-test="sidebar">
            {({ getRootProps }) => {
              // eslint-disable-next-line react/jsx-props-no-spreading
              return <div {...getRootProps()}>{formatMessage({ id: 'title.schedule' })}</div>;
            }}
          </SidebarHeader>
          <DateHeader
            className={`${styles.headerMonth} ${primaryHeaderFormat === 'year' ? styles.headerMonthYear : undefined}`}
            unit={primaryHeaderFormat}
            labelFormat={primaryHeaderLabelFormat}
            intervalRenderer={({ getIntervalProps, intervalContext }) => {
              const evenNumber = Number(intervalContext.intervalText) % 2 === 0;
              return (
                <div
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getIntervalProps()}
                  className={`${
                    primaryHeaderFormat === 'year' && evenNumber ? styles.monthIntervalEvenYear : undefined
                  }`}
                >
                  {intervalContext.intervalText}
                </div>
              );
            }}
            data-test="primaryHeader"
          />
          <DateHeader
            unit={secondaryHeaderFormat}
            labelFormat={secondaryHeaderLabelFormat}
            className={`${styles.headerDay} ${primaryHeaderFormat === 'year' ? styles.headerDayYear : undefined}`}
            intervalRenderer={({ getIntervalProps, intervalContext }) => {
              const dayNo = intervalContext.intervalText.substring(
                intervalContext.intervalText.indexOf('_') + 1,
                intervalContext.intervalText.indexOf('-'),
              );
              const evenNumber = dayNo % 2 === 0;
              return (
                <div
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getIntervalProps()}
                  className={`${styles.dayInterval} ${
                    (dayNo === '6' || dayNo === '0') && secondaryHeaderFormat === 'day'
                      ? styles.dayIntervalWeekend
                      : undefined
                  } ${primaryHeaderFormat === 'year' && evenNumber ? styles.dayIntervalEvenYear : undefined}`}
                >
                  {intervalContext.intervalText.substring(0, intervalContext.intervalText.indexOf('_'))}
                  <span>
                    {secondaryHeaderLabelFormat === 'w_w-'
                      ? 'WK'
                      : intervalContext.intervalText.substring(
                          intervalContext.intervalText.indexOf('-') + 1,
                          intervalContext.intervalText.indexOf('-') + 2,
                        )}
                  </span>
                </div>
              );
            }}
            data-test="secondaryHeader"
          />
        </TimelineHeaders>
      </Timeline>
    </Col>
  );
};

UpdateCalendar.propTypes = {
  item: PropTypes.object.isRequired,
  newDate: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default injectIntl(UpdateCalendar);
