/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAircraftLogs } from '../../services/api';
import { AircraftLogbookActionTypes } from '.';

// eslint-disable-next-line import/prefer-default-export
export const fetch = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await getAircraftLogs(payload);
  dispatch({
    type: AircraftLogbookActionTypes.SAVE,
    payload: response,
  });
};
