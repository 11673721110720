import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import FlexWrapper from '../DefectDrawer/components/FlexWrapper';
import searchIcon from '../../assets/search.svg';
import cancelIcon from '../../assets/icon-cancel.svg';
import Portal from '../TFPortal/TFPortal';

interface TFSearchInputProps {
  handleSearchInput: (input: string) => void;
}

const slideRight = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: 10px;
  }
`;

const slideLeft = keyframes`
  0%{
    left: 10px;
  }
  100% {
    left: 0;
  }
`;

const fadeIn = keyframes`
  0%{
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0%{
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const slideRightAnimation = css`
  animation: ${slideRight} 0.3s;
`;

const slideLeftAnimation = css`
  animation: ${slideLeft} 0.3s;
`;

const fadeInAnimation = css`
  animation: ${fadeIn} 0.3s;
`;

const fadeOutAnimation = css`
  animation: ${fadeOut} 0.3s;
`;

const SearchImage = styled.img`
  width: 14px;
`;

const StyledSearchingButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  left: ${({ searching }): string => (searching ? '10px' : '0')};
  top: 2px;
  height: 32px;
  z-index: 1;
  border: none;
  background-color: transparent;
  ${({ searching }): string =>
    searching &&
    css`
      ${slideRightAnimation}
    `};
  ${({ searching }): string =>
    !searching &&
    css`
      ${slideLeftAnimation}
    `};
`;

const StyledClearButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  right: 22px;
  border: none;
  background-color: transparent;
`;

const StyledInput = styled.input`
  min-width: 210px;
  width: ${({ width }): string => (width ? `${width}px` : 'auto')};
  height: 34px;
  margin-left: -15px;
  padding: ${({ padding }): string => (padding ? `${padding}` : `9px 10px 9px 34px`)};
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
  ::placeholder {
    color: rgba(36, 45, 65, 0.3);
  }
  &:focus {
    outline: none;
  }
  ${({ searching }): string =>
    searching &&
    css`
      ${fadeInAnimation}
    `};
  ${({ searching }): string =>
    !searching &&
    css`
      ${fadeOutAnimation}
    `};
`;

const TFSearchInput: React.FC<TFSearchInputProps> = ({ handleSearchInput }) => {
  const [searching, setSearching] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const inputRef = useRef(null);

  const handleSearchClick = (): void => {
    if (!searchInput) {
      setSearching(!searching);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 400);
    }
  };

  const handleSearchInputChange = (input: string): void => {
    setSearchInput(input);
  };

  useEffect(() => {
    if (searchInput) {
      handleSearchInput(searchInput);
    }
  }, [handleSearchInput, searchInput]);

  return (
    <FlexWrapper marginBottom={20} zIndex="202">
      <StyledSearchingButton
        type="button"
        onClick={(): void => {
          if (!searching) {
            handleSearchClick();
          }
        }}
        searching={searching}
      >
        <SearchImage src={searchIcon} alt="search icon" />
      </StyledSearchingButton>
      {searching && (
        <>
          <FlexWrapper>
            <StyledInput
              type="text"
              placeholder="Search for an MEL item…"
              padding="9px 26px"
              width={280}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                handleSearchInputChange(e.target.value);
              }}
              ref={inputRef}
              searching={searching}
              value={searchInput}
              onBlur={(): void => {
                if (!searchInput) {
                  setTimeout(() => {
                    setSearching(false);
                  }, 100);
                }
              }}
            />
            <StyledClearButton
              type="button"
              onClick={(): void => {
                setSearchInput('');
                inputRef.current.focus();
              }}
            >
              <img src={cancelIcon} alt="cancel icon" />
            </StyledClearButton>
          </FlexWrapper>
        </>
      )}
    </FlexWrapper>
  );
};

export default TFSearchInput;
