import React from 'react';
import SlidingDrawer from '../SlidingDrawer';
import WorkpackDrawerContents from './WorkpackDrawerContents';

const WorkPackDrawer = ({
  initialMode,
  toggleDrawer,
  wp,
  pendingWps,
  ac,
  wpId,
  handleDeleteWorkpack,
  updateWorkpackid,
  match,
}): JSX.Element => {
  return (
    <SlidingDrawer toggleDrawer={toggleDrawer}>
      <WorkpackDrawerContents
        wp={wp}
        pendingWps={pendingWps}
        ac={ac}
        initialMode={initialMode}
        wpId={wpId}
        handleDeleteWorkpack={handleDeleteWorkpack}
        toggleDrawer={toggleDrawer}
        updateWorkpackid={updateWorkpackid}
        match={match}
      />
    </SlidingDrawer>
  );
};

export default WorkPackDrawer;
