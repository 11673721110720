import { Table, Button, Dropdown, Menu, Icon, Modal } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Loading } from '@arcflight/tf-component-library';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ImageAvatar from '../../components/ImageAvatar';
import emptyCrewSearch from '../../assets/empty-state-crew.svg';
import PageOverlayAction from '../../components/PageOverlayAction';
import styles from './SettingsCrewList.module.less';

class SettingsCrewList extends Component {
  static propTypes = {
    crew: PropTypes.array,
    intl: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isCrewSearched: PropTypes.string,
    loading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isCrewSearched: false,
    crew: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }

  confirmDelete = (id) => {
    const {
      intl: { formatMessage },
      handleDelete,
    } = this.props;
    Modal.confirm({
      title: formatMessage({ id: 'title.removeCrew' }),
      content: formatMessage({ id: 'form.question.areYouSureRemoveCrew' }),
      okText: formatMessage({ id: 'form.button.remove' }),
      cancelText: formatMessage({ id: 'form.button.cancel' }),
      onOk: () => handleDelete(id),
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  getTableColumns = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const columns = [
      {
        title: formatMessage({ id: 'title.crew' }),
        dataIndex: 'crew',
        width: 220,
        className: 'crewColumn',
        sorter: (a, b) => a.person.last_name.localeCompare(b.person.last_name),
        render: (text, record) => (
          <div className={styles.noWrap}>
            <div className={styles.avatarContainer}>
              <ImageAvatar
                avatar_url={record.person.avatar_url}
                avatarUrl={record.person.avatar_url}
                user={record.person}
              />
            </div>
            <div className={styles.nameContainer}>{`${record.person.first_name} ${record.person.last_name}`}</div>
          </div>
        ),
      },
      {
        title: formatMessage({ id: 'title.email' }),
        dataIndex: 'person.email',
        width: 300,
        className: 'emailColumn',
        sorter: (a, b) => a.person.email.localeCompare(b.person.email),
        render: (text) => <div className={styles.noWrap}>{text}</div>,
      },
      {
        title: formatMessage({ id: 'title.role' }),
        dataIndex: 'person.position',
        className: 'roleColumn',
        sorter: (a, b) => a.person.position.localeCompare(b.person.position),
      },
      {
        title: formatMessage({ id: 'title.lastFlown' }),
        dataIndex: 'last_flown',
        className: 'lastFlownColumn',
        sorter: (a, b) => moment(a.last_flown) - moment(b.last_flown),
        render(date) {
          return date ? moment(date).fromNow() : '';
        },
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) => {
          return (
            <div className={styles.editableRowOperations}>
              <Dropdown
                placement="bottomLeft"
                overlay={
                  <Menu>
                    <Menu.Item key="delete" onClick={() => this.confirmDelete(record.person.id)}>
                      {formatMessage({ id: 'form.button.remove' })}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="link">
                  <Icon className={styles.icon} type="more" />
                </Button>
              </Dropdown>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  handleMultipleRemove = () => {
    const { handleDelete } = this.props;
    this.state.selectedRowKeys.forEach((id) => {
      handleDelete(id);
    });
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      crew,
      intl: { formatMessage },
      isCrewSearched,
      loading,
    } = this.props;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };
    return (
      <div className={styles.crewTableDiv}>
        <PageOverlayAction
          visible={selectedRowKeys.length > 0}
          type="crew"
          items={selectedRowKeys}
          onSuccess={() => this.handleMultipleRemove()}
          onCancel={() => this.setState({ selectedRowKeys: [] })}
        />
        <div>
          <Loading loading={loading} contain />
          <Table
            locale={{
              emptyText: (
                <div className={crew ? styles.emptyText : styles.emptyTextNoAPU} data-test="emptyText">
                  {isCrewSearched ? (
                    <>
                      <img src={emptyCrewSearch} alt="empty state" data-test="emptyTextImg" />
                      <span data-test="emptyTextSpan">{formatMessage({ id: 'text.noSearchMatching' })}</span>
                    </>
                  ) : (
                    <>
                      <img src={emptyCrewSearch} alt="empty state" data-test="emptyTextImg" />
                      <span data-test="emptyTextSpan">{formatMessage({ id: 'text.noCrew' })}</span>
                    </>
                  )}
                </div>
              ),
            }}
            columns={this.getTableColumns()}
            data-test="crewTable"
            dataSource={crew}
            scroll={{ x: 760 }}
            rowSelection={rowSelection}
            rowKey={(record) => record.person.id}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(SettingsCrewList);
