/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import trimCanvas from 'trim-canvas';

import Bezier from './bezier';
import Point from './point';

const calculateCurveControlPoints = (s1, s2, s3) => {
  const dx1 = s1.x - s2.x;
  const dy1 = s1.y - s2.y;
  const dx2 = s2.x - s3.x;
  const dy2 = s2.y - s3.y;

  const m1 = { x: (s1.x + s2.x) / 2.0, y: (s1.y + s2.y) / 2.0 };
  const m2 = { x: (s2.x + s3.x) / 2.0, y: (s2.y + s3.y) / 2.0 };

  const l1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
  const l2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);

  const dxm = m1.x - m2.x;
  const dym = m1.y - m2.y;

  const k = l2 / (l1 + l2);
  const cm = { x: m2.x + dxm * k, y: m2.y + dym * k };

  const tx = s2.x - cm.x;
  const ty = s2.y - cm.y;

  return {
    c1: new Point(m1.x + tx, m1.y + ty),
    c2: new Point(m2.x + tx, m2.y + ty),
  };
};

export default class SignatureCanvas extends Component {
  static propTypes = {
    velocityFilterWeight: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    dotSize: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    penColor: PropTypes.string,
    onEnd: PropTypes.func,
    onBegin: PropTypes.func,
    clearOnResize: PropTypes.bool,
    backgroundColor: PropTypes.string,
    canvasProps: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
  };

  static defaultProps = {
    velocityFilterWeight: 0.7,
    minWidth: 0.5,
    maxWidth: 2.5,
    dotSize: (minWidth, maxWidth) => {
      return (minWidth + maxWidth) / 2;
    },
    penColor: 'black',
    onEnd: () => {
      /* default prop */
    },
    onBegin: () => {
      /* default prop */
    },
    clearOnResize: true,
    backgroundColor: '',
    canvasProps: {},
    height: 0,
    width: 0,
  };

  componentDidMount() {
    this.ctx = this.canvas.getContext('2d');

    this.handleMouseEvents();
    this.handleTouchEvents();
    this.resizeCanvas();
    this.clear();
  }

  componentWillUnmount() {
    this.off();
  }

  // return the canvas ref for operations like toDataURL
  getCanvas = () => {
    return this.canvas;
  };

  // return a trimmed copy of the canvas
  getTrimmedCanvas = () => {
    // copy the canvas
    const copy = document.createElement('canvas');
    copy.width = this.canvas.width;
    copy.height = this.canvas.height;
    copy.getContext('2d').drawImage(this.canvas, 0, 0);
    // then trim it
    return trimCanvas(copy);
  };

  clear = () => {
    const { backgroundColor } = this.props;
    const ctxVar = this.ctx;
    const canvasVar = this.canvas;
    if (ctxVar) {
      ctxVar.fillStyle = backgroundColor;
      ctxVar.clearRect(0, 0, canvasVar.width, canvasVar.height);
      ctxVar.fillRect(0, 0, canvasVar.width, canvasVar.height);
    }
    this.reset();
    this.isItEmpty = true;
  };

  fromDataURL = (dataURL, options) => {
    const image = new Image();
    const opts = options || {};
    const ratio = opts.ratio || window.devicePixelRatio || 1;
    const width = opts.width || this.canvas.width / ratio;
    const height = opts.height || this.canvas.height / ratio;

    this.reset();
    this.resizeCanvas();
    image.onload = () => this.ctx.drawImage(image, 0, 0, width, height);
    image.src = dataURL;
    this.isItEmpty = false;
  };

  isEmpty = () => this.isItEmpty;

  checkClearOnResize = () => {
    if (!this.props.clearOnResize) {
      return;
    }
    this.resizeCanvas();
  };

  resizeCanvas = () => {
    const { canvasProps } = this.props;
    const { width, height } = canvasProps;

    const ctxVar = this.ctx;
    const canvasVar = this.canvas;
    /* When zoomed out to less than 100%, for some very strange reason,
      some browsers report devicePixelRatio as less than 1
      and only part of the canvas is cleared then. */
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    // only change width/height if none has been passed in as a prop
    if (!width) {
      canvasVar.width = canvasVar.offsetWidth * ratio;
    }
    if (!height) {
      canvasVar.height = canvasVar.offsetHeight * ratio;
    }
    if (!width || !height) {
      ctxVar.scale(ratio, ratio);
    }
  };

  reset = () => {
    this.points = [];
    this.lastVelocity = 0;
    this.lastWidth = (this.props.minWidth + this.props.maxWidth) / 2;
    if (this.ctx) {
      this.ctx.fillStyle = this.props.penColor;
    }
  };

  handleMouseEvents = () => {
    this.mouseButtonDown = false;

    this.canvas.addEventListener('mousedown', this.handleMouseDown);
    this.canvas.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);

    window.addEventListener('resize', this.checkClearOnResize);
  };

  handleTouchEvents = () => {
    // Pass touch events to canvas element on mobile IE.
    this.canvas.style.msTouchAction = 'none';

    this.canvas.addEventListener('touchstart', this.handleTouchStart);
    this.canvas.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('touchend', this.handleTouchEnd);
  };

  off = () => {
    this.canvas.removeEventListener('mousedown', this.handleMouseDown);
    this.canvas.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);

    this.canvas.removeEventListener('touchstart', this.handleTouchStart);
    this.canvas.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleTouchEnd);

    window.removeEventListener('resize', this.checkClearOnResize);
  };

  handleMouseDown = (ev) => {
    if (ev.which === 1) {
      this.mouseButtonDown = true;
      this.strokeBegin(ev);
    }
  };

  handleMouseMove = (ev) => {
    if (this.mouseButtonDown) {
      this.strokeUpdate(ev);
    }
  };

  handleMouseUp = (ev) => {
    if (ev.which === 1 && this.mouseButtonDown) {
      this.mouseButtonDown = false;
      this.strokeEnd(ev);
    }
  };

  handleTouchStart = (ev) => {
    const touch = ev.changedTouches[0];
    this.strokeBegin(touch);
  };

  handleTouchMove = (ev) => {
    // prevent scrolling
    ev.preventDefault();

    const touch = ev.changedTouches[0];
    this.strokeUpdate(touch);
  };

  handleTouchEnd = (ev) => {
    const wasCanvasTouched = ev.target === this.canvas;
    if (wasCanvasTouched) {
      this.strokeEnd(ev);
    }
  };

  strokeUpdate = (ev) => {
    const point = this.createPoint(ev);
    this.addPoint(point);
  };

  strokeBegin = (ev) => {
    this.reset();
    this.strokeUpdate(ev);
    this.props.onBegin(ev);
  };

  strokeDraw = (point) => {
    const ctxVar = this.ctx;
    const dotSize =
      typeof this.props.dotSize === 'function'
        ? this.props.dotSize(this.props.minWidth, this.props.maxWidth)
        : this.props.dotSize;

    ctxVar.beginPath();
    this.drawPoint(point.x, point.y, dotSize);
    ctxVar.closePath();
    ctxVar.fill();
  };

  strokeEnd = (ev) => {
    const canDrawCurve = this.points.length > 2;
    const point = this.points[0];

    if (!canDrawCurve && point) {
      this.strokeDraw(point);
    }

    this.props.onEnd(ev);
  };

  createPoint = (ev) => {
    const rect = this.canvas.getBoundingClientRect();
    return new Point(ev.clientX - rect.left, ev.clientY - rect.top);
  };

  addPoint = (point) => {
    const pointsVar = this.points;
    let c3;
    let curve;
    let tmp;

    pointsVar.push(point);

    if (pointsVar.length > 2) {
      // To reduce the initial lag make it work with 3 points
      // by copying the first point to the beginning.
      if (pointsVar.length === 3) pointsVar.unshift(pointsVar[0]);

      tmp = calculateCurveControlPoints(pointsVar[0], pointsVar[1], pointsVar[2]);
      const c2Var = tmp.c2;
      tmp = calculateCurveControlPoints(pointsVar[1], pointsVar[2], pointsVar[3]);
      c3 = tmp.c1;
      curve = new Bezier(pointsVar[1], c2Var, c3, pointsVar[2]);
      this.addCurve(curve);

      // Remove the first element from the list,
      // so that we always have no more than 4 points in points array.
      pointsVar.shift();
    }
  };

  addCurve = (curve) => {
    const { startPoint, endPoint } = curve;
    let velocity;

    velocity = endPoint.velocityFrom(startPoint);
    velocity = this.props.velocityFilterWeight * velocity + (1 - this.props.velocityFilterWeight) * this.lastVelocity;

    const newWidth = this.strokeWidth(velocity);
    this.drawCurve(curve, this.lastWidth, newWidth);

    this.lastVelocity = velocity;
    this.lastWidth = newWidth;
  };

  drawPoint = (x, y, size) => {
    const ctxVar = this.ctx;

    ctxVar.moveTo(x, y);
    ctxVar.arc(x, y, size, 0, 2 * Math.PI, false);
    this.isItEmpty = false;
  };

  drawCurve = (curve, startWidth, endWidth) => {
    const ctxVar = this.ctx;
    const widthDelta = endWidth - startWidth;
    let width;
    let i;
    let t;
    let tt;
    let ttt;
    let u;
    let uu;
    let uuu;
    let x;
    let y;

    const drawSteps = Math.floor(curve.length());
    ctxVar.beginPath();
    for (i = 0; i < drawSteps; i += 1) {
      // Calculate the Bezier (x, y) coordinate for this step.
      t = i / drawSteps;
      tt = t * t;
      ttt = tt * t;
      u = 1 - t;
      uu = u * u;
      uuu = uu * u;

      x = uuu * curve.startPoint.x;
      x += 3 * uu * t * curve.control1.x;
      x += 3 * u * tt * curve.control2.x;
      x += ttt * curve.endPoint.x;

      y = uuu * curve.startPoint.y;
      y += 3 * uu * t * curve.control1.y;
      y += 3 * u * tt * curve.control2.y;
      y += ttt * curve.endPoint.y;

      width = startWidth + ttt * widthDelta;
      this.drawPoint(x, y, width);
    }
    ctxVar.closePath();
    ctxVar.fill();
  };

  strokeWidth = (velocity) => {
    return Math.max(this.props.maxWidth / (velocity + 1), this.props.minWidth);
  };

  render() {
    const { canvasProps } = this.props;
    return (
      <canvas
        ref={(ref) => {
          this.canvas = ref;
        }}
        {...canvasProps}
      />
    );
  }
}
