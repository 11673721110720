import React from 'react';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';

interface ViewDeferralOptionsProps {
  defect: Defect | null;
}

const ViewDeferralOptions: React.FC<ViewDeferralOptionsProps> = ({ defect }) => {
  const { formatMessage } = useIntl();
  let defect_type;
  let deferred;
  let ata;
  let mel_rectifications;
  if (defect) {
    defect_type = defect?.defect_type;
    deferred = defect?.deferred;
    ata = defect?.display_data?.ata;
    mel_rectifications = defect?.mel_item?.mel_rectifications;
  }

  let melRectifications = { interval: '' };
  // eslint-disable-next-line prefer-destructuring
  if (mel_rectifications?.length > 0) melRectifications = mel_rectifications[0];

  const { interval } = melRectifications;

  return (
    <FlexWrapper marginBottom={30} column>
      <Label marginBottom={20}>{formatMessage({ id: 'text.deferralOptions' })}</Label>
      <GridWrapper columns="1fr 1fr 1fr 1fr">
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'text.defectTypeCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{deferred ? 'Deferred' : 'Not Deferred'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'text.deferralOptionsCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{defect_type || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.categoryCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{interval || '-'}</Label>
        </FlexWrapper>
        <FlexWrapper column>
          <Label fontSize="12" color="rgba(36, 45, 65, 0.4)">
            {formatMessage({ id: 'title.ataSectionCaps' })}
          </Label>
          <Label color="rgba(36, 45, 65, 0.7)">{ata || '-'}</Label>
        </FlexWrapper>
      </GridWrapper>
      {interval !== 'A' && (
        <GridWrapper columns="1fr 1fr 1fr 1fr" rows="1fr 1fr" marginTop={20} rowGap={20}>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightHourLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flight_seconds_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.apuHoursLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.apu_seconds_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightDaysLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flight_days_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.hoursLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.seconds_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.calendarDaysLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.calendar_days_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.cyclesDaysLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.cycles_limit || '-'}</Label>
          </FlexWrapper>
          <FlexWrapper column>
            <Label fontSize="12" textTransform="uppercase" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'form.labels.flightLimit' })}
            </Label>
            <Label color="rgba(36, 45, 65, 0.7)">{defect?.flights_limit || '-'}</Label>
          </FlexWrapper>
        </GridWrapper>
      )}
    </FlexWrapper>
  );
};

export default ViewDeferralOptions;
