import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatTime } from '../../utils/utils';
import styles from './MXTimeDetails.module.less';

const MXTimeDetails = ({ title, item, unitPlural, unitSingular, layout }) => {
  const { formatMessage } = useIntl();
  let repeatDisplay = item.repeat;
  let thresholdDisplay = item.threshold;
  let dueDisplay =
    item.visible_tolerance === null || item.visible_tolerance === undefined
      ? item.due
      : item.due + item.visible_tolerance;
  let toleranceDisplay = item.tolerance;
  let toleranceVisibleDisplay = item.visible_tolerance;
  let dueCheck = item.due;
  let { remaining } = item;
  if (item.format === 'seconds') {
    repeatDisplay = formatTime(item.repeat);
    thresholdDisplay = formatTime(item.threshold);
    dueDisplay =
      item.visible_tolerance === null || item.visible_tolerance === undefined
        ? formatTime(item.due)
        : formatTime(item.due + item.visible_tolerance);
    toleranceDisplay = Math.round(item.tolerance / 3600);
    toleranceVisibleDisplay = item.visible_tolerance === null ? 0 : Math.round(item.visible_tolerance / 3600);
    remaining = Math.round(item.remaining / 3600);
  }

  if (item.format === 'months') {
    const differentYear =
      moment().format('YYYY') !==
      (item.visible_tolerance === 0 ? item.due : moment(item.due).add(item.visible_tolerance, 'months'));
    dueDisplay =
      item.visible_tolerance === 0
        ? item.due
        : moment(item.due)
            .add(item.visible_tolerance, 'months')
            .format(differentYear ? 'DD MMM YY' : 'DD MMM');
    dueCheck = moment(dueCheck)
      .diff(moment(), 'months')
      .toString();
    remaining = Math.round(item.remaining / 30);
  }
  if (item.format === 'days') {
    const differentYear =
      moment().format('YYYY') !==
      (item.visible_tolerance === 0 ? item.due : moment(item.due).add(item.visible_tolerance, 'days'));
    dueDisplay =
      item.visible_tolerance === 0
        ? item.due
        : moment(item.due)
            .add(item.visible_tolerance, 'days')
            .format(differentYear ? 'DD MMM YY' : 'DD MMM');
    dueCheck = moment(dueCheck)
      .diff(moment(), 'days')
      .toString();
    remaining = item.remaining;
  }
  const thrDisplayAndUnits = `${thresholdDisplay} ${thresholdDisplay === 1 ? unitSingular : unitPlural}`;
  return (
    <div>
      {title && (
        <div className={styles.itemTitle} data-test="itemTitle">
          {title}
        </div>
      )}
      <div className={`${styles.itemDetails} ${layout === 'inline' ? styles.itemDetailsInline : undefined}`}>
        {item.repeat > 0 && (
          <div className={styles.itemDetailsUpperWrapper}>
            <span className={styles.columnTitle} data-test="itemInterval">
              {formatMessage({ id: 'title.interval' })}:
            </span>
            <span className={styles.columnSpan}>
              <span data-test="intervalVal" className={`${styles.inlineSpan} ${styles[`span${item.repeat_status}`]}`}>
                {repeatDisplay} {repeatDisplay === 1 ? unitSingular : unitPlural}
              </span>
              {(item?.threshold && (
                <span
                  className={`${styles.secondarySpan} ${styles[`span${item.threshold_status}`]}`}
                  data-test="thresholdVal"
                >
                  ({`${formatMessage({ id: 'text.thresholdShort' })}${thrDisplayAndUnits}`})
                </span>
              )) ||
                null}
            </span>
          </div>
        )}
        {item.due && (
          <div>
            <span className={styles.columnTitle} data-test="itemDue">
              {formatMessage({ id: 'text.due' })}:
            </span>
            <span className={styles.columnSpan}>
              <span data-test="dueVal" className={`${styles.inlineSpan} ${styles[`span${item.due_status}`]}`}>
                {remaining <= 0 && item.visible_tolerance ? <div className={styles.warningCircle} /> : null}{' '}
                {dueDisplay}{' '}
                {item.format !== 'months' && item.format !== 'days' && (dueDisplay === 1 ? unitSingular : unitPlural)}
              </span>
              {item.tolerance && !item.visible_tolerance ? (
                <div
                  className={`${styles.secondarySpan} ${styles[`span${item.tolerance_status}`]}`}
                  data-test="toleranceVal"
                >
                  (+/- {Math.abs(toleranceDisplay)} {toleranceDisplay === 1 ? unitSingular : unitPlural})
                </div>
              ) : (
                ''
              )}
            </span>
          </div>
        )}
        {!!item.visible_tolerance && (
          <div className={styles.visibleTolerance} data-test="visibleToleranceVal">
            {`${'Using'} ${toleranceVisibleDisplay}/${toleranceDisplay} ${
              toleranceDisplay === 1 ? unitSingular : unitPlural
            } of tolerance`}
          </div>
        )}
        {!item.due && !item.repeat && '-'}
      </div>
    </div>
  );
};

MXTimeDetails.defaultProps = {
  title: '',
  unitPlural: '',
  unitSingular: '',
  layout: null,
};

MXTimeDetails.propTypes = {
  title: PropTypes.string,
  item: PropTypes.object.isRequired,
  unitPlural: PropTypes.string,
  unitSingular: PropTypes.string,
  layout: PropTypes.string,
};

export default MXTimeDetails;
