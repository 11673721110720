import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import FiltersGroup from './FiltersGroup';

const FiltersSidebar = ({ aircraft, allItems, activeFilters, onFilterChange, onFiltersClear }) => {
  const { formatMessage } = useIntl();
  const getAvailableFilters = () => {
    const showFilters = [
      {
        title: formatMessage({ id: 'text.show' }),
        key: 'show',
        filters: [
          {
            title: formatMessage({ id: 'text.expandAllItems' }),
            key: 'expandAll',
            type: 'boolean',
            value: activeFilters.show.includes('expandAll'),
          },
        ],
      },
    ];

    const typeFilters = [
      {
        title: formatMessage({ id: 'text.itemType' }),
        key: 'itemType',
        filters: [
          {
            title: formatMessage({ id: 'text.package' }),
            key: 'pkg',
            type: 'keyValue',
            number: allItems && allItems.filter((item) => !!item.package).length,
            value: activeFilters.itemType.includes('pkg'),
          },
          {
            title: formatMessage({ id: 'tab.scheduled' }),
            key: 'scheduled',
            type: 'keyValue',
            number:
              allItems &&
              allItems.filter((item) => item.mx_type && item.mx_type.includes('scheduled') && !item.package).length,
            value: activeFilters.itemType.includes('scheduled'),
          },
          {
            title: formatMessage({ id: 'tab.outOfPhase' }),
            key: 'oop',
            type: 'keyValue',
            number:
              allItems &&
              allItems.filter((item) => item.mx_type && item.mx_type.includes('oop') && !item.package).length,
            value: activeFilters.itemType.includes('oop'),
          },
          {
            title: formatMessage({ id: 'title.lifeLimitedParts' }),
            key: 'llp',
            type: 'keyValue',
            number:
              allItems &&
              allItems.filter((item) => item.mx_type && item.mx_type.includes('llp') && !item.package).length,
            value: activeFilters.itemType.includes('llp'),
          },
          {
            title: formatMessage({ id: 'title.defect' }),
            key: 'def',
            type: 'keyValue',
            number: allItems && allItems.filter((item) => item.mx_type && item.mx_type.includes('def')).length,
            value: activeFilters.itemType.includes('defect'),
          },
        ],
      },
    ];

    const apuInstalled = aircraft ? aircraft.apu_installed : null;
    const engineCount = aircraft ? aircraft.aircraft_type.engine_count : null;
    const engineType = aircraft ? aircraft.aircraft_type.engine_type : null;
    const areaFilters = {
      title: formatMessage({ id: 'title.area' }),
      key: 'area',
      filters: [
        {
          title: formatMessage({ id: 'text.airframe' }),
          key: 'airframe',
          type: 'keyValue',
          value: activeFilters.area.includes('airframe'),
        },
        {
          title: formatMessage({ id: 'text.engine' }),
          type: 'parent',
          children: [],
        },
        {
          title: formatMessage({ id: 'form.button.propeller' }),
          type: 'parent',
          children: [],
        },
        {
          title: formatMessage({ id: 'text.apu' }),
          key: 'apu',
          type: 'keyValue',
          value: activeFilters.area.includes('apu'),
        },
      ],
    };

    if (!apuInstalled) {
      areaFilters.filters.splice(3, 1);
    }

    if (engineCount > 0) {
      for (let i = 1; i <= engineCount; i += 1) {
        areaFilters.filters[1].children.push({
          title: `${formatMessage({ id: 'text.engine' })} #${i}`,
          key: `engine_${i}`,
          type: 'keyValue',
          value: activeFilters.area.includes(`engine_${i}`),
        });
      }
    }
    if ((engineType === 'piston' || engineType === 'turboprop') && engineCount > 0) {
      for (let i = 1; i <= engineCount; i += 1) {
        areaFilters.filters[2].children.push({
          title: `${formatMessage({ id: 'form.button.propeller' })} #${i}`,
          key: `prop_${i}`,
          type: 'keyValue',
          value: activeFilters.area.includes(`prop_${i}`),
        });
      }
    }

    if (engineType === 'jet') {
      areaFilters.filters.splice(2, 1);
    }

    const sourceFilters = [
      {
        title: formatMessage({ id: 'form.labels.source' }),
        key: 'source',
        filters: [
          {
            title: formatMessage({ id: 'text.camp' }),
            key: 'camp',
            type: 'keyValue',
            number: allItems && allItems.filter((item) => item.source && item.source === 'camp').length,
            value: activeFilters.source.includes('camp'),
          },
          {
            title: formatMessage({ id: 'text.trustflight' }),
            key: 'dash',
            type: 'keyValue',
            number:
              allItems &&
              allItems.filter(
                (item) =>
                  item.source &&
                  item.source === 'core' &&
                  Object.prototype.hasOwnProperty.call(item, 'app_source') &&
                  (item.app_source === 'dash' || item.app_source === null),
              ).length,
            value: activeFilters.source.includes('dash'),
          },
        ],
      },
    ];

    const limitationFilters = [
      {
        title: 'Limits',
        key: 'limitations',
        filters: [
          {
            title: 'Cycles',
            key: 'cycles',
            type: 'keyValue',
            value: activeFilters.limitations.includes('cycles'),
          },
          {
            title: 'Days',
            key: 'days',
            type: 'keyValue',
            value: activeFilters.limitations.includes('days'),
          },
          {
            title: 'Flight Hours',
            key: 'flightHours',
            type: 'keyValue',
            value: activeFilters.limitations.includes('flightHours'),
          },
          {
            title: 'APU Hours',
            key: 'APUHours',
            type: 'keyValue',
            value: activeFilters.limitations.includes('APUHours'),
          },
        ],
      },
    ];

    const planningFilters = [
      {
        title: 'Planning',
        key: 'planning',
        filters: [
          {
            title: 'Planned in Workpack',
            key: 'planned',
            type: 'keyValue',
            value: activeFilters.planning.includes('planned'),
          },
          {
            title: 'Unplanned',
            key: 'unplanned',
            type: 'keyValue',
            value: activeFilters.planning.includes('unplanned'),
          },
        ],
      },
    ];

    const toleranceFilters = [
      {
        title: 'Tolerance',
        key: 'tolerance',
        filters: [
          {
            title: 'Tolerance Applied',
            key: 'tolApplied',
            type: 'keyValue',
            value: activeFilters.tolerance.includes('tolApplied'),
          },
          {
            title: 'Tolerance Not Applied',
            key: 'tolNotApplied',
            type: 'keyValue',
            value: activeFilters.tolerance.includes('tolNotApplied'),
          },
        ],
      },
    ];
    return [
      showFilters,
      typeFilters,
      areaFilters,
      sourceFilters,
      limitationFilters,
      planningFilters,
      toleranceFilters,
    ].reduce((acc, val) => acc.concat(val), []);
  };
  return (
    <div>
      {getAvailableFilters().map((group) => (
        <FiltersGroup
          key={`group${group.key}`}
          group={group}
          onFilterChange={(filterKey, groupKey) => onFilterChange(filterKey, groupKey)}
          onFiltersClear={(groupName) => onFiltersClear(groupName)}
          data-test="filtersGroup"
        />
      ))}
    </div>
  );
};

FiltersSidebar.propTypes = {
  aircraft: PropTypes.object,
  allItems: PropTypes.array,
  activeFilters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
};

FiltersSidebar.defaultProps = {
  allItems: [],
  aircraft: null,
};

export default FiltersSidebar;
