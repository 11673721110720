import React, { useState } from 'react';
import { ResponsiveContainer, AreaChart, CartesianGrid, Area, XAxis, YAxis, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import EmptyStateHourly from '../../assets/emptyState/empty-state-hours-flights.svg';
import NoData from './NoData';
import styles from './FuelUsage.module.less';

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#b0bac9" transform="rotate(0)">
        {payload.value}
      </text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

CustomizedAxisTick.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
};

const Hourly = ({ monthly, formatMessage, isMobile }) => {
  const [fuelPerHourVisible, setFuelPerHourVisible] = useState(true);
  const [cyclesPerHourVisible, setCyclesPerHourVisible] = useState(true);

  const handleButtonClick = (button) => {
    switch (button) {
      case 'fuelPerHour':
        setFuelPerHourVisible(!fuelPerHourVisible);
        break;
      case 'cyclesPerHour':
        setCyclesPerHourVisible(!cyclesPerHourVisible);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        {monthly.length > 0 ? (
          <AreaChart width={600} height={300} data={monthly} margin={{ top: 10, right: 0, left: 0, bottom: 20 }}>
            <CartesianGrid stroke="#dbe3ed" strokeDasharray="2 2" />
            <XAxis dataKey="periodFormatted" stroke="#b0bac9" tick={<CustomizedAxisTick />} />
            <YAxis
              type="number"
              stroke="#012140"
              domain={['auto', 'auto']}
              padding={{ bottom: 20, top: 20 }}
              unit=" kg"
              yAxisId="left"
            />
            <YAxis
              type="number"
              unit=" hrs"
              stroke="#1790FF"
              orientation="right"
              domain={['auto', 'auto']}
              padding={{ bottom: 20, top: 20 }}
              yAxisId="right"
            />
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#739CD8" stopOpacity={0.1} />
                <stop offset="100%" stopColor="#ffffff" stopOpacity={1} />
              </linearGradient>
            </defs>
            <Tooltip />
            {fuelPerHourVisible ? (
              <Area
                yAxisId="left"
                type="linear"
                strokeWidth="2"
                dataKey="fuelHour"
                stroke="#012140"
                dot="#012140"
                fill="url(#splitColor)"
                name={formatMessage({ id: 'units.fuelPerHour' })}
              />
            ) : null}
            {cyclesPerHourVisible ? (
              <Area
                yAxisId="right"
                type="linear"
                strokeWidth="2"
                dataKey="cycleHour"
                stroke="#1790FF"
                dot="#1790FF"
                fill="url(#splitColor)"
                name={formatMessage({ id: 'units.hoursPerCycle' })}
              />
            ) : null}
          </AreaChart>
        ) : (
          <NoData image={EmptyStateHourly} />
        )}
      </ResponsiveContainer>
      <div
        className={monthly.length > 0 ? styles.toggleButtons : styles.hideToggleButtons}
        data-testid="Hourly--ToggleButtons"
      >
        <button
          className={fuelPerHourVisible ? styles.buttonToggle : styles.buttonToggleOff}
          type="button"
          onClick={() => handleButtonClick('fuelPerHour')}
        >
          <div className={styles.badge1} />
          <span>{formatMessage({ id: isMobile ? 'text.fph' : 'units.fuelPerHour' })}</span>
        </button>
        <button
          className={cyclesPerHourVisible ? styles.buttonToggle : styles.buttonToggleOff}
          type="button"
          onClick={() => handleButtonClick('cyclesPerHour')}
        >
          <div className={styles.badge2} />
          <span>{formatMessage({ id: isMobile ? 'text.cph' : 'units.hoursPerCycle' })}</span>
        </button>
      </div>
    </>
  );
};

Hourly.propTypes = {
  monthly: PropTypes.array,
  formatMessage: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

Hourly.defaultProps = {
  monthly: [],
  isMobile: false,
};

export default Hourly;
