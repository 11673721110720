import React from 'react';
import TFSelect from '../TFSelect/TFSelect';

interface PaginationDropdownProps {
  handleChange: (input: string) => void;
  initial: { title: string };
}

const PaginationDropdown: React.FC<PaginationDropdownProps> = ({ handleChange, initial }) => {
  const options = [
    {
      title: '10',
    },
    {
      title: '25',
    },
    {
      title: '50',
    },
    {
      title: '100',
    },
  ];
  return (
    <TFSelect
      options={options}
      initial={initial}
      width={60}
      borderRadius="16px"
      height={24}
      handleSelectChange={(option): void => handleChange(option.title)}
    />
  );
};

export default PaginationDropdown;
