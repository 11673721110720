import React, { useState } from 'react';
import { Button, Spin, message } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { approveMaintenance, rejectMaintenance, commitMaintenanceDiffs } from '../../../../../services/api';
import styles from './DraftsList.module.less';
import ItemCard from './ItemCard';
import ItemCardUpdate from './ItemCardUpdate';

const ItemWrapper = ({
  item,
  aircraft,
  showSkip,
  onSuccess,
  onSkip,
  hideTitle,
  intl: { formatMessage },
  handleSubmittingChange,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const acceptAllItem = async () => {
    setSubmitting(true);
    handleSubmittingChange(true);
    try {
      const response = await approveMaintenance({
        ids: [item.id],
      });

      if (response && response.statusCode === 204) {
        message.success(formatMessage({ id: 'message.approveItemSuccess' }));
        onSuccess();
      } else {
        setSubmitting(false);
        handleSubmittingChange(false);
        message.error(formatMessage({ id: 'message.approveItemsError' }));
      }
    } catch (error) {
      setSubmitting(false);
      handleSubmittingChange(false);
      message.error(formatMessage({ id: 'message.approveItemsError' }));
    }
  };

  const rejectAllItem = async () => {
    setSubmitting(true);
    handleSubmittingChange(true);
    try {
      const response = await rejectMaintenance({
        ids: [item.id],
      });

      if (response && response.statusCode === 204) {
        message.success(formatMessage({ id: 'message.rejectItemSuccess' }));
        onSuccess();
      } else {
        setSubmitting(false);
        handleSubmittingChange(false);
        message.error(formatMessage({ id: 'message.rejectItemsError' }));
      }
    } catch (error) {
      setSubmitting(false);
      handleSubmittingChange(false);
      message.error(formatMessage({ id: 'message.rejectItemsError' }));
    }
  };

  const acceptItemChanges = async () => {
    setSubmitting(true);
    handleSubmittingChange(true);
    try {
      const response = await commitMaintenanceDiffs({
        id: item.id,
        commits: item.camp_diffs.map((diff) => {
          const itemWithAction = { ...diff };
          itemWithAction.action = 'merge';
          return itemWithAction;
        }),
      });

      if (response && response.statusCode === 200) {
        message.success(formatMessage({ id: 'message.approveItemSuccess' }));
        onSuccess();
      } else {
        setSubmitting(false);
        handleSubmittingChange(false);
        message.error(formatMessage({ id: 'message.approveItemsError' }));
      }
    } catch (error) {
      setSubmitting(false);
      handleSubmittingChange(false);
      message.error(formatMessage({ id: 'message.approveItemsError' }));
    }
  };

  const rejectItemChanges = async () => {
    setSubmitting(true);
    handleSubmittingChange(true);
    try {
      const response = await commitMaintenanceDiffs({
        id: item.id,
        commits: item.camp_diffs.map((diff) => {
          const itemWithAction = { ...diff };
          itemWithAction.action = 'reject';
          return itemWithAction;
        }),
      });
      if (response && response.statusCode === 200) {
        message.success(formatMessage({ id: 'message.rejectItemSuccess' }));
        onSuccess();
      } else {
        setSubmitting(false);
        handleSubmittingChange(false);
        message.error(formatMessage({ id: 'message.rejectItemsError' }));
      }
    } catch (error) {
      setSubmitting(false);
      handleSubmittingChange(false);
      message.error(formatMessage({ id: 'message.rejectItemsError' }));
    }
  };

  const skipItem = () => {
    onSkip();
  };

  const getItemFooter = (showChanges) => {
    return (
      <div className={styles.footerWrapper}>
        {showChanges && (
          <div className={styles.changesWrapper} data-test="changesWrapper">
            <span className={styles.changesCount}>{item.camp_diffs.length}</span>
            {formatMessage({ id: 'text.changes' })}
          </div>
        )}
        <Button
          type="link"
          onClick={() => (showChanges ? acceptItemChanges() : acceptAllItem())}
          className={styles.acceptButton}
          data-test="acceptButton"
        >
          {formatMessage({ id: 'text.accept' })}
        </Button>
        <Button
          type="link"
          onClick={() => (showChanges ? rejectItemChanges() : rejectAllItem())}
          className={styles.rejectButton}
          data-test="rejectButton"
        >
          {formatMessage({ id: 'text.reject' })}
        </Button>
        {showSkip && (
          <Button type="link" onClick={() => skipItem()} className={styles.skipButton} data-test="skipButton">
            {formatMessage({ id: 'text.skip' })}
          </Button>
        )}
      </div>
    );
  };

  const hasAPU = aircraft ? aircraft.apu_installed : false;
  const aircraftThreshold = aircraft ? aircraft.mx_thresholds : {};

  const getItemType = (itemObj) => {
    if (itemObj.package) {
      return formatMessage({ id: 'text.package' });
    }
    let standardItemType = itemObj.mx_type.substring(itemObj.mx_type.lastIndexOf('_') + 1, itemObj.mx_type.length);
    if (standardItemType === 'scheduled') {
      standardItemType = formatMessage({ id: 'tab.scheduled' });
    } else if (standardItemType === 'llp') {
      standardItemType = formatMessage({ id: 'text.llp' });
    } else {
      standardItemType = formatMessage({ id: 'text.oop' });
    }
    return standardItemType;
  };

  const outputItem = { ...item };
  if (outputItem.draft) {
    outputItem.draft_type = 'NEW';
  }
  if (outputItem.camp_diffs && outputItem.camp_diffs.length > 0) {
    outputItem.draft_type = 'UPDATE';
  }

  return (
    <div key={item.id}>
      <Spin spinning={submitting}>
        {outputItem.draft_type === 'NEW' && !hideTitle && (
          <div className={styles.itemStatusTitle} data-test="itemStatusTitle">
            {formatMessage({ id: 'title.newTypeRequirement' }, { type: getItemType(outputItem) })}
          </div>
        )}
        {outputItem.draft_type === 'UPDATE' && !hideTitle && (
          <div className={styles.itemStatusTitle} data-test="itemStatusTitle">
            {formatMessage({ id: 'title.updateTypeRequirement' }, { type: getItemType(outputItem) })}
          </div>
        )}
        {outputItem.draft_type === 'REMOVED' && !hideTitle && (
          <div className={styles.itemStatusTitle} data-test="itemStatusTitle">
            {formatMessage({ id: 'title.removedTypeRequirement' }, { type: getItemType(outputItem) })}
          </div>
        )}
        {outputItem.draft_type === 'UPDATE' && !outputItem.package ? (
          <ItemCardUpdate
            item={item}
            key={item.id}
            hasAPU={hasAPU}
            aircraftThreshold={aircraftThreshold}
            data-test="itemCardUpdate"
          />
        ) : (
          <ItemCard
            item={item}
            key={item.id}
            hasAPU={hasAPU}
            aircraftThreshold={aircraftThreshold}
            type={outputItem.draft_type}
            data-test="itemCard"
          />
        )}
        <div className={styles.cardFooter}>{getItemFooter(outputItem.draft_type === 'UPDATE')}</div>
      </Spin>
    </div>
  );
};

ItemWrapper.propTypes = {
  item: PropTypes.object.isRequired,
  aircraft: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSkip: PropTypes.func,
  showSkip: PropTypes.bool,
  hideTitle: PropTypes.bool,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  handleSubmittingChange: PropTypes.func.isRequired,
};

ItemWrapper.defaultProps = {
  showSkip: false,
  hideTitle: false,
  onSkip: null,
};

export default injectIntl(ItemWrapper);
