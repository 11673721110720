export default async function awaitForPropChange(checkerFunction = () => new Error('no function supplied')) {
  await new Promise((resolve) => {
    window.requestAnimationFrame(() => {
      if (checkerFunction()) {
        return resolve();
      }
      return awaitForPropChange(checkerFunction);
    });
  });
}
