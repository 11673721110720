/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { Layout } from 'antd';
import Animate from 'rc-animate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GlobalHeader from '../components/GlobalHeader';
import TopNavHeader from '../components/TopNavHeader';
import servers from '../utils/servers';
import logoText from '../assets/logoText.svg';
import logout from '../utils/logout';
import styles from './Header.module.less';

const { Header } = Layout;

class HeaderView extends PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool.isRequired,
    collapsed: PropTypes.bool.isRequired,
    autoHideHeader: PropTypes.bool.isRequired,
    fixedHeader: PropTypes.bool.isRequired,
    setting: PropTypes.object.isRequired,
    handleMenuCollapse: PropTypes.func.isRequired,
  };

  state = {
    visible: true,
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.autoHideHeader && !state.visible) {
      return {
        visible: true,
      };
    }
    return null;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handScroll, { passive: true });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handScroll);
  }

  getHeadWidth = () => {
    const { isMobile, collapsed, setting } = this.props;
    const { fixedHeader, layout } = setting;
    if (isMobile || !fixedHeader || layout === 'topmenu') {
      return '100%';
    }
    return collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)';
  };

  handleMenuClick = ({ key }) => {
    if (key === 'changePassword') {
      window.location.href = `${servers.api}/change_password`;
      return;
    }
    if (key === 'logout') {
      logout();
    }
  };

  handScroll = () => {
    const { autoHideHeader } = this.props;
    const { visible } = this.state;
    if (!autoHideHeader) {
      return;
    }
    const scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
    if (!this.ticking) {
      requestAnimationFrame(() => {
        if (this.oldScrollTop > scrollTop) {
          this.setState({
            visible: true,
          });
          this.scrollTop = scrollTop;
          return;
        }
        if (scrollTop > 300 && visible) {
          this.setState({
            visible: false,
          });
        }
        if (scrollTop < 300 && !visible) {
          this.setState({
            visible: true,
          });
        }
        this.oldScrollTop = scrollTop;
        this.ticking = false;
      });
    }
    this.ticking = false;
  };

  render() {
    const { isMobile, handleMenuCollapse, setting } = this.props;
    const { navTheme, layout, fixedHeader } = setting;
    const { visible } = this.state;
    const isTop = layout === 'topmenu';
    const HeaderDom = visible ? (
      <Header className={fixedHeader ? styles.fixedHeader : styles.staticHeader}>
        {isTop && !isMobile ? (
          <TopNavHeader
            logoText={logoText}
            theme={navTheme}
            mode="horizontal"
            onCollapse={handleMenuCollapse}
            onMenuClick={this.handleMenuClick}
            {...this.props}
          />
        ) : (
          <GlobalHeader onCollapse={handleMenuCollapse} onMenuClick={this.handleMenuClick} {...this.props} />
        )}
      </Header>
    ) : null;
    return (
      <Animate component="" transitionName="fade">
        {HeaderDom}
      </Animate>
    );
  }
}

export default connect(({ menu, userSettings }) => ({
  collapsed: menu.collapsed,
  setting: userSettings.ui,
  userSettings,
}))(HeaderView);
