import React, { Component } from 'react';
import { Col, Button, Row, Form, Input, Icon } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import globalStyles from '../../utils/globalStyles.module.less';
import styles from './AdditionalTasks.module.less';

const { TextArea } = Input;

class AdditionalTasks extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      additionalTasks: false,
      data: [{ task_ref: null, details: null }],
    };
  }

  componentDidUpdate = () => {
    if (this.props.form.getFieldsValue && this.props.form.getFieldValue) {
      if (
        !this.props.form.getFieldsValue().additional_tasks_task_ref_0 &&
        !this.props.form.getFieldsValue().additional_tasks_details_0 &&
        !this.props.form.getFieldsValue().additional_tasks_bool &&
        this.state.additionalTasks
      ) {
        this.clearAdditionalTasksForm();
      }
    }
  };

  clearAdditionalTasksForm = () => {
    this.setState({
      additionalTasks: false,
      data: [{ task_ref: null, details: null }],
    });
  };

  changeAdditionalTasks = (value) => {
    this.setState({
      additionalTasks: value,
    });
    this.props.form.setFieldsValue({ additional_tasks_bool: value });
  };

  removeElement = (index) => {
    const { data: originalData } = this.state;
    const formValues = this.props.form.getFieldsValue();

    // Remove values from state array
    const data = [...originalData];
    data.splice(index, 1);
    this.setState({ data });

    // Remove values from form instance
    this.props.form.setFieldsValue({ [`additional_tasks_details_${index}`]: null });
    this.props.form.setFieldsValue({ [`additional_tasks_task_ref_${index}`]: null });

    // Set new values instead of previous
    originalData.forEach((item, i) => {
      if (i >= index) {
        this.props.form.setFieldsValue({
          [`additional_tasks_task_ref_${i}`]: formValues[`additional_tasks_task_ref_${i + 1}`],
        });
        this.props.form.setFieldsValue({
          [`additional_tasks_details_${i}`]: formValues[`additional_tasks_details_${i + 1}`],
        });
      }
    });
  };

  addElement = () => {
    const { data: stateData, data } = this.state;
    const newData = Array.isArray(stateData) ? [...data] : [];

    newData.push({ task_ref: null, details: null });
    this.setState({ data: newData });
  };

  render() {
    const {
      form,
      intl: { formatMessage },
    } = this.props;
    const { getFieldDecorator } = form;
    const { additionalTasks, data } = this.state;

    const additionalTasksData = data || null;

    let additionalTasksFormItems = null;
    if (data && additionalTasksData) {
      additionalTasksFormItems = additionalTasksData.map((k, index) => {
        return (
          <div className={styles.singleTask}>
            <Col md={4}>
              <Form.Item
                label={formatMessage({ id: 'form.labels.taskRefNo' })}
                className={globalStyles.formItem}
                key={`${k}_refNo`}
                data-test="formItemTaskRefNo"
              >
                {getFieldDecorator(`additional_tasks_task_ref_${index}`, {
                  rules: [{ required: false }],
                })(
                  <Input
                    className={`${globalStyles.formInput}`}
                    placeholder={formatMessage({ id: 'form.placeholder.taskRefNo' })}
                    data-test="formInputTaskRef"
                  />,
                )}
              </Form.Item>
            </Col>
            {additionalTasksData.length > 1 && (
              <Col md={6} className={styles.removeCol}>
                <Form.Item label=" " className={globalStyles.formItem} data-test="formItemRemoveButton">
                  <Button
                    type="danger"
                    onClick={() => this.removeElement(index)}
                    className={globalStyles.standardButton}
                    data-test="removeTaskButton"
                  >
                    <Icon className={styles.deleteIcon} type="minus-circle-o" />{' '}
                    <span>{formatMessage({ id: 'form.button.removeTask' })}</span>
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col md={24} />

            <Col md={8} className={styles.taskDescription}>
              <Form.Item
                label={formatMessage({ id: 'form.labels.taskRefDetails' })}
                className={globalStyles.formItem}
                key={`${k}_details`}
                data-test="formItemTaskDetails"
              >
                {getFieldDecorator(`additional_tasks_details_${index}`, {
                  rules: [{ required: false }],
                })(
                  <TextArea
                    spellCheck="false"
                    className={globalStyles.formTextArea}
                    placeholder={formatMessage({ id: 'form.placeholder.taskRefDetails' })}
                    rows={4}
                    data-test="formTextAreaDetails"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col md={24} />
          </div>
        );
      });
    }

    return (
      <>
        <Form.Item
          label={formatMessage({ id: 'form.labels.additionalTasks' })}
          className={globalStyles.formItem}
          data-test="formItemAdditionalTasks"
        >
          {getFieldDecorator('additional_tasks_bool', {
            rules: [{ required: false }],
          })(
            <Row gutter={16}>
              <Col>
                <Button
                  className={`${globalStyles.buttonRadioGroup} ${additionalTasks &&
                    globalStyles.buttonRadioGroupActive}`}
                  onClick={() => this.changeAdditionalTasks(true)}
                  data-test="additionalTaskButtonYes"
                >
                  {formatMessage({ id: 'form.button.yes' })}
                </Button>
                <Button
                  className={`${globalStyles.buttonRadioGroup} ${!additionalTasks &&
                    globalStyles.buttonRadioGroupActive}`}
                  onClick={() => this.changeAdditionalTasks(false)}
                  data-test="additionalTaskButtonNo"
                >
                  {formatMessage({ id: 'form.button.no' })}
                </Button>
              </Col>
            </Row>,
          )}
        </Form.Item>

        {additionalTasks && (
          <Row gutter={16}>
            <Col md={24}>
              <Row gutter={16}>{additionalTasksFormItems}</Row>
            </Col>
            <Col md={24} className={styles.addNewCol}>
              <Button
                type="primary"
                className={`${globalStyles.standardButton} ${globalStyles.formSingleRowButton}`}
                onClick={() => this.addElement()}
                data-test="addAnotherTaskButton"
              >
                <Icon type="plus" /> <span>{formatMessage({ id: 'form.button.addAnotherTask' })}</span>
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default injectIntl(AdditionalTasks);
