import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import AddTrip from '../AddTrip';

const AddAircraftTrip = () => {
  const [loading, setLoading] = useState(true);
  const {
    params: { id },
  } = useRouteMatch();
  const { selectedAircraft } = useSelector(({ aircraft }) => ({
    selectedAircraft: aircraft.aircraftMap.get(id),
  }));

  useEffect(() => {
    if (selectedAircraft && loading) {
      setLoading(false);
    }
  }, [selectedAircraft, loading]);

  return (
    <InnerMenuLayout loading={loading}>
      <AddTrip />
    </InnerMenuLayout>
  );
};

export default AddAircraftTrip;
