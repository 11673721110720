/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import moment from 'moment';
import { Icon } from 'antd';
import { formatTime } from '../../../utils/utils';
import expandIcon from '../../../assets/expand.svg';
import completeIcon from '../../../assets/icon-complete.svg';
import bin from '../../../assets/delete-red.svg';
import collapseIcon from '../../../assets/collapse.svg';
import styles from '../workpackDrawer.module.less';

const sourceDictionary = {
  core: { text: 'TrustFlight', logo: <Icon type="plus" className={styles.iconBlue} /> },
  camp: { text: 'CAMP', logo: <Icon type="arrow-down" className={styles.iconAmber} /> },
};

const calculateColour = (threshold, value): string => {
  if (value <= (threshold * 4) / 3 && value > 0) return '#f2a650';
  if (value <= 0) return '#ff4040';
  return '#35b96d';
};

const prepareUnits = (item): any => {
  const hoursThreshold = 30;
  const daysThreshold = 30;
  const apuHoursThreshold = 30;
  const cyclesThreshold = 30;
  const statusDetails = {
    label: 'Status',
    hours: Math.floor(item.flight_seconds_remaining / 3600),
    hoursColour: calculateColour(hoursThreshold, Math.floor(item.flight_seconds_remaining / 3600)),
    cycles: item.cycles_remaining,
    cyclesColour: calculateColour(cyclesThreshold, item.cycles_remaining),
    months: moment()
      .add(item.days_remaining, 'days')
      .diff(moment(), 'months'),
    monthsColour: calculateColour(daysThreshold, item.days_remaining),
    apu: Math.floor(item.apu_seconds_remaining / 3600),
    apuColours: calculateColour(apuHoursThreshold, Math.floor(item.apu_seconds_remaining / 3600)),
  };

  const hrsTimeDetails = {
    label: 'Flight Hours',
    unit: { sing: 'hr', plur: 'hrs' },
    repeat: item.flight_seconds_repeat,
    due: item.flight_seconds_due,
    remaining: item.flight_seconds_remaining,
    visible_tolerance: item.flight_seconds_visible_tolerance,
  };

  const cyclesTimeDetails = {
    label: 'Cycles',
    unit: { sing: 'cycle', plur: 'cycles' },
    repeat: item.cycles_repeat,
    due: item.cycles_due,
    remaining: item.cycles_remaining,
    visible_tolerance: item.cycles_visible_tolerance,
  };

  const daysTimeDetails = {
    label: 'Months',
    unit: { sing: 'month', plur: 'months' },
    repeat: item.months_repeat,
    due: item.date_due,
    remaining: item.days_remaining,
    visible_tolerance: item.months_visible_tolerance,
  };

  return { cyclesTimeDetails, daysTimeDetails, hrsTimeDetails, statusDetails };
};

const Hours = ({ item }): JSX.Element => {
  const repeatDisplay = formatTime(item.repeat);
  const dueDisplay =
    item.visible_tolerance === null ? formatTime(item.due) : formatTime(item.due + item.visible_tolerance);

  const toleranceVisibleDisplay = item.visible_tolerance === null ? null : Math.round(item.visible_tolerance / 3600);
  const remaining = Math.round(item.remaining / 3600);

  return (
    <>
      <div className={styles.rowItem}>
        <span className={styles.mxItemLabel}>{item.label}</span>
        {repeatDisplay !== null ? (
          <>
            <span className={styles.innerLabel}>INTERVAL:</span>
            <span>{`${repeatDisplay} hrs` || '-'}</span>
            <br />
          </>
        ) : null}
        {dueDisplay !== null ? (
          <>
            <span className={styles.innerLabel}>NEXT DUE:</span>
            <span>{(dueDisplay !== null && `${dueDisplay} hrs`) || '-'}</span>
            <span>
              {toleranceVisibleDisplay !== (null || 0) && `Using: ${toleranceVisibleDisplay} unit of tolerance`}
            </span>
          </>
        ) : (
          '-'
        )}
      </div>
    </>
  );
};

const Months = ({ item }): JSX.Element => {
  const differentYear =
    moment().format('YYYY') !==
    (item.visible_tolerance === 0 ? item.due : moment(item.due).add(item.visible_tolerance, 'months'));

  const dueDisplay =
    item.visible_tolerance === 0
      ? item.due
      : moment(item.due)
          .add(item.visible_tolerance, 'months')
          .format(differentYear ? 'DD MMM YY' : 'DD MMM');

  const remaining = Math.round(item.remaining / 30);

  return (
    <>
      <div className={styles.rowItem}>
        <span className={styles.mxItemLabel}>{item.label}</span>
        {item?.repeat ? (
          <>
            <span className={styles.innerLabel}>INTERVAL:</span>
            <span>{`${item?.repeat} mos`}</span>
            <br />
          </>
        ) : null}
        {item?.due ? (
          <>
            <span className={styles.innerLabel}>NEXT DUE:</span>
            <span>{dueDisplay}</span>
            <span>
              {item.visible_tolerance !== (null || 0 || undefined) &&
                `Using: ${item?.visible_tolerance} unit of tolerance`}
            </span>
          </>
        ) : (
          '-'
        )}
      </div>
    </>
  );
};

const Cycles = ({ item }): JSX.Element => {
  const dueDisplay = item.visible_tolerance === null ? item.due : item.due + item.visible_tolerance;

  return (
    <>
      <div className={styles.rowItem}>
        <span className={styles.mxItemLabel}>{item.label}</span>
        {item.repeat !== null ? (
          <>
            <span className={styles.innerLabel}>INTERVAL:</span>
            <span>{`${item.repeat} cycles`}</span>
            <br />
          </>
        ) : null}
        {dueDisplay !== (null || 0) ? (
          <>
            <span className={styles.innerLabel}>NEXT DUE:</span>
            <span>{`${dueDisplay} cycles`}</span>
            <span>
              {item.visible_tolerance !== (null || 0) && `Using: ${item.visible_tolerance} unit of tolerance`}
            </span>
          </>
        ) : (
          '-'
        )}
      </div>
    </>
  );
};

const StatusDetails = ({ item }): JSX.Element => {
  return (
    <div className={styles.rowItem}>
      <span className={styles.mxItemLabel}>{item.label}</span>
      {item.hours ? (
        <span>
          <span style={{ color: item.hoursColour }}>{item.hours > -1 ? item.hours : '0'}</span> hrs left
        </span>
      ) : null}
      {item.cycles ? (
        <span>
          <span style={{ color: item.cyclesColour }}>{item.cycles > -1 ? item.cycles : '0'}</span> cycles left
        </span>
      ) : null}
      {item.months ? (
        <span>
          <span style={{ color: item.monthsColour }}>{item.months > -1 ? item.months : '0'}</span> mos left
        </span>
      ) : null}
      {item.apu ? (
        <span>
          <span style={{ color: item.apuHoursColour }}>{item.apu > -1 ? item.apu : '0'}</span> hrs left
        </span>
      ) : null}
    </div>
  );
};

const MXDetails = ({ details, mode }): JSX.Element => {
  const { cyclesTimeDetails, daysTimeDetails, hrsTimeDetails, statusDetails } = prepareUnits(details);
  const { status } = details;
  let MXDetailsWrapper = styles.defectResolution;
  if (mode === 'edit') MXDetailsWrapper = styles.defectResolutionEdit;
  if (status === 'resolved') MXDetailsWrapper = styles.defectResolutionResolved;
  return (
    <div className={MXDetailsWrapper}>
      <span className={styles.header}>Maintenance Details</span>
      <div className={styles.rowGridFour}>
        <StatusDetails item={statusDetails} />
        <Hours item={hrsTimeDetails} />
        <Months item={daysTimeDetails} />
        <Cycles item={cyclesTimeDetails} />
      </div>
    </div>
  );
};

const ScheduledMX = ({ item: { mx_item, id, mx_item_id }, mode, removeWPItem }): JSX.Element => {
  const [showMXDetails, setShowMXDetails] = useState(false);
  const toggleMXDetails = (): void => setShowMXDetails((state) => !state);

  let engineNumber = null;
  const source = sourceDictionary[mx_item.source];

  const mxType = mx_item.mx_type.split('_');
  const isThereAnEngineNumber = mxType.findIndex((isThisANumber) => !Number.isNaN(Number(isThisANumber)));

  if (isThereAnEngineNumber > -1) {
    engineNumber = mxType.splice(isThereAnEngineNumber, 1);
  }

  const colourChart = {
    SCH: '#470cfe',
    OOP: '#794efc',
    LLP: '#baa3ff',
  };

  const { name } = mx_item;
  const [area, type] = mxType || [];

  const maintenanceArea = area.replace(area.charAt(0), area.charAt(0).toUpperCase());
  const maintenanceType = type.substr(0, 3).toUpperCase();
  return (
    <div>
      <div className={styles.wpRowWrapper}>
        {mx_item.status === 'resolved' ? (
          <div className={styles.checkbox}>
            <img src={completeIcon} alt="complete icon" className={styles.tickIcon} />
          </div>
        ) : (
          <div className={styles.emptyCheckbox} />
        )}
        <div
          role="button"
          key={id}
          className={showMXDetails ? styles.wpItemRowExpanded : styles.wpItemRow}
          onClick={toggleMXDetails}
        >
          <div className={styles.itemDesWrapper}>
            <span className={styles.mxType} style={{ backgroundColor: `${colourChart[maintenanceType]}` }}>
              {maintenanceType}{' '}
            </span>
            <span className={showMXDetails ? styles.mxLabelExpanded : styles.mxLabel}>
              <span className={styles.chapterName}>{maintenanceArea}</span> {engineNumber}, {name}
            </span>
          </div>
          <div className={styles.expandWrapper}>
            <span>
              <span className={styles.logo}>{source && source.logo}</span>
              <span className={styles.logoText}>{source && source.text}</span>
            </span>
            {mx_item.status === 'resolved' ? (
              <span className={styles.outcome}>
                {mx_item.mx_events[0].part_changes && mx_item.mx_events[0].part_changes.length > 0
                  ? 'Part Replaced'
                  : 'Complied With'}
              </span>
            ) : (
              <span className={styles.outcome}>Created {moment(mx_item.created_at).format('DD MMM YYYY')}</span>
            )}
            <img src={showMXDetails ? collapseIcon : expandIcon} alt="expand icon" className={styles.expandIcon} />
          </div>
        </div>
        {mode === 'edit' && (
          <button type="button" onClick={(): void => removeWPItem(id, mx_item_id)}>
            <img src={bin} alt="bin" className={styles.bin} />
          </button>
        )}
      </div>
      {showMXDetails && <MXDetails details={mx_item} mode={mode} />}
    </div>
  );
};

export default ScheduledMX;
