import { Col, Icon, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './ExpandableCard.module.less';

const MXDetailsWorkpacks = ({ item, aircraftID }) => {
  const { formatMessage } = useIntl();
  return item.workpacks && item.workpacks.length > 0 ? (
    <Row className={styles.mxWorkpacks}>
      <Col>
        <Col md={24}>
          <div className={styles.partTitle} data-test="workpacksTitle">
            {formatMessage({ id: 'title.workpacks' })}:
          </div>
          <div className={styles.partDetails} data-test="workpacksDetails">
            {item.workpacks.map((workpack) => {
              return (
                <div>
                  <span>{workpack.po_number || formatMessage({ id: 'text.poNotSpecified' })}</span>
                  <Link
                    to={`/aircraft/${aircraftID}/workpacks/${workpack.id}/view`}
                    target="_blank"
                    data-test="viewWorkpackLink"
                  >
                    {formatMessage({ id: 'text.viewWorkpack' })} <Icon type="right" />
                  </Link>
                </div>
              );
            })}
          </div>
        </Col>
      </Col>
    </Row>
  ) : null;
};

MXDetailsWorkpacks.propTypes = {
  aircraftID: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
};

export default MXDetailsWorkpacks;
