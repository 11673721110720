import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import WorkpackForm from './WorkpackForm';

const AddWorkpack = ({ intl: { formatMessage } }) => (
  <WorkpackForm
    title={formatMessage({ id: 'title.newWorkpack' })}
    content={formatMessage({ id: 'text.newWorkpack' })}
  />
);

AddWorkpack.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AddWorkpack);
