import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import logo from '../../assets/logo.svg';
import QuestionMarkIcon from '../../assets/icon-help-round.svg';
import logoText from '../../assets/logoText.svg';
import styles from './index.module.less';

const PublicPageWrapper = ({ children, wrapperClassName, intercomLink }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={wrapperClassName}>
      <div className={styles.backgroundOverlay} />
      <Row gutter={8} className={styles.logoRow}>
        <Col>
          <div className={styles.tfLogo} data-test="tfLogo">
            <img src={logo} alt="logo" className={styles.logo} data-test="tfLogoImg" />
            <img src={logoText} alt="logoText" className={styles.logoText} data-test="tfLogoText" />
          </div>
        </Col>
      </Row>
      {intercomLink && (
        <Row className={styles.helpRow}>
          <div className={styles.helpContainer}>
            <img src={QuestionMarkIcon} alt="question mark blue" />
            <a href={intercomLink} target="_blank" rel="noopener noreferrer">
              {formatMessage({ id: 'text.howToCompleteThisForm' })}
            </a>
          </div>
        </Row>
      )}
      {children ? (
        <div className={styles.cardRow} data-test="childrenRow">
          <div>{children}</div>
        </div>
      ) : null}
    </div>
  );
};

PublicPageWrapper.defaultProps = {
  wrapperClassName: '',
  intercomLink: '',
};

PublicPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperClassName: PropTypes.string,
  intercomLink: PropTypes.string,
};

export default PublicPageWrapper;
