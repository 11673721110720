import React from 'react';
import { Row, Col, Card } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import newIcon from '../../assets/new-icon.svg';
import updateIcon from '../../assets/edited-icon.svg';
import removedIcon from '../../assets/removed-icon.svg';
import ProgressBars from '../../../../MXCard/ProgressBars';
import MXTimeDetails from '../../../../MXCard/MXTimeDetails';
import MXDetailsPackage from '../../../../MXCard/MXDetailsPackage';
import SmallTag from '../../../../MXCard/SmallTag';
import styles from './DraftsList.module.less';

const ItemCard = ({ item, aircraftThreshold, type, hasAPU, intl: { formatMessage } }) => {
  let globalType = null;
  if (type === 'REMOVED') {
    globalType = 'REMOVED';
  }
  const hrsTimeDetails = {
    repeat: item.flight_seconds_repeat,
    due: item.flight_seconds_due,
    threshold: item.flight_seconds_threshold,
    tolerance: item.flight_seconds_tolerance,
    visible_tolerance: item.flight_seconds_visible_tolerance,
    repeat_status: globalType,
    due_status: globalType,
    threshold_status: globalType,
    tolerance_status: globalType,
    format: 'seconds',
  };

  const cyclesTimeDetails = {
    repeat: item.cycles_repeat,
    due: item.cycles_due,
    threshold: item.cycles_threshold,
    tolerance: item.cycles_tolerance,
    visible_tolerance: item.cycles_visible_tolerance,
    repeat_status: globalType,
    due_status: globalType,
    threshold_status: globalType,
    tolerance_status: globalType,
    format: 'number',
  };

  const daysTimeDetails = {
    repeat: item.months_repeat,
    due: item.date_due,
    threshold: item.months_threshold,
    tolerance: item.months_tolerance,
    visible_tolerance: item.months_visible_tolerance,
    repeat_status: globalType,
    due_status: globalType,
    threshold_status: globalType,
    tolerance_status: globalType,
    format: 'months',
  };

  const apuHrsTimeDetails = {
    repeat: item.apu_seconds_repeat,
    due: item.apu_seconds_due,
    threshold: item.apu_seconds_threshold,
    tolerance: item.apu_seconds_tolerance,
    visible_tolerance: item.apu_seconds_visible_tolerance,
    repeat_status: globalType,
    due_status: globalType,
    threshold_status: globalType,
    tolerance_status: globalType,
    format: 'seconds',
  };
  return (
    <Card className={`${styles.itemCard} ${styles[`itemCard${globalType}`]}`}>
      <Row gutter={16} className={styles.mxDetails}>
        <Col md={24}>
          <div className={styles.titleWrapper}>
            <div className={styles.titleDiv}>
              {type === 'NEW' && <img src={newIcon} alt="newIcon" data-test="newIcon" />}
              {type === 'UPDATE' && <img src={updateIcon} alt="updateIcon" data-test="updateIcon" />}
              {type === 'REMOVED' && <img src={removedIcon} alt="removedIcon" data-test="removedIcon" />}
              <SmallTag
                type={item.mx_type.substring(item.mx_type.lastIndexOf('_'), item.mx_type.length).substring(1, 4)}
                isPackage={item.package}
                packageLength={item.package_items.length}
                data-test="smallTag"
              />
              <div className={styles.itemStatusTitle} data-test="itemTitle">
                {item.name || '-'}
              </div>
            </div>
            <div className={styles.itemDueDate}>
              <span>Due: {item.date_due || item.estimated_date}</span>
            </div>
          </div>
        </Col>
        <Col xl={7} lg={24}>
          <ProgressBars
            title={formatMessage({ id: 'title.status' })}
            mxItem={item}
            hasAPU={hasAPU}
            aircraftThreshold={aircraftThreshold}
            data-test="progressBars"
          />
        </Col>
        <Col xl={16} lg={24} offset={1} className={styles.mxTimings}>
          <Row gutter={16}>
            <Col md={hasAPU ? 6 : 8} data-test="hrsCol">
              <MXTimeDetails
                title={formatMessage({ id: 'title.flightHrs' })}
                unitSingular={formatMessage({ id: 'units.hourShort' })}
                unitPlural={formatMessage({ id: 'units.hoursShort' })}
                item={hrsTimeDetails}
                data-test="mxTimeHrs"
              />
            </Col>
            <Col md={hasAPU ? 6 : 8} data-test="cyclesCol">
              <MXTimeDetails
                title={formatMessage({ id: 'title.cycles' })}
                unitSingular={formatMessage({ id: 'units.cycle' })}
                unitPlural={formatMessage({ id: 'units.cycles' })}
                item={cyclesTimeDetails}
                data-test="mxTimeCycles"
              />
            </Col>
            <Col md={hasAPU ? 6 : 8} data-test="monthsCol">
              <MXTimeDetails
                title={formatMessage({ id: 'title.months' })}
                unitSingular={formatMessage({ id: 'units.monthShort' })}
                unitPlural={formatMessage({ id: 'units.monthsShort' })}
                item={daysTimeDetails}
                data-test="mxTimeMonths"
              />
            </Col>
            {hasAPU && (
              <Col md={6} data-test="apuCol">
                <MXTimeDetails
                  title={formatMessage({ id: 'title.apuHours' })}
                  unitSingular={formatMessage({ id: 'units.hourShort' })}
                  unitPlural={formatMessage({ id: 'units.hoursShort' })}
                  item={apuHrsTimeDetails}
                  data-test="mxTimeAPU"
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <MXDetailsPackage item={item} inModal data-test="mxDetailsPackage" />
    </Card>
  );
};

ItemCard.propTypes = {
  item: PropTypes.object.isRequired,
  aircraftThreshold: PropTypes.object.isRequired,
  hasAPU: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

export default injectIntl(ItemCard);
