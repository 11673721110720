/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
// Forward Ref render functions cannot have prop types so we're disabling the rule for this file
/* eslint-disable react/prop-types */
import { Divider, Empty, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getFlightsForAircraft } from '../../models/flights/actions';
import Loading from '../TFLoading';
import styles from './index.module.less';

const { Option } = Select;

const SelectFlight = (props, ref) => {
  const { aircraftId, value, onChange } = props;

  const [loading, setLoading] = useState(true);
  const [newFlightsMap, setNewFlightsMap] = useState(new Map());
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  // Get data from Redux
  const {
    flights: { aircraftFlightsMaps, ttl, dirty },
    userSettings: { dateFormat },
  } = useSelector(({ flights, userSettings }) => ({ flights, userSettings }));
  // Update loading state based on Redux data
  const { flightsMap = new Map(), lastFetched = 0 } = aircraftFlightsMaps.get(aircraftId) || {};
  useEffect(() => {
    const details = aircraftFlightsMaps.get(aircraftId) || {};
    setNewFlightsMap(details.flightsMap);
  }, [aircraftFlightsMaps, aircraftId]);

  const usePrevious = (newValue) => {
    const newRef = useRef();
    useEffect(() => {
      newRef.current = newValue;
    });
    return newRef.current;
  };

  const prevFlightsMap = usePrevious(aircraftFlightsMaps);

  useEffect(() => {
    if (Date.now() - lastFetched >= ttl || dirty) {
      setLoading(true);
      if (aircraftId) {
        dispatch(
          getFlightsForAircraft({
            payload: { id: aircraftId },
          }),
        );
      }
    }
  }, [aircraftId, dirty, lastFetched, ttl]);

  useEffect(() => {
    if (aircraftFlightsMaps.size !== prevFlightsMap?.size) {
      setLoading(false);
    }
  }, [aircraftFlightsMaps, prevFlightsMap]);

  // create dropdown options
  const options = useMemo(
    () =>
      newFlightsMap && newFlightsMap.size > 0 && !loading
        ? Array.from(flightsMap.values()).map((item) => (
            <Option key={item.id}>
              {`#${item.trip.number || formatMessage({ id: 'text.newCaps' })}-${item.sector_number}`}
              <Divider type="vertical" />
              {moment(item.trip.date).format(dateFormat)}
              <Divider type="vertical" />
              {`${item.departure_airport} - ${item.arrival_airport || ''}`}
            </Option>
          ))
        : [],
    [newFlightsMap, loading, flightsMap, formatMessage, dateFormat],
  );

  return (
    <Select
      className={styles.selectFlight}
      placeholder={formatMessage({ id: 'form.placeholder.selectFlight' })}
      filterOption={false}
      notFoundContent={
        loading ? (
          <div className={styles.spinHolder}>
            <Loading loading contain width={50} height={50} />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      loading={loading}
      ref={ref}
      onChange={onChange}
      {...props}
    >
      {options}
    </Select>
  );
};

export default React.forwardRef(SelectFlight);
