/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import Styles from './TFTableStyling';

interface TFTableProps {
  columns: Array<any>;
  data: Array<any>;
  hiddenColumns?: Array<string>;
  handleRowClick: (id: string) => void | null;
}

const TFTable: React.FC<TFTableProps> = ({ columns, data, hiddenColumns, handleRowClick }) => {
  const tableInstance = useTable({ columns, data, initialState: { hiddenColumns } }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <table {...getTableProps()} autoResetHiddenColumns>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th width={column.width} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr tabIndex={0} {...row.getRowProps({ onClick: () => handleRowClick(row.values.id) })}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
};

export default TFTable;
