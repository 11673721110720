/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getTopAirports, getTopAircraft, getMonthlySummary, getRoutes, getOilConsumption } from '../../services/apiNew';
import { OperationsActionTypes } from '.';

export const fetchTopAirports = ({ payload }) => async (dispatch): Promise<any> => {
  const { data } = await getTopAirports(payload);
  dispatch({
    type: OperationsActionTypes.UPDATE_TOP_AIRPORTS,
    payload: {
      airports: data,
    },
  });
};
export const fetchTopAircraft = ({ payload }) => async (dispatch): Promise<any> => {
  const { data } = await getTopAircraft(payload);
  if (payload.date === 'year') {
    dispatch({
      type: OperationsActionTypes.UPDATE_TOP_AIRCRAFT_YEAR,
      payload: {
        prevYear: {
          aircraftHours: data.hours,
          aircraftFlights: data.flights,
        },
      },
    });
  } else if (payload.date === 'month') {
    dispatch({
      type: OperationsActionTypes.UPDATE_TOP_AIRCRAFT_MONTH,
      payload: {
        prevMonth: {
          aircraftHours: data.hours,
          aircraftFlights: data.flights,
        },
      },
    });
  } else {
    dispatch({
      type: OperationsActionTypes.UPDATE_TOP_AIRCRAFT_CURRENT,
      payload: {
        current: {
          aircraftHours: data.hours,
          aircraftFlights: data.flights,
        },
      },
    });
  }
};
export const fetchMonthlySummary = ({ payload }) => async (dispatch): Promise<any> => {
  const { data } = await getMonthlySummary(payload);
  dispatch({
    type: OperationsActionTypes.UPDATE_MONTHLY_SUMMARY,
    payload: {
      monthly: data,
    },
  });
};
export const fetchOilConsumption = ({ payload }) => async (dispatch): Promise<any> => {
  const data = await getOilConsumption(payload);
  dispatch({
    type: OperationsActionTypes.UPDATE_OIL_CONSUMPTION,
    payload: {
      oilConsumption: data,
    },
  });
};

export const fetchRoutes = ({ payload }) => async (dispatch): Promise<void> => {
  const promiseArray = [];
  const clonedAircraftPayload = [...payload.aircraft];

  do {
    const currentBatchOfAircraft = clonedAircraftPayload.splice(0, 20);
    promiseArray.push(getRoutes({ aircraft: currentBatchOfAircraft, from: payload.from, to: payload.to }));
  } while (clonedAircraftPayload.length > 20);

  const response: any = await Promise.all(promiseArray);
  const data = response.reduce((acc, curr) => {
    acc = acc.concat(curr.data);
    return acc;
  }, []);

  dispatch({
    type: OperationsActionTypes.UPDATE_ROUTES,
    payload: {
      routes: data,
    },
  });
};

export const clearMonthlySummary = () => (dispatch): any => {
  dispatch({
    type: OperationsActionTypes.CLEAR_MONTHLY,
  });
};

export const clearRoutes = () => (dispatch): any => {
  dispatch({
    type: OperationsActionTypes.CLEAR_ROUTES,
  });
};
