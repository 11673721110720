/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { MELItem, MELRectification } from '../../../models/mels';
import smoothScroller from '../../../utils/smoothScroller';
import QuestionMarkIcon from '../../../assets/icon-tooltip.svg';
import TFTooltip from '../../TFTooltip/TFTooltip';
import FlexWrapper from './FlexWrapper';
import StyledRadioInput from './StyledRadioInput';
import ViewMelDetails from './ViewMelDetails';

interface DefectMelItemsProps {
  melItems: Array<MELItem>;
  setMelItem: (input: MELItem) => void;
  handleMelItemChange: (melItemId: string, rectId: string) => void;
  defect: Defect | null;
}

const MelItemWrapper = styled.div`
  padding: 35px 20px;
  display: flex;
`;

const FirstColumn = styled.div`
  display: flex;
  align-items: 'center';
  margin-right: 46px;
  span {
    color: rgba(36, 45, 65, 0.8);
  }
`;

const Title = styled.span`
  color: rgba(36, 45, 65, 0.6);
  width: max-content;
  max-width: 500px;
`;

const SmallText = styled.span`
  color: rgba(36, 45, 65, 0.6);
  font-size: 12px;
  max-width: 500px;
  margin-right: ${({ margin }): string => (margin ? '20px' : '0')};
`;

const BlueText = styled.span`
  color: #1890ff;
  font-weight: 300;
  font-size: 12px;
`;

const BoldText = styled.span`
  font-weight: 500;
`;

const DefectMelItems: React.FC<DefectMelItemsProps> = ({ melItems, setMelItem, handleMelItemChange, defect }) => {
  const [selectedMelItem, setSelectedMelItem] = useState('');
  const [selectedRectification, setSelectedRectification] = useState(0);
  const [selectedRectificationId, setSelectedRectificationId] = useState('');
  const [showMelToolTip, setShowMelToolTip] = useState(false);

  const { formatMessage } = useIntl();

  const onMouseEnterMEL = (): void => {
    setShowMelToolTip(true);
  };

  const onMouseLeaveMEL = (): void => {
    setShowMelToolTip(false);
  };

  const IntervalOptions = {
    A: 'As per remarks',
    B: '3 days',
    C: '10 days',
    D: '120 days',
  };

  const handleMelItemClick = (item: MELItem): void => {
    setSelectedMelItem(item.id);
    setMelItem(item);
    setSelectedRectification(0);
    setSelectedRectificationId(item.mel_rectifications[0].id);
    handleMelItemChange(item.id, item.mel_rectifications[0].id);
  };

  const handleRectificationClick = (index: number, passedMelItem: MELItem, rectId: string): void => {
    setSelectedMelItem(passedMelItem?.id);
    setMelItem(passedMelItem);
    setSelectedRectification(index);
    setSelectedRectificationId(rectId);
    handleMelItemChange(passedMelItem?.id, rectId);
  };

  useEffect(() => {
    if (defect) {
      setSelectedMelItem(defect?.mel_item?.id);
      setSelectedRectificationId(defect?.mel_rectification_id);
    } else if (melItems && melItems.length > 0) {
      const firstMelItemId = melItems[0].id;
      setMelItem(melItems[0]);
      setSelectedMelItem(firstMelItemId);
    }
  }, [defect, melItems]);

  useEffect(() => {
    if (selectedMelItem) {
      const wrapper = document.getElementById('MELItemWrapper');
      const item = document.getElementById(`${selectedMelItem}`);
      if (wrapper && item) {
        smoothScroller({ scrollTo: item, scrollContainer: wrapper, scrollOffset: 40 });
      }
    }
  }, [selectedMelItem]);

  const melRectifications = (items: Array<MELRectification>, passedMelItem: MELItem): Array<ReactElement> => {
    return items.map((item: MELRectification, index: number) => {
      return (
        <FlexWrapper marginTop={15} key={item.id} column identifier="MelItemsWrapper">
          <FlexWrapper marginBottom={10} alignItems="center">
            <StyledRadioInput
              checked={item.id === selectedRectificationId}
              handleClick={(): void => {
                handleRectificationClick(index, passedMelItem, item.id);
              }}
              id={`Rectification${item.id}`}
              value={`${item.id}`}
              name="rectificationGroup"
            />
            <Title>{item.title || '-'}</Title>
          </FlexWrapper>
          <FlexWrapper marginBottom={10}>
            <SmallText margin data-testid="DefectMelItems--IntervalSection">
              {formatMessage({ id: 'title.rectificationInterval' })}:{' '}
              <BoldText data-testid="DefectMelItems--Interval">{item.interval}</BoldText>{' '}
              {IntervalOptions[item.interval]}
            </SmallText>
            <SmallText margin data-testid="DefectMelItems--NumberInstalled">
              {formatMessage({ id: 'title.numberInstalled' })}: {item.number_installed}
            </SmallText>
            <SmallText data-testid="DefectMelItems--NumberRequired">
              {formatMessage({ id: 'title.numberRequired' })}: {item.number_required}
            </SmallText>
          </FlexWrapper>
          <div>
            <SmallText data-testid="DefectMelItems--Details">Remarks: {item.remarks}</SmallText>
          </div>
        </FlexWrapper>
      );
    });
  };

  return (
    <>
      {melItems &&
        melItems.map((item) => {
          return (
            <MelItemWrapper id={item.id} key={item.id} data-testid="DefectMelItems--MelWrapper">
              <FirstColumn>
                <StyledRadioInput
                  checked={selectedMelItem === item.id}
                  handleClick={(): void => {
                    handleMelItemClick(item);
                  }}
                  marginTop={2}
                  identifier="MelItem"
                  id={`MelItem${item.id}`}
                  value={`${item.id}`}
                  name="melItemGroup"
                />
                <Title data-testid="DefectMelItems--MelNumber">
                  {item.chapter_number}-{item.section_number}-{item.subsection_number}
                </Title>
              </FirstColumn>
              <div>
                <FlexWrapper marginBottom={5} column>
                  <FlexWrapper>
                    <Title data-testid="DefectMelItems--MelTitle">{item.title}</Title>
                    {/* <TFTooltip
                      showToolTip={showMelToolTip}
                      handleMouseLeave={onMouseLeaveMEL}
                      handleMouseOver={onMouseEnterMEL}
                    >
                      <ViewMelDetails defect={defect} isMelTableVisible />
                    </TFTooltip> */}
                  </FlexWrapper>
                  {item.mel_rectifications && item.mel_rectifications.length > 1 && (
                    <BlueText data-testid="DefectMelItems--MultipleOptions">
                      {formatMessage({ id: 'text.thisChapterContainsMultipleOptions' })}
                    </BlueText>
                  )}
                </FlexWrapper>
                {item.mel_rectifications && melRectifications(item.mel_rectifications, item)}
              </div>
            </MelItemWrapper>
          );
        })}
    </>
  );
};

export default DefectMelItems;
