import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import styles from './TimeInput.module.less';

interface TimeInputProps {
  input: number;
  onChange: (value: number) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({ input, onChange }): ReactElement => {
  const [hours, setHours] = useState(undefined);
  const [mins, setMins] = useState(undefined);
  useEffect(() => {
    if (input) {
      setHours(Math.floor(input / 3600));
      setMins(Math.floor(input / 60) % 60);
    }
  }, [input]);

  const calculateTime = (hrs: number, minutes: number): number => {
    const newTime = (hrs || 0) * 3600 + (minutes || 0) * 60;
    return newTime;
  };

  const handleHoursChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setHours(event.target.value);
    onChange(calculateTime(parseInt(event.target.value, 10), mins));
  };

  const handleMinsChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setMins(event.target.value);
    onChange(calculateTime(hours, parseInt(event.target.value, 10)));
  };
  return (
    <>
      <input type="number" value={hours} onChange={handleHoursChange} min={0} className={styles.hoursInput} />
      {' : '}
      <input type="number" value={mins} onChange={handleMinsChange} min={0} max={59} className={styles.minsInput} />
    </>
  );
};

export default TimeInput;
