/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Card, Input, Button, Select, Badge, Dropdown, Menu, Icon, Modal, Form, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import 'react-image-crop/dist/ReactCrop.css';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import EXIF from 'exif-js';
import Magnifier from '../../components/Magnifier';
import servers from '../../utils/servers';
import Loading from '../../components/TFLoading';
import uploadIcon from '../../assets/upload-doc-icon.svg';
import schematics from '../../assets/emptyState/empty-state-damage-maps.svg';
import Header from '../../components/Modals/common/header';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import Footer from '../../components/Modals/common/footer';
import { fetch as fetchDamageMaps, setDefaultImage, add as addDamageMap } from '../../models/damageMaps/actions';
import { fetch as fetchDamageReports } from '../../models/damageReports/actions';
import SchematicViewModalBody from './SchematicViewModalBody';
import DisplayThumbnails from './DisplayThumbnails';
import styles from './DamageMaps.module.less';

const { Option } = Select;
const cookies = new Cookies();
const mobileViewWidth = 576;

const globalAspectRatio = 1.78; // fixed equivalent of 16x9
const globalAspectRatioInverse = 9 / 16;

class SchematicView extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    damageMaps: PropTypes.object.isRequired,
    damageReports: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      categorySelectorButtons: [],
      categorySelectorDropDown: [],
      categoryObject: {},
      damageMaps: [],
      displayingImage: 'default',
      imageBlob: '',
      damageMapId: '',
      showUploadImage: false,
      imageIsUploading: false,
      newThumbnails: [],
      showThumbnail: false,
      showCoordinates: false,
      thumbnailIndex: null,
      uploadedFileName: '',
      detailsHaveBeenSaved: false,
      thumbnailComplete: false,
      completeButtonClicked: false,
      imageLoaded: false,
      viewTitle: 'all',
      categoryInput: props.intl.formatMessage({ id: 'text.categoryNotSet' }),
      categoryButtonSelected: '',
      titleInput: '',
      fileList: [],
      categorySelectorButtonsNames: ['general', 'fuselage', 'wings', 'empennage', 'powerplant', 'propellor', 'windows'],
      mainImageInModalSrc: null,
      crop: {
        aspect: globalAspectRatio,
        width: 1000,
      },
      magnifierHeight: this.calcMagnifierHeight(),
    };
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.getDamageMaps();
      this.getDamageReports();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { damageReports, damageMaps, match } = this.props;
    const { displayingImage } = this.state;
    if (match.params.id !== prevProps.match.params.id) {
      this.getDamageMaps();
      this.getDamageReports();
      this.setDefaultImage();
    }
    if (damageMaps.damageMapsMap !== prevProps.damageMaps.damageMapsMap) {
      this.setUpDamageMaps();
    }
    if (damageReports.damageReportsMap !== prevProps.damageReports.damageReportsMap) {
      this.setUpDamageReports();
    }
    if (displayingImage && displayingImage !== prevState.displayingImage) {
      this.makeImageABlob();
    }
  }

  setUpDamageMaps = () => {
    const { match, damageMaps } = this.props;
    const newDamageMapsArr = Array.from(damageMaps.damageMapsMap.get(match.params.id).values());
    this.setState(
      {
        damageMaps: newDamageMapsArr,
        imageIsUploading: false,
      },
      () => {
        this.getSelectorButtons();
        this.getSelectorDropDown();
        this.gatherImages(newDamageMapsArr);
        this.gatherTitles();
        this.closeModal();
      },
    );
  };

  setUpDamageReports = () => {
    const { damageReports, match } = this.props;
    this.setState({
      damageReports: Array.from(damageReports.damageReportsMap.get(match.params.id).values()),
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    if (crop.height === 0 && crop.width === 0) {
      this.setState({ croppedImageUrl: null });
    }
    this.setState({ crop });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const { width, height } = canvas;
    const ctx = canvas.getContext('2d');

    // Rotate image on crop based on EXIF orientation
    // eslint-disable-next-line func-names
    EXIF.getData(image, function() {
      const orientation = EXIF.getTag(this, 'Orientation');
      if (orientation) {
        switch (orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }
      }

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
    });

    return new Promise((resolve) => {
      const { intl } = this.props;
      canvas.toBlob((blob) => {
        const newBlob = blob;
        if (!newBlob) {
          message.error(intl.formatMessage({ id: 'message.canvasEmpty' }));
          return;
        }
        newBlob.name = fileName;
        this.fileUrl = window.URL.createObjectURL(newBlob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getDamageMaps() {
    const { dispatch, match } = this.props;
    dispatch(
      fetchDamageMaps({
        payload: {
          id: match.params.id,
        },
      }),
    );
  }

  getDamageReports = () => {
    this.props.dispatch(
      fetchDamageReports({
        payload: {
          id: this.props.match.params.id,
        },
      }),
    );
  };

  getMainButtons = (button) => {
    const { categoryObject, categoryButtonSelected } = this.state;
    let buttonText = button;
    if (button === 'general') {
      buttonText = 'all';
    } else if (button === 'propellor') {
      buttonText = 'propeller';
    }
    const buttons = (
      <Button
        className={categoryButtonSelected === button ? styles.selectedSelectorButton : styles.selectorButton}
        onClick={() => {
          if (categoryButtonSelected === button) {
            return null;
          }
          this.updateSelectedButton(button);
          if (categoryObject[button] && categoryObject[button].length > 0) {
            this.displayCorrectImage(categoryObject[button][0].title);
          } else {
            this.setDefaultImage();
          }
          return null;
        }}
        disabled={button === 'general' || false}
        key={button}
      >
        {this.props.intl.formatMessage({
          id: `form.button.${buttonText}`,
        })}
      </Button>
    );
    return buttons;
  };

  getSelectorButtons = () => {
    const { categorySelectorButtonsNames } = this.state;
    const buttons = categorySelectorButtonsNames.map((button) => {
      if (this.getDropdownCategories(button).some((arr) => typeof arr !== 'undefined')) {
        return (
          <Dropdown
            placement="bottomCenter"
            key={`selectorButton-${button}`}
            overlay={<Menu>{this.getDropdownCategories(button)}</Menu>}
            disabled={button === 'general' || false}
          >
            {this.getMainButtons(button)}
          </Dropdown>
        );
      }
      return this.getMainButtons(button);
    });
    this.setState({ categorySelectorButtons: buttons });
  };

  getSelectorDropDown = () => {
    const { categorySelectorButtonsNames } = this.state;
    const dropdownOptions = categorySelectorButtonsNames.map((button) => {
      let buttonText = button;
      if (button === 'general') {
        buttonText = 'all';
      } else if (button === 'propellor') {
        buttonText = 'propeller';
      }
      return (
        <Option
          key={`selectorDropdown-${button}`}
          className={styles.option}
          value={button}
          disabled={button === 'general' || false}
        >
          {this.props.intl.formatMessage({
            id: `form.button.${buttonText}`,
          })}
        </Option>
      );
    });
    this.setState({ categorySelectorDropDown: dropdownOptions });
  };

  getDropdownCategories = (button) => {
    const { damageMaps, categoryObject, viewTitle } = this.state;
    if (!damageMaps) {
      return null;
    }
    return damageMaps
      .filter((damageMap) => damageMap.category === button && damageMap.title !== null && damageMap.title !== '')
      .map((damageMap) => (
        <Menu.Item
          key={damageMap.id}
          onClick={() => {
            if (categoryObject[button] && viewTitle === damageMap.title) {
              return null;
            }
            this.updateSelectedButton(button);
            if (categoryObject[button] && categoryObject[button].length > 0) {
              this.displayCorrectImage(damageMap.title);
            } else {
              this.setDefaultImage();
            }
            return null;
          }}
          disabled={button === 'general' || false}
        >
          {damageMap.title}
        </Menu.Item>
      ));
  };

  getDropdownCategoriesSub = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { categoryObject, categoryButtonSelected, categorySelectorButtonsNames } = this.state;
    const dropdownOptions = categorySelectorButtonsNames.map((button) => {
      let buttonText = button;
      if (button === 'general') {
        buttonText = 'all';
      } else if (button === 'propellor') {
        buttonText = 'propeller';
      }
      return (
        <SubMenu
          key={button}
          title={formatMessage({
            id: `form.button.${buttonText}`,
          })}
          className={styles.subMenu}
          onTitleClick={() => {
            if (categoryButtonSelected === button) {
              return null;
            }
            this.updateSelectedButton(button);
            if (categoryObject[button] && categoryObject[button].length > 0) {
              this.displayCorrectImage(categoryObject[button][0].title);
            } else {
              this.setDefaultImage();
            }
            return null;
          }}
          disabled={button === 'general' || false}
        >
          {this.getDropdownCategories(button)}
        </SubMenu>
      );
    });
    return <Menu>{dropdownOptions}</Menu>;
  };

  getModalHeader = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Header
        headerIcon={uploadIcon}
        handleModalVisible={this.handleModalVisible}
        headerTitle={formatMessage({ id: 'title.uploadSchematics' })}
        headerSubtitle={<span>{formatMessage({ id: 'text.uploadSchematicImage' })}</span>}
      />
    );
  };

  getModalFooter = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    let savedDetails = false;
    this.state.newThumbnails.forEach((item) => {
      if (item[2] && item[1] !== formatMessage({ id: 'text.categoryNotSet' })) {
        savedDetails = true;
      }
    });
    return (
      <Footer
        handleModalVisible={this.handleModalVisible}
        submitButtonText={formatMessage({ id: 'form.button.complete' })}
        submitNotClickable={!savedDetails}
        completeButtonClickable={this.state.completeButtonClicked}
        handleFormUpload={() => this.checkIfItemsNeedSaved()}
        type="primary"
      />
    );
  };

  getMagnifierImageDetails = (zoomedWidth, zoomedHeight) => {
    this.setState({ zoomedHeight, zoomedWidth });
  };

  getBase64s = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      EXIF.getData(file, () => {
        const orientation = EXIF.getTag(this, 'Orientation');
        const can = document.createElement('canvas');
        const ctx = can.getContext('2d');
        const thisImage = new Image();
        let imageType = 'thin';
        thisImage.onload = () => {
          const imageWidth = thisImage.width;
          const imageHeight = thisImage.height;
          const image169Height = imageWidth * globalAspectRatioInverse;
          if (imageHeight > image169Height) {
            // image is higher than 16x9
            can.height = imageHeight;
            can.width = imageWidth + (imageHeight - image169Height) * globalAspectRatio;
            imageType = 'high';
          } else {
            // image is lower than 16x9
            can.width = imageWidth;
            can.height = image169Height;
          }

          ctx.save();
          const { width } = can;
          const styleWidth = can.style.width;
          const { height } = can;
          const styleHeight = can.style.height;
          if (orientation) {
            if (orientation > 4) {
              can.width = height;
              can.style.width = styleHeight;
              can.height = width;
              can.style.height = styleWidth;
            }
            switch (orientation) {
              case 2:
                ctx.transform(-1, 0, 0, 1, width, 0);
                break;
              case 3:
                ctx.transform(-1, 0, 0, -1, width, height);
                break;
              case 4:
                ctx.transform(1, 0, 0, -1, 0, height);
                break;
              case 5:
                ctx.transform(0, 1, 1, 0, 0, 0);
                break;
              case 6:
                ctx.transform(0, 1, -1, 0, height, 0);
                break;
              case 7:
                ctx.transform(0, -1, -1, 0, height, width);
                break;
              case 8:
                ctx.transform(0, -1, 1, 0, 0, width);
                break;
              default:
                break;
            }
          }
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, can.width + 2, can.height + 2);
          let startX = 0;
          let startY = 0;
          if (imageType === 'thin') {
            startY = can.height / 2 - imageHeight / 2;
          } else {
            startX = can.width / 2 - imageWidth / 2;
          }
          ctx.drawImage(thisImage, startX, startY);
          ctx.restore();
          const dataURL = can.toDataURL();
          this.setState({
            mainImageInModalSrc: dataURL,
            showThumbnail: true,
            thumbnailIndex: null,
            uploadedFileName: file.name,
          });
        };
        thisImage.src = URL.createObjectURL(file);
      });
    };
  };

  setDefaultImage = () => {
    this.setState(
      {
        displayingImage: 'default',
      },
      () => {
        this.props.dispatch(
          setDefaultImage({
            payload: '',
          }),
        );
      },
    );
  };

  handleModalVisible = (visible) => {
    this.setState({ showUploadImage: visible }, () => this.resetModal());
  };

  handleClickAddNewImage = (showThumbnail, thumbnailIndex, titleInput, categoryInput) => {
    this.setState({
      showThumbnail,
      thumbnailIndex,
      titleInput,
      categoryInput,
      crop: {
        aspect: globalAspectRatio,
        width: 1000,
      },
    });
  };

  handleClickLeftArrow = () => {
    const { thumbnailIndex } = this.state;
    if (thumbnailIndex > 0) {
      this.setState({ thumbnailIndex: thumbnailIndex - 1 }, () => {
        this.displayThumbnailInMainView();
      });
    }
  };

  handleRightArrowClick = () => {
    const { newThumbnails, thumbnailIndex } = this.state;
    if (newThumbnails.length === 0) {
      return null;
    }
    if (thumbnailIndex === null || 0) {
      this.setState({ thumbnailIndex: 0 }, () => {
        this.displayThumbnailInMainView();
      });
    } else if (thumbnailIndex !== newThumbnails.length - 1) {
      this.setState({ thumbnailIndex: thumbnailIndex + 1 }, () => {
        this.displayThumbnailInMainView();
      });
    }
    return null;
  };

  handleInput = (value) => {
    this.setState({ titleInput: value });
  };

  handleSelectChange = (value) => {
    this.setState({ categoryInput: value });
  };

  handleFileListChange = (fileList) => {
    this.setState({ fileList });
  };

  addNewDamageReportImage = () => {
    const {
      newThumbnails,
      thumbnailIndex,
      mainImageInModalSrc,
      crop,
      showThumbnail,
      categorySelectorDropDown,
      titleInput,
      categoryInput,
      fileList,
      imageIsUploading,
      categoryButtonSelected,
    } = this.state;

    return (
      <SchematicViewModalBody
        newThumbnails={newThumbnails}
        thumbnailIndex={thumbnailIndex}
        mainImageInModalSrc={mainImageInModalSrc}
        crop={crop}
        showThumbnail={showThumbnail}
        fileList={fileList}
        handleFileListChange={this.handleFileListChange}
        categorySelectorDropDown={categorySelectorDropDown}
        titleInput={titleInput}
        categoryInput={categoryInput}
        categoryButtonSelected={categoryButtonSelected}
        imageIsUploading={imageIsUploading}
        handleClickAddNewImage={this.handleClickAddNewImage}
        handleClickLeftArrow={this.handleClickLeftArrow}
        handleRightArrowClick={this.handleRightArrowClick}
        handleInput={this.handleInput}
        handleSelectChange={this.handleSelectChange}
        displayThumbnails={this.displayThumbnails}
        handleBeforeUpload={this.handleBeforeUpload}
        onImageLoaded={this.onImageLoaded}
        onCropComplete={this.onCropComplete}
        onCropChange={this.onCropChange}
        handleFileUpload={this.handleFileUpload}
        handleSaveButtonPress={this.handleSaveButtonPress}
      />
    );
  };

  updateSelectedButton = (button) => {
    this.setState({ categoryButtonSelected: button }, () => {
      this.getSelectorButtons();
    });
  };

  handleThumbnailClick = (index) => {
    this.setState({ thumbnailIndex: index }, () => {
      this.displayThumbnailInMainView();
    });
  };

  displayThumbnails = () => {
    const { newThumbnails } = this.state;
    return <DisplayThumbnails newThumbnails={newThumbnails} handleThumbnailClick={this.handleThumbnailClick} />;
  };

  displayThumbnailInMainView = () => {
    const index = this.state.thumbnailIndex;
    const { newThumbnails } = this.state;
    this.setState(
      {
        mainImageInModalSrc: newThumbnails[index][0],
        categoryInput: newThumbnails[index][1],
        titleInput: newThumbnails[index][2],
      },
      () => {
        this.setState({ showThumbnail: true });
      },
    );
  };

  handleSave = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const {
      croppedImageUrl,
      categoryInput,
      titleInput,
      mainImageInModalSrc,
      thumbnailIndex,
      newThumbnails,
      allTitles,
      uploadedFileName,
    } = this.state;
    if (mainImageInModalSrc === null) {
      message.error(formatMessage({ id: 'message.imageMustBeAdded' }));
      return null;
    }
    const thumbnailTitles = newThumbnails.map((thumbnail) => {
      return thumbnail[2];
    });
    if (allTitles.includes(titleInput) || thumbnailTitles.includes(titleInput)) {
      if (thumbnailTitles[thumbnailIndex] !== titleInput) {
        message.error(formatMessage({ id: 'message.titleNotUnique' }));
        return null;
      }
    }
    let newThumbnail = [];
    if (croppedImageUrl) {
      newThumbnail = [[croppedImageUrl, categoryInput, titleInput, uploadedFileName]];
    } else {
      newThumbnail = [[mainImageInModalSrc, categoryInput, titleInput, uploadedFileName]];
    }
    this.setState(
      (prevState) => {
        if (thumbnailIndex !== null) {
          const amendedState = prevState.newThumbnails.map((item, index) => {
            if (index === thumbnailIndex) {
              let currentItem = item;
              const newItem = [croppedImageUrl || mainImageInModalSrc, categoryInput, titleInput, uploadedFileName];
              currentItem = newItem;
              return currentItem;
            }
            return item;
          });
          return {
            newThumbnails: amendedState,
            croppedImageUrl: null,
            crop: {
              aspect: globalAspectRatio,
            },
          };
        }
        return {
          newThumbnails: prevState.newThumbnails.concat(newThumbnail),
          thumbnailIndex: newThumbnails.length,
          croppedImageUrl: null,
          crop: {
            aspect: globalAspectRatio,
          },
        };
      },
      () => {
        this.displayThumbnails();
        this.displayThumbnailInMainView();
      },
    );
    return null;
  };

  handleSaveButtonPress = () => {
    if (this.state.titleInput === '' || this.state.titleInput.replace(/\s/g, '').length <= 0) {
      message.error(this.props.intl.formatMessage({ id: 'message.titleMustBeAdded' }));
    } else {
      this.handleSave();
    }
  };

  checkIfItemsNeedSaved = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { titleInput, newThumbnails, categoryInput, croppedImageUrl, mainImageInModalSrc } = this.state;
    const index = this.state.thumbnailIndex;
    if (titleInput === '') {
      return message.error(formatMessage({ id: 'message.titleMustBeAdded' }));
    }
    if (newThumbnails && newThumbnails.length === 0) {
      message.error(formatMessage({ id: 'message.changesNotSaved' }));
    } else if (
      newThumbnails[index] &&
      (titleInput !== newThumbnails[index][2] || categoryInput !== newThumbnails[index][1] || croppedImageUrl)
        ? croppedImageUrl !== mainImageInModalSrc
        : null
    ) {
      message.error(formatMessage({ id: 'message.changesNotSaved' }));
    } else {
      this.setState({ detailsHaveBeenSaved: true }, () => {
        this.checkAllThumbnailsAreComplete();
      });
    }
    return null;
  };

  resetModal = () => {
    this.setState({
      mainImageInModalSrc: null,
      newThumbnails: [],
      thumbnailIndex: null,
      showThumbnail: false,
      thumbnailComplete: false,
      detailsHaveBeenSaved: false,
      completeButtonClicked: false,
      titleInput: '',
      uploadedFileName: '',
      categoryInput: this.props.intl.formatMessage({ id: 'text.categoryNotSet' }),
      crop: {
        aspect: globalAspectRatio,
        width: 1000,
      },
    });
  };

  closeModal = () => {
    this.setState({ showUploadImage: false }, () => {
      this.resetModal();
    });
  };

  handleFileUpload = (info) => {
    this.getBase64s(info.file);
  };

  checkAllThumbnailsAreComplete = () => {
    const { detailsHaveBeenSaved, newThumbnails } = this.state;
    let savedDetails = false;
    if (detailsHaveBeenSaved === true) {
      const checkArray = newThumbnails.filter((item) => {
        return item[2] && item[1] !== this.props.intl.formatMessage({ id: 'text.categoryNotSet' });
      });
      if (checkArray.length === newThumbnails.length && newThumbnails.length !== 0) {
        savedDetails = true;
      }
    }
    this.setState({ thumbnailComplete: savedDetails }, () => {
      this.handleSubmit();
    });
  };

  makeBase64ToBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    return blob;
  };

  makeBlobUrlIntoBlob = async (url) => {
    let blob = null;
    try {
      const response = await fetch(url);
      blob = await response.blob();
      return blob;
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { newThumbnails, thumbnailComplete } = this.state;

    if (thumbnailComplete === true) {
      this.setState({ completeButtonClicked: true });
      newThumbnails.forEach(async (thumbnail) => {
        const blobObject = thumbnail[0].startsWith('blob')
          ? await this.makeBlobUrlIntoBlob(thumbnail[0])
          : this.makeBase64ToBlob(thumbnail[0].split(',')[1]);
        const formData = new FormData();
        if (thumbnail[3].endsWith('.jpeg' || '.jpg' || '.png')) {
          formData.append(`attachment[attachment]`, blobObject, thumbnail[3]);
        } else {
          formData.append(`attachment[attachment]`, blobObject, thumbnail[3].concat('.png'));
        }
        formData.append('title', thumbnail[2]);
        formData.append('category', thumbnail[1]);
        formData.append('sort_order', 1);
        this.setState({ imageIsUploading: true });
        dispatch(
          addDamageMap({
            payload: {
              id: this.props.match.params.id,
              form: formData,
            },
          }),
        );
      });
    } else {
      message.error(this.props.intl.formatMessage({ id: 'message.aThumbnailNotComplete' }));
      this.setState({ completeButtonClicked: false });
    }
  };

  gatherTitles = () => {
    const { damageMaps } = this.state;
    const allTitles = damageMaps.map((map) => {
      return map.title;
    });
    this.setState({ allTitles });
  };

  gatherImages = (damageMaps) => {
    const attachments = damageMaps.reduce((total, map) => {
      const result = total;
      if (!total[map.category]) {
        result[map.category] = [
          {
            title: map.title,
            url: map.attachment.url,
            id: map.id,
          },
        ];
      } else {
        result[map.category].push({
          title: map.title,
          url: map.attachment.url,
          id: map.id,
        });
      }
      return result;
    }, {});
    this.setState({ categoryObject: attachments }, () => {
      this.checkForPreviousImage();
    });
  };

  checkForPreviousImage = () => {
    const { damageMaps } = this.props;
    if (damageMaps.image && damageMaps.image.url !== '') {
      const prevImage = damageMaps.image.url;
      this.setState(
        {
          displayingImage: prevImage,
          damageMapId: damageMaps.image.id,
          nonDefaultImage: true,
          categoryButtonSelected: damageMaps.image.category,
          showCoordinates: false,
        },
        () => {
          this.getSelectorButtons();
        },
      );
    } else {
      const firstGeneralMap = this.state.damageMaps.find((e) => e.category === 'general');
      if (firstGeneralMap) {
        this.displayCorrectImage(firstGeneralMap.title);
        this.setState({ categoryButtonSelected: 'general' }, () => {
          this.getSelectorButtons();
        });
      }
      this.setState({ imageLoaded: true });
    }
  };

  displayCorrectImage = (title) => {
    const { dispatch } = this.props;
    const categories = this.state.categoryObject;
    Object.keys(categories).forEach((category) => {
      const categoryArray = categories[category];
      Object.keys(categoryArray).forEach((object) => {
        const categoryObject = categoryArray[object];
        if (categoryObject.title === title && categoryObject.url !== null) {
          this.setState(
            {
              displayingImage: categoryObject.url,
              damageMapId: categoryObject.id,
              nonDefaultImage: true,
              viewTitle: title,
              showCoordinates: false,
            },
            () => {
              dispatch(
                setDefaultImage({
                  payload: {
                    url: this.state.displayingImage,
                    id: categoryObject.id,
                    category,
                  },
                }),
              );
            },
          );
        }
      });
    });
  };

  displayCoordinates = () => {
    const { damageMapId, globalFilter, zoomedHeight, zoomedWidth, damageReports } = this.state;
    return damageReports
      ? damageReports
          .filter(
            (report) =>
              report.damage_map_id === damageMapId &&
              (report.type
                ? report.type.toLowerCase().includes(globalFilter ? globalFilter.toLowerCase() : '')
                : null) &&
              report.y_coordinate &&
              report.x_coordinate,
          )
          .map((report) => {
            const xPercentage = report.x_coordinate / 0.5;
            const yPercentage = report.y_coordinate / 0.5;
            const midImageWidth = zoomedWidth / 2;
            const midImageHeight = zoomedHeight / 2;
            const widthDistance = midImageWidth * xPercentage;
            const heightDistance = midImageHeight * yPercentage;
            const actualWidth = midImageWidth + widthDistance - 49;
            const actualHeight = midImageHeight + heightDistance - 33;
            let displayBadge = <Badge className={styles.badge} status="warning" />;
            if (report.status === 'reported') {
              displayBadge = <Badge className={styles.badge} status="error" />;
            } else if (report.status === 'approved') {
              displayBadge = <Badge className={styles.badge} status="success" />;
            }
            return (
              <div key={report.id} className={styles.pinDiv} style={{ top: actualHeight, left: actualWidth }}>
                <Link
                  to={{
                    pathname: this.props.match.params.id
                      ? `/aircraft/${this.props.match.params.id}/damage_maps/list_view`
                      : '',
                    state: {
                      damageReportID: report.id,
                    },
                  }}
                >
                  <span className={styles.label}>
                    {displayBadge}
                    <span className={styles.labelText}>{report.type}</span>
                  </span>
                  <div className={styles.triangleDown} />
                </Link>
              </div>
            );
          })
      : null;
  };

  calcMagnifierHeight = () => {
    if (window.innerWidth < 440) {
      return 300;
    }
    return 500;
  };

  makeImageABlob = async () => {
    const { displayingImage } = this.state;
    if (displayingImage === 'default') {
      this.setState({ imageBlob: '', imageLoaded: true });
    } else {
      const url = displayingImage || '';
      try {
        const response = await fetch(url.startsWith('http') ? url : `${servers.api}/${url}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${cookies.get('auth')}`,
          },
        });
        const blob = await response.blob();
        this.setState({ imageBlob: blob, showCoordinates: true, imageLoaded: true });
      } catch (err) {
        console.error(err);
      }
    }
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({ croppedImageUrl });
    }
  }

  render() {
    const {
      match,
      intl: { formatMessage },
    } = this.props;
    const {
      damageMaps,
      imageBlob,
      nonDefaultImage,
      globalFilter,
      categorySelectorButtons,
      viewTitle,
      showUploadImage,
      showCoordinates,
      imageLoaded,
      magnifierHeight,
    } = this.state;
    return (
      <>
        <Card bordered={false}>
          <div className={styles.filterDiv}>
            <Input
              value={globalFilter}
              className={styles.filterBox}
              onChange={(e) => {
                this.setState({ globalFilter: e.target.value });
              }}
              placeholder={formatMessage({ id: 'form.placeholder.searchDamageMaps' })}
            />
            <AircraftAuthenticationWrapper
              aircraftId={match.params.id}
              requiredResource={AircraftResource.DAMAGE_MAP}
              requiredPermissionLevel={AircraftPermission.CREATE}
            >
              <Button
                type="primary"
                icon="plus"
                className={styles.addNewButton}
                onClick={() => this.setState({ showUploadImage: true })}
              >
                <span className={styles.buttonText}>{formatMessage({ id: 'form.button.uploadNewSchematic' })}</span>
              </Button>
            </AircraftAuthenticationWrapper>
          </div>
          <div className={styles.selectorButtons}>{categorySelectorButtons}</div>
          <div className={styles.selectorDropdown}>
            <span>{formatMessage({ id: 'text.youAreViewing' })}</span>
            <Dropdown placement="bottomCenter" overlay={this.getDropdownCategoriesSub()}>
              <Button type="link" className={styles.dropdown}>
                {viewTitle} <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
          <div className={nonDefaultImage ? styles.imageContainer : styles.defaultImageContainer}>
            <Loading loading={!imageLoaded} contain />
            {damageMaps && imageBlob ? (
              <div className={styles.damageMapContainer}>
                {imageBlob.size === 0 && (
                  <div>
                    {window.innerWidth < mobileViewWidth ? (
                      <div className={styles.defaultImageMobile}>
                        <div className={styles.imageDivMobile}>
                          <img
                            alt={formatMessage({ id: 'text.defaultImage' })}
                            src={schematics}
                            className={styles.defaultPlaneImage}
                          />
                        </div>
                        <div className={styles.defaultDiv}>
                          <h1 className={styles.defaultText}>{formatMessage({ id: 'text.uploadSchematic' })}</h1>
                          <p className={styles.defaultParagraph}>{formatMessage({ id: 'text.uploadText' })}</p>
                          <Button
                            type="primary"
                            className={styles.defaultButton}
                            onClick={() => this.setState({ showUploadImage: true })}
                          >
                            <span className={styles.uploadText}>{formatMessage({ id: 'form.button.upload' })}</span>{' '}
                            <Icon type="upload" />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.defaultImage}>
                        <img
                          alt={formatMessage({ id: 'text.defaultImage' })}
                          src={schematics}
                          className={styles.defaultPlaneImage}
                        />
                        <div className={styles.defaultDiv}>
                          <h1 className={styles.defaultText}>{formatMessage({ id: 'text.uploadSchematic' })}</h1>
                          <p className={styles.defaultParagraph}>{formatMessage({ id: 'text.uploadText' })}</p>
                          <Button
                            type="primary"
                            className={styles.defaultButton}
                            onClick={() => this.setState({ showUploadImage: true })}
                          >
                            <span className={styles.uploadText}>{formatMessage({ id: 'form.button.upload' })}</span>{' '}
                            <Icon type="upload" />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div>
                  <div style={{ position: 'relative' }}>
                    {imageBlob && imageBlob.size !== 0 && (
                      <Magnifier
                        file={imageBlob}
                        height={magnifierHeight}
                        getMagnifierImageDetails={this.getMagnifierImageDetails}
                        displayCoordinates={showCoordinates && nonDefaultImage ? this.displayCoordinates : null}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.spinDiv}>
                {window.innerWidth < mobileViewWidth ? (
                  <div className={styles.defaultImageMobile}>
                    <div className={styles.imageDivMobile}>
                      <img
                        alt={formatMessage({ id: 'text.defaultImage' })}
                        src={schematics}
                        className={styles.defaultPlaneImage}
                      />
                    </div>
                    <div className={styles.defaultDiv}>
                      <h1 className={styles.defaultText}>{formatMessage({ id: 'text.uploadSchematic' })}</h1>
                      <p className={styles.defaultParagraph}>{formatMessage({ id: 'text.uploadText' })}</p>
                      <AircraftAuthenticationWrapper
                        aircraftId={match.params.id}
                        requiredResource={AircraftResource.DAMAGE_MAP}
                        requiredPermissionLevel={AircraftPermission.CREATE}
                      >
                        <Button
                          type="primary"
                          className={styles.defaultButton}
                          onClick={() => this.setState({ showUploadImage: true })}
                        >
                          <span className={styles.uploadText}>{formatMessage({ id: 'form.button.upload' })}</span>{' '}
                          <Icon type="upload" />
                        </Button>
                      </AircraftAuthenticationWrapper>
                    </div>
                  </div>
                ) : (
                  <div className={styles.defaultImage}>
                    <img
                      alt={formatMessage({ id: 'text.defaultImage' })}
                      src={schematics}
                      className={styles.defaultPlaneImage}
                    />
                    <div className={styles.defaultDiv}>
                      <h1 className={styles.defaultText}>{formatMessage({ id: 'text.uploadSchematic' })}</h1>
                      <p className={styles.defaultParagraph}>{formatMessage({ id: 'text.uploadText' })}</p>
                      <AircraftAuthenticationWrapper
                        aircraftId={match.params.id}
                        requiredResource={AircraftResource.DAMAGE_MAP}
                        requiredPermissionLevel={AircraftPermission.CREATE}
                      >
                        <Button
                          type="primary"
                          className={styles.defaultButton}
                          onClick={() => this.setState({ showUploadImage: true })}
                        >
                          <span className={styles.uploadText}>{formatMessage({ id: 'form.button.upload' })}</span>{' '}
                          <Icon type="upload" />
                        </Button>
                      </AircraftAuthenticationWrapper>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
        <Modal
          title={this.getModalHeader()}
          visible={showUploadImage}
          className={styles.modal}
          width={900}
          destroyOnClose
          onCancel={this.closeModal}
          closable={false}
          footer={this.getModalFooter()}
        >
          {this.addNewDamageReportImage()}
        </Modal>
      </>
    );
  }
}

const schematicViewForm = Form.create()(SchematicView);
const schematicViewWithRedux = connect(({ damageMaps, damageReports }) => ({
  damageMaps,
  damageReports,
}))(schematicViewForm);
export default withRouter(injectIntl(schematicViewWithRedux));
