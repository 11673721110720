/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
// import { useTable, useSortBy, usePagination } from 'react-table';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { Table } from '@arcflight/tf-component-library';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Tag, Dropdown, Menu } from 'antd';
import defaults from '../../utils/defaults';
import EmptyStateWorkpacks from '../../assets/emptyState/empty-state-workpacks.svg';
// import threeDotMenu from '../../assets/more-options.svg';
// import TableTitle from '../../components/StandardTable/TableTitle';
import DefectPagination from '../../components/PaginatedDefectsTable/DefectPagination';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource } from '../../models/aircraft';
import StatusBadge from '../../components/Status/StatusBadge';
// import defaults from '../../utils/defaults';
import tableStyles from './WorkpacksTable.module.less';

const DocumentAssessmentTable = ({
  data,
  intl,
  toggleWorkpackDrawer,
  handleDeleteWorkpack,
  match,
  loading,
  dateFormat,
}) => {
  const { formatMessage } = intl;
  const tableData = useMemo(() => data, [data.length]);

  const columns = useMemo(
    () => [
      {
        Cell: ({ value }) => {
          return <StatusBadge status={value} />;
        },
        Header: '',
        width: 20,
        accessor: 'status',
      },
      {
        accessor: 'po_number',
        Header: 'Po Number',
        sortable: true,
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: true,
        Cell: ({ value }) => {
          if (value) {
            return value;
          }
          return '-';
        },
      },
      {
        Header: 'date',
        accessor: 'date',
        sortable: true,
        Cell: ({ value }) => {
          if (value) {
            return dateFormat ? moment(value).format(dateFormat) : moment(value).format(defaults.defaultDateFormat);
          }
          return '-';
        },
      },
      {
        Cell: ({ value }) => {
          if (value) {
            return dateFormat ? moment(value).format(dateFormat) : moment(value).format(defaults.defaultDateFormat);
          }
          return '-';
        },
        Header: 'Release Date',
        accessor: 'release_date',
        sortable: true,
      },
      {
        Cell: ({ value }) => (
          <Tag className={value && value > 0 ? tableStyles.highlighted : tableStyles.greyedOut}>{value || '-'}</Tag>
        ),
        Header: 'DEF',
        sortable: true,
        width: 50,
        accessor: 'itemCounts.defects',
      },
      {
        Cell: ({ value }) => (
          <Tag className={value && value > 0 ? tableStyles.highlighted : tableStyles.greyedOut}>{value || '-'}</Tag>
        ),
        width: 50,
        Header: 'SCH',
        sortable: true,
        accessor: 'itemCounts.scheduled',
      },
      {
        Cell: ({ value }) => (
          <Tag className={value && value > 0 ? tableStyles.highlighted : tableStyles.greyedOut}>{value || '-'}</Tag>
        ),
        Header: 'OOP',
        width: 50,
        accessor: 'itemCounts.oop',
      },
      {
        Cell: ({ value }) => (
          <Tag className={value && value > 0 ? tableStyles.highlighted : tableStyles.greyedOut}>{value || '-'}</Tag>
        ),
        Header: 'LLP',
        width: 50,
        accessor: 'itemCounts.llp',
      },
      {
        Header: '',
        id: '3dot',
        width: 50,
        Cell: ({ row }) => {
          const viewButtonChild = (
            <button
              type="button"
              onClick={() => toggleWorkpackDrawer(row, 'view')}
              className={tableStyles.menuDropdownButton}
            >
              <span>{formatMessage({ id: 'form.button.details' })} </span>
            </button>
          );
          if (row.original.status === 'complete' || row.original.status === 'pending') {
            return (
              <AuthDropdownMenu
                viewText={viewButtonChild}
                handleDelete={() => handleDeleteWorkpack(row.original.id)}
                resource={AircraftResource.WORKPACK}
                aircraftId={match.params.id}
                options={{ create: false, read: true, update: false, delete: true }}
              />
            );
          }

          const editButtonChild = (
            <Link to={`/aircraft/${match.params.id}/workpacks/${row.original.id}/edit`}>
              <span>{formatMessage({ id: 'form.button.edit' })}</span>
            </Link>
          );

          return (
            <>
              <AuthDropdownMenu
                viewText={viewButtonChild}
                editText={editButtonChild}
                handleDelete={() => handleDeleteWorkpack(row.original.id)}
                resource={AircraftResource.WORKPACK}
                aircraftId={match.params.id}
                options={{ create: false, read: true, update: true, delete: true }}
              />
            </>
          );
        },
      },
    ],
    [],
  );

  const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

  return <Table tableInstance={tableInstance} handleRowClick={(row) => toggleWorkpackDrawer(row, 'view')} />;
};

DocumentAssessmentTable.propTypes = {
  intl: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  toggleWorkpackDrawer: PropTypes.func.isRequired,
  handleDeleteWorkpack: PropTypes.func.isRequired,
};

export default injectIntl(DocumentAssessmentTable);
