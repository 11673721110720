import React from 'react';
import styled from 'styled-components';

interface LabelProps {
  fontWeight?: number;
  marginBottom?: number;
  marginRight?: number;
  marginTop?: number;
  marginLeft?: number;
  identifier?: string;
  color?: string;
  fontSize?: string;
  width?: string;
  textTransform?: string;
}

const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }): string => (fontWeight ? `${fontWeight}` : '400')};
  color: ${({ color }): string => color || '#242d41'};
  width: ${({ width }): string => (width ? `${width}` : 'auto')};
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}px` : '0')};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
  margin-left: ${({ marginLeft }): string => (marginLeft ? `${marginLeft}px` : '0')};
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}px` : '0')};
  font-size: ${({ fontSize }): string => (fontSize ? `${fontSize}px` : '14px')};
  text-transform: ${({ textTransform }): string => (textTransform ? `${textTransform}` : 'none')};
`;

const Label: React.FC<LabelProps> = ({
  children,
  fontWeight,
  marginBottom,
  marginRight,
  identifier,
  color,
  marginLeft,
  marginTop,
  fontSize,
  width,
  textTransform,
}) => {
  return (
    <StyledLabel
      fontWeight={fontWeight}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
      marginTop={marginTop}
      data-testid={`Label--${identifier}`}
      color={color}
      fontSize={fontSize}
      width={width}
      textTransform={textTransform}
    >
      {children}
    </StyledLabel>
  );
};

export default Label;
