import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import servers from '../../../utils/servers';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import SignaturePad from './SignaturePad';

interface SignatureSectionProps {
  handleSignature: (signature: string) => void;
  signature?: string;
  formChanged?: boolean;
  addDefect?: boolean;
}

const StyledImg = styled.img`
  width: 760px;
`;

const SignatureSection: React.FC<SignatureSectionProps> = ({ handleSignature, signature, formChanged }) => {
  const { formatMessage } = useIntl();

  let showSignature = false;
  if (signature) showSignature = true;
  if (formChanged) showSignature = false;

  let sigSource = `data:image/png;base64,${signature}`;
  if (signature?.startsWith('/signature')) sigSource = `${servers.api}/${signature}`;

  return (
    <FlexWrapper marginTop={30} column>
      <Label marginBottom={20} fontWeight={600}>
        {formatMessage({ id: 'form.labels.signature' })}
      </Label>
      <Label marginBottom={15}>{formatMessage({ id: 'form.helper.defectSignatureText' })}</Label>
      <FlexWrapper column>
        {showSignature ? (
          <StyledImg alt="Signature" src={sigSource} />
        ) : (
          <SignaturePad setAcceptanceSig={handleSignature} width={760} />
        )}
      </FlexWrapper>
    </FlexWrapper>
  );
};

export default SignatureSection;
