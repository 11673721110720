import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, InputNumber, Row, Col, Radio, Switch, Modal } from 'antd';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import Header from '../common/header';
import Footer from '../common/footer';
import { update } from '../../../models/aircraft/actions';
import styles from './styles.module.less';

class AircraftSettingsModal extends Component {
  static propTypes = {
    updateAircraftSettings: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    visibleSettingsModal: PropTypes.bool,
    closeSettingsModal: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    visibleSettingsModal: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      serialError: '',
      yearError: '',
      fuelError: '',
    };
  }

  onChange = async (value, title) => {
    const { selectedAircraft } = this.props;
    await new Promise((resolve) => {
      if (title === 'serial_number') {
        this.setState({ serialError: '' }, resolve);
      }
      if (title === 'year') {
        this.setState({ yearError: '' }, resolve);
      }
      if (title === 'max_fuel_value') {
        this.setState({ fuelError: '' }, resolve);
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      formData: { ...selectedAircraft, ...prevState.formData, [`${title}`]: value },
    }));
  };

  getModalHeader = () => {
    const {
      selectedAircraft,
      closeSettingsModal,
      intl: { formatMessage },
    } = this.props;
    return (
      <Header
        handleModalVisible={() => {
          closeSettingsModal();
          this.resetErrors();
        }}
        headerTitle={formatMessage(
          { id: 'title.generalSettingsReg' },
          { field: selectedAircraft ? selectedAircraft.registration : null },
        )}
        headerSubtitle={formatMessage({ id: 'title.generalSettingsSub' })}
      />
    );
  };

  getModalFooter = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <Footer
        handleModalVisible={() => {
          this.props.closeSettingsModal();
          this.resetErrors();
        }}
        submitButtonText={formatMessage({ id: 'form.button.save' })}
        handleFormUpload={() => this.handleSubmit()}
        type="primary"
      />
    );
  };

  settingsDetailsForm = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { regError, serialError, yearError, fuelError } = this.state;
    const { selectedAircraft } = this.props;

    let apuMode = false;
    if (selectedAircraft && selectedAircraft.apu_mode !== 'not_installed') {
      apuMode = true;
    }
    return (
      <>
        <Form onSubmit={this.handleSubmit} className={styles.editableDetailsForm} data-test="aircraftForm">
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.serialNum' })} data-test="formSerialNumber">
                {getFieldDecorator('serial_number', {
                  rules: [
                    {
                      required: true,
                      message: () => this.setState({ serialError: formatMessage({ id: 'message.addSerialNum' }) }),
                    },
                  ],
                  initialValue: selectedAircraft ? selectedAircraft.serial_number || '-' : '-',
                })(
                  <Input
                    className={styles.editableCellInput}
                    onChange={(e) => this.onChange(e.target.value.toUpperCase(), 'serial_number')}
                    data-test="inputSerialNum"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.year' })} data-test="formYear">
                {getFieldDecorator('year', {
                  rules: [
                    {
                      required: true,
                      type: 'number',
                      message: () =>
                        this.setState({ yearError: formatMessage({ id: 'message.addYearOfManufacture' }) }),
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: () => this.setState({ yearError: formatMessage({ id: 'message.addYear' }) }),
                    },
                  ],
                  initialValue: selectedAircraft ? selectedAircraft.year || 1903 : 1903,
                })(
                  <InputNumber
                    className={styles.editableCellCapacityInput}
                    onChange={(e) => this.onChange(e, 'year')}
                    min={1903}
                    data-test="inputYear"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.fuelCapacity' })} data-test="formMaxCapacity">
                <div className={styles.fuelCapacityEditDiv}>
                  {getFieldDecorator('max_fuel_value', {
                    rules: [
                      {
                        required: true,
                        type: 'number',
                        message: () =>
                          this.setState({ fuelError: formatMessage({ id: 'message.addMaxFuelCapacity' }) }),
                      },
                      {
                        pattern: new RegExp('^[0-9]*$'),
                        message: () => this.setState({ fuelError: formatMessage({ id: 'message.addYear' }) }),
                      },
                    ],
                    initialValue: selectedAircraft ? selectedAircraft.max_fuel_value || 0 : 0,
                  })(
                    <InputNumber
                      className={styles.editableCellCapacityInput}
                      onChange={(e) => this.onChange(e, 'max_fuel_value')}
                      min={0}
                      data-test="inputMaxFuel"
                    />,
                  )}
                  <span style={{ marginLeft: '4px' }} className={styles.fuelUnit} data-test="fuelUnitSuffix">
                    {this.props.form.getFieldValue('fuel_unit') || (selectedAircraft && selectedAircraft.fuel_unit)}
                  </span>
                </div>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.fuelUnit' })} data-test="formFuelUnit">
                {getFieldDecorator('fuel_unit', {
                  initialValue: selectedAircraft ? selectedAircraft.fuel_unit || 'lb' : '-',
                })(
                  <Radio.Group buttonStyle="solid" onChange={(e) => this.onChange(e)} data-test="radioGroupFuel">
                    <Radio.Button className={styles.radioButton} value="lb" data-test="lbRadioButtonFuel">
                      {formatMessage({ id: 'units.lb' })}
                    </Radio.Button>
                    <Radio.Button className={styles.radioButton} value="kg" data-test="kgRadioButtonFuel">
                      {formatMessage({ id: 'units.kg' })}
                    </Radio.Button>
                    <Radio.Button className={styles.radioButton} value="l" data-test="lRadioButtonFuel">
                      {formatMessage({ id: 'units.ltr' })}
                    </Radio.Button>
                    <Radio.Button className={styles.radioButton} value="usg" data-test="usgRadioButtonFuel">
                      {formatMessage({ id: 'units.usg' })}
                    </Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={formatMessage({ id: 'form.labels.oilUnit' })} data-test="formOilUnit">
                {getFieldDecorator('oil_unit', {
                  initialValue: selectedAircraft ? selectedAircraft.oil_unit || 'ml' : '-',
                })(
                  <Radio.Group buttonStyle="solid" onChange={(e) => this.onChange(e)} data-test="radioGroupOil">
                    <Radio.Button className={styles.radioButton} value="ml" data-test="firstRadioButtonOil">
                      {formatMessage({ id: 'units.ml' })}
                    </Radio.Button>
                    <Radio.Button className={styles.radioButton} value="qt" data-test="secondRadioButtonOil">
                      {formatMessage({ id: 'units.qt' })}
                    </Radio.Button>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label={formatMessage({ id: 'form.labels.apu' })}
                className={styles.apuFormItem}
                data-test="formAPU"
              >
                {getFieldDecorator('apu_mode', {
                  rules: [
                    {
                      type: 'boolean',
                    },
                  ],
                  initialValue: apuMode,
                })(<Switch defaultChecked={apuMode} onChange={(e) => this.onChange(e)} data-test="apuSwitch" />)}
              </Form.Item>
            </Col>
          </Row>
          <div className={regError || serialError || yearError || fuelError ? styles.errorDiv : styles.errorDivHidden}>
            {(regError || serialError || yearError || fuelError) && (
              <div className={styles.displayErrorDiv}>
                <div className={styles.errorMessage} data-test="serialErrorMessage">
                  {this.state.serialError}
                </div>
                <div className={styles.errorMessage} data-test="yearErrorMessage">
                  {this.state.yearError}
                </div>
                <div className={styles.errorMessage} data-test="fuelErrorMessage">
                  {this.state.fuelError}
                </div>
              </div>
            )}
          </div>
        </Form>
      </>
    );
  };

  resetErrors = () => {
    this.setState({
      regError: '',
      serialError: '',
      yearError: '',
      fuelError: '',
    });
  };

  handleSubmit = () => {
    const { form, selectedAircraft } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        this.props.form.resetFields();
        const formValues = values;
        if (values.apu_mode === true) {
          formValues.apu_mode = 'cumulative';
          formValues.apu_installed = true;
        } else {
          formValues.apu_mode = 'not_installed';
          formValues.apu_installed = false;
        }
        const payload = {
          update: true,
          aircraftSettings: true,
          aircraftId: selectedAircraft.id,
          aircraft: {
            ...selectedAircraft,
            ...formValues,
          },
        };
        this.props.updateAircraftSettings(payload);
        this.props.closeSettingsModal();
        this.props.setLoading();
      }
    });
  };

  render() {
    const { visibleSettingsModal, closeSettingsModal } = this.props;
    return (
      <Modal
        title={this.getModalHeader()}
        visible={visibleSettingsModal}
        className={styles.modal}
        destroyOnClose
        onCancel={closeSettingsModal}
        closable={false}
        footer={this.getModalFooter()}
        data-test="aircraftSettingsModal"
      >
        {this.settingsDetailsForm(true)}
      </Modal>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    ({ aircraft }) => ({
      aircraftMap: aircraft.aircraftMap,
    }),
    (dispatch) => ({
      updateAircraftSettings: (payload) => {
        return dispatch(
          update({
            payload,
          }),
        );
      },
    }),
  ),
  Form.create(),
)(AircraftSettingsModal);
