import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card, Icon, Button, DatePicker, Table } from 'antd';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Loading from '../../components/TFLoading';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import DurationFormat from '../../components/DurationFormat';
import servers from '../../utils/servers';
import defaults from '../../utils/defaults';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { fetch } from '../../models/aircraftLogbook/actions';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import EmptyStateNoDate from '../../assets/emptyState/empty-state-no-data.svg';
import ExpandedContent from '../../components/TopNavWrapper/ExpandedContent';
import styles from './Monthly.module.less';

const monthFormat = 'MMM-YY';
const { MonthPicker } = DatePicker;

const ENGINE_HAS_PROPELLERS = { piston: true, turboprop: true };

class Monthly extends PureComponent {
  static propTypes = {
    aircraftLogbook: PropTypes.object.isRequired,
    aircraft: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    selectedAircraft: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    loading: true,
    changeMonthLoading: false,
    month: moment(),
    nextDisabled: true,
    disableDownload: false,
    aircraftQuery: [
      'trip_date',
      'takeoff',
      'landing',
      'departure_airport',
      'arrival_airport',
      'flight_time',
      'blocks_time',
      'landings',
      'airframe_hours',
      'airframe_landings',
      'srp_number',
    ],
  };

  componentDidMount() {
    const {
      aircraft: { ttl },
      selectedAircraft,
    } = this.props;
    if (!selectedAircraft.id || Date.now() - selectedAircraft.lastFetched >= ttl) {
      this.getAircraft(true);
    }
    this.getLogbookData();
  }

  componentDidUpdate(prevProps) {
    const { selectedAircraft } = this.props;
    if (selectedAircraft.id !== prevProps.selectedAircraft.id) {
      this.getLogbookData();
    }
  }

  getAircraft = async (forceRefetch = false) => {
    const {
      dispatch,
      match: {
        params: { id },
      },
    } = this.props;
    if (forceRefetch) {
      await dispatch(
        getSingleAircraft({
          payload: id,
        }),
      );
    }
  };

  getDataQueryBasedOnAircraft = () => {
    const { selectedAircraft } = this.props;
    const engineNumber = selectedAircraft.aircraft_type.engine_count;
    const engineType = selectedAircraft.aircraft_type.engine_type;
    const engineQuery = [];
    const apuQuery = [];
    if (selectedAircraft.apu_installed) {
      apuQuery.push('apu_hours');
      apuQuery.push('apu_cycles');
    }
    for (let i = 0; i < engineNumber; i += 1) {
      engineQuery.push(`engine_${i + 1}_oil_uplift`);
      engineQuery.push(`engine_${i + 1}_hours`);
      engineQuery.push(`engine_${i + 1}_cycles`);
      if (ENGINE_HAS_PROPELLERS[engineType]) {
        engineQuery.push(`prop_${i + 1}_hours`);
      }
    }
    return [...this.state.aircraftQuery, ...engineQuery, ...apuQuery];
  };

  getLogbookData = async () => {
    const { dispatch, selectedAircraft } = this.props;

    if (selectedAircraft.id) {
      await new Promise((resolve) => {
        this.setState({ loading: true }, resolve);
      });

      await dispatch(
        fetch({
          payload: {
            aircraft: selectedAircraft.id,
            columns: this.getDataQueryBasedOnAircraft(),
            from: moment(this.state.month)
              .startOf('month')
              .format('YYYY-MM-DD'),
            to: moment(this.state.month)
              .endOf('month')
              .format('YYYY-MM-DD'),
          },
        }),
      );
      await new Promise((resolve) => {
        this.setState({ loading: false }, resolve);
      });
    }
  };

  aircraftHasPropellers = () => {
    const { selectedAircraft } = this.props;
    return ENGINE_HAS_PROPELLERS[selectedAircraft.aircraft_type.engine_type];
  };

  generateColumnsBasedOnData = (engines) => {
    const {
      aircraftLogbook,
      intl: { formatMessage },
    } = this.props;
    const data = aircraftLogbook.data || [];
    const columnObj = [];
    columnObj.push(
      {
        title: formatMessage({ id: 'title.date' }),
        dataIndex: 'date',
        key: 'date',
        className: 'columnDate',
        width: 130,
        render: (value, row, index) => {
          let obj = {
            children: '-',
            props: {},
          };
          if (value) {
            obj = {
              children:
                this.props.userSettings && this.props.userSettings.dateFormat
                  ? moment(value).format(this.props.userSettings.dateFormat)
                  : moment(value).format(defaults.defaultDateFormat),
              props: {},
            };
          }
          if (index === 0) {
            obj.props.colSpan = 5;
            obj.children = formatMessage({ id: 'text.carriedFromPrevious' });
          }
          if (index === data.length - 1) {
            obj.props.colSpan = 5;
            obj.children = formatMessage({ id: 'text.totals' });
          }
          return obj;
        },
      },
      {
        title: formatMessage({ id: 'title.takeOff' }),
        dataIndex: 'takeoff',
        key: 'takeoff',
        className: 'columnTakeoff',
        width: 80,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0 || index === data.length - 1) {
            obj.props.colSpan = 0;
          }
          return obj;
        },
      },
      {
        title: formatMessage({ id: 'title.landing' }),
        dataIndex: 'landing',
        key: 'landing',
        className: 'columnLanding',
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0 || index === data.length - 1) {
            obj.props.colSpan = 0;
          }
          return obj;
        },
      },
      {
        title: formatMessage({ id: 'title.from' }),
        dataIndex: 'from',
        key: 'from',
        className: 'columnFrom',
        width: 90,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0 || index === data.length - 1) {
            obj.props.colSpan = 0;
          }
          return obj;
        },
      },
      {
        title: formatMessage({ id: 'title.to' }),
        dataIndex: 'to',
        key: 'to',
        className: 'columnTo',
        width: 80,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0 || index === data.length - 1) {
            obj.props.colSpan = 0;
          }
          return obj;
        },
      },
      {
        title: formatMessage({ id: 'title.flightTime' }),
        dataIndex: 'flight_time',
        key: 'flight_time',
        className: 'columnFlightTime',
        width: 110,
        render: (value) => {
          return <DurationFormat time={value} />;
        },
      },
      {
        title: formatMessage({ id: 'title.flightLandings' }),
        dataIndex: 'landings',
        key: 'landings',
        className: 'columnLandings',
        width: 170,
      },
      {
        title: formatMessage({ id: 'title.airframeHours' }),
        dataIndex: 'airframe_hours',
        rowKey: 'airframe_hours',
        className: 'columnAirframeHours',
        width: 170,
        render: (val) => {
          return <DurationFormat time={val} />;
        },
      },
    );

    if (this.aircraftHasPropellers()) {
      for (let s = 0; s < engines; s += 1) {
        columnObj.push({
          title: `Prop #${s + 1} Hours`,
          dataIndex: `prop_${s + 1}_hours`,
          key: `prop_${s + 1}_hours`,
          className: `columnProp${s + 1}Hours`,
          width: 110,
        });
      }
    }

    for (let i = 0; i < engines; i += 1) {
      columnObj.push({
        title: `${formatMessage({ id: 'title.engShort' })} #${i + 1} ${formatMessage({ id: 'title.hours' })}`,
        dataIndex: `engine_${i + 1}_hours`,
        key: `engine_${i + 1}_hours`,
        className: `columnEngine${i + 1}Hours`,
        width: 110,
      });
    }
    for (let j = 0; j < engines; j += 1) {
      columnObj.push({
        title: `${formatMessage({ id: 'title.engShort' })} #${j + 1} ${formatMessage({ id: 'title.cycles' })}`,
        dataIndex: `engine_${j + 1}_cycles`,
        key: `engine_${j + 1}_cycles`,
        className: `columnEngine${j + 1}Cycles`,
        width: 120,
      });
    }

    columnObj.push({
      title: formatMessage({ id: 'title.totalLandings' }),
      dataIndex: 'cumulative_landings',
      key: 'cumulative_landings',
      className: 'columnTotalLandings',
      width: 100,
    });

    if (data[0] && Object.keys(data[0]).includes('apu_hours')) {
      columnObj.push({
        title: formatMessage({ id: 'title.apuHours' }),
        dataIndex: 'apu_hours',
        key: 'apu_hours',
        className: 'apuHours',
        width: 100,
      });

      columnObj.push({
        title: formatMessage({ id: 'title.apuCycles' }),
        dataIndex: 'apu_cycles',
        key: 'apu_cycles',
        className: 'apuCycles',
        width: 120,
      });
    }

    for (let k = 0; k < engines; k += 1) {
      columnObj.push({
        title: `${formatMessage({ id: 'title.engShort' })} #${k + 1} ${formatMessage({ id: 'title.oilUplift' })}`,
        dataIndex: `engine_${k + 1}_oil`,
        key: `engine_${k + 1}_oil`,
        className: `columnEngine${k + 1}Oil`,
        width: 100,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          if (index === 0) {
            obj.props.colSpan = 0;
          }
          obj.props.datatype = 'engoil';
          return obj;
        },
      });
    }
    columnObj.push({
      title: formatMessage({ id: 'title.srp' }),
      dataIndex: 'srp',
      key: 'srp',
      className: 'columnSRP',
      width: 80,
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index === 0) {
          obj.props.colSpan = engines + 1;
        }
        return obj;
      },
    });
    return columnObj;
  };

  downloadCSV = () => {
    const { selectedAircraft } = this.props;
    const start = moment(this.state.month)
      .startOf('month')
      .format('YYYY-MM-DD');
    const end = moment(this.state.month)
      .endOf('month')
      .format('YYYY-MM-DD');
    const fileName = 'flights.csv';

    const csvURL = `${servers.api}/aircraft/${selectedAircraft.id}/${fileName}?from_date=${start}&to_date=${end}`;
    this.saveData(csvURL, fileName);
  };

  saveData = (csvURL, fileName) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = csvURL;
    a.download = fileName;
    if (isMobile) {
      a.target = '_blank';
    }
    a.click();
  };

  previousMonth = async () => {
    const { month } = this.state;
    const current = month;
    const newMonth = moment(current).subtract(1, 'months');
    await new Promise((resolve) => this.setState({ month: newMonth }, resolve));
    this.handleMonthChange();
  };

  handleMonthChange = async () => {
    const { month } = this.state;
    await new Promise((resolve) => this.setState({ changeMonthLoading: true }, resolve));
    if (month !== null) {
      this.setState({ disableDownload: false });
      await this.getLogbookData();
      if (moment(month).format('MM-YY') === moment().format('MM-YY')) {
        this.setState({
          nextDisabled: true,
        });
      } else {
        this.setState({
          nextDisabled: false,
        });
      }
    } else {
      this.setState({ disableDownload: true });
    }
    await new Promise((resolve) => this.setState({ changeMonthLoading: false }, resolve));
  };

  nextMonth = async () => {
    const { month } = this.state;
    const current = month;
    const newMonth = moment(current).add(1, 'months');
    await new Promise((resolve) => this.setState({ month: newMonth }, resolve));
    this.handleMonthChange();
  };

  pickerChange = async (val) => {
    await new Promise((resolve) => this.setState({ changeMonthLoading: true }, resolve));
    this.setState(
      {
        month: val,
      },
      () => {
        this.handleMonthChange();
      },
    );
  };

  render() {
    const {
      aircraftLogbook,
      intl: { formatMessage },
      selectedAircraft,
    } = this.props;
    const { loading, month, nextDisabled, disableDownload, changeMonthLoading } = this.state;
    const isScreenIPadOrSmaller = window.innerWidth < 769;
    return (
      <InnerMenuLayout loading={loading || changeMonthLoading}>
        <ExpandedContent
          displayTitle={formatMessage({ id: 'title.aircraftFlightLog' })}
          action={
            <div className={styles.allButtons}>
              <div className={styles.monthPickerWrapper}>
                <span className={styles.firstBtn}>
                  <Button type="secondary" onClick={() => this.previousMonth()} data-test="prevMonthButton">
                    <Icon type="left" />
                  </Button>
                </span>
                <MonthPicker
                  onChange={(val) => this.pickerChange(val)}
                  disabledDate={(d) => !d || d.isBefore('1902-12-31') || d.isAfter(moment().endOf('month'))}
                  value={month}
                  format={monthFormat}
                  allowClear={false}
                  data-test="monthPicker"
                />
                <span className={styles.lastBtn}>
                  <Button
                    disabled={nextDisabled}
                    type="secondary"
                    onClick={() => this.nextMonth()}
                    data-test="nextMonthButton"
                  >
                    <Icon type="right" />
                  </Button>
                </span>
              </div>
              <span className={styles.downloadButton}>
                <Button
                  type="primary"
                  onClick={() => {
                    this.downloadCSV();
                  }}
                  disabled={disableDownload}
                  data-test="csvButton"
                >
                  <Icon type="download" />
                  {formatMessage({ id: 'form.button.downloadCSV' })}
                </Button>
              </span>
            </div>
          }
        />
        <Card bordered={false}>
          <div className={styles.tableList}>
            <Loading loading={loading} contain />
            <Table
              className={styles.logbookTable}
              size="middle"
              bordered
              scroll={{ x: isScreenIPadOrSmaller ? 1100 : 100 }}
              columns={
                selectedAircraft.id ? this.generateColumnsBasedOnData(selectedAircraft.aircraft_type.engine_count) : []
              }
              dataSource={aircraftLogbook.data}
              pagination={false}
              data-test="logbookTable"
              locale={{
                emptyText: (
                  <div className={styles.emptyText}>
                    <img src={EmptyStateNoDate} alt="empty state" />
                    <span>No Data</span>
                  </div>
                ),
              }}
            />
          </div>
        </Card>
      </InnerMenuLayout>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(({ aircraft, aircraftLogbook, userSettings }, { match: { params: { id } } }) => ({
    aircraft,
    aircraftLogbook,
    selectedAircraft: aircraft.aircraftMap.get(id) || {},
    userSettings,
  })),
)(Monthly);
