/* eslint-disable react/jsx-props-no-spreading */
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.less';

const { TabPane } = Tabs;

class ExpandedContent extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    onTabChange: PropTypes.func,
    linkElement: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    displayTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    tabList: PropTypes.array,
    tabActiveKey: PropTypes.string,
    tabBarExtraContent: PropTypes.string,
    datatestid: PropTypes.string,
    action: PropTypes.object,
    logo: PropTypes.object,
  };

  static defaultProps = {
    content: undefined,
    onTabChange: undefined,
    tabActiveKey: undefined,
    tabBarExtraContent: undefined,
    tabList: undefined,
    datatestid: undefined,
    action: undefined,
    logo: undefined,
  };

  onChange = (key) => {
    const { onTabChange } = this.props;
    if (onTabChange) {
      onTabChange(key);
    }
  };

  render() {
    const { content, tabList, tabActiveKey, tabBarExtraContent, datatestid, action, displayTitle, logo } = this.props;

    let tabDefaultValue;
    if (tabActiveKey !== undefined && tabList) {
      tabDefaultValue = tabList.find((item) => item.default) || tabList[0];
    }
    const activeKeyProps = {
      defaultActiveKey: tabDefaultValue && tabDefaultValue.key,
    };
    if (tabActiveKey !== undefined) {
      activeKeyProps.activeKey = tabActiveKey;
    }

    return (
      <>
        <div data-testid={datatestid} className={`${styles.expandedContentWrapper} ${styles.pageHeader}`}>
          <div className={styles.detail}>
            {logo && <div className={styles.logo}>{logo}</div>}
            <div className={styles.main}>
              <div className={styles.row}>
                {displayTitle && (
                  <h1 className={styles.title} data-test="pageTitle" data-testid="expandedContentTitle">
                    {displayTitle}
                  </h1>
                )}
                {action && <div className={styles.action}>{action}</div>}
              </div>
              <div className={styles.row}>
                {content && (
                  <div data-testid="expanded-content" className={styles.content}>
                    {content}
                  </div>
                )}
                {/* {extraContent && (
                  <div className={flightProfile ? styles.flightExtraContent : styles.extraContent}>{extraContent}</div>
                )} */}
              </div>
            </div>

            {/* <div className={styles.main}>
              <div className={styles.row}>
                {content && (
                  <div data-testid="expanded-content" className={styles.content}>
                    {content}
                  </div>
                )}
              </div>
            </div> */}
          </div>
          {tabList && tabList.length ? (
            <Tabs
              className={styles.tabs}
              {...activeKeyProps}
              onChange={this.onChange}
              tabBarExtraContent={tabBarExtraContent}
            >
              {tabList.map((item) => (
                <TabPane tab={item.tab} key={item.key} />
              ))}
            </Tabs>
          ) : null}
        </div>
      </>
    );
  }
}

export default connect()(ExpandedContent);
