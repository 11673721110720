import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import EditTripFlight from '../EditFlight/EditTripFlight';
import Loading from '../../components/TFLoading';

const AircraftEditTripFlight = () => {
  const [loading, setLoading] = useState(true);
  const {
    params: { id },
  } = useRouteMatch();
  const { selectedAircraft } = useSelector(({ aircraft }) => ({
    selectedAircraft: aircraft.aircraftMap.get(id),
  }));

  useEffect(() => {
    if (selectedAircraft && loading) {
      setLoading(false);
    }
  }, [selectedAircraft, loading]);

  return (
    <InnerMenuLayout>
      <Loading loading={loading} />
      <EditTripFlight />
    </InnerMenuLayout>
  );
};

export default AircraftEditTripFlight;
