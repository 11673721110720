/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DatePicker, Form, Input, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import SelectPerson from '../SelectPerson';
import defaults from '../../utils/defaults';
import Loading from '../TFLoading';
import styles from './index.module.less';
import { warnings } from './validation';

class TripDescriptionForm extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    trip: PropTypes.object.isRequired,
    aircraft: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    onChange: PropTypes.func,
    userSettings: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onChange: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  updateLoading = (value) => {
    this.setState({ loading: value });
  };

  render() {
    const {
      intl: { formatMessage },
      trip,
      form,
      aircraft,
      userSettings,
    } = this.props;
    const { loading } = this.state;
    const { getFieldDecorator } = form;
    const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;

    return (
      <Form layout="horizontal" className={styles.loadingWrapper}>
        <Loading loading={loading} contain width={100} height={100} />
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              labelCol={{ lg: 4, xl: 4 }}
              wrapperCol={{ lg: 12, xl: 12 }}
              label={formatMessage({ id: 'form.labels.tripNum' })}
              data-test="tripFormTripNumber"
              style={{ marginBottom: 17 }}
            >
              {trip.number}
            </Form.Item>
            <Form.Item
              labelCol={{ lg: 4, xl: 4 }}
              wrapperCol={{ lg: 12, xl: 12 }}
              label={formatMessage({ id: 'form.labels.date' })}
              data-test="tripFormDate"
              {...warnings(formatMessage).tripDate(trip)}
              style={{ marginBottom: 17 }}
            >
              {getFieldDecorator('date')(<DatePicker format={dateFormat} />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              labelCol={{ lg: 6, xl: 6 }}
              wrapperCol={{ lg: 8, xl: 8 }}
              label={formatMessage({ id: 'form.labels.aircraft' })}
              data-test="tripFormAircraft"
              style={{ marginBottom: 17 }}
            >
              {trip.aircraft && trip.aircraft.registration}
            </Form.Item>
            <Form.Item
              labelCol={{ lg: 6, xl: 6 }}
              wrapperCol={{ lg: 8, xl: 8 }}
              label={formatMessage({ id: 'form.labels.callsign' })}
              data-test="tripFormCallsign"
              {...warnings(formatMessage).callsign(trip)}
              style={{ marginBottom: 17 }}
            >
              {getFieldDecorator('callsign', {
                initialValue: [form.getFieldValue('callsign')],
              })(
                <Input
                  placeholder={formatMessage({ id: 'form.placeholder.registration' })}
                  style={{ maxWidth: 120, width: '100%' }}
                />,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <Form.Item
              labelCol={{ lg: 4, xl: 4 }}
              wrapperCol={{ lg: 16, xl: 16 }}
              label={formatMessage({ id: 'form.labels.pic' })}
              data-test="tripFormPIC"
              className={styles.pilotSelector}
              {...warnings(formatMessage).pic(form.getFieldValue('captain_id'))}
              style={{ marginBottom: 17 }}
            >
              {getFieldDecorator('captain_id')(
                <SelectPerson
                  placeholder={formatMessage({ id: 'form.placeholder.selectPilotInCommand' })}
                  blockedPeople={[form.getFieldValue('first_officer_id')]}
                  currentCrew={aircraft && aircraft.aircraft_people}
                  aircraft_id={aircraft && aircraft.id}
                  addPerson
                  organisation={aircraft && aircraft.operator_id}
                  updateLoading={this.updateLoading}
                />,
              )}
            </Form.Item>
            <Form.Item
              labelCol={{ lg: 4, xl: 4 }}
              wrapperCol={{ lg: 16, xl: 16 }}
              label={formatMessage({ id: 'form.labels.sic' })}
              data-test="tripFormSIC"
              className={styles.pilotSelector}
              style={{ marginBottom: 17 }}
            >
              {getFieldDecorator('first_officer_id')(
                <SelectPerson
                  placeholder={formatMessage({ id: 'form.placeholder.selectSecondInCommand' })}
                  blockedPeople={[form.getFieldValue('captain_id')]}
                  currentCrew={aircraft && aircraft.aircraft_people}
                  aircraft_id={aircraft && aircraft.id}
                  addPerson
                  allowClear
                  organisation_id={aircraft && aircraft.operator_id}
                  updateLoading={this.updateLoading}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const ComposedTripDescriptionForm = compose(
  injectIntl,
  connect(({ userSettings }) => ({
    userSettings,
  })),
  Form.create({
    onValuesChange(props, _, allValues) {
      if (!props.onChange) return;
      props.onChange({ ...allValues });
    },
    mapPropsToFields({ trip }) {
      const fields = {};
      if (trip) {
        fields.number = Form.createFormField({ value: trip.number || null });
        fields.date = Form.createFormField({ value: moment(trip.date).isValid() ? trip.date : null });
        fields.captain_id = Form.createFormField({ value: (trip.captain && trip.captain.id) || null });
        fields.first_officer_id = Form.createFormField({
          value: (trip.first_officer && trip.first_officer.id) || null,
        });
        fields.callsign = Form.createFormField({ value: trip.callsign || null });
      }
      return fields;
    },
  }),
)(TripDescriptionForm);

export default ComposedTripDescriptionForm;
