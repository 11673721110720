/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, Icon, InputNumber, Popconfirm, Upload } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import DurationFormat from '../DurationFormat';
import InputNumberUnits from '../InputNumberUnits';
import SelectAirport from '../SelectAirport';
import defaults from '../../utils/defaults';
import DeiceEvents from './DeiceEvents';
import FlightTime from './FlightTime';
import FuelUplifts from './FuelUplifts';
import styles from './index.module.less';
import OilUplift from './OilUplift';
import { timeDifference } from './utils';
import { warnings } from './validation';

const labelCol = {
  lg: { span: 8 },
  xl: { span: 8 },
};
const wrapperCol = {
  lg: { span: 16 },
  xl: { span: 16 },
};

const buttonWrapperCol = {
  lg: { span: 24, offset: 0 },
  xl: { span: wrapperCol.xl.span, offset: labelCol.xl.span },
};

const formItemLayout = {
  labelCol,
  wrapperCol,
};

class FlightForm extends React.PureComponent {
  static propTypes = {
    aircraft: PropTypes.object.isRequired,
    flight: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    onAttachmentPreview: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    previousAirport: PropTypes.string,
    previousBlocksOnTime: PropTypes.object,
    submitting: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    preventSubmit: PropTypes.bool,
  };

  static defaultProps = {
    previousAirport: null,
    previousBlocksOnTime: undefined,
    onAttachmentPreview: undefined,
    onDelete: undefined,
    onChange: undefined,
    preventSubmit: false,
    submitting: false,
  };

  state = {
    activeDeice: '',
    activeUplift: '',
    finish: false,
    departureAirport: null,
    arrivalAirport: null,
  };

  addFuelUplift = () => {
    const { form } = this.props;
    const fuelUplifts = form.getFieldValue('fuel_uplifts');
    // Temporary unique identifier for react keys
    const id = `new-${uuid.v4()}`;
    form.setFieldsValue({
      fuel_uplifts: (fuelUplifts || []).concat([
        {
          id,
          aircraft_planned_uplift: null,
          aircraft_actual_uplift: null,
        },
      ]),
    });

    this.setState({ activeUplift: id });
  };

  addDeicing = () => {
    const { form } = this.props;
    const deiceEvents = form.getFieldValue('deice_events');
    form.setFieldsValue({
      deice_events: (deiceEvents || []).concat([{}]),
    });

    this.setState({
      activeDeice: deiceEvents ? deiceEvents.length.toString() : '',
    });
  };

  handleSubmit = (e) => {
    const {
      flight,
      intl: { formatMessage },
      preventSubmit,
    } = this.props;
    e.preventDefault();
    if (preventSubmit) {
      // Just validate; scrolling will be handled on the parent.
      this.props.onSubmit(false);
      this.props.form.validateFields();
    } else {
      this.props.form.validateFieldsAndScroll({ scroll: { offsetTop: 100 } }, (err) => {
        const landingError = warnings(formatMessage).landing(flight) === null;
        if (!err && landingError && this.props.onSubmit) {
          this.props.onSubmit(this.state.finish);
        }
      });
    }
  };

  departureAirportChange = (ap = {}) => {
    const {
      form: { validateFields },
    } = this.props;
    validateFields(['departure_airport_id']);

    this.setState({ departureAirport: ap });
  };

  validatePrevDepAirport = () => {
    const {
      form: { validateFields },
    } = this.props;
    validateFields(['departure_airport_id']);
  };

  arrivalAirportChange = (ap = {}) => {
    this.setState({ arrivalAirport: ap });
  };

  render() {
    const {
      form,
      flight,
      aircraft,
      submitting,
      onAttachmentPreview,
      intl: { formatMessage },
      previousAirport,
      previousBlocksOnTime,
      onDelete,
      userSettings,
    } = this.props;
    const { departureAirport, arrivalAirport, activeUplift, activeDeice, finish } = this.state;
    const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;

    const { getFieldDecorator } = form;
    const sectorTotal = timeDifference(form.getFieldValue('time_takeoff'), form.getFieldValue('time_landing'));

    let formAirportDontMatch = null;
    if (previousAirport && departureAirport && departureAirport.ident !== previousAirport) {
      formAirportDontMatch = {
        validateStatus: 'error',
        help: `${formatMessage({ id: 'message.previousArrival' }, { field: previousAirport })} ${formatMessage(
          { id: 'message.doesntMatchDeparture' },
          { field: departureAirport.ident },
        )}`,
      };
    }
    return (
      <Form layout="horizontal" hideRequiredMark onSubmit={this.handleSubmit}>
        <fieldset>
          <legend data-test="airportLegend">{formatMessage({ id: 'form.labels.airport' })}</legend>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.departureAirport' })}
            {...formAirportDontMatch}
            data-test="formItemDepartureAirport"
          >
            {getFieldDecorator('departure_airport_id', {
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'message.selectDepartureAirport' }),
                },
              ],
            })(<SelectAirport onChangeObj={this.departureAirportChange} data-test="formElementDepartureAirport" />)}
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.arrivalAirport' })}
            data-test="formItemArrivalAirport"
          >
            {getFieldDecorator('arrival_airport_id', {
              rules: [
                {
                  required: true,
                  message: formatMessage({ id: 'message.selectArrivalAirport' }),
                },
              ],
            })(<SelectAirport onChangeObj={this.arrivalAirportChange} data-test="formElementArrivalAirport" />)}
          </Form.Item>
        </fieldset>
        <fieldset>
          <legend data-test="timesLegend">{formatMessage({ id: 'form.labels.times' })}</legend>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.offBlocks' })}
            {...warnings(formatMessage).offBlocks(flight, previousBlocksOnTime)}
            data-test="formItemOffBlocks"
            className={styles.dateTimePicker}
          >
            {getFieldDecorator('time_offblocks', {
              rules: [{ required: true, message: formatMessage({ id: 'message.selectOffBlocks' }) }],
            })(
              <FlightTime
                airport={departureAirport}
                prevValue={form.getFieldValue('time_offblocks')}
                data-test="formElementOffBlocks"
                showDateLabel
                dateFormat={dateFormat}
              />,
            )}
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.takeOff' })}
            {...warnings(formatMessage).takeOff(flight)}
            data-test="formItemTakeoff"
            className={styles.dateTimePicker}
          >
            {getFieldDecorator('time_takeoff', {
              rules: [{ required: false, message: formatMessage({ id: 'message.selectTakeOff' }) }],
            })(
              <FlightTime
                airport={departureAirport}
                prevValue={form.getFieldValue('time_takeoff')}
                data-test="formElementTakeoff"
                showDateLabel
                dateFormat={dateFormat}
              />,
            )}
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.landing' })}
            {...warnings(formatMessage).landing(flight)}
            data-test="formItemLanding"
            className={styles.dateTimePicker}
          >
            {getFieldDecorator('time_landing', {
              rules: [{ required: false, message: formatMessage({ id: 'message.selectLanding' }) }],
            })(
              <FlightTime
                airport={arrivalAirport}
                prevValue={form.getFieldValue('time_landing')}
                data-test="formElementLanding"
                showDateLabel
                dateFormat={dateFormat}
              />,
            )}
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.onBlocks' })}
            {...warnings(formatMessage).onBlocks(flight)}
            data-test="formItemOnBlocks"
            className={styles.dateTimePicker}
          >
            {getFieldDecorator('time_onblocks', {
              rules: [{ required: true, message: formatMessage({ id: 'message.selectOnBlocks' }) }],
            })(
              <FlightTime
                airport={arrivalAirport}
                prevValue={form.getFieldValue('time_onblocks')}
                data-test="formElementOnBlocks"
                showDateLabel
                dateFormat={dateFormat}
              />,
            )}
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.sectorTotal' })}
            data-test="formItemSectorTotal"
          >
            {!Number.isNaN(sectorTotal) && <DurationFormat time={sectorTotal} data-test="formElementSectorTotal" />}
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.hobbsDeparture' })}
            data-test="formItemHobbsDeparture"
          >
            {getFieldDecorator('hobbs_departure')(
              <InputNumberUnits
                min={0}
                step={0.1}
                units={formatMessage({ id: 'period.hoursShort' })}
                data-test="formElementHobbsDeparture"
              />,
            )}
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.hobbsArrival' })}
            data-test="formItemHobbsArrival"
          >
            {getFieldDecorator('hobbs_arrival')(
              <InputNumberUnits
                min={form.getFieldValue('hobbs_departure') ? parseFloat(form.getFieldValue('hobbs_departure')) : 0}
                step={0.1}
                units={formatMessage({ id: 'period.hoursShort' })}
                data-test="formElementHobbsArrival"
              />,
            )}
          </Form.Item>
          {aircraft.apu_installed && (
            <Form.Item
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              label={formatMessage({ id: 'form.labels.apuTotal' })}
              data-test="formItemAPU"
            >
              {getFieldDecorator('apu_seconds', {
                getValueProps: (seconds) => ({ value: seconds && seconds / 3600 }),
                getValueFromEvent: (hrs) => hrs * 3600,
              })(
                <InputNumberUnits
                  min={0}
                  step={0.1}
                  units={formatMessage({ id: 'period.hoursShort' })}
                  data-test="formElementAPU"
                />,
              )}
            </Form.Item>
          )}
        </fieldset>
        <fieldset>
          <legend data-test="cyclesLegend">{formatMessage({ id: 'form.labels.cycles' })}</legend>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.landings' })}
            data-test="formItemLandings"
          >
            {getFieldDecorator('landings_count', {
              rules: [{ required: true, message: formatMessage({ id: 'message.selectLandings' }) }],
            })(<InputNumber min={1} step={1} data-test="formElementCycles" />)}
          </Form.Item>
        </fieldset>
        <fieldset>
          <legend data-test="fuelLegend">{formatMessage({ id: 'form.labels.fuel' })}</legend>
        </fieldset>
        <fieldset>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.initialFuel' })}
            data-test="formItemInitialFuel"
          >
            {getFieldDecorator('departure_fuel_pre_uplifts')(
              <InputNumberUnits
                min={0}
                max={aircraft.max_fuel_value || undefined}
                units={aircraft.fuel_unit}
                data-test="formElementInitialFuel"
              />,
            )}
          </Form.Item>
          {getFieldDecorator('fuel_uplifts')(
            <FuelUplifts
              aircraft={aircraft}
              formItemLayout={(labelCol, wrapperCol)}
              active={activeUplift}
              onActive={(key) => this.setState({ activeUplift: key })}
              data-test="formElementFuelUplifts"
            />,
          )}
          <Form.Item wrapperCol={buttonWrapperCol} data-test="formItemFuelButton">
            <Button onClick={this.addFuelUplift} icon="plus" data-test="formElementFuelButton">
              {formatMessage({ id: 'form.button.fuelUplift' })}
            </Button>
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.fuelAdjustment' })}
            data-test="formItemFuelAdjustment"
          >
            {getFieldDecorator('departure_fuel_adjustment')(
              <InputNumberUnits units={aircraft.fuel_unit} data-test="formElementFuelAdjustment" />,
            )}
          </Form.Item>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'form.labels.arrivalFuel' })}
            data-test="formItemArrivalFuel"
          >
            {getFieldDecorator('arrival_fuel')(
              <InputNumberUnits
                min={0}
                max={aircraft.max_fuel_value || undefined}
                step={1}
                units={aircraft.fuel_unit}
                data-test="formElementArrivalFuel"
              />,
            )}
          </Form.Item>
        </fieldset>
        <fieldset>
          <legend data-test="oilLegend">{formatMessage({ id: 'form.labels.oil' })}</legend>
          {getFieldDecorator('oil_uplift')(
            <OilUplift data-test="formElementOilUplift" aircraft={aircraft} formItemLayout={formItemLayout} />,
          )}
        </fieldset>
        <fieldset>
          <legend data-test="groundLegend">{formatMessage({ id: 'form.labels.groundServices' })}</legend>
          {getFieldDecorator('deice_events')(
            <DeiceEvents
              formItemLayout={(labelCol, wrapperCol)}
              active={activeDeice}
              onActive={(key) => this.setState({ activeDeice: key })}
              flight={flight}
              data-test="formElementDeiceEvents"
            />,
          )}
          <Form.Item wrapperCol={buttonWrapperCol} data-test="formItemDeiceButton">
            <Button onClick={this.addDeicing} icon="plus" data-test="formElementDeiceButton">
              {formatMessage({ id: 'form.button.deicing' })}
            </Button>
          </Form.Item>
        </fieldset>
        <fieldset>
          <legend data-test="confirmLegend">{formatMessage({ id: 'form.labels.confirm' })}</legend>
          <Form.Item
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            label={formatMessage({ id: 'title.attachments' })}
            data-test="formItemAttachments"
          >
            {getFieldDecorator('attachments', {
              valuePropName: 'fileList',
              getValueFromEvent(event) {
                const eventFileList = event.fileList.map((item) => {
                  if (item.uid.indexOf('rc-upload') > -1) {
                    return {
                      ...item,
                      status: 'newfile',
                    };
                  }
                  return item;
                });
                return eventFileList;
              },
              getValueProps(value) {
                if (value) {
                  return {
                    fileList: value.map((item) => ({
                      ...item,
                      uid: `${item.uid}`,
                      key: item.uid,
                    })),
                  };
                }
                return null;
              },
            })(
              <Upload
                className={styles.upload}
                beforeUpload={() => false}
                multiple
                onPreview={onAttachmentPreview}
                data-test="formElementAttachments"
                accept={['.jpg', '.jpeg', '.pdf', '.png'].join(',')}
              >
                <Button>
                  <Icon type="upload" /> {formatMessage({ id: 'form.button.upload' })}
                </Button>
              </Upload>,
            )}
          </Form.Item>
          <Form.Item wrapperCol={buttonWrapperCol}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={finish && submitting}
              loading={!finish && submitting}
              onClick={() => this.setState({ finish: false })}
              data-test="buttonSave"
            >
              {formatMessage({ id: 'form.button.save' })}
            </Button>{' '}
            <Button
              type="primary"
              htmlType="submit"
              disabled={!finish && submitting}
              loading={finish && submitting}
              onClick={() => this.setState({ finish: true })}
              data-test="buttonSaveAndFinish"
            >
              {formatMessage({ id: 'form.button.saveAndFinish' })}
            </Button>
          </Form.Item>
          {onDelete && (
            <Form.Item wrapperCol={buttonWrapperCol}>
              <Popconfirm
                title={formatMessage({ id: 'form.question.areYouSureDeleteSector' })}
                okText={formatMessage({ id: 'form.button.yes' })}
                cancelText={formatMessage({ id: 'form.button.no' })}
                placement="topRight"
                onClick={(e) => e.stopPropagation()}
                onCancel={(e) => e.stopPropagation()}
                onConfirm={() => onDelete(flight.id, 'remove')}
              >
                <Button type="danger" data-test="buttonDelete">
                  {formatMessage({ id: 'form.button.delete' })}
                </Button>
              </Popconfirm>
            </Form.Item>
          )}
        </fieldset>
      </Form>
    );
  }
}

const ComposedFlightForm = compose(
  injectIntl,
  connect(({ userSettings }) => ({ userSettings })),
  Form.create({
    onValuesChange(props, _, allValues) {
      if (!props.onChange) return;
      props.onChange({ id: props.flight.id, ...allValues });
    },
    mapPropsToFields({ flight }) {
      const fields = [
        'aircraft_id',
        'departure_airport_id',
        'arrival_airport_id',
        'hobbs_departure',
        'hobbs_arrival',
        'landings_count',
        'time_offblocks',
        'time_takeoff',
        'time_landing',
        'time_onblocks',
        'apu_seconds',
        'oil_uplift',
        'departure_fuel_pre_uplifts',
        'departure_fuel_adjustment',
        'fuel_uplifts',
        'deice_events',
        'arrival_fuel',
        'attachments',
      ].reduce((acc, x) => {
        let value;
        if (flight && flight[x]) {
          value = x.startsWith('time_') ? moment.utc(flight[x]) : flight[x];
        }
        acc[x] = Form.createFormField({ value });
        return acc;
      }, {});
      return fields;
    },
  }),
)(FlightForm);

export default ComposedFlightForm;
