import React from 'react';
import { Col, Row, Progress } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { formatTime } from '../../utils/utils';
import styles from './ProgressBarMX.module.less';

const ProgressBarMX = ({ enabled, item, unitSingular, unitPlural, layout, intl: { formatMessage }, aircraft }) => {
  let displayUnitSingular = unitSingular;
  let displayUnitPlural = unitPlural;
  let { remaining, tolerance } = item;
  const getProgressStyles = (percentage) => {
    if (percentage === 'disabled') {
      const strokeColor = '#f5f5f5';
      const textColor = null;
      return {
        strokeColor,
        textColor,
      };
    }

    const defaultStrokeColor = {
      '0%': '#d01e27',
      '100%': '#f5222d',
    };
    const warningStrokeColor = {
      '0%': '#f2a650',
      '100%': '#fad288',
    };
    const greenStrokeColor = {
      '0%': '#4fb184',
      '100%': '#35b96d',
    };
    let strokeColor = defaultStrokeColor;
    let textColor = styles.statusTitleGreen;
    if (item.tolerance && item.remaining > 0) {
      strokeColor = warningStrokeColor;
      textColor = styles.statusTitleAmber;
    } else if (percentage > 0) {
      strokeColor = greenStrokeColor;
      textColor = styles.statusTitleGreen;
    } else if (percentage <= 0 && item.threshold === 'critical') {
      strokeColor = warningStrokeColor;
      textColor = styles.statusTitleAmber;
    } else if (percentage <= 0) {
      strokeColor = defaultStrokeColor;
      textColor = styles.statusTitleRed;
    }

    return {
      strokeColor,
      textColor,
    };
  };

  let itemPercentage = (item.remaining / 100) * 100;
  if (item.format === 'days') {
    itemPercentage = (item.remaining / 365) * 100;
  }
  let itemProgressStyles = getProgressStyles(itemPercentage);
  let itemRemainingDisplay = item.remaining;

  if (item.remaining <= 0) {
    itemPercentage = 100;
    itemProgressStyles = getProgressStyles(0);
    itemRemainingDisplay = '-';
  }

  if (!enabled) {
    itemProgressStyles = getProgressStyles('disabled');
  }

  if (item.format === 'seconds' && enabled) {
    // If we want to display as HH:mm, uncomment
    remaining /= 3600;
    tolerance /= 3600;
    itemRemainingDisplay = formatTime(itemRemainingDisplay === '-' ? 0 : itemRemainingDisplay);

    // If we want to display as HH only, uncomment:
    // itemRemainingDisplay = Math.round(itemRemainingDisplay / 3600);
  }

  if (item.format === 'days') {
    tolerance *= 30;
  }

  if (item.format === 'days' && itemRemainingDisplay > 30) {
    // Switch days to months
    itemRemainingDisplay = Math.round(itemRemainingDisplay / 30);
    displayUnitSingular = formatMessage({ id: 'units.monthLeftShort' });
    displayUnitPlural = formatMessage({ id: 'units.monthsLeftShort' });
  }

  return (
    <Row className={styles.dataRow}>
      <Col xs={24} className={styles.xxsCol}>
        <div
          className={
            !enabled ? `${styles.summaryItemWrapper} ${styles.summaryItemWrapperDisabled}` : styles.summaryItemWrapper
          }
          data-test="progressWrapper"
        >
          <span
            className={`${styles.statusTitle} ${itemProgressStyles.textColor} ${
              layout === 'inline' ? styles.statusTitleInline : undefined
            }`}
            data-test="progressTitle"
          >
            <span className={aircraft ? styles.numberWrapper : ''}>
              {item.dot && tolerance && remaining <= tolerance ? <div className={styles.warningCircle} /> : null}
              <span data-test="progressValue">{itemRemainingDisplay === null ? '-' : itemRemainingDisplay}</span>{' '}
            </span>
            {itemRemainingDisplay === 1 ? displayUnitSingular : displayUnitPlural}
          </span>
          {!aircraft ? (
            <Progress
              showInfo={false}
              percent={itemPercentage}
              strokeWidth={5}
              strokeColor={itemProgressStyles.strokeColor}
              size="small"
              data-test="progressElement"
            />
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

ProgressBarMX.propTypes = {
  enabled: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  unitSingular: PropTypes.string.isRequired,
  unitPlural: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  layout: PropTypes.string,
  aircraft: PropTypes.bool,
};

ProgressBarMX.defaultProps = {
  layout: null,
  aircraft: false,
};
export default injectIntl(ProgressBarMX);
