/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import closeIcon from '../../assets/new-close-icon-mobile.svg';
import styles from './SlidingDrawer.module.less';

const SlidingDrawer = ({ toggleDrawer, children, filterDrawer = false }): JSX.Element => {
  const drawerRef = useRef<HTMLInputElement>();
  const bgRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const currentRef = drawerRef.current;
    if (currentRef) {
      setTimeout(() => {
        if (drawerRef.current) {
          if (filterDrawer) {
            drawerRef.current.classList.add(styles.drawerSlideOutFilters);
          } else {
            drawerRef.current.classList.add(styles.drawerSlideOut);
          }
        }
      }, 50);
    }
  }, [drawerRef]);

  const closeDrawer = (): void => {
    if (drawerRef.current) {
      if (filterDrawer) {
        drawerRef.current.classList.remove(styles.drawerSlideOutFilters);
        drawerRef.current.classList.add(styles.drawerSlideInFilters);
      } else {
        drawerRef.current.classList.remove(styles.drawerSlideOut);
        drawerRef.current.classList.add(styles.drawerSlideIn);
      }
    }
    if (bgRef.current) {
      bgRef.current.classList.add(styles.bgFadeOut);
    }
    setTimeout(() => {
      toggleDrawer();
    }, 150);
  };

  const pageContainer = document.getElementById('root');

  return ReactDOM.createPortal(
    <div
      role="button"
      tabIndex={0}
      onKeyDown={(): void => null}
      className={styles.drawerBg}
      ref={bgRef}
      onClick={closeDrawer}
    >
      <div className={styles.drawerWrapper} ref={drawerRef}>
        <button
          className={filterDrawer ? styles.drawerClosrButtonFilters : styles.drawerCloseButton}
          type="button"
          onClick={(e): void => {
            e.stopPropagation();
            closeDrawer();
          }}
        >
          <img src={closeIcon} alt="close icon" id="drawerCloseButton" className={styles.closeIcon} />
        </button>
        <div role="button" onClick={(e): void => e.stopPropagation()} onKeyDown={(): void => null} tabIndex={0}>
          <div>{children}</div>
        </div>
      </div>
    </div>,
    pageContainer,
  );
};

export default SlidingDrawer;
