import { Button, DatePicker, Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import SelectAircraft from '../../components/SelectAircraft';
import { AircraftResource } from '../../models/aircraft';
import SelectFlight from '../../components/SelectFlight';
import defaults from '../../utils/defaults';
import { getAllAircraft } from '../../models/aircraft/actions';
import { resetEphemeralDefect, updateEphemeralDefect } from '../../models/defects/actions';
import { getFlightsForAircraft } from '../../models/flights/actions';
import AddDefectPageWrapper from './AddDefectPageWrapper';
import styles from './style.module.less';

class FlightDefect extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    submitting: false,
  };

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(resetEphemeralDefect());
    dispatch(getAllAircraft());

    if (match.params.id) {
      this.resetFlights(match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id && match.params.id) {
      this.resetFlights(match.params.id);
    }
  }

  resetFlights = async (aircraft) => {
    const { form, dispatch } = this.props;
    form.setFieldsValue({
      flight: undefined,
    });
    await dispatch(
      getFlightsForAircraft({
        payload: aircraft,
      }),
    );
  };

  handleSubmit = (event) => {
    const { form, dispatch, match, history } = this.props;
    event.preventDefault();
    const selectedAircraft = match.params.id;
    form.validateFields((err, values) => {
      if (err) return;
      dispatch(
        updateEphemeralDefect({
          payload: {
            flight_id: values.flight,
            aircraft_id: selectedAircraft || values.aircraft,
            date: values.date,
          },
        }),
      );
      history.push(selectedAircraft ? `/aircraft/${selectedAircraft}/defects/add/details` : '/add/defect/details');
    });
  };

  render() {
    const {
      form,
      intl: { formatMessage },
      match,
      submitting,
      userSettings,
    } = this.props;
    const selectedAircraft = match.params.id || form.getFieldValue('aircraft');
    const { getFieldDecorator } = form;
    let labelCol = {
      span: 8,
    };
    let wrapperCol = {
      span: 12,
    };
    if (selectedAircraft) {
      labelCol = {
        span: 7,
      };
      wrapperCol = {
        span: 12,
      };
    }
    const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;
    return (
      <AddDefectPageWrapper>
        <Form layout="horizontal" className={styles.stepForm} onSubmit={this.handleSubmit} hideRequiredMark>
          {!match.params.id && (
            <Form.Item
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              label={formatMessage({ id: 'form.labels.aircraft' })}
            >
              {getFieldDecorator('aircraft', {
                rules: [{ required: true, message: formatMessage({ id: 'message.noAircraftSelected' }) }],
              })(<SelectAircraft onChange={this.resetFlights} requiredResource={AircraftResource.DEFECT} />)}
            </Form.Item>
          )}
          <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.flight' })}>
            {getFieldDecorator('flight', {
              rules: [
                {
                  required: false,
                  message: formatMessage({ id: 'message.selectAircraft' }),
                },
              ],
            })(<SelectFlight aircraftId={selectedAircraft} />)}
          </Form.Item>
          <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.date' })}>
            {getFieldDecorator('date', {
              rules: [{ required: true, message: formatMessage({ id: 'message.noDateSelected' }) }],
            })(<DatePicker format={dateFormat} />)}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: wrapperCol.span, offset: labelCol.span },
            }}
          >
            <Button type="primary" loading={submitting} htmlType="submit">
              {formatMessage({ id: 'form.button.continue' })}
            </Button>
          </Form.Item>
        </Form>
      </AddDefectPageWrapper>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(({ userSettings }) => ({ userSettings })),
  Form.create(),
)(FlightDefect);
