/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Loading from '../TFLoading/index';
import {
  putWorkpack,
  postWorkpack,
  getWorkpackUrl,
  completeWorkpack,
  deleteAttachment,
  addAttachmentWorkpack,
} from '../../services/api';
import { AircraftPermission, AircraftResource, AircraftState } from '../../models/aircraft/index';
import { WorkpacksState, Workpack } from '../../models/workpacks/index';
import { UserSettingsState } from '../../models/userSettings/index';
import { getSingleWorkpack } from '../../models/workpacks/actions';
import TFModal from '../TFModal/TFModal';
import { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import servers from '../../utils/servers';
import Controls from './Components/Controls';
import NotesAndAttachments from './Components/NotesAndAttachments';
import AircraftTotals from './Components/AircraftTotals';
import WorkpackInfo from './Components/WorkpackInfo';
import AddItemsToWorkpack from './Components/AddItemsToWorkpack';
import ViewWorkpacks from './Components/ViewWorkpacks';
import WorkpackTitle from './Components/WorkpackTitle';
import ResolutionDetails from './Components/ResolutionDetails';
import CRSAcceptance from './Components/CRSAcceptance';
import styles from './workpackDrawer.module.less';

interface WorkpackProps {
  aircraft: AircraftState;
  workpacks: WorkpacksState;
  userSettings: UserSettingsState;
}

enum DrawerState {
  EDIT = 'edit',
  VIEW = 'view',
  NEW = 'new',
}

enum ItemStatus {
  COMPLETE = 'complete',
  PENDING = 'pending',
}

const ViewWorkpack = ({
  wp,
  pendingWps,
  ac,
  initialMode,
  wpId,
  handleDeleteWorkpack,
  toggleDrawer,
  updateWorkpackid,
  match,
}): JSX.Element => {
  const [localWorkpackState, updateLocalWorkpackState] = useState<Workpack>();
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [workpackUrl, setWorkpackUrl] = useState<string>('');
  const [CRSUrl, setCRSUrl] = useState<string>('');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const Dispatch = useDispatch();
  const {
    workpack_items: wpItems,
    status,
    date,
    release_date,
    po_number,
    description,
    aircraft_id,
    attachments,
    notes,
  } = localWorkpackState || {};
  const initialPayload = { workpack_items: [], id: wpId, aircraft_id };
  const [stagedPayload, updateStagedPayload] = useState(initialPayload);
  const [drawerMode, updateDrawerMode] = useState(initialMode);
  const [acceptanceSig, setAcceptanceSig] = useState('');
  const [closeClick, setCloseClick] = useState(false);
  const unsavedChanges = stagedPayload && initialPayload && !_.isEqual(stagedPayload, initialPayload);

  const editStagedPayload = (newPayload): void => {
    updateStagedPayload((currentPayload) => ({ ...currentPayload, ...newPayload }));
  };

  const changeMode = (newMode: string): void => {
    updateDrawerMode(newMode);
  };

  const getWorkpack = (): Workpack => {
    return wp.find((wrkpck) => wrkpck.id === wpId);
  };

  useEffect(() => {
    if (drawerMode !== DrawerState.NEW) {
      const currentWorkpack = getWorkpack();
      updateLocalWorkpackState(currentWorkpack);
      const { aircraft_id: aircraftId } = currentWorkpack;
      const payloadWithId = { workpack_items: [], id: wpId, aircraft_id: aircraftId };
      updateStagedPayload(payloadWithId);
      const getPublicShareUrl = async (): Promise<void> => {
        try {
          const response = await getWorkpackUrl(currentWorkpack.id);
          setWorkpackUrl(servers.pub + response.url);
          setCRSUrl(response.url);
        } catch (err) {
          setWorkpackUrl('');
          setCRSUrl('');
        }
      };
      getPublicShareUrl();
    }
  }, [wp.length]);

  const removeWPItem = (itemId: string, mxItemId: string): void => {
    const localItemHasNotBeenSaved = (): boolean => itemId === null;
    let updatedLoclWorkpack;
    let updatedStagedPayload;

    if (localItemHasNotBeenSaved()) {
      updatedStagedPayload = wpItems.filter((item) => item.mx_item_id !== mxItemId);
      updatedLoclWorkpack = { ...localWorkpackState, workpack_items: updatedStagedPayload };
    } else {
      const updatedLoclWpItems = wpItems.filter((item) => item.id !== itemId);
      updatedLoclWorkpack = { ...localWorkpackState, workpack_items: updatedLoclWpItems };
      updatedStagedPayload = stagedPayload.workpack_items.concat({ id: itemId, _destroy: true });
    }
    editStagedPayload({ workpack_items: updatedStagedPayload });
    updateLocalWorkpackState(updatedLoclWorkpack);
  };

  const addWpItem = (mx_item, mxType): void => {
    let itemIsDuplicate;
    if (drawerMode !== 'new') itemIsDuplicate = wpItems.find((item) => mx_item.id === item.id);
    if (!itemIsDuplicate) {
      mx_item.type = mxType;
      const localItem = { mx_item, id: null, mx_item_id: mx_item.id };
      let addItemToLocalWorkpackState = [];
      if (drawerMode === 'new') {
        if (!wpItems) {
          addItemToLocalWorkpackState = [localItem];
        } else {
          addItemToLocalWorkpackState = (wpItems as any[]).concat(localItem);
        }
      } else {
        addItemToLocalWorkpackState = (wpItems as any[]).concat(localItem);
      }

      updateLocalWorkpackState((currentWorkpackState) => ({
        ...currentWorkpackState,
        workpack_items: addItemToLocalWorkpackState,
      }));
      if (drawerMode === 'new') {
        const addWPIdToPayload =
          stagedPayload.workpack_items.length > 0
            ? stagedPayload.workpack_items.concat({ mx_item_id: mx_item.id })
            : [{ mx_item_id: mx_item.id }];
        editStagedPayload({ workpack_items: addWPIdToPayload });
      } else {
        const addWPItemToPayload = stagedPayload.workpack_items.concat({ mx_item_id: mx_item.id, id: null });
        editStagedPayload({ workpack_items: addWPItemToPayload });
      }
    }
  };

  const resetPayload = (): void => {
    editStagedPayload(initialPayload);
  };

  const resetDrawer = (): void => {
    changeMode('view');
    const currentWorkpack = getWorkpack();
    updateLocalWorkpackState(currentWorkpack);
    resetPayload();
  };

  const handleUnsavedItems = (): void => {
    if (stagedPayload.workpack_items !== localWorkpackState.workpack_items) {
      setModalVisible(true);
    } else {
      resetDrawer();
    }
  };

  const saveWorkpack = async (): Promise<void> => {
    setLoadingState(true);
    try {
      if (drawerMode === 'new') {
        delete stagedPayload.id;
        stagedPayload.aircraft_id = ac[0].id;
        const response = await postWorkpack(stagedPayload);
        if (response && response.statusCode > 199 && response.statusCode < 400) {
          Dispatch(getSingleWorkpack({ payload: response.body.id }));
          updateWorkpackid(response.body.id);
          updateDrawerMode(DrawerState.VIEW);
        } else {
          console.error(response.statusCode);
        }
      } else {
        const response = await putWorkpack({ body: stagedPayload, id: wpId });
        if (response && response.statusCode > 199 && response.statusCode < 400) {
          Dispatch(getSingleWorkpack({ payload: wpId }));
          updateDrawerMode(DrawerState.VIEW);
        } else {
          console.error(response.statusCode);
        }
      }
    } catch (e) {
      setLoadingState(false);
      console.error(e);
    }
    setLoadingState(false);
    resetPayload();
  };

  const haveReleaseInfo = wpItems && wpItems.some((item) => item?.mx_item?.mx_events?.length === 0);

  const completePendingWorkpack = async (): Promise<void> => {
    if (acceptanceSig.length !== 0 && haveReleaseInfo) {
      const { id } = localWorkpackState.workpack_items[0].mx_item.mx_events[0].release;
      try {
        const response = await completeWorkpack({ id, body: { approval_signature_data: acceptanceSig } });
        if (response && response.statusCode > 199 && response.statusCode < 400) {
          Dispatch(getSingleWorkpack({ payload: wpId }));
          updateDrawerMode(DrawerState.VIEW);
        } else {
          console.error(response.statusCode);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handlePendingIndexChange = (index: number): void => {
    const activePendingWp = pendingWps[index];
    updateLocalWorkpackState(activePendingWp);
    setAcceptanceSig('');
  };

  const handleDeleteAttachement = async (id: string): Promise<void> => {
    if (id) {
      try {
        await deleteAttachment(id);
        Dispatch(getSingleWorkpack({ payload: wpId }));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCancelClick = (): void => {
    if (closeClick) setCloseClick(false);
    setModalVisible(false);
  };

  const handleContinueClick = (): void => {
    if (closeClick) {
      setCloseClick(false);
      document.getElementById('drawerCloseButton').click();
    }
    resetDrawer();
    setModalVisible(false);
  };

  const resolutionInfo = wpItems && wpItems.find((item) => item?.mx_item?.mx_events?.length !== 0);

  return (
    <>
      <div className={styles.workpackDrawerWrapper}>
        <Loading contain loading={isLoading} />
        <WorkpackTitle
          mode={drawerMode}
          description={description}
          changeMode={changeMode}
          date={date}
          po={po_number}
          editStagedPayload={editStagedPayload}
          status={status}
          handleDeleteWorkpack={handleDeleteWorkpack}
          wpId={wpId}
          unsavedChanges={unsavedChanges}
          setModalVisible={setModalVisible}
          setCloseClick={setCloseClick}
          aircraftId={aircraft_id}
          CRSUrl={CRSUrl}
          match={match}
        />
        {drawerMode === DrawerState.VIEW && (
          <WorkpackInfo dateCreated={date} status={status} dateCompleted={release_date} publicLink={workpackUrl} />
        )}
        {false && (
          <NotesAndAttachments
            notes={notes}
            attachments={attachments}
            handleDeleteClick={handleDeleteAttachement}
            editStagedPayload={editStagedPayload}
            id={wpId}
            mode={drawerMode}
          />
        )}
        {ac[0] && ac[0].totals && <AircraftTotals totals={ac[0].totals} showApu={ac[0].apu_installed} />}
        {wpItems && wpItems.length > 0 && (
          <ViewWorkpacks wpItems={wpItems} mode={drawerMode} removeWPItem={removeWPItem} />
        )}
        {drawerMode === DrawerState.VIEW && status !== ItemStatus.COMPLETE && status !== ItemStatus.PENDING && (
          <AircraftAuthenticationWrapper
            aircraftId={aircraft_id}
            requiredResource={AircraftResource.WORKPACK}
            requiredPermissionLevel={AircraftPermission.UPDATE}
          >
            <div className={styles.CRSButtonWrapper}>
              <a href={CRSUrl} target="_blank" rel="noopener noreferrer">
                <button type="button" className={styles.addCRSButton}>
                  Add CRS
                </button>
              </a>
            </div>
          </AircraftAuthenticationWrapper>
        )}
        {(drawerMode === DrawerState.EDIT || drawerMode === DrawerState.NEW) && (
          <AddItemsToWorkpack
            aircraft={ac[0]}
            addWpItem={addWpItem}
            setLoadingState={setLoadingState}
            wpItems={wpItems}
          />
        )}
        {(status === ItemStatus.COMPLETE || status === ItemStatus.PENDING) &&
          resolutionInfo &&
          drawerMode === DrawerState.VIEW && (
            <ResolutionDetails release={resolutionInfo.mx_item.mx_events[0]} poNum={po_number} />
          )}
        {status === ItemStatus.PENDING && (
          <CRSAcceptance setAcceptanceSig={setAcceptanceSig} acceptanceSig={acceptanceSig} />
        )}
        <Controls
          saveWorkpack={saveWorkpack}
          drawerMode={drawerMode}
          status={status}
          pendingWps={pendingWps}
          activeWPId={wpId}
          handlePendingIndexChange={handlePendingIndexChange}
          removeUnsavedItems={handleUnsavedItems}
          acceptanceSig={acceptanceSig}
          completePendingWorkpack={completePendingWorkpack}
        />
      </div>
      {modalVisible ? (
        <TFModal
          handleCancelClick={handleCancelClick}
          handleContinueClick={handleContinueClick}
          title="Workpack Changes"
          text="Changes to the workpack have not been saved"
          cancelButtonText="Cancel"
          confirmButtonText="Discard changes"
        />
      ) : null}
    </>
  );
};

export default ViewWorkpack;
