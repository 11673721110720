import { BaseModel } from '..';

const initialState: SubscriptionsState = {
  subscriptionsMap: new Map(),
  lastFetched: Date.now(),
  data: {
    list: [],
  },
  events: {
    list: [],
  },
  subscription: {},
};

export enum SubscriptionsActionTypes {
  REMOVE_SUBSCRIPTION = 'subscriptions/removeSubscription',
  SAVE_ALL_SUBSCRIPTIONS = 'subscriptions/saveAllSubscriptions',
  SAVE_SUBSCRIPTION = 'subscriptions/saveSubscription',
  SET_EVENTS = 'subscriptions/setEvents',
}

interface RemoveSubscriptionSubscriptionsActionType {
  type: SubscriptionsActionTypes.REMOVE_SUBSCRIPTION;
  payload: { removeId: string };
}

interface SaveAllSubscriptionsActionType {
  type: SubscriptionsActionTypes.SAVE_ALL_SUBSCRIPTIONS;
  payload: Subscription[];
}

interface SaveSubscriptionsActionType {
  type: SubscriptionsActionTypes.SAVE_SUBSCRIPTION;
  payload: { body: Subscription };
}

interface SetEventsSubscriptionsActionType {
  type: SubscriptionsActionTypes.SET_EVENTS;
  payload: { list: Subscription[] };
}

type SubscriptionsAction =
  | RemoveSubscriptionSubscriptionsActionType
  | SaveAllSubscriptionsActionType
  | SaveSubscriptionsActionType
  | SetEventsSubscriptionsActionType;

export default function(state = initialState, action: SubscriptionsAction): SubscriptionsState {
  switch (action.type) {
    case SubscriptionsActionTypes.SAVE_ALL_SUBSCRIPTIONS: {
      const subscriptionsMap = new Map(state.subscriptionsMap.entries());
      action.payload.forEach((subscription) => {
        subscriptionsMap.set(subscription.id, {
          ...subscriptionsMap.get(subscription.id),
          ...subscription,
          lastFetched: Date.now(),
        });
      });

      return {
        ...state,
        subscriptionsMap,
        lastFetched: Date.now(),
      };
    }
    case SubscriptionsActionTypes.SAVE_SUBSCRIPTION: {
      const subscriptionsMap = new Map(state.subscriptionsMap.entries());
      if (action.payload.body && action.payload.body.id) {
        const subscription = action.payload.body;
        subscriptionsMap.set(subscription.id, {
          ...subscriptionsMap.get(subscription.id),
          ...subscription,
        });
      }
      return {
        ...state,
        subscriptionsMap,
      };
    }
    case SubscriptionsActionTypes.REMOVE_SUBSCRIPTION: {
      const subscriptionsMap = new Map(state.subscriptionsMap.entries());
      if (action.payload.removeId) {
        subscriptionsMap.delete(action.payload.removeId);
      }
      return {
        ...state,
        subscriptionsMap,
      };
    }
    case SubscriptionsActionTypes.SET_EVENTS: {
      return {
        ...state,
        events: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface SubscriptionsState {
  subscriptionsMap: Map<string, Subscription>;
  lastFetched: number;
  data: {
    list: Subscription[];
  };
  events: {
    list: Subscription[];
  };
  subscription: {};
}

export interface Subscription extends BaseModel {
  id: string;
  model: string;
  event: string;
  filter_params: {
    aircraft_id: string;
  };
  enabled: boolean;
  last_triggered: unknown;
  last_updated: number;
}
