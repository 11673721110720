import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Portal from '../TFPortal/TFPortal';
import aircraft, { AircraftPermission } from '../../models/aircraft';
import { UserPermission } from '../../models/userSettings';
import moreIcon from '../../assets/icon-more.svg';
import AuthMenuItem from './AuthMenuItem';
import styles from './AuthDropdownMenu.module.less';

const AuthDropdownMenu = ({
  setDrawerVisible,
  setEditingRecord,
  setEditable,
  handleDelete,
  viewText,
  viewCallback,
  editText,
  editCallback,
  customText,
  customCallback,
  deleteButtonChild,
  aircraftId,
  hasDrawer = false,
  resource,
  options,
}: AuthDropdownMenuProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const [menuStyle, setMenuStyle] = useState({});
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);

  const menuWrapperRef = useRef<HTMLDivElement>();

  const handleMenuClick = (): void => {
    setShowMenu(true);
    const rect = menuRef.current.getBoundingClientRect();
    const { top, left } = rect;
    if (menuWrapperRef.current && menuWrapperRef.current.childNodes.length !== 0) {
      setMenuStyle({ position: 'absolute', top: top + 24 + window.scrollY, left: left - 49 + window.scrollX });
    }
  };

  return (
    <div
      onMouseOver={(): void => {
        handleMenuClick();
      }}
      onFocus={(): void => handleMenuClick()}
      onMouseLeave={(): void => setShowMenu(false)}
      onBlur={(): void => setShowMenu(false)}
      className={styles.menuButton}
    >
      <img src={moreIcon} ref={menuRef} alt="Open menu" className={styles.moreIcon} data-test="threeDotMenu" />

      {showMenu && (
        <Portal>
          <div ref={menuWrapperRef} className={styles.menuWrapper} style={menuStyle}>
            {options.read && (
              <AuthMenuItem
                onMenuItemClick={(): void => {
                  if (hasDrawer) {
                    setDrawerVisible(true);
                    setEditingRecord();
                  }
                  if (viewCallback) {
                    viewCallback();
                  }
                }}
                action={viewText || <span>{formatMessage({ id: 'text.view' })}</span>}
                aircraftId={aircraftId}
                resource={resource}
                permissionLevel={aircraftId ? AircraftPermission.READ : UserPermission.READ}
              />
            )}
            {options.update && (
              <AuthMenuItem
                onMenuItemClick={(): void => {
                  if (hasDrawer) {
                    setDrawerVisible(true);
                    setEditingRecord();
                    setEditable(true);
                  }
                  if (editCallback) {
                    editCallback();
                  }
                }}
                action={editText || <span>{formatMessage({ id: 'text.edit' })}</span>}
                aircraftId={aircraftId}
                resource={resource}
                permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
              />
            )}
            {options.update && customText && (
              <AuthMenuItem
                onMenuItemClick={(): void => {
                  if (hasDrawer) {
                    setDrawerVisible(true);
                    setEditingRecord();
                    setEditable(true);
                  }
                  if (customCallback) {
                    customCallback();
                  }
                }}
                action={customText || <span>{formatMessage({ id: 'text.edit' })}</span>}
                aircraftId={aircraftId}
                resource={resource}
                permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
              />
            )}
            {options.delete && (
              <AuthMenuItem
                onMenuItemClick={(): void => {
                  handleDelete();
                }}
                action={deleteButtonChild || <span>{formatMessage({ id: 'text.delete' })}</span>}
                optionalClass={styles.delete}
                aircraftId={aircraftId}
                resource={resource}
                permissionLevel={aircraftId ? AircraftPermission.DELETE : UserPermission.DELETE}
              />
            )}
          </div>
        </Portal>
      )}
    </div>
  );
};

interface MenuOptions {
  read: boolean;
  update: boolean;
  delete: boolean;
}

type AuthDropdownMenuProps = {
  record?: Record<string, unknown>;
  setDrawerVisible?: (arg0: boolean) => void;
  setEditingRecord?: () => void;
  setEditable?: (arg0: boolean) => void;
  handleDelete?: () => void;
  viewText?: JSX.Element | string;
  viewCallback?: () => void;
  editText?: JSX.Element | string;
  editCallback?: () => void;
  deleteButtonChild?: JSX.Element | string;
  customText?: JSX.Element | string;
  customCallback?: () => void;
  aircraftId?: string;
  resource?: string;
  menuStyle?: object;
  hasDrawer?: boolean;
  options: MenuOptions;
};

export default AuthDropdownMenu;
