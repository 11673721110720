import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, InputNumber } from 'antd';
import styles from './FuelSection.module.less';

const FuelSection = ({
  fuelLogs,
  editable,
  form,
  handleFuelUpliftAdd,
  intl: { formatMessage },
  disableFuelUplift,
  onMainPageCancelClick,
  aircraft,
}) => {
  const [addUpliftVisible, setAddUpliftVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const blankFuelLog = {
    fuel_tank_id: '',
    aircraft_actual_uplift: 0,
  };

  const { getFieldDecorator } = form;
  const { max_fuel_value } = aircraft;

  const removeUnusedFuelUplifts = () => {
    if (addUpliftVisible) fuelLogs[activeIndex].fuel_uplifts.pop();
  };

  const handleToggleClick = (index) => {
    if (addUpliftVisible && index !== activeIndex) removeUnusedFuelUplifts();
    if (fuelLogs[index].fuel_uplifts.length > 0) fuelLogs[index].fuel_uplifts.push(blankFuelLog);
    setAddUpliftVisible(true);
    setActiveIndex(index);
  };

  const handleFuelUpliftSaveClick = async () => {
    await handleFuelUpliftAdd();
    setAddUpliftVisible(false);
  };

  const handleFuelUpliftCancelClick = () => {
    removeUnusedFuelUplifts();
    setAddUpliftVisible(false);
  };

  useEffect(() => {
    if (disableFuelUplift && addUpliftVisible) {
      fuelLogs[activeIndex].fuel_uplifts.pop();
      setAddUpliftVisible(false);
      onMainPageCancelClick();
    }
  }, [disableFuelUplift, addUpliftVisible, fuelLogs, activeIndex, onMainPageCancelClick]);

  const fuelUpliftLayout = (uplifts, id, unit, index) => {
    let newUplifts = [];
    let upliftLayout;
    if (uplifts) newUplifts = uplifts;
    if (addUpliftVisible && uplifts.length === 0 && index === activeIndex) {
      upliftLayout = (
        <Col xs={12} md={6} key={id}>
          <Form.Item data-testid={`FuelSection -- ${index}Uplift`}>
            <div>
              <div className={styles.dataTitle}>{formatMessage({ id: 'text.uplift' })}</div>
              <div className={styles.fuelInput}>
                {getFieldDecorator(`fuel_logs.[${index}]fuel_uplifts.[0]aircraft_actual_uplift`, {
                  rules: [
                    { required: false },
                    { type: 'number' },
                    { pattern: new RegExp(`^[0-9]{1,5}$`), message: formatMessage({ id: 'message.wholeNumbers' }) },
                    { max: max_fuel_value, type: 'number', message: formatMessage({ id: 'message.fuelWarning' }) },
                  ],
                  initialValue: 0,
                })(<InputNumber type="number" className={styles.dataInput} step={1} max={max_fuel_value} />)}
                {` ${unit}`}
              </div>
            </div>
          </Form.Item>
        </Col>
      );
    } else if (addUpliftVisible && !editable && uplifts.length > 0 && index === activeIndex) {
      upliftLayout = newUplifts.map((item, i) => {
        return (
          <Col xs={12} md={6} key={`Uplift add ${item.id}`}>
            <Form.Item data-testid={`FuelSection -- ${index}Uplift${i}`}>
              <div>
                <div className={styles.dataTitle}>
                  {newUplifts.length > 1
                    ? `${formatMessage({ id: 'text.uplift' })} #${i + 1}`
                    : formatMessage({ id: 'text.uplift' })}
                </div>
                {i === uplifts.length - 1 ? (
                  <div className={styles.fuelInput}>
                    {getFieldDecorator(`fuel_logs.[${index}]fuel_uplifts.[${i}]aircraft_actual_uplift`, {
                      rules: [
                        { required: false },
                        { type: 'number' },
                        { pattern: new RegExp(`^[0-9]{1,5}$`), message: formatMessage({ id: 'message.wholeNumbers' }) },
                        { max: max_fuel_value, type: 'number', message: formatMessage({ id: 'message.fuelWarning' }) },
                      ],
                      initialValue: parseInt(item.aircraft_actual_uplift, 10) || 0,
                    })(
                      <InputNumber
                        type="number"
                        placeholder={item.aircraft_actual_uplift || 0}
                        className={styles.dataInput}
                        step={1}
                        max={max_fuel_value}
                      />,
                    )}
                    {` ${unit}`}
                  </div>
                ) : (
                  <div className={styles.data}>{`${item.aircraft_actual_uplift || 0} ${unit}`}</div>
                )}
              </div>
            </Form.Item>
          </Col>
        );
      });
    } else {
      upliftLayout = newUplifts.map((item, i) => {
        return (
          <Col xs={12} md={6} key={`Uplift ${item.id}`}>
            <Form.Item data-testid={`FuelSection -- ${index}Uplift${i}`}>
              <div>
                <div className={styles.dataTitle}>
                  {newUplifts.length > 1
                    ? `${formatMessage({ id: 'text.uplift' })} #${i + 1}`
                    : formatMessage({ id: 'text.uplift' })}
                </div>
                {editable ? (
                  <div className={styles.fuelInput}>
                    {getFieldDecorator(`fuel_logs.[${index}]fuel_uplifts.[${i}]aircraft_actual_uplift`, {
                      rules: [
                        { required: false },
                        { type: 'number' },
                        { pattern: new RegExp(`^[0-9]{1,5}$`), message: formatMessage({ id: 'message.wholeNumbers' }) },
                        { max: max_fuel_value, type: 'number', message: formatMessage({ id: 'message.fuelWarning' }) },
                      ],
                      initialValue: parseInt(item.aircraft_actual_uplift, 10) || 0,
                    })(
                      <InputNumber
                        type="number"
                        placeholder={item.aircraft_actual_uplift || 0}
                        className={styles.dataInput}
                        step={1}
                        max={max_fuel_value}
                      />,
                    )}
                    {` ${unit}`}
                  </div>
                ) : (
                  <div className={styles.data}>{`${item.aircraft_actual_uplift || 0} ${unit}`}</div>
                )}
              </div>
            </Form.Item>
          </Col>
        );
      });
    }
    return upliftLayout;
  };

  const fuelLayout = fuelLogs.map((log, index) => {
    let value = 0;
    log.fuel_uplifts.forEach((uplift) => {
      value += parseInt(uplift.aircraft_actual_uplift, 10) || 0;
    });
    const carriedForwardValue = log.pre_uplift_value + value;

    let buttonsDisplay = (
      <button
        type="button"
        className={styles.toggleUpliftButton}
        onClick={() => handleToggleClick(index)}
        data-testid={`FuelSection -- addButton${index}`}
      >
        {formatMessage({ id: 'form.button.addUplift' })}
      </button>
    );
    if (addUpliftVisible && editable) {
      buttonsDisplay = null;
    } else if (addUpliftVisible && index === activeIndex)
      buttonsDisplay = (
        <>
          <button type="button" className={styles.cancelButton} onClick={() => handleFuelUpliftCancelClick()}>
            {formatMessage({ id: 'form.button.cancel' })}
          </button>
          <Button className={styles.addUpliftButton} onClick={() => handleFuelUpliftSaveClick(index)}>
            {formatMessage({ id: 'form.button.save' })}
          </Button>
        </>
      );
    return (
      <div key={log.fuel_tank_id} data-testid={`FuelSection -- wrapper${index}`}>
        <div className={styles.rowHeaderWrapper}>
          <div className={styles.rowHeader}>
            <span className={styles.title}>{log.fuel_tank_name}</span>
            {buttonsDisplay}
          </div>
        </div>
        <Row className={styles.rowHeaderWrapper}>
          <Col xs={12} md={6}>
            <Form.Item>
              <div data-testid={`FuelSection -- Initial${index}`}>
                <div className={styles.dataTitle}>{formatMessage({ id: 'text.initial' })}</div>
                {editable ? (
                  <div className={styles.fuelInput}>
                    {getFieldDecorator(`fuel_logs.[${index}]pre_uplift_value`, {
                      rules: [
                        { required: false },
                        { type: 'number' },
                        { pattern: new RegExp(`^[0-9]{1,5}$`), message: formatMessage({ id: 'message.wholeNumbers' }) },
                        { max: max_fuel_value, type: 'number', message: formatMessage({ id: 'message.fuelWarning' }) },
                      ],
                      initialValue: log.pre_uplift_value || 0,
                    })(
                      <InputNumber
                        type="number"
                        placeholder={log.pre_uplift_value || 0}
                        className={styles.dataInput}
                        step={1}
                        max={max_fuel_value}
                      />,
                    )}
                    {` ${log.uplift_unit || 'kg'}`}
                  </div>
                ) : (
                  <div className={styles.data}>{`${log.pre_uplift_value || 0} ${log.uplift_unit || 'kg'}`}</div>
                )}
              </div>
            </Form.Item>
          </Col>
          {fuelUpliftLayout(log.fuel_uplifts, log.id, log.uplift_unit, index)}
          <Col xs={12} md={6}>
            <Form.Item>
              <div data-testid={`FuelSection -- Carried${index}`}>
                <div className={styles.dataTitle}>{formatMessage({ id: 'text.carriedForward' })}</div>
                {editable ? (
                  <div className={styles.fuelInput}>
                    {getFieldDecorator(`fuel_logs.[${index}]carried_forwards`, {
                      rules: [
                        { required: false },
                        { type: 'number' },
                        { max: max_fuel_value, type: 'number', message: formatMessage({ id: 'message.fuelWarning' }) },
                        { pattern: new RegExp(`^[0-9]{1,5}$`), message: formatMessage({ id: 'message.wholeNumbers' }) },
                      ],
                      initialValue: log.carried_forwards || 0,
                    })(
                      <InputNumber
                        type="number"
                        placeholder={log.carried_forwards || carriedForwardValue}
                        className={styles.dataInput}
                        step={1}
                        max={max_fuel_value}
                      />,
                    )}
                    {` ${log.uplift_unit || 'kg'}`}
                  </div>
                ) : (
                  <div className={styles.data}>
                    {`${log.carried_forwards || carriedForwardValue} ${log.uplift_unit || 'kg'}`}
                  </div>
                )}
              </div>
            </Form.Item>
          </Col>
        </Row>
        {editable ? (
          <div className={styles.dataTitleJust} data-testid={`FuelSection -- justification${index}`}>
            <div>{formatMessage({ id: 'text.justificationForFuel' })}</div>
            {getFieldDecorator(`fuel_logs.[${index}]justification`, {
              rules: [{ required: false }],
              initialValue: log.justification || '',
            })(<input type="text" placeholder={log.justification} className={styles.textbox} />)}
          </div>
        ) : (
          log.justification && (
            <div className={styles.rowHeaderWrapper}>
              <div className={styles.dataTitleJust}>
                <div>{formatMessage({ id: 'text.justificationForFuel' })}</div>
                <div className={styles.data}>{log.justification}</div>
              </div>
            </div>
          )
        )}
      </div>
    );
  });

  return fuelLayout;
};

FuelSection.propTypes = {
  fuelLogs: PropTypes.array,
  editable: PropTypes.bool,
  form: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
  handleFuelUpliftAdd: PropTypes.func.isRequired,
  onMainPageCancelClick: PropTypes.func.isRequired,
};

FuelSection.defaultProps = {
  fuelLogs: [],
  editable: false,
};

export default injectIntl(FuelSection);
