/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { Button, Collapse, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import DeiceEvent from './DeiceEvent';

const { Panel } = Collapse;

const updateArray = (arr, index, value) => arr.map((x, i) => (index === i ? value : x));

const DeiceEvents = ({ value = [], active, onActive, onChange, formItemLayout, flight }, ref) => {
  const { formatMessage } = useIntl();
  return (
    <Collapse ref={ref} accordion activeKey={active} onChange={onActive} bordered={value.length !== 0}>
      {value.map((deicing, i) => (
        <Panel
          header={
            <span>
              {formatMessage({ id: 'title.deicing' })} {deicing.start_time} {deicing.quantity}l{' '}
              {formatMessage({ id: 'title.type' })} {deicing.fluid_type}
            </span>
          }
          // Deice events do not have unique ids
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          extra={
            <Popconfirm
              title={formatMessage({ id: 'form.question.confirmDeicingDelete' })}
              okText={formatMessage({ id: 'form.button.yes' })}
              cancelText={formatMessage({ id: 'form.button.no' })}
              placement="topRight"
              onClick={(e) => e.stopPropagation()}
              onCancel={(e) => e.stopPropagation()}
              onConfirm={() => onChange(value.filter((x, index) => index !== i))}
            >
              <Button type="danger" size="small">
                {formatMessage({ id: 'form.button.delete' })}
              </Button>
            </Popconfirm>
          }
        >
          <DeiceEvent
            flight={flight}
            formItemLayout={formItemLayout}
            value={deicing}
            onChange={(v) => onChange(updateArray(value, i, v))}
          />
        </Panel>
      ))}
    </Collapse>
  );
};

DeiceEvents.propTypes = {
  value: PropTypes.array,
  active: PropTypes.bool.isRequired,
  onActive: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  flight: PropTypes.object,
  formItemLayout: PropTypes.object.isRequired,
};

DeiceEvents.defaultProps = {
  value: [],
  flight: undefined,
};

// forwardRef necessary for antd form components
export default forwardRef(DeiceEvents);
