import React, { useEffect, useState } from 'react';
import styles from '../workpackDrawer.module.less';
import TFPagination from '../../TFPagination/TFPagination';

const Controls = ({
  saveWorkpack,
  drawerMode,
  status,
  pendingWps,
  activeWPId,
  handlePendingIndexChange,
  removeUnsavedItems,
  acceptanceSig,
  completePendingWorkpack,
}): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);

  const SaveButton = (): JSX.Element => {
    return (
      <div className={styles.buttonWrapper}>
        <button
          type="button"
          className={styles.transparentButton}
          onClick={(): void => {
            removeUnsavedItems();
          }}
        >
          Cancel
        </button>
        <button type="button" className={styles.blueButton} onClick={saveWorkpack}>
          Save
        </button>
      </div>
    );
  };

  const handleIndexChange = (index: number): void => {
    handlePendingIndexChange(index);
  };
  const completeDisabled = acceptanceSig.length === 0;

  useEffect(() => {
    if (activeWPId) {
      const index = pendingWps.findIndex((wp) => wp.id === activeWPId);
      if (index) setActiveIndex(index);
    }
  }, [activeWPId, pendingWps]);

  return (
    <div className={styles.crsAcceptance}>
      {status === 'pending' && (
        <>
          <button
            type="button"
            className={completeDisabled ? `${styles.blueButton} ${styles.disabledButton}` : styles.blueButton}
            disabled={completeDisabled}
            onClick={completePendingWorkpack}
          >
            Complete
          </button>
          <div className={styles.paginationWrapper}>
            <TFPagination passedArray={pendingWps} activeIndex={activeIndex} handleIndexChange={handleIndexChange} />
          </div>
        </>
      )}
      {(drawerMode === 'edit' || drawerMode === 'new') && status !== 'pending' && <SaveButton />}
    </div>
  );
};

export default Controls;
