/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import servers from '../../utils/servers';
import { DefectAttachment } from '../../models/defects';
import ImageCarousel from './carousel';
import MapAttachmentThumbnails from './mapThumbnails';
import FileUploader from './fileUploader';
import styles from './fileViewer.module.less';

declare global {
  interface Window {
    images: HTMLImageElement[];
  }
}

interface DeleteProps {
  fileName: string;
  fileId: string;
}

interface FileViewerProps {
  addAttachment?: (file) => void;
  handleDeleteClick?: (fileName, fieldId) => void;
  allowDelete?: boolean;
  disableAddition?: boolean;
  attachments?: any[];
  uploaderVisible?: boolean;
}

const FileViewer: React.FC<FileViewerProps> = ({
  attachments,
  addAttachment,
  handleDeleteClick,
  allowDelete = true,
  disableAddition = false,
  uploaderVisible = true,
}) => {
  const [showFile, toggleShowFile] = useState({ visible: false, index: 0 });

  const fileRef = useRef<HTMLInputElement>();

  const fileUpload = (): void => {
    addAttachment(fileRef.current.files);
  };
  useEffect(() => {
    // preload images
    // using global context to prevent preloads from being
    // garbage collected on component unmount
    window.images = [];
    Array.isArray(attachments) &&
      attachments.forEach((attachment) => {
        if (attachment?.content_type) {
          const img = new Image();
          img.src = `${servers.api}/${attachment.url}`;
          window.images.push(img);
        } else {
          const img = new Image();
          img.src = attachment.attachment;
          window.images.push(img);
        }
      });
  }, [attachments.length]);

  return (
    <>
      {showFile.visible && (
        <ImageCarousel
          attachments={attachments}
          toggleShowFile={toggleShowFile}
          chosenIndex={showFile.index}
          handleDeleteClick={handleDeleteClick}
          allowDelete={allowDelete}
        />
      )}
      <div className={styles.attachmentWrapper}>
        <MapAttachmentThumbnails attachments={attachments} toggleShowFile={toggleShowFile} />
        {uploaderVisible ? (
          <FileUploader fileRef={fileRef} fileUpload={fileUpload} disableAddition={disableAddition} />
        ) : null}
      </div>
    </>
  );
};

export default FileViewer;
