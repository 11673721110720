import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { FilterSideBar } from '@arcflight/tf-component-library';
import _ from 'lodash';
import Loading from '../../components/TFLoading/index';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import { getAllWorkpacks, removeWorkpack } from '../../models/workpacks/actions';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import { getSingleAircraft } from '../../models/aircraft/actions';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import PageMessage from '../../components/PageMessage';
import TableSearch from '../../components/StandardTable/TableSearch';
import infoIcon from '../../assets/icon-info-white.svg';
import WorkpackDrawer from '../../components/WorkPackDrawer/index';
import filtersIcon from '../../assets/filters.svg';
import TFButton from '../../components/TFButton/TFButton';
import SlidingDrawer from '../../components/SlidingDrawer';
import ExpandedContent from '../../components/TopNavWrapper/ExpandedContent';
import produceFilterModel from './produceFilterModel';
import WorkPacksTable from './WorkpacksTable';
import styles from './Workpacks.module.less';

const mediaQueryList = window.matchMedia('(max-width: 1200px)');
class Workpacks extends PureComponent {
  static propTypes = {
    fetchSingleAircraft: PropTypes.func.isRequired,
    workpacksArray: PropTypes.array.isRequired,
    currentAircraft: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    lastFetched: PropTypes.number,
    getAllWorkpacks: PropTypes.func.isRequired,
    deleteWorkpack: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
  };

  static defaultProps = {
    lastFetched: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        status: [],
        source: [],
      },
      searchValue: '',
      searchVisible: false,
      loading: false,
      showDrawer: false,
      currentWorkpack: false,
      workpacksFilterModel: produceFilterModel(),
      workpackId: false,
      mode: false,
      localWorkpacksArray: [],
      showFilterSidebar: true,
      showFiltersDrawer: false,
    };
  }

  componentDidMount() {
    this.getWorkpacksAndAircraft();
  }

  componentDidUpdate(prevProps) {
    const { lastFetched, workpacksArray } = this.props;
    const { loading } = this.state;

    // if (!isEqual(workpacksArray, prevProps.workpacksArray) && prevProps.workpacksArray.length !== 0) {
    //   this.getWorkpacks();
    // }
    if (loading && (lastFetched !== prevProps.lastFetched || !_.isEqual(workpacksArray, prevProps.workpacksArray))) {
      this.setLoading(false);
    }
  }

  getWorkpacks = () => {
    const { getAllWorkpacks: dispatchGetAllWorkpacks, match, lastFetched, workpacksArray } = this.props;

    if (workpacksArray.length === 0 || Date.now() - lastFetched > 30000) {
      dispatchGetAllWorkpacks({
        aircraft_id: match.params.id,
      });
    }
  };

  async getWorkpacksAndAircraft() {
    const { match, fetchSingleAircraft } = this.props;

    await this.setLoading(true);
    fetchSingleAircraft(match.params.id);
    this.getWorkpacks();
  }

  toggleDrawer = () => {
    const { showDrawer } = this.state;
    this.setState({ showDrawer: !showDrawer });
  };

  toggleWorkpackDrawer = (row, mode) => {
    const {
      original: { id },
    } = row;
    this.setState({ workpackId: id, mode }, () => this.toggleDrawer());
  };

  updateLocalWorkpacksArray = (filteredWorkPacksArray) => {
    this.setState({ localWorkpacksArray: filteredWorkPacksArray });
  };

  setLoading = async (loading) => {
    await new Promise((resolve) => this.setState({ loading }, resolve));
  };

  handleDeleteWorkpack = (id) => {
    const { showDrawer } = this.state;
    const {
      intl: { formatMessage },
      deleteWorkpack,
    } = this.props;
    this.setState({ loading: true });
    Modal.confirm({
      title: formatMessage({ id: 'title.deleteWorkpack' }),
      content: `${formatMessage({ id: 'form.question.areYouSureDeleteWorkpack' })} ${formatMessage({
        id: 'form.labels.cannotBeUndone',
      })}`,
      okText: formatMessage({ id: 'form.button.delete' }),
      cancelText: formatMessage({ id: 'form.button.cancel' }),
      onCancel: () => this.setState({ loading: false }),
      onOk: () => {
        deleteWorkpack(id);
        if (showDrawer) this.toggleDrawer();
      },
    });
  };

  setSearchValue = (value) => {
    this.setState({ searchValue: value, searchFilter: value });
  };

  showSearchInput = () => {
    const { searchVisible, searchValue } = this.state;
    if (searchVisible && searchValue) {
      this.setSearchValue(null);
    }
    this.setState({
      searchVisible: !searchVisible,
    });
  };

  onFilterChange = (filterKey, groupKey) => {
    const { filters } = this.state;
    const newFilters = filters;
    if (filters[groupKey].includes(filterKey)) {
      const index = newFilters[groupKey].indexOf(filterKey);
      newFilters[groupKey].splice(index, 1);
    } else {
      newFilters[groupKey].push(filterKey);
    }
    this.setState((prevState) => ({
      ...prevState,
      filters: { ...filters, newFilters },
    }));
  };

  onFiltersClear = (groupKey) => {
    const { filters } = this.state;
    const clearedFilters = { ...filters };
    clearedFilters[groupKey] = [];
    this.setState({ filters: clearedFilters });
  };

  updateMediaScreenSize = () => {
    this.setState({ showFilterSidebar: !mediaQueryList.matches });
  };

  toggleFiltersDrawer = () => {
    const { showFiltersDrawer } = this.state;
    this.setState({ showFiltersDrawer: !showFiltersDrawer });
  };

  handleAddNewWorkpack = () => {
    this.setState({ showDrawer: true, mode: 'new' });
  };

  updateWorkpackId = (id) => {
    this.setState({ workpackId: id });
  };

  render() {
    const {
      userSettings,
      workpacksArray,
      currentAircraft,
      intl: { formatMessage },
      match,
      menu,
    } = this.props;
    const {
      searchValue,
      searchVisible,
      loading,
      localWorkpacksArray,
      showDrawer,
      workpackId,
      mode,
      showFilterSidebar,
      showFiltersDrawer,
      workpacksFilterModel,
    } = this.state;
    const workpacksToReview = workpacksArray.filter((workpack) => {
      return workpack.status === 'pending';
    });
    this.updateMediaScreenSize();
    matchMedia('(max-width: 1000px)').addListener(this.updateMediaScreenSize);

    let tableStyles = styles.tableWrapper;
    if (menu.collapsed) tableStyles = styles.tableWrapperCollapsedMenu;
    return (
      <InnerMenuLayout>
        <div>
          {showDrawer && (
            <WorkpackDrawer
              toggleDrawer={this.toggleDrawer}
              initialMode={mode}
              wp={workpacksArray}
              pendingWps={workpacksToReview}
              ac={currentAircraft}
              wpId={workpackId}
              handleDeleteWorkpack={this.handleDeleteWorkpack}
              updateWorkpackid={this.updateWorkpackId}
              match={match}
            />
          )}
        </div>
        <ExpandedContent
          displayTitle={formatMessage({ id: 'title.workpacks' })}
          action={
            <Link to={`/aircraft/${match.params.id}/workpacks/add`}>
              <TFButton skinny handleClick={null}>
                + Add a New Workpack
              </TFButton>
            </Link>
          }
        />

        <Card className={styles.workpacksCard}>
          <Loading contain loading={loading && !showDrawer} />
          <div className={styles.pageContentWrapper}>
            <div className={`${styles.tableRowWrapper} ${styles.mainCol}`}>
              {workpacksToReview.length > 0 && (
                <PageMessage
                  type="info"
                  typeIcon={infoIcon}
                  text={formatMessage({ id: 'title.workpacksToReview' }, { workpacks: workpacksToReview.length })}
                  actionText={formatMessage({ id: 'message.startReviewing' })}
                  actionFunction={() =>
                    this.toggleWorkpackDrawer({ original: { id: workpacksToReview[0].id } }, 'view')
                  }
                  data-test="pageMessage"
                />
              )}
              <div className={styles.tableHeader}>
                {formatMessage({ id: 'title.workpacksCount' }, { count: workpacksArray.length })}
                <div className={styles.tableActions}>
                  <TableSearch
                    placeholder={formatMessage({ id: 'form.placeholder.searchMX' })}
                    value={searchValue}
                    visible={searchVisible}
                    onToggle={this.showSearchInput}
                    onChange={(value) => this.setSearchValue(value)}
                  />
                  <button className={styles.filterButton} type="button" onClick={this.toggleFiltersDrawer}>
                    <img src={filtersIcon} alt="Open filters" className={styles.filterIcon} />
                  </button>
                </div>
              </div>
              <div id="workpackTable" className={tableStyles}>
                <WorkPacksTable
                  toggleWorkpackDrawer={this.toggleWorkpackDrawer}
                  data={localWorkpacksArray.length === 0 ? workpacksArray : localWorkpacksArray}
                  userSettings={userSettings}
                  globalFilter={searchValue}
                  match={match}
                  dateFormat={userSettings?.dateFormat}
                  loading={loading}
                  handleDeleteWorkpack={this.handleDeleteWorkpack}
                />
              </div>
            </div>
            <div className={styles.filtersCol}>
              <FilterSideBar
                data={workpacksArray}
                updateArray={this.updateLocalWorkpacksArray}
                filterGroups={workpacksFilterModel}
                onChange={(e) => {
                  if (e.length === 0) {
                    this.setState({ localWorkpacksArray: workpacksArray });
                  }
                }}
              />
            </div>
          </div>
        </Card>
        {showFiltersDrawer && (
          <SlidingDrawer filterDrawer toggleDrawer={this.toggleFiltersDrawer}>
            <div className={styles.drawerHeader}>
              <span>Filters</span>
            </div>
            <FilterSideBar
              data={workpacksArray}
              onChange={this.updateLocalWorkpacksArray}
              updateArray={this.updateLocalWorkpacksArray}
              filterGroups={workpacksFilterModel}
            />
          </SlidingDrawer>
        )}
      </InnerMenuLayout>
    );
  }
}

export default withRouter(
  injectIntl(
    connect(
      ({ workpacks, userSettings, aircraft, menu }, { match }) => ({
        workpacksArray: Array.from(workpacks.workpacksMap.values())
          .filter((pack) => pack.aircraft_id === match.params.id)
          .reduce(
            (array, item) => {
              if (item.status === 'pending') {
                array[0].push(item);
                array[0].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              if (item.status === 'draft') {
                array[1].push(item);
                array[1].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              if (item.status === 'active') {
                array[2].push(item);
                array[2].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              if (item.status === 'complete') {
                array[3].push(item);
                array[3].sort((a, b) => new Date(b.date) - new Date(a.date));
              }
              return array;
            },
            [[], [], [], []],
          )
          .flat(),
        currentAircraft: Array.from(aircraft.aircraftMap.values()).filter(
          (currAircraft) => currAircraft.id === match.params.id,
        ),
        lastFetched: workpacks.lastFetched,
        userSettings,
        menu,
      }),
      (dispatch) => ({
        getAllWorkpacks: (payload) => {
          dispatch(
            getAllWorkpacks({
              payload,
            }),
          );
        },
        deleteWorkpack: (id) => {
          dispatch(
            removeWorkpack({
              payload: id,
            }),
          );
        },
        fetchSingleAircraft: (payload) => {
          dispatch(
            getSingleAircraft({
              payload,
            }),
          );
        },
      }),
    )(Workpacks),
  ),
);
