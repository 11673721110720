import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Icon, Row, Col, Card, Badge, Tooltip, Divider, Spin, Descriptions } from 'antd';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import ReactImageFallback from 'react-image-fallback';
import icon from '../../assets/ic_build_black_24px.svg';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import servers from '../../utils/servers';
import { getDefectUrl } from '../../services/api';
import { getSingleDefect } from '../../models/defects/actions';
import logo from '../../assets/back-arrow.svg';
import DescriptionList from '../../components/DescriptionList';
import SharingLinkModal from '../../components/Modals/SharingLink/SharingLink';
import styles from './DefectProfile.module.less';

const { Description } = DescriptionList;
class DefectProfile extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    defectsMap: PropTypes.instanceOf(Map),
    match: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    defectsMap: new Map(),
  };

  constructor(props) {
    super(props);
    this.state = {
      defectSignature: true,
      defectUrl: '',
      responseUrl: '',
      sharingModalVisible: false,
    };
  }

  async componentDidMount() {
    const defect = this.getDefect();
    const { match } = this.props;
    if (!defect || Date.now() - defect.lastFetched > 30000) {
      this.getDefect(true);
    }
    try {
      const response = await getDefectUrl(match.params.defect);
      this.setState({
        defectUrl: servers.pub + response.url,
        responseUrl: response.url,
      });
    } catch (err) {
      this.setState({
        defectUrl: '',
        responseUrl: '',
      });
    }
  }

  getDefect(forceRefetch = false) {
    const { dispatch, match, defectsMap } = this.props;
    if (forceRefetch) {
      dispatch(
        getSingleDefect({
          payload: { id: match.params.defect },
        }),
      );
    }
    return defectsMap.get(match.params.defect);
  }

  renderDescription = (defect) => {
    const {
      intl: { formatMessage },
      userSettings,
    } = this.props;
    let displayDaysRemainingComponent = <Description term={formatMessage({ id: 'text.daysRemaining' })}>-</Description>;
    if (defect.date_closed !== null) {
      displayDaysRemainingComponent = (
        <Description size="small" term={formatMessage({ id: 'text.dateClosed' })}>
          {moment(defect.date_closed).format(userSettings.dateFormat)}
        </Description>
      );
    } else if (defect.days_remaining) {
      if (defect.days_remaining > 0) {
        displayDaysRemainingComponent = (
          <Description term={formatMessage({ id: 'text.daysRemaining' })}>{defect.days_remaining}</Description>
        );
      } else {
        displayDaysRemainingComponent = (
          <Description term={formatMessage({ id: 'text.overdue' })}>
            {`${Math.abs(defect.days_remaining)} ${
              Math.abs(defect.days_remaining) === 1
                ? formatMessage({ id: 'units.day' })
                : formatMessage({ id: 'units.days' })
            }`}
          </Description>
        );
      }
    }
    return (
      <>
        <DescriptionList size="small" col={2} className={styles.headerDescriptions} layout="horizontal">
          <Description term="Sector">
            {defect.flight.sector !== null ? (
              <Link to={`/operations/flights/${defect.flight_id}`}>
                {defect.flight.sector} (Trip{' '}
                {defect.trip.number ? `#${defect.trip.number}` : formatMessage({ id: 'text.newCaps' })})
              </Link>
            ) : (
              '-'
            )}
          </Description>
          <Description term={formatMessage({ id: 'text.dateOpened' })}>
            {moment(defect.date).format(userSettings.dateFormat)}
          </Description>
          <Description term={formatMessage({ id: 'form.labels.deferred' })}>
            {defect.deferred ? formatMessage({ id: 'form.button.yes' }) : formatMessage({ id: 'form.button.no' })}
          </Description>
          {displayDaysRemainingComponent}
        </DescriptionList>
      </>
    );
  };

  render() {
    const {
      intl: { formatMessage },
      match,
      location,
    } = this.props;
    const status = {
      open: {
        badge: 'processing',
        text: formatMessage({ id: 'status.open' }),
      },
      resolved: {
        badge: 'success',
        text: formatMessage({ id: 'status.resolved' }),
      },
      overdue: {
        badge: 'error',
        text: formatMessage({ id: 'status.overdue' }),
      },
      draft: {
        badge: 'default',
        text: formatMessage({ id: 'status.draft' }),
      },
      error: {
        badge: 'error',
        text: formatMessage({ id: 'status.error' }),
      },
    };
    const { defectSignature, defectUrl, responseUrl, sharingModalVisible } = this.state;
    const defect = this.getDefect();
    const selectedAircraft = match.params.id;
    let displaySignatureComponent = formatMessage({ id: 'text.noSignature' });
    if (defect && defect.signature_image_url) {
      if (defectSignature !== false) {
        displaySignatureComponent = (
          <ReactImageFallback
            src={`${servers.api}/${defect.signature_image_url}`}
            fallbackImage="/images/tf-no-image.png"
            onError={() => {
              this.setState({ defectSignature: false });
            }}
            alt="signature"
            className={styles.defectSignature}
            data-test="signatureImage"
          />
        );
      } else {
        displaySignatureComponent = formatMessage({ id: 'text.unableToLoadImage' });
      }
    }
    let linkDestination = '/defects';
    if (location.state && location.state.fromFlight) {
      linkDestination = `/operations/flights/${location.state.fromFlight}`;
    } else if (selectedAircraft) {
      linkDestination = `/aircraft/${selectedAircraft}/defects/log`;
    }
    return (
      <>
        {defect ? (
          <PageHeaderWrapper
            logo={
              <>
                <Link to={linkDestination} data-test="backLink">
                  <img className={styles.backIcon} alt="back arrow" src={logo} />
                </Link>
                <img alt="spanner" src={icon} />
              </>
            }
            title={
              <span>
                {formatMessage({ id: 'title.defect' })}: {defect.aircraft.registration} #{defect.number}
              </span>
            }
            content={this.renderDescription(defect)}
            extraContent={
              <Row gutter={14}>
                <Col lg={{ span: 8 }}>
                  <div className={styles.textSecondary}>{formatMessage({ id: 'text.statusCaps' })}</div>
                  <div className={styles.heading}>
                    <Badge status={status[defect.status].badge} text={status[defect.status].text} />
                  </div>
                </Col>
                <Col lg={{ span: 8 }}>
                  <div className={styles.textSecondary}>{formatMessage({ id: 'title.sharingLinkCaps' })}</div>
                  <Tooltip
                    title={
                      defectUrl
                        ? formatMessage({ id: 'tooltip.generateLink3rdParty' })
                        : formatMessage({ id: 'message.generateShareLinkFailed' })
                    }
                  >
                    <Icon type="info-circle-o" className={styles.toolTipIcon} />
                  </Tooltip>
                  <Button
                    disabled={!defectUrl}
                    onClick={defectUrl ? () => this.setState({ sharingModalVisible: true }) : false}
                  >
                    {formatMessage({ id: 'form.button.generate' })}
                  </Button>
                </Col>
                <Col lg={{ span: 8 }}>
                  <div className={styles.textSecondary}>{formatMessage({ id: 'title.resolve' })}</div>
                  <Tooltip
                    title={
                      defectUrl
                        ? formatMessage({ id: 'tooltip.resolveDefect' })
                        : formatMessage({ id: 'message.generateShareLinkFailed' })
                    }
                  >
                    <Icon type="info-circle-o" className={styles.toolTipIcon} />
                  </Tooltip>
                  <Button disabled={!responseUrl}>
                    <a href={responseUrl} target="_blank" rel="noopener noreferrer">
                      {defect.status !== 'resolved'
                        ? formatMessage({ id: 'form.button.addCRS' })
                        : formatMessage({ id: 'form.button.viewCRS' })}
                    </a>
                  </Button>
                </Col>
              </Row>
            }
          >
            <SharingLinkModal
              visible={sharingModalVisible}
              handleModalVisible={() => this.setState({ sharingModalVisible: false })}
              url={defectUrl}
            />
            <Card title={formatMessage({ id: 'title.defectReport' })} className={styles.card} bordered={false}>
              <Card type="inner" title={formatMessage({ id: 'title.details' })} className={styles.card}>
                <Descriptions>
                  <Description>{defect.details ? defect.details : formatMessage({ id: 'text.noDetails' })}</Description>
                </Descriptions>
                <Divider className={styles.divider} />
                <Descriptions
                  size="small"
                  className={styles.cardDescriptionList}
                  title={formatMessage({ id: 'title.mel' })}
                >
                  <Description label={formatMessage({ id: 'text.item' })}>
                    <>
                      {defect.mel_item.chapter_number}-{defect.mel_item.section_number}-
                      {defect.mel_item.subsection_number} {defect.mel_item.title}
                    </>
                  </Description>
                  <Description label={formatMessage({ id: 'text.repairCategory' })}>
                    {defect.mel_item.interval}
                  </Description>
                  <Description label={formatMessage({ id: 'text.deferralInterval' })}>
                    {defect.mel_item.interval_days} {formatMessage({ id: 'period.days' })}
                  </Description>
                </Descriptions>
                <Divider className={styles.divider} />
                <Descriptions
                  size="small"
                  className={styles.cardDescriptionList}
                  title={formatMessage({ id: 'title.reportedBy' })}
                >
                  <Description>
                    <div>{formatMessage({ id: 'text.confirmationReportText' })}</div>
                    <br />
                    <div className={styles.canvasWrapper} style={{ pointerEvents: 'none' }}>
                      {displaySignatureComponent}
                    </div>
                  </Description>
                </Descriptions>
              </Card>
              {defect.attachments.length > 0 && (
                <Card type="inner" title={formatMessage({ id: 'title.attachments' })}>
                  <Row>
                    {defect.attachments.map((item) => (
                      <Col lg={6} md={12} sm={24}>
                        <Card
                          key={item}
                          hoverable
                          className={styles.attachmentCard}
                          cover={
                            item.url ? (
                              <a href={`${servers.api}/${item.url}`} rel="noopener noreferrer" target="_blank">
                                <img alt="defect" src={`${servers.api}/${item.thumbnail_url}`} />
                              </a>
                            ) : (
                              <p>{formatMessage({ id: 'text.attachmentProblem' })}.</p>
                            )
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Card>
              )}
            </Card>
          </PageHeaderWrapper>
        ) : (
          <Spin className={styles.spin} indicator={<Icon type="loading" className={styles.spinIcon} spin />} />
        )}
      </>
    );
  }
}

const defectProfileWithRedux = connect(({ defects, userSettings }) => ({
  defectsMap: defects.defectsMap,
  userSettings,
}))(DefectProfile);
export default withRouter(injectIntl(defectProfileWithRedux));
