const initialState: OperationsState = {
  aircraftUsageData: {
    current: {
      aircraftFlights: [],
      aircraftHours: [],
    },
    prevMonth: {
      aircraftFlights: [],
      aircraftHours: [],
    },
    prevYear: {
      aircraftFlights: [],
      aircraftHours: [],
    },
  },
  airports: [],
  monthly: [],
  oilConsumption: [],
  routes: [],
};

export enum OperationsActionTypes {
  CLEAR_MONTHLY = 'operations/clearMonthly',
  CLEAR_ROUTES = 'operations/clearRoutes',
  UPDATE_OIL_CONSUMPTION = 'operations/saveOilConsumption',
  UPDATE_MONTHLY_SUMMARY = 'operations/saveMonthlySummary',
  UPDATE_ROUTES = 'operations/saveRoutes',
  UPDATE_TOP_AIRCRAFT_YEAR = 'operations/saveTopAircraftYear',
  UPDATE_TOP_AIRCRAFT_MONTH = 'operations/saveTopAircraftMonth',
  UPDATE_TOP_AIRCRAFT_CURRENT = 'operations/saveTopAircraftCurrent',
  UPDATE_TOP_AIRPORTS = 'operations/saveTopAirports',
}

export interface ClearMonthlyOperationsActionType {
  type: OperationsActionTypes.CLEAR_MONTHLY;
}

export interface ClearRoutesOperationsActionType {
  type: OperationsActionTypes.CLEAR_ROUTES;
}

export interface UpdateMonthlySummaryOperationsActionType {
  type: OperationsActionTypes.UPDATE_MONTHLY_SUMMARY;
  payload: OperationsReportCoordinate[];
}

export interface UpdateOilConsumptionActionType {
  type: OperationsActionTypes.UPDATE_OIL_CONSUMPTION;
  payload: OilConsumption[];
}

export interface UpdateRoutesOperationsActionType {
  type: OperationsActionTypes.UPDATE_ROUTES;
  payload: FlightRoute[];
}

export interface UpdateTopAircraftYearOperationsActionType {
  type: OperationsActionTypes.UPDATE_TOP_AIRCRAFT_YEAR;
  payload: AircraftUsageData;
}

export interface UpdateTopAircraftMonthOperationsActionType {
  type: OperationsActionTypes.UPDATE_TOP_AIRCRAFT_MONTH;
  payload: AircraftUsageData;
}

export interface UpdateTopAircraftCurrentOperationsActionType {
  type: OperationsActionTypes.UPDATE_TOP_AIRCRAFT_CURRENT;
  payload: AircraftUsageData;
}

export interface UpdateTopAirportsOperationsActionType {
  type: OperationsActionTypes.UPDATE_TOP_AIRPORTS;
  payload: OperationsReportCoordinate[];
}

type OperationsAction =
  | ClearMonthlyOperationsActionType
  | ClearRoutesOperationsActionType
  | UpdateMonthlySummaryOperationsActionType
  | UpdateRoutesOperationsActionType
  | UpdateTopAircraftYearOperationsActionType
  | UpdateTopAircraftMonthOperationsActionType
  | UpdateTopAircraftCurrentOperationsActionType
  | UpdateOilConsumptionActionType
  | UpdateTopAirportsOperationsActionType;

export default function(state = initialState, action: OperationsAction): OperationsState {
  switch (action.type) {
    case OperationsActionTypes.CLEAR_MONTHLY: {
      return {
        ...state,
        monthly: [],
      };
    }
    case OperationsActionTypes.CLEAR_ROUTES: {
      return {
        ...state,
        routes: [],
      };
    }
    case OperationsActionTypes.UPDATE_MONTHLY_SUMMARY:
      return {
        ...state,
        ...action.payload,
      };
    case OperationsActionTypes.UPDATE_ROUTES:
      return {
        ...state,
        ...action.payload,
      };
    case OperationsActionTypes.UPDATE_TOP_AIRCRAFT_YEAR:
      return {
        ...state,
        aircraftUsageData: {
          ...state.aircraftUsageData,
          ...action.payload,
        },
      };
    case OperationsActionTypes.UPDATE_TOP_AIRCRAFT_MONTH:
      return {
        ...state,
        aircraftUsageData: {
          ...state.aircraftUsageData,
          ...action.payload,
        },
      };
    case OperationsActionTypes.UPDATE_TOP_AIRCRAFT_CURRENT:
      return {
        ...state,
        aircraftUsageData: {
          ...state.aircraftUsageData,
          ...action.payload,
        },
      };
    case OperationsActionTypes.UPDATE_OIL_CONSUMPTION:
      return {
        ...state,
        ...action.payload,
      };
    case OperationsActionTypes.UPDATE_TOP_AIRPORTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export interface OperationsReportCoordinate {
  x: string;
  y: number;
}

export interface Location {
  latitude: string;
  longitude: string;
  ident: string;
  name: string;
}

export interface OilConsumption {
  date: string;
  engine_oil_consumption_threshold: number;
  apu_oil_consumption_threshold: number;
  data: Array<any>;
}

export interface FlightRoute {
  departure: Location;
  arrival: Location;
  startLat: number;
  startLng: number;
  endLat: number;
  endLong: number;
}

export interface AircraftUsage {
  aircraftFlights: OperationsReportCoordinate[];
  aircraftHours: OperationsReportCoordinate[];
}
export interface AircraftUsageData {
  current: AircraftUsage;
  prevMonth: AircraftUsage;
  prevYear: AircraftUsage;
}

export interface OperationsState {
  aircraftUsageData: AircraftUsageData;
  airports: OperationsReportCoordinate[];
  monthly: OperationsReportCoordinate[];
  routes: FlightRoute[];
  oilConsumption: OilConsumption[];
}
