/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, InputNumber, Select, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import defaults from '../../../utils/defaults';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import { formatTime } from '../../../utils/utils';
import ata from '../../../utils/ata';
import globalStyles from '../../../utils/globalStyles.module.less';
import styles from './MXModal.module.less';

const { Option } = Select;
const { TextArea } = Input;

const MXModalOOPContents = ({ form: { getFieldDecorator }, intl: { formatMessage }, userSettings, aircraft, item }) => {
  const dateFormat = userSettings ? userSettings.dateFormat : defaults.defaultDateFormat;
  const hasAPU = aircraft ? aircraft.apu_installed : false;
  const [unitOfTime, setUnitOfTime] = useState('days');
  useEffect(() => {
    if (item && item.unit_of_time) {
      setUnitOfTime(item.unit_of_time);
    }
  }, [item]);

  const activeLimitValue = ({ monthsValue, daysValue }) => {
    if (monthsValue && Number(monthsValue) && Number(monthsValue) > 0) {
      return monthsValue;
    }

    if (daysValue && Number(daysValue) && Number(daysValue) > 0) {
      return daysValue;
    }

    return null;
  };

  return (
    <>
      <Form.Item
        label={formatMessage({ id: 'form.labels.ataChapter' })}
        className={globalStyles.formItem}
        data-test="formItemATA"
      >
        {getFieldDecorator('ata_number', {
          rules: [{ required: true, message: formatMessage({ id: 'form.message.selectATA' }) }],
          initialValue: item ? item.ata_number : undefined,
        })(
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            placeholder={formatMessage({ id: 'form.placeholder.searchATA' })}
            className={`${globalStyles.formSelect} ${styles.selectFull}`}
            data-test="formSelectATA"
          >
            {ata.map((itemATA) => {
              return (
                <Option key={itemATA.key} value={itemATA.key}>
                  {itemATA.nameFull}
                </Option>
              );
            })}
          </Select>,
        )}
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'form.labels.taskDescription' })}
        className={globalStyles.formItem}
        data-test="formItemName"
      >
        {getFieldDecorator('name', {
          rules: [{ required: true, message: formatMessage({ id: 'form.message.addItemName' }) }],
          initialValue: item ? item.name : null,
        })(
          <Input
            className={globalStyles.formInput}
            placeholder={formatMessage({ id: 'form.placeholder.itemName' })}
            data-test="formInputName"
          />,
        )}
      </Form.Item>
      <Form.Item
        label={ReactHtmlParser(
          `${formatMessage({ id: 'form.labels.notes' })} <span>${formatMessage({
            id: 'form.labels.optional',
          })}</span>`,
        )}
        className={globalStyles.formItem}
        data-test="formItemNotes"
      >
        {getFieldDecorator('repeat_remarks', {
          rules: [{ required: false }],
          initialValue: item ? item.repeat_remarks : null,
        })(
          <TextArea
            className={globalStyles.formTextArea}
            spellCheck="false"
            rows={2}
            placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
            data-test="formTextAreaNotes"
          />,
        )}
      </Form.Item>
      <Form.Item
        label={ReactHtmlParser(
          `${formatMessage({ id: 'form.labels.taskRef' })} <span>${formatMessage({
            id: 'form.labels.optional',
          })}</span>`,
        )}
        className={globalStyles.formItem}
        data-test="formItemTaskRef"
      >
        {getFieldDecorator('reference', {
          rules: [{ required: false }],
          initialValue: item ? item.reference : null,
        })(
          <Input
            className={globalStyles.formInput}
            placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
            data-test="formInputTaskRef"
          />,
        )}
      </Form.Item>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleFH">
            <span>{formatMessage({ id: 'form.title.addFlightHours' })}</span>
          </div>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.threshold' })}
            className={globalStyles.formItem}
            data-test="formItemFHThreshold"
          >
            {getFieldDecorator('flight_seconds_threshold', {
              rules: [{ required: false }],
              initialValue: item && item.flight_seconds_threshold ? formatTime(item.flight_seconds_threshold) : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputFHThreshold"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.interval' })}
            className={globalStyles.formItem}
            data-test="formItemFHInterval"
          >
            {getFieldDecorator('flight_seconds_repeat', {
              rules: [{ required: false }],
              initialValue: item && item.flight_seconds_repeat ? formatTime(item.flight_seconds_repeat) : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputFHInterval"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemFHTolerance"
          >
            {getFieldDecorator('flight_seconds_tolerance', {
              rules: [{ required: false }],
              initialValue:
                item && item.flight_seconds_tolerance ? Math.round(item.flight_seconds_tolerance / 3600) : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputFHTolerance"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.nextDue' })}
            className={globalStyles.formItem}
            data-test="formItemFHNextDue"
          >
            {getFieldDecorator('flight_seconds_due', {
              rules: [{ required: false }],
              initialValue: item && item.flight_seconds_due ? formatTime(item.flight_seconds_due) : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputFHNextDue"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleCycles">
            <span>{formatMessage({ id: 'form.title.addCycles' })}</span>
          </div>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.threshold' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesThreshold"
          >
            {getFieldDecorator('cycles_threshold', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_threshold : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputCyclesThreshold"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.interval' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesInterval"
          >
            {getFieldDecorator('cycles_repeat', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_repeat : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputCyclesInterval"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesTolerance"
          >
            {getFieldDecorator('cycles_tolerance', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_tolerance : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputCyclesTolerance"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.nextDue' })}
            className={globalStyles.formItem}
            data-test="formItemCyclesNextDue"
          >
            {getFieldDecorator('cycles_due', {
              rules: [{ required: false }],
              initialValue: item ? item.cycles_due : null,
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputCyclesNextDue"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-test="sectionTitleMonths">
            <span>{formatMessage({ id: 'form.title.addMonths' })}</span>
          </div>
        </Col>
        <Col md={24}>
          <Form.Item>
            {getFieldDecorator('unit_of_time', {
              rules: [{ required: false }],
              initialValue: unitOfTime,
            })(
              <>
                <div className={styles.dateLabel}>Limit Type</div>
                <RadioInputButton
                  text="Days"
                  active={unitOfTime === 'days'}
                  onClick={() => setUnitOfTime('days')}
                  marginRight="8px"
                />
                <RadioInputButton
                  text="Months"
                  active={unitOfTime === 'months'}
                  onClick={() => setUnitOfTime('months')}
                />
              </>,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.threshold' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsThreshold"
          >
            {getFieldDecorator('months_threshold', {
              rules: [{ required: false }],
              initialValue: activeLimitValue({ monthsValue: item?.months_threshold, daysValue: item?.days_threshold }),
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputMonthsThreshold"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.interval' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsInterval"
          >
            {getFieldDecorator('months_repeat', {
              rules: [{ required: false }],
              initialValue: activeLimitValue({ monthsValue: item?.months_repeat, daysValue: item?.days_repeat }),
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputMonthsInterval"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.tolerance' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsTolerance"
          >
            {getFieldDecorator('months_tolerance', {
              rules: [{ required: false }],
              initialValue: activeLimitValue({ monthsValue: item?.months_tolerance, daysValue: item?.days_tolerance }),
            })(
              <InputNumber
                min={1}
                className={globalStyles.formInputNumber}
                placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                data-test="formInputMonthsTolerance"
              />,
            )}
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label={formatMessage({ id: 'form.labels.nextDue' })}
            className={globalStyles.formItem}
            data-test="formItemMonthsNextDue"
          >
            {getFieldDecorator('date_due', {
              rules: [{ required: false }],
              initialValue: item && item.date_due ? moment(item.date_due) : null,
            })(
              <DatePicker
                className={globalStyles.formDatePicker}
                format={dateFormat}
                data-test="formInputMonthsNextDue"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      {hasAPU && (
        <Row gutter={16}>
          <Col md={24}>
            <div className={styles.timesTitle} data-test="sectionTitleAPU">
              <span>{formatMessage({ id: 'form.title.addAPUHours' })}</span>
            </div>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.threshold' })}
              className={globalStyles.formItem}
              data-test="formItemAPUThreshold"
            >
              {getFieldDecorator('apu_seconds_threshold', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_threshold ? formatTime(item.apu_seconds_threshold) : null,
              })(
                <InputNumber
                  min={1}
                  className={globalStyles.formInputNumber}
                  placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                  data-test="formInputAPUThreshold"
                />,
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.interval' })}
              className={globalStyles.formItem}
              data-test="formItemAPUInterval"
            >
              {getFieldDecorator('apu_seconds_repeat', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_repeat ? formatTime(item.apu_seconds_repeat) : null,
              })(
                <InputNumber
                  min={1}
                  className={globalStyles.formInputNumber}
                  placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                  data-test="formInputAPUInterval"
                />,
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.tolerance' })}
              className={globalStyles.formItem}
              data-test="formItemAPUTolerance"
            >
              {getFieldDecorator('apu_seconds_tolerance', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_tolerance ? Math.round(item.apu_seconds_tolerance / 3600) : null,
              })(
                <InputNumber
                  min={1}
                  className={globalStyles.formInputNumber}
                  placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                  data-test="formInputAPUTolerance"
                />,
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={formatMessage({ id: 'form.labels.nextDue' })}
              className={globalStyles.formItem}
              data-test="formItemAPUNextDue"
            >
              {getFieldDecorator('apu_seconds_due', {
                rules: [{ required: false }],
                initialValue: item && item.apu_seconds_due ? formatTime(item.apu_seconds_due) : null,
              })(
                <InputNumber
                  min={1}
                  className={globalStyles.formInputNumber}
                  placeholder={formatMessage({ id: 'form.placeholder.xxxx' })}
                  data-test="formInputAPUNextDue"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

MXModalOOPContents.propTypes = {
  item: PropTypes.object,
  aircraft: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  userSettings: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
};

MXModalOOPContents.defaultProps = {
  item: null,
};

export default injectIntl(MXModalOOPContents);
