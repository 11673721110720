import React, { PureComponent } from 'react';
import { Card, Row, Col, Tag, Badge, Menu, Dropdown, Icon } from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import moment from 'moment';
import Loading from '../../components/TFLoading';
import { getUserDetails } from '../../services/api';
import isScreenMobile from '../../utils/isScreenMobile';
import {
  fetchMonthlySummary,
  fetchTopAircraft,
  fetchTopAirports,
  fetchOilConsumption,
} from '../../models/operations/actions';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import OilConsumption from '../../components/Graphs/OilConsumption';
import Hourly from '../../components/Graphs/Hourly';
import Totals from '../../components/Graphs/Totals';
import FuelDelta from '../../components/Graphs/FuelDelta';
import MostVisitedAirports from '../../components/Graphs/MostVisitedAirports';
import FlightsHours from '../../components/Graphs/FlightsHours';
import GraphRadioButton from '../../components/Graphs/GraphRadioButton';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import ExpandedContent from '../../components/TopNavWrapper/ExpandedContent';
import styles from './Metrics.module.less';

const cookies = new Cookies();

class Metrics extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    operations: PropTypes.object.isRequired,
    metrics: PropTypes.object,
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    metrics: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      oilGraphSelection: 'Engine Oil',
      overdueGraphSelection: 'Hourly',
      oilAlert: 0,
      days: '365',
      daysList: [3, 10, 30, 60, 90, 120, 365],
      monthlyLoading: false,
      oilLoading: false,
      isNoOilData: true,
      isNoOilDataForRange: true,
      dateFormat: 'YYYY-MM-DD',
      isMobile: false,
    };
  }

  componentDidMount() {
    this.getMonthlySummary();
    this.getTopAirports();
    this.getTopAircraft();
    this.getOilConsumption('initial');
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      metrics,
      operations: { oilConsumption },
    } = this.props;
    const { dateFormat, days } = this.state;
    if (metrics !== prevProps.metrics) {
      this.getMonthlySummary();
      this.getTopAirports();
      this.getTopAircraft();
      this.getOilConsumption();
    }
    if (oilConsumption !== prevProps.operations.oilConsumption) {
      this.checkForNoData();
    }
    if (days !== prevState.days) {
      this.getOilConsumption();
    }
    if (dateFormat !== prevState.dateFormat) {
      this.getMonthlySummary();
      this.getOilConsumption();
    }
  }

  async getMonthlySummary() {
    const { dispatch, match } = this.props;
    const { dateFormat } = this.state;
    await new Promise((resolve) => this.setState({ monthlyLoading: true }, resolve));
    await dispatch(
      fetchMonthlySummary({
        payload: {
          aircraft: [match.params.id],
          organisation_id: cookies.get('org'),
          dateFormat,
        },
      }),
    );
    this.setState({ monthlyLoading: false });
  }

  getUserDetailsFromRedux = async () => {
    const userDetails = await getUserDetails();
    const { date_format: dateFormat } = userDetails;
    this.setState({ dateFormat });
  };

  async getOilConsumption(initial) {
    const { dispatch, match } = this.props;
    const { days, dateFormat } = this.state;
    await new Promise((resolve) => this.setState({ oilLoading: true }, resolve));
    await dispatch(
      fetchOilConsumption({
        payload: {
          aircraft: [match.params.id],
          organisation_id: cookies.get('org'),
          range: days,
          dateFormat,
        },
      }),
    );
    if (initial) {
      this.setState({ days: '30' });
    } else {
      this.setState({ oilLoading: false });
    }
  }

  getTopAirports() {
    const { dispatch, match } = this.props;
    dispatch(
      fetchTopAirports({
        payload: {
          from: moment('1900-01-01').format('YYYY-MM-DD'),
          to: moment(new Date()).format('YYYY-MM-DD'),
          aircraft_id: match.params.id,
        },
      }),
    );
  }

  getTopAircraft() {
    const { dispatch, match } = this.props;
    dispatch(
      fetchTopAircraft({
        payload: {
          from: moment(new Date())
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
          to: moment(new Date()).format('YYYY-MM-DD'),
          aircraft_id: match.params.id,
          date: 'now',
        },
      }),
    );
    dispatch(
      fetchTopAircraft({
        payload: {
          from: moment(new Date())
            .subtract(60, 'days')
            .format('YYYY-MM-DD'),
          to: moment(new Date())
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
          aircraft_id: match.params.id,
          date: 'month',
        },
      }),
    );
    dispatch(
      fetchTopAircraft({
        payload: {
          from: moment(new Date())
            .subtract(395, 'days')
            .format('YYYY-MM-DD'),
          to: moment(new Date())
            .subtract(365, 'days')
            .format('YYYY-MM-DD'),
          aircraft_id: match.params.id,
          date: 'year',
        },
      }),
    );
  }

  updateMediaScreenSize = () => {
    this.setState({ isMobile: isScreenMobile('769px') });
  };

  checkForNoData = () => {
    const {
      operations: { oilConsumption },
    } = this.props;
    const { days } = this.state;
    const data = oilConsumption && oilConsumption.data;
    let count = 0;
    if (data) {
      data.forEach((entry) => {
        const values = Object.values(entry);
        values
          .filter((value) => typeof value === 'number')
          .forEach((item) => {
            if (item) {
              count += 1;
            }
          });
      });
      if (days === '365') {
        if (count !== 0) {
          this.setState({ isNoOilData: false, isNoOilDataForRange: false });
        } else {
          this.setState({ isNoOilData: true, isNoOilDataForRange: true });
        }
      } else if (days !== '365') {
        if (count !== 0) {
          this.setState({ isNoOilDataForRange: false });
        } else {
          this.setState({ isNoOilDataForRange: true });
        }
      }
    }
  };

  handleOilRadioSelection = (selection) => {
    this.setState({ oilGraphSelection: selection });
  };

  handleFuelUsageRadioSelection = (selection) => {
    this.setState({ overdueGraphSelection: selection });
  };

  fuelUsageGraphDisplaying = () => {
    const {
      operations: { monthly },
      intl: { formatMessage },
    } = this.props;
    const { overdueGraphSelection, isMobile } = this.state;
    switch (overdueGraphSelection) {
      case 'Hourly':
        return <Hourly monthly={monthly} formatMessage={formatMessage} isMobile={isMobile} />;
      case 'Totals':
        return <Totals monthly={monthly} formatMessage={formatMessage} isMobile={isMobile} />;
      case 'Fuel Delta':
        return <FuelDelta monthly={monthly} formatMessage={formatMessage} />;
      default:
        return <Hourly monthly={monthly} formatMessage={formatMessage} isMobile={isMobile} />;
    }
  };

  checkForAlerts = (count) => {
    this.setState({ oilAlert: count });
  };

  onMenuClick = (daysObject) => {
    this.setState({ days: daysObject.key });
  };

  getDateMenu = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { daysList } = this.state;
    return (
      <Menu className={styles.dateMenu} onClick={(daysObject) => this.onMenuClick(daysObject)}>
        {daysList.map((day) => (
          <Menu.Item key={day}>{formatMessage({ id: 'text.lastXDays' }, { days: day })}</Menu.Item>
        ))}
      </Menu>
    );
  };

  render() {
    const {
      oilGraphSelection,
      overdueGraphSelection,
      oilAlert,
      days,
      oilLoading,
      monthlyLoading,
      isNoOilData,
      isNoOilDataForRange,
      isMobile,
    } = this.state;
    const {
      operations: { aircraftUsageData, airports, oilConsumption },
      intl: { formatMessage },
    } = this.props;
    const oilData = oilConsumption !== undefined ? oilConsumption : { data: [{}] };
    const displayWarning = oilAlert ? (
      <>
        <Badge color="red" />
        <span>{formatMessage({ id: 'text.oilConsumption' })}</span>
      </>
    ) : (
      formatMessage({ id: 'text.oilConsumption' })
    );

    this.updateMediaScreenSize();

    matchMedia('(max-width: 769px)').addListener(this.updateMediaScreenSize);

    const lottiePos = window.innerWidth < 1600 ? 50 : 100;

    return (
      <InnerMenuLayout>
        <ExpandedContent displayTitle="Metrics" />
        <div stlye={{ position: 'relative' }}>
          <Loading loading={oilLoading || monthlyLoading} contain topPosition={lottiePos} />
        </div>
        <Row gutter={[24, 24]}>
          <Col md={24} lg={24} xl={24} xxl={12}>
            <Card
              bordered={false}
              data-testid="Metrics--FuelUsageCard"
              title={formatMessage({ id: 'title.fuelUsage' })}
              bodyStyle={{ minHeight: '530px' }}
              headStyle={{ fontSize: '14px', fontWeight: 500, letterSpacing: '0.1px', color: '#868686' }}
            >
              <div className={styles.buttonsDiv} data-testid="Metrics--RadioButtonDiv">
                <GraphRadioButton
                  buttons={['Hourly', 'Totals', 'Fuel Delta']}
                  handleRadioSelection={this.handleFuelUsageRadioSelection}
                  initialSelection={overdueGraphSelection}
                />
              </div>
              {this.fuelUsageGraphDisplaying()}
            </Card>
          </Col>
          <Col md={24} lg={24} xl={24} xxl={12}>
            <Card
              bordered={false}
              title={displayWarning}
              extra={
                oilAlert ? (
                  <Tag color="red">
                    <Badge color="red" />
                    {`${oilAlert} ${oilAlert === 1 ? 'Alert' : 'Alerts'}`}
                  </Tag>
                ) : null
              }
              bodyStyle={{ minHeight: '530px' }}
              headStyle={{ fontSize: '14px', fontWeight: 500, letterSpacing: '0.1px', color: '#868686' }}
            >
              <div className={styles.buttonsDiv} data-testid="Metrics--OilRadioButtons">
                <GraphRadioButton
                  buttons={['Engine Oil']}
                  handleRadioSelection={this.handleOilRadioSelection}
                  initialSelection={oilGraphSelection}
                />
                <div className={isNoOilData ? styles.hideDropdown : styles.dropdown} data-testid="Metrics--OilDropDown">
                  <span>{formatMessage({ id: 'text.showing' })}: </span>
                  <Dropdown
                    overlay={this.getDateMenu()}
                    trigger={['click']}
                    placement={window.innerWidth <= 991 ? 'bottomLeft' : 'bottomCenter'}
                    data-test="daysDropdown"
                  >
                    <span href="#" className={styles.dropdownLink}>
                      <span>{formatMessage({ id: 'text.lastXDays' }, { days })}</span> <Icon type="caret-down" />
                    </span>
                  </Dropdown>
                </div>
              </div>
              <OilConsumption
                oilData={oilData}
                checkForAlerts={this.checkForAlerts}
                formatMessage={formatMessage}
                isNoOilData={isNoOilData}
                isNoOilDataForRange={isNoOilDataForRange}
                isMobile={isMobile}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col md={24} lg={12} xl={10}>
            <Card
              bordered={false}
              title={formatMessage({ id: 'title.hours&flights' })}
              bodyStyle={{ minHeight: '300px' }}
              headStyle={{ fontSize: '14px', fontWeight: 500, letterSpacing: '0.1px', color: '#6f6f6f' }}
            >
              <FlightsHours aircraftUsageData={aircraftUsageData} formatMessage={formatMessage} />
            </Card>
          </Col>
          <Col md={24} lg={12} xl={14}>
            <Card
              bordered={false}
              title={formatMessage({ id: 'title.mostVisitedAirports' })}
              bodyStyle={{ minHeight: '300px' }}
              headStyle={{ fontSize: '14px', fontWeight: 500, letterSpacing: '0.1px', color: '#6f6f6f' }}
            >
              <MostVisitedAirports airports={airports} formatMessage={formatMessage} />
            </Card>
          </Col>
        </Row>
      </InnerMenuLayout>
    );
  }
}

const metricsWithRedux = connect(({ operations, userSettings }) => ({
  operations,
  userSettings,
}))(Metrics);
export default withRouter(injectIntl(metricsWithRedux));
