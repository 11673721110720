/* eslint-disable react/jsx-props-no-spreading */
import { Form } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import InputNumberUnits from '../InputNumberUnits';

class FuelUplift extends React.PureComponent {
  static propTypes = {
    aircraft: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    formItemLayout: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  render() {
    const {
      aircraft,
      form: { getFieldDecorator },
      formItemLayout,
      intl: { formatMessage },
    } = this.props;

    const { labelCol, wrapperCol } = formItemLayout;

    return (
      <>
        <Form.Item
          wrapperCol={wrapperCol}
          labelCol={labelCol}
          label={formatMessage({ id: 'form.labels.plannedUplift' })}
        >
          {getFieldDecorator('aircraft_planned_uplift')(
            <InputNumberUnits
              min={0}
              max={aircraft.max_fuel_value || undefined}
              step={0.1}
              units={aircraft.fuel_unit}
            />,
          )}
        </Form.Item>
        <Form.Item
          wrapperCol={wrapperCol}
          labelCol={labelCol}
          label={formatMessage({ id: 'form.labels.actualUplift' })}
        >
          {getFieldDecorator('aircraft_actual_uplift')(
            <InputNumberUnits
              min={0}
              max={aircraft.max_fuel_value || undefined}
              step={0.1}
              units={aircraft.fuel_unit}
            />,
          )}
        </Form.Item>
      </>
    );
  }
}

export default compose(
  injectIntl,
  Form.create({
    onValuesChange(props, _, allValues) {
      if (props.onChange) {
        props.onChange({ ...props.value, ...allValues });
      }
    },
    mapPropsToFields({ value }) {
      return ['aircraft_planned_uplift', 'aircraft_actual_uplift'].reduce((acc, x) => {
        acc[x] = Form.createFormField({ value: value[x] });
        return acc;
      }, {});
    },
  }),
)(FuelUplift);
