/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import chevron from '../../assets/right-chevron-grey.svg';
import Message from '../Message/Message';
import TFSelect from '../TFSelect/TFSelect';
import PaginationDropdown from './PaginationDropdown';

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px 0 0;
  border-top: 1px solid rgba(36, 45, 65, 0.05);
  margin-top: 20px;
  margin-bottom: 30px;
  @media (max-width: 950px) {
    justify-content: flex-start;
  }
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ItemsSpan = styled.span`
  margin-right: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  @media (max-width: 400px) {
    position: relative;
    padding: 5px;
    margin-left: 30px;
  }
`;

const PageNumber = styled.div`
  color: #242d41;
  font-size: 13px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
`;

const ChevronWrapper = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid rgba(36, 45, 65, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChevronLeft = styled.img`
  width: 6px;
  margin-left: 2px;
  transform: rotate(180deg);
  margin-left: -2px;
`;

const ChevronRight = styled.img`
  width: 6px;
  margin-left: 2px;
`;

const StyledButton = styled.button`
  cursor: ${({ disableLeft, disableRight }): string => (disableLeft || disableRight ? 'default' : 'pointer')};
  opacity: ${({ disableLeft, disableRight }): string => (disableLeft || disableRight ? '0.3' : '1')};
  border: none;
  background-color: transparent;
`;

interface DefectPaginationProps {
  arrayLength: number;
  index: number;
  pageSize: number;
  handlePageChange: (input: string) => void;
  handlePageSizeChange: (input: string) => void;
}

const DefectPagination: React.FC<DefectPaginationProps> = ({
  arrayLength,
  index,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [options, setOptions] = useState([]);

  const handleLeftClick = (): void => {
    handlePageChange('left');
  };

  const handleRightClick = (): void => {
    handlePageChange('right');
  };

  const handleSizeChange = (value): void => {
    handlePageSizeChange(value);
    setOptions([]);
  };

  const disableLeft = index === 1;
  const disableRight = index === totalPages;

  useEffect(() => {
    if (arrayLength && pageSize) {
      const pages = Math.ceil(arrayLength / pageSize);
      const newOptions = [];
      setTotalPages(pages);
      for (let i = 1; i <= pages; i += 1) {
        const item = { title: i };
        newOptions.push(item);
      }
      setOptions(newOptions);
    }
  }, [arrayLength, pageSize, index]);

  useEffect(() => {
    if (index) {
      setPageNumber(index);
    }
  }, [index]);

  return (
    <PaginationWrapper>
      <ItemsSpan>Items per page:</ItemsSpan>
      <PaginationDropdown handleChange={handleSizeChange} initial={{ title: pageSize.toString() }} />
      <ButtonWrapper>
        <StyledButton type="button" disabled={disableLeft} onClick={handleLeftClick} disableLeft={disableLeft}>
          <ChevronWrapper>
            <ChevronLeft src={chevron} alt="chevron" />
          </ChevronWrapper>
        </StyledButton>
        <PageNumber>
          <TFSelect
            options={options}
            initial={{ title: index.toString() }}
            width={60}
            height={24}
            handleSelectChange={(option): void => {
              handlePageChange(option.title);
            }}
            borderRadius="16px"
          />
          <span> of {totalPages}</span>
        </PageNumber>
        <StyledButton type="button" disabled={disableRight} onClick={handleRightClick} disableRight={disableRight}>
          <ChevronWrapper>
            <ChevronRight src={chevron} alt="chevron" />
          </ChevronWrapper>
        </StyledButton>
      </ButtonWrapper>
      <Message text={errorMessage} />
    </PaginationWrapper>
  );
};

export default DefectPagination;
