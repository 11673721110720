import moment from 'moment';

// Returns time difference in seconds
// eslint-disable-next-line import/prefer-default-export
export function timeDifference(before, after) {
  if (!before || !after) {
    return NaN;
  }
  const beforeMoment = moment.utc(before);
  const afterMoment = moment.utc(after);
  let diff = moment({ hour: afterMoment.hour(), minute: afterMoment.minute() }).diff(
    moment({ hour: beforeMoment.hour(), minute: beforeMoment.minute() }),
    'second',
  );
  if (diff < 0) {
    diff += 86400;
  }
  return diff;
}
