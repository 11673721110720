import { Badge, Icon, Modal } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { FilterSideBar } from '@arcflight/tf-component-library';
import { hasAircraftPermission } from '../_utils/AuthenticationWrapper';
import Loading from '../TFLoading/index';
import defaults from '../../utils/defaults';
import servers from '../../utils/servers';
import TFTable from '../TFTable/TFTable';
import {
  getV3Defects as getAllDefectsAction,
  getAircraftsDefects,
  saveParams,
  saveFilters,
  remove,
} from '../../models/defects/actions';
import { getAllAircraft } from '../../models/aircraft/actions';
import TFDrawer from '../TFDrawer/TFDrawer';
import EmptyStateDefects from '../../assets/emptyState/empty-state-defects.svg';
import closeIcon from '../../assets/cancel.svg';
import NonStyledButton from '../NonStyledButton/NonStyledButton';
import DefectDrawer from '../DefectDrawer/NewDrawer';
import arrowIcon from '../../assets/icon-sort.svg';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import defectsFilterModel from './defectsFilterModel';
import styles from './index.module.less';
import DefectPagination from './DefectPagination';
import DefectTableHeader from './DefectTableHeader';

class PaginatedDefectsTable extends PureComponent {
  static propTypes = {
    getAllDefects: PropTypes.func.isRequired,
    getAircraftList: PropTypes.func.isRequired,
    getACDefects: PropTypes.func.isRequired,
    saveFilters: PropTypes.func.isRequired,
    removeDefect: PropTypes.func.isRequired,
    saveParams: PropTypes.func.isRequired,
    userSettings: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    menu: PropTypes.object.isRequired,
    aircraftList: PropTypes.shape({
      list: PropTypes.array.isRequired,
      lastFetched: PropTypes.number.isRequired,
    }),
    defects: PropTypes.shape({
      pagination: PropTypes.object.isRequired,
      list: PropTypes.array.isRequired,
      lastFetched: PropTypes.number.isRequired,
    }),
    aircraft: PropTypes.object,
    intl: PropTypes.object.isRequired,
    defectId: PropTypes.string,
  };

  static defaultProps = {
    defects: {
      pagination: {},
      list: [],
      lastFetched: 0,
    },
    aircraftList: {
      list: [],
      lastFetched: 0,
    },
    aircraft: null,
    defectId: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      filteredTableData: [],
      wholeFilteredTableData: [],
      unsortedData: [],
      paginatedTableData: [],
      sortDirection: null,
      sortedColumn: null,
      defectFilterModel: defectsFilterModel(),
      params: null,
      filters: null,
      pagination: null,
      loading: true,
      showDefectDrawer: false,
      editDefect: false,
      addDefect: false,
      selectedDefect: {},
      currentPage: 1,
      pageSize: 10,
      defectLength: 0,
      controller: new AbortController(),
      melItemController: new AbortController(),
      userSettingsLoading: true,
    };
  }

  componentDidMount() {
    const { aircraftList, getAircraftList, aircraft } = this.props;
    this.getDefectData();
    if (!aircraft && Date.now() - aircraftList?.lastFetched > 30000) {
      getAircraftList();
    }
  }

  componentDidUpdate(prevProps) {
    const { aircraft, defects, userSettings } = this.props;
    const { loading } = this.state;
    if (aircraft && (!prevProps.aircraft || prevProps.aircraft.id !== aircraft.id)) {
      this.getDefectData();
    }
    if (defects.lastFetched !== prevProps.defects.lastFetched && loading) {
      this.onLoaded();
    }
    if (userSettings.displayName) {
      this.handleUserSettingsLoading();
    }
    if (!_.isEqual(defects.list, prevProps.defects.list)) {
      this.getDefectData();
    }
  }

  componentWillUnmount = () => {
    const { params, filters, controller, melItemController } = this.state;
    if (params) {
      this.props.saveParams(params);
      this.props.saveFilters(filters);
    }
    controller.abort();
    melItemController.abort();
  };

  onLoaded = () => {
    const { defects, defectId } = this.props;
    this.setState({ loading: false });
    if (defects?.list?.length && defectId) this.handleDefectId();
  };

  handleUserSettingsLoading = () => {
    this.setState({ userSettingsLoading: false });
  };

  handleDefectId = () => {
    const { defects, defectId } = this.props;
    const foundDefect = defects.list.find(({ id }) => id === defectId.id);
    this.setState({ selectedDefect: foundDefect, showDefectDrawer: true });
  };

  defectTypeChange = () => {
    const { melItemController } = this.state;
    melItemController.abort();
    this.setState({ melItemController: new AbortController() });
  };

  getDefectData = () => {
    this.setState({ loading: true });
    const { aircraft } = this.props;
    const {
      controller: { signal },
    } = this.state;
    const payload = {
      signal,
    };
    if (aircraft) {
      payload.aircraft_id = aircraft.id;
    }

    // no idea what the loading conditional below is about :shrug:
    if (aircraft !== 'loading') {
      if (aircraft !== null) {
        this.props.getACDefects({ aircraftId: aircraft.id, signal });
      } else {
        this.props.getAllDefects(payload);
      }
    }
  };

  getPaginatedData = (array, sort) => {
    const { pageSize, paginatedTableData, currentPage } = this.state;
    let i;
    let j;
    const tempArray = [];
    for (i = 0, j = array.length; i < j; i += pageSize) {
      tempArray.push(array.slice(i, i + pageSize));
    }
    if (sort) {
      this.setState({
        paginatedTableData: tempArray,
        filteredTableData: tempArray[currentPage - 1],
      });
    } else {
      this.setState({
        paginatedTableData: tempArray,
        filteredTableData: tempArray[0],
      });
    }
    if (array.length !== paginatedTableData.flat().length) {
      this.setState({ currentPage: 1 });
    }
  };

  handlePageSizeChange = (input, sort) => {
    const { wholeFilteredTableData } = this.state;
    if (input) {
      this.setState({ pageSize: parseInt(input, 10), currentPage: 1 }, () =>
        this.getPaginatedData(wholeFilteredTableData, sort),
      );
    }
  };

  handlePageChange = (input) => {
    const { currentPage, paginatedTableData } = this.state;
    if (input) {
      if (input === 'left') {
        const newData = paginatedTableData[currentPage - 2];
        this.setState({ currentPage: currentPage - 1, filteredTableData: newData });
      } else if (input === 'right') {
        const newData = paginatedTableData[currentPage];
        this.setState({ currentPage: currentPage + 1, filteredTableData: newData });
      } else {
        const newData = paginatedTableData[input - 1];
        this.setState({ currentPage: parseInt(input, 10), filteredTableData: newData });
      }
    }
  };

  setFilters = (filters, pagination) => {
    this.setState({ filters, pagination });
  };

  handleSelectRows = (id, edit) => {
    const { defects } = this.props;
    const record = defects.list.find((item) => item.id === id);
    this.setState({ selectedDefect: record, showDefectDrawer: true });
    if (edit) this.setState({ editDefect: true });
  };

  addNewDefect = () => {
    this.setState({ showDefectDrawer: true, editDefect: true, selectedDefect: {}, addDefect: true });
  };

  editDefect = (defect) => {
    this.setState({ showDefectDrawer: true, editDefect: true, selectedDefect: defect, addDefect: false });
  };

  viewDefect = (defect) => {
    this.setState({ showDefectDrawer: true, editDefect: false, selectedDefect: defect, addDefect: false });
  };

  handleDeleteDefect = (id) => {
    const {
      intl: { formatMessage },
    } = this.props;
    Modal.confirm({
      title: formatMessage({ id: 'title.deleteDefect' }),
      content: `${formatMessage({ id: 'form.question.areYouSureDeleteDefect' })} ${formatMessage({
        id: 'form.labels.cannotBeUndone',
      })}`,
      okText: formatMessage({ id: 'form.button.delete' }),
      cancelText: formatMessage({ id: 'form.button.cancel' }),
      onOk: () => {
        this.props.removeDefect(id);
      },
    });
  };

  handleCancelClick = () => {
    this.setState({ editDefect: false });
  };

  handleSubmitClick = (input) => {
    this.setState({ editDefect: false, selectedDefect: input || {} });
  };

  handleEditClick = () => {
    this.setState({ editDefect: true });
  };

  findObjectString = (obj, str) => {
    const keys = Object.keys(obj);
    let val;
    for (let i = 0; i < keys.length; i += 1) {
      val = obj[keys[i]];
      if (
        (typeof val === 'string' && val.toLowerCase().includes(str.toLowerCase())) || // check string values
        (typeof val === 'number' && val.toString().includes(str)) || // check number values
        (val && typeof val === 'object' && this.findObjectString(val, str)) // recursively check child objects
      ) {
        return true;
      }
    }
    return false;
  };

  applyFilters = (newFilters) => {
    const { pageSize, defectLength, filteredTableData } = this.state;
    const arrayLength = newFilters.length;
    this.getPaginatedData(newFilters);
    if (filteredTableData.length === 0) {
      const paginatedArraySection = newFilters.slice(0, pageSize);
      this.setState({ filteredTableData: paginatedArraySection });
    }
    this.setState({
      wholeFilteredTableData: newFilters,
      unsortedData: [],
      sortDirection: null,
      sortedColumn: null,
      defectLength: arrayLength,
    });
    if (defectLength !== arrayLength) this.setState({ currentPage: 1 });
  };

  handleColumnSort = (title, sorter, sorter2nd) => {
    const { wholeFilteredTableData, unsortedData, sortDirection, pageSize } = this.state;
    this.setState({ sortedColumn: title });
    if (unsortedData.length === 0) {
      this.setState({
        unsortedData: wholeFilteredTableData.sort((a, b) => {
          if (new Date(b.date) - new Date(a.date)) {
            return -1;
          }
          if (new Date(a.date) - new Date(b.date)) {
            return 1;
          }
          if (a.date === b.date) {
            return b.number - a.number;
          }
          return 0;
        }),
      });
    }
    if (sortDirection === null) {
      wholeFilteredTableData.sort((a, b) => {
        if (sorter2nd) {
          if (sorter2nd === 'ata') {
            const aNew = a[`${sorter}`][`${sorter2nd}`].split(' ')[0];
            const bNew = b[`${sorter}`][`${sorter2nd}`].split(' ')[0];
            if (aNew < bNew) return -1;
            if (aNew > bNew) return 1;
          } else {
            if (a[`${sorter}`][`${sorter2nd}`] < b[`${sorter}`][`${sorter2nd}`]) return -1;
            if (a[`${sorter}`][`${sorter2nd}`] > b[`${sorter}`][`${sorter2nd}`]) return 1;
          }
        } else {
          if (a[`${sorter}`] < b[`${sorter}`]) return -1;
          if (a[`${sorter}`] > b[`${sorter}`]) return 1;
        }
        return 0;
      });
      this.setState(
        { wholeFilteredTableData: [...wholeFilteredTableData], sortDirection: 'asc' },
        this.handlePageSizeChange(pageSize, 'sort'),
      );
    } else if (sortDirection === 'asc') {
      wholeFilteredTableData.sort((a, b) => {
        if (sorter2nd) {
          if (sorter2nd === 'ata') {
            const aNew = a[`${sorter}`][`${sorter2nd}`].split(' ')[0];
            const bNew = b[`${sorter}`][`${sorter2nd}`].split(' ')[0];
            if (aNew > bNew) {
              return -1;
            }
            if (aNew < bNew) return 1;
          } else {
            if (a[`${sorter}`][`${sorter2nd}`] > b[`${sorter}`][`${sorter2nd}`]) return -1;
            if (a[`${sorter}`][`${sorter2nd}`] < b[`${sorter}`][`${sorter2nd}`]) return 1;
          }
        } else {
          if (a[`${sorter}`] > b[`${sorter}`]) return -1;
          if (a[`${sorter}`] < b[`${sorter}`]) return 1;
        }
        return 0;
      });
      this.setState({ wholeFilteredTableData: [...wholeFilteredTableData], sortDirection: 'desc' }, () =>
        this.handlePageSizeChange(pageSize, 'sort'),
      );
    } else if (sortDirection === 'desc') {
      this.setState(
        {
          sortDirection: null,
          wholeFilteredTableData: unsortedData.sort((a, b) => {
            if (new Date(b.date) - new Date(a.date)) {
              return -1;
            }
            if (new Date(a.date) - new Date(b.date)) {
              return 1;
            }
            if (a.date === b.date) {
              return b.number - a.number;
            }
            return 0;
          }),
        },
        () => this.handlePageSizeChange(pageSize, 'sort'),
        this.setState({ unsortedData: [] }),
      );
    }
  };

  columnHeader = (title, sorter, sorter2nd) => {
    const { sortDirection, sortedColumn } = this.state;
    let arrowStyles = styles.noArrowDisplay;
    if (sortDirection === 'asc' && sortedColumn === title) arrowStyles = styles.arrowAsc;
    if (sortDirection === 'desc' && sortedColumn === title) arrowStyles = styles.arrowDesc;
    return (
      <button
        type="button"
        className={styles.columnHeaderButton}
        onClick={() => {
          this.handleColumnSort(title, sorter, sorter2nd);
        }}
      >
        <span>{title}</span>
        <img src={arrowIcon} alt="arrow" className={arrowStyles} />
      </button>
    );
  };

  toggleDrawer = () => {
    const { showDefectDrawer, controller, melItemController } = this.state;
    this.setState({ showDefectDrawer: !showDefectDrawer });
    if (showDefectDrawer) this.setState({ editDefect: false });
    controller.abort();
    melItemController.abort();
    this.setState({ controller: new AbortController(), melItemController: new AbortController() });
  };

  renderDrawerHeader() {
    const { selectedDefect } = this.state;
    return (
      <div>
        <div className={styles.headerWrapper} id="drawerHeader">
          <div className={styles.headerWrapperInner}>
            <div>
              <span className={styles.defectText}>Defect:</span>{' '}
              <span className={styles.tripUpdateHeader}>{selectedDefect?.mel_item?.title}</span>
            </div>
          </div>
          <div className={styles.closeIcon}>
            <NonStyledButton onClick={() => this.setState({ showDefectDrawer: false })}>
              <img src={closeIcon} alt="close-icon" />
            </NonStyledButton>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      userSettings,
      defects,
      intl: { formatMessage },
      match,
      menu,
    } = this.props;
    const {
      loading,
      pagination,
      params,
      showDefectDrawer,
      editDefect,
      addDefect,
      selectedDefect,
      filteredTableData,
      defectFilterModel,
      currentPage,
      pageSize,
      defectLength,
      controller,
      melItemController,
      userSettingsLoading,
    } = this.state;
    if (!pagination && defects.pagination) {
      if (params) {
        defects.pagination.current = params.current;
      } else {
        delete defects.pagination.current;
      }
    }

    if (pagination) {
      if (!defects.pagination) {
        defects.pagination = {};
      }
      defects.pagination.current = pagination;
    }
    const showAircraftColumn = document.location.pathname === '/defects';
    const hiddenColumns = [];
    if (!showAircraftColumn) hiddenColumns.push('aircraft_ids');
    const status = {
      open: {
        badge: 'warning',
        text: formatMessage({ id: 'status.open' }),
      },
      resolved: {
        badge: 'success',
        text: formatMessage({ id: 'status.resolved' }),
      },
      overdue: {
        badge: 'error',
        text: formatMessage({ id: 'status.overdue' }),
      },
      draft: {
        badge: 'default',
        text: formatMessage({ id: 'status.draft' }),
      },
      critical: {
        badge: 'error',
        text: formatMessage({ id: 'status.inTolerance' }),
      },
    };
    const columns = [
      {
        accessor: 'status',
        width: 60,
        Cell: ({ value }) => {
          if (value)
            return (
              <div className={styles.badgeStyle}>
                <Badge status={status[value].badge} />
              </div>
            );
          return null;
        },
      },
      {
        Header: this.columnHeader(formatMessage({ id: 'title.aircraft' }), 'aircraft_ids'),
        accessor: 'aircraft_ids',
        isVisible: showAircraftColumn,
        width: 90,
        sortable: false,
      },
      {
        Header: this.columnHeader(formatMessage({ id: 'title.numberShort' }), 'number'),
        accessor: 'number',
        width: 70,
      },
      {
        Header: this.columnHeader(formatMessage({ id: 'title.item' }), 'details'),
        accessor: 'details',
        className: 'columnItem',
        width: 270,
        Cell: ({ value }) => value || '-',
      },
      {
        Header: this.columnHeader(formatMessage({ id: 'title.type' }), 'display_data', 'type'),
        accessor: 'display_data.type',
        Cell: ({ value }) => value || '-',
        width: 70,
      },
      {
        Header: this.columnHeader(formatMessage({ id: 'title.ata' }), 'display_data', 'ata'),
        accessor: 'display_data.ata',
        Cell: ({ value }) => {
          if (value === 'UNKNOWN') return '-';
          const number = value.split(' ')[0];
          return number || '-';
        },
        width: 70,
      },
      {
        Header: this.columnHeader(formatMessage({ id: 'title.date' }), 'date'),
        accessor: 'date',
        width: 130,
        className: 'date',
        Cell: ({ value }) => {
          if (value)
            return userSettings && userSettings.dateFormat
              ? moment(value).format(userSettings.dateFormat)
              : moment(value).format(defaults.defaultDateFormat);
          return null;
        },
      },
      {
        Header: this.columnHeader(formatMessage({ id: 'title.daysRemainingShort' }), 'days_remaining'),
        accessor: 'days_remaining',
        width: 80,
        className: 'date',
        Cell: ({ value }) => {
          if (value && Number(value) < 0) {
            return 0;
          }
          if (value) {
            return value;
          }
          return '-';
        },
      },
      {
        accessor: 'ddl_url',
        width: 100,
        className: 'leftBorder',
        Cell: ({ value }) =>
          value === null ? null : (
            <span>
              <NonStyledButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <a
                  href={`${servers.api}${value}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                  download="DDL"
                  className={styles.pdfCell}
                >
                  <Icon type="file-pdf" /> {formatMessage({ id: 'text.ddl' })}
                </a>
              </NonStyledButton>
            </span>
          ),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'id',
        Cell: ({ value, row }) => {
          const complete = row?.original?.status === 'resolved';
          return (
            <AuthDropdownMenu
              options={{
                create: false,
                read: true,
                update: !complete,
                delete: true,
              }}
              menuStyle={{ right: 0, position: 'absolute', zIndex: 10 }}
              resource={AircraftResource.DEFECT}
              aircraftId={row.original.aircraft.id}
              handleDelete={() => this.handleDeleteDefect(value)}
              viewCallback={() => this.viewDefect(row.original)}
              editCallback={() => this.editDefect(row.original)}
            />
          );
        },
        width: 50,
      },
    ];

    let tableStyles = styles.tableWrapper;
    if (showAircraftColumn && menu.collapsed) tableStyles = styles.tableWrapperCollapsedMenu;
    if (!showAircraftColumn && menu.collapsed) tableStyles = styles.tableWrapperAircraft;
    if (!showAircraftColumn && !menu.collapsed) tableStyles = styles.tableWrapperAircraftExpandedMenu;
    return (
      <div className={styles.columnContainer} data-testid="PaginatedDefectsTable--ColumnContainer">
        {showDefectDrawer && (
          <TFDrawer toggleDrawer={this.toggleDrawer} visible={showDefectDrawer}>
            <DefectDrawer
              acId={selectedDefect?.aircraft?.id}
              defectId={selectedDefect?.id}
              editDefect={editDefect}
              addDefect={addDefect}
              handleCancelClick={this.handleCancelClick}
              handleSubmitClick={this.handleSubmitClick}
              handleEditClick={this.handleSelectRows}
              userSettings={userSettings}
              controller={controller}
              melItemController={melItemController}
              defectTypeChange={this.defectTypeChange}
            />
          </TFDrawer>
        )}
        <div className={styles.defectsTable} data-testid="PaginatedDefectsTable--TableContainer">
          <Loading loading={(loading && !showDefectDrawer) || userSettingsLoading} contain />
          <div className={styles.stickyHeader}>
            <DefectTableHeader
              defectCount={defects?.list?.length}
              addNewDefect={this.addNewDefect}
              aircraft={match.params.id}
            />
          </div>
          {filteredTableData && filteredTableData.length > 0 ? (
            <>
              <div className={tableStyles}>
                <TFTable
                  columns={columns}
                  data={filteredTableData}
                  handleRowClick={this.handleSelectRows}
                  hiddenColumns={hiddenColumns}
                />
              </div>
              <div className={styles.stickyHeader}>
                <DefectPagination
                  arrayLength={defectLength}
                  index={currentPage}
                  pageSize={pageSize}
                  handlePageChange={this.handlePageChange}
                  handlePageSizeChange={this.handlePageSizeChange}
                />
              </div>
            </>
          ) : (
            <div className={styles.emptyStateWrapper}>
              <img src={EmptyStateDefects} alt="empty state" />
              <span>
                {defects?.list?.length > 0 && filteredTableData?.length === 0
                  ? formatMessage({ id: 'text.noItemsMatchMX' })
                  : formatMessage({ id: 'text.noData' })}
              </span>
            </div>
          )}
        </div>
        <div className={styles.filterSidebar} data-testid="PaginatedDefectsTable--FiltersContainer">
          <FilterSideBar
            data={defects.list}
            updateArray={this.applyFilters}
            filterGroups={defectFilterModel}
            onChange={() => {
              this.applyFilters(defects.list);
            }}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    ({ defects, aircraft, userSettings, menu }) => ({
      defects: {
        pagination: defects.pagination,
        list: Array.from(defects?.defectsMap.values())
          .filter((item) => {
            const usersPermissions = userSettings?.details?.people?.find((person) =>
              person?.permission_groups[1]?.aircraft.includes(item?.aircraft.id),
            );
            return hasAircraftPermission(
              item.aircraft,
              AircraftResource.AIRCRAFT,
              AircraftPermission.READ,
              usersPermissions?.permission_groups[1],
            );
          })
          .sort((a, b) => {
            if (new Date(b.date) - new Date(a.date)) {
              return -1;
            }
            if (new Date(a.date) - new Date(b.date)) {
              return 1;
            }
            if (a.date === b.date) {
              return b.number - a.number;
            }
            return 0;
          }),
        lastFetched: defects.lastFetched,
      },
      params: defects.params,
      filters: defects.filters,
      aircraftList: {
        list: Array.from(aircraft.aircraftMap.values()),
        lastFetched: aircraft.lastFetched,
      },
      userSettings,
      menu,
    }),
    (dispatch) => ({
      getAllDefects: (payload) => {
        return dispatch(
          getAllDefectsAction({
            payload,
          }),
        );
      },
      getACDefects: (payload) => {
        return dispatch(getAircraftsDefects(payload));
      },
      getAircraftList: () => {
        return dispatch(getAllAircraft({}));
      },
      saveParams: (params) => {
        return dispatch(
          saveParams({
            payload: params,
          }),
        );
      },
      saveFilters: (filters) => {
        return dispatch(
          saveFilters({
            payload: filters,
          }),
        );
      },
      removeDefect: (id) => {
        return dispatch(
          remove({
            payload: id,
          }),
        );
      },
    }),
  ),
)(PaginatedDefectsTable);
