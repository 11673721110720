import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import GridWrapper from './GripWrapper';
import Label from './Label';

interface ViewMelDetailsProps {
  defect: Defect | null;
  isMelTableVisible: boolean;
}

const Section = styled.div`
  width: ${({ width }): string => `${width}px`};
  height: ${({ height }): string => `${height}px`};
  border-left: ${({ borderLeft }): string => (borderLeft ? 'solid 2px rgba(36, 45, 65, 0.02)' : 'none')};
  border-top: ${({ borderTop }): string => (borderTop ? 'solid 2px rgba(36, 45, 65, 0.02)' : 'none')};
  display: ${({ isMelTableVisible }): string => (isMelTableVisible ? 'flex' : 'none')};
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 13px 0 20px 18px;
  flex-direction: column;
`;

const slideDown = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: max-content;
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    height: max-content;
    opacity: 0;
  }
  to {
    height: 0;
    opacity: 1;
  }
`;

const slideTableDown = css`
  animation: ${slideDown} 0.3s;
`;

const slideTableUp = css`
  animation: ${slideUp} 0.3s;
`;

const AnimationDiv = styled.div`
  ${({ isMelTableVisible }): string =>
    isMelTableVisible &&
    css`
      ${slideTableDown}
    `};
  ${({ isMelTableVisible }): string =>
    !isMelTableVisible &&
    css`
      ${slideTableUp}
    `};
  height: ${({ isMelTableVisible }): string => (isMelTableVisible ? 'auto' : '0')};
`;

const ViewMelDetails: React.FC<ViewMelDetailsProps> = ({ defect, isMelTableVisible }) => {
  const section5Ref = useRef(null);

  const [extraHeight, setExtraHeight] = useState(0);

  const { formatMessage } = useIntl();
  const {
    mel_item: { chapter_number, section_number, subsection_number, title, mel_rectifications },
  } = defect;

  let melRectifications = { interval: '', number_installed: 0, number_required: 0, remarks: '' };
  // eslint-disable-next-line prefer-destructuring
  if (mel_rectifications.length > 0) melRectifications = mel_rectifications[0];
  const { interval, number_installed, number_required, remarks } = melRectifications;

  let chapterNumberTitle = ``;
  if (chapter_number) chapterNumberTitle += chapter_number;
  if (section_number) chapterNumberTitle += `-${section_number}`;
  if (subsection_number) chapterNumberTitle += `-${subsection_number}`;

  const sectionTitle = `${chapterNumberTitle} ${title}`;

  useEffect(() => {
    const section5 = document.getElementById('section5');
    if (section5) {
      setTimeout(() => {
        const rect = section5.getBoundingClientRect();
        const { height } = rect;
        if (height > 30) setExtraHeight(height - 35);
      }, 350);
    }
  }, [remarks, section5Ref]);

  const isIpad = window.innerWidth < 770;
  let wrapperHeight = 265;
  if (isIpad) wrapperHeight = 289;
  return (
    <AnimationDiv isMelTableVisible={isMelTableVisible}>
      <Card padding="0" marginTop={20} display={isMelTableVisible ? '' : 'display'}>
        <GridWrapper columns={isIpad ? '230px 1fr' : '1fr 1fr'}>
          <FlexWrapper height={isMelTableVisible ? wrapperHeight + extraHeight : 0} column>
            <Label marginTop={15} marginLeft={20} marginBottom={165}>
              {`1. ${formatMessage({ id: 'title.systemSequence' })}`}
            </Label>
            <Label marginLeft={20} marginBottom={40} color="rgba(36, 45, 65, 0.7)" fontWeight={300} width="250px">
              {sectionTitle}
            </Label>
          </FlexWrapper>
          <FlexWrapper position="relative">
            <Section
              isMelTableVisible={isMelTableVisible}
              height={265 + extraHeight}
              width={isIpad ? 400 : 470}
              borderLeft
            >
              <Label marginBottom={165}>{`2. ${formatMessage({ id: 'title.rectificationInterval' })}`}</Label>
              <Label color="rgba(36, 45, 65, 0.7)" fontWeight={300}>
                {interval}
              </Label>
            </Section>
            <Section
              isMelTableVisible={isMelTableVisible}
              height={218 + extraHeight}
              width={isIpad ? 360 : 420}
              borderLeft
              borderTop
            >
              <Label marginBottom={116}>{`3. ${formatMessage({ id: 'title.numberInstalled' })}`}</Label>
              <Label color="rgba(36, 45, 65, 0.7)" fontWeight={300}>
                {number_installed}
              </Label>
            </Section>
            <Section
              isMelTableVisible={isMelTableVisible}
              height={172 + extraHeight}
              width={isIpad ? 310 : 370}
              borderLeft
              borderTop
            >
              <Label marginBottom={70}>{`4. ${formatMessage({ id: 'title.numberRequiredDispatch' })}`}</Label>
              <Label color="rgba(36, 45, 65, 0.7)" fontWeight={300}>
                {number_required}
              </Label>
            </Section>
            <Section
              isMelTableVisible={isMelTableVisible}
              height={125 + extraHeight}
              width={isIpad ? 263 : 318}
              borderLeft
              borderTop
            >
              <Label marginBottom={20}>{`5. ${formatMessage({ id: 'title.remarksOrException' })}`}</Label>
              <Label color="rgba(36, 45, 65, 0.7)" fontWeight={300}>
                <div id="section5" ref={section5Ref}>
                  {remarks}
                </div>
              </Label>
            </Section>
          </FlexWrapper>
        </GridWrapper>
      </Card>
    </AnimationDiv>
  );
};

export default ViewMelDetails;
