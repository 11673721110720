/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import TFSelect from '../../TFSelect/TFSelect';
import TFTooltip from '../../TFTooltip/TFTooltip';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';

interface DefectNonMelItemsProps {
  defectType: string;
  editDefect: boolean;
  defect: Defect | null;
  rectificationCategory?: { title: string; colour?: string };
  setRectificationCategory?: (option: { title: string; colour?: string }) => void;
  deferred: boolean;
}

const DefectNonMelItems: React.FC<DefectNonMelItemsProps> = ({
  defectType,
  editDefect,
  defect,
  rectificationCategory,
  setRectificationCategory,
  deferred,
}) => {
  const [ataChapter, setAtaChapter] = useState('');
  const [ataSection, setAtaSection] = useState('');
  const [showCatAInfo, setShowCatAInfo] = useState(false);

  const { formatMessage } = useIntl();

  const handleSelectChange = (option: { title: string }): void => {
    setRectificationCategory(option);
  };

  const onMouseEnterDefer = (): void => {
    setShowCatAInfo(true);
  };

  const onMouseLeaveDefer = (): void => {
    setShowCatAInfo(false);
  };

  useEffect(() => {
    if (defect && defect.mel_item) {
      setAtaChapter(defect?.display_data?.ata.split(' ')[0]);
      setAtaSection(defect?.display_data?.ata_section);
      setRectificationCategory({ title: defect?.display_data?.category });
    }
  }, [defect]);

  let displayOption;
  if (defectType === 'CDL' || defectType === 'CAS' || defectType === 'Other')
    displayOption = (
      <FlexWrapper marginTop={30} column>
        <Label marginBottom={15} fontWeight={500}>
          {defectType}
        </Label>
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          {formatMessage({ id: 'text.pleaseEnterReferenceNumber' })}
        </Label>
        <Label marginBottom={5} fontWeight={500}>
          {formatMessage({ id: 'title.reference' })}
        </Label>
        <StyledInput id="cdlReference" type="text" width={280} />
        <Label marginTop={20} marginBottom={5}>
          {formatMessage({ id: 'title.ataChapter' })}
        </Label>
        <StyledInput id="cdlATAChapter" type="text" defaultValue={ataChapter} width={280} />
        <FlexWrapper>
          <FlexWrapper column marginRight={20}>
            <Label marginTop={20} marginBottom={5}>
              {formatMessage({ id: 'title.ataSection' })}
            </Label>
            <StyledInput id="cdlATASection" type="text" defaultValue={ataSection} width={110} />
          </FlexWrapper>
          {deferred && (
            <FlexWrapper column>
              <Label marginTop={20} marginBottom={5}>
                {formatMessage({ id: 'title.rectificationCategory' })}
              </Label>
              <TFSelect
                initial={{ title: rectificationCategory.title || 'A' }}
                options={[{ title: 'A' }, { title: 'B' }, { title: 'C' }, { title: 'D' }, { title: 'Advisory only' }]}
                handleSelectChange={handleSelectChange}
              />
            </FlexWrapper>
          )}
        </FlexWrapper>
        {rectificationCategory.title === 'A' && (
          <FlexWrapper column marginTop={30}>
            <FlexWrapper>
              <Label fontWeight={500} marginBottom={15} marginRight={5}>
                {formatMessage({ id: 'title.enterRectificationIntervals' })}
              </Label>
              <TFTooltip
                handleMouseOver={onMouseEnterDefer}
                handleMouseLeave={onMouseLeaveDefer}
                showToolTip={showCatAInfo}
              >
                <span>{formatMessage({ id: 'text.allowedRectificationIntervals' })}</span>
              </TFTooltip>
            </FlexWrapper>
            <Label color="rgba(36, 45, 65, 0.7)" marginBottom={10}>
              {formatMessage({ id: 'text.pleaseEnterRectificationInterval' })}
            </Label>
            <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)" fontSize="12">
              {formatMessage({ id: 'text.allFieldsOptional' })}
            </Label>
            <FlexWrapper marginBottom={20}>
              <FlexWrapper column marginRight={20}>
                <Label marginBottom={5}>{formatMessage({ id: 'form.labels.flightHourLimit' })}</Label>
                <StyledInput
                  defaultValue={defect?.flight_seconds_limit || ''}
                  type="text"
                  width={140}
                  id="flightHoursLimit"
                />
              </FlexWrapper>
              <FlexWrapper column marginRight={20}>
                <Label marginBottom={5}>{formatMessage({ id: 'form.labels.apuHoursLimit' })}</Label>
                <StyledInput
                  defaultValue={defect?.apu_seconds_limit || ''}
                  type="text"
                  width={140}
                  id="APUHoursLimit"
                />
              </FlexWrapper>
              <FlexWrapper column>
                <Label marginBottom={5}>{formatMessage({ id: 'form.labels.flightDaysLimit' })}</Label>
                <StyledInput
                  defaultValue={defect?.flight_days_limit || ''}
                  type="text"
                  width={140}
                  id="flightDaysLimit"
                />
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper marginBottom={20}>
              <FlexWrapper column marginRight={20}>
                <Label marginBottom={5}>{formatMessage({ id: 'form.labels.hoursLimit' })}</Label>
                <StyledInput defaultValue={defect?.seconds_limit || ''} type="text" width={140} id="hoursLimit" />
              </FlexWrapper>
              <FlexWrapper column marginRight={20}>
                <Label marginBottom={5}>{formatMessage({ id: 'form.labels.calendarDaysLimit' })}</Label>
                <StyledInput
                  defaultValue={defect?.calendar_days_limit || ''}
                  type="text"
                  width={140}
                  id="calendarDaysLimit"
                />
              </FlexWrapper>
              <FlexWrapper column>
                <Label marginBottom={5}>{formatMessage({ id: 'form.labels.cyclesDaysLimit' })}</Label>
                <StyledInput defaultValue={defect?.cycles_limit || ''} type="text" width={140} id="cyclesDaysLimit" />
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper>
              <FlexWrapper column marginRight={20}>
                <Label>{formatMessage({ id: 'form.labels.flightLimit' })}</Label>
                <StyledInput defaultValue={defect?.flights_limit || ''} type="text" width={140} id="flightLimit" />
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
        )}
      </FlexWrapper>
    );
  if (defectType === 'NEF')
    displayOption = (
      <FlexWrapper column>
        <Label marginBottom={15}>{formatMessage({ id: 'text.nefExpanded' })}</Label>
        <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
          {formatMessage({ id: 'text.pleaseEnterReferenceNumber' })}
        </Label>
        <Label marginBottom={5}>{formatMessage({ id: 'title.reference' })}</Label>
        {editDefect ? <StyledInput id="nefReference" type="text" width={280} /> : <span>nef reference here</span>}
      </FlexWrapper>
    );
  return displayOption;
};

export default DefectNonMelItems;
