/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dropdown, Menu, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import threeDotMenu from '../../../assets/more-options.svg';
import { AircraftResource } from '../../../models/aircraft';
import AuthDropdownMenu from '../../AuthDropdownMenu/AuthDropdownMenu';
import StatusBadge from '../../Status/StatusBadge';
import TFToggle from '../../TFToggle/TFToggle';
import styles from '../workpackDrawer.module.less';
import UnsavedConfirmationButton from './UnsavedConfirmationButton';

const EditTitleInfo = ({ editStagedPayload, description = '', po = '', date, status, match = {} }): JSX.Element => {
  let active = false;
  if (status === 'active') active = true;
  const [workpackDate, setWorkpackDate] = useState(null);

  const handleDateChange = (dateMoment, dateString: string): void => {
    setWorkpackDate(dateString);
    editStagedPayload({ date: dateString });
  };

  useEffect(() => {
    if (date) setWorkpackDate(date);
  }, [date]);
  return (
    <>
      <div className={styles.rowGridSix}>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>PO Number</span>
          <input
            type="text"
            defaultValue={po}
            onBlur={(e): void => editStagedPayload({ po: e.target.value })}
            className={styles.formInput}
          />
        </div>
        <div className={styles.rowItemDescription}>
          <span className={styles.formLabel}>Description</span>
          <input
            type="text"
            defaultValue={description}
            className={styles.formInput}
            onBlur={(e): void => editStagedPayload({ description: e.target.value })}
          />
        </div>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>Date Created</span>
          <DatePicker
            suffixIcon={<span />}
            value={workpackDate ? moment(workpackDate, 'YYYY-MM-DD') : moment()}
            format="YYYY-MM-DD"
            onChange={handleDateChange}
          />
        </div>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>Status Toggle</span>
          <div className={styles.toggleWrapper}>
            {status === 'completed' ? (
              <span className={styles.statusInfo}>
                <StatusBadge status={status} /> {status}
              </span>
            ) : (
              <TFToggle
                active={active}
                handleToggle={(value: boolean): void => editStagedPayload({ status: value ? 'active' : 'draft' })}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const AddTitleInfo = ({ editStagedPayload }): JSX.Element => {
  const [status, setStatus] = useState('draft');
  const [date, setDate] = useState(null);
  const [po, setPo] = useState('');
  const [description, setDescription] = useState('');

  let active = false;
  if (status === 'active') active = true;

  const handleInputChange = (target, value): void => {
    editStagedPayload({ [`${target}`]: value });
  };

  const handleDateChange = (dateMoment, dateString: string): void => {
    setDate(dateString);
    handleInputChange('date', dateString);
  };

  useEffect(() => {
    if (date === null) {
      setDate(moment().format('YYYY-MM-DD'));
      const setInitialDate = (): void => {
        editStagedPayload({ date: moment().format('YYYY-MM-DD'), status: 'draft' });
      };
      setInitialDate();
    }
  }, [date, editStagedPayload]);
  return (
    <div className={styles.rowGridSix}>
      <div className={styles.rowItem}>
        <span className={styles.formLabel}>PO Number</span>
        <input
          type="text"
          value={po}
          onChange={(e): void => setPo(e.target.value)}
          onBlur={(e): void => handleInputChange('po_number', e.target.value)}
          className={styles.formInput}
        />
      </div>
      <div className={styles.rowItemDescription}>
        <span className={styles.formLabel}>Description</span>
        <input
          type="text"
          value={description}
          className={styles.formInput}
          onChange={(e): void => setDescription(e.target.value)}
          onBlur={(e): void => handleInputChange('description', e.target.value)}
        />
      </div>
      <div className={styles.rowItem}>
        <span className={styles.formLabel}>Date</span>
        <DatePicker
          suffixIcon={<span />}
          value={date ? moment(date, 'YYYY-MM-DD') : moment()}
          format="YYYY-MM-DD"
          onChange={handleDateChange}
        />
      </div>
      <div className={styles.rowItem}>
        <span className={styles.formLabel}>Status Toggle</span>
        <div className={styles.toggleWrapper}>
          <TFToggle
            active={active}
            handleToggle={(value: boolean): void => {
              handleInputChange('status', value ? 'active' : 'draft');
              setStatus(value ? 'active' : 'draft');
            }}
          />
        </div>
      </div>
    </div>
  );
};

const WorkpackTitle = ({
  mode,
  description,
  changeMode,
  editStagedPayload,
  po,
  date,
  status,
  handleDeleteWorkpack,
  wpId,
  unsavedChanges,
  setModalVisible,
  setCloseClick,
  aircraftId,
  CRSUrl,
  match,
}): JSX.Element => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const { formatMessage } = useIntl();

  const handleCloseClick = (): void => {
    setModalVisible(true);
    setCloseClick(true);
  };

  const editButtonChild = (
    <Link to={`/aircraft/${match.params.id}/workpacks/${wpId}/edit`}>
      <span>{formatMessage({ id: 'form.button.edit' })}</span>
    </Link>
  );

  let customButtonChild = null;

  if (status !== 'pending')
    customButtonChild = (
      <a href={CRSUrl} target="_blank" rel="noopener noreferrer" className={styles.addCRSLink}>
        <button className={styles.dropdownItem} onClick={(): void => null} type="button">
          Add CRS
        </button>
      </a>
    );

  let allowUpdate = true;
  if (status === 'complete') allowUpdate = false;

  return (
    <>
      <div className={styles.workpackTitle}>
        {mode === 'edit' ? (
          <h3>Editing: {po}</h3>
        ) : (
          <>
            <h3>
              Workpack:{' '}
              <span>
                {po} {description && `(${description})`}
              </span>
            </h3>
            <AuthDropdownMenu
              editText={editButtonChild}
              customText={customButtonChild}
              handleDelete={(): void => handleDeleteWorkpack(wpId)}
              resource={AircraftResource.WORKPACK}
              aircraftId={match.params.id}
              options={{ read: false, update: allowUpdate, delete: true }}
            />
          </>
        )}
        <UnsavedConfirmationButton handleClick={handleCloseClick} unsavedChanges={unsavedChanges} />
      </div>
      <div>
        {mode === 'edit' && (
          <EditTitleInfo
            editStagedPayload={editStagedPayload}
            description={description}
            po={po}
            date={date}
            status={status}
          />
        )}
      </div>
      <div>{mode === 'new' && <AddTitleInfo editStagedPayload={editStagedPayload} />}</div>
    </>
  );
};

export default WorkpackTitle;
