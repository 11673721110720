/* eslint-disable @typescript-eslint/no-explicit-any */
import { getDamageReports } from '../../services/api';
import { DamageReportsActionTypes } from '.';

// eslint-disable-next-line import/prefer-default-export
export const fetch = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await getDamageReports(payload);
  dispatch({
    type: DamageReportsActionTypes.SAVE_ALL,
    aircraftId: payload.id,
    payload: response,
  });
};
