/* eslint-disable @typescript-eslint/no-explicit-any */
import { getDamageMaps, addDamageMap } from '../../services/api';
import { DamageMapsActionTypes } from '.';

export const fetch = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await getDamageMaps(payload);
  dispatch({
    type: DamageMapsActionTypes.SAVE_ALL,
    aircraftId: payload.id,
    payload: response,
  });
};
export const add = ({ payload }) => async (dispatch): Promise<any> => {
  const response = await addDamageMap(payload);
  if (response && response.status === 201) {
    const body = await response.json();
    dispatch({
      type: DamageMapsActionTypes.SAVE,
      aircraftId: payload.id,
      payload: body,
    });
  }
};
export const setDefaultImage = ({ payload }) => async (dispatch): Promise<any> => {
  dispatch({
    type: DamageMapsActionTypes.SAVE_IMAGE,
    payload,
  });
};
