import React from 'react';
import { connect } from 'react-redux';
import { DashboardState } from '../../models';
import { Person } from '../../models/people';

interface OrgWrapperProps {
  orgId: string;
  loggedInUser: Person[];
}

const OrgAuthWrapper: React.FC<OrgWrapperProps> = ({ orgId, loggedInUser, children }) => {
  const loggedInUserIsAdminInRequiredOrg = loggedInUser.some(
    (userAccount) => userAccount.organisation.id === orgId && userAccount.position === 'Admin',
  );

  if (loggedInUserIsAdminInRequiredOrg) {
    return <>{children}</>;
  }
  return null;
};

export default connect(({ userSettings }: DashboardState) => ({
  loggedInUser: (userSettings && userSettings.details && userSettings.details.people) || [],
}))(OrgAuthWrapper);
