import React from 'react';
import { Col, Row, Form, Input, Button, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import moment from 'moment';
import globalStyles from '../../utils/globalStyles.module.less';
import DocumentUploader from '../DocumentUploader';
import styles from './CompanyPersonalDetails.module.less';

const CompanyPersonalDetails = ({
  form,
  intl: { formatMessage },
  workpackResolved,
  uploadParams,
  signoffAsEngineer,
  toggleSignoffMethod,
  dateFormat,
}) => {
  const setFormAttachment = (fileList) => {
    form.setFieldsValue({ attachment: fileList });
  };

  const { getFieldDecorator } = form;

  return (
    <>
      <div style={workpackResolved ? { display: 'none' } : null}>
        <Form.Item
          label="Method"
          className={`${styles.signoffMethodRow} ${globalStyles.formItem}`}
          data-test="signoffMethod"
          trigger="onBlur"
        >
          {getFieldDecorator('signoff_method', {
            rules: [{ required: false }],
          })(
            <Row gutter={16}>
              <Col>
                <Button
                  className={`${globalStyles.buttonRadioGroup} ${signoffAsEngineer &&
                    globalStyles.buttonRadioGroupActive}`}
                  onClick={() => toggleSignoffMethod(true)}
                  data-test="signoffAsEngineer"
                >
                  {formatMessage({ id: 'form.button.fillInCRS' })}
                </Button>
                <Button
                  className={`${globalStyles.buttonRadioGroup} ${!signoffAsEngineer &&
                    globalStyles.buttonRadioGroupActive}`}
                  onClick={() => toggleSignoffMethod(false)}
                  data-test="signoffAsReporter"
                >
                  {formatMessage({ id: 'form.button.uploadCRS' })}
                </Button>
              </Col>
            </Row>,
          )}
        </Form.Item>

        {!signoffAsEngineer && (
          <Row gutter={16} className={styles.formRow}>
            <Col md={4}>
              <Form.Item
                trigger="onBlur"
                label={formatMessage({ id: 'form.placeholder.yourFirstName' })}
                className={`${globalStyles.formItem}`}
                data-test="formItemReporterFirstName"
              >
                {getFieldDecorator('reporter_first_name', {
                  rules: [{ required: true, message: formatMessage({ id: 'message.addFirstName' }) }],
                })(
                  <Input
                    className={`${globalStyles.formInput}`}
                    placeholder={formatMessage({ id: 'form.placeholder.yourFirstName' })}
                    data-test="formElementReporterFirstName"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item
                trigger="onBlur"
                label={formatMessage({ id: 'form.placeholder.yourLastName' })}
                className={`${globalStyles.formItem}`}
                data-test="formItemReporterLastName"
              >
                {getFieldDecorator('reporter_last_name', {
                  rules: [{ required: true, message: formatMessage({ id: 'message.addLastName' }) }],
                })(
                  <Input
                    className={`${globalStyles.formInput}`}
                    placeholder={formatMessage({ id: 'form.placeholder.yourLastName' })}
                    data-test="formElementReporterLastName"
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16} className={styles.formRow}>
          <Col md={4}>
            <Form.Item
              trigger="onBlur"
              label={
                signoffAsEngineer
                  ? formatMessage({ id: 'form.labels.engineerFirstName' })
                  : formatMessage({ id: 'form.labels.engineerFirstNameOpt' })
              }
              className={`${globalStyles.formItem} ${styles.formItem}`}
              data-test="formItemFirstName"
            >
              {getFieldDecorator('first_name', {
                rules: [{ required: signoffAsEngineer, message: formatMessage({ id: 'message.addFirstName' }) }],
              })(
                <Input
                  className={`${globalStyles.formInput}`}
                  placeholder={formatMessage({ id: 'form.labels.engineerFirstName' })}
                  data-test="formElementFirstName"
                />,
              )}
            </Form.Item>
          </Col>
          <Col md={4}>
            <Form.Item
              trigger="onBlur"
              label={
                signoffAsEngineer
                  ? formatMessage({ id: 'form.labels.engineerLastName' })
                  : formatMessage({ id: 'form.labels.engineerLastNameOpt' })
              }
              className={`${globalStyles.formItem} ${styles.formItem}`}
              data-test="formItemLastName"
            >
              {getFieldDecorator('last_name', {
                rules: [{ required: signoffAsEngineer, message: formatMessage({ id: 'message.addLastName' }) }],
              })(
                <Input
                  className={`${globalStyles.formInput}`}
                  placeholder={formatMessage({ id: 'form.labels.engineerLastName' })}
                  data-test="formElementLastName"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className={styles.formRow}>
          <Col md={8}>
            <Form.Item
              trigger="onBlur"
              label={formatMessage({ id: 'form.labels.engineerAuthorisationNumber' })}
              className={`${globalStyles.formItem} ${styles.formItem}`}
              data-test="formItemAuthorisationNumber"
            >
              {getFieldDecorator('licence_number', {
                rules: [{ required: false, message: formatMessage({ id: 'message.addAuthorisationNumber' }) }],
              })(
                <Input
                  className={`${globalStyles.formInput}`}
                  placeholder={formatMessage({ id: 'form.labels.engineerAuthorisationNumber' })}
                  data-test="formElementAuthorisationNumber"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className={styles.formRow}>
          <Col md={8}>
            <Form.Item
              trigger="onBlur"
              label={formatMessage({ id: 'form.labels.part145Organisation' })}
              className={`${globalStyles.formItem} ${styles.formItem}`}
              data-test="formItemCompanyName"
            >
              {getFieldDecorator('company_name', {
                rules: [{ required: true, message: formatMessage({ id: 'message.addPart145Organisation' }) }],
              })(
                <Input
                  className={`${globalStyles.formInput}`}
                  placeholder={formatMessage({ id: 'form.placeholder.yourPart145Org' })}
                  data-test="formElementCompanyName"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className={styles.formRow}>
          <Col md={8}>
            <Form.Item
              trigger="onBlur"
              label={formatMessage({ id: 'form.labels.part145Approval' })}
              className={`${globalStyles.formItem} ${styles.formItem}`}
              data-test="formItemCompanyApproval"
            >
              {getFieldDecorator('company_approval', {
                rules: [{ required: true, message: formatMessage({ id: 'message.addPart145Approval' }) }],
              })(
                <Input
                  className={`${globalStyles.formInput}`}
                  placeholder={formatMessage({ id: 'form.placeholder.yourPart145App' })}
                  data-test="formElementCompanyApproval"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className={styles.formRow}>
          <Col md={8}>
            <Form.Item
              trigger="onBlur"
              label={ReactHtmlParser(
                `${formatMessage({ id: 'form.labels.workpackRefNo' })} <span>${formatMessage({
                  id: 'form.labels.optional',
                })}</span>`,
              )}
              className={`${globalStyles.formItem} ${styles.formItem}`}
              data-test="formItemWorkpackRefNo"
            >
              {getFieldDecorator('workpack_reference', {
                rules: [{ required: false }],
              })(
                <Input
                  className={`${globalStyles.formInput}`}
                  placeholder={formatMessage({ id: 'form.placeholder.yourWorkpackRefNo' })}
                  data-test="formElementWorkpackRefNo"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className={styles.formRow}>
          <Col md={8}>
            <Form.Item
              trigger="onBlur"
              label={formatMessage({ id: 'form.labels.resolutionDate' })}
              className={globalStyles.formItem}
              data-test="formItemResolutionDate"
            >
              {getFieldDecorator('resolution_date', {
                rules: [{ required: true, message: formatMessage({ id: 'message.addResolutionDate' }) }],
                initialValue: moment.utc(),
              })(
                <DatePicker
                  className={globalStyles.formDatePicker}
                  format={dateFormat}
                  allowClear={false}
                  disabledDate={(date) =>
                    date.isAfter(
                      moment
                        .utc()
                        .add(1, 'd')
                        .startOf('day'),
                    )
                  }
                  data-test="resolutionDatePicker"
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Row gutter={16} className={styles.formRow}>
        <Col md={8}>
          <Form.Item
            label={
              signoffAsEngineer
                ? ReactHtmlParser(
                    `${formatMessage({ id: 'form.labels.documents' })} <span>${formatMessage({
                      id: 'form.labels.optional',
                    })}</span>`,
                  )
                : formatMessage({ id: 'form.labels.documents' })
            }
            className={`${globalStyles.formItem} ${styles.formItem}`}
            data-test="formItemDocuments"
          >
            {getFieldDecorator('attachment', {
              rules: [{ required: !signoffAsEngineer }],
            })(
              <DocumentUploader
                acceptedFileString=".jpg,.jpeg,.png,.pdf"
                showFileName
                returnDocument={(fileList) => setFormAttachment(fileList)}
                onRemove={() => setFormAttachment(null)}
                returnBase64
                enableClearRemove
                uploadParams={uploadParams}
                workpackResolved={workpackResolved}
                data-test="formElementDocuments"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

CompanyPersonalDetails.defaultProps = {
  workpackResolved: false,
};

CompanyPersonalDetails.propTypes = {
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  workpackResolved: PropTypes.bool,
  uploadParams: PropTypes.object.isRequired,
  toggleSignoffMethod: PropTypes.func.isRequired,
  signoffAsEngineer: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default injectIntl(CompanyPersonalDetails);
