import React from 'react';
import { Icon, Button } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.less';

const KeyValueFilter = ({ title, valueKey, value, number, additionalClass, onChange }) => {
  return (
    <Button
      type="link"
      className={`${styles.singleFilter} ${additionalClass || undefined} ${value &&
        styles.singleFilterActive} ${number === 0 && styles.singleFilterDisabled}`}
      onClick={() => onChange(valueKey)}
      data-test="keyValueFilter"
    >
      <Icon type="close" className={styles.closeIcon} />
      {title} {number !== undefined && number !== null && number >= 0 ? `(${number})` : ''}
    </Button>
  );
};

KeyValueFilter.defaultProps = {
  number: null,
  additionalClass: null,
  valueKey: null,
};

KeyValueFilter.propTypes = {
  title: PropTypes.string.isRequired,
  valueKey: PropTypes.string,
  value: PropTypes.bool.isRequired,
  number: PropTypes.number,
  additionalClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default KeyValueFilter;
