/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import Loading from '../../TFLoading/index';
import { Defect } from '../../../models/defects';
import { MELItem } from '../../../models/mels';
import { DashboardState } from '../../../models';
import { getMelItemsForAircraft } from '../../../models/mels/actions';
import TFSearchInput from '../../TFSearchInput/TFSearchInput';
import TFTooltip from '../../TFTooltip/TFTooltip';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import DefectMelItems from './DefectMelItems';
import StyledRadioInput from './StyledRadioInput';
import DefectNonMelItems from './DefectNonMelItems';
import StyledRadioButton from './StyledRadioButton';

interface DeferralOptionsProps {
  defect: Defect | null;
  editDefect: boolean;
  rectificationCategory: { title: string; colour?: string };
  setRectificationCategory: (option: { title: string; colour?: string }) => void;
  controller: any;
  defectTypeChange?: () => void;
  setMelItem?: (input: MELItem) => void;
  aircraftId?: string;
  melItemsLoading?: boolean;
  setMelItemsLoading?: (input: boolean) => void;
  handleMelItemChange?: (melItemId: string, rectId: string) => void;
  defectType: string;
  setDefectType: (input: string) => void;
}

const BoldDiv = styled.div`
  font-weight: 600;
`;

const RegularText = styled.span`
  font-weight: 400;
`;

const MelItemsWrapper = styled.div`
  position: relative;
  min-height: ${({ empty }): string => (empty ? 'auto' : '500px')};
`;

const DeferralOptions: React.FC<DeferralOptionsProps> = ({
  defect,
  editDefect,
  rectificationCategory,
  setRectificationCategory,
  controller,
  defectTypeChange,
  setMelItem,
  aircraftId,
  melItemsLoading,
  setMelItemsLoading,
  handleMelItemChange,
  defectType,
  setDefectType,
}) => {
  const [showDeferInfo, setShowDeferInfo] = useState(false);
  const [showDoNotDeferInfo, setShowDoNotDeferInfo] = useState(false);
  const [deferred, setDeferred] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [filteredItems, setFilteredItems] = useState(null);
  const [melItems, setMelItems] = useState(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { aircraftMelsMap, lastFetched, dirty } = useSelector((state: DashboardState) => state.mels);
  let list;
  if (aircraftMelsMap && aircraftMelsMap.get(defect?.aircraft?.id)) {
    list = aircraftMelsMap.get(defect.aircraft.id).list;
  }
  if (aircraftMelsMap && aircraftMelsMap.get(aircraftId)) {
    list = aircraftMelsMap.get(aircraftId).list;
  }
  const deferralOptionsArray = ['MEL', 'CDL', 'NEF', 'CAS', 'Other'];
  const deferralOptions = deferralOptionsArray.map((option) => (
    <StyledRadioButton
      identifier={`DeferralOption${option}`}
      id={`DeferralOption${option}`}
      value={`${option}`}
      group="defectTypeGroup"
      checked={option === defectType}
      key={option}
      marginRight={option !== 'Other' ? 5 : 0}
      onClick={(): void => setDefectType(option)}
      disabled={!deferred && option !== 'Other'}
    >
      {option}
    </StyledRadioButton>
  ));

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevDefectType = usePrevious(defectType);

  const usePreviousMelItems = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevMelItems = usePreviousMelItems(aircraftMelsMap);

  const usePreviousAircraft = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const PrevAircraft = usePreviousAircraft(aircraftId);

  const onMouseEnterDefer = (): void => {
    setShowDeferInfo(true);
  };

  const onMouseLeaveDefer = (): void => {
    setShowDeferInfo(false);
  };

  const onMouseEnterDoNotDefer = (): void => {
    setShowDoNotDeferInfo(true);
  };

  const onMouseLeaveDoNotDefer = (): void => {
    setShowDoNotDeferInfo(false);
  };

  const handleDoNotDeferClick = (): void => {
    setDeferred(false);
    setDefectType('Other');
  };

  useEffect(() => {
    if (prevDefectType === 'MEL' && prevDefectType !== defectType) {
      defectTypeChange();
      setMelItemsLoading(false);
    }
  }, [defectType, defectTypeChange, prevDefectType, setMelItemsLoading]);

  useEffect(() => {
    if (defect) {
      setDeferred(defect.deferred);
      if (!defect?.deferred) {
        setDefectType('Other');
      } else if (defect.defect_type) setDefectType(defect.defect_type);
    }
  }, [defect]);

  useEffect(() => {
    if (melItems) {
      setFilteredItems(melItems);
    }
  }, [melItems]);

  useEffect(() => {
    if (aircraftId !== PrevAircraft) {
      defectTypeChange();
    }
  }, [PrevAircraft, aircraftId, defectTypeChange]);

  useEffect(() => {
    if (editDefect && defectType === 'MEL') {
      if (aircraftId) {
        if ((Date.now() - lastFetched >= 30000 || dirty) && !aircraftMelsMap.has(aircraftId)) {
          setMelItemsLoading(true);
          dispatch(getMelItemsForAircraft({ payload: { id: aircraftId, signal: controller.signal } }));
        }
      }
    }
  }, [
    aircraftId,
    aircraftMelsMap,
    controller,
    defect,
    defectType,
    dirty,
    dispatch,
    editDefect,
    lastFetched,
    melItems,
    setMelItemsLoading,
  ]);

  useEffect(() => {
    if (!_.isEqual(aircraftMelsMap, prevMelItems)) {
      setMelItems(list);
      setMelItemsLoading(false);
    }
  }, [aircraftMelsMap, list, prevMelItems, setMelItemsLoading]);

  const filterMelItems = useCallback(
    (input: string): void => {
      const filtered = melItems.filter((item) => {
        if (
          item.chapter_number
            .toString()
            .toLowerCase()
            .includes(input) ||
          item.title.toLowerCase().includes(input)
        )
          return item;
        return null;
      });
      setFilteredItems(filtered);
    },
    [melItems],
  );

  useEffect(() => {
    if (searchInput) {
      filterMelItems(searchInput);
    } else {
      setFilteredItems(melItems);
    }
  }, [filterMelItems, melItems, searchInput]);

  return (
    <Card identifier="DeferralOptions">
      <FlexWrapper column marginBottom={25}>
        <Label fontWeight={600} marginBottom={20} identifier="DeferralLabel">
          {formatMessage({ id: 'text.deferralCaps' })}
        </Label>
        <Label marginBottom={15} identifier="DeferDefect">
          {formatMessage({ id: 'text.wouldYouLikeToDeferThisDefect' })}
        </Label>
        <FlexWrapper column>
          <FlexWrapper marginBottom={10} alignItems="center" position="relative">
            <StyledRadioInput
              id="deferred"
              value="deferred"
              name="deferGroup"
              checked={deferred}
              handleClick={(): void => setDeferred(!deferred)}
              identifier="Deferred"
            />
            <Label marginRight={10} marginBottom={0} identifier="DeferLabel">
              {formatMessage({ id: 'text.defer' })}
            </Label>
            <TFTooltip
              handleMouseOver={onMouseEnterDefer}
              handleMouseLeave={onMouseLeaveDefer}
              showToolTip={showDeferInfo}
            >
              <BoldDiv>
                {formatMessage({ id: 'text.mel' })}{' '}
                <RegularText>{formatMessage({ id: 'text.minimumEquipmentList' })}</RegularText>
              </BoldDiv>
              <BoldDiv>
                {formatMessage({ id: 'text.cdl' })}
                <RegularText>{formatMessage({ id: 'text.configurationDeviationList' })}</RegularText>
              </BoldDiv>
              <BoldDiv>
                {formatMessage({ id: 'text.nef' })}{' '}
                <RegularText>{formatMessage({ id: 'text.nonEssentialEquipmentFurnishing' })}</RegularText>
              </BoldDiv>
              <BoldDiv>
                {formatMessage({ id: 'text.cas' })}{' '}
                <RegularText>{formatMessage({ id: 'text.crewAlertingSystem' })}</RegularText>
              </BoldDiv>
            </TFTooltip>
          </FlexWrapper>
          <FlexWrapper alignItems="center" position="relative">
            <StyledRadioInput
              id="notDeferred"
              value="notDeferred"
              name="deferGroup"
              checked={!deferred}
              handleClick={(): void => handleDoNotDeferClick()}
              identifier="NotDeferred"
            />
            <Label marginRight={10} marginBottom={0} identifier="DoNotDeferLabel">
              {formatMessage({ id: 'text.doNotDefer' })}
            </Label>
            <TFTooltip
              handleMouseOver={onMouseEnterDoNotDefer}
              handleMouseLeave={onMouseLeaveDoNotDefer}
              showToolTip={showDoNotDeferInfo}
            >
              <div>{formatMessage({ id: 'text.noDeferralOptionsForDefect' })}</div>
              <BoldDiv>
                {formatMessage({ id: 'text.warningCaps' })}:{' '}
                <RegularText>{formatMessage({ id: 'text.thisWillRenderAircraftUnservicable' })}</RegularText>
              </BoldDiv>
            </TFTooltip>
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper marginTop={30} marginBottom={30}>
          <FlexWrapper column marginRight={80}>
            <Label marginBottom={10} identifier="DeferralOptions">
              {formatMessage({ id: 'text.deferralOptionsCaps' })}
            </Label>
            <FlexWrapper identifier="DeferralOptionsWrapper">{deferralOptions}</FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>
        {defectType === 'MEL' ? (
          <MelItemsWrapper empty={melItems && melItems.length === 0}>
            <Loading loading={melItemsLoading} contain />
            {melItems && melItems.length > 0 ? (
              <>
                <TFSearchInput handleSearchInput={(value): void => setSearchInput(value)} />
                <Card id="MELItemWrapper" maxHeight={500} identifier="MelItemsWrapper">
                  <DefectMelItems
                    melItems={filteredItems}
                    setMelItem={setMelItem}
                    handleMelItemChange={handleMelItemChange}
                    defect={defect}
                  />
                </Card>
              </>
            ) : null}
          </MelItemsWrapper>
        ) : null}
        {defectType && defectType !== 'MEL' ? (
          <DefectNonMelItems
            defect={defect}
            editDefect={editDefect}
            defectType={defectType}
            rectificationCategory={rectificationCategory}
            setRectificationCategory={setRectificationCategory}
            deferred={deferred}
          />
        ) : null}
      </FlexWrapper>
    </Card>
  );
};

export default DeferralOptions;
