/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { InputNumber } from 'antd';

const InputNumberUnits = forwardRef((props, ref) => (
  <>
    <InputNumber ref={ref} {...props} /> {props.units}
  </>
));

InputNumberUnits.propTypes = {
  units: PropTypes.string,
};

InputNumberUnits.defaultProps = {
  units: undefined,
};

export default InputNumberUnits;
