import moment from 'moment';

export default function transformFlightForSubmit(values) {
  const flight = { ...values };

  if (values.fuel_uplifts) {
    flight.fuel_uplifts = values.fuel_uplifts.map((uplift) => {
      const upliftFormatted = { ...uplift };
      upliftFormatted.id = uplift.id.startsWith('new-') ? null : uplift.id;
      return upliftFormatted;
    });
  }

  const timeValues = ['time_offblocks', 'time_onblocks', 'time_landing', 'time_takeoff'];

  // Convert times to strings
  timeValues.forEach((time) => {
    flight[time] = values[time] && moment.utc(values[time]).format();
  });

  // Only include necessary fields
  const fields = [
    'id',
    'oil_uplift',
    ...timeValues,
    'hobbs_departure',
    'hobbs_arrival',
    'landings_count',
    'apu_seconds',
    'departure_airport_id',
    'arrival_airport_id',
    'flight_type',
    'departure_fuel_pre_uplifts',
    'departure_fuel_adjustment',
    'arrival_fuel',
    'fuel_uplifts',
    'deice_events',
  ];
  const newFlight = fields.reduce((acc, field) => {
    if (field in flight) {
      if (field === 'id') {
        acc[field] = flight[field].replace('new-', '');
      } else {
        acc[field] = flight[field];
      }
    }
    return acc;
  }, {});

  return newFlight;
}
