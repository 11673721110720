/* eslint-disable react/jsx-props-no-spreading */
import { Icon, Button, Drawer, Menu, Avatar, Affix } from 'antd';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import servers from '../../utils/servers';
import isScreenMobile from '../../utils/isScreenMobile';
import { version } from '../../../package.json';
import logo from '../../assets/trustflight-logo-white.svg';
import burgerMenu from '../../assets/icon-menu.svg';
import closeIcon from '../../assets/mobile-close-icon.svg';
import settings from '../../assets/icon-Settings.svg';
import downArrow from '../../assets/icon-arrow-down-blue.svg';
import logout from '../../utils/logout';
import EmptyStateProfile from '../../assets/emptyState/empty-state-profile-picture.svg';
import support from '../../assets/mainNav/support.svg';
import styles from './index.module.less';
import RightContent from './RightContent';

const { SubMenu } = Menu;
const cookies = new Cookies();

const getIcon = (icon) => {
  if (typeof icon === 'string') {
    return <img src={icon} alt="icon" className={styles.icon} />;
  }
  return icon;
};
export default class GlobalHeader extends PureComponent {
  triggerResizeEvent = debounce(() => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }, 600);

  static propTypes = {
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func.isRequired,
    menuData: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object,
    Authorized: PropTypes.object,
    userSettings: PropTypes.func.isRequired,
  };

  static defaultProps = {
    collapsed: true,
    Authorized: undefined,
    history: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      expanded: false,
      active: '',
      openKeys: [],
    };
  }

  componentDidMount() {
    this.toggle();
    this.getSubMenus();
  }

  componentWillUnmount() {
    this.triggerResizeEvent.cancel();
    window.removeEventListener('resize', this.updateScreenSize);
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    this.triggerResizeEvent();
  };

  updateMediaScreenSize = () => {
    this.setState({ isMobile: isScreenMobile() });
  };

  getNavMenuItems = (menusData, parent) => {
    if (!menusData) {
      return [];
    }
    return menusData
      .filter((item) => item.name && !item.hideInMenu)
      .map((item) => {
        // make dom
        const ItemDom = this.getSubMenuOrItem(item, parent);
        return this.checkPermissionItem(item.authority, ItemDom);
      })
      .filter((item) => item);
  };

  handleExpandMenuClick = ({ key }) => {
    const { expanded } = this.state;
    this.setState({ active: key, expanded: !expanded });
  };

  getSubMenuOrItem = (item) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { isMobile, expanded, active } = this.state;
    // doc: add hideChildrenInMenu
    if (item.children && !item.hideChildrenInMenu && item.children.some((child) => child.name)) {
      const name = item.locale ? formatMessage({ id: item.locale }) : item.name;
      return (
        <SubMenu
          title={
            item.icon ? (
              <span>
                {getIcon(item.icon)}
                <span>{name}</span>
                {isMobile ? (
                  <img
                    src={downArrow}
                    alt="down arrow"
                    className={
                      expanded && active === item.path
                        ? `${styles.downArrowExpanded} ${styles.downArrow}`
                        : styles.downArrow
                    }
                  />
                ) : null}
              </span>
            ) : (
              name
            )
          }
          key={item.path}
          data-testid="BaseMenu--SubMenuIcon"
          className={styles.subMenu}
          // removed for now until I can figure out the icon flipping issue
          // onTitleClick={(event) => this.handleExpandMenuClick(event)}
        >
          {this.getNavMenuItems(item.children)}
        </SubMenu>
      );
    }
    return <Menu.Item key={item.path}>{this.getMenuItemPath(item)}</Menu.Item>;
  };

  getMenuItemPath = (item) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const name = item.locale ? formatMessage({ id: item.locale }) : item.name;
    const itemPath = this.conversionPath(item.path);
    const icon = getIcon(item.icon);
    const { target } = item;
    // Is it a http link
    if (/^https?:\/\//.test(itemPath)) {
      return (
        <a href={itemPath} target={target}>
          {icon}
          <span>{name}</span>
        </a>
      );
    }
    const { location, onCollapse } = this.props;
    const { isMobile } = this.state;
    return (
      <Link
        to={itemPath}
        target={target}
        replace={itemPath === location.pathname}
        onClick={
          isMobile
            ? () => {
                onCollapse(true);
              }
            : undefined
        }
        data-testid="BaseMenu--IconLink"
      >
        {icon}
        <span>{name}</span>
      </Link>
    );
  };

  checkPermissionItem = (authority, ItemDom) => {
    const { Authorized } = this.props;
    if (Authorized && Authorized.check) {
      const { check } = Authorized;
      return check(authority, ItemDom);
    }
    return ItemDom;
  };

  conversionPath = (path) => {
    if (path && path.indexOf('http') === 0) {
      return path;
    }
    return `/${path || ''}`.replace(/\/+/g, '/');
  };

  getSubMenus = () => {
    const { menuData } = this.props;
    const keys = [];
    menuData.forEach((menuItem) => {
      if (menuItem.children) {
        keys.push(menuItem.path);
      }
      return keys;
    });
    this.setState({ subMenus: keys });
  };

  handleOpenChange = async (openKeys) => {
    const { subMenus } = this.state;
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);
    if (subMenus.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
    }
  };

  render() {
    const {
      collapsed,
      menuData,
      userSettings: { avatar, details = {}, displayName },
      intl: { formatMessage },
      history,
    } = this.props;
    const { avatar_url: avatarUrl, email } = details;

    const { isMobile, openKeys } = this.state;

    this.updateMediaScreenSize();

    matchMedia('(max-width: 767px)').addListener(this.updateMediaScreenSize);
    return (
      <div className={styles.header} data-testid="GlobalHeader--header">
        {isMobile && (
          <>
            <Affix offsetTop={0} style={{ zIndex: 200 }}>
              <div className={styles.headerWrapper}>
                <Link to="/" className={styles.logo} key="logo">
                  <img src={logo} alt="logo" width="140" />
                </Link>
                <div className={styles.burgerMenu}>
                  <Button type="link" onClick={this.toggle} data-testid="GlobalHeader--burgerMenuIcon">
                    <img src={collapsed ? closeIcon : burgerMenu} alt="logo" width={collapsed ? '20' : '24'} />
                  </Button>
                </div>
              </div>
            </Affix>
            <Drawer
              placement="top"
              visible={collapsed}
              closable={false}
              height="calc(100vh - 64px)"
              className={collapsed ? styles.drawer : styles.drawerHidden}
            >
              <Menu
                key="Menu"
                className={styles.menu}
                mode="inline"
                openKeys={openKeys}
                onOpenChange={this.handleOpenChange}
              >
                {this.getNavMenuItems(menuData)}
                <Menu.Item style={{ backgroundColor: 'unset' }}>
                  <a
                    href="https://intercom.help/trustflight/en/collections/2751520-tech-log-dashboard"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={support} alt="icon" className={styles.icon} />
                    <span>Support</span>
                  </a>
                </Menu.Item>
              </Menu>
              <div className={styles.userSection}>
                <div className={styles.userDetails}>
                  <div className={styles.avatarSection}>
                    {avatarUrl ? (
                      <Avatar
                        data-test="image-avatar"
                        data-testid="GlobalHeader--avatarImage"
                        size="75"
                        className={styles.mobileAvatar}
                        alt="avatar"
                        src={`${servers.api}/logins/${cookies.get('userId')}/avatar?${avatar}`}
                      />
                    ) : (
                      <Avatar
                        data-test="text-avatar"
                        data-testid="GlobalHeader--textAvatar"
                        size="75"
                        className={styles.mobileAvatar}
                        alt="avatar"
                        src={EmptyStateProfile}
                      />
                    )}
                    <div className={styles.userName}>
                      <div data-testid="GlobalHeader--DisplayName">{displayName}</div>
                      <div className={styles.email} data-testid="GlobalHeader--emial">
                        {email}
                      </div>
                    </div>
                  </div>
                  <div className={styles.settingsIcon}>
                    <Button
                      data-testid="GlobalHeader--settingsIcon"
                      type="link"
                      onClick={() => history.push('/settings/account')}
                    >
                      <img src={settings} alt="settings" />
                    </Button>
                  </div>
                </div>
                <div className={styles.dashboardVersion} data-testid="GlobalHeader--dashboardVersion">
                  <span>Dashboard v{version}</span>
                </div>
                <div className={styles.logout}>
                  <Button data-testid="GlobalHeader--signOut" type="link" onClick={() => logout()}>
                    <span>{formatMessage({ id: 'menu.account.signout' })}</span>
                  </Button>
                </div>
              </div>
            </Drawer>
          </>
        )}
        {!isMobile && (
          <>
            <Icon className={styles.trigger} type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={this.toggle} />
            <RightContent {...this.props} />
          </>
        )}
      </div>
    );
  }
}
