const produceFilterModel = () => {
  return [
    {
      groupLabel: 'Status',
      filters: [
        {
          label: 'Pending Acceptance',
          key: 'status',
          value: 'pending',
          badge: 'amber',
          filterFunction: (item) => item.status === 'pending',
        },
        {
          label: 'Draft',
          key: 'status',
          value: 'draft',
          badge: 'grey',
          filterFunction: (item) => item.status === 'draft',
        },
        {
          label: 'Active',
          key: 'status',
          value: 'active',
          badge: 'blue',
          filterFunction: (item) => item.status === 'active',
        },
        {
          label: 'Complete',
          key: 'status',
          value: 'complete',
          badge: 'green',
          filterFunction: (item) => item.status === 'complete',
        },
      ],
    },
    {
      groupLabel: 'Source',
      filters: [
        {
          label: 'CAMP',
          key: 'source',
          value: 'camp',
          filterFunction: (item) => item.source === 'camp',
        },
        {
          label: 'TrustFlight',
          key: 'source',
          value: 'trustflight',
          filterFunction: (item) => item.source === 'trustflight',
        },
      ],
    },
  ];
};

export default produceFilterModel;
