import React, { useRef, useState } from 'react';
import searchIcon from '../../assets/search.svg';
import cancelIcon from '../../assets/new-icon-cancel.svg';
import styles from './searchBox.module.less';

interface SearchBoxProps {
  searchCallback: (event) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ searchCallback }) => {
  const [searchBoxState, toggleSearchBoxState] = useState(false);
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  const handleSearch = (input): void => {
    searchCallback(input);
  };

  const handleInputBlur = (): void => {
    if (!value) {
      toggleSearchBoxState(!searchBoxState);
    }
  };

  const clearSearch = (): void => {
    setValue('');
    handleSearch('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={styles.searchHolder}>
      <button
        type="button"
        className={styles.searchIconHolder}
        onClick={(): void => {
          if (!value) {
            toggleSearchBoxState(!searchBoxState);
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 400);
          }
        }}
      >
        <img
          src={searchIcon}
          alt="search"
          className={`${styles.searchIcon} ${searchBoxState ? styles.searchIconActive : undefined}`}
        />
      </button>
      {searchBoxState ? (
        <>
          <div className={styles.searchInputHolder}>
            <input
              className={`${styles.searchInput} ${searchBoxState ? styles.searchInputActive : undefined}`}
              onChange={(e): void => {
                handleSearch(e.target.value);
                setValue(e.target.value);
              }}
              value={value}
              onBlur={handleInputBlur}
              ref={inputRef}
              placeholder="Search ..."
            />
            <button
              type="button"
              className={`${styles.closeIconHolder} ${searchBoxState ? styles.closeIconHolderActive : undefined}`}
              onClick={clearSearch}
            >
              <img src={cancelIcon} alt="cancel icon" className={styles.cancelIcon} />
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SearchBox;
