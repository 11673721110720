import React from 'react';
import { Card, Row, Col, Tag } from 'antd';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import servers from '../../utils/servers';
import globalStyles from '../../utils/globalStyles.module.less';
import styles from './index.module.less';

const DefectCard = ({ defect, intl: { formatMessage } }) => {
  const detailsArray = defect && defect.display_data ? Object.entries(defect.display_data) : [];
  return (
    <Card className={globalStyles.itemCard}>
      <Row>
        {detailsArray.map((detail) => (
          <Col key={detail[0]} span={6} data-testid={`${detail[0]}`}>
            <div className={styles.defectReportSubHeading}>{detail[0]}</div>
            <div>{detail[1]}</div>
          </Col>
        ))}
      </Row>
      <Row className={styles.defectReportSection} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} data-testid="defectDetails">
          <div className={styles.defectReportSubHeading}>Details</div>
          <div>{defect.details}</div>
        </Col>
        <Col span={12} data-testid="defectAttachments">
          <div className={styles.defectReportSubHeading}>Attachments</div>
          <Row gutter={8} span={24}>
            {defect.attachments &&
              defect.attachments.map((attachment) => {
                return (
                  <Col lg={4} md={4} sm={12} key={`attachment_${attachment.id}`}>
                    <a
                      href={`${servers.api}/${attachment.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-testid="attachmentLink"
                    >
                      <div
                        className={styles.attachmentView}
                        style={{ backgroundImage: `url(${servers.api}/${attachment.thumbnail_url})` }}
                        data-test="attachmentImage"
                      />
                    </a>
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
      {(defect.signature ||
        defect.date ||
        (defect.reported_by && (defect.reported_by.first_name || defect.reported_by.last_name))) && (
        <div className={styles.cardFooter}>
          <div className={styles.footerLeftWrapper}>
            <div className={styles.signatureText} data-testid="signatureLabel">
              {defect.signature &&
                defect.reported_by &&
                (defect.reported_by.first_name || defect.reported_by.last_name) &&
                formatMessage({ id: 'text.reportedAndSignedBy' })}
              {!defect.signature &&
                defect.reported_by &&
                (defect.reported_by.first_name || defect.reported_by.last_name) &&
                formatMessage({ id: 'text.reportedBy' })}
              {defect.signature &&
                !(defect.reported_by && (defect.reported_by.first_name || defect.reported_by.last_name)) &&
                formatMessage({ id: 'text.signed' })}
            </div>
            {defect.reported_by && (defect.reported_by.first_name || defect.reported_by.last_name) && (
              <div className={`${styles.signatureText} ${styles.signaturePerson}`} data-testid="signaturePerson">
                <span>{`${defect.reported_by.first_name} ${defect.reported_by.last_name}`}</span>
              </div>
            )}
            {defect.signature && (
              <div className={styles.signatureImg} data-testid="signatureImage">
                <img alt="Signature" src={`data:image/png;base64,${defect.signature}`} />
              </div>
            )}
          </div>
          <div className={styles.footerRightWrapper}>
            <div className={styles.signatureText}>
              {formatMessage({ id: 'status.reported' })} {formatMessage({ id: 'status.on' })}:
            </div>
            <div className={styles.signatureText}>
              <span>{defect.date}</span>
            </div>
            {defect.deferred && (
              <div className={styles.signatureText}>
                <Tag color="orange" className={styles.deferredTag}>
                  {formatMessage({ id: 'form.option.deferred' })}
                </Tag>
              </div>
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

DefectCard.propTypes = {
  defect: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(DefectCard);
