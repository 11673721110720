import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import { getSingleAircraft } from '../../models/aircraft/actions';
import { setDefaultImage } from '../../models/damageMaps/actions';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import ExpandedContent from '../../components/TopNavWrapper/ExpandedContent';

class DamageMaps extends PureComponent {
  static propTypes = {
    aircraftMap: PropTypes.instanceOf(Map).isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'list_view',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const aircraft = this.getAircraft();
    if (!aircraft || Date.now() - aircraft.lastFetched > 30000) {
      this.getAircraft(true);
    }
    if (location.pathname.includes('schematic_view')) {
      this.updateActiveTab();
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, match, location } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      dispatch(
        setDefaultImage({
          payload: '',
        }),
      );
    }
    if (location.pathname.includes('schematic_view')) {
      this.updateActiveTab();
    }
  }

  updateActiveTab = () => {
    this.setState({ activeTab: 'schematic_view' });
  };

  getAircraft = (forceRefetch = false) => {
    const { dispatch, match, aircraftMap } = this.props;
    if (forceRefetch) {
      dispatch(
        getSingleAircraft({
          payload: match.params.id,
        }),
      );
    }
    return aircraftMap.get(match.params.id);
  };

  handleTabChange = (key) => {
    const { match, history } = this.props;
    switch (key) {
      case 'list_view':
        this.setState({ activeTab: 'list_view' });
        history.push(`/aircraft/${match.params.id}/damage_maps/list_view`);
        break;
      case 'schematic_view':
        this.setState({ activeTab: 'schematic_view' });
        history.push(`/aircraft/${match.params.id}/damage_maps/schematic_view`);
        break;
      default:
        break;
    }
  };

  render() {
    const {
      children,
      intl: { formatMessage },
    } = this.props;
    const { activeTab } = this.state;
    const tabList = [
      {
        key: 'list_view',
        tab: formatMessage({ id: 'tab.listView' }),
      },
      {
        key: 'schematic_view',
        tab: formatMessage({ id: 'tab.schematicView' }),
      },
    ];
    return (
      <InnerMenuLayout loading={false}>
        <ExpandedContent
          displayTitle={formatMessage({ id: 'text.damageMaps' })}
          tabList={tabList}
          tabActiveKey={activeTab}
          onTabChange={this.handleTabChange}
        />
        {children}
      </InnerMenuLayout>
    );
  }
}

const damageMapsWithRedux = connect(({ aircraft, damageMaps }) => ({
  aircraftMap: aircraft.aircraftMap,
  damageMaps,
}))(DamageMaps);
export default withRouter(injectIntl(damageMapsWithRedux));
