import { BaseModel } from '..';

const initialState: AircraftLogbookState = {
  data: [],
};

export enum AircraftLogbookActionTypes {
  SAVE = 'aircraftLogbook/save',
}

export interface SaveAircraftLogbookActionTypes {
  type: AircraftLogbookActionTypes.SAVE;
  payload: AircraftLogbook[];
}

export default function(state = initialState, action: SaveAircraftLogbookActionTypes): AircraftLogbookState {
  switch (action.type) {
    case AircraftLogbookActionTypes.SAVE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface AircraftLogbookState {
  data: AircraftLogbook[];
}

export interface AircraftLogbook extends BaseModel {
  columns: string[];
  entries: {}[];
  totals: {
    brought_forward: {};
    carried_forward: {};
  };
  first_trip: string;
}
