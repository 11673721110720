import { BaseModel } from '..';

const initialState: DamageReportsState = {
  damageReportsMap: new Map(),
  lastFetched: 0,
};

export enum DamageReportsActionTypes {
  SAVE_ALL = 'damageReports/saveAll',
}

export interface SaveAllDamageReportsActionTypes {
  type: DamageReportsActionTypes.SAVE_ALL;
  payload: DamageReport[];
  aircraftId: string;
}

export default function(state = initialState, action: SaveAllDamageReportsActionTypes): DamageReportsState {
  switch (action.type) {
    case DamageReportsActionTypes.SAVE_ALL: {
      const masterMap = new Map(state.damageReportsMap.entries());
      const damageReportsMap = masterMap.has(action.aircraftId) ? masterMap.get(action.aircraftId) : new Map();
      action.payload.forEach((damageMap) => {
        damageReportsMap.set(damageMap.id, {
          ...damageReportsMap.get(damageMap.id),
          ...damageMap,
          lastFetched: Date.now(),
        });
      });
      masterMap.set(action.aircraftId, damageReportsMap);

      return {
        ...state,
        damageReportsMap: masterMap,
        lastFetched: Date.now(),
      };
    }
    default:
      return state;
  }
}

interface Attachment {
  id: string;
  file_name: string;
  category: string;
  valid_from: string;
  valid_to: string;
  organisation_id: string;
  status: string;
  url: string;
  pdf_url: string;
  thumbnail_url: string;
  content_type: string;
}

export interface DamageReportsState {
  damageReportsMap: Map<string, Map<string, DamageReport>>;
  lastFetched: number;
}

export interface DamageReport extends BaseModel {
  id: string;
  damage_map_id: string;
  type: string;
  details: string;
  status: string;
  created_at: string;
  x_coordinate: null;
  y_coordinate: null;
  attachments: Attachment[];
  reported_by: null;
  approved_by: null;
}
