import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';
import convertMomentToHoursMins from '../../utils/convertMomentToHoursMins';
import styles from './ProgressCircle.module.less';

const ProgressCircle = ({ widget, widgetNumber, aircraftId }) => {
  const [timeNow, setTimeNow] = useState(moment());

  useEffect(() => {
    setTimeNow(moment());
  }, []);
  const { title, threshold } = widget;
  let { total, nextDue, remaining, tolerance } = widget;
  let unit = 'hours';
  let percentage;
  if (title === 'Days') {
    percentage = ((remaining + tolerance * 30) / (threshold * 4)) * 100;
  } else {
    percentage = ((remaining + tolerance) / (threshold * 4)) * 100;
  }
  if (Number.isNaN(percentage) || percentage < 0) percentage = 0;
  let itemRemaining = remaining;
  if (nextDue === null) remaining = 'Unknown';
  if (title !== 'Days' && tolerance !== null && remaining <= 0) {
    if (remaining <= 0) {
      remaining = 'Overdue';
    }
  } else if (tolerance === null && remaining <= 0) {
    remaining = 'Overdue';
  }

  if (title === 'Cycles') {
    unit = tolerance === 1 ? 'cycle' : 'cycles';
    nextDue += tolerance !== null ? tolerance : 0;
  }

  if (title === 'Days') {
    const differentYear =
      moment().format('YYYY') !==
      (tolerance === 0
        ? nextDue
        : moment(nextDue)
            .add(tolerance, 'months')
            .format('YYYY'));
    nextDue =
      nextDue !== null
        ? moment(nextDue)
            .add(tolerance, 'months')
            .format(differentYear ? 'DD MMM YY' : 'DD MMM')
        : '-';
    total = moment().format('DD MMM');
    unit = tolerance === 1 ? 'day' : 'days';
    if (tolerance !== null && remaining > 0) {
      const dateOfRemaining = timeNow.add(remaining, 'days');
      if (moment(dateOfRemaining).diff(timeNow, 'days') < 0) {
        remaining = 'Overdue';
      }
    } else if (remaining === 0) {
      remaining = moment(widget.nextDue).diff(timeNow, 'days');
    } else if (remaining < 0) {
      remaining = 'Overdue';
    }
  }

  const workOutTimes = (hoursNextDue, hoursTotal, hoursRemaining, hoursTolerance) => {
    let newHours = hoursNextDue;
    if (hoursNextDue !== null) {
      newHours += hoursTolerance !== null ? hoursTolerance : 0;
      nextDue = convertMomentToHoursMins(newHours);
    }
    total = hoursTotal && convertMomentToHoursMins(hoursTotal, timeNow);
    if (remaining !== 'Unknown' && remaining !== 'Overdue' && remaining !== 'Critical' && remaining !== null) {
      remaining = hoursRemaining !== 0 ? convertMomentToHoursMins(hoursRemaining, timeNow) : '00:00';
    }
  };

  const addTimesTogether = (rem, tot, due) => {
    const splitRem = rem.split(':');
    const remHours = parseInt(splitRem[0], 10);
    const remMins = parseInt(splitRem[1], 10);
    const splitTot = tot.split(':');
    const totHours = parseInt(splitTot[0], 10);
    const totMins = parseInt(splitTot[1], 10);
    const newMins = (remMins + totMins) % 60;
    const newHours = remHours + totHours + Math.floor((remMins + totMins) / 60);
    const calculatedDue = `${newHours}:${newMins}`;
    if (calculatedDue === due) {
      return true;
    }
    return false;
  };

  if (title === 'Hours' || title === 'APU Hours') {
    percentage = itemRemaining / 3600;
    tolerance /= 3600;
    itemRemaining /= 3600;
    unit = tolerance === 1 ? 'hour' : 'hours';
    workOutTimes(nextDue, total, remaining, tolerance);
    if (
      nextDue &&
      total &&
      remaining &&
      (remaining !== 'Overdue' || remaining !== 'Critical') &&
      !addTimesTogether(remaining, total, nextDue)
    )
      workOutTimes(widget.nextDue, widget.total, widget.remaining, widget.tolerance);
  }
  if (remaining === 'Overdue' || remaining === 'Critical') percentage = 100;

  let strokeColour = { '0%': '#7bc88d', '100%': '#4aa91c' };
  if ((title === 'Hours' || title === 'APU Hours') && widget.remaining / 3600 < (threshold * 4) / 3) {
    strokeColour = { '0%': '#fad288', '100%': '#f2a650' };
  } else if (remaining < (threshold * 4) / 3) {
    strokeColour = { '0%': '#fad288', '100%': '#f2a650' };
  }
  if (remaining === 'Overdue') strokeColour = { '0%': '#ff7474', '100%': '#ff4040' };
  let remainingStyling = styles.innerNumber;
  if ((title === 'Hours' || title === 'APU Hours') && widget.remaining / 3600 < (threshold * 4) / 3) {
    remainingStyling = styles.criticalStyle;
  } else if (widget.remaining < (threshold * 4) / 3) {
    remainingStyling = styles.criticalStyle;
  }
  if (remaining === 'Overdue') {
    remainingStyling = styles.redStyle;
  }
  if (remaining === 'Unknown') {
    percentage = 0;
    remainingStyling = `${styles.innerNumber} ${styles.greyText}`;
  }
  const innerText = () => (
    <div className={styles.innerTextWrapper}>
      <div className={remainingStyling} data-testid="ProgressCircle--Number">
        {remaining}
      </div>
      <div className={styles.innerName} data-testid="ProgressCircle--Name">
        {title}
      </div>
    </div>
  );

  const showWarningDot = () => {
    let showDot = false;
    if (title === 'Days' && widget.tolerance) {
      if (itemRemaining - widget.tolerance * 30 <= 0) showDot = true;
    } else if (widget.tolerance && itemRemaining - widget.tolerance <= 0) {
      showDot = true;
    }
    return showDot;
  };

  let widgetWidth = 140;
  if (window.innerWidth < 1250 && widgetNumber === 4) widgetWidth = 120;
  return (
    <Link
      to={{
        pathname: widget.isDefect ? `/aircraft/${aircraftId}/defects/log` : `/aircraft/${aircraftId}/maintenance`,
        state: { id: widget.itemId },
      }}
    >
      <Progress
        type="circle"
        percent={percentage}
        strokeWidth={5}
        strokeColor={strokeColour}
        strokeLinecap="square"
        width={widgetWidth}
        className={styles.progressBar}
        trailColor="#e6e8ed"
        format={() => innerText()}
        data-testid="ProgressCircle--ProgressCircle"
      />
      <div className={styles.total}>
        <div className={styles.label}>{title === 'Days' ? 'Today' : 'Total'}</div>
        <div className={remaining === 'Unknown' ? styles.greyValue : styles.value}>{total}</div>
      </div>
      <div className={styles.nextDue}>
        <div className={styles.label}>Due</div>
        <div className={remaining === 'Unknown' ? styles.greyValue : styles.value}>
          {showWarningDot() ? <div className={styles.warningCircle} /> : null}
          {nextDue === null ? '-' : nextDue}
        </div>
      </div>
      {tolerance ? <div className={styles.includingTolerance}>{`Inc. ${tolerance} ${unit} tolerance`}</div> : null}
    </Link>
  );
};

ProgressCircle.propTypes = {
  widget: PropTypes.object,
  widgetNumber: PropTypes.number,
  aircraftId: PropTypes.string.isRequired,
};

ProgressCircle.defaultProps = {
  widget: {
    title: '',
    total: 0,
    nextDue: 0,
  },
  widgetNumber: 3,
};

export default ProgressCircle;
