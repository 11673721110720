/* eslint-disable react/prop-types */

const produceFilterModel = () => {
  return [
    {
      groupLabel: 'Status',
      filters: [
        {
          label: 'Open',
          key: 'status',
          value: 'open',
          badge: 'amber',
          filterFunction: (item) => item.status === 'open',
        },
        {
          label: 'Overdue',
          key: 'status',
          value: 'overdue',
          badge: 'red',
          filterFunction: (item) => item.status === 'overdue',
        },
        {
          label: 'Resolved',
          key: 'status',
          value: 'resolved',
          badge: 'green',
          filterFunction: (item) => item.status === 'resolved',
        },
        {
          label: 'Draft',
          key: 'status',
          value: 'draft',
          badge: 'grey',
          filterFunction: (item) => item.status === 'draft',
        },
      ],
    },
    {
      groupLabel: 'Type',
      filters: [
        {
          label: 'CAS',
          key: 'defect_type',
          value: 'CAS',
          filterFunction: (item) => item.display_data.type === 'CAS',
        },
        {
          label: 'CDL',
          key: 'defect_type',
          value: 'CDL',
          filterFunction: (item) => item.display_data.type === 'CDL',
        },
        {
          label: 'MEL',
          key: 'defect_type',
          value: 'MEL',
          filterFunction: (item) => item.display_data.type === 'MEL',
        },
        {
          label: 'NEF',
          key: 'defect_type',
          value: 'NEF',
          filterFunction: (item) => item.display_data.type === 'NEF',
        },
        {
          label: 'Other',
          key: 'defect_type',
          value: null,
          filterFunction: (item) => item.display_data.type === 'Other' || null,
        },
      ],
    },
    {
      groupLabel: 'Deferred',
      filters: [
        {
          label: 'Deferred',
          key: 'deferred',
          value: true,
          filterFunction: (item) => item.deferred === true,
        },
        {
          label: 'Non-deferred',
          key: 'deferred',
          value: false,
          filterFunction: (item) => item.deferred === false,
        },
      ],
    },
  ];
};

export default produceFilterModel;

// {
//   groupLabel: 'Custom Component',
//   contents: [
//     {
//       type: 'custom-component',
//       label: 'Custom Defect Filter',
//       key: 'workpack_items',
//       nestedArraySubKey: 'mx_item',
//       compareCallback(value, currItem) {
//         return value === true && currItem && currItem.name && currItem.name === '600HR Check';
//       },
//       customComponent({
//         toggleFilterItem: { label, key, relatedTo, count, compareCallback, nestedArraySubKey },
//         updateExclusiveFilter,
//         filterStates,
//       }) {
//         const checked = (filterStates.workpack_items && filterStates.workpack_items.filterState) || false;

//         const handlestageFilterState = () => {
//           updateExclusiveFilter({
//             key,
//             type: 'exclusive-key-filter',
//             filterState: !checked,
//             relatedTo,
//             compareCallback,
//             nestedArraySubKey,
//           });
//         };

//         return (
//           <div>
//             <label htmlFor="custom-filter">
//               {label} <span>({count})</span>
//             </label>
//             <input
//               id="custom-filter"
//               className={styles.filterInput}
//               type="checkbox"
//               checked={checked}
//               onChange={handlestageFilterState}
//             />
//           </div>
//         );
//       },
//       count: draft,
//     },
//   ],
// },
