/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getDefectUrl } from '../../../services/api';
import { getFlightsForAircraft } from '../../../models/flights/actions';
import { getAllAircraft } from '../../../models/aircraft/actions';
import { DashboardState } from '../../../models';
import Loading from '../../TFLoading/index';
import TFPortal from '../../TFPortal/TFPortal';
import TFSelect from '../../TFSelect/TFSelect';
import { Defect } from '../../../models/defects';
import pdfIcon from '../../../assets/icon-pdf.svg';
import greenTick from '../../../assets/complete-icon.svg';
import shareIcon from '../../../assets/link-share.svg';
import servers from '../../../utils/servers';
import StatusBadge from '../../Status/StatusBadge';
import TFButton from '../../TFButton/TFButton';
import { AircraftAuthenticationWrapper } from '../../_utils/AuthenticationWrapper';
import { AircraftPermission, AircraftResource } from '../../../models/aircraft';
import FlexWrapper from './FlexWrapper';
import Label from './Label';

interface DefectHeaderProps {
  defect: Defect | null;
  editDefect: boolean;
  addDefect: boolean;
  handleEditClick: (id: string, edit: boolean) => void;
  status: string;
  date: any;
  flightId: string;
  handleHeaderDetailsChange?: ({ newDate, newFlightId, newAircraftId }) => void;
  controller?: any;
}

const Header = styled.div``;

const DefectTitle = styled.div`
  font-size: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  column-span: ${({ colSpan, editDefect }): string => (colSpan && editDefect ? `${colSpan}` : 'none')};
  span {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
  margin-right: ${({ margin, editDefect }): string => (margin && editDefect ? '20px' : '50px')};
`;

const StyledLinkWrapper = styled.div`
  border-left: ${({ tripId }): string => (tripId ? 'solid 1px rgba(36, 45, 65, 0.2)' : '')};
  padding-left: 6px;
  margin-left: 5px;
  position: relative;
  min-width: 25px;
`;

const LinkBackground = styled.button`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  z-index: 201;
  background-color: transparent;
  border: none;
`;

const PopoverWrapper = styled.div`
  width: 440px;
  background-color: #fafafa;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: 2px solid #fff;
  text-align: left;
  padding: 15px;
`;

const SelectWrapper = styled.div`
  position: relative;
`;

const ImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border: solid 0.8px #75bd9a;
  background-image: linear-gradient(to bottom, #4fb184, #35b96d);
  border-radius: 50%;
  text-align: center;
  display: inline-block;
`;

const ClipboardText = styled.div`
  font-weight: 600;
  letter-spacing: 0.06px;
  color: #181818;
  display: inline-block;
  margin-left: 10px;
`;

const LinkText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.05px;
  color: #9f9f9f;
  margin-top: 10px;
  margin-bottom: 10px;
  word-break: break-all;
`;

const PopoverText = styled.div`
  opacity: 0.7;
  line-height: 1.57;
  letter-spacing: 0.06px;
  color: #181818;
`;

const StyledButton = styled.button`
  color: #1890ff;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const DefectHeader: React.FC<DefectHeaderProps> = ({
  defect,
  editDefect,
  addDefect,
  handleEditClick,
  status,
  date,
  flightId,
  handleHeaderDetailsChange,
  controller,
}) => {
  const [flights, setFlights] = useState([]);
  const [flightTitles, setFlightTitles] = useState([]);
  const [chosenFlight, setChosenFlight] = useState(null);
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [chosenAircraft, setChosenAircraft] = useState(null);
  const [tripId, setTripId] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [menuStyle, setMenuStyle] = useState({});
  const [publicLink, setPublicLink] = useState('');
  const [localDefectLink, setLocalDefectLink] = useState('');
  const [aircraftLoading, setAircraftLoading] = useState(false);
  const [flightLoading, setFlightLoading] = useState(false);
  const [publicLinkLoading, setPublicLinkLoading] = useState(false);
  const [sector, setSector] = useState('');
  const [tripNumber, setTripNumber] = useState('');

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { aircraftFlightsMaps, dirty } = useSelector((state: DashboardState) => state.flights);
  const { aircraftMap, lastFetched: aircraftLastFetched = 0 } = useSelector((state: DashboardState) => state.aircraft);
  const { flightsMap = new Map() } =
    aircraftFlightsMaps.get(chosenAircraft?.value) || aircraftFlightsMaps.get(defect?.aircraft?.id) || {};

  const handlePopoverOpen = (): void => {
    const button = document.getElementById('publicButton');
    if (button) {
      const rect = button.getBoundingClientRect();
      const { top } = rect;
      setMenuStyle({ position: 'absolute', top: top + 30, right: 30, zIndex: 201 });
    }
    setShowPopup(true);
    if (publicLink) {
      const url = publicLink;
      const el = document.createElement('textarea');
      el.value = url;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  };

  const updateFlightDetails = (): void => {
    const newFlight = flights.find((flight) => flight.id === flightId);
    if (newFlight) {
      const {
        sector_number,
        trip: { number },
      } = newFlight;
      setSector(sector_number);
      setTripNumber(number);
    }
  };

  const handleChangeOfFlight = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenFlight(option);
    handleHeaderDetailsChange({ newDate: date, newFlightId: option.value, newAircraftId: chosenAircraft?.value });
  };

  const handleChangeOfAircraft = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenAircraft(option);
    handleHeaderDetailsChange({ newDate: date, newFlightId: chosenFlight?.value, newAircraftId: option.value });
  };

  const handleDateChange = (newDate): void => {
    handleHeaderDetailsChange({ newDate, newFlightId: chosenFlight?.value, newAircraftId: chosenAircraft?.value });
  };

  const handleBackgoundLinkClick = (): void => {
    setShowPopup(false);
  };

  const usePrevious = (value): any => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevFlights = usePrevious(aircraftFlightsMaps);

  useEffect(() => {
    if (aircraftFlightsMaps.get(chosenAircraft?.value)) {
      setFlightLoading(false);
    }
  }, [aircraftFlightsMaps, prevFlights]);

  useEffect(() => {
    if (flightId && flights) {
      updateFlightDetails();
    }
  }, [flights, flightId]);

  useEffect(() => {
    if (defect?.date_due) {
      handleHeaderDetailsChange({
        newDate: moment(defect.date_due, 'YYYY-MM-DD'),
        newFlightId: chosenFlight?.value,
        newAircraftId: chosenAircraft?.value,
      });
    }
  }, [defect]);

  useEffect(() => {
    if (defect?.aircraft?.id) {
      const existingAircraft = aircraftOptions.find((aircraft) => aircraft?.value === defect?.aircraft?.id);
      if (existingAircraft) {
        setChosenAircraft(existingAircraft);
        handleHeaderDetailsChange({
          newDate: date,
          newFlightId: chosenFlight?.value,
          newAircraftId: existingAircraft?.value,
        });
      }
    }
  }, [aircraftOptions, defect]);

  useEffect(() => {
    if (defect && defect.id) {
      setPublicLinkLoading(true);
      getDefectUrl({ id: defect.id, signal: controller.signal }).then((response: any) => {
        if (response && response.res && response.res.status > 199 && response.res.status < 399) {
          setPublicLink(servers.pub + response.url);
          setLocalDefectLink(response.url);
          setPublicLinkLoading(false);
        }
      });
    }
  }, [defect, controller]);

  useEffect(() => {
    if ((chosenAircraft?.value || dirty) && !aircraftFlightsMaps.get(chosenAircraft?.value)) {
      setFlightLoading(true);
      dispatch(getFlightsForAircraft({ payload: { id: chosenAircraft?.value, signal: controller.signal } }));
    }
  }, [chosenAircraft?.value]);

  useEffect(() => {
    const createFlightTitle = (input): void => {
      let currentFlightIndex = 0;
      const titleArray = input.map((flight, index) => {
        if (flight.id === flightId) {
          currentFlightIndex = index;
        }
        return {
          title: `${flight.departure_airport}-${flight.arrival_airport} ${flight.trip.date}`,
          value: flight.id,
        };
      });
      titleArray.unshift({ title: 'None', value: null });
      setFlightTitles(titleArray);
      setChosenFlight(titleArray[currentFlightIndex]);
      handleHeaderDetailsChange({
        newDate: date,
        newFlightId: titleArray[currentFlightIndex].value,
        newAircraftId: chosenAircraft?.value,
      });
    };
    if (flightsMap && Array.from(flightsMap.values()).length > 0) {
      const values = Array.from(flightsMap.values());
      createFlightTitle(values);
      setFlights(values);
      updateFlightDetails();
    }
  }, [flightsMap]);

  useEffect(() => {
    if (flights.length > 0 && tripId === '') {
      const currentFlight = flights.find((flight) => flight.id === flightId);
      if (currentFlight) setTripId(currentFlight?.trip?.id);
    }
  }, [flightId, flights, tripId]);

  useEffect(() => {
    if (Array.from(aircraftMap.values()).length === 0 && Date.now() - aircraftLastFetched >= 300000) {
      setAircraftLoading(true);
      getAllAircraft();
    }
  }, [aircraftLastFetched, aircraftMap]);

  useEffect(() => {
    const createAircraftOptions = (values): void => {
      const newAircraftOptions = [];
      values.forEach((aircraft) => {
        const displayName = {
          title: `${aircraft?.registration} (${aircraft?.aircraft_type.designator})`,
          value: aircraft.id,
        };
        newAircraftOptions.push(displayName);
      });
      setAircraftOptions(newAircraftOptions);
      if (addDefect) {
        setChosenAircraft(newAircraftOptions[0]);
        handleHeaderDetailsChange({
          newDate: date,
          newFlightId: chosenFlight?.value,
          newAircraftId: newAircraftOptions[0].value,
        });
      }
    };
    if (aircraftMap && Array.from(aircraftMap.values()).length > 0) {
      const values = Array.from(aircraftMap.values());
      createAircraftOptions(values);
    }
  }, [aircraftMap, defect]);

  return (
    <Header>
      <DefectTitle data-testid="DefectHeader--Title">
        {editDefect ? (
          <>
            {defect && Object.keys(defect).length > 0 && (
              <span>
                {formatMessage({ id: 'title.editDefect' })}
                <strong>{defect?.number}</strong>
              </span>
            )}
            {!defect && <span>{formatMessage({ id: 'title.addNewDefect' })}</span>}
          </>
        ) : (
          <>
            <span>
              {formatMessage({ id: 'title.defect' })}: <strong>{defect && defect.number}</strong>
            </span>
            {defect?.status !== 'resolved' && (
              <FlexWrapper marginLeft={10}>
                <AircraftAuthenticationWrapper
                  aircraftId={defect?.aircraft?.id}
                  requiredResource={AircraftResource.DEFECT}
                  requiredPermissionLevel={AircraftPermission.UPDATE}
                >
                  <TFButton
                    clear
                    handleClick={(): void => handleEditClick(defect?.id, true)}
                    data-testid="DefectHeader--EditButton"
                  >
                    {formatMessage({ id: 'text.edit' })}
                  </TFButton>
                </AircraftAuthenticationWrapper>
              </FlexWrapper>
            )}
          </>
        )}
      </DefectTitle>
      <FlexWrapper justifyContent="space-between">
        <FlexWrapper>
          {document.location.pathname === '/defects' ? (
            <InfoSection margin colSpan={2} editDefect data-testid="DefectHeader--AircraftSection">
              <Label marginBottom={5} fontSize="12px" color="rgba(36, 45, 65, 0.4)">
                {editDefect ? formatMessage({ id: 'title.aircraft' }) : formatMessage({ id: 'title.aircraftCaps' })}
              </Label>
              {editDefect ? (
                <SelectWrapper>
                  <Loading loading={aircraftLoading} contain width={20} height={20} />
                  <TFSelect
                    initial={chosenAircraft}
                    options={aircraftOptions}
                    handleSelectChange={handleChangeOfAircraft}
                    width={200}
                  />
                </SelectWrapper>
              ) : (
                <FlexWrapper>{chosenAircraft?.title}</FlexWrapper>
              )}
            </InfoSection>
          ) : null}
          <InfoSection margin colSpan={2} editDefect data-testid="DefectHeader--SectorSection">
            <Label marginBottom={5} fontSize="12px" color="rgba(36, 45, 65, 0.4)">
              {editDefect ? formatMessage({ id: 'title.sector' }) : formatMessage({ id: 'title.sectorCaps' })}
            </Label>
            {editDefect ? (
              <SelectWrapper>
                <Loading loading={flightLoading} contain height={20} width={20} />
                <TFSelect
                  initial={chosenFlight}
                  options={flightTitles}
                  handleSelectChange={handleChangeOfFlight}
                  width={230}
                />
              </SelectWrapper>
            ) : (
              <FlexWrapper>
                <Loading loading={flightLoading} contain height={20} width={20} />
                {sector && tripNumber ? (
                  <Link to={`/operations/flights/${flightId}`}>{`${sector} ${`(Trip #${tripNumber})`}`}</Link>
                ) : (
                  '-'
                )}
                <StyledLinkWrapper tripId={tripId}>
                  {tripId ? (
                    <a
                      href={`${servers.api}/srp/${tripId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={formatMessage({ id: 'text.srp' })}
                    >
                      <FlexWrapper>
                        <img src={pdfIcon} alt="pdf icon" />
                        <span>SRP</span>
                      </FlexWrapper>
                    </a>
                  ) : null}
                </StyledLinkWrapper>
              </FlexWrapper>
            )}
          </InfoSection>
          <InfoSection margin data-testid="DefectHeader--DateSection">
            <Label marginBottom={5} fontSize="12px" color="rgba(36, 45, 65, 0.4)">
              {formatMessage({ id: 'text.dateCreated' })}
            </Label>
            {editDefect ? (
              <DatePicker value={date} onChange={handleDateChange} />
            ) : (
              <span>{date.format('YYYY-MM-DD') || '-'}</span>
            )}
          </InfoSection>
          {!editDefect && (
            <InfoSection margin data-testid="DefectHeader--StatusSection">
              <Label marginBottom={5} fontSize="12px" color="rgba(36, 45, 65, 0.4)">
                {editDefect ? formatMessage({ id: 'text.status' }) : formatMessage({ id: 'title.statusCaps' })}
              </Label>
              <FlexWrapper>
                {(defect && defect.status && (
                  <span>
                    <StatusBadge status={status} />
                    <span>{status}</span>
                  </span>
                )) ||
                  '-'}
                <StyledLinkWrapper>
                  <AircraftAuthenticationWrapper
                    aircraftId={defect?.aircraft?.id}
                    requiredResource={AircraftResource.RELEASE}
                    requiredPermissionLevel={AircraftPermission.CREATE}
                  >
                    <Loading loading={flightLoading} contain height={20} width={20} />
                    <Link to={localDefectLink}>
                      {defect?.status !== 'resolved'
                        ? formatMessage({ id: 'form.button.addCRS' })
                        : formatMessage({ id: 'form.button.viewCRS' })}
                    </Link>
                  </AircraftAuthenticationWrapper>
                </StyledLinkWrapper>
              </FlexWrapper>
            </InfoSection>
          )}
        </FlexWrapper>
        <FlexWrapper position="relative" alignItems={editDefect ? 'center' : 'flex-end'}>
          {!editDefect && (
            <AircraftAuthenticationWrapper
              aircraftId={defect?.aircraft?.id}
              requiredResource={AircraftResource.RELEASE}
              requiredPermissionLevel={AircraftPermission.CREATE}
            >
              <StyledButton type="button" id="publicButton" onClick={handlePopoverOpen}>
                <img src={shareIcon} alt="share icon" /> {formatMessage({ id: 'text.publicShareLink' })}
              </StyledButton>
              {showPopup && (
                <TFPortal>
                  <LinkBackground onClick={handleBackgoundLinkClick} />
                  <PopoverWrapper style={menuStyle}>
                    <ImageContainer>
                      <img src={greenTick} alt="Green Tick" />
                    </ImageContainer>
                    <ClipboardText>{formatMessage({ id: 'text.copiedToClipboard' })}</ClipboardText>
                    <Loading loading={publicLinkLoading} contain />
                    <LinkText>{publicLink}</LinkText>
                    <PopoverText>{formatMessage({ id: 'text.validFor30days' })}</PopoverText>
                  </PopoverWrapper>
                </TFPortal>
              )}
            </AircraftAuthenticationWrapper>
          )}
        </FlexWrapper>
      </FlexWrapper>
    </Header>
  );
};

export default DefectHeader;
