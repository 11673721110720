/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import { intl } from '../../providers/IntlProvider';
import { getUserDetails, setUserDetails } from '../../services/api';
import { UserSettingsActionTypes } from '.';

export const fetch = () => async (dispatch: (arg: any) => any): Promise<any> => {
  const response = await getUserDetails();

  if (response && response.people) {
    dispatch({
      type: UserSettingsActionTypes.SAVE,
      payload: { ...response, people: response.people },
    });
  }
};

export const add = ({ payload }: { payload: any }) => async (dispatch: (arg: any) => any): Promise<any> => {
  const response = await setUserDetails(payload);
  if (payload.avatar) {
    message.success(intl.formatMessage({ id: 'text.accountSettings.uploadProfileSuccessful' }));
  } else {
    message.success(intl.formatMessage({ id: 'text.accountSettings.dateFormatUpdated' }));
  }
  dispatch({
    type: UserSettingsActionTypes.SAVE,
    payload: response,
    avatar: new Date(),
  });
};

export const selectView = ({ payload }: { payload: any }) => (dispatch: (arg: any) => any): void => {
  dispatch({
    type: UserSettingsActionTypes.SET_SELECTED_VIEW,
    payload,
  });
};

export const updateUserDetails = ({ details }: { details: any }): any => {
  return {
    type: UserSettingsActionTypes.UPDATE_DETAILS,
    details,
  };
};
