import { Button, Form, Icon, Input, List, Switch, Upload } from 'antd';
import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import SelectMELItem from '../SelectMELItem';
import SelectPerson from '../SelectPerson';
import styles from './index.module.less';

const { TextArea } = Input;

const labelCol = {
  span: 7,
};
const wrapperCol = {
  span: 12,
};

const noLabelWrapperCol = {
  xs: { span: 24, offset: 0 },
  sm: { span: wrapperCol.span, offset: labelCol.span },
};

class DefectForm extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    melItems: PropTypes.array.isRequired,
    melLoading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    submittable: PropTypes.bool,
    submitting: PropTypes.bool,
    className: PropTypes.string.isRequired,
    aircraft_id: PropTypes.string,
  };

  static defaultProps = {
    aircraft_id: '',
    melLoading: false,
    submittable: true,
    submitting: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err) => {
      if (err) return;
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    });
  };

  renderDeferredForm = () => {
    const {
      form,
      intl: { formatMessage },
      melItems,
    } = this.props;

    const id = form.getFieldValue('mel_item_id');
    const melItem = id && melItems?.find((item) => id?.includes(item.id));
    const rectItem = id && melItem?.mel_rectifications?.find((item) => id?.includes(item.id));

    return (
      rectItem && (
        <>
          <Form.Item wrapperCol={noLabelWrapperCol}>
            <List>
              {rectItem.remarks && (
                <List.Item>
                  <List.Item.Meta
                    title={formatMessage({ id: 'title.remarks' })}
                    description={<p className={styles.melDescription}>{rectItem.remarks}</p>}
                  />
                </List.Item>
              )}
              {rectItem.placard_procedure && (
                <List.Item>
                  <List.Item.Meta
                    title={formatMessage({ id: 'title.placard' })}
                    description={<p className={styles.melDescription}>{rectItem.placard_procedure}</p>}
                  />
                </List.Item>
              )}
              {rectItem.maintenance_procedure && (
                <List.Item>
                  <List.Item.Meta
                    title={formatMessage({ id: 'title.maintenanceProcedure' })}
                    description={<p className={styles.melDescription}>{rectItem.maintenance_procedure}</p>}
                  />
                </List.Item>
              )}
              {rectItem.operational_procedure && (
                <List.Item>
                  <List.Item.Meta
                    title={formatMessage({ id: 'title.operationalProcedure' })}
                    description={<p className={styles.melDescription}>{rectItem.operational_procedure}</p>}
                  />
                </List.Item>
              )}
            </List>
          </Form.Item>
        </>
      )
    );
  };

  render() {
    const {
      className,
      form,
      intl: { formatMessage },
      melItems,
      melLoading,
      submittable,
      submitting,
      aircraft_id,
    } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    return (
      <Form layout="horizontal" className={className} onSubmit={this.handleSubmit} hideRequiredMark>
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.deferred' })}>
          {getFieldDecorator('deferred', {
            rules: [
              {
                required: getFieldValue('mel_item_id') !== undefined,
                message: formatMessage({ id: 'message.melDefectsMustBeDeferred' }),
              },
            ],
            initialValue: false,
          })(<Switch checkedChildren={<Icon type="check" />} />)}
        </Form.Item>
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.item' })}>
          {getFieldDecorator('mel_item_id', {
            rules: [
              {
                required: getFieldValue('deferred'),
                message: formatMessage({ id: 'message.addDefectItem' }),
              },
            ],
          })(<SelectMELItem items={melItems} loading={melLoading} />)}
        </Form.Item>
        {getFieldValue('deferred') && this.renderDeferredForm()}
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.details' })}>
          {getFieldDecorator('details', {
            rules: [
              {
                required: true,
                message: formatMessage({ id: 'message.addDefectDetails' }),
              },
            ],
          })(<TextArea spellCheck="false" rows={4} />)}
        </Form.Item>
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'form.labels.reporter' })}>
          {getFieldDecorator('reporter', {
            initialValue: false,
          })(<SelectPerson aircraft_id={aircraft_id} />)}
        </Form.Item>
        <Form.Item labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage({ id: 'title.attachments' })}>
          {getFieldDecorator('attachments', {
            getValueFromEvent: (event) => event.fileList,
            getValueProps: (value) => ({ fileList: value }),
          })(
            <Upload multiple accept={['.jpg', '.jpeg', '.pdf', '.png'].join(',')} beforeUpload={() => false}>
              <Button>
                <Icon type="upload" /> {formatMessage({ id: 'form.button.upload' })}
              </Button>
            </Upload>,
          )}
        </Form.Item>
        {submittable && (
          <Form.Item wrapperCol={noLabelWrapperCol}>
            <Button type="primary" loading={submitting} htmlType="submit">
              {formatMessage({ id: 'form.button.submit' })}
            </Button>
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default compose(
  injectIntl,
  Form.create({
    onValuesChange(props, _, allValues) {
      if (!props.onChange) return;
      props.onChange(allValues);
    },
    mapPropsToFields({ defect }) {
      return ['mel_item_id', 'deferred', 'details', 'reporter', 'attachments'].reduce((acc, x) => {
        acc[x] = Form.createFormField({ value: defect && defect[x] ? defect[x] : undefined });
        return acc;
      }, {});
    },
  }),
)(DefectForm);
