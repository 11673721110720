import { Button, Col, Row, Menu, Dropdown, Icon, message, Modal } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { approveMaintenance, removeMaintenance } from '../../services/api';
import ToleranceIcon from './assets/tolerance.svg';
import styles from './ExpandableCard.module.less';

const MXDetailsManage = ({
  hideEdit,
  hideTolerance,
  onEdit,
  onApplyTolerance,
  item,
  onSuccess,
  aircraftPermissions,
}) => {
  const { formatMessage } = useIntl();

  const [submitting, setSubmitting] = useState(false);

  const editItem = () => {
    onEdit();
  };

  const applyTolerance = () => {
    onApplyTolerance();
  };

  const approveItem = async () => {
    setSubmitting(true);
    try {
      const response = await approveMaintenance({
        ids: [item.id],
      });
      if (response && response.statusCode === 204) {
        message.success(formatMessage({ id: 'message.approveItemSuccess' }));
        onSuccess();
      }
      setSubmitting(false);
      return null;
    } catch (error) {
      return error;
    }
  };

  const deleteItem = async () => {
    setSubmitting(true);
    try {
      const response = await removeMaintenance({
        id: item.id,
      });
      if (response && response.statusCode === 204) {
        message.success(formatMessage({ id: 'message.itemDeleted' }));
        onSuccess();
      }
      setSubmitting(false);
      return null;
    } catch (error) {
      return error;
    }
  };

  const showDeleteConfirm = () => {
    const { confirm } = Modal;

    confirm({
      title: formatMessage({ id: 'form.question.areYouSureDelete' }),
      okText: formatMessage({ id: 'text.confirmYes' }),
      okType: 'danger',
      cancelText: formatMessage({ id: 'text.confirmNo' }),
      onOk() {
        deleteItem();
      },
    });
  };

  const menu = (
    <Menu className={styles.itemDropdown}>
      {!hideTolerance && (
        <Menu.Item>
          <Button type="link" onClick={() => applyTolerance()} className={styles.dropdownButton}>
            <img src={ToleranceIcon} alt="toleranceIcon" className={styles.toleranceIcon} />{' '}
            {formatMessage({ id: 'form.button.applyTolerance' })}
          </Button>
        </Menu.Item>
      )}
      {!hideEdit && (
        <Menu.Item>
          <Button type="link" onClick={() => editItem()} className={styles.dropdownButton}>
            <Icon type="edit" /> {formatMessage({ id: 'text.editItem' })}
          </Button>
        </Menu.Item>
      )}
      <Menu.Item>
        <Button type="link" onClick={() => showDeleteConfirm()} className={styles.dropdownButton}>
          <Icon type="delete" /> {formatMessage({ id: 'title.deleteItem' })}
        </Button>
      </Menu.Item>
    </Menu>
  );

  const maintenancePermissions = aircraftPermissions.filter(
    (permission) => permission.resource_type === 'ScheduledMxItem',
  )[0];
  const canEditOrDeleteMx = maintenancePermissions.to_update || maintenancePermissions.to_delete;

  return (
    <>
      {!item.draft && canEditOrDeleteMx && (
        <Row className={styles.mxManage}>
          <Col md={24}>
            {submitting && <Icon type="sync" spin />}
            <Dropdown overlay={menu} placement="bottomRight">
              <Button type="link" data-test="manageItem">
                {formatMessage({ id: 'text.manageItem' })}
              </Button>
            </Dropdown>
          </Col>
        </Row>
      )}
      {!!item.draft && maintenancePermissions.to_update && (
        <Row className={styles.mxManage}>
          <Col md={24}>
            {submitting && <Icon type="sync" spin />}
            <Button type="link" data-test="approveItem" onClick={() => approveItem()}>
              {formatMessage({ id: 'title.approveItem' })}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

MXDetailsManage.propTypes = {
  item: PropTypes.object.isRequired,
  hideEdit: PropTypes.bool,
  hideTolerance: PropTypes.bool,
  onEdit: PropTypes.func,
  onApplyTolerance: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  aircraftPermissions: PropTypes.array.isRequired,
};

MXDetailsManage.defaultProps = {
  hideEdit: false,
  hideTolerance: false,
  onEdit: null,
  onSuccess: null,
};

export default connect(({ userSettings }) => ({
  aircraftPermissions: userSettings.details.people[0].permission_groups[1].permissions,
}))(MXDetailsManage);
