import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { isCompleteAircraftRecord } from '../../models/aircraft';
import { DashboardResource, UserPermission } from '../../models/userSettings';
import goArrow from '../../assets/go-arrow.svg';
import AuthenticationWrapper from '../../components/_utils/AuthenticationWrapper';
import AircraftInfoSection from './AircraftInfoSection';
import AircraftTotals from './AircraftTotals';
import AircraftWidgets from './AircraftWidgets';
import styles from './AircraftCard.module.less';

const AircraftCard = ({
  intl: { formatMessage },
  aircraft,
  currentAirport,
  isAircraftTotalsVisible,
  handleShowAircraftTotalsClick,
}) => {
  return (
    <div>
      {isCompleteAircraftRecord(aircraft) ? (
        <div className={styles.aircraftCard}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={8} data-testid="AircraftCard--AircraftInfoSection">
              <AircraftInfoSection aircraft={aircraft} formatMessage={formatMessage} currentAirport={currentAirport} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={16}>
              <div className={styles.widgetWrapper}>
                <AuthenticationWrapper
                  requiredResource={DashboardResource.AIRCRAFT}
                  requiredPermissionLevel={UserPermission.UPDATE}
                >
                  <div className={styles.settingsTitle}>
                    <Link to={aircraft ? `/aircraft/${aircraft.id}/settings` : ''} data-test="settingsLink">
                      <span data-test="settingsLinkTitle">{formatMessage({ id: 'menu.settings' })}</span>
                    </Link>
                  </div>
                </AuthenticationWrapper>
                <AircraftWidgets aircraft={aircraft} />
                <div
                  className={
                    isAircraftTotalsVisible ? styles.showTotalsButtonWrapperPadded : styles.showTotalsButtonWrapper
                  }
                >
                  <button
                    type="button"
                    className={styles.aircraftTotalsButton}
                    onClick={() => handleShowAircraftTotalsClick()}
                  >
                    {isAircraftTotalsVisible
                      ? formatMessage({ id: 'text.hideAircraftTotals' })
                      : formatMessage({ id: 'text.showAircraftTotals' })}
                    <img src={goArrow} alt="arrow" className={styles.goArrow} />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          {isAircraftTotalsVisible ? (
            <Row>
              <Col md={24} lg={8} />
              <Col md={24} lg={16}>
                <AircraftTotals aircraft={aircraft} />
              </Col>
            </Row>
          ) : null}
        </div>
      ) : (
        <div className={styles.loading}>{formatMessage({ id: 'text.loading' })}</div>
      )}
    </div>
  );
};

AircraftCard.propTypes = {
  intl: PropTypes.object.isRequired,
  aircraft: PropTypes.object,
  currentAirport: PropTypes.object,
  isAircraftTotalsVisible: PropTypes.bool,
  handleShowAircraftTotalsClick: PropTypes.func.isRequired,
};

AircraftCard.defaultProps = {
  isAircraftTotalsVisible: false,
  currentAirport: {},
  aircraft: {},
};

export default injectIntl(AircraftCard);
