import { Row, TimePicker, Divider } from 'antd';
import moment from 'moment';
// @TODO Find out a way to not need the global import here
// Globally load moment-timezone and data
import PropTypes from 'prop-types';
import 'moment-timezone';
import latestTz from 'moment-timezone/data/packed/latest.json';
import React from 'react';
import styles from './index.module.less';

moment.tz.load(latestTz);

export default class FlightTime extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    airport: PropTypes.object,
    prevValue: PropTypes.object,
    dateFormat: PropTypes.string,
    showDateLabel: PropTypes.bool,
  };

  static defaultProps = {
    airport: null,
    prevValue: undefined,
    onChange: () => {
      // default func
    },
    showDateLabel: false,
    dateFormat: 'YYYY-MM-dd',
  };

  // Modifies UTC offset to 0 while keeping date and time the same
  convertToUTC = (newTime) => {
    if (newTime && !newTime.isUTC()) {
      newTime.add(newTime.utcOffset(), 'm').utc();
    }
    return newTime;
  };

  handleChange = (newTime) => {
    const { onChange } = this.props;

    // Convert new time moment to UTC
    const utcTime = this.convertToUTC(newTime);
    // If time has changed, update the previous value and pass to parent
    if (onChange) {
      onChange(utcTime);
    }
  };

  render() {
    const { airport, prevValue, dateFormat, showDateLabel } = this.props;

    const dateLabel = showDateLabel && moment(prevValue).isValid && moment.utc(prevValue).format(dateFormat);
    const timezone = airport && airport.timezone;

    return (
      <Row type="flex">
        <div className={styles.rowDate}>
          <TimePicker
            className={styles.timePicker}
            onChange={this.handleChange}
            format="HH:mm"
            defaultValue={prevValue}
          />
          {' Z'}
          <div className={styles.timeZoneLabel}>
            {timezone && moment.tz.zone(timezone) && (
              <h6>
                Airport:{' '}
                {prevValue &&
                  moment(prevValue)
                    .tz(timezone)
                    .format('HH:mm z')}
              </h6>
            )}
          </div>
        </div>
        {showDateLabel && prevValue && (
          <div className={styles.dateLabel}>
            <Divider type="vertical" className={styles.divider} />
            {dateLabel}
          </div>
        )}
      </Row>
    );
  }
}
