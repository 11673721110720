import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const hasDashboardPermission = (peoplePermissions, requiredResource, requiredPermissionLevel) => {
  try {
    if (peoplePermissions) {
      const permission = peoplePermissions.find((person) => {
        const resource = person.permission_groups[0].permissions.filter(
          (group) => group.resource_type === requiredResource,
        )[0];
        return resource && resource[requiredPermissionLevel];
      });
      return !!permission;
    }
    return false;
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

export const hasAircraftPermission = (aircraft, requiredResource, requiredPermissionLevel, permissionGroup) => {
  try {
    if (aircraft && permissionGroup) {
      const canAccessAircraft = aircraft && permissionGroup.aircraft.includes(aircraft.id);

      if (canAccessAircraft) {
        const resourcePermission = permissionGroup.permissions.find(
          (permission) => permission.resource_type === requiredResource,
        );

        if (!resourcePermission) {
          throw new Error('Resource does not exist on this aircraft');
        }

        return !!resourcePermission[requiredPermissionLevel];
      }
      throw new Error('User does not have permission on this aircraft');
    }
    throw new Error('Aircraft or permissions objects not supplied');
  } catch (error) {
    console.error(error.message);
    return false;
  }
};

const AircraftAuthentication = ({
  children,
  aircraftMap,
  people,
  aircraftId,
  requiredResource,
  requiredPermissionLevel,
}) => {
  const requiredAircraft = aircraftMap.get(aircraftId);
  const personInOrgWithPermissions = people?.find((person) =>
    person.permission_groups[1].aircraft.includes(aircraftId),
  );

  if (
    personInOrgWithPermissions &&
    requiredAircraft &&
    hasAircraftPermission(
      requiredAircraft,
      requiredResource,
      requiredPermissionLevel,
      personInOrgWithPermissions.permission_groups[1],
    )
  ) {
    return children;
  }
  return null;
};

AircraftAuthentication.propTypes = {
  children: PropTypes.node.isRequired,
  aircraftMap: PropTypes.object.isRequired,
  requiredResource: PropTypes.string,
  requiredPermissionLevel: PropTypes.string.isRequired,
};

AircraftAuthentication.defaultProps = {
  requiredResource: null,
};

export const AircraftAuthenticationWrapper = connect(({ aircraft, userSettings }) => ({
  aircraftMap: aircraft.aircraftMap,
  people: userSettings.details.people,
}))(AircraftAuthentication);

const AuthenticationWrapper = ({ children, userSettings, requiredResource, requiredPermissionLevel }) => {
  const peoplePermissions = userSettings.details && userSettings.details.people;

  if (hasDashboardPermission(peoplePermissions, requiredResource, requiredPermissionLevel)) {
    return children;
  }
  return null;
};

export default connect(({ userSettings }) => ({ userSettings }))(AuthenticationWrapper);
